import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL,TZ } from "../../Global";
function PatientSearch(props) {
    const [searchTerm, setSearchTerm] = useState("");
    const [patients, setPatients] = useState([])
    const [display, setDisplay] = useState(false);
    const initialRender = useRef(true)
    const [deceased,setdeceased]=useState(false)


    useEffect(() => {
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (initialRender.current) {
            initialRender.current = false
        }

        else if (searchTerm !== "") {

            const tokenString = sessionStorage.getItem("usertoken");

            let v2 = tokenString.replace(
                /["]+/g,
                ""
            ); /* REGEX To remove double quotes from tokenstring */
            let url=""
            if(props.from==="inv"&&deceased===true){
                url=`${APIURL}/api/v1/staff/patient-list/?timeZone=${currentTimezone}&deceased=true&per_page=30&page=10&q=${searchTerm}`
            }
            else{
                url=`${APIURL}/api/v1/staff/patient-list/?timeZone=${currentTimezone}&per_page=30&page=10&q=${searchTerm}&ip=true`
            }

            axios
                .get(url, {
                    headers: { Authorization: "Token " + v2 },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setPatients(res.data.patient)
                        setDisplay(true)
                    }
                    else {

                    }
                }).catch((error) => {

                })

        }
        else {
            setPatients([])
            setDisplay(false)
        }

    }, [searchTerm,])
   
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
        if (e.target.value === "") {
            setDisplay(false)
            setPatients([])
          
        }
    }
    return (
        <>
        
            <div style={{ width: "100%", marginTop: "1%" }} className='flex-row'>
            {/* {props.from==="inv"&&<> 
            <div className='enquiryinput' style={{textAlign:"center",border:"none", marginTop:"1%"}}>
               <span style={{ fontSize: "17px",color:deceased === false? "#2196F3":"gray", marginTop:"5%" }} className="text-style-here"><b>(Alive</b></span>
          
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <label class="switch">
                                    <input style={{width:"1%"}} type="checkbox" onChange={() => {setdeceased(!deceased);setSearchTerm("");props.onRadioChng()} }/>
                                    <span class="slider round"></span>
                                </label>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <span style={{ fontSize: "17px", color:deceased === true ? "#2196F3":"gray",marginTop:"55%"  }} className="text-style-here"><b>Deceased)</b></span>
               </div>
               </>} */}
              
                <input
                    style={{ width: '50%' }}
                    className="form-control"
                    type="text"
                    maxLength="100"
                    placeholder="Search name, ID, Phone number"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                <button disabled={searchTerm === ""} className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                </button>
             
            </div>
          

            <div className='flex-row' style={{ justifyContent: "center" }}>
                {
                   patients.length>0&&display?

                        <div className='suggest-display ' style={{ width: "54%", }}>
                            <button style={{ backgroundColor: "#F8F8F8", marginLeft: "92%" }} variant="secondary" onClick={() => { setDisplay(false) }}>
                                <i class="fa fa-times-circle " style={{ color: "#0d6efd" }} aria-hidden="true"></i>
                            </button>
                            {
                                patients.length > 0 ? patients.map((item) => {
                                    return (
                                        <div style={{ fontWeight:"700"}} onClick={() => {
                                            props.onSuccess({
                                                full_name: item.full_name,
                                                first_name: item.first_name,
                                                last_name: item.last_name,
                                                mobile_number: item.mobile_number,
                                                id: item.id,
                                                room: item.bedunit_code,
                                                gender: item.gender,
                                                location:item.location,
                                                admission_date:item.admission_date,
                                                deceased:deceased,
                                                death_status:item.death_status
                                            });setSearchTerm(""); setDisplay(false); 
                                        }} className='suggest-item text-secondary row'>
                                            <div className='col-5'>{item.full_name}</div>
                                            <div className='col-3'>ID:{item.id}</div>
                                            <div className='col-4'>MOB:{item.mobile_number}</div>
                                        </div>
                                    )
                                }) : <h6 className='text-muted'>No Patients found !!</h6>
                            }
                        </div>

                        : null
                }</div>
        </>
    )
}
export default PatientSearch
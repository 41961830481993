import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
function TestScan(props) {
    const navigate = useNavigate();
    const alert = useAlert();
    const [deleteScanConfirmModalShow, setDeleteScanConfirmModalShow] =useState(false);
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
    const [testsList, setTestsList] = useState([]);
    const [scanList, setScanList]=useState([])
    const [scanIndexToDelete, setScanIndexToDelete] = useState();
    const [isDelete,setIsDelete]=useState("")
    const [scanRefresh,setScanRefresh]=useState(false)
    const [testRefresh,setTestRefresh]=useState(false)
    const delConfirmRef = useRef(null)
      useEffect(() => {
        const handleKey = (e) => {
    
          if (e.altKey && e.key === 's') {
            e.preventDefault()
            delConfirmRef.current.click()
          }else if (e.key === 'Enter') {
            e.preventDefault()
            delConfirmRef.current.click()
          }else if (e.altKey && e.key === 'c') {
            e.preventDefault()
            setDeleteScanConfirmModalShow(false);
            
          }
        
        }
        document.addEventListener("keydown", handleKey);
        
        return () => {
          document.removeEventListener("keydown", handleKey);
        }
        
      }, []);
    useEffect(() => {
        setTestsList([])
        const tokenString = sessionStorage.getItem("usertoken");
  
        let str = tokenString.replace(/["]+/g, "");
        axios
        .get(`${APIURL}/api/v1/doctor/tests-list/?patient_id=${props.id}&${TZ}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.message.length > 0) {
              const data = res.data.message;
              const filteredData = data.filter((item) => {
                return item.id !== "";
              });
             
              
              setTestsList(filteredData);
              
            
            }
          }
        });

    
  
    },[testRefresh])
    useEffect(() => {
        
    setScanList([])
        const tokenString = sessionStorage.getItem("usertoken");
  
        let str = tokenString.replace(/["]+/g, "");
  
        axios
          .get(`${APIURL}/api/v1/doctor/scans-list/?patient_id=${props.id}&${TZ}`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              if (res.data.message.length > 0) {
                const data = res.data.message;
                const filteredData = data.filter((item) => {
                  return item.id !== "";
                });
               
                
                setScanList(filteredData);
                
              
              }
            }
          });
        
    }, [scanRefresh]); 
  
      
      const deleteRowScan = (index) => {
        setDeleteScanConfirmModalShow("true");
        setScanIndexToDelete(index);
      };
      const DeleteScanConfirmPopup = (props) => {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 id="confirm-reject">Please confirm to delete Scheduled {isDelete==="scan"?"Scan":"Test"}</h4>
            </Modal.Body>
            <Modal.Footer>
              
    
              <Button
                variant="danger"
                ref={delConfirmRef}
                onClick={() => {
                  setDeleteScanConfirmModalShow(false);
                  if(isDelete==="scan"){
                  
                  handleDeleteScan(scanIndexToDelete);
                  }
                  else if(isDelete==="test"){
                    handleDeleteTest(scanIndexToDelete)
                  }
                  setScanIndexToDelete(null);
                }}
              >
                {" "}
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        );
      };
      const handleDeleteTest = (index, props) => {
        const tokenString = sessionStorage.getItem("usertoken");
    
        let str = tokenString.replace(/["]+/g, "");
    
        const ts_id = index
    
        axios
          .delete(`${APIURL}/api/v1/doctor/tests-details/${ts_id}`, {
            headers: {
              Authorization: "Token " + str,
              "Content-Type": "application/json",
            },
          })
    
          .then((res) => {
            if (res.data.status == "success") {
                alert.success("successfully deleted")
              setTestRefresh(!testRefresh);
             
            } else {
              
            }
          })
          .catch((err) => {
            
          });
      };
      const handleDeleteScan = (index) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const ts_id =index;
        axios
          .delete(`${APIURL}/api/v1/doctor/scans-details/${ts_id}`, {
            headers: {
              Authorization: "Token " + str,
              "Content-Type": "application/json",
            },
          })
    
          .then((res) => {
            if (res.data.status == "success") {
            alert.success("successfully deleted")
            setScanRefresh(!scanRefresh)
            } else {
             
            }
          })
         
      };
   
return(
    <>
       <div style={{marginBottom:"4%",padding:"0.5%"}}>
        <button disabled={login_datas.user_type !=="hospital_doctor" && login_datas.user_type !== "hospital_allied_prof"} onClick={() => {navigate(`/treat-plan/${props.id}`, { state: {cat:"test"} }) }} style={{ margin: "0px", backgroundColor: "rgba(43, 147, 160, 1)",color:"white", width:"20%",float:"right" }} className='treat-button-main'><b>Manage Test & Scan</b></button>
        </div>
   
    <div className='row' style={{padding:"1%"}}>
    
        <div className='col-6'>
        <div style={{paddingLeft:"3%",paddingRight:"2%"}}>
        {testsList.length > 0 &&<div className='row pro_lists ' style={{backgroundColor:"rgba(227, 221, 166, 1)",width:"100%"}}>
                <label className='text-patient-rec col-5 ' >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Test</b></label>
                <label className='text-patient-rec col-3' ><b>Date</b></label>
                <label className='text-patient-rec col-4' ><b>Added by</b></label>
        </div>}
        </div>
        <SimpleBar style={{ maxHeight: '800px',paddingLeft:"3%",paddingRight:"2%"}}>
            {testsList.length > 0 ? testsList.map((item,index) => {
                return (
                    <div  className="pro_lists row" style={{width:"100%"}}>
                        <label className='text-patient-rec col-5' style={{textAlign:"left"}}><i
                        disabled={login_datas.user_type !=="hospital_doctor" && login_datas.user_type !== "hospital_allied_prof"}
                style={{ color:(login_datas.user_type ==="hospital_doctor" || login_datas.user_type === "hospital_allied_prof")?"red":"gray",cursor:(login_datas.user_type ==="hospital_doctor" || login_datas.user_type === "hospital_allied_prof")?"pointer":"not-allowed" }}
                className="fas fa-trash-alt"
                onClick={() => {if(login_datas.user_type ==="hospital_doctor" || login_datas.user_type === "hospital_allied_prof"){
                  setIsDelete("test")
                  deleteRowScan(item.id);
                }
                    
                }}
              ></i>&nbsp;&nbsp;{item.test_name}</label>
                        <label className='text-patient-rec col-3' >{item.date}</label>
                        <label className='text-patient-rec col-4 ' >{item.created_by}    </label>
                       
                    </div>
                )
            }):<div className="" style={{ marginTop: "10%", color: "red" }}>No Tests!!</div>}
        </SimpleBar>
        
        </div>
        <div className='col-6'>
        <div style={{paddingLeft:"3%",paddingRight:"2%"}}>
        {scanList.length > 0 &&<div className='row pro_lists ' style={{backgroundColor:"rgba(227, 221, 166, 1)",width:"100%"}}>
                <label className='text-patient-rec col-5 ' >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Scan</b></label>
                <label className='text-patient-rec col-3' ><b>Date</b></label>
                <label className='text-patient-rec col-4' ><b>Added by</b></label>
        </div>}
        </div>
        <SimpleBar style={{ maxHeight: '800px',paddingLeft:"3%",paddingRight:"2%"}}>
            {scanList.length > 0 ? scanList.map((item,index) => {
                return (
                    <div  className="pro_lists row"style={{width:"100%"}}>
                        <label className='text-patient-rec col-5' style={{textAlign:"left"}}><i
                        disabled={login_datas.user_type !=="hospital_doctor" && login_datas.user_type !== "hospital_allied_prof"}
                        style={{ color:(login_datas.user_type ==="hospital_doctor" || login_datas.user_type === "hospital_allied_prof")?"red":"gray",cursor:(login_datas.user_type ==="hospital_doctor" || login_datas.user_type === "hospital_allied_prof")?"pointer":"not-allowed" }}
                className="fas fa-trash-alt"
                onClick={() => {if(login_datas.user_type ==="hospital_doctor" || login_datas.user_type === "hospital_allied_prof"){
                    setIsDelete("scan")
                  deleteRowScan(item.id);
                }
                }}
              ></i>&nbsp;&nbsp;{item.scan_name}</label>
                        <label className='text-patient-rec col-3' >{item.date}</label>
                        <label className='text-patient-rec col-4 ' >{item.created_by} 
                          </label>
                       
                    </div>
                )
            }):<div className="" style={{ marginTop: "10%", color: "red" }}>No Scan!!</div>}
        </SimpleBar>
       
        </div>
        {deleteScanConfirmModalShow  ? (
                      <DeleteScanConfirmPopup
                        show={deleteScanConfirmModalShow}
                        onHide={() => setDeleteScanConfirmModalShow(false)}
                        index={scanIndexToDelete}
                      />
                    ) : (
                      ""
                    )}
    </div>
    </>
)
}

export default TestScan

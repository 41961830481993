import React,{useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import './hospprofile.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {Button ,Modal } from 'react-bootstrap';
import "./popup.css";
import { useAlert } from 'react-alert'; 
function MobileUpdate(props) {
  const alert = useAlert()
  let number = props.ph;
  if (number && !number.startsWith("356")) {
    number = "356" + number;
  }
    const [phone ,setPhone] =useState({
        dialcode: "",
        rawphone: "",
        mobile_number: number,
    });
    const [isvalidnum,setIsValidnum]=useState(true)
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);
  const [rawPhone, setRawPhone] = useState("");
    

    const [error,setError]=useState({
        phone:""
       });


        const handlePhoneInput= (value, data)=>{
  setError({phone:""})
            const rawPhone = value.slice(data.dialCode.length) ;
            setRawPhone(rawPhone);
            const dialcode = value.slice(0,data.dialCode.length) ;
            let number = value;
            if (number && !number.startsWith("356")) {
              number = "356" + number;
            }
             setPhone(currentstate=>({
                  ...currentstate,
                      dialcode: dialcode,
                        rawphone: rawPhone,
                        mobile_number: number
          
                                 }));
           }

          
let flag = true

           const handleCheckExistingPhone= async()=>{

            const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

         const rawPhone =  phone.rawphone ;
         
         const dialcode =  phone.dialcode;
          setError(currentstate=>({
                     ...currentstate,
                   phone: ''}));
       
           
           const data = {
                   code: dialcode,
                   mobile_number: rawPhone
               }
              
               await axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
                  "Authorization" : 'Token '+str,
                  'Content-Type': 'application/json'}})
           .then(res =>{
                
                   if(res.data.status === "success"){
                     if(res.data.is_existing_user === true){
                         flag=false

                         setError(currentstate=>({
                     ...currentstate,
                   phone: "This phone number is already registered!"}));
                          
                        }
                         else{
                            flag= true
                         
                   }
                        
                          
                           
                       }
                   else{
                    flag= true
                        
                   }
                       
                   })
           .catch(err =>{
       
           
         })
       
        }
        const validatePhoneInput = (value,) => {
  
          if (value.length !== 11&&value.length !== 3) {
            setIsValidnum(false)
              return "Invalid phone number";
        
          }
          else {
            setIsValidnum(true)
              return true
          }
        
        
        }
  const  validate = async() => {
      //let input = email;
      let errors = {};
      let isValid = true;
    await handleCheckExistingPhone()

      if( flag===false  ){
        isValid = false;
        errors["phone"] = "Phone number already registered!";
      }
      if (!isvalidnum) {
        isValid = false;
        //errors["phone"] = "Enter valid phone number";
      }
      if (!phone.dialcode || phone.dialcode==="" || !phone.rawphone || phone.rawphone==="" ) {
        isValid = false;
        errors["phone"] = "Enter phone number";
      }
      if (rawPhone.length > 0 && rawPhone.length < 8) {
        isValid = false;
        // errors["phone"] = "Enter valid phone number  ";
      }
      setError(currentstate=>({
        ...currentstate,...errors
      }))
  
      return isValid;
  }


  const handleContinue =async(e) => {
    e.preventDefault();

    if( await validate() ){
        
        submitPhone()
        //handleSendOTP();
    }
  }


const submitPhone = () =>{
    const tokenString= sessionStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '') 
  
     const data = {
       code : phone.dialcode,
       mobile_number : phone.dialcode + phone.rawphone
     }
  
     axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/` ,data, { headers: {
  
      "Authorization" : 'Token '+str  }})
    .then(res=>{
    
          
      if(res.data.status==="success"){

        alert.success("Updated successfully")
        props.handle();
    
      }else{
        alert.error(res.data.message);
        // setErrorSubmit(true);
      
       
      }
    
    })
    .catch(err=>{
      alert.error("error");

      // setErrorSubmit(true);

    
    })
  
  }

  const ConfirmPopUp = (props) => {
    return(
    <Modal
      {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        //  backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 class="updated-message">Updated Successfully</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
  }
  
  
  const SubmitErrorPopup =(props)=>{
      
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        //  backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 class="updated-message" style={{color: "red"}}>{ errorMsg ? errorMsg
          : 'Error in Data Submission. Please try again!'} </h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>
  
         
         
  
        </Modal.Footer>
      </Modal>
    );
  }


    return (<>
        <div className='phone-edit flex-phone'>
            <div className="form-control phone-input item item-left-row">
                                        <PhoneInput
                                            inputProps={{
              
                                                }}
                                                country={"mt"}
                                                onlyCountries={["mt"]}
                                                countryCodeEditable={false}
                                            value={phone.mobile_number}
                                            onChange={handlePhoneInput}
                                            onBlur={handleCheckExistingPhone}
                                            isValid={validatePhoneInput}  

                                          />
                                          {error.phone ? <div className="error-validation-msg"> {error.phone}</div> : null}

                                          </div>
            <div className="form-button">
                <button style={{marginLeft:'10px',marginTop:"9%"}} onClick={handleContinue} className="btn btn-primary btn-col" >Update Phone</button>
            </div>


            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
        </>
    )
}

export default MobileUpdate

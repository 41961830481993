

let backendHost;
let socketHost;

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const hostname = window && window.location && window.location.hostname;
 if(hostname === 'svp.cianlogic.com') {
  backendHost = 'https://svpapi.cianlogic.com';
  socketHost = 'wss://svpapi.cianlogic.com';
}else if(hostname === 'svpdev.cianlogic.com') {
  backendHost = 'https://svpapidev.cianlogic.com';
  socketHost = 'wss://svpapidev.cianlogic.com';

}else if(hostname === 'localhost') {
  // backendHost = 'https://svpapi.cianlogic.com';
  // socketHost = 'wss://svpapi.cianlogic.com';
  backendHost = 'https://svpapidev.cianlogic.com';
  socketHost = 'wss://svpapidev.cianlogic.com';
}


export const APIURL = `${backendHost}`;
export const SOCKETURL = `${socketHost}`;
export const TZ = `timeZone=${currentTimezone}`;





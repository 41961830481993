import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { logo } from "../../assets/images/icons/cp_logo.jpg";
// import orgData from "../../felixaconfig.json"
function Footer() {
 
  const [backend, setBackend] = useState(null);
  const [webapp, setWebapp] = useState(null);
  const { t } = useTranslation();
  const[orgData,setOrgData]=useState({})
 
  useEffect(() => {
  

    axios.get(`${APIURL}/api/v1/account/customer-info/?${TZ}`, 
  
   )
   .then(res=>{
     if(res.data.status==="success"){
     setOrgData(res.data.message)
  
      }
    

  })
},[]);
  useEffect(() => {
    axios
      .get(`${APIURL}/api/v1/account/app-config/`)
      .then((res) => {
        if (res.data.status === "success") {
          setBackend(res.data.backend_version);
          setWebapp(res.data.webapp_version);
        } else {
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <footer className="footerclass">
      <div className="flex-row footer-row">
        <div className="flex-column-footer">
          <h6 className="text-muted1">{orgData.name_of_organization ?orgData.name_of_organization:"IntPurple Technologies LLP"}</h6>

          <h6 className="text-muted">
          {orgData.official_address?orgData.official_address:" A-307, UNITED CROSSANDRA, OPPO HORAMAVU LAKE,HORAMAVU AGARA, BANGALORE,KARNATAKA. 560043. Malta"}
          </h6>

          {/* <h6 className="text-muted"> No.30/358, Aradhana,</h6>
          <h6 className="text-muted"> Kovoor in the Kozhikode, Kerala,</h6> */}
          <h6 className="text-muted"> {orgData.country?orgData.country:"Malta"}</h6>
        </div>
        <div className="flex-column-footer" style={{alignItems: 'center', paddingTop: 30}}>
        <div className="flex-row footer-icons-row">
        <a
          aria-label="linkedin"
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="social-media-icons" width="35px" height="35px" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"/></svg>
        </a>

        <a
          aria-label="mail id"
          href={orgData.office_email?"mailto:"+orgData.office_email:"mailto:'Contact@IntPurple.com'"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa fa-envelope social-media-icons"></i>
        </a>
&nbsp;&nbsp;
        <a
          aria-label="fb"
          href="https://www.facebook.com/ActiveAgeingandcommunitycare/"
          target="_blank"
          rel="noopener noreferrer"
        >
         <b><svg className="social-media-icons" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="currentColor" d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95"/></svg></b> </a>

        <a
          href={orgData.website?orgData.website:"https://felixacare.com"}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="website"
        >
          <i className="fa fa-globe social-media-icons"></i>
        </a>&nbsp;&nbsp;
        {/* <span style={{ float: "right" ,paddingTop:"1.5%"}}>
          Powered By{" "}
          <a
            href="https://felixacare.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="website link"
          >
            FelixaCare
          </a>
        </span> */}
      </div>
      <div className="flex-row copy-info" style={{paddingTop:"1%"}}>
        {/* Copyright&copy; 2024 IntPurple Technologies LLP. */}
        
      </div>
      </div>
        <div className="flex-column-footer" style={{alignItems: 'flex-end'}}>
          {/* <img className="logo-img" src={logo} alt="Logo" /> */}
          <div>
          <h6 className="">{t("Contact")} </h6>

          <h6 className="text-muted" style={{color:"white"}}>
            <a
              href="tel:+91 75610 57363"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="contact 1"
            >
              <i className="fa fa-mobile mob-icon" aria-hidden="true"></i>

              <span style={{color:"white"}} className="mob"> {orgData.mobile_number?"+"+orgData.mobile_number:"+91 75610 57363"}</span>
            </a>

            <br />

          
          </h6>
          </div>
          

          <span style={{ marginTop: 40 }}>
          {backend !== null && webapp !== null ? (
            <h6>Ver:{webapp}</h6>
          ) : null}
        </span>
        </div>
      </div>


      
      
    </footer>
  );
}

export default Footer;

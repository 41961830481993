import React, {useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function ShipSize (props) {
    

    const [size, setSize]=useState(props.setsizevalue !== " " ? props.setsizevalue : " ")
    const [modalShow, setModalShow]=useState(false)

    const handleChange = (e)=> {
        const val = e.target.value
     
            setSize(e.target.value)
         }

  const handleShipEdit = () => {

           
    
                const id =props.id
            const tokenString= sessionStorage.getItem('usertoken');
       
            let str= tokenString.replace(/["]+/g, '');
             
             
           const dataToUpdate = 
               {
                   "threshold_size": size
               }
         
         
           
             axios.put(`${APIURL}/api/v1/service-provider/shipping-charge-detail/11/`,dataToUpdate, { headers: {
               
               "Authorization" : 'Token '+str,
               'Content-Type': 'application/json'  }
         
         
             })
         
         
         
             .then(res=>{
            
               if(res.data.status==="success"){
                    setModalShow(true)
                    //setCharge(" ")
               }
          
             })
             .catch(err=>{
           
               
         })
    
            }
      const SubmitPopup =(props)=>{
    

                return (
                  <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                     backdrop="static"
                      keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                       
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className='text-success'>Size Edited Successfully !!</h5>
                     
                    </Modal.Body>
                    <Modal.Footer>
                     
                       <Button variant="secondary" onClick={props.onHide}>Ok</Button>
              
                     
                     
              
                    </Modal.Footer>
                  </Modal>
                );
              } 
              const ErrorPopup =(props)=>{
    

                return (
                  <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                     backdrop="static"
                      keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                       
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
                     
                    </Modal.Body>
                    <Modal.Footer>
                     
                       <Button variant="secondary" onClick={props.onHide}>Ok</Button>
              
                     
                     
              
                    </Modal.Footer>
                  </Modal>
                );
              }
        
        return (
            <div className='flex-col'>

<input
                    type="number"
                    name="size"
                    
                    value={size}
                    onChange={handleChange}
                    required
                ></input>  

<button  onClick={handleShipEdit} className='btn btn-primary btn-col'>Add</button> 


{
        modalShow ? 
        <SubmitPopup
          show={modalShow}
          onHide= {() => { setModalShow(false); props.setSizeHandle(); 
          }}
        /> : null
      }
                </div>
      

        )

}

export default ShipSize;

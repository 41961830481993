import React,{useState,useContext } from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";

function EditProcedure(props) {
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  let isEdited = false;

    const[data, setData]= useState(props.data);
       

    const [error,setError]=useState({
      procedure_name:'',
      procedure_type:'',
       });
       const [selectedImage, setSelectedImage] = useState(null);
    const alert = useAlert()
    const [year, setYear]= useState()
    const [yearData, setYearData]= useState()
    const [expiry, setExpiry]= useState()
    const [expiryData, setExpiryData]= useState()
    const [refresh, setRefresh]= useState(false)

    //const [addRentalShow, setAddRentalShow]= useState(false)
    const[errorValidate,setErrorValidate]=useState(false)

//     const serviceOptions = [{ value:'general', label:'General' },
//     { value:'service_request', label:'Service Request' },
//     { value:'rental', label:'Equipment Request' }
// ]

    const unitOptions = [{ value:'days', label:'Days' },
    { value: 'count', label: 'count' }
]



  const handleError = () =>
  {
    setErrorValidate(false);
  }
    
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


  const validate=()=>{
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["procedure_name"] || input["procedure_name"]==="" ) {
      isValid = false;
      errors["procedure_name"] = "Enter procedure Name";
    }
    if (!input["procedure_type"] || input["procedure_type"].value==="" ) {
      isValid = false;
      errors["procedure_type"] = "Enter procedure Type";
    }
    

     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}
const handleConfirm = () => {
  closeConfirmationPopup()
  saveChanges();
 
};

const handleCancel = () => {
  closeConfirmationPopup()
};
const handleContinue = () => {
  if(validate()){ 
    setShowConfirmation(true)
  };
}

  const saveChanges = ()=> {
   
    
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
     const formData = new FormData();

     if (selectedImage) {
       formData.append('image', selectedImage);
       isEdited=true;
     }
     
     if (data.procedure_name !==props.data.procedure_name) {
       formData.append('procedure_name', data.procedure_name);
       isEdited=true;
     }
     
     if (data.procedure_type!==props.data.procedure_type) {
       formData.append('procedure_type', data.procedure_type);
       isEdited=true;
     }
 
     if(isEdited){
     axios
       .put(`${APIURL}/api/v1/staff/master-procedure-detail/${data.id}/`, formData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Successfully updated');
          props.modalClose()
         } else {
           alert.error('Procedure already present')
         }
       })
       .catch((err) => {
        alert.error('Procedure already present')
       });
   

      }
    

}

const handleImageUpload = (event) => {
  const image = event.target.files[0];
  setData(prevData => ({...prevData, image: URL.createObjectURL(image)}));
  setSelectedImage(image);
  
};
    return (
        <>
        
    <h2 style={{marginTop:'0'}} className="title-of-page">Edit procedure</h2>
    <div style={{display:"flex" , marginLeft: "4rem", marginRight: "4rem", overlap:"hidden", padding: "0 10px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {data.image && (
         <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: "5px"  }}>
          <img src={data.image} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
        <div className='config-box fit-content' style={{minHeight:'fit-content', width:'100%'}}>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Procedure Name: </label>
                    <input type='text' className='form-control title-flex' name='procedure_name' value={data.procedure_name} onChange={handleTitle} />
                   </div>
                   {error.procedure_name? <div className="error-validation-msg error-feature-inventory">{error.procedure_name}</div>:""}
                </div>

                <div className="formField">
                  <div  style={{display:"flex"}}>
                    <label className='align-rental'>*Procedure Type: </label>
                    <input type='text' className='form-control title-flex' name='procedure_type' value={data.procedure_type} onChange={handleTitle} /></div>
                   {error.procedure_type? <div className="error-validation-msg error-feature-inventory">{error.procedure_type}</div>:""}
                </div>

          </div>            
        </div>
        <div style={{textAlign:'right'}}>          
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>cancel</button>
          <button onClick={handleContinue} className='btn btn-primary btn-col mb-2'>Save</button>
        </div>
        {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to continue?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </>
    )
}

export default EditProcedure;

import React,{useState,useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';

import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
function AddDiscease(props) {
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
    const[data, setData]= useState({
        diseasename:'',
        diseasetype:'',
        diseasestage:'',
        genericname:'',
        icdcode:''
        

    })
    const alert = useAlert();
    const [error,setError]=useState({
      diseasename:''
        
       });

    const[errorValidate,setErrorValidate]=useState(false)

      const handleError = () =>
  {
    setErrorValidate(false);
  }
    
     
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


  

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["diseasename"] || input["diseasename"]==="" ) {
      isValid = false;
      errors["diseasename"] = "Enter disease Name";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = ()=> {
  
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
        disease_name: data.diseasename.slice(0,1).toUpperCase() +data.diseasename.slice(1, data.diseasename.length),  
        disease_type:data.diseasetype,
        disease_stage:data.diseasestage,
        generic_name:data.genericname,
        icd_code:data.icdcode
        

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-primary-diseases/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Saved successfully!');
          props.modalClose();  
          setData({
            diseasename : '',
            diseasetype : '',
            diseasestage : '',
            genericname : '',
            icdcode : ''
         });
         } else {
           alert.error('Check if duplicated')
         }
       })
       .catch((err) => {
        alert.error('Check if duplicated')
       });
    

 

}
const handleConfirm = () => {
  closeConfirmationPopup()
  saveChanges();
 
};

const handleCancel = () => {
  closeConfirmationPopup()
};
const handleContinue = () => {
  if(validate()){ 
    setShowConfirmation(true)
  };
}

    return (
        <>
   
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features' style={{textAlign:"left"}}>
            
                    <label className='text-style-here col-6'>*Name: </label>
                    <input type='text' className='enquiryinput col-6' name='diseasename' value={data.diseasename} onChange={handleTitle} />
                    {error.diseasename ? <div className="error-validation-msg error-feature-inventory">{error.diseasename}</div> : null}
                <br/>

               
                    <label className='text-style-here col-6'>Type: </label>
                    <input type='text' className='enquiryinput col-6' name='diseasetype' value={data.diseasetype} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                    <br/>
                
                    <label className='text-style-here col-6'>Stage: </label>
                    <input type='text' className='enquiryinput col-6' name='diseasestage' value={data.diseasestage} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                    <br/>
              
                    <label className='text-style-here col-6'>Generic Name:</label>
                    <input type='text' className='enquiryinput col-6' name='genericname' value={data.genericname} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                    <br/>
                
                    <label className='text-style-here col-6'> ICD Code: </label>
                    <input type='text' className='enquiryinput col-6' name='icdcode' value={data.icdcode} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                

            </div>
          </div>
          <div style={{textAlign:'right'}}>
         
          <button onClick={handleContinue} className='btn btn-primary btn-col mb-2'>Save</button>   
          {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to continue?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </div>
        </>
    )
}

export default AddDiscease

import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function AddSp(props) {
  const alert = useAlert();
    const [sp, setSp]=useState()
    const saveButtonRef = useRef(null)

    useEffect(() => {
      const handleKey = (e) => {
  
        if (e.altKey && e.key === 's') {
          e.preventDefault()
          saveButtonRef.current.click()
        }else if (e.key === 'Enter') {
          e.preventDefault()
          saveButtonRef.current.click()
        }
      
      }
      document.addEventListener("keydown", handleKey);
      
      return () => {
        document.removeEventListener("keydown", handleKey);
      }
      
    }, []);


    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = toISOString(Date().toLocaleString());
  function toISOString(s) {
    var months = {jan:'01',feb:'02',mar:'03',apr:'04',may:'05',jun:'06',
                  jul:'07',aug:'08',sep:'09',oct:'10',nov:'11',dec:'12'};
    var b = s.split(' ');
  
    return b[3] + '-' +
           months[b[1].toLowerCase()] + '-' +
           ('0' + b[2]).slice(-2) + 'T' +
           b[4] + b[5].substr(3);
  }

    const handleChange = (e)=> {
        const val = e.target.value
        if(val.length < 6){
            setSp((e.target.value).split(".")[0])
        }
        else {
            return false
        }
    }

    const handleSubmit = () => {

        if(sp > 59 && sp <= 101) {

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               'vital_name':"spo2",
               'result' :sp,
               'date_time':currentDateTime
           }
     
     
       
         axios.post(`${APIURL}/api/v1/patient/vitals/?patient_id=${id}&timeZone=${timezoneOffset}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
                alert.success("SPO2 added Successfully")
                props.submitsp() ;setSp("")
          
       }
       else{
           alert.error(res.data.message)
        
       }
     
         })
        
        }
        else {
            alert.error("SPO2 value must be between 60%  and 100%")
        }

        
    }

   

   
    return (
        <div className='flex-col'>
           <input style={{width:'50%'}} type="number" min='0' max='100'step="1" placeholder="Add SPO2 in %" value={sp} onChange={handleChange} className="form-control"/><br/>
           <button disabled={sp<1 || sp=== undefined} onClick={handleSubmit} ref={saveButtonRef} className='btn btn-primary btn-col'>Add</button> 


      

        </div>
    )
}

export default AddSp

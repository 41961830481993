import React ,{useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import './profile.css'
import {Button ,Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';

function MedicalUpdate(props) {
    const alert = useAlert()
    const[medical ,setMedical] =useState(props.medical !== " " ? props.medical : " ");    
    const id = props.id1
    const [error,setError]=useState({
        medical:''
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='medical'){
            setMedical(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (medical==="" ) {
            isValid = false;
            errors["medical"] = "Enter medical competence";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitAddress()
        }
      }

        const submitAddress = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
                medical_competencies : medical
             }
          
             axios.put(`${APIURL}/api/v1/doctor/medical-profile-update-view/${id}/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
              
              alert.success("Updated successfully")
              props.handle()
              setMedical()
               
            
              }else{
              alert.error("Error in Data Submission. Please try again!")
               
              }
            
            })
            .catch(err=>{
              alert.error("Error in Data Submission. Please try again!")

            
            })
          
          }

    return (
        <div>
            <div className="item"><textarea className="form-control" type="text" value={medical} maxLength="150" name="medical" placeholder="Medical Competence" onChange={handleChange}/>

                {error.medical ? <div className="error-validation-msg"> {error.medical}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Medical Competence</button>
            </div>
         </div>
    )
}

export default MedicalUpdate

import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./service.css";
import { useAlert } from 'react-alert' 

import DispenceAdd from "./DispenceAdd";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { SOCKETURL } from "../../Global";
import { useNavigate, Navigate, Link } from "react-router-dom";
import EditRemark from "./EditRemark";
import AddRemark from "./AddRemark";
import LoadingOverlay from "react-loading-overlay";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ViewRemark from "./ViewRemark";
import { IoClose } from "react-icons/io5";
import moment from 'moment';
import FormService from "./FormService";
import { Tooltip } from 'react-tooltip';
function Consumables (props){
    const alert = useAlert()
  const [url,setUrl]=useState("")
  let approve="approved";
  let reject ="rejected";
  const [wardSearch, setWardSearch] = useState(false);
  const [show, setShow] = useState(false);
  var Typeahead = require("react-bootstrap-typeahead").Typeahead;
  const [intakeValue, setIntakeValue] = useState("");
  const [reRender, setRerender] = useState(false);
  const [search, setSearch] = useState("");
  const [orderedCount,setOrderedCount] = useState("");
  const [disHistory,setDisHistory]=useState([])
  const [assignId, setAssignId] = useState(0);
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const [hasNext, setHasNext] = useState(false)
  const [loading, setLoading] = useState(false);
  const [approved, setApprove] = useState(false);
  const [rejected, setReject] = useState(false);
  const [editingcol,setEditingCol]=useState()
  const [render, setRender] = useState(true);
  const [dispatched,setDispatched]=useState()

  const [displayIndex, setDisplayIndex] = useState(null)
  
  const [remarkData, setRemarkData]= useState('')
  const [remarkData1, setRemarkData1]= useState([])
  const [viewShow, setViewShow]= useState(false)


  const [, setGetError] = useState('');
  const [tableData, setTableData] = useState([]);
  const [medData, setMedData] = useState([]);

  const [modalShow, setModalShow] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [remarkList, setRemarkList] = useState([])
  const [backup, setBackup] = useState('')
 const [dispenceCount,setDispenceCount] = useState("");
 const [dispenceID,setDispenceID] = useState("");
 const[dispenseShow,setDispenseShow] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalRows1, setTotalRows1] = useState(0);
  const[page,setPage]=useState(1)
  const [editShow, setEditShow] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false)
 
  const [cell, setCell] = useState('')

  const [id, setId] = useState("");

  const [patient, setpatient] = useState("");
  const [order, setOrder] = useState("")
  const [trackId, setTrackingId] = useState()
 
  let navigate = useNavigate();


  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let tableData2=[]
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
  const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
  const [selectedRows, setSelectedRows] = useState([]);
  const [anyRowSelected, setAnyRowSelected] = useState(false);
  const [selectedAssignees, setSelectedAssignees] = useState({});
  
  useEffect(() => {
    setAnyRowSelected(Object.values(selectedRows).some((value) => value));
  }, [selectedRows]);

  

  useEffect(() => {
    setTableData(tableData)
   
  }, [page,render, tableData,forceRender]);

  const filterData5 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
  let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&quantity=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        setLoading(false);

        data = response.data.requests
        if (data.length>0){
       
            setMedData(data);
           }
           else{
            const data1 = data.filter((item) => !medData.some((existingItem) => existingItem.id === item.id));
            setMedData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  useEffect(() => {
    console.log(options);
    const xdata = options1;
    
    let filteredData = xdata.length > 0 && xdata.filter(x => {
        return x.name.toLowerCase().includes(search.toLowerCase());
    });
    
    setOptions(filteredData);
    console.log("filteredData", filteredData); // Log the object directly
}, [search]);


  

  
  const handleCloseDispence =()=>{
    setDispenseShow(false);
  // setMedData([])
  // setRerender(!reRender)
  // setBackup('')
   // window.location.reload(false)
   
  }


  const handleSelectAll = (e) => {
    setSearch("")
    setAssignId(0)
    setSelectedAssignees({})
    const checked = e.target.checked;
    const updatedSelectedRows = tableData.reduce((acc, row) => {
      acc[row.id] = checked;
      return acc;
    }, {});
    setSelectedRows(updatedSelectedRows);
     setAnyRowSelected(checked);
  };
  useEffect(() => {
    
    tableData.splice(0, tableData.length + 1)

    getServices(1,perPage);
  },[perPage,props.render,reRender])

  // useEffect(() => {
 
  //   tableData.splice(0, tableData.length + 1)
  //   getServices(1,perPage);
  // },[reRender])

  const getServices =  async  (page,per_page) => {
    
   
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');



   await axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=${page}&per_page=${per_page}&timeZone=${currentTimezone}&current_date=${formattedDate}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        if (res.data.status === "success") {
      
          setLoading(false);
          let data=res.data.requests
         
          if (data && data.length > 0) {
            setTableData((prevData) => {
              const newData = data.filter((item) => !prevData.some((existingItem) => existingItem.id === item.id));
              return [...prevData, ...newData];
            });
          setPage(res.data.page)
            setHasNext(res.data.has_next);
            setTotalRows(res.data.total_records);
           
          }
        }

        else {
          setLoading(false);

          setGetError(res.data.message);
        }



      })
      .catch(err => {
        setLoading(false);

        setGetError("Error in fetching data");
      })
  };

  var sendData = null

  function handleSend(childData) {
    sendData = childData
    
  }

  const handleView =(data) => {
 
  
    setRemarkData1(data)
    setViewShow(true)
  }
  const SuccessPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>{props.msg} </h5>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    );
  }


  

  const SubmitErrorPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {errorMsg !== '' ? errorMsg : "Error in Data Submission. Please try again!"} </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>




        </Modal.Footer>
      </Modal>
    );
  }

  const EditPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={remarkData} list={remarkList} update={handleSend} />


        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

        </Modal.Footer>
      </Modal>
    );
  }
  const handleRender=(e)=>{
    
  
    // setRerender(!reRender)
    setModalShow(false)
  
  setTableData([])
  //setMedData([])
  setRerender(!reRender)
  setBackup('')
  // window.location.reload(false)
   
  }

  const AddPopup = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        <span>Add Remark</span>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} trackId={trackId} id={id} patient={patient} order={order} render1={handleRender}  />

        </Modal.Body>
       
      </Modal>
    );
  }

  const UpdatePopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>Please save to complete changes </h5>




        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>






        </Modal.Footer>
      </Modal>
    );
  }


  const handlePageChange = (pageIndex) => {
    const nextPage = pageIndex + 1; // Convert 0-based index to 1-based page number
    if (pageIndex >= 0 && nextPage <= Math.ceil(totalRows / perPage)) {
      setPage(nextPage);
      getServices(nextPage, perPage); // Fetch data for the new page
    }
  };
  
  const handlePerRowsChange = async (newPerPage, pageindex) => {
    
    setPerPage(newPerPage);

  }

  const handleRowCheckboxChange = (e, row) => {

    const rowId = row.id;
    const checked = e.target.checked;
  
    setSelectedRows((prevSelectedRows) => {
      // Create a copy of the previous selected rows
      const updatedSelectedRows = { ...prevSelectedRows };
  
      // Update the status of the current row
      updatedSelectedRows[rowId] = checked;
     
  
      return updatedSelectedRows;
    });
  };

 
  

  const handleRejectAll=(selectedRows)=>{
   
    console.log(selectedRows)
    const tokenString = sessionStorage.getItem('usertoken');
    const approvedIds = Object.entries(selectedRows)
    .filter(([id, isSelected]) => isSelected)
    .map(([id]) => id);
    let str = tokenString.replace(/["]+/g, '');
    const dataToUpdate = {
      approval_ids : approvedIds,
      status : reject,
    
       }

       axios.put(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/`, dataToUpdate, {
        headers: {
  
          "Authorization": 'Token ' + str
        }
      })
        .then(res => {
  
          if (res.data.status === "success") {
          
            alert.success("Rejected successfully");
            setTableData([]);
            setRerender(!reRender);
            // setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");
  
            // setSuccessSubmit(true);
            setBackup('')
          }
          else {
  
  
            setErrorMsg(res.data.message ? res.data.message : "Error in submission");
            setErrorSubmit(true);
  
          }
        })
        .catch(err => {
  
          setErrorMsg("Error in submission");
          setErrorSubmit(true);
  
        })
  }

  const saveChanges = (index) => {

    setApprove(true);
    const rowToUpdate = tableData.filter((elt, i) => {
        return i === index
    })

    callSave(rowToUpdate[0],index);

}

  const saveChanges1 = (index) => {

    setReject(true);
    const rowToUpdate = tableData.filter((elt, i) => {
      return i === index
    })

    callSave1(rowToUpdate[0],index);

  }
  const callSave = (rowToUpdate,index) => {

    // if (assignId !==0){
    
    
        const tokenString = sessionStorage.getItem('usertoken');
    
        let str = tokenString.replace(/["]+/g, '');
       // const id = rowToUpdate.id;
       let dataToUpdate={}
       if(rowToUpdate.dispensing_count>rowToUpdate.dispatched_count+rowToUpdate.remaining_count){
        dataToUpdate = {
          status:"partially_approved",
          dispatched_count:rowToUpdate.remaining_count
      };
       }
       else{
        dataToUpdate = {
          status:"approved",
          dispensing_count:rowToUpdate.remaining_count+rowToUpdate.dispatched_count
          ,
          dispatched_count:rowToUpdate.remaining_count
      };
       }
     
      
    
      
       
    
        console.log("THE DATA", rowToUpdate);
      
    
        axios.put(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${rowToUpdate.id}/`, dataToUpdate, {
          headers: {
    
            "Authorization": 'Token ' + str
          }
        })
          .then(res => {
    
            if (res.data.status === "success") {
            
              alert.success("Approved successfully");
              //window.location.reload(false)
              setSearch("")
              setAssignId(0)
              setSelectedAssignees({})
              setTableData([]);
              setRerender(!reRender);
              //  props.data()
              // setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");
    
              // setSuccessSubmit(true);
              setBackup('')
            }
            else {
    
    
              setErrorMsg(res.data.message ? res.data.message : "Error in submission");
              setErrorSubmit(true);
    
            }
    
    
    
          })
          .catch(err => {
    
            setErrorMsg("Error in submission");
            setErrorSubmit(true);
    
          })
    
        
      }

      const callSave1 = (rowToUpdate,index) => {

  
        const tokenString = sessionStorage.getItem('usertoken');
    
        let str = tokenString.replace(/["]+/g, '');
       // const id = rowToUpdate.id;
      
      
     const dataToUpdate = {
        status: reject
      };
      
     
      
       
    
        console.log("THE DATA", rowToUpdate);
      
    
        axios.put(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${rowToUpdate.id}/`, dataToUpdate, {
          headers: {
    
            "Authorization": 'Token ' + str
          }
        })
          .then(res => {
    
            if (res.data.status === "success") {
            
              alert.success("Rejected successfully");
              setTableData([]);
              setSearch("")
              setAssignId(0)
              setSelectedAssignees({})
              setRerender(!reRender);
              // setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");
    
              // setSuccessSubmit(true);
              setBackup('')
            }
            else {
    
    
              setErrorMsg(res.data.message ? res.data.message : "Error in submission");
              setErrorSubmit(true);
    
            }
    
    
    
          })
          .catch(err => {
    
            setErrorMsg("Error in submission");
            setErrorSubmit(true);
    
          })
    
      }
    
  
    // Render "Approve" and "Reject" buttons in the header

  

 
  const renderRemarks = (cellInfo) => {
    return (<>
      <button  style={{borderRadius: "5px",width:"94px",height:"39px",
      background: "#61C65F",color:"white",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
   
          onClick={() => {saveChanges(cellInfo.index)  }} >
             <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
          <path d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z" fill="white"/>
          </svg> &nbsp;&nbsp; Approve </button>&nbsp;&nbsp; 
          <button style={{borderRadius: "5px",width:"94px",height:"39px",
      background: "#C6725F",color:"white",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
      disabled={tableData[cellInfo.index].status==="partially_approved"}
            onClick={() => {saveChanges1(cellInfo.index)  }} > <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="white"/>
            </svg> &nbsp;&nbsp;Reject </button>&nbsp;&nbsp; 
      <button onClick={() => handleAdd(cellInfo.index, tableData[cellInfo.index].ref, tableData[cellInfo.index].id, tableData[cellInfo.index].patient, tableData[cellInfo.index].remarks)}
       style={{borderRadius: "5px",padding:"3%",paddingTop:"3.5%",paddingBottom:"3.5%",
      background: "#2A6379",color:"white",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
         <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path d="M20 2H10a2 2 0 0 0-2 2v2h8a2 2 0 0 1 2 2v8h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor"/><path d="M4 22h10c1.103 0 2-.897 2-2V10c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2zm2-10h6v2H6v-2zm0 4h6v2H6v-2z" fill="white"/></svg>
     </button>
     </>);
   


  }
  const DispenseAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          
            <span ><b>Dispensing Count- <span style={{color:"red"}}>{orderedCount-dispatched}</span>/{orderedCount}</b>&nbsp;</span>
         
        </Modal.Header>
        <Modal.Body>
          <DispenceAdd
            id={dispenceID}
            ordered={orderedCount}
            intake={""}
            history={disHistory}
            dispatched={dispatched}
            onSuccess={(data)=>{let list=tableData
              list[editingcol].remaining_count = data;
              setTableData(list); setDispenseShow(false);}}
            seteditDispence={dispenceCount}
            //
            handle={handleCloseDispence}
          />
        </Modal.Body>
       
      </Modal>
    );
  };
  const handleAdd = (index, id, trackId, name, order) => {
    
        setCell(index);
        setId(id);
        setTrackingId(trackId)
        setpatient(name)
        setOrder(order)
        setModalShow(true);
      };
    

  const filterDataID = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&request_pid=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        setLoading(false);
         data = response.data.requests
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
         }
         else{
          const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData((prevData) => [...prevData, ...data1]);
         }
        // }
        console.log("tableData",tableData)
        
       setTotalRows1(response.data.total_records)
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataQuantity = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&quantity=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        setLoading(false);
         data = response.data.requests
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
         }
         else{
          const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData((prevData) => [...prevData, ...data1]);
         }
        // }
        console.log("tableData",tableData)
        
       setTotalRows1(response.data.total_records)
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataNurse = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&nurse_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        setLoading(false);
         data = response.data.requests
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
         }
         else{
          const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData((prevData) => [...prevData, ...data1]);
         }
        // }
        console.log("tableData",tableData)
        
       setTotalRows1(response.data.total_records)
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataBed = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&bedunit_code=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        setLoading(false);
         data = response.data.requests
         if (data.length>0){
         
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
        setWardSearch(true)

          setTableData(data);
          if(filterValue.length===0){
            setWardSearch(false)
           }
         }
         else{
          setWardSearch(false)
          const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData((prevData) => [...prevData, ...data1]);
         }
        // }
        console.log("tableData",tableData)
        
       setTotalRows1(response.data.total_records)
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataName = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data= [];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        setLoading(false);
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
       setTotalRows1(response.data.total_records)
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const renderDispenceCount =(cellInfo)=>{
   
    return(
      <div> <span  style={{textTransform: "capitalize", backgroundColor:"white",borderRadius:"10px",  padding:" 5% 15% 5% 15%",textAlign:"center",fontSize:"16px",width:"90%",display:"flex",border:dispenceID===tableData[cellInfo.index].id&&"2px solid var(--dark-teal)" }}>
      {tableData[cellInfo.index][cellInfo.column.id]}
      <i
              style={{ color: "#75759c",float:"right",fontSize:"18px",marginTop:"7%"}}
              className="fas fa-info-circle"
              onClick={() => {
                handleDispense(tableData[cellInfo.index].dispensing_count,
                  tableData[cellInfo.index].remaining_count,tableData[cellInfo.index].id,
                  cellInfo.index,
                  tableData[cellInfo.index].dispensing_history,
                  tableData[cellInfo.index].dispatched_count);
               }}
            ></i>
    </span>
   
    </div>
    )
  }
  const handleDispense =(item,x,id,index,data,dispatched) =>{
    setOrderedCount(item)
    setDispenceCount(x);
    setDispenceID(id);
    setEditingCol(index)
    setDisHistory(data);
    setDispatched(dispatched)
    setDispenseShow(true);
    
    
    }

  const InfoPopup = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
              <div  style={{width:"100%", display:"flex"}}>
              
                <img  style={{width:"60px"}}  src="https://svpapidev.cianlogic.com/media/utils/logo/svpr_logo_1_ll8Z7pY.png" alt="Logo"/>
                        
                
                  <h4 style={{marginLeft:"35%"}}>Preview</h4>
              
               
                <h4 style={{marginLeft:"30%",fontSize:"16px"}}> {(new Date()+"").slice(4,16)}</h4>
               
              </div>
           
            </Modal.Header>
            <Modal.Body>
          <FormService medData={tableData} item="med"/>
              
            </Modal.Body>
        </Modal>
    );
  };
  
  const filterDataDate = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
      // Convert filterValue to the desired format (2023-10-05)


let data= [];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&date=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        
     
        data = response.data.requests
        if (data.length>0){
     
            setTableData(data);
            
            
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
       setTotalRows1(response.data.total_records)
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
    
    });
  };
  const filterDataPatient = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&patient_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        setLoading(false);
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
       setTotalRows1(response.data.total_records)
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataDoctor = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&status=pending&page=1&per_page=20&${TZ }&current_date=${formattedDate}&doctor_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        setLoading(false);
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
       setTotalRows1(response.data.total_records)
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const renderService = (cellInfo) => {

    return (

      <div > <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {tableData[cellInfo.index][cellInfo.column.id]}
      </span>
     
      </div>

    );
  }
  const renderServiceName = (cellInfo) => {

    return (

      <div > <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {tableData[cellInfo.index][cellInfo.column.id]} 
     
          
      </span>
   
      </div>

    );
  }
  const renderTableDate = (cellInfo) => {
    const datetimeString = tableData[cellInfo.index][cellInfo.column.id];
    const dateParts = datetimeString.split(' ')[0].split('-'); // Split by space and then by hyphen
    const formattedDate = dateParts.join('-'); // Join the date parts with hyphens
  
    return (
      <div >
        <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {formattedDate}
        </span>
      </div>
    );
  };

  const handleClose =() =>{
    setDisplayIndex(null)
  }
  const handleSelectAssignee = (assignee, cellInfo) => {
    setSelectedAssignees(prevState => ({
        ...prevState,
        [cellInfo.index]: assignee.name
    }));
    setAssignId(assignee.id)
    setSearch(assignee.name)
    setDisplayIndex(null);
};



const columns1 = [
   
    {
      Header: "Id",
      accessor: "request_pid",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 50,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataID(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },

    {
      Header: "Consumables",
      accessor: "request_items",
      Cell: renderServiceName,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 150,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataName(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Date",
      accessor: "date",
      Cell: renderTableDate,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 100,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataDate(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      
      
      
      
    
      
 
  

    },
    {
      Header: "Qty",
      accessor: "quantity",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 80,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataQuantity(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
     

    },
    {
      Header: "Patient",
      accessor: "patient",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 150,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataPatient(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Doctor",
      accessor: "doctor",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 150,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataDoctor(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
    },
    {
      Header: "Nurse",
      accessor: "nurse",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 140,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataNurse(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Ward",
      accessor: "admit_details",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 140,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataBed(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: <div>
      <span data-tooltip-id="name-tooltip1" 
        data-tooltip-content="Dispense Count" >Dispense Count</span>
    
      <Tooltip id="name-tooltip1" place="bottom" style={{ zIndex:"1000000", color: "white" }}/>
    </div>,
      accessor: "remaining_count",
      Cell: renderDispenceCount,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

    
      width: 100,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
            filterData5(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: wardSearch&& <button onClick={() => {
        setShow(true)
  }}  style={{border: "1px solid #00657c",padding:"2%",
      borderRadius:"20px",
      textDecoration: "none",
      curser: "pointer",
      color: "rgb(42, 99, 121)"}}>&nbsp;Export&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M216 112v96a16 16 0 0 1-16 16H56a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h24a8 8 0 0 1 0 16H56v96h144v-96h-24a8 8 0 0 1 0-16h24a16 16 0 0 1 16 16M93.66 69.66L120 43.31V136a8 8 0 0 0 16 0V43.31l26.34 26.35a8 8 0 0 0 11.32-11.32l-40-40a8 8 0 0 0-11.32 0l-40 40a8 8 0 0 0 11.32 11.32"/></svg></button>,
     
     
      Cell: renderRemarks,
      
     
      sortable: false,
      filterable: false,
      minWidth: 50,
      width: 250
    },
 ]
 const displayTable1 = (

   <ReactTable
        data={tableData}
        columns={columns1}
        resizable
        className="service-req-table"
        defaultPageSize={perPage}
        showPagination={tableData.length>0}
        pages={Math.ceil(totalRows / perPage)}
        page={page-1}
        onPageChange={(pageIndex) => { handlePageChange(pageIndex); }}
        onPageSizeChange={(newPerPage) => { setPerPage(newPerPage); setPage(0); }}
        pageSizeOptions={[5, 10, 20, 50, totalRows]}
      />
 )
  return(
        <div  style={{marginBottom:"2%"}}>

         {displayTable1}

         {errorMsg !== '' ?
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg('');
            setRerender(!reRender)
          }}

        /> : ''
      }

      {submitMsg !== '' ?
        <SuccessPopup
          show={successSubmit}
          msg={submitMsg}
          onHide={() => {
            setSuccessSubmit(false);
            setSubmitMsg('');

            setRerender(!reRender)
          }}

        /> : ''}
          {
        modalShow ?
          <AddPopup
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          /> : null
      }





      {
        editShow ?
          <EditPopup
            show={editShow}
            onHide={() => {
              setEditShow(false)
            }}
            onSave={() => {
              if (sendData !== tableData[cell].remarks) {
                const data = tableData
                data[cell].remarks = sendData
                setTableData(data)
                setBackup(sendData)
                setEditShow(false);
                setRender(!render);
                setUpdateSuccess(true)

              }
            }} /> : null
      }



      {
        updateSuccess ?
          <UpdatePopup
            show={updateSuccess}
            onHide={() => {
              setUpdateSuccess(false);
            }}
          /> : null
      }
      {dispenseShow ? (
          <DispenseAddPopup
            show={dispenseShow}
            seteditDispence={dispenceCount}
            onHide={() => {
              setDispenseShow(false);
            }}
          />
        ) : null}
        {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}
   
        </div>
    )
}
export default Consumables
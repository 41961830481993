import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "../BDO/addbdo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../BDO/dob.css";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { APIURL,TZ } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";
let isValid = true;
function AddVisitor(props) {
  const alert = useAlert();
  const [visitorDatas, setVisitorDatas] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    code: "",
    email: "",
    country: "Malta",
    familyrelation: { value: '', label: '' },
    state: "",
    username: "",
    password:"",
    address:"",
    dob: "",
    gender: "",
  })

  const [error, setError] = useState({
   
  });
  const inputRefs = useRef([])
  //const [dobValue, setDobValue] = useState();
  const [dobValue, setDobValue] = useState();

  const [errorMsg, setErrorMsg] = useState("");

  const [, setFormattedDob] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [errorShow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  //const history = useHistory();
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
       
      if (index !== 6 ) {
      e.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
    }
  };
  
  const togglePasswordVisiblity = (e) =>
  {
  
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const relation = [{ value:'son', label:'SON' },                       
  { value:'daughter', label:'DAUGHETR' },
  { value:'cousin', label:'COUSIN' },
  { value:'brother', label:'BROTHER' },
  { value:'sister', label:'SISTER' },
  { value:'father', label:'FATHER' },
  { value:'mother', label:'MOTHER' },
  { value:'nephew', label:'NEPHEW' },
  { value:'uncle', label:'UNCLE' },
  { value:'aunt', label:'AUNT' },
  { value:'friend', label:'FRIEND' },
  { value:'husband', label:'HUSBAND' },
  { value:'wife', label:'WIFE' },
  { value:'other', label:'OTHER' },
 
  ]

  const FamilyRelation = (data) => {
    
    setVisitorDatas(current => ({
      ...current, familyrelation: data
    }))
    setError((currentstate) => ({
      ...currentstate,
      "familyrelation": "",
    }));
  }


  function SubmitPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps">{submitMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
         
           
          
        </Modal.Footer>
      </Modal>
    );
  }

  function ErrorPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps" style={{ color: "red" }}>
            {errorMsg}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleDOB = (date) => {
    
    setDobValue(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      //let datefinal = moment(DateExtracted).format('YYYY-MM-DD')  
      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);

      setVisitorDatas((currentstate) => ({
        ...currentstate,
        dob: DateExtracted,
      }));
    }
  };

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      gender: genderOpted,
    }));
  };

  const handleSubmit = () => {
    if(isValid){

    
    
    if (rawPhone !== null && rawPhone !== "" && rawPhone !== " ") {
      setValidateRawPhoneMsg("success");
      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

        const dataToSend = {
            first_name: visitorDatas.firstname ,
            last_name:visitorDatas.lastname,
            username: visitorDatas.username,
            password: visitorDatas.password,
            phone: visitorDatas.phone,
            related_patient:props.patient,
            relation:visitorDatas.familyrelation.value,
            code: visitorDatas.code,

        }
        if (visitorDatas.state) {
            dataToSend.state = visitorDatas.state;
          }

          if (visitorDatas.country) {
            dataToSend.country = visitorDatas.country;
          }

          if (visitorDatas.email) {
            dataToSend.email = visitorDatas.email;
          }

          if (visitorDatas.dob) {
            dataToSend.dob = visitorDatas.dob;
          }
          if (visitorDatas.gender) {
            dataToSend.gender =visitorDatas.gender;
          }
          if (visitorDatas.city){
            dataToSend.city = visitorDatas.city;
          }
         
        axios
          .post(`${APIURL}/api/v1/service-provider/visitor/`, dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
             alert.success(
                "Successfully added"
              );
              
             
              props.handle();
            } else {
             
              setErrorMsg(
                res.data.message ? res.data.message : "Error in submission"
              );

              setErrorShow(true);
            }
          })
          .catch((err) => {
            setErrorMsg("Error in submission");
          });
      }
    } else {
      setValidateRawPhoneMsg("error");
    }
  }
  };

  const handleCheckEmail = (e) => {
    
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        phone: "",
      }));

      const data = {
        code: dialCode,
        mobile_number: rawPhone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                phone: "This phone number is already registered!",
              }));
              setIsValidPhone(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleContinue = async (e) => {
    
    
    e.preventDefault();

    if (validate() &&  isValidPhone || isValidEmail) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setVisitorDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));

    setError((currentstate) => ({
      ...currentstate,
      [key]: "",
    }));
  };

  const handleQualification = (e) => {
    e.preventDefault();
    const val = e.target.value;

    const key = e.target.name;
    let arr = [];
    if (val && val.includes(",")) {
      arr = val.split(",");
    } else {
      arr.push(val);
    }
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      [key]: arr,
    }));
  };

  const handleCountry = (val) => {
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      country: val,
    }));
  };

  const handleRegion = (val) => {
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
    setRawPhone(value.slice(data.dialCode.length));
    setDialCode(value.slice(0, data.dialCode.length));
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      phone: value.slice(data.dialCode.length),
      code: value.slice(0, data.dialCode.length),
    
    }));
    setError((currentstate) => ({
      ...currentstate,
      "phone": "",
    }));
  };

  const validate = () => {
    let input = visitorDatas;
    let focussed = false;
    let errors = {};
    isValid = true;
    setError({});

    if (!input["firstname"] || input["firstname"] === "") {
      isValid = false;
      errors["firstname"] = "Enter first name";
    }

    if (!input["lastname"] || input["lastname"] === "") {
      isValid = false;
      errors["lastname"] = "Enter last name";
    }

    // if (!input["email"] || input["email"] === "") {
    //   isValid = false;
    //   errors["email"] = "Enter Email";
    // }

    if (!dialCode || dialCode === "" || !rawPhone || rawPhone === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }

    if (
        visitorDatas.familyrelation.value === "undefined" ||
        visitorDatas.familyrelation.value === '' ||
        visitorDatas.familyrelation.value === null
      ) {
        
        isValid = false;
        errors["familyrelation"] = "Select relationship";
      }

      if (input["email"] !== "undefined" && input["email"] !== "") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Enter a valid email address.";
        }
      }
      
    // if (typeof input["department"] !== "undefined") {
    //   var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if(pattern2.test(input["department"])) {
    //     isValid = false;
    //     errors["specialization"] = "Special Characters are not allowed";
    //   }
    // }
// 
   
    if (input["username"] !== "undefined") {
      var pattern1 = new RegExp(/^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){0,18}[a-zA-Z0-9]$/);
      if (!pattern1.test(input["username"]) || input["username"].length < 6) {
        isValid = false;
        errors["username"] =
          'Special Characters and spaces are not allowed in the username. The character limit is 6-20.';
      }
    }
    

    if (
        input["password"].trim() === null ||
        typeof input["password"] === "undefined" ||
        input["password"].trim() === "" ||
        input["password"].trim() === null
      ) {
        isValid = false;
        errors["password"] = "Enter your password";
  
      }
 

    if ( input["password"] !== "undefined") {
        let pattern = new RegExp(
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!#^%*?&])([a-zA-Z0-9@$#^!%*?&]{8,})$/
        );
        if (!pattern.test(input["password"])) {
          isValid = false;
          errors["password"]=
            'Password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character(@$!#^%*?&) and must not include spaces';
          
        }
      }
    //   if (input["password"].length > 0 && input["password"].length < 8) {
    //     isValid = false;
    //     errors["password"] = "Password must contain atleast 8 characters";
    // }
 
    if (input["firstname"] !== "undefined") {
      var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern1.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }

    if (input["firstname"] && typeof input["firstname"] !== "undefined") {
      if (input["firstname"].length > 0 && input["firstname"].length < 3) {
        isValid = false;
        errors["firstname"] = "Must contain atleast 3 characters";
      }
    }

    if (input["lastname"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern2.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }

    if (rawPhone.length > 0 && rawPhone.length < 10) {
      isValid = false;
      errors["phone"] = "Check the phone number ";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };


  return (
    <div className="add-dr-page">
 

      <div className="add-dr-form-holder">

     
        <form className="add-dr-form" onSubmit={handleContinue} style={{width:"80%"}}>
          <h4 className="title-of-page"> Add Family Member</h4>
          <div className="add-dr-div">
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* First Name: </label>
              <input
                ref={el => inputRefs.current[0] = el} 
                onKeyDown ={(e) =>handleKeyDown(e,0)}
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="firstname"
                onChange={handleChange}
              />
              {error.firstname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.firstname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Last Name: </label>

              <input
                ref={el => inputRefs.current[1] = el} 
                onKeyDown ={(e) =>handleKeyDown(e,1)}
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="lastname"
                onChange={handleChange}
              />
              {error.lastname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.lastname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className=""> Email Address: </label>

              <input
                ref={el => inputRefs.current[2] = el} 
                onKeyDown ={(e) =>handleKeyDown(e,2)}
                className="form-control "
                type="email"
                maxLength="50"
                placeholder=""
                name="email"
                onChange={handleChange}
                onBlur={handleCheckEmail}
              />
              {error.email ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.email}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row removemargin_phone">
              <label className="">* Phone: </label>

              <PhoneInput
                inputProps={{
                  ref: el => inputRefs.current[3] = el, // Assuming inputRefs is a useRef initialized as useRef([])
                  tabIndex: 0,
                  onKeyDown: e => handleKeyDown(e, 3),
                }}
                country={"mt"}
                onlyCountries={["mt"]}
                countryCodeEditable={false}
                onChange={handlePhoneInput}
                value={visitorDatas.code + visitorDatas.phone}
                onBlur={handleCheckExistingPhone}
              />
              {error.phone ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.phone}
                </div>
              ) : null}              
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className="">* User Name: </label>

              <input
                ref={el => inputRefs.current[4] = el} 
                onKeyDown ={(e) =>handleKeyDown(e,4)}
                className="form-control"
                type="text"
                maxLength="150"
                placeholder=""
                name="username"
                onChange={handleChange}
              />
              {error.username ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.username}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Password: </label>
 <div className="form-control">
              <input
                ref={el => inputRefs.current[5] = el} 
                onKeyDown ={(e) =>handleKeyDown(e,5)}
                style={{backgroundColor:"#f5f5f5",border:"none",width:"90%",height:"100%"}}
                //type="password"
                maxLength="150"
                placeholder=""
                name="password"
                type={passwordType}
               
                onChange={handleChange}
              />   <span style={{}}>
           
              { passwordType==="password"? <i  onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> :<i onClick={togglePasswordVisiblity} className="fa fa-eye"></i> }
              </span>
     </div>
              {error.password ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.password}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className=""> *Relationship: </label>

              <Select
                           value={visitorDatas.familyrelation}
                            onChange={(data,e)=>{FamilyRelation(data,e)
                            }}
                            options={relation}
                            name='familyrelation'
                            className="select-currency"
                        /> 
              {error.familyrelation ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.familyrelation}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className="">  Location/ City : </label>

              <input
                ref={el => inputRefs.current[6] = el} 
                onKeyDown ={(e) =>handleKeyDown(e,6)}
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="city"
                onChange={handleChange}
                
              />
            
            </div>

            <div className="form-group dob-check col-lg-6">
              {/* <div className="dob-container form-control"> */}
                <span>Date of Birth  : </span>
                <DatePicker
                onChange={date => { handleDOB(date) }}

                maxDate={new Date()}
                value={dobValue}

              />
              {/* </div> */}
        
            </div>

            <div className="form-group gender-radio-check col-lg-6">
              <div className="form-control">
                <span>Gender:</span>&nbsp;
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  checked={visitorDatas.gender === "male"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="male">Male</label>

                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  checked={visitorDatas.gender === "female"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="female">Female</label>
                <input
                  type="radio"
                  id="other"
                  name="gender"
                  value="others"
                  checked={visitorDatas.gender === "others"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="other">Other</label>
              </div>
           
            </div>

            <div className="form-group col-lg-6 country-selector-dr">
              <label className="">Country: </label>
                <input
                  className="form-control"
                  type="text"
                  value={visitorDatas.country}
                  maxLength="150"
                  disabled={true}
                />
              {/* <CountryDropdown
               disabled={true}
              
                value={bdoDatas.country}
                onChange={(val) => handleCountry(val)}
              /> */}
           
            </div>
            <div className="form-group col-lg-6 region-selector-dr">
              <label className="">State: </label>

              <RegionDropdown
              defaultOptionLabel={'Select Region'}
                country={visitorDatas.country}
                value={visitorDatas.state}
                onChange={(val) => handleRegion(val)}
              />
           
             
             
            </div>
          </div>
          <div className="form-button">
          <button type="submit" className="btn btn-primary btn-col">
            {" "}
            Submit Data
          </button>{" "}
          </div>
          <br />
          <br />
          <h6 className="text-muted">All fields marked * are mandatory</h6>
        </form>

        {submitMsg !== "" ? (
          <SubmitPopUp
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setSubmitMsg("");
            }}
          />
        ) : null}

        {errorMsg !== "" ? (
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
              setErrorMsg("");
            }}
          />
        ) : null}

        {/************************Unused***************
    {(() => {
      switch(submitMsg){
      case "success":
      return   (
          <div>
            <h5 style={{ color: "green" }}> Data submitted sucessfully !!</h5>{" "}

            


            <br />
            <br />
           
          
            
          </div>
        );

        case "error": 
        return (
          <h5 style={{ color: "red" }}>
            
            Error in data submission.Please check again!
          </h5>
        );
        default: 
        return "";
           }
      })()}

    *************************/}

        {validateRawPhoneMsg === "error" ? (
          <h5 style={{ color: "red" }}>Phone number field is mandatory!</h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddVisitor;

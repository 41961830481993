import React, { useCallback, useState, useEffect, useRef } from "react";
import axios from "axios";
import "./como.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { APIURL,TZ } from "../../../Global";
//import TargetSet from "../TargetSet/TargetSet";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import StageAdd from "./StageAdd";
import DurationAdd from "./DurationAdd";
import DiseaseAdd from "./DiseaseAddComo";
import { updateLocale } from "moment";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import c
import Card from "react-bootstrap/Card";
import { useParams } from "react-router";
import { useAlert } from "react-alert";

function Comorbidities(props) {
  // 
  const id = props.id;
  const [newValue, setNewValue] = useState("");
  const [medCondition, setMedCondition] = useState("");
  const [, setNewMedCondition] = useState("");
  const [reRender, setReRender] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [comoDeleteShow, setComoDeleteShow] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("");
  const [submitShow, setSubmitShow] = useState("");
  const [submitShow1, setSubmitShow1] = useState("");
  const [diabeticTargetShow, setDiabeticTargetShow] = useState(false);
  const [targetShow, setTargetShow] = useState(false);
  const [displayComo, setDisplayComo] = useState(false);
  const [targetData, setTargetData] = useState([]);
  const [searchAssign, setSearchAssign] = useState([]);
  const [assignList, setAssignList] = useState([]);
  const [singleSelections, setSingleSelections] = useState([]);
  const [singleSelectionsKeyup, setSingleSelectionsKeyup] = useState("");
  const [addStage, setAddStage] = useState("");
  const [addDuration, setAddDuration] = useState("");
  const [, setGetError] = useState("");
  const [stageAddShow, setStageAddShow] = useState([]);
  const [durationAddShow, setDurationAddShow] = useState([]);
  const [diseaseAddShow, setDiseaseAddShow] = useState([]);
  const [comoValue, setComoValue] = useState([]);
  const [stage, setStage] = useState("");
  const [msg, setMsg] = useState("");
  const [msgShow, setMsgShow] = useState(false);
  const [duration, setDuration] = useState("");
  const [hideandshow, SetHideandShow] = useState(0);
  const [displaycomoValue, displaysetComoValue] = useState([]);
  const [displaycomocValueId, setdisplaysetComoValueId] = useState(0);
  const [displaystage, displaysetStage] = useState("");
  const [displayduration, displaysetDuration] = useState("");
  const [displaydisease, displaysetDisease] = useState("");
  const [editStage, setEditStage] = useState("");
  const [editDuration, setEditDuration] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [disableSaveStageAndDuration, setDisableSaveStageAndDuration] = useState(false);
  const alert = useAlert();
  const inputRefs = useRef([])
  const saveButtonRef = useRef(null)
  const comoDelRef = useRef(null)
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {  
      e.preventDefault()
      if(index === 2 ) {
        saveButtonRef.current.click();
      }else{
        e.preventDefault();    
        const nextIndex = index + 1;
        if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
          inputRefs.current[nextIndex].focus();
        }
    }
    }else if (e.altKey && e.key === 's') {
      saveButtonRef.current.click(); 
    }
  };
  
  useEffect(() => {
    
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && comoDelRef.current) {
        e.preventDefault()
        comoDelRef.current.click();
      }else if ((e.altKey && e.key === 's') && comoDelRef.current) {
        comoDelRef.current.click(); 
      }else if ((e.altKey && e.key === 'c') && comoDelRef.current) {
        setComoDeleteShow(false) 
      }else if(e.altKey && e.key === 'c'){
        setStageAddShow(false)
        setDurationAddShow(false)
      }
    };
    
    document.addEventListener("keydown", handleKeyDown);
    
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
    
  }, []);
  
  // Add disease details-----------------------------------
  const [addDiseaseDisable, setAddDiseaseDisable] = useState(false);
  useEffect(() => {
    if (singleSelections.length > 0) {
      setAddDiseaseDisable(true);
    }
  }, []);
  //-----------------------------confirmation-----------------------------------
  const submit = (id) => {

    // confirmAlert({
    //   title: "Confirm to Delete the Disease",
    //   message: "Are you sure you want to delete the Disease ",
    //   closeOnEscape: true,
    //   closeOnClickOutside: true,
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
    if (displaycomocValueId > 0) {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");

      axios
        .delete(
          `${APIURL}/api/v1/doctor/comorbidities-details/${displaycomocValueId}/`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.status == 204) {

            setRefresh(!refresh);

            //setSingleSelections([]);
            //setSingleSelectionsKeyup("");
            // setSingleSelections("");
            setDuration("");
            setStage("");
            // setSubmitMsg("Deleted Successfully");
            //setSubmitShow(true);

            // handleTargets();
            setSubmitShow(false)
            setComoDeleteShow(false);


          } else {
            setErrorMsg(res.data.message);
            setErrorSubmit(true);
            setReRender(!reRender);
          }
        })
        .catch((err) => {
          setSubmitMsg("error");

          setErrorSubmit(true);
          setReRender(!reRender);
        });

    }


  }

  // {
  //   label: "No",
  // },

  // setSubmitMsg("Deleted Successfully");
  // setSubmitShow(true)



  //--------------------------------------------------------------------------------

  useEffect(() => {
    disalyLatest();
  }, [refresh]);
  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/comorbidities/?search=${searchAssign}&${TZ}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setAssignList(res.data);
        } else {
          setGetError(res.data.message);
        }
      })
      .catch((err) => {
        setGetError("Error in fetching data");
      });
  }, [searchAssign]);

  const handleKeyPress = useCallback((e) => {

    if (e != "" && e != null) {
      setSingleSelectionsKeyup(e);
      setSingleSelections([]);
    } else {
      setSingleSelections([]);
    }
  });

  const handlesetStage = (e) => {
    setStage(e.target.value);
  };
  const handleSetDuration = (e) => {
    if(e.target.value>0){
      setDuration(e.target.value);
    }
    else{
      setDuration("");
    }
    
  };
  // change event of type head diseases
  const setOnChangeTypeaheadDisease = (e) => {


    if (e != null && e != undefined) {


      setSingleSelections(e);


    }
  };

  useEffect(() => {
    if (singleSelections.length > 0) {
      setAddDiseaseDisable(true);
    } else {
      setAddDiseaseDisable(false);
    }
  }, [singleSelections, singleSelectionsKeyup]);
  useEffect(() => {
    if (displaycomocValueId > 0) {
      setDisableSaveStageAndDuration(false);
    } else {
      setDisableSaveStageAndDuration(true);
    }
  }, [displaycomocValueId]);

  // add new disease
 
  const handleUpdateStageAndDuration = (e) => {
    if (e == "Save") {
      Save();
    } else {
      Update();
    }
  };

  const Update = () => {

    if (displaycomocValueId > 0) {
      if (stage != null && stage != undefined && stage != "" && duration > 0) {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const dataToSend = {
          stage: stage,
          duration: duration,
        };
        axios
          .put(
            `${APIURL}/api/v1/doctor/comorbidities-details/${displaycomocValueId}/`,
            dataToSend,
            {
              headers: {
                Authorization: "Token " + str,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {

              //setSubmitMsg(res.data.message);
              //setSubmitShow(true);
              setSingleSelections([]);
              setSingleSelectionsKeyup("");
              setSingleSelections("");
              setDuration(0);
              setStage("");

              displaysetComoValue(res.data.message.disease);
              displaysetStage(res.data.message.stage);
              displaysetDuration(res.data.message.duration);
            } else {
              setErrorMsg(res.data.message);
              setErrorSubmit(true);
              setReRender(!reRender);
            }
          })
          .catch((err) => {
            setSubmitMsg("error");

            setErrorSubmit(true);
            setReRender(!reRender);
          });
      } else {
        setErrorMsg("Enter stage and duration");
        setErrorSubmit(true);
      }
    } else {
      setErrorMsg("Please Enter ");
    }
  };

  const Save = () => {

    // if (singleSelections.length == 0 && stage == "" && duration == "") {
    //   setErrorMsg('Please add a disease here!!');
    //   setErrorSubmit(true);
    // }
    if (singleSelections.length > 0) {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");
      const dataToSend = {
        patient: id,
        comorbidity: singleSelections[0].comorbidity_name,
        stage: stage,
        duration: duration,
      };

      axios
        .post(`${APIURL}/api/v1/doctor/comorbidities-list/?patient_id=${id}`, dataToSend, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.status == 200) {

              setRefresh(!refresh); 
              setDuration(0);
              setStage("");
              handleTargets();
              setSingleSelections([]);
              setSingleSelections("");
              setSingleSelectionsKeyup("");            
              handleTargets();
              alert.success("Comorbidity added successfullly");
            //  setMsg("Comorbidity added successfully");
            //  setMsgShow(true);
            // setSubmitMsg(res.data.message);

            //setDiabeticTargetShow(true);
           
            //setReRender(!reRender);

            if (singleSelections[0].comorbidity == "Diabetic") {


              //setSubmitShow1(true);

            }

          } else {
            setErrorMsg(res.data.message);
            setErrorSubmit(true);

          }
        })
        .catch((err) => {
          // setErrorMsg('Please check all input fields!!');

          // setErrorSubmit(true);
          alert.error("Please check all input fields")
          setReRender(!reRender);

        });


    } else {
      if (singleSelectionsKeyup != "") {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const dataToSend = {
          patient: id,
          comorbidity: singleSelectionsKeyup,
          stage: stage,
          duration: duration,
        };

        axios
          .post(`${APIURL}/api/v1/doctor/comorbidities-list/?patient_id=${id}`, dataToSend, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              alert.success("Comorbidity added successfullly");
              setRefresh(!refresh); 
              setDuration(0);
              setStage("");
              handleTargets();
              setSingleSelections([]);
              setSingleSelections("");
              setSingleSelectionsKeyup("");
            
              handleTargets();
              // setSubmitMsg(res.data.message);

          

            } else {
              setErrorMsg(res.data.message);
              setErrorSubmit(true);
              setReRender(!reRender);
            }
          })
          .catch((err) => {
            // setErrorMsg('Please check all input fields!!');

            // setErrorSubmit(true);
            alert.error("Please check all input fields")
            setReRender(!reRender);
          });
      } else {
      }
    }


  };

  const handleTargets = () => {
    disalyLatest();
    setDiseaseAddShow(false);

  };
  const disalyLatest = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/comorbidities-list/?patient_id=${id}&${TZ}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })

      .then((res) => {
        if (res.status == 200) {
          setComoValue(res.data.message)
          displaysetComoValue("");
          setdisplaysetComoValueId(0);
          displaysetStage("");
          displaysetDuration("");


          if (res.data.message.length > 0) {

            const count = res.data.message.length - 1;
            displaysetComoValue(res.data.message[count].comorbidity);
            setdisplaysetComoValueId(res.data.message[count].id);
            if (
              res.data.message[count].stage != "" &&
              res.data.message[count].stage != null
            ) {
              displaysetStage(res.data.message[count].stage);
            }

            if (
              res.data.message[count].duration != "" &&
              res.data.message[count].duration != null
            ) {
              displaysetDuration(res.data.message[count].duration);
            }
          }
        } else {
        }

      })
      .catch((err) => { });
    setSubmitShow1(false)
    setStageAddShow(false);
    setDurationAddShow(false);
    setDiseaseAddShow(false);
  };

  const handleStageAdd = (id,stage) => {
    setdisplaysetComoValueId(id)
    displaysetStage(stage)
    setStageAddShow(true);
  };

  const handleDurationAdd = (id,duration) => {
    setdisplaysetComoValueId(id)
    displaysetDuration(duration)
    setDurationAddShow(true);
  };

  const handleDiseaseAdd = () => {
    //setDiseaseAddShow(true);
    //handleTargets();

    setDiseaseAddShow(true);

  };

  const handleDiseaseClose = () => {
    //setDiseaseAddShow(true);
    //handleTargets();
     setRefresh(!refresh)
    setDiseaseAddShow(false);

  };



  const TargetPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <TargetSet {...props} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success"> {submitMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {errorMsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const StageAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StageAdd
            id={displaycomocValueId}
            duration={displayduration}
            submitstage={submitStageHandle}
            setEditStage={displaystage}
          />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  };

  const submitStageHandle = () => {
    setAddStage(false);
    setStageAddShow(false);
    setRefresh(!refresh)
  };

  const submitDurationHandle = () => {
    setAddDuration(false);
    setDurationAddShow(false);
    setRefresh(!refresh);
  };

  const handleDelete = (id) => {
    setdisplaysetComoValueId(id)
    setComoDeleteShow(true)
  }

  const DurationAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DurationAdd
            id={displaycomocValueId}
            stage={comoValue.find(cm=>cm.id===displaycomocValueId)?.stage}
            submitduration={submitDurationHandle}
            setEditDuration={displayduration}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const DiseaseAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DiseaseAdd
            id={id}
            stage={displaystage}
            onHide={handleDiseaseClose}
            submitduration={submitDurationHandle}

          />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  };
  const DeletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
         
        </Modal.Body>
        <Modal.Footer>
          <Button ref={comoDelRef} variant="danger" onClick={() => {

            submit(props.id);
          }}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success"> Added Successfully !!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SuccessPop = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}>
            {msg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DiabeticTargetsPopup = (props) => {

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Proceed to set the following Targets : </h5>
          {targetData.map((item, index) => {
            return (
              <>
                <span className="text-muted font-weight-bold">
                  {index + 1}. {item.vital_name}
                </span>
                <br />
              </>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.onHide}>
            Not Now
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onCall}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const retunButton = (props) => {
    let value = "";
    if (singleSelections.length > 0) {
      value = singleSelections[0].comorbidity;
    } else {
      value = "";
    }
    let text = "Save";
    if (displaycomocValueId > 0) {
      text = "Update";
    } else {
      text = "Save";
    }

    return (
      <div className="form-button">
        <button
        ref={saveButtonRef}
          type="submit"
          className="btn btn-smallest btn-square btn-primary btn-col btn-col "
          // disabled={disableSaveStageAndDuration}
         
          onClick={() =>{if((singleSelections.length>0||singleSelectionsKeyup != "")&&stage!==""&&duration!==""){ handleUpdateStageAndDuration(text)}
          // else{setErrorMsg("Enter all fields");setErrorSubmit(true)}
          else{alert.error("Enter all fields")}}}
        >
          {" "}
          {text}{" "}
        </button>
      </div>
    );
  };

  const saveDisease = () => {
    if (displaycomocValueId > 0) {
      return (
        <div style={{ width: "100%",overflowY: "scroll",height:"350px" }}>
          <div className="form-button" style={{paddingRight:"5%",paddingBottom:"3%",paddingTop:"3%"}}>
          <button className="btn btn-primary btn-col btn-col btn-smallest btn-square mb-3"
  style={{
    float: "right",
    width:"50px",
    marginTop:"20px",
  }}
  onClick={handleDiseaseAdd}
  >+
</button>

          </div>
          <div className="" >
            <div className='list-item ' style={{Height:"10px" }}>
                    <div class="row" style={{ textAlign: "left",}}>
                    <div class="col-4 primary-disease-label" >
                        <b>Name</b>
                      </div>
                      <div class="col-3 primary-disease-label" >
                        <b>Stage</b>
                      </div>
                      <div class="col-3 primary-disease-label" >
                        <b>Duration</b>
                      </div>
                      <div class="col-1" >
                       
                      </div>
                      </div>
                      
              {comoValue.map((item) => {
                return <>
                  <div className='list-item ' style={{ marginBottom: "0px", marginTop: "10px",boxShadow:"none",borderBottom:"1px solid #e4dd9f",borderRadius:"0px", paddingLeft:"0px" }}>
                    <div class="row" style={{ textAlign: "left",}} >
                      <div class="col-4" style={{ color: "#E985E3", textAlign: "left", }}>
                        {item.comorbidity}
                      </div>
                      <div class="col-3"style={{color:"black"}}>
                        {item.stage} <i
                          style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                          onClick={() =>handleStageAdd(item.id, item.stage)}
                         
                          className="fas fa-edit iconStyle"
                        ></i>
                      </div>
                      <div class="col-3" style={{color:"black"}}>
                        {item.duration} <i
                          style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                          onClick={() =>handleDurationAdd(item.id, item.duration)}
                          className="fas fa-edit iconStyle"
                        ></i>

                      </div>
                      <div class="col-1">
                        <i style={{ float: "right",color:"red" }}
                          onClick={() => {
                            handleDelete(item.id); }}
                          className="fas fa-trash-alt fa-lg"
                        ></i>
                      </div>
                    </div>
                  </div>
                </>
              })
              }
            </div>
            
          </div>
         
        </div>
      );
    } else {
      return (
        <div className="primary-disease">
          <br />
          <span className="primary-disease-label">
            <b>Name *: </b>
          </span>
          <br />

          <Typeahead
            ref={el => inputRefs.current[0] = el} 
            onKeyDown={(e) => handleKeyDown(e,0)}
            id="basic-typeahead-single1"
            labelKey="comorbidity_name"
            style={{ width: "100%", display: "grid" }}
            onChange={setOnChangeTypeaheadDisease}
            options={assignList}
            selected={singleSelections}
            placeholder="Add Comorbidity Name here.."
            onInputChange={handleKeyPress}
          />
          <br />

          <span className="primary-disease-label">
            <b>Stage *: </b>
          </span>
          <input
            ref={el => inputRefs.current[1] = el} 
            onKeyDown={(e) => handleKeyDown(e,1)}
            type="text"
            className="form-control"
            name="stage"
            value={stage}
            onChange={handlesetStage}
          />
          <br />
          <span className="primary-disease-label">
            <b>Duration (yrs)*: </b>
          </span>
          <input
            ref={el => inputRefs.current[2] = el} 
            onKeyDown={(e) => handleKeyDown(e,2)}
            type="number"
            min="1"
            className="form-control"
            name="duration"
            value={duration}
            placeholder="In Years"
            onChange={handleSetDuration}
          />
          <br />
          {retunButton()}
        </div>
      );
    }
  };

  return (
    <>
      <>

        {saveDisease()}
        <br />


        {submitMsg !== "" ? (
          <SubmitSuccessPopup
            show={submitShow}
            onHide={() => {
              setDiseaseAddShow(false);
              setSubmitShow(false);
              setStageAddShow(false);
              setDurationAddShow(false);
              //setReRender(!reRender);
              // if (e[0].disease_name=="Diabetic") {
              //   handleTargetsDiabetic();
              // }
            }}
          />
        ) : (
          ""
        )}

        {errorMsg !== "" ? (
          <SubmitErrorPopup
            show={errorSubmit}
            onHide={() => setErrorSubmit(false)}
          />
        ) : (
          ""
        )}

        {diabeticTargetShow ? (
          <DiabeticTargetsPopup
            show={diabeticTargetShow}
            onHide={() => {
              setDiabeticTargetShow(false);
              setDiseaseAddShow(false);
            }}
            onCall={() => {
              setDiabeticTargetShow(false);
              setTargetShow(true);
            }}
          />
        ) : (
          ""
        )}

        {targetShow ? (
          <TargetPopup
            show={targetShow}
            onHide={() => {
              setTargetShow(false);
            }}
            id={id}
            diabeticTargets={targetData}
            select={2}
          />
        ) : null}
        {submitShow1 ? (
          <SubmitPopup
            show={submitShow1}
            onHide={() => {
              setSubmitShow1(false);
              setDiseaseAddShow(false);
            }}
            id={id}
            diabeticTargets={targetData}
            select={2}
          />
        ) : null}

        {stageAddShow ? (
          <StageAddPopup
            show={stageAddShow}
            setEditStage={stage}
            onHide={() => {
              setStageAddShow(false);
            }}
          />
        ) : null}

        {durationAddShow ? (
          <DurationAddPopup
            show={durationAddShow}
            setEditDuration={comoValue.find(cm=>cm.id===displaycomocValueId)?.duration}
            onHide={() => {
              setDurationAddShow(false);
            }}
          />
        ) : null}

        {diseaseAddShow ? (
          <DiseaseAddPopup
            show={diseaseAddShow}
            onHide={() => {
              setDiseaseAddShow(false);
            }}
          />
        ) : null}

        {msgShow ? (
          <SuccessPop
            show={msgShow}
            onHide={() => {setMsgShow(false);
              
              setRefresh(!refresh); 
              setDuration(0);
              setStage("");
              handleTargets();
              setSingleSelections([]);
              setSingleSelections("");
              setSingleSelectionsKeyup("");
            
              handleTargets();}}
          />
        ) : (
          ""
        )}

        {comoDeleteShow ? (
          <DeletePopUp
            show={comoDeleteShow}
            id={displaycomocValueId}
            onHide={() => {
              setComoDeleteShow(false);
            }}
          />
        ) : null}
      </>
    </>
  );
}

export default Comorbidities;

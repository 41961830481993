import React, { useCallback, useState, useEffect, useRef} from "react";
import axios from "axios";
import "./chronic.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { APIURL,TZ } from "../../../Global";
//import TargetSet from "../TargetSet/TargetSet";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import StageAdd from "./StageAdd";
import DurationAdd from "./DurationAdd";
import DiseaseAdd from "./DiseaseAdd";
import { updateLocale } from "moment";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import c
import Card from "react-bootstrap/Card";
import { useParams } from "react-router";
import { useAlert } from "react-alert";


function Chronic(props) {
  // 
 const id = props.id;
  const [newValue, setNewValue] = useState("");
  const [medCondition, setMedCondition] = useState("");
  const [, setNewMedCondition] = useState("");
  const [reRender, setReRender] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [chronicDeleteShow, setChronicDeleteShow] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("");
  const [submitShow, setSubmitShow] = useState("");
  const [submitShow1, setSubmitShow1] = useState("");
  const [diabeticTargetShow, setDiabeticTargetShow] = useState(false);
  const [targetShow, setTargetShow] = useState(false);
  const [displayChronic, setDisplayChronic] = useState(false);
  const [targetData, setTargetData] = useState([]);
  const [searchAssign, setSearchAssign] = useState([]);
  const [assignList, setAssignList] = useState([]);
  const [singleSelections, setSingleSelections] = useState([]);
  const [singleSelectionsKeyup, setSingleSelectionsKeyup] = useState("");
  const [addStage, setAddStage] = useState("");
  const [addDuration, setAddDuration] = useState("");
  const [setGetError] = useState("");
  const [stageAddShow, setStageAddShow] = useState([]);
  const [durationAddShow, setDurationAddShow] = useState([]);
  const [diseaseAddShow, setDiseaseAddShow] = useState([]);
  const [chronicValue, setChronicValue] = useState([]);
  const [stage, setStage] = useState("");
  const [duration, setDuration] = useState("");
  const [hideandshow, SetHideandShow] = useState(0);
  const [displaychronicValue, displaysetChronicValue] = useState([]);
  const [displaychronicValueId, setdisplaysetChronicValueId] = useState(0);
  const [displaystage, displaysetStage] = useState("");
  const [displayduration, displaysetDuration] = useState("");
  const [displaydisease, displaysetDisease] = useState("");
  const [editStage, setEditStage] = useState("");
  const [editDuration, setEditDuration] = useState("");
  const[refresh,setRefresh] = useState(false);
  const [msg, setMsg] = useState("");
  const[msgShow,setMsgShow] = useState(false);
  const [disableSaveStageAndDuration, setDisableSaveStageAndDuration] = useState(false);
  const alert = useAlert();
  const inputRefs = useRef([])
  const saveButtonRef = useRef (null)
  const chronicDelRef = useRef (null)
  const disaddCloseRef = useRef (null)
  

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {  
      e.preventDefault()
      if(index === 2 ) {
        saveButtonRef.current.click();
      }else{
      e.preventDefault();    
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
    }else if (e.altKey && e.key === 's') {
      saveButtonRef.current.click(); 
    }
  };

  useEffect(() => {
    
    const handleKey = (e) => {
      if (e.key === "Enter" && chronicDelRef.current) {
        e.preventDefault()
        chronicDelRef.current.click();
      }else if ((e.altKey && e.key === 's') && chronicDelRef.current) {
        e.preventDefault()
        chronicDelRef.current.click();
      }else if ((e.altKey && e.key === 'c') && chronicDelRef.current) {
        e.preventDefault()
        setChronicDeleteShow(false);
      }else if(e.altKey && e.key === 'c'){
        setStageAddShow(false)
        setDurationAddShow(false)
      }
    };
    
    document.addEventListener("keydown", handleKey);
    
    return () => {
      document.removeEventListener("keydown", handleKey);
    }
    
  }, []);
 

  // Add disease details-----------------------------------
  const [addDiseaseDisable, setAddDiseaseDisable] = useState(false);
  useEffect(() => {
    if (singleSelections.length > 0) {
      setAddDiseaseDisable(true);
    }
  }, []);
  //-----------------------------confirmation-----------------------------------
  const submit = (id) => {
    // confirmAlert({
    //   title: "Confirm to Delete the Disease",
    //   message: "Are you sure you want to delete the Disease ",
    //   closeOnEscape: true,
    //   closeOnClickOutside: true,
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
            if (displaychronicValueId > 0) {
              const tokenString = sessionStorage.getItem("usertoken");
              let str = tokenString.replace(/["]+/g, "");

              axios
                .delete(
                  `${APIURL}/api/v1/doctor/primary-disease-details/${displaychronicValueId}/`,
                  {
                    headers: {
                      Authorization: "Token " + str,
                    },
                  }
                )
                .then((res) => {
                  if (res.status == 204) {
               
               
                   
                    //setSingleSelections([]);
                    //setSingleSelectionsKeyup("");
                   // setSingleSelections("");
                   setDuration("");
                    setStage("");
                   // setSubmitMsg("Deleted Successfully");
                    //setSubmitShow(true);
                  
                    handleTargets();
                   setSubmitShow(false)
                   setChronicDeleteShow(false);
                  
                   
                  } else {
                    setErrorMsg(res.data.message);
                    setErrorSubmit(true);
                    setReRender(!reRender);
                  }
                })
                .catch((err) => {
                  setSubmitMsg("error");

                  setErrorSubmit(true);
                  setReRender(!reRender);
                });
               
            }


          }
      
        // {
        //   label: "No",
        // },
  
    // setSubmitMsg("Deleted Successfully");
    // setSubmitShow(true)

 

  //--------------------------------------------------------------------------------
        
  useEffect(() => {
    disalyLatest();
  }, [refresh]);
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/primary-diseases/?search=${searchAssign}&${TZ}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setAssignList(res.data);
        } else {
          setGetError(res.data.message);
        }
      })
      .catch((err) => {
        setGetError("Error in fetching data");
      });
  }, [searchAssign]);

  const handleKeyPress = useCallback((e) => {
    if (e != "" && e != null) {
      setSingleSelectionsKeyup(e);
      setSingleSelections([]);
    } else {
      setSingleSelections([]);
    }
  });

  const handlesetStage = (e) => {
    setStage(e.target.value);
  };
  const handleSetDuration = (e) => {
    setDuration(e.target.value);
  };
  // change event of type head diseases
  const setOnChangeTypeaheadDisease = (e) => {
    ;
    if (e != null && e != undefined) {
     
  
        setSingleSelections(e);
      
    
    }
  };

  useEffect(() => {
    if (singleSelections.length > 0) {
      setAddDiseaseDisable(true);
    } else {
      setAddDiseaseDisable(false);
    }
  }, [singleSelections, singleSelectionsKeyup]);
  useEffect(() => {
    if (displaychronicValueId > 0) {
      setDisableSaveStageAndDuration(false);
    } else {
      setDisableSaveStageAndDuration(true);
    }
  }, [displaychronicValueId]);

  // add new disease
 
  const handleUpdateStageAndDuration = (e) => {
    if (e == "Save") {
      Save();
    } else {
      Update();
    }
  };

  const Update = () => {
    
    if (displaychronicValueId > 0) {
      if (stage != null && stage != undefined && stage != "" && duration > 0) {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const dataToSend = {
          stage: stage,
          duration: duration,
        };
        axios
          .put(
            `${APIURL}/api/v1/doctor/primary-disease-details/${displaychronicValueId}/`,
            dataToSend,
            {
              headers: {
                Authorization: "Token " + str,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
            
              //setSubmitMsg(res.data.message);
              //setSubmitShow(true);
              setSingleSelections([]);
              setSingleSelectionsKeyup("");
              setSingleSelections("");
              setDuration(0);
              setStage("");
              
              displaysetChronicValue(res.data.data.disease);
              displaysetStage(res.data.data.stage);
              displaysetDuration(res.data.data.duration);
            } else {
              setErrorMsg(res.data.message);
              setErrorSubmit(true);
              setReRender(!reRender);
            }
          })
          .catch((err) => {
            setSubmitMsg("error");

            setErrorSubmit(true);
            setReRender(!reRender);
          });
      } else {
        setErrorMsg("Enter stage and duration");
        setErrorSubmit(true);
      }
    } else {
      setErrorMsg("Please Enter ");
    }
  };
  const SuccessPop = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}>
          {msg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-smallest btn-square btn-primary btn-col btn-col " onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const Save = () => {
    
    // if(singleSelections.length==0 && stage=="" && duration==""){
    //   setErrorMsg('Please add a disease here!!');
    //   setErrorSubmit(true);
    // }
    if (singleSelections.length > 0) {
      const tokenString = sessionStorage.getItem("usertoken");
      let str = tokenString.replace(/["]+/g, "");
      const dataToSend = {
        patient: id,
        disease: singleSelections[0].disease_name,
        stage: stage,
        duration: duration,
      };

      axios
        .post(`${APIURL}/api/v1/doctor/primary-disease-list/`, dataToSend, {
          headers: {
            Authorization: "Token " + str,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            alert.success("Disease added successfully"); 
            setRefresh(!refresh); setSingleSelections([]);
            setSingleSelections("");
            setSingleSelectionsKeyup("");
            handleTargets();            
            setDuration(0);
            setStage(""); 
            
           
            
            if (singleSelections[0].disease_name=="Diabetic") {
               
              handleTargetsDiabetic();
              //setSubmitShow1(true);
            
            }
    
          } else {
            alert.error(res.data.message);
            // setErrorSubmit(true);
           
          }
        })
        .catch((err) => {
          setErrorMsg('Please check all input fields!!');

          setErrorSubmit(true);
          setReRender(!reRender);
        });
       
       
    } else {
      if (singleSelectionsKeyup != "") {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const dataToSend = {
          patient: id,
          disease: singleSelectionsKeyup,
          stage: stage,
          duration: duration,
        };

        axios
          .post(`${APIURL}/api/v1/doctor/primary-disease-list/`, dataToSend, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              alert.success("Disease added successfully"); 
            setRefresh(!refresh); 
              
            } else {
              alert.error(res.data.message);
              
              setReRender(!reRender);
            }
          })
          .catch((err) => {
            setErrorMsg('Please check all input fields!!');

            setErrorSubmit(true);
            setReRender(!reRender);
          });
      } else {
      }
    }
   

  };

  const handleTargets = () => {
    disalyLatest();
    setDiseaseAddShow(false);
  
  };
  const disalyLatest = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/primary-disease-list/?patient_id=${id}&${TZ}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })

      .then((res) => {
        if (res.status == 200) {
         
          displaysetChronicValue("");
          setdisplaysetChronicValueId(0);
          displaysetStage("");
          displaysetDuration("");
         

          if (res.data.message.length > 0) {

            const count = res.data.message.length - 1;
            displaysetChronicValue(res.data.message[count].disease);
            setdisplaysetChronicValueId(res.data.message[count].id);
            if (
              res.data.message[count].stage != "" &&
              res.data.message[count].stage != null
            ) {
              displaysetStage(res.data.message[count].stage);
            }

            if (
              res.data.message[count].duration != "" &&
              res.data.message[count].duration != null
            ) {
              displaysetDuration(res.data.message[count].duration);
            }
          }
        } else {
        }
        
      })
      .catch((err) => {});
      setSubmitShow1(false)
    setStageAddShow(false);
    setDurationAddShow(false);
    setDiseaseAddShow(false);
  };

  const handleStageAdd = () => {
   // handleTargets();
    setStageAddShow(true);
  };

  const handleDurationAdd = () => {
  //  handleTargets();
    setDurationAddShow(true);
  };

  const handleDiseaseAdd = () => {
    //setDiseaseAddShow(true);
   //handleTargets();
    
    setDiseaseAddShow(true);
  
  };

  const handleDiseaseClose = () => {
    //setDiseaseAddShow(true);
   //handleTargets();
    setRefresh(!refresh)
    setDiseaseAddShow(false);
  
  };

  const handleTargetsDiabetic = () => {
    // const tokenString = sessionStorage.getItem("usertoken");

    // let str = tokenString.replace(/["]+/g, "");

    // axios
    //   .get(
    //     `${APIURL}/api/v1/patient/targets-for-patient-type/?patient_type=t2_diabetic`,
    //     {
    //       headers: {
    //         Authorization: "Token " + str,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     if (res.data.status === "success") {
    //       setTargetData(res.data.target_vitals);
    //       setDiabeticTargetShow(true);
    //     } else {
    //     }
    //   })
    //   .catch((err) => {});
  };
  const TargetPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <TargetSet {...props} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success"> {submitMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-smallest btn-square btn-primary btn-col btn-col " onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
          {errorMsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-smallest btn-square btn-primary btn-col btn-col " onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const StageAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StageAdd
            id={displaychronicValueId}
            duration={displayduration}
            submitstage={submitStageHandle}
            setEditStage = {displaystage}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const submitStageHandle = () => {
    setAddStage(false);
    setStageAddShow(false);
    setRefresh(!refresh)
  };

  const submitDurationHandle = () => {
    setAddDuration(false);
    setDurationAddShow(false);
    setRefresh(!refresh);
  };

  const handleDelete=(displaychronicValueId)=>
  {
    setChronicDeleteShow(true)
  }

  const DurationAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DurationAdd
            id={displaychronicValueId}
            stage={displaystage}
            submitduration={submitDurationHandle}
            setEditDuration ={displayduration}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const DiseaseAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DiseaseAdd
            id={id}
            stage={displaystage}
            onSubmit={() => { setRefresh(!refresh);setDiseaseAddShow(false);}}
            submitduration={submitDurationHandle}
          /> 
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  };
  const DeletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
         
        </Modal.Body>
        <Modal.Footer>
          <Button ref={chronicDelRef} variant="danger"  onClick={() => {
              
              submit(props.id);
            }}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success"> Added Successfully !!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-smallest btn-square btn-primary btn-col btn-col " onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DiabeticTargetsPopup = (props) => {

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Proceed to set the following Targets : </h5>
          {targetData.map((item, index) => {
            return (
              <>
                <span className="text-muted font-weight-bold">
                  {index + 1}. {item.vital_name}
                </span>
                <br />
              </>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.onHide}>
            Not Now
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onCall}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  

  const retunButton = (props) => {
    let value = "";
    if (singleSelections.length > 0) {
      value = singleSelections[0].disease_name;
    } else {
      value = "";
    }
    let text = "Save";
    if (displaychronicValueId > 0) {
      text = "Update";
    } else {
      text = "Save";
    }

    return (
      <div className="form-button">
      <button
        ref={saveButtonRef}
        type="submit"
        className="btn btn-smallest btn-square btn-primary btn-col btn-col "
        // disabled={isSaveButtonDisabled()}
        // disabled={disableSaveStageAndDuration}
        onClick={() =>{if((singleSelections.length>0||singleSelectionsKeyup != "")&&stage!==""&&duration!==""){ handleUpdateStageAndDuration(text)}
        else{alert.error("Enter all fields")}}}
      >
        {" "}
        {text}{" "}
      </button>
      </div>
    );
  };

  const saveDisease = () => {
    if (displaychronicValueId > 0) {
      return (
        <div style={{ width: "100%" }}>
          <div className="comorb-holder">
           
            <Card className="card-body3">
              <Card.Text>
              <i style={{float:"right",paddingTop:"2%",paddingRight:"7%"}}
                    onClick={() => {
              
              handleDelete(displaychronicValueId);
            }}
                    className="fas fa-times close-button-chronic fa-lg"
                  ></i>
                <div className="primary-disease1">
                 
               
                  <span className="primary-disease-label card-style-label">
                   <h6 style={{color:"black"}}> <b>Disease Name: </b>   {displaychronicValue}</h6>
                 
                  </span>
                  <br />
                  
                  <span className="stage-label">
               

                    <i
                      style={{ justifySelf: "start", color: "#3468c2",cursor:"pointer" }}
                      onClick={handleStageAdd}
                      className=""
                    ></i>
                  </span>
                  <br />
                  <span className="primary-disease-label">
                   <h6 style={{color:"black"}}> <b>Stage : </b> {displaystage}{" "} <i
                      style={{ justifySelf: "start", color: "#3468c2",cursor:"pointer" }}
                      onClick={handleStageAdd}
                      className="fas fa-edit iconStyle"
                    ></i></h6>
                  
                   
                  </span>
                  <br />
                  <br />
                  <span className="primary-disease-label">
                    <h6 style={{color:"black"}}><b>Duration (yrs): </b> {displayduration}{" "} <i
                      style={{ justifySelf: "start", color: "#3468c2",cursor:"pointer" }}
                      onClick={handleDurationAdd}
                      className="fas fa-edit iconStyle"
                    ></i> </h6>
                    
                   
                  </span>

                  <br />
                </div>
              </Card.Text>
            </Card>
            <br />
          </div>
          <br/>
          <div className="form-button">
          <button
            onClick={handleDiseaseAdd}
            className="btn btn-primary btn-col btn-col btn-smallest btn-square mb-3"
            style={{width:"50px"}}
          >
            +
          </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="primary-disease">

          <span className="primary-disease-label">
            <b>Disease Name *: </b>
          </span>
          <br />
         
          <Typeahead
            ref={el => inputRefs.current[0] = el} 
            onKeyDown={(e) => handleKeyDown(e,0)}
            id="basic-typeahead-single"
            labelKey="disease_name"
            style={{width:"100%",display: "grid"}}
            onChange={setOnChangeTypeaheadDisease}
            options={assignList}
            selected={singleSelections}
            placeholder="Add Disease Name here.."
            onInputChange={handleKeyPress}
          />
          <br />

          <span className="primary-disease-label">
            <b>Stage *: </b>
          </span>
          <input
            ref={el => inputRefs.current[1] = el} 
            onKeyDown={(e) => handleKeyDown(e,1)}
            type="text"
            className="form-control"
            name="stage"
            value={stage}
            onChange={handlesetStage}
          />
          <br />
          <span className="primary-disease-label">
            <b>Duration (yrs)*: </b>
          </span>
          <input
            ref={el => inputRefs.current[2] = el} 
            onKeyDown={(e) => handleKeyDown(e,2)}
            type="number"
            className="form-control"
            name="duration"
            value={duration}
            placeholder="In Years"
            onChange={handleSetDuration}
          />
          <br />
          {retunButton()}
        </div>
      );
    }
  };

  return (
    <>
      <>
        <br />
        {saveDisease()}
        <br />

        {/* {assignList !== "" && assignList !== null ? (
          <div className="comorb-holder">
            <table>
              <tr>
                <th className="primary-disease-table">
                  <b>Disease</b>
                </th>
                <th className="primary-disease-table">
                  <b>Stage</b>
                </th>
                <th className="primary-disease-table">
                  <b>Duration</b>
                </th>
                <th className="primary-disease-table">
                  <b>Delete</b>
                </th>
              </tr>
              <tr>
                <td> {displaychronicValue}</td>
                <td> {displaystage} <i
                      style={{ justifySelf: "start", color: "#3468c2" }}
                      onClick={handleStageAdd}
                      className="fas fa-edit iconStyle"
                    ></i></td>
                <td>{displayduration}  <i
                      style={{ justifySelf: "start", color: "#3468c2" }}
                      onClick={handleDurationAdd}
                      className="fas fa-edit iconStyle"
                    ></i></td>
                <td>
                  {}
                  <button onClick={submit}>
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  {}
                </td>
              </tr>
            </table>
          </div>
        ) : null} */}

        {submitMsg !== "" ? (
          <SubmitSuccessPopup
            show={submitShow}
            onHide={() => {
              setDiseaseAddShow(false);
              setSubmitShow(false);
              setStageAddShow(false);
              setDurationAddShow(false);
              //setReRender(!reRender);
              // if (e[0].disease_name=="Diabetic") {
              //   handleTargetsDiabetic();
              // }
            }}
          />
        ) : (
          ""
        )}

{errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : (
        ""
      )}

        {diabeticTargetShow ? (
          <DiabeticTargetsPopup
            show={diabeticTargetShow}
            onHide={() => {
              setDiabeticTargetShow(false);
              setDiseaseAddShow(false);
            }}
            onCall={() => {
              setDiabeticTargetShow(false);
              setTargetShow(true);
            }}
          />
        ) : (
          ""
        )}

        {targetShow ? (
          <TargetPopup
            show={targetShow}
            onHide={() => {
              setTargetShow(false);
            }}
            id={id}
            diabeticTargets={targetData}
            select={2}
          />
        ) : null}
         {submitShow1 ? (
          <SubmitPopup
            show={submitShow1}
            onHide={() => {
              setSubmitShow1(false);
              setDiseaseAddShow(false);
            }}
            id={id}
            diabeticTargets={targetData}
            select={2}
          />
        ) : null}

        {stageAddShow ? (
          <StageAddPopup
            show={stageAddShow}
            setEditStage={stage}
            onHide={() => {
              setStageAddShow(false);
            }}
          />
        ) : null}

        {durationAddShow ? (
          <DurationAddPopup
            show={durationAddShow}
            setEditDuration = {duration}
            onHide={() => {
              setDurationAddShow(false);
            }}
          />
        ) : null}

        {diseaseAddShow ? (
          <DiseaseAddPopup
            show={diseaseAddShow}
            onHide={() => {
              setDiseaseAddShow(false);
            }}
          />
        ) : null}

        {chronicDeleteShow ? (
          <DeletePopUp
            show={chronicDeleteShow}
            id={displaychronicValueId}
            onHide={() => {
              setChronicDeleteShow(false);
            }}
          />
        ) : null}
        {msgShow ? (
        <SuccessPop
          show={msgShow}
          onHide={() =>{setMsgShow(false);
            setRefresh(!refresh); setSingleSelections([]);
            setSingleSelections("");
            setSingleSelectionsKeyup("");
            handleTargets();            
            setDuration(0);
            setStage("");
          
          }}
        />
      ) : (
        ""
      )}
      </>
    </>
  );
}

export default Chronic;

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../BDO/addbdo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../BDO/dob.css";
import Select from 'react-select';
import DatePicker from "react-date-picker";
//import DatePicker from "react-datepicker";
import { APIURL,TZ } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";
let isValid = true;
function VisitorUpdate(props) {
 
    
  const alert = useAlert();
    const [passwordType, setPasswordType] = useState("password");

    const togglePasswordVisiblity = (e) =>
    {
    
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    const [visitorDatas, setVisitorDatas] = useState(props.data);


  const [error, setError] = useState({
   
  });

  //const [dobValue, setDobValue] = useState();
  
  const [dobValue, setDobValue] = useState(props.data.dob ? new Date(props.data.dob) : "");
  const [errorMsg, setErrorMsg] = useState("");
  const [formattedDob, setFormattedDob] = useState("");
  const [submitMsg, setSubmitMsg] = useState("");
  const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isPhoneModified, setPhoneModified] = useState(false);
  const [isEmailModified, setEmailModified] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [fname, setFname] = useState(props.data.first_name);
  const [lname, setLname] = useState(props.data.last_name);
  const value2 = props.data.relation
  ? props.data.relation.toLowerCase()
  : "";
  const label2 = props.data.relation ? props.data.relation : "";
  const [relation1, setRelation1] = useState({ value: value2, label: label2 });
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [secondNameValid, setSecondNameValid] = useState(false);
  const [CityValid, setCityValid] = useState(false);
  const [maxDataManage, setMaxDataManage] = useState(false);
  const [maxDataManage1, setMaxDataManage1] = useState(false);
  const [cityUpdated, setCityUpdated] = useState('');
  //const history = useHistory();
  const relation = [{ value:'son', label:'SON' },                       
  { value:'daughter', label:'DAUGHETR' },
  { value:'cousin', label:'COUSIN' },
  { value:'brother', label:'BROTHER' },
  { value:'sister', label:'SISTER' },
  { value:'father', label:'FATHER' },
  { value:'mother', label:'MOTHER' },
  { value:'nephew', label:'NEPHEW' },
  { value:'uncle', label:'UNCLE' },
  { value:'aunt', label:'AUNT' },
  { value:'friend', label:'FRIEND' },
  { value:'husband', label:'HUSBAND' },
  { value:'wife', label:'WIFE' },
  { value:'other', label:'OTHER' },
 
  ]

  const FamilyRelation = (data) => {
    

    setVisitorDatas(current => ({
      ...current, relation: data
    }))
  }


  function SubmitPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps">{submitMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
         
           
          
        </Modal.Footer>
      </Modal>
    );
  }

  function ErrorPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps" style={{ color: "red" }}>
            {errorMsg}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleDOB = (date) => {

    setDobValue(date);

    let dateUpdated = date;
    if (date !== null) {
      
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      //let datefinal = moment(DateExtracted).format('YYYY-MM-DD')  
      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);
      
     // setDobValue(DateExtracted);
      setVisitorDatas((currentstate) => ({
        ...currentstate,
        dob:DateExtracted,
      }));
    }
  };

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      gender: genderOpted,
    }));
  };

  const handleSubmit = () => {
    
  
   
    if (!firstNameValid && !secondNameValid ) {
      setValidateRawPhoneMsg("success");
      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");
       
        const dataToSend = {
            first_name: fname,
            last_name:lname,
            username: visitorDatas.username,
            password: visitorDatas.password,
       
            related_patient:props.patient,
            relation:visitorDatas.relation.value,
            code: visitorDatas.code,

        }
        if(dobValue !== null){
           dataToSend.dob = visitorDatas.dob;
        }else{
          dataToSend.dob = null;
        }
          if(isPhoneModified){
            dataToSend.phone = visitorDatas.phone
          }
        if (visitorDatas.state) {
            dataToSend.state = visitorDatas.state;
          }

          if (visitorDatas.country) {
            dataToSend.country = visitorDatas.country;
          }

          if (isEmailModified) {
            dataToSend.email = visitorDatas.email;
          }

          if (visitorDatas.gender) {
            dataToSend.gender = visitorDatas.gender;
          }
          if (visitorDatas.city !== ""){
            dataToSend.city = visitorDatas.city;
          }else{
            dataToSend.city = null;
          }
       
        axios
          .put(`${APIURL}/api/v1/service-provider/visitor-detail/${visitorDatas.id}/`,dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
            
             
             alert.success(
                 "Successfully updated"
              );
              
             
              props.handle();
            } else {
          
              setErrorMsg(
                res.data.message ? res.data.message : "Error in submission"
              );

              setErrorShow(true);
            }
          })
          .catch((err) => {
            setErrorMsg("Error in submission");
          });
      }
    } 
  };

  const FnameChecker = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    const pattern = new RegExp(/[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/?~]/);
    if (inputValue === '' || inputValue.length < 3) {
      setFirstNameValid(true);
    }else if(pattern.test(inputValue)){
      setFirstNameValid(true);
    }else if(inputValue.length >= 30){
      setMaxDataManage(true);
    } 
    else {
      setFname(inputValue);
      setFirstNameValid(false);
      setMaxDataManage(false);
    }setFname(inputValue);
  };
  const LnameChecker = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    const pattern = new RegExp(/[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/?~]/);
    if (inputValue === '') {
      setSecondNameValid(true);
    }else if(pattern.test(inputValue)){
      setSecondNameValid(true);
    } else if(inputValue.length >= 30){
      setMaxDataManage1(true);
    } 
    else {
      setLname(inputValue);
      setSecondNameValid(false);
      setMaxDataManage1(false);
    }setLname(inputValue);
  };

  const handleCheckEmail = (e) => {
    
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleCheckExistingPhone = (e) => {
    if(isPhoneModified){
    setIsValidPhone(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        phone: "",
      }));

      const data = {
        code: visitorDatas.code,
        mobile_number: visitorDatas.phone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                phone: "This phone number is already registered!",
              }));
              setIsValidPhone(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
    //setPhoneModified(false);
  }
  };

  const handleContinue = async (e) => {
   
    //
    
    e.preventDefault();

    if (validate() && (isValidPhone || isValidEmail)) {
      
      handleSubmit();
    }
    
  };

  const handleChange = (e) => {
    
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
    if(key === 'email'){
      setEmailModified(true);
    }
  };


  // const handleCountry = (val) => {
  //   setVisitorDatas((currentstate) => ({
  //     ...currentstate,
  //     country: val,
  //   }));
  // };

  const handleRegion = (val) => {
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
    setPhoneModified(true);
    setRawPhone(value.slice(data.dialCode.length));
    setDialCode(value.slice(0, data.dialCode.length));
    setVisitorDatas((currentstate) => ({
      ...currentstate,
      phone: value.slice(data.dialCode.length),
      code: value.slice(0, data.dialCode.length),
    
    }));
    
  };

  const validate = () => {
   
    let input = visitorDatas;
    let focussed = false;
    let errors = {};
    isValid = true;
    setError({});
    
    if (
      visitorDatas.relation === "undefined" ||
      visitorDatas.relation === '' ||
      visitorDatas.relation === null
      ) {
        isValid = false;
        errors["familyrelation"] = "Select relationship";
      }
      if(isEmailModified){
      if (input["email"] !== "undefined" && input["email"] !== "") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Enter a valid email address.";
        }
      }}

    
 
    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }
    if (visitorDatas.phone===""||visitorDatas.phone==="356") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }
    if (visitorDatas.phone.length !==10) {
      isValid = false;
      errors["phone"] = "Please check numbers ";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };
 
 
  return (
    <div className="add-dr-page">
 

      <div className="add-dr-form-holder">

     
        <form className="add-dr-form" onSubmit={handleContinue} style={{width:"80%"}}>
          <h4 className="title-of-page"> Update Details</h4>
          <div className="add-dr-div">
          <div className="form-group col-lg-6 item-left-row">
              <label className="">* First Name: </label>

              <input
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="fast_name"
                value={fname}
                onChange={FnameChecker}
              />
              {firstNameValid ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                > First name must contain atleast 3 character and Special characters are not allowed.
              
                </div>
              ) : null}
               {maxDataManage ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                > Maximum of 30 Characters allowed!.
              
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Last Name: </label>

              <input
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="last_name"
                value={lname}
                onChange={LnameChecker}
              />
              {secondNameValid ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  Last name cannot be empty! and Special characters,space are not allowed.
                </div>
              ) : null}
               {maxDataManage1 ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                > Maximum of 30 Characters allowed!.
              
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className=""> Email Address: </label>

              <input
                className="form-control "
                type="email"
                maxLength="50"
                placeholder=""
                name="email"
                value={visitorDatas.email}
                onChange={handleChange}
                onBlur={handleCheckEmail}
              />
              {error.email ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.email}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Phone: </label>

              <PhoneInput
                inputProps={{}}
                country={"mt"}
                onlyCountries={["mt"]}
                countryCodeEditable={false}
                onChange={handlePhoneInput}
                value={visitorDatas.code+visitorDatas.phone}
                onBlur={handleCheckExistingPhone}
              />
              {error.phone ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.phone}
                </div>
              ) : null}
            </div>
           
             <div className="form-group dob-check col-lg-6">
              <div className="dob-container form-control">
                <span>Date of Birth  : </span>
                <DatePicker
                onChange={date => {handleDOB(date)}}
                maxDate={new Date()}
                value= {dobValue}
         

              />
              </div>
        
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className=""> * Relationship: </label>
           
                        <Select
                           placeholder={visitorDatas.relation}
                           defaultValue={visitorDatas.relation}
                            onChange={(data,e)=>{FamilyRelation(data,e)
                            }}
                            options={relation}
                            name='familyrelation'
                            value={visitorDatas.relation}
                            //className="select-currency select-flex"
                        /> 
              {error.familyrelation ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.familyrelation}
                </div>
              ) : null}
            </div>
            <div className="form-group gender-radio-check col-lg-6">
              <div className="form-control">
                <span>Gender:</span>
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  checked={visitorDatas.gender === "male"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="male">Male</label>

                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  checked={visitorDatas.gender === "female"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="female">Female</label>
                <input
                  type="radio"
                  id="other"
                  name="gender"
                  value="others"
                  checked={visitorDatas.gender === "others"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="other">Other</label>
              </div>
           
            </div>
            <div className="form-group col-lg-6 item-left-row" style={{marginBottom:"20px"}}>
              <label className="">  Location/ City : </label>

              <input
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="city"
                value={visitorDatas.city}
                onChange={handleChange}
              />
            
            </div>
           
            <div className="form-group col-lg-6 country-selector-dr">
              <label className="">Country: </label>
                <input
                  className="form-control"
                  type="text"
                  value={visitorDatas.country}
                  maxLength="150"
                  disabled={true}
                />
              {/* <CountryDropdown
               disabled={true}
              
                value={bdoDatas.country}
                onChange={(val) => handleCountry(val)}
              /> */}
           
            </div>
            <div className="form-group col-lg-6 region-selector-dr">
              <label className="">State: </label>

              <RegionDropdown
              defaultOptionLabel={'Select Region'}
                country={visitorDatas.country}
                value={visitorDatas.state}
                onChange={(val) => handleRegion(val)}
              />
         
            </div>
          </div>
          <div className="form-button">
          <button type="submit" className="btn btn-primary btn-col">
             
            {" "}
            Submit Data
          </button>
          {" "}
          </div>
          <br />
          <br />
          <h6 className="text-muted">All fields marked * are mandatory</h6>
        </form>

        {submitMsg !== "" ? (
          <SubmitPopUp
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setSubmitMsg("");
            }}
          />
        ) : null}

        {/* {errorMsg !== "" ? (
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
              setErrorMsg("");
            }}
          />
        ) : null} */}

        {/************************Unused***************
    {(() => {
      switch(submitMsg){
      case "success":
      return   (
          <div>
            <h5 style={{ color: "green" }}> Data submitted sucessfully !!</h5>{" "}

            


            <br />
            <br />
           
          
            
          </div>
        );

        case "error": 
        return (
          <h5 style={{ color: "red" }}>
            
            Error in data submission.Please check again!
          </h5>
        );
        default: 
        return "";
           }
      })()}

    *************************/}

        {validateRawPhoneMsg === "error" ? (
          <h5 style={{ color: "red" }}>Phone number field is mandatory!</h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default VisitorUpdate;

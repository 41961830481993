import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton() {

    const navigate = useNavigate();


  const handleBack = (e) => {

    navigate(-1);
  
  };

  return(
    <button style={{float:"left", marginLeft:"0px"}}
    className="btn nav-btn "
    onClick={handleBack}
  >
    {" "}
    <i className="fas fa-chevron-left back-btn-icon"></i>{" "}Back
  </button>
  )

}
export default BackButton
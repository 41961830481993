import React, { useEffect, useState , useRef } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { SOCKETURL } from "../../../Global";
import Pagination from "react-js-pagination";
import "../regdhospital.css"
import { Modal } from 'react-bootstrap';
import ReassignService from "./ReassignService";
import "../pagination.css";
import LoadingOverlay from 'react-loading-overlay';
import { Button } from 'react-bootstrap';
import moment from "moment";
import DatePicker from "react-date-picker";
import Message from '../../common/Message';

function ServiceOrderList() {
  const [loading, setLoading] = useState(false);
  const [itemSrvice, setItemService] = useState()
  const [subId, setSubId] = useState()
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage,] = useState(10);
  const [data, setData] = useState("");
  const [alldata, setAllData] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [display, setDisplay] = useState(false);
  const [displayAll, setDisplayAll] = useState(true);
  const [displayPending, setDisplayPending] = useState(false);
  const [displayOngoing, setDisplayOngoing] = useState(false);
  const [displayAccepted, setDisplayAccepted] = useState(false);
  const [displayNew, setDisplayNew] = useState(false);
  const [infoShow, setinfoShow] = useState(false)
  const [infoData, setInfoData] = useState('')
  const [reassignServiceShow, setReassignServiceShow] = useState(false)
  const [subServiceId, setSubServiceId] = useState("")
  const webSocket = useRef(null);
  const [count, setCount] = useState(0);
  const[delayed,setDelayed]=useState(false)
  const aggr_type=sessionStorage.getItem("aggr_type")
  let ordered_time = ""
  let assigned = ""

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 2))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const handleFromDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setStartDate(DateExtracted);
    }
  };

  const handleToDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setEndDate(DateExtracted);
    }
  };
  
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    if (tokenString) {
   
      let str = tokenString.replace(/["]+/g, "");
      webSocket.current = new WebSocket(
        `${SOCKETURL}/ws/service_request/staff/?token=` +
        str
      );
      webSocket.current.onopen = () => {
        console.log("WebSocket connected");
      };
      webSocket.current.onmessage = (evt) => {
        if (evt && evt.data) {
          let message = JSON.parse(evt.data);
          if (message && message.message && message.message.unread_count) {
            setCount(message.message.unread_count);
            setDelayed(true)
          }
        }
      };
      webSocket.current.onclose = () => {
        console.log("disconnected");
      };
    }
  }, []);
  useEffect(() => {
    handleGo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ refresh])
 

  const handleGo = () => {
   
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    axios.get(`${APIURL}/api/v1/staff/service-request-fulfilment-dates/?date_after=${startDate}&date_before=${endDate}&timeZone=${timezoneOffset}&sort_by=asc`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.status == 200) {
          const data = res.data

          setAllData(data)


        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  };

  const ReassignServicePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-medium"
      >  <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <ReassignService id={subServiceId} item_service={itemSrvice} subId={subId} onSuccess1={onSuccess} refresh1={refreshPage} />


        </Modal.Body>

      </Modal>
    )
  }
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false} >
<Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5> {infoData.order_type==="master_medicine"?"Medicines":infoData.order_type==="master_labtest"?"Lab Tests":infoData.su_orders[0].item_service_name}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div>
            {/* <h4 className="title-of-page"> {infoData.item_service_name}</h4> */}
            <fieldset className='field_set'>

              <legend style={{ size: "5" }}>
              {infoData.su_orders.map((item,index) => {
             return (            
             <div className="row-of-features">
               <div className="col-of-features"><br/>
               {infoData.order_type==="master_medicine"||infoData.order_type==="master_labtest"?<h6> {index+1}&nbsp;{item.item_service_name}</h6>:""}
                 <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Consent Level Id : {item.consent_level_id}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Assigned To: {infoData.assigned_to_name}</h6>
              
               </div>
             </div>
             );
          })}
           
               
              </legend>


            </fieldset>
          </div>



        </Modal.Body>

      </Modal>
    );
  }
  const handlealldata = () => {
    setDisplay(true);
    setDisplayAll(true)
    setDisplayPending(false);
    setDisplayOngoing(false);
    setDisplayAccepted(false)
    setDisplayNew(false)
    setCurrentPage(1)
    setData(alldata)
  }
  const handlepending = () => {
    setDisplay(true);
    setDisplayAll(false)
    setDisplayPending(true);
    setDisplayOngoing(false);
    setDisplayAccepted(false)
    setDisplayNew(false)
    const filteredData = alldata.filter(item => {
      return item.request_status === 'pending'
    })
    setCurrentPage(1)
    setData(filteredData)
  }
  const handleongoing = () => {
    setDisplay(true);
    setDisplayAll(false)
    setDisplayPending(false);
    setDisplayOngoing(true);
    setDisplayAccepted(false)
    setDisplayNew(false)
    const filteredData = alldata.filter(item => {
      return item.request_status === 'ongoing'
    })
    setCurrentPage(1)
    setData(filteredData)
  }

  const handlenew = () => {
    setDisplay(true);
    setDisplayAll(false)
    setDisplayPending(false);
    setDisplayOngoing(false);
    setDisplayAccepted(false)
    setDisplayNew(true)
    const filteredData = alldata.filter(item => {
      return item.request_status === 'new'
    })
    setCurrentPage(1)                          
    setData(filteredData)
  }
  const handleaccepted = () => {
    setDisplay(true);
    setDisplayAll(false)
    setDisplayPending(false);
    setDisplayOngoing(false);
    setDisplayAccepted(true)
    setDisplayNew(false)
    const filteredData = alldata.filter(item => {
      return item.request_status === 'accepted'
    })
    setCurrentPage(1)
    setData(filteredData)
  }


  const onSuccess = () => {
    setRefresh(!refresh)
    handlealldata()
    setReassignServiceShow(false);

  }
  const refreshPage = () => {
    window.location.reload(false);
  }
  const handleInfoPopUp = (item) => {
    setInfoData(item);
    setinfoShow(true);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1)
    setSearchTerm(e.target.value)
  }
  let data1 = alldata ? alldata.sort((a, b) => {
    if (a.id > b.id) return -1;
    if (a.id < b.id) return 1;
    return 0;
  })
    .filter((value) => {
      if (searchTerm === '') {
        return value
      } else if (value.su_orders[0].item_service_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.su_orders[0].hospital_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.su_orders[0].request_priority.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.request_status.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.patient_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.id.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    }) : [];

  let data2 = data ? data.sort((a, b) => {
    if (a.id > b.id) return -1;
    if (a.id < b.id) return 1;
    return 0;
  })
    .filter((value) => {
      if (searchTerm === '') {
        return value
      } else if (value.item_service_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.hospital_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.request_priority.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.request_status.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }else if (value.patient_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      } else if (value.id.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    }) : [];
  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const alldata1 = data1.slice(indexFirst, indexLast);
  const alldata2 = data2.slice(indexFirst, indexLast);
  const total = data1.length;

  const pageNumbers = []
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i)
  }
  const totalPages = pageNumbers.length;

  let display1 = alldata2.length > 0 ? alldata2.map((item, index) => {
    assigned = item.assigned_to;
    ordered_time = item.su_orders[0].created_at
    const today = new Date();
    const offset = today.getTimezoneOffset();
    let dateUpdated = new Date(today.getTime() - offset);
    let ot = moment.utc(ordered_time).toDate()
    const m = moment(dateUpdated, "YYYY-MM-DD").format('YYYY-MM-DDTHH:mm:ss');
    var ms = moment(ot, "YYYY-MM-DD").format('YYYY-MM-DDTHH:mm:ss');
    console.log( Math.abs(m - ms) / 36e5);
    let timediff = moment.duration(moment(m, "YYYY-MM-DDTHH:mm:ss").diff(moment(ms, "YYYY-MM-DDTHH:mm:ss"))).asHours();
    let colorCode = "#747c001f";
    if (item.request_status==="new"||item.request_status==="pending") {
      if (timediff <= 1) {
        colorCode = "#DBFBD0";
      }
      else if (timediff > 1 && timediff <= 3) {
        colorCode = "#FAE4C6";
      } else {
        colorCode = "#FEC7C7";
      }
    }
console.log("m="+m+"  ms="+ ms+"  diff="+timediff+ "  color="+colorCode)
    return (
      <>

        {" "}
        <div class="row" style={{ backgroundColor: colorCode, padding: "0px", marginBottom: "0px", marginTop: "0px", textAlign: "center" }} >
        <div class="col-1 ser_body">
            {item.order_id}

          </div>
          <div class="col ser_body">
            {item.patient_name}

          </div>
          <div class="col-3 ser_body">
          {item.order_type==="master_medicine"?"Medicines":item.order_type==="master_labtest"?"Lab Tests":item.su_orders[0].item_service_name}
          <i style={{ color: "#75759c", paddingLeft: "5%" }} className="fas fa-info-circle fa-sm" onClick={() => { handleInfoPopUp(item) }} ></i>
            {/* {item.su_orders[0].item_service_name} */}

          </div>
          {aggr_type==="aggregator"&&
          <div class="col-3 ser_body">
            {item.su_orders[0].hospital_name}{item.request_status === "pending" ? <i style={{ color: "rgb(90, 149, 105)" }} onClick={() => { setReassignServiceShow(true); setSubId(item.id); setSubServiceId(item.sub_service); setItemService(item.item_service_name) }} class="fa  fa-pencil" aria-hidden="true"></i> : ""}

          </div>
          } 
          <div class="col ser_body">
            {item.request_status}

          </div>
          <div class="col ser_body">
            {item.su_orders[0].request_priority}
           

          </div>
        </div>
      </>
    )
  }) : null

  let display2 = alldata1.length > 0 ? alldata1.map((item, index) => {
    assigned = item.assigned_to;
    ordered_time = item.su_orders[0].created_at
    const today = new Date();
    const offset = today.getTimezoneOffset();
    let dateUpdated = new Date(today.getTime() - offset);
    let ot = moment.utc(ordered_time).toDate()
    const m = moment(dateUpdated, "YYYY-MM-DD").format('YYYY-MM-DDTHH:mm:ss');
    var ms = moment(ot, "YYYY-MM-DD").format('YYYY-MM-DDTHH:mm:ss');
    ;
    let timediff = moment.duration(moment(m, "YYYY-MM-DDTHH:mm:ss").diff(moment(ms, "YYYY-MM-DDTHH:mm:ss"))).asHours();
    let colorCode = "#747c001f";
    if (item.request_status==="new"||item.request_status==="pending") {
      if (timediff <= 1) {
        colorCode = "#DBFBD0";
      }
      else if (timediff > 1 && timediff <= 3) {
        colorCode = "#FAE4C6";
      } else {
        colorCode = "#FEC7C7";
      }
    
    }
   
    return (
      <>
        {" "}
        <div class="row" style={{ backgroundColor: colorCode, padding: "0px", marginBottom: "0px", marginTop: "0px", textAlign: "center" }} >
        <div class="col-1 ser_body">
            {item.order_id}

          </div>
          <div class="col ser_body">
            {item.patient_name}

          </div>
          <div class="col-3 ser_body">
          {item.order_type==="master_medicine"?"Medicines":item.order_type==="master_labtest"?"Lab tests":item.su_orders[0].item_service_name}
          <i style={{ color: "#75759c", paddingLeft: "5%" }} className="fas fa-info-circle fa-sm" onClick={() => { handleInfoPopUp(item) }} ></i>
            {/* {item.su_orders[0].item_service_name} */}

          </div>
          {aggr_type==="aggregator"&&
          <div class="col-3 ser_body">
            {item.su_orders[0].hospital_name}{item.request_status === "pending" ? <i style={{ color: "rgb(90, 149, 105)" }} onClick={() => { setReassignServiceShow(true); setSubId(item.id); setSubServiceId(item.sub_service); setItemService(item.item_service_name) }} class="fa  fa-pencil" aria-hidden="true"></i> : ""}

          </div>
           } 
          <div class="col ser_body">
            {item.request_status}

          </div>
          <div class="col ser_body">
            {item.su_orders[0].request_priority}
           

          </div>
        </div> </>
    )

  }) : null

  return (

    <div>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: 'rgba(0, 0, 255, 0.5)'

            }
          }),
          overlay: {},
        }}

      >
        <>
 
          <h2 className="title-of-page">Service Order Request List </h2>
          <div className="search-section">
            <div className="search-div">
              <div style={{ display: "flex" }}>
                <input
                  style={{}}
                  className="form-control search-input"
                  type="text"
                  placeholder="Search Here"
                  value={searchTerm}
                  maxLength="150"
                  onChange={handleSearchChange}
                />
                <button className="btn btn-primary btn-col search-btn">
                  {" "}
                  <i className="fas fa-search"></i>{" "}
                </button>

              </div>

            </div>
          </div>
          <div className="container">
{alldata.length > 0?
            <div className="container failedlist-parentcontainer">
              <br />
              <div className="title-of-tasks" >

                <Button variant={displayAll?"info":"primary"}  onClick={handlealldata}  >All Requests</Button>{' '}{' '}
                <Button variant={displayPending?"info":"primary"} onClick={handlepending} >Pending</Button>{' '}{' '}
                <Button variant={displayOngoing?"info":"primary"} onClick={handleongoing} >OnGoing</Button>{' '}
                <Button variant={displayAccepted?"info":"primary"} onClick={handleaccepted} >Accepted</Button>{' '}
                {/* <Button variant="danger"onClick={handlerejected} >Rejected</Button>{' '} */}
                <Button variant={displayNew?"info":"primary"} onClick={handlenew} >New</Button>{' '}
                {/* <Button variant="info"onClick={handlecompleted}>Completed</Button>{' '} */}
                {delayed&&count!==0?<div style={{width:"20%",backgroundColor:"#fffafa",border:"1px #f55050", display:"flex", float:"right"}}className=''><Message variant="danger" children={count+ " orders are delayed"} />
                <button  onClick={() => setDelayed(false)} style={{width:"34px",height:"34px",marginLeft:"-2px"}} className=" btn-outline-danger" >ok</button></div>:""}
              </div><br />

              <div style={{backgroundColor:"#00657c54", display: "flex", padding: "5px", float: "right" }}>
                <div className="span-date" style={{ display: "flex" }}>
                  <span> From&nbsp;&nbsp; </span>

                  <DatePicker

                    value={new Date(startDate)}
                    onChange={(date) => {
                      handleFromDate(date);
                    }}
                    maxDate={new Date()}
                  />
                </div>
                <div className="span-date " style={{ display: "flex" }}>
                  <span> To &nbsp;&nbsp;</span>

                  <DatePicker
                    value={new Date(endDate)}
                    onChange={(date) => {
                      handleToDate(date);
                    }}
                    maxDate={new Date()}
                  />

                </div>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <br />
                <Button
                  type="submit"
                  className="btn btn-smallest btn-col"
                  onClick={handleGo}
                >
                  {" "}
                  Check{" "}
                </Button>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>


              </div><br /><br />

              <div className="failed-list-section">
                <div className="container">
                  <div class="row" style={{ textAlign: "center" }}>
                  <div class="col-1 ser_header">
                      <h5>ID</h5>
                      {/* <input /> */}
                    </div>
                    <div class="col ser_header">
                      <h5>Name</h5>
                      {/* <input /> */}
                    </div>
                    <div class="col-3 ser_header">
                      <h5>Service Request</h5>
                      {/* <input /> */}
                    </div>
                    {aggr_type==="aggregator"&& <div class="col-3 ser_header">
                      <h5>Service Provider</h5>
                      {/* <input /> */}
                    </div>}
                    <div class="col ser_header">
                      <h5>Status</h5>
                      {/* <input /> */}
                    </div>
                    <div class="col ser_header">
                      <h5>Priority</h5>
                      {/* <input /> */}
                    </div>
                  </div>
                  <div className="test-reports-display-list">
                    {display ? (
                      display1
                    ) : (
                      display2
                    )}
                  </div>
                </div>
              </div>

              {totalPages > 1 ?
                <div className="pagn pagn-small">
                  <br />
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={totalPages}
                    onChange={handlePageChange}
                    disabledClass="disabled-class"
                  />
                </div>
                : null}

            </div>:<h4  style={{
            color: "red",
            fontSize: "25px",
            height: "200px",
            padding: "40px",
          }}>No data to show!!</h4>}
          </div>
        </>


        {
          reassignServiceShow ?
            <ReassignServicePopUp
              show={reassignServiceShow}
              onHide={() => { setReassignServiceShow(false);setRefresh(!refresh)}}

            /> : null
        }
        {
          infoShow ?
            <InfoPopup
              show={infoShow}
              onHide={() => {
                setinfoShow(false);
              }}

            /> : ''
        }

      </LoadingOverlay>
    </div>
  )

}
export default ServiceOrderList
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { Link, Navigate, useNavigate } from "react-router-dom";
function FollowUp() {
    const [followup, setFollowUp] = useState({})
    const [todayDate, setTodayDate] = useState(
        new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
          .toISOString()
          .split("T")[0]
      );

   
    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );

        axios.get(`${APIURL}/api/v1/patient/reminder/?${TZ}`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    setFollowUp(res.data.follow_ups)
                }

            })
            .catch((err) => {

            })

    }, [])
    return (
        <>

            <div className='second-contentbox ' >
                <div style={{width:"70%"}} className='second-tab sub-section_followup '>
                    <div className='sub-section-header box-theme'>
                        <h6 className='sub-section-title'>follow Ups</h6>
                    </div>
                    <div className='row'>
                        {followup.length>0?followup.map(item => (
                            <div className='col-5 follow_up_tile'>
                               {item.is_online===true?<p>You have an <span style={{color:"blue"}}>online appoinment</span> with  <span><b>{item.doctor.name+"("+item.doctor.specializations+")"}</b></span> on  
                                <span><b>{item.next_visit}</b></span><br/><br/>{item.next_visit!==todayDate?"The Link will be shared on the consultation day":<><Link to={{
                  pathname: `/videocall`,
                }} state={{ patient: item.doctor }}
                >Click here</Link> to start the call </>}</p>:
                                <p>You have an appointment with <span><b>{item.doctor.name+"("+item.doctor.specializations+")"}</b></span> on {" "} 
                                <span><b>{item.next_visit}</b></span></p>}
                               
                            </div>
                        )):<div style={{paddingLeft:"40%",paddingTop:"10%"}}> <h6 style={{ color: "red" }}>no data to show!!</h6></div>}
                    </div>
                </div>
            </div>



        </>
    );
}
export default FollowUp;

import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./service.css";
import { SOCKETURL } from "../../Global";
import { useNavigate, Navigate, Link,useLocation } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Medicines from "./Medicines";
import Consumables from "./Consumables";
import ServiceHistoryDoc from "./ServiceHistoryDoc";
import ServiceHistoryDoc1 from "./ServiceHistoryDoc1";

function ServiceRequests_doc(props) {
  const { state } = useLocation()
  const [reRender, setRerender] = useState(false);
  const [reRender1, setRerender1] = useState(false);
  const [deletes, setDelete] = useState([]);
  const [render, setRender] = useState(true);
  const [messageType, setMessageType] = useState("");
  const [, setGetError] = useState('');
  const [tableData, setTableData] = useState([]);
  const [medData, setMedData] = useState([]);
  const [dispenseShow, setDispenseShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [medicineShow, setMedicineShow] = useState(state.from==="medHistory"||state.from==="dashboard"?true:false)
  const [consumableShow, setConsumableShow] = useState(state.from==="conshistory"?true:false)
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [displayAlert,setDisplayAlert]=useState(false)
  const webSocket = useRef(null);
  const [tempdata,settempData]=useState([]) 
  const [orderId, serOrderId] = useState()
  const [selected, setSelected] = useState("medicine")
  let navigate = useNavigate();
  const [keys,] = useState({ 'p': false, 'a': false, 'n': false, 'm': false, 'l': false });
 
  const handlingWebsocket = () => {
    const tokenString = sessionStorage.getItem("usertoken");
    const hospId = sessionStorage.getItem("hospId");
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(
      `${SOCKETURL}/ws/pharmacy_request/sp_` +
      hospId +
      "/?token=" +
      str
    );
    webSocket.current.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log("WebSocket connected inside servicereq ");
    };

    webSocket.current.onmessage = (evt) => {
      // listen to data sent from the websocket server
      const message = JSON.parse(evt.data);
      console.log(message);
      //  console.log(message.message.count);
      if (message.message.type === "consumables") {
        setCount(message.message.count + count);
      } else if (message.message.type === "medicine") {

        setCount1(message.message.count + count1);

      }
      else if (message.message.message_type === "pharmacy_fulfillment") {
        console.log(message)
       setDisplayAlert(true)
        let data = {}
        data={ id: message.message.order_id, pamstatus: message.message.pam_status ,is_deleted:message.message.is_deleted}
        settempData([...tempdata,data])
       
      }
      //setCount(message.message.count);
   
      serOrderId(message.message.order_id ? message.message.order_id : "")
      setMessageType(message.message.type ? message.message.type : message.message.request_type)
      if (message.message.message_type === "order_request") {
        setUpdate(true)

      }


      if (message.message.message_type === "pharmacy_fulfillment") {
        setUpdate(true)
      }
    };

    webSocket.current.onclose = () => {
      console.log("disconnected");
      // automatically try to reconnect on connection loss
    };
  }
useEffect(()=>{
  console.log(tempdata)
  let del=tempdata.filter((item)=>{
    return item.is_deleted===true
  })
 
  let z=[]
  del.map((item)=>{
    z.push(item.id)
    console.log("z",z)
  })
  setDelete(z)
},[tempdata])
  useEffect(() => {

    setTimeout(() => {

      handlingWebsocket();
    }, 10000)
  }, [])

  useEffect(() => { window.scrollTo(0, 0) }, [])

  const reloadTable = (e) => {
    //setMedData([])
    
    setMedicineShow(true);
    setConsumableShow(false);
    setDispenseShow(false)
    
    setCount1(0);
    
    setUpdate(false)
    setRerender(!reRender)
  };
  const reloadTable1 = (e) => {

    // setTableData([])
    setConsumableShow(true)
    setMedicineShow(false);

    
    setCount(0);
    setUpdate(false)
    setRerender(!reRender)


  };

  useEffect(() => {
    setTableData(tableData)
  }, [render, tableData]);

  useEffect(() => {
    setMedData(medData)
  }, [render, medData]);




  const handleMedicine = (e) => {

    reloadTable();
  }

  const handleConsumables = (e) => {

    reloadTable1();
  }

  return (
    <>

<div className='row' style={{width:"100%"}}>
                <div className="col-lg-2 col-md-2">
                    <h5
                        style={{
                            color: "#245693",
                            marginTop: "8%",
                            fontFamily: "inter",
                            fontWeight: "700",
                            marginLeft: "20px",
                        }}
                    >
                       Fulfilment
                    </h5>
                    <div className="leftdivdeathrepo">
                       
                        <h6
                            style={{
                                color: "#2D3748",
                                fontFamily: "Helvetica",
                                fontWeight: "700",
                                marginLeft: "20px",
                                marginTop: "5px",
                                fontSize: "12px",

                            }}
                        >
                          
                        </h6>
                        <button className="deathreportbutton" onClick={e => { setSelected("medicine");setCount1(0) }} style={{ backgroundColor: selected === "medicine" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "medicine" ? "white" : "black" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M8.6 16.4a4.6 4.6 0 0 1-6.5-6.5l7.8-7.8a4.6 4.6 0 0 1 6.5 6.5M5.999 5.999l4.25 4.25" /><path d="M23.25 15.75a7.669 7.669 0 0 1-6 7.5a7.669 7.669 0 0 1-6-7.5V13.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v2.25Zm-6-.75v4.5M15 17.25h4.5" /></g></svg>
                        
                            &nbsp;&nbsp;Medicine
                        </button>
                        {/* <button className="deathreportbutton" onClick={e => { setSelected("consumables")}} style={{ backgroundColor: selected === "consumables" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "consumables" ? "white" : "black" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M22.003 3v4.497A.503.503 0 0 1 21.5 8v0a.52.52 0 0 1-.466-.3A10 10 0 0 0 12.003 2c-5.185 0-9.449 3.947-9.95 9" /><path d="M17 10v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2m-5 1V8" /><path d="M2.05 21v-4.497c0-.278.226-.503.504-.503v0c.2 0 .38.119.466.3a10.001 10.001 0 0 0 9.03 5.7c5.186 0 9.45-3.947 9.952-9" /></g></svg>
                            &nbsp;&nbsp;Consumables
                        </button> */}
                        <button className="deathreportbutton" onClick={e => {  setSelected("medHistory") }} style={{ backgroundColor: selected === "medHistory" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "medHistory" ? "white" : "black" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="currentColor" d="M16 5.5c5.799 0 10.5 4.701 10.5 10.5S21.799 26.5 16 26.5S5.5 21.799 5.5 16q0-.34.021-.674a1.25 1.25 0 0 0-2.495-.157Q3 15.582 3 16c0 7.18 5.82 13 13 13s13-5.82 13-13S23.18 3 16 3c-3.25 0-6.222 1.193-8.5 3.164V4.25a1.25 1.25 0 1 0-2.5 0v5c0 .69.56 1.25 1.25 1.25h5a1.25 1.25 0 1 0 0-2.5H9.199A10.46 10.46 0 0 1 16 5.5m1 3.75a1.25 1.25 0 1 0-2.5 0v7c0 .69.56 1.25 1.25 1.25h5a1.25 1.25 0 1 0 0-2.5H17z"/></svg>
                            &nbsp;&nbsp;Medicine History
                        </button>
                        {/* <button
                            className="deathreportbutton"
                            style={{ backgroundColor: selected === "conHistory" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "conHistory" ? "white" : "black" }}
                            onClick={e => {  setSelected("conHistory") }}
                        >
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="currentColor" d="M16 5.5c5.799 0 10.5 4.701 10.5 10.5S21.799 26.5 16 26.5S5.5 21.799 5.5 16q0-.34.021-.674a1.25 1.25 0 0 0-2.495-.157Q3 15.582 3 16c0 7.18 5.82 13 13 13s13-5.82 13-13S23.18 3 16 3c-3.25 0-6.222 1.193-8.5 3.164V4.25a1.25 1.25 0 1 0-2.5 0v5c0 .69.56 1.25 1.25 1.25h5a1.25 1.25 0 1 0 0-2.5H9.199A10.46 10.46 0 0 1 16 5.5m1 3.75a1.25 1.25 0 1 0-2.5 0v7c0 .69.56 1.25 1.25 1.25h5a1.25 1.25 0 1 0 0-2.5H17z"/></svg>
                            &nbsp;&nbsp;Consumable History
                        </button> */}
                    </div>
                </div>
                <div className="col-lg-10 col-md-10 text-center" style={{ paddingRight: "0.5%" }}>
                <div class="row" style={{ paddingTop: "4px" }}>
               
                <div class="col-4">

               
                </div>

                <div class="col" >
{displayAlert&&<span className="" style={{color:"orange"}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="M11 3a1 1 0 1 0-2 0v1a1 1 0 1 0 2 0V3Zm4.657 2.757a1 1 0 0 0-1.414-1.414l-.707.707a1 1 0 0 0 1.414 1.414l.707-.707ZM18 10a1 1 0 0 1-1 1h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1ZM5.05 6.464A1 1 0 1 0 6.464 5.05l-.707-.707a1 1 0 0 0-1.414 1.414l.707.707ZM5 10a1 1 0 0 1-1 1H3a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1Zm3 6v-1h4v1a2 2 0 1 1-4 0Zm4-2c.015-.34.208-.646.477-.859a4 4 0 1 0-4.954 0c.27.213.462.519.476.859h4.002Z"/></svg>
There have been recent updates in the medicine.<button onClick={(e) =>{setCount1(0);setRerender(!reRender); setDisplayAlert(false)}}
                    type="button"
                    style={{
                      border: "none",
                      
                      curser: "pointer",
                      color: "#007bff",
                    }}
                    className="notification1  btn-link"
                  >
                   
                    Click here

                  </button> to view </span>}
                </div>
                <div class="col">
                  <button onClick={(e) =>{setCount1(0);setDisplayAlert(false);setDelete([]);handleMedicine(e);setSelected("medicine")}}
                    type="button"
                    style={{
                      border: "1px solid #00657c",
                      borderRadius:"20px",
                      textDecoration: "none",
                      curser: "pointer",
                      color: "#007bff",
                    }}
                    className="notification1  btn-link"
                  >
                    <span
                      style={{float:"right",marginTop:"-7px", fontSize: 10, curser: "pointer", }}
                      className="badge"
                    >
                      {count1}
                    </span>&nbsp;&nbsp;&nbsp;
                    Update Medicine

                  </button> <span>&nbsp;</span>
                   {/* <button onClick={(e) =>{setCount(0);setDelete([]);handleConsumables(e);setSelected("consumables")}}
                    type="button"
                    style={{
                      border: "1px solid #00657c",
                      borderRadius:"20px",
                      textDecoration: "none",
                      curser: "pointer",
                      color: "#007bff",
                    }}
                    className="notification1  btn-link"
                  >
                    <span
                      style={{float:"right",marginTop:"-7px", fontSize: 10, curser: "pointer", }}
                      className="badge"
                    >
                      {count}
                    </span>&nbsp;&nbsp;&nbsp;
                    Update Consumables

                  </button> */}
                </div>

                
              

              </div>
                    {selected === "medicine" ? <Medicines  data={reloadTable} render={reRender}  del={deletes} setCount1={()=>{setCount1(0)}} /> :
                        selected === "consumables" ? <Consumables data={reloadTable1} render={reRender}   /> : selected === "medHistory" ? <ServiceHistoryDoc /> : <ServiceHistoryDoc1  />}
                </div>
            </div>

   
    </>
  );
}

export default ServiceRequests_doc;

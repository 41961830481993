import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal,Button } from "react-bootstrap";
import { Carousel } from 'react-bootstrap';
import AddToCart from './AddToCart';
import { Badge } from 'react-bootstrap';

function DealPromotions({  }) {
    const [promotions, setPromotions] = React.useState([]);

    useEffect(() => {
   
        // const tokenString = sessionStorage.getItem("usertoken");
    
        // let v2 = tokenString.replace(
        //   /["]+/g,
        //   ""
        // ); /* REGEX To remove double quotes from tokenstring */
    
        axios
          .get(`${APIURL}/api/v1/patient/list-promotions/?promo_type=deal_promotion`, 
          // {
          //   headers: { Authorization: "Token " + v2 },
          // }
          )
          .then((res) => {
            //console.log(res);
            if (res.status == 200) {
            
                // const dealPromotions = res.data.details.filter(
                //     (promo) => promo.promo_type === "deal_promotion"
                //   );
                  setPromotions(res.data.details);
              //console.log(featureList)
            } else {
              //setLoading(false);
            }
          })
          .catch((err) => {
           // setLoading(false);
          });
        }, []);





return(
    <>
    {promotions.length>0 ?

<div className="container">
        <div className="row">
        <div style={{ display: "contents" }} className="home-slides owl-carousel owl-theme" >
    <OwlCarousel items={3}
                            className="owl-theme"
                            loop
                            nav
                            dots
                            margin={10}
                            autoplay={true}
            navigation={true}
            autoplayTimeout={3000}
                     
                        >
        {promotions.map((promo) => {
  if (promo.promo_display === "dp3") {
    return (
      <div className="col-lg-4 col-md-6" key={promo.promotion_id}>
          <div className="single-banner-categories-box" style={{marginLeft:"-18px" ,   width: "410px"
  ,height: "250px"}}>
        <a href={`/dealspage?promo_id=${promo.promotion_id}`}>
  <img src={promo["promo_banner"]} alt="image" style={{ height: "200px", width: "94%", objectFit: "cover", marginLeft: "3%" }} />
</a>


          <div className="content" style={{marginTop:"9%"}}>
           
          {promo.overlay_text && (
                <p style={{ fontSize: parseInt(promo.overlay_size), color:promo.overlay_colour,marginTop:"5%" }}>
                  {promo.overlay_text} 
                </p>
               )}
       
            <a
              href={`/dealspage?promo_id=${promo.promotion_id}`}
              className="link-btn"
            >
             <b style={{color:"black"}}> Shop Now </b><i className="flaticon-next" style={{color:"white"}}></i>
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
})}
 </OwlCarousel>
      </div>

        </div>
      </div>
     
:""}
    </>






)







}
export default DealPromotions
import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Button, Modal } from 'react-bootstrap';
import ShipEdit from "./ShipEdit";
import ShipQuantity from "./ShipQuantity";
import "./shipcharge.css";
function QuantityPop({ data, shipid, props, modalclose, shiplclose }) {

  const [refresh, setRefresh] = useState(false);
  const id = data.id;
  //const shipid1=shipid[1];
  const [chargeqty, setChargeqty] = useState("")
  const [charge2, setCharge2] = useState("")
  const [completeShow, setCompleteShow] = useState(false);
  const [quantityShow, setQuantityShow] = useState(false);
  const [quantity, setQuantity] = useState("")
  const [quantity1, setQuantity1] = useState("")
  const [error, setError] = useState("")
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [shipeditshow, setShipeditShow] = useState(false)
  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");
    const id1 = id;
    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */
    axios
      .get(`${APIURL}/api/v1/service-provider/shipping-charge-detail/${shipid}/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {

        if (res.status == 200) {
          const data1 = res.data;


          setCharge2(data1.charge);


          setQuantity1(data1.threshold_quantity);

          // onSuccess();


          //setSuccessShow(false)
        } else {

        }
      })
      .catch((err) => {
        //setErrorShow(true)
      });

  }, [refresh])


  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "charge") {
      setChargeqty(val);
    }
    if (key === "quantity") {
      setQuantity(val)
    }

  };
  const handleShipEdit = () => {
    setShipeditShow(true);

  }
  const submitshipedithandle = () => {
    setShipeditShow(false)
    setRefresh(!refresh)
  }
  const submitQuantityEditHandle = () => {
    setQuantityShow(false);
    setRefresh(!refresh)
  }
  const handleSizeQuantity = () => {
    setQuantityShow(true);
  }
  const CompletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'> Shipping Charge Successfully Saved</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const ShipeditPopup = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShipEdit
            id={shipid}
            submiteditship={submitshipedithandle}
            seteditship={charge2}
          //onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const QuantityPopup = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShipQuantity
            id={shipid}
            setQuantityHandle={submitQuantityEditHandle}
            setquantitydata={quantity1}
          //onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {errorMsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const addship = (e) => {
    if (chargeqty == "" && quantity == "") {
      setErrorMsg("Enter ShipCharge and Quantity");
      setErrorSubmit(true);

    }
    else if (chargeqty !== "" && quantity == "" || quantity !== "" && chargeqty == "") {
      setErrorMsg("Please fill all fields");
      setErrorSubmit(true);
    }
    if (chargeqty < 0 || quantity < 0) {
      setErrorMsg("negative values are not allowed");
      setErrorSubmit(true);

    }
    if (chargeqty == "0" || quantity == "0") {
      setErrorMsg("zero values are not allowed");
      setErrorSubmit(true);

    }
    else {
      e.preventDefault();


      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      const dataToSend = {
        charge: chargeqty,
        item_service: id,
        threshold_quantity: quantity,
      }

      axios
        .post(`${APIURL}/api/v1/service-provider/shipping-charge/`, dataToSend, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {

            // onSuccess();

            setCompleteShow(true)
            setRefresh(!refresh)
            //setSuccessShow(false)
          } else {
            setError(res.data.message)
          }
        })
        .catch((err) => {
          //setErrorShow(true)
        });
      // setSuccessShow(false)




    }
  }

  return (
    <div className="" >


      <div className="title-of-tasks" style={{}}><h5>Add Shipping Charge based on quantity</h5></div><br />

      <div className="formFields" >

        <div className="formField" style={{ display: "flex" }}>
          <label className="col-sm-5">*Ship Charge   : </label> {charge2}
          {charge2 != "" ?
            <>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <i
                style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                onClick={handleShipEdit}
                className="fas fa-edit"
                aria-hidden="true"
              ></i>    </>
            :
            <>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input

                type="number"
                name="charge"
                min="0"
                value={chargeqty}
                onChange={handleChange}
                required
              ></input>
            </>
          }
          {charge2 == 0 && charge2 !== "" ? <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <i
            style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
            onClick={handleShipEdit}
            className="fas fa-edit"
            aria-hidden="true"
          ></i> </> : " "}





        </div>
        <div className="formField" style={{ display: "flex" }}>
          <label className="col-sm-5">*Quantity   : </label> {quantity1}
          {quantity1 != "" ?
            <>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <i
                style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                onClick={handleSizeQuantity}
                className="fas fa-edit"
                aria-hidden="true"
              ></i>    </>
            :
            <>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input
                type="number"
                name="quantity"
                min="0"
                value={quantity}
                onChange={handleChange}
                required
              ></input>
            </>
          }
          {quantity1 == 0 && quantity1 !== "" ? <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <i
            style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
            onClick={handleSizeQuantity}
            className="fas fa-edit"
            aria-hidden="true"
          ></i> </> : " "}



        </div>
        {charge2==="" ? <div className="form-heading title-of-tasks" >
          <button className=" btn formFieldButton" disabled={charge2 > 0} onClick={addship}>Save Changes</button>
          {/* <Button variant= "danger" onClick={() => { handleDelete(id) }} >Delete</Button> */}
          {error ? (
            <div className="error-validation-msg">
              {error}
            </div>
          ) : null}
        </div>:""}
      </div>


      {completeShow ?

        <CompletePopUp
          show={completeShow}
          onHide={() => {
            setCompleteShow(false); setRefresh(!refresh)
            modalclose();
          }}
        />
        : null}
      {shipeditshow ?

        <ShipeditPopup
          show={shipeditshow}
          seteditship={charge2}
          onHide={() => {
            setShipeditShow(false)
            modalclose();
          }}
        />
        : null}

      {quantityShow ?

        <QuantityPopup
          show={quantityShow}
          setquantitydata={quantity1}
          onHide={() => {
            setQuantityShow(false); setRefresh(!refresh)
            modalclose();
          }}
        />
        : null}
      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : (
        ""
      )}
    </div>

  )

}
export default QuantityPop
import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert';
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import "./popup.css";
import "./country_region_selector.css";
import "./dob.css";
import './profile.css'
import MailUpdate from './MailUpdate';
import AddressUpdate from './AddressUpdate';
import DobUpdate from './DobUpdate';
import GenderUpdate from './GenderUpdate';
import RegionUpdate from './RegionUpdate';
import YearUpdate from './YearUpdate';
import SplUpdate from './SplUpdate';
import ExpUpdate from './ExpUpdate';
import CouncilUpdate from './CouncilUpdate';
import MedicalUpdate from './MedicalUpdate';
//import CountryUpdate from './CountryUpdate';
import PhoneUpdate from './PhoneUpdate';
import OfficialUpdate from './OfficialUpdate';
import EducationalUpdate from './EducationalUpdate';
import { AiOutlineUserAdd } from "react-icons/ai";
import defaultprofile from "../../../assets/images/icons/profile.png";

function EditProfile(props) {
  const alert = useAlert()
  const id = props.datas.id;
  const [medical, setMedical] = useState("");
  const [regYear, setRegYear] = useState("");
  const [council, setCouncil] = useState("");
  const [exp, setExp] = useState("");
  const [spl, setSpl] = useState("");
  // const medical = props.datas.medical_competencies;
  // const regYear = props.datas.registeration_year;
  // const council = props.datas.registration_council;
  // const exp =  props.datas.years_of_experience;
  // const spl = props.datas.specialization;
  const [email, setEmail] = useState(null);
  const [img, setImg] = useState('');
  const [address, setAddress] = useState(null);
  const [state, setState] = useState('');
  const [official, setOfficial] = useState('');
  const [educational, setEducational] = useState('');
  const [country, setCountry] = useState('')
  const [phn, setPhn] = useState('')
  const [gender, setGender] = useState();
  const [dob, setDob] = useState();
  const [show, setShow] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const [previewOn, setPreviewOn] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [mailEdit, setMailEdit] = useState(false)
  const [addressEdit, setAddressEdit] = useState(false)
  const [dobEdit, setDobEdit] = useState(false)
  const [genderEdit, setGenderEdit] = useState(false)
  const [countryEdit, setCountryEdit] = useState(false)
  const [stateEdit, setStateEdit] = useState(false)
  const [phoneEdit, setPhoneEdit] = useState(false)
  const [splEdit, setSplEdit] = useState(false)
  const [medicalEdit, setMedicalEdit] = useState(false)
  const [yearEdit, setYearEdit] = useState(false)
  const [officialEdit, setOfficialEdit] = useState(false)
  const [educationalEdit, setEducationalEdit] = useState(false)
  const [councilEdit, setCouncilEdit] = useState(false)
  const [expEdit, setExpEdit] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [flag, setFlag] = useState(false)
  const [sizeShow, setSizeShow] = useState(false)
  const [validShow, setValidShow] = useState(false)
  const [contentShow, setContentShow] = useState(false)
  const [uploadShow, setUploadShow] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [data, setData] = useState({})
  const [code,setCode]=useState("")
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))

  );
  const reader = new FileReader();

  useEffect(() => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')


    axios.get(`${APIURL}/api/v1/doctor/medical-profile-view/?doctor_id=${props.medId}&${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.status == 200) {

          setData(res.data)
          setMedical(res.data[0].medical_competencies)
          setCouncil(res.data[0].registration_council)
          setExp(res.data[0].years_of_experience)
          setRegYear(res.data[0].registeration_year)
          setSpl(res.data[0].specialization)
          setOfficial(res.data[0].official_practice_licenses)
          setEducational(res.data[0].educational_credentials)
        }


      })
      .catch(err => {

      })


  }, [refresh]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')


    axios.get(`${APIURL}/api/v1/account/profile/?${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          setImg(res.data.data.photo)
          setAddress(res.data.data.address)
          setGender(res.data.data.gender)
          setDob(res.data.data.dob)
          setEmail(res.data.data.email)
          setCountry(res.data.data.country_name)
          setState(res.data.data.state)
          setPhn(res.data.data.mobile_number)
          setCode(res.data.data.country_code)
        }


      })
      .catch(err => {

      })


  }, [refresh]);


  const removePicPreview = (e) => {
    e.preventDefault();
    setFlag(true)
    setToggle(false)
    setPreviewOn(false);

  }

  const editImage = () => {
    const fileInput = document.getElementById('imageInput')
    fileInput.click();
  }




  // const selectCountry =(val)=> {
  // setCountry(val)


  //}

  // const selectRegion= (val)=> {
  // setRegion(val)
  //}

  const editMail = (e) => {
    e.preventDefault();
    setMailEdit(true)
  }

  const editAddress = (e) => {
    e.preventDefault();
    setAddressEdit(true)
  }

  const editDob = (e) => {
    e.preventDefault();
    setDobEdit(true)
  }

  const editGender = (e) => {
    e.preventDefault();
    setGenderEdit(true)
  }

  const editCountry = (e) => {
    e.preventDefault();
    setCountryEdit(true)
  }

  const editRegion = (e) => {
    e.preventDefault();
    setStateEdit(true)
  }

  const editPhone = (e) => {
    e.preventDefault();
    setPhoneEdit(true)
  }
  const editSpl = (e) => {
    e.preventDefault();
    setSplEdit(true)
  }
  const editMedical = (e) => {
    e.preventDefault();
    setMedicalEdit(true)
  }
  const editYear = (e) => {
    e.preventDefault();
    setYearEdit(true)
  }
  const editOfficial = (e) => {
    e.preventDefault();
    setOfficialEdit(true)
  }
  const editEducational = (e) => {
    e.preventDefault();
    setEducationalEdit(true)
  }
  const editExp = (e) => {
    e.preventDefault();
    setExpEdit(true)
  }
  const editCouncil = (e) => {
    e.preventDefault();
    setCouncilEdit(true)
  }


  const GenderPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Gender
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <GenderUpdate handle={GenderHandler} gender={gender}/>

        </Modal.Body>
      </Modal>
    )
  }


  const DobPopUp = (props) => {
    return (
      <Modal
        {...props}

        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update DOB
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">

          <DobUpdate handle={DobHandler} dob={dob} />

        </Modal.Body>
      </Modal>
    )
  }


  const AddressPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">

          <AddressUpdate handle={AddressHandler} address={address} />

        </Modal.Body>
      </Modal>
    )
  }



  const MailPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">

          <MailUpdate handle={MailHandler} email={email} />

        </Modal.Body>
      </Modal>
    )
  }


  const RegionPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update State
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <RegionUpdate country={country} handle={RegionHandler} state={state}/>

        </Modal.Body>
      </Modal>
    )
  }

  const PhonePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Phone Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <PhoneUpdate handle={PhoneHandler} phone={phn} />

        </Modal.Body>
      </Modal>
    )
  }
  const MedicalPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Medical Competence
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <MedicalUpdate handle={medicalHandler} id1={id} medical={medical} />

        </Modal.Body>
      </Modal>
    )
  }
  const CouncilPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Registration Council
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <CouncilUpdate handle={councilHandler} id1={id} council={council} />

        </Modal.Body>
      </Modal>
    )
  }
  const ExpPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Year of Experience
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <ExpUpdate handle={expHandler} id1={id} exp={exp}/>

        </Modal.Body>
      </Modal>
    )
  }
  const SplPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Super Speciality
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <SplUpdate handle={splHandler} id1={id} spl={spl}/>

        </Modal.Body>
      </Modal>
    )
  }
  const YearPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Registration year
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <YearUpdate handle={yearHandler} id1={id} regYear={regYear} />

        </Modal.Body>
      </Modal>
    )
  }
  const OfficialPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Official Practice Licence
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <OfficialUpdate handle={offHandler} id1={id} official={official} />

        </Modal.Body>
      </Modal>
    )
  }
  const EducationalPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Educational Credentials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <EducationalUpdate handle={eduHandler} id1={id} educational={educational}/>

        </Modal.Body>
      </Modal>
    )
  }


  const uploadImage = e => {

    const file = e.target.files[0];

    if (!file) {
      setUploadShow(!uploadShow)
      //setInvalidPic('Please Select an Image')
      return false
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow)
      //setInvalidPic('Select valid image')
      return false;
    }
    if (file.size > 10e6) {
      setSizeShow(!sizeShow)
      return false
    }

    reader.onload = (e) => {
      const img1 = new Image();
      img1.onload = () => {
        setToggle(true)
        setImg(file)
      };
      img1.onerror = () => {
        setPreviewOn(false)
        setContentShow(!contentShow)

        return false;
      };
      img1.src = e.target.result;
    };
    reader.readAsDataURL(file);

    const url = URL.createObjectURL(file)
    setImgURL(url)
    setPreviewOn(true)

  }


  const SizePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">You have Exceeded the size limit 10MB</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const LoadPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Select an Image</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ValidPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Select a valid Image</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ContentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Invalid Image Content</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }



  const submitHandle = async () => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    let fd = new FormData()

    //if(address!== null){
    //fd.append('address',address)
    //}

    if (img !== null) {
      fd.append('photo', img)
    }

    // if(email!== null){
    // fd.append('email',email)
    // }


    await axios.put(`${APIURL}/api/v1/account/profile/`, fd, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {



        if (res.data.status === "success") {
          setToggle(false)
          setFlag(false)
          setPreviewOn(false)
          setRefresh(!refresh)
          alert.success("Updated successfully")

        } else {
          alert.error("Error occured")
          setPreviewOn(false)

        }

      })
      .catch(err => {
        alert.error("Error occured")
        setPreviewOn(false)
      })


  }

  const handleRemove = async () => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      photo: ''
    }



    await axios.put(`${APIURL}/api/v1/account/profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {



        if (res.data.status === "success") {

          alert.success("Removed successfully")
          setPreviewOn(false)
          setRefresh(!refresh)

        } else {

          alert.error("Error occured")
          setPreviewOn(false)
        }

      })
      .catch(err => {
        alert.error("Error occured")
        setPreviewOn(false)

      })
  }

  const DobHandler = () => {
    setDobEdit(false)
    setRefresh(!refresh)
  }

  const GenderHandler = () => {
    setGenderEdit(false)
    setRefresh(!refresh)
  }

  const MailHandler = () => {
    setMailEdit(false)
    setRefresh(!refresh)
  }

  const AddressHandler = () => {
    setAddressEdit(false)
    setRefresh(!refresh)
  }

  const CountryHandler = () => {
    setCountryEdit(false)
    setRefresh(!refresh)
  }

  const RegionHandler = () => {
    setStateEdit(false)
    setRefresh(!refresh)
  }
  const PhoneHandler = () => {
    setPhoneEdit(false)
    setRefresh(!refresh)
  }

  const medicalHandler = () => {
    setMedicalEdit(false)
    setRefresh(!refresh)
  }
  const councilHandler = () => {
    setCouncilEdit(false)
    setRefresh(!refresh)
  }
  const yearHandler = () => {
    setYearEdit(false)
    setRefresh(!refresh)
  }
  const offHandler = () => {
    setOfficialEdit(false)
    setRefresh(!refresh)
  }
  const eduHandler = () => {
    setEducationalEdit(false)
    setRefresh(!refresh)
  }
  const expHandler = () => {
    setExpEdit(false)
    setRefresh(!refresh)
  }
  const splHandler = () => {
    setSplEdit(false)
    setRefresh(!refresh)
  }


  return (
    <div className="profile-flex-col profile-holder" style={{overflow:"auto"}}>

      <h5 className="title-of-tasks"><i style={{ marginRight: '4px' }} className="fas fa-user-circle"></i>Edit Profile</h5>
      {previewOn === false ? (
  <>
    <div className='avatar' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow:'hidden' }}>
            {img ? (
              <img src={img} />
            ) : (
            <><input type="file" id="imageInput" hidden="hidden" onChange={uploadImage} />
              <AiOutlineUserAdd onClick={editImage} style={{ fontSize: '100px', cursor:'pointer' }} /></>
            )}
    </div>   
  </>
) : (
  <div className='avatar' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , overflow:'hidden'}}>
    <img src={imgURL} />
  </div>
)}
      <br />
      <div className='flex-row'>
        <button onClick={submitHandle} disabled={toggle === false} className="btn btn-primary btn-col" >Update Picture</button>
        <button style={{ marginLeft: '10px' }} disabled={img === '' || img === null} onClick={handleRemove} className="btn btn-primary btn-col" >Remove Picture</button>
      </div>

      <div style={{textAlign:"left",marginTop:"6%"}}><div><label className='bold-label'>Email :&nbsp; </label><label className='bio-data'>{email === '' ? <span className='text-danger'>No Data</span> : email}</label>
            {/* <label onClick={editMail} style={{ color: "blue", cursor: "pointer" }}> (change)</label> */}
          </div>
          <div><label className='bold-label'>Address :&nbsp; </label><label className='bio-data'>{address === '' ? <span className='text-danger'>No Data</span> : address}</label>
            <label onClick={editAddress} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
          </div>
          <div ><label className='bold-label'>DOB :&nbsp; </label><label className='bio-data'>{dob === '' ? <span className='text-danger'>No Data</span> : dob}</label>
            <label onClick={editDob} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
          </div>
          <div ><label className='bold-label'>Gender :&nbsp; </label><label className='bio-data'>{gender === '' ? <span className='text-danger'>No Data</span> : gender}</label>
            <label onClick={editGender} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
          </div>
          <div ><label className='bold-label'>State :&nbsp; </label><label className='bio-data'>{state === '' ? <span className='text-danger'>No Data</span> : state}</label>
            <label onClick={editRegion} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
          </div>
          <div ><label className='bold-label'>Phone : &nbsp;</label><label className='bio-data'>{phn === '' ? (
  <span className='text-danger'>No Data</span>
) : (
  phn.length === 12 ? (
    <span style={{fontWeight:600}}>{'+' + phn.substr(0, 2) + ' ' + phn.substr(2)}</span>
  ) : (
    <span style={{fontWeight:600}}>+{code}{phn}</span>
  )
)}</label>
            {/* <label onClick={editPhone} style={{ color: "blue", cursor: "pointer" }}> (change)</label> */}
          </div>
          {login_datas.user_type === "hospital_doctor"&&data.length>0 ? <div>
            <div><label className='bold-label'>Medical Competence :&nbsp; </label><label className='bio-data'>{medical === '' ? <span className='text-danger'>No Data</span> : medical}</label>
              <label onClick={editMedical} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
            </div>
            <div ><label className='bold-label'>Super Speciality : &nbsp;</label><label className='bio-data'>{spl === '' ? <span className='text-danger'>No Data</span> : spl}</label>
              <label onClick={editSpl} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
            </div>
            <div ><label className='bold-label'>Year of Experience : &nbsp;</label><label className='bio-data'>{exp === '' ? <span className='text-danger'>No Data</span> : exp}</label>
              <label onClick={editExp} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
            </div>
            <div ><label className='bold-label'>Council : &nbsp;</label><label className='bio-data'>{council === '' ? <span className='text-danger'>No Data</span> : council}</label>
              <label onClick={editCouncil} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
            </div> 
            <div ><label className='bold-label'>Reg.Year : &nbsp;</label><label className='bio-data'>{regYear === '' ? <span className='text-danger'>No Data</span> : regYear}</label>
              <label onClick={editYear} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
            </div>
            <div ><label className='bold-label'>Official Practice Licence :&nbsp; </label><label className='bio-data'>{official === '' ? <span className='text-danger'>No Data</span> : official}</label>
              <label onClick={editOfficial} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
            </div>
            <div ><label className='bold-label'>Educational Credentials :&nbsp; </label><label className='bio-data'>{educational === '' ? <span className='text-danger'>No Data</span> : educational}</label>
              <label onClick={editEducational} style={{ color: "blue", cursor: "pointer" }}> (change)</label>
            </div>
        </div> : ""}
      </div>
      
      {
        <MailPopUp
          show={mailEdit}
          onHide={() => {
            setMailEdit(false)
          }} />

      }

      {
        <AddressPopUp
          show={addressEdit}
          onHide={() => {
            setAddressEdit(false)
          }} />
      }

      {
        <DobPopUp
          show={dobEdit}
          onHide={() => {
            setDobEdit(false)

          }} />
      }

      {
        <GenderPopUp
          show={genderEdit}
          onHide={() => {
            setGenderEdit(false)
          }} />
      }



      {
        <RegionPopUp
          show={stateEdit}
          onHide={() => {
            setStateEdit(false)
          }} />
      }

      {
        <PhonePopUp
          show={phoneEdit}
          onHide={() => {
            setPhoneEdit(false)
          }} />
      }

      {
        <SplPopUp
          show={splEdit}
          onHide={() => {
            setSplEdit(false)
          }} />
      }
      {
        <MedicalPopUp
          show={medicalEdit}
          onHide={() => {
            setMedicalEdit(false)
          }} />
      }
      {
        <ExpPopUp
          show={expEdit}
          onHide={() => {
            setExpEdit(false)
          }} />
      }
      {
        <YearPopUp
          show={yearEdit}
          onHide={() => {
            setYearEdit(false)
          }} />
      }
      {
        <OfficialPopUp
          show={officialEdit}
          onHide={() => {
            setOfficialEdit(false)
          }} />
      }
      {
        <EducationalPopUp
          show={educationalEdit}
          onHide={() => {
            setEducationalEdit(false)
          }} />
      }
      {
        <CouncilPopUp
          show={councilEdit}
          onHide={() => {
            setCouncilEdit(false)
          }} />
      }

      {
        sizeShow ?
          <SizePopUp
            show={sizeShow}
            onHide={() => {
              setSizeShow(false)
            }} /> : null
      }

      {
        uploadShow ?
          <LoadPopUp
            show={uploadShow}
            onHide={() => {
              setUploadShow(false)
            }} /> : null
      }

      {
        validShow ?
          <ValidPopUp
            show={validShow}
            onHide={() => {
              setValidShow(false)
            }} /> : null
      }

      {
        contentShow ?
          <ContentPopUp
            show={contentShow}
            onHide={() => {
              setContentShow(false)
            }} /> : null
      }
    </div>
  )
}

export default EditProfile

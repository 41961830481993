import React, { useContext, useState, useEffect, useRef } from "react";
import ChartistGraph from "react-chartist";
import { Chart, ChartConfiguration, LineController, LineElement, PointElement, LinearScale, Tooltip, Title, TimeScale } from 'chart.js'
import 'chartjs-adapter-date-fns';
import { Scatter, Bubble } from "react-chartjs-2";
import moment from 'moment-timezone';
import ProgressBar from 'react-bootstrap/ProgressBar';
import axios from "axios";
import { APIURL, TZ } from "../../../../Global";
import { SOCKETURL } from "../../../../Global";
import "../monitor.css"
import PatientsList from "./PatientsList";
import { OverlayTrigger, Tooltip as Tooltip1 } from 'react-bootstrap';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import NurseList from "../NurseList";
Chart.register(LineController, LineElement, Tooltip, PointElement, LinearScale, TimeScale, Title);
function Monitor(props) {
  const [lastUpdated, setLastUpdated] = useState(null);
  const [timeAgo, setTimeAgo] = useState(0)
  const webSocket = useRef(null);
  const currentDate = new Date();
  const [startTime, setStartTime] = useState(currentDate.setHours(1, 0, 0, 0))
  const [endTime, setEndTime] = useState(currentDate.setHours(24, 0, 0, 0));
  const [type, setType] = useState("medicine");
  const [completedCon, setCompletedCon] = useState(0)
  const [pendingCon, setPendingCon] = useState(0)
  const [missedCon, setMissedCon] = useState(0)
  const [completedPatCon, setCompletedPatCon] = useState(0)
  const [pendingPatCon, setPendingPatCon] = useState(0)
  const [missedPatCon, setMissedPatCon] = useState(0)
  const [totalCon, setTotalCon] = useState(0)
  const [graphDataCon, setGraphDataCon] = useState([])

  const [completedTest, setCompletedTest] = useState(0)
  const [pendingTest, setPendingTest] = useState(0)
  const [missedTest, setMissedTest] = useState(0)
  const [completedPatTest, setCompletedPatTest] = useState(0)
  const [pendingPatTest, setPendingPatTest] = useState(0)
  const [missedPatTest, setMissedPatTest] = useState(0)
  const [totalTest, setTotalTest] = useState(0)
  const [graphDataTest, setGraphDataTest] = useState([])

  const [completedPatId, setCompletedPatId] = useState([])
  const [missedPatId, setMissedPatId] = useState([])
  const [delayedPatId, setDelayedPatId] = useState([])
  const [pendingPatId, setPendingPatId] = useState([])

  const [completedPatIdMed, setCompletedPatIdMed] = useState([])
  const [missedPatIdMed, setMissedPatIdMed] = useState([])
  const [delayedPatIdMed, setDelayedPatIdMed] = useState([])
  const [pendingPatIdMed, setPendingPatIdMed] = useState([])

  const [completedPatIdFd, setCompletedPatIdFd] = useState([])
  const [missedPatIdFd, setMissedPatIdFd] = useState([])
  const [delayedPatIdFd, setDelayedPatIdFd] = useState([])
  const [pendingPatIdFd, setPendingPatIdFd] = useState([])

  const [completedPatIdLq, setCompletedPatIdLq] = useState([])
  const [missedPatIdLq, setMissedPatIdLq] = useState([])
  const [delayedPatIdLq, setDelayedPatIdLq] = useState([])
  const [pendingPatIdLq, setPendingPatIdLq] = useState([])

  const [completedPatIdCon, setCompletedPatIdCon] = useState([])
  const [missedPatIdCon, setMissedPatIdCon] = useState([])
  const [pendingPatIdCon, setPendingPatIdCon] = useState([])

  const [completedPatIdTest, setCompletedPatIdTest] = useState([])
  const [missedPatIdTest, setMissedPatIdTest] = useState([])
  const [pendingPatIdTest, setPendingPatIdTest] = useState([])

  const [completedPatIdScan, setCompletedPatIdScan] = useState([])
  const [missedPatIdScan, setMissedPatIdScan] = useState([])
  const [pendingPatIdScan, setPendingPatIdScan] = useState([])

  const [completedPatIdPro, setCompletedPatIdPro] = useState([])
  const [missedPatIdPro, setMissedPatIdPro] = useState([])
  const [pendingPatIdPro, setPendingPatIdPro] = useState([])

  const [completedScan, setCompletedScan] = useState(0)
  const [pendingScan, setPendingScan] = useState(0)
  const [missedScan, setMissedScan] = useState(0)
  const [completedPatScan, setCompletedPatScan] = useState(0)
  const [pendingPatScan, setPendingPatScan] = useState(0)
  const [missedPatScan, setMissedPatScan] = useState(0)
  const [totalScan, setTotalScan] = useState(0)
  const [graphDataScan, setGraphDataScan] = useState([])

  const [completedPro, setCompletedPro] = useState(0)
  const [pendingPro, setPendingPro] = useState(0)
  const [missedPro, setMissedPro] = useState(0)
  const [completedPatPro, setCompletedPatPro] = useState(0)
  const [pendingPatPro, setPendingPatPro] = useState(0)
  const [missedPatPro, setMissedPatPro] = useState(0)
  const [totalPro, setTotalPro] = useState(0)
  const [graphDataPro, setGraphDataPro] = useState([])

  const [completedMed, setCompletedMed] = useState(0)
  const [pendingMed, setPendingMed] = useState(0)
  const [delayedMed, setDelayedMed] = useState(0)
  const [missedMed, setMissedMed] = useState(0)

  const [completedLqd, setCompletedLqd] = useState(0)
  const [pendingLqd, setPendingLqd] = useState(0)
  const [delayedLqd, setDelayedLqd] = useState(0)
  const [missedLqd, setMissedLqd] = useState(0)

  const [completedFd, setCompletedFd] = useState(0)
  const [pendingFd, setPendingFd] = useState(0)
  const [delayedFd, setDelayedFd] = useState(0)
  const [missedFd, setMissedFd] = useState(0)

  const [pendingPatMed, setPendingPatMed] = useState(0)
  const [pendingPatLqd, setPendingPatLq] = useState(0)
  const [pendingPatFd, setPendingPatFd] = useState(0)

  const [completed, setCompleted] = useState(0)
  const [completedPatMed, setCompletedPatMed] = useState(0)
  const [completedPatLq, setCompletedPatLq] = useState(0)
  const [completedPatFd, setCompletedPatFd] = useState(0)
  const [completedPat, setCompletedPat] = useState(0)
  const [pendingPat, setPendingPat] = useState(0)
  const [delayedPat, setDelayedPat] = useState(0)
  const [missedPat, setMissedPat] = useState(0)
  const [pending, setPending] = useState(0)
  const [delayedd, setDelayed] = useState(0)
  const [delayedPatMed, setDelayedPatMed] = useState(0)
  const [delayedPatFd, setDelayedPatFd] = useState(0)
  const [delayedPatLq, setDelayedPatLq] = useState(0)

  const [missed, setMissed] = useState(0)
  const [missedPatMed, setMissedPatMed] = useState(0)
  const [missedPatFd, setMissedPatFd] = useState(0)
  const [missedPatLq, setMissedPatLq] = useState(0)

  const [completedP, setCompletedP] = useState(0)
  const [pendingP, setPendingP] = useState(0)
  const [delayedP, setDelayedP] = useState(0)
  const [missedP, setMissedP] = useState(0)

  const [refresh, setRefresh] = useState(false)
  const [refresh1, setRefresh1] = useState(false)
  const [graphData, setGraphData] = useState([])
  const [graphDataMed, setGraphDataMed] = useState([])
  const [graphDataLqd, setGraphDataLqd] = useState([])
  const [graphDataFd, setGraphDataFd] = useState([])
  const currentTime = new Date().getTime();
  const checkTime = new Date();

  const morningStart = new Date(checkTime.setHours(7, 0, 0, 0)).getTime();
  const morningEnd = new Date(checkTime.setHours(11, 59, 0, 0)).getTime();

  const afternoonStart = new Date(checkTime.setHours(12, 0, 0, 0)).getTime();
  const afternoonEnd = new Date(checkTime.setHours(15, 29, 0, 0)).getTime();

  const eveningStart = new Date(checkTime.setHours(15, 30, 0, 0)).getTime();
  const eveningEnd = new Date(checkTime.setHours(19, 29, 0, 0)).getTime();

  const nightStart = new Date(checkTime.setHours(19, 30, 0, 0)).getTime();
  const nightEnd = new Date(checkTime.setHours(23, 59, 59, 999)).getTime();

  const [serveTime, setServeTime] = useState(props.date1 === new Date().toISOString().slice(0, 10)?((currentTime >= morningStart && currentTime <= morningEnd) ?
    'morning'
    : (currentTime >= afternoonStart && currentTime <= afternoonEnd) ?
      'afternoon'
      : (currentTime >= eveningStart && currentTime <= eveningEnd) ?
        'evening'
        : (currentTime >= nightStart && currentTime <= nightEnd) ?
          'night' : ""):"night")

  const [totalMed, setTotalMed] = useState(0)
  const [totalLqd, setTotalLqd] = useState(0)
  const [totalFd, setTotalFd] = useState(0)
  const [total, setTotal] = useState(0)
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const annotationPadding = 0.05;
  const [medFlag, setMedFlag] = useState(false)
  const [fdFlag, setFdFlag] = useState(false)
  const [lqFlag, setLqFlag] = useState(false)
  const [conFlag, setConFlag] = useState(false)
  const [testFlag, setTestFlag] = useState(false)
  const [scanFlag, setScanFlag] = useState(false)
  const [proFlag, setProFlag] = useState(false)
  const [wsFlag, setWSFlag] = useState("")
  const [wsrefresh, setWSRefresh] = useState(false)
  const [socketLoaded, setSocketLoaded] = useState(false);

  useEffect(() => {
    if (lastUpdated) {
      const updateElapsedTime = () => {
        setTimeAgo(moment().diff(moment(lastUpdated), 'minutes'));
      };
      updateElapsedTime();
      const interval = setInterval(updateElapsedTime, 60000);
      return () => clearInterval(interval);
    }
  }, [lastUpdated]);



  useEffect(() => {
    if (type === "medicine") {
      setCompleted(completedMed)
      setMissed(missedMed)
      setDelayed(delayedMed)
      setTotal(totalMed)
      setPending(pendingMed)
      setGraphData(graphDataMed)
      setMissedPat(missedPatMed)
      setCompletedPat(completedPatMed)
      setDelayedPat(delayedPatMed)
      setPendingPat(pendingPatMed)
      setCompletedPatId(completedPatIdMed)
      setPendingPatId(pendingPatIdMed)
      setMissedPatId(missedPatIdMed)
      setDelayedPatId(delayedPatIdMed)
    }
    else if (type === "food") {
      setCompleted(completedFd)
      setMissed(missedFd)
      setDelayed(delayedFd)
      setTotal(totalFd)
      setPending(pendingFd)
      setGraphData(graphDataFd)
      setMissedPat(missedPatFd)
      setCompletedPat(completedPatFd)
      setPendingPat(pendingPatFd)
      setDelayedPat(delayedPatFd)
      setCompletedPatId(completedPatIdFd)
      setPendingPatId(pendingPatIdFd)
      setMissedPatId(missedPatIdFd)
      setDelayedPatId(delayedPatIdFd)
    }
    else if (type === "liquid") {
      setCompleted(completedLqd)
      setMissed(missedLqd)
      setDelayed(delayedLqd)
      setTotal(totalLqd)
      setPending(pendingLqd)
      setGraphData(graphDataLqd)
      setMissedPat(missedPatLq)
      setCompletedPat(completedPatLq)
      setDelayedPat(delayedPatLq)
      setPendingPat(pendingPatLqd)
      setCompletedPatId(completedPatIdLq)
      setPendingPatId(pendingPatIdLq)
      setMissedPatId(missedPatIdLq)
      setDelayedPatId(delayedPatIdLq)
    }

    else if (type === "consumables") {
      setCompleted(completedCon)
      setMissed(missedCon)
      setTotal(totalCon)
      setPending(pendingCon)
      setGraphData(graphDataCon)
      setMissedPat(missedPatCon)
      setPendingPat(pendingPatCon)
      setCompletedPat(completedPatCon)
      setCompletedPatId(completedPatIdCon)
      setPendingPatId(pendingPatIdCon)
      setMissedPatId(missedPatIdCon)
    }
    else if (type === "test") {
      setCompleted(completedTest)
      setMissed(missedTest)
      setTotal(totalTest)
      setPending(pendingTest)
      setGraphData(graphDataTest)
      setMissedPat(missedPatTest)
      setPendingPat(pendingPatTest)
      setCompletedPat(completedPatTest)
      setCompletedPatId(completedPatIdTest)
      setPendingPatId(pendingPatIdTest)
      setMissedPatId(missedPatIdTest)
    }
    else if (type === "scan") {
      setCompleted(completedScan)
      setMissed(missedScan)
      setTotal(totalScan)
      setPending(pendingScan)
      setGraphData(graphDataScan)
      setMissedPat(missedPatScan)
      setPendingPat(pendingPatScan)
      setCompletedPat(completedPatScan)
      setCompletedPatId(completedPatIdScan)
      setPendingPatId(pendingPatIdScan)
      setMissedPatId(missedPatIdScan)
    }
    else if (type === "procedure") {
      setCompleted(completedPro)
      setMissed(missedPro)
      setTotal(totalPro)
      setPending(pendingPro)
      setGraphData(graphDataPro)
      setMissedPat(missedPatPro)
      setPendingPat(pendingPatPro)
      setCompletedPat(completedPatPro)
      setCompletedPatId(completedPatIdPro)
      setPendingPatId(pendingPatIdPro)
      setMissedPatId(missedPatIdPro)
    }

  }, [refresh, graphDataMed, graphDataFd, graphDataLqd, graphDataCon, graphDataScan, graphDataPro, graphDataTest])
  useEffect(() => {
    if (wsFlag === "medication_monitor") {
      if (props.date1 === new Date().toISOString().slice(0, 10) && type === "medicine") {
        handlingAll()
      }
      else {
        setMedFlag(true)
      }
    }
    if (wsFlag === "food_monitor") {
      if (props.date1 === new Date().toISOString().slice(0, 10) && type === "food") {
        handlingAll()
      }
      else {
        setFdFlag(true)
      }
    }
    if (wsFlag === "liquid_monitor") {
      if (props.date1 === new Date().toISOString().slice(0, 10) && type === "liquid") {
        handlingAll()
      }
      else {
        setLqFlag(true)
      }
    }
    if (wsFlag === "consumables_monitor") {
      if (props.date1 === new Date().toISOString().slice(0, 10) && type === "consumables") {
        handlingAll()
      }
      else {
        setConFlag(true)
      }
    }
    if (wsFlag === "test_monitor") {
      if (props.date1 === new Date().toISOString().slice(0, 10) && type === "test") {
        handlingAll()
      }
      else {
        setTestFlag(true)
      }
    }
    if (wsFlag === "scan_monitor") {
      if (props.date1 === new Date().toISOString().slice(0, 10) && type === "scan") {
        handlingAll()
      }
      else {
        setScanFlag(true)
      }
    }
    if (wsFlag === "procedure_monitor") {
      if (props.date1 === new Date().toISOString().slice(0, 10) && type === "procedure") {
        handlingAll()
      }
      else {
        setProFlag(true)
      }
    }
  }, [wsrefresh])

  const handlingWebsocket = () => {
    if (socketLoaded) {
      return;
    }
    const tokenString = sessionStorage.getItem("usertoken");
    const hospId = sessionStorage.getItem("hospId");
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(
      `${SOCKETURL}/ws/pharmacy_request/sp_` +
      hospId +
      "/?token=" +
      str
    );
    webSocket.current.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log("WebSocket connected inside servicereq ");
      setSocketLoaded(true);
    };

    webSocket.current.onmessage = (evt) => {

      const message = JSON.parse(evt.data);
      console.log(message);

      if (message.message.message_type === "medication_monitor") {
        setWSFlag("medication_monitor")

        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "food_monitor") {
        setWSFlag("food_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "liquid_monitor") {
        setWSFlag("liquid_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "consumables_monitor") {
        setWSFlag("consumables_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "test_monitor") {
        setWSFlag("test_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "scan_monitor") {
        setWSFlag("scan_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "procedure_monitor") {
        setWSFlag("procedure_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }

    };

    webSocket.current.onclose = () => {
      console.log("disconnected");

    };
  }
  useEffect(() => {
    setGraphData([])
    handlingGraph();
    handlingCard();
  }, [refresh1,type,serveTime,props.id])
  useEffect(() => {
    if (type === "medicine" && props.date1 === new Date().toISOString().slice(0, 10)) {
      setMedFlag(false)
    }
    else if (type === "food" && props.date1 === new Date().toISOString().slice(0, 10)) {
      setFdFlag(false)
    }
    else if (type === "liquid" && props.date1 === new Date().toISOString().slice(0, 10)) {
      setLqFlag(false)
    }
    else if (type === "consumables" && props.date1 === new Date().toISOString().slice(0, 10)) {
      setConFlag(false)
    }
    else if (type === "test" && props.date1 === new Date().toISOString().slice(0, 10)) {
      setTestFlag(false)
    }
    else if (type === "scan" && props.date1 === new Date().toISOString().slice(0, 10)) {
      setScanFlag(false)
    }
    else if (type === "procedure" && props.date1 === new Date().toISOString().slice(0, 10)) {
      setProFlag(false)
    }
    setRefresh1(!refresh1)
  }, [type, props.date1])

  const handlingAll = () => { handlingGraph(); handlingCard() }

  useEffect(() => {
    handlingWebsocket();
  }, [])

  const handlingCard = () => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')



    axios.get(`${APIURL}/api/v1/service-provider/current-drug-admin/?serv_time=${serveTime}&category=${type}&date=${props.date1}&bedunit_data=${props.id}&${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        setLastUpdated(new Date());
        if (res.data.status === "success") {
          if (type === "medicine") {
            setCompletedMed(res.data.med_completed)
            setMissedMed(res.data.med_missed)
            setDelayedMed(res.data.med_delayed)
            setTotalMed(res.data.med_total)
            setPendingMed(res.data.med_pending)
            setPendingPatMed(res.data.pending_pat_count)
            setCompletedPatMed(res.data.completed_pat_count)
            setMissedPatMed(res.data.missed_pat_count)
            setDelayedPatMed(res.data.delayed_pat_count)
            setCompletedPatIdMed(res.data.completed_pat_ids)
            setMissedPatIdMed(res.data.missed_pat_ids)
            setPendingPatIdMed(res.data.pending_pat_ids)
            setDelayedPatIdMed(res.data.delayed_pat_ids)
          }
          else if (type === "food") {
            setCompletedFd(res.data.food_completed)
            setMissedFd(res.data.food_missed)
            setDelayedFd(res.data.food_delayed)
            setTotalFd(res.data.food_total)
            setPendingFd(res.data.food_pending)
            setPendingPatFd(res.data.pending_pat_count)
            setCompletedPatFd(res.data.completed_pat_count)
            setMissedPatFd(res.data.missed_pat_count)
            setDelayedPatFd(res.data.delayed_pat_count)
            setCompletedPatIdFd(res.data.completed_pat_ids)
            setMissedPatIdFd(res.data.missed_pat_ids)
            setPendingPatIdFd(res.data.pending_pat_ids)
            setDelayedPatIdFd(res.data.delayed_pat_ids)
          }
          else if (type === "liquid") {
            setCompletedLqd(res.data.liquid_completed)
            setMissedLqd(res.data.liquid_missed)
            setDelayedLqd(res.data.liquid_delayed)
            setTotalLqd(res.data.liquid_total)
            setPendingLqd(res.data.liquid_pending)
            setPendingPatLq(res.data.pending_pat_count)
            setCompletedPatLq(res.data.completed_pat_count)
            setMissedPatLq(res.data.missed_pat_count)
            setDelayedPatLq(res.data.delayed_pat_count)
            setCompletedPatIdLq(res.data.completed_pat_ids)
            setMissedPatIdLq(res.data.missed_pat_ids)
            setPendingPatIdLq(res.data.pending_pat_ids)
            setDelayedPatIdLq(res.data.delayed_pat_ids)
          }
          else if (type === "consumables") {
            setCompletedCon(res.data.completed)
            setMissedCon(res.data.missed)
            setTotalCon(res.data.total)
            setPendingCon(res.data.pending)
            setCompletedPatCon(res.data.completed_pat_count)
            setMissedPatCon(res.data.missed_pat_count)
            setPendingPatCon(res.data.pending_pat_count)
            setCompletedPatIdCon(res.data.completed_pat_ids)
            setMissedPatIdCon(res.data.missed_pat_ids)
            setPendingPatIdCon(res.data.pending_pat_ids)
          }
          else if (type === "test") {
            setCompletedTest(res.data.completed)
            setMissedTest(res.data.missed)
            setTotalTest(res.data.total)
            setPendingTest(res.data.pending)
            setCompletedPatTest(res.data.completed_pat_count)
            setMissedPatTest(res.data.missed_pat_count)
            setPendingPatTest(res.data.pending_pat_count)
            setCompletedPatIdTest(res.data.completed_pat_ids)
            setMissedPatIdTest(res.data.missed_pat_ids)
            setPendingPatIdTest(res.data.pending_pat_ids)
          }
          else if (type === "scan") {
            setCompletedScan(res.data.completed)
            setMissedScan(res.data.missed)
            setTotalScan(res.data.total)
            setPendingScan(res.data.pending)
            setCompletedPatScan(res.data.completed_pat_count)
            setMissedPatScan(res.data.missed_pat_count)
            setPendingPatScan(res.data.pending_pat_count)
            setCompletedPatIdScan(res.data.completed_pat_ids)
            setMissedPatIdScan(res.data.missed_pat_ids)
            setPendingPatIdScan(res.data.pending_pat_ids)
          }
          else if (type === "procedure") {
            setCompletedPro(res.data.completed)
            setMissedPro(res.data.missed)
            setTotalPro(res.data.total)
            setPendingPro(res.data.pending)
            setCompletedPatPro(res.data.completed_pat_count)
            setMissedPatPro(res.data.missed_pat_count)
            setPendingPatPro(res.data.pending_pat_count)
            setCompletedPatIdPro(res.data.completed_pat_ids)
            setMissedPatIdPro(res.data.missed_pat_ids)
            setPendingPatIdPro(res.data.pending_pat_ids)
          }
        }
        setRefresh(!refresh)

      })
      .catch(err => {

      })
  }

  const handlingGraph = () => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    let data = []

    axios.get(`${APIURL}/api/v1/service-provider/administer-graph/?category=${type}&date=${props.date1}&bedunit_data=${props.id}&timeZone=${currentTimezone}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          res.data.message.length > 0 && res.data.message.map((item) => {

            data = ([...data, { x: item.administered_time.slice(0, 19), y: item.patient_id, nurse: item.administered_by }])
          })

        }
        if (type === "medicine") {
          setGraphDataMed(data)

        }
        else if (type === "food") {
          setGraphDataFd(data)

        }
        else if (type === "liquid") {
          setGraphDataLqd(data)

        }
        else if (type === "consumables") {
          setGraphDataCon(data)

        }
        else if (type === "test") {
          setGraphDataTest(data)

        }
        else if (type === "scan") {
          setGraphDataScan(data)

        }
        else if (type === "procedure") {
          setGraphDataPro(data)

        }

      })
      .catch(err => {

      })


  }



  const formatNumber = (number) => {
    const roundedNumber = parseFloat(number).toFixed(2);
    return roundedNumber.endsWith('.00') ? roundedNumber.split('.')[0] : roundedNumber;
  };
  useEffect(() => {
    if (total > 0 && completed > 0) {
      let n = formatNumber(completed / total * 100)
      setCompletedP(n)
    }
    else setCompletedP(0)
    if (total > 0 && pending > 0) {
      let n = formatNumber(pending / total * 100)
      setPendingP(n)
    }
    else setPendingP(0)
    if (total > 0 && missed > 0) {
      let n = formatNumber(missed / total * 100)
      setMissedP(n)
    }
    else setMissedP(0)
    if (total > 0 && delayedd > 0) {
      let n = formatNumber(delayedd / total * 100)
      setDelayedP(n)
    }
    else setDelayedP(0)
  }, [completed, total, pending, missed, delayedd])

  const getColor = (context) => {
    const value = context.dataset.data[context.dataIndex];


    return "#28a745";
  };

  const getRadius = (context) => {
    const value = context.dataset.data[context.dataIndex];
    return 5;
  };
  const plugins = {

    tooltip: {

      callbacks: {

        label: function (context) {
          const data = context.dataset.data[context.dataIndex];
          return `Service: ${data.x}, Count: ${data.y}`;
        },
      },
    },

    afterDatasetsDraw: (chartInstance) => {
      var ctx = chartInstance.chart.ctx;
      chartInstance.data.datasets.forEach((dataset, i) => {
        var meta = chartInstance.getDatasetMeta(i);
        if (!meta.hidden) {
          meta.data.forEach((element, index) => {
            ctx.fillStyle = "white";
            var dataString = dataset.data[index].count.toString() + "人";
            ctx.font = "bold 11px arial";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var position = element.tooltipPosition();
            ctx.fillText(dataString, position.x, position.y);
          });
        }
      });
    }

  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const e = context.dataset.data[context.dataIndex];
            return (e.y, e.nurse);
          },
        },
      },
    },
    elements: {
      bubble: {
        label: (context) => {
        
          return context.raw.service
        }
      },
      point: {
        backgroundColor: getColor.bind(null),
        radius: getRadius.bind(null)
      }
    },
    tooltips: {
      callbacks: {
        label: (context) => {

        }
      }
    },

    scales: {
      x: {
        type: 'time',  // Set the x-axis scale to 'time'

        time: {
          unit: 'hour',
          tooltipFormat: 'h:mm a',
          displayFormats: {
            hour: 'h:mm a',
            timezone: { currentTimezone },
          },

        },
        min: startTime,
        max: endTime,
        title: {
          display: true,
          text: 'Time',
        },
        grid: {
          display: false, // hide x-axis grid lines
        },

      },
      y: {
        type: 'linear',
        ticks: {
          // Set the step size to 1 to display only integer values
          stepSize: 1,
        },
        
        max: graphData.length>0?graphData[0].y+20:1,
        title: {
          display: true,
          text: 'Patient ID',
        },

        grid: {
          display: false, // hide x-axis grid lines
        },
      },
    },
    annotation: {
      annotations: [
        {
          type: "box",
          drawTime: "beforeDatasetsDraw",
          xScaleID: "x-axis-0",
          yScaleID: "y-axis-0",
          xMin: 2 + annotationPadding,
          xMax: 5 - annotationPadding,
          yMin: 4 + annotationPadding,
          yMax: 6 - annotationPadding,
          borderWidth: 2,
          backgroundColor: "rgba(255, 99, 132, 0.25)"
        },
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: 4.5,
          xScaleID: "x-axis-0",
          yScaleID: "y-axis-0",
          xMin: 2 + annotationPadding,
          yMin: 4 + annotationPadding,
          borderColor: "transparent",
          backgroundColor: "transparent",
          label: {
            backgroundColor: "transparent",
            fontFamily: "sans-serif",
            fontSize: 12,
            fontStyle: "bold",
            fontColor: "red",
            xPadding: 12,
            yPadding: 6,
            cornerRadius: 4,
            position: "center",
            yAdjust: -100,
            enabled: true,
            content: "Test Label"
          }
        }
      ]
    }
  };

  const data = {
    datasets: [
      {

        label: "Nurses on duty",
        data: graphData

      }
    ]
  };
  useEffect(() => {
    if (props.date.toISOString().slice(0, 10) === new Date().toISOString().slice(0, 10)) {
      if (currentTime >= morningStart && currentTime <= morningEnd) {
        setServeTime('morning')
      } else if (currentTime >= afternoonStart && currentTime <= afternoonEnd) {
        setServeTime('afternoon')
      } else if (currentTime >= eveningStart && currentTime <= eveningEnd) {
        setServeTime('evening')
      } else if (currentTime >= nightStart && currentTime <= nightEnd) {
        setServeTime('night')
      }
    }
    else { setServeTime("night") }

    if (props.date !== "") {
      props.setDate1(props.date.toISOString().slice(0, 10))
    }
    else {
      props.setDate1("")
    }
    setStartTime(new Date(props.date).setHours(1, 0, 0, 0))
    setEndTime(new Date(props.date).setHours(24, 0, 0, 0))
  }, [props.date, props.date1])

  return (
    <>
      <Container fluid style={{ paddingTop: "1%" }}>
      <Card>
      <Card.Title as="h4" style={{ marginTop: "1%" }}><b>&nbsp;&nbsp;
                    Drug Administration Tracker</b>  <div onClick={() => { props.setDate(new Date()); setType("medicine"); }} style={{ padding:"0.2%",fontSize:"12px",border: medFlag ? "2px solid red":"2px solid rgb(43, 147, 160)",cursor:"pointer",borderRadius:"10px",float:"right",marginRight:"2%"}} className="icons-right-not newMed">&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M8.6 16.4a4.6 4.6 0 0 1-6.5-6.5l7.8-7.8a4.6 4.6 0 0 1 6.5 6.5M5.999 5.999l4.25 4.25" /><path d="M23.25 15.75a7.669 7.669 0 0 1-6 7.5a7.669 7.669 0 0 1-6-7.5V13.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v2.25Zm-6-.75v4.5M15 17.25h4.5" /></g></svg>&nbsp;Update&nbsp;&nbsp;</div>
           
            </Card.Title>
                  
                  <Card.Body>
        <div className="row">
          <div className="col-12">
            <Row>
              <Col lg={(type === "medicine" || type === "food" || type === "liquid") ? "3" : "4"} sm="6">
                <Card className="card-stats " style={{ borderRadius: "5px", overflow: "hidden" }} >
                  <Card.Body style={{ padding: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: "10px 30px", backgroundColor: "#36B73B" }}>
                      <label style={{ color: "#fff", fontSize: "20px", fontWeight: 500, textTransform: "capitalize" }}>Completed</label>
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                        <circle cx="17" cy="17" r="15.25" fill="#36B73B" stroke="#fff" stroke-width="1.5" />
                        <path d="M5.28125 18.2812L9.96875 22.9688M16.5312 15.4688L21.2188 10.7812M12.7812 18.2812L17.4688 22.9688L28.7188 10.7812" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                    <div style={{ display: 'flex', padding: "10px" }}>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div style={{ height: "35px", width: "35px", backgroundColor: "#36b73b80", borderRadius: "50%", alignContent: "center", textAlign: "center" }}>
                          {type === "medicine" ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5103 11.9157C18.8771 12.5414 19.1165 13.2336 19.2148 13.9522C19.3131 14.6709 19.2682 15.4019 19.0828 16.1031C18.8974 16.8044 18.5751 17.462 18.1345 18.0382C17.6939 18.6144 17.1437 19.0977 16.5156 19.4604C15.8874 19.8231 15.1937 20.0579 14.4744 20.1514C13.7551 20.2449 13.0244 20.1952 12.3244 20.0052C11.6244 19.8151 10.969 19.4885 10.3957 19.0441C9.82245 18.5997 9.34276 18.0463 8.98426 17.4157M18.5103 11.9157C17.7757 10.6628 16.5754 9.75122 15.1713 9.37999C13.7672 9.00876 12.2732 9.20799 11.0154 9.93419C9.75766 10.6604 8.83817 11.8546 8.45764 13.2562C8.0771 14.6579 8.26641 16.1531 8.98426 17.4157M18.5103 11.9157L8.98426 17.4157M9.20609 5.08012L13.8673 2.32553C15.5833 1.3117 17.7778 1.91303 18.7688 3.67028C19.1474 4.34265 19.3089 5.11568 19.2309 5.8834C19.1529 6.65112 18.8394 7.37593 18.3333 7.95845M9.20609 5.08012L4.54484 7.8347C2.82884 8.84853 2.24034 11.0944 3.23126 12.8507C3.50816 13.3488 3.90014 13.7736 4.37453 14.0895C4.84891 14.4053 5.39194 14.6032 5.95834 14.6666M9.20609 5.08012L10.5417 7.33328" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg> : type === "food" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18 3a1 1 0 0 1 .993.883L19 4v16a1 1 0 0 1-1.993.117L17 20v-5h-1a1 1 0 0 1-.993-.883L15 14V8c0-2.21 1.5-5 3-5Zm-6 0a1 1 0 0 1 .993.883L13 4v5a4.002 4.002 0 0 1-3 3.874V20a1 1 0 0 1-1.993.117L8 20v-7.126a4.002 4.002 0 0 1-2.995-3.668L5 9V4a1 1 0 0 1 1.993-.117L7 4v5a2 2 0 0 0 1 1.732V4a1 1 0 0 1 1.993-.117L10 4l.001 6.732a2 2 0 0 0 .992-1.563L11 9V4a1 1 0 0 1 1-1Z" />
                          </svg> : type === "liquid" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18.32 8H5.67l-.44-4h13.54M12 19a3 3 0 0 1-3-3c0-2 3-5.4 3-5.4s3 3.4 3 5.4a3 3 0 0 1-3 3M3 2l2 18.23c.13 1 .97 1.77 2 1.77h10c1 0 1.87-.77 2-1.77L21 2H3Z" />
                          </svg> : type === "consumables" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                              <path d="M22.003 3v4.497A.503.503 0 0 1 21.5 8v0a.52.52 0 0 1-.466-.3A10 10 0 0 0 12.003 2c-5.185 0-9.449 3.947-9.95 9" />
                              <path d="M17 10v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2m-5 1V8" />
                              <path d="M2.05 21v-4.497c0-.278.226-.503.504-.503v0c.2 0 .38.119.466.3a10.001 10.001 0 0 0 9.03 5.7c5.186 0 9.45-3.947 9.952-9" />
                            </g>
                          </svg> : type === "test" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="none" stroke="#fff" d="M2 23.5h20M8.646 15a4.5 4.5 0 1 0 4.262-7.408M5.124 15a7.519 7.519 0 0 0 4.84 4.22M6.5 9.5L8 11m-6 2.5h9m4.258-8.258l.712-.712a1.81 1.81 0 0 0 0-2.56l-.94-.94a1.81 1.81 0 0 0-2.56 0L7 6.5l3.5 3.5l2.408-2.408m2.35-2.35a7.503 7.503 0 0 1-1.222 13.978m1.222-13.978l-2.35 2.35M9.964 19.22a3.235 3.235 0 0 1 4.072 0m-4.072 0a3.233 3.233 0 0 0-.858 1.069L8.5 21.5s-1 2-3 2h13c-2 0-3-2-3-2l-.606-1.211a3.234 3.234 0 0 0-.858-1.069M15.5 1.5L16.6.4" />
                          </svg> : type === "scan" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="none" stroke="#fff" d="M3.765 7A10.498 10.498 0 0 1 13 1.5c5.799 0 10.5 4.701 10.5 10.5S18.799 22.5 13 22.5A10.503 10.503 0 0 1 3.289 16M18 16H3.289M0 16h3.289M2 13h9.5v-1a3 3 0 0 0-3-3c-.54 0-4.692.437-8.4.84m13.4 1.56s1 1.6 2.25 1.6a1.75 1.75 0 0 0 1.75-1.75c0-.966-.784-1.746-1.75-1.746c-1.25 0-2.25 1.596-2.25 1.596z" />
                          </svg> : type === "procedure" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18.27 10.73q-.74 0-1.622-.46q-.883-.46-1.673-1.276q-.79-.815-1.251-1.698q-.46-.883-.46-1.634q0-.233.048-.4q.048-.168.144-.264q.534-.535 2.233-.907q1.7-.372 3.546-.354q.298.011.49.063q.19.052.294.156q.098.098.153.278t.066.46q.03 1.86-.32 3.56t-.891 2.24q-.115.116-.31.176t-.448.06m.51-2.326q.223-.68.334-1.594q.112-.914.156-2.08q-.98-.024-1.897.1q-.916.124-1.685.366q.525.296.97.617q.445.322.824.681q.442.423.77.904q.328.48.528 1.006m-.51 1.327q-.129-.596-.519-1.267q-.39-.67-.983-1.256q-.586-.587-1.231-.965q-.646-.378-1.242-.512q.05.575.425 1.29q.375.716.944 1.285q.6.6 1.303.987q.703.388 1.303.438m3.031 8.257L16.812 13.5h-10l-4.8-4.8l.688-.688L7.188 12.5h10l4.8 4.8zM8.5 20.981v-3.385q0-.671.472-1.143t1.143-.472h3.77q.67 0 1.143.472q.472.472.472 1.143v3.385zm1-1h5v-2.385q0-.27-.173-.442t-.442-.173h-3.77q-.269 0-.442.173t-.173.442zm0 0v-3z" />
                          </svg> : ""}
                        </div>&nbsp;&nbsp;
                        <label style={{ margin: 0, fontWeight: 700, fontSize: "20px", color: "#000" }}>{completed}</label>
                      </div>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div style={{ height: "35px", width: "35px", backgroundColor: "#36b73b80", borderRadius: "50%", alignContent: "center", textAlign: "center" }}>
                          {completedPatId.length > 0 ? (
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={
                                <Tooltip1 className="tap-tooltip custom-tooltip" id="button-tooltip">
                                  <PatientsList data={completedPatId} />
                                </Tooltip1>
                              }
                            >
                              <svg style={{ cursor: 'pointer', borderRadius: "50%" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="26" height="26" fill="#AFAFAF" />
                                <g clip-path="url(#clip0_558_1232)">
                                  <rect width="1440" height="1024" transform="translate(-505 -168)" fill="white" />
                                  <rect x="-134" y="-71" width="225" height="151" rx="5" fill="white" />
                                  <rect x="-133.75" y="-70.75" width="224.5" height="150.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                                  <rect x="-3" y="-3" width="32" height="32" rx="16" fill="#36B73B" fill-opacity="0.5" />
                                  <path d="M13.1812 0.000231381C5.86999 -0.0985186 -0.0987628 5.87023 -1.27598e-05 13.1815C0.0974872 20.1934 5.80686 25.9027 12.8187 26.0002C20.1312 26.1002 26.0987 20.1315 25.9987 12.8202C25.9025 5.80711 20.1931 0.0977314 13.1812 0.000231381ZM21.0825 20.4534C21.0576 20.4803 21.0271 20.5014 20.9931 20.5152C20.9591 20.529 20.9225 20.5352 20.8859 20.5333C20.8493 20.5314 20.8136 20.5214 20.7812 20.5042C20.7489 20.4869 20.7207 20.4627 20.6987 20.4334C20.1397 19.702 19.4551 19.0758 18.6769 18.584C17.0856 17.5627 15.0694 17.0002 13 17.0002C10.9306 17.0002 8.91436 17.5627 7.32311 18.584C6.54487 19.0755 5.86026 19.7015 5.30124 20.4327C5.27927 20.4621 5.25112 20.4863 5.21877 20.5035C5.18642 20.5208 5.15067 20.5308 5.11405 20.5327C5.07743 20.5346 5.04084 20.5284 5.00687 20.5146C4.9729 20.5008 4.94238 20.4797 4.91749 20.4527C3.08353 18.473 2.04467 15.886 1.99999 13.1877C1.89811 7.10586 6.88874 2.01523 12.9731 2.00023C19.0575 1.98523 24 6.92586 24 13.0002C24.0021 15.7636 22.96 18.4257 21.0825 20.4534Z" fill="white" />
                                  <path d="M13 6C11.7675 6 10.6532 6.46188 9.86128 7.30125C9.0694 8.14063 8.67378 9.30125 8.76316 10.5469C8.94441 13 10.845 15 13 15C15.155 15 17.0519 13 17.2369 10.5475C17.3294 9.31375 16.9369 8.16375 16.1319 7.30875C15.3369 6.465 14.2244 6 13 6Z" fill="white" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_558_1232">
                                    <rect width="1440" height="1024" fill="white" transform="translate(-505 -168)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </OverlayTrigger>
                          ) : (
                            <svg style={{ cursor: 'not-allowed', borderRadius: "50%" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="26" height="26" fill="#AFAFAF" />
                              <g clip-path="url(#clip0_558_1232)">
                                <rect width="1440" height="1024" transform="translate(-505 -168)" fill="white" />
                                <rect x="-134" y="-71" width="225" height="151" rx="5" fill="white" />
                                <rect x="-133.75" y="-70.75" width="224.5" height="150.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                                <rect x="-3" y="-3" width="32" height="32" rx="16" fill="#36B73B" fill-opacity="0.5" />
                                <path d="M13.1812 0.000231381C5.86999 -0.0985186 -0.0987628 5.87023 -1.27598e-05 13.1815C0.0974872 20.1934 5.80686 25.9027 12.8187 26.0002C20.1312 26.1002 26.0987 20.1315 25.9987 12.8202C25.9025 5.80711 20.1931 0.0977314 13.1812 0.000231381ZM21.0825 20.4534C21.0576 20.4803 21.0271 20.5014 20.9931 20.5152C20.9591 20.529 20.9225 20.5352 20.8859 20.5333C20.8493 20.5314 20.8136 20.5214 20.7812 20.5042C20.7489 20.4869 20.7207 20.4627 20.6987 20.4334C20.1397 19.702 19.4551 19.0758 18.6769 18.584C17.0856 17.5627 15.0694 17.0002 13 17.0002C10.9306 17.0002 8.91436 17.5627 7.32311 18.584C6.54487 19.0755 5.86026 19.7015 5.30124 20.4327C5.27927 20.4621 5.25112 20.4863 5.21877 20.5035C5.18642 20.5208 5.15067 20.5308 5.11405 20.5327C5.07743 20.5346 5.04084 20.5284 5.00687 20.5146C4.9729 20.5008 4.94238 20.4797 4.91749 20.4527C3.08353 18.473 2.04467 15.886 1.99999 13.1877C1.89811 7.10586 6.88874 2.01523 12.9731 2.00023C19.0575 1.98523 24 6.92586 24 13.0002C24.0021 15.7636 22.96 18.4257 21.0825 20.4534Z" fill="white" />
                                <path d="M13 6C11.7675 6 10.6532 6.46188 9.86128 7.30125C9.0694 8.14063 8.67378 9.30125 8.76316 10.5469C8.94441 13 10.845 15 13 15C15.155 15 17.0519 13 17.2369 10.5475C17.3294 9.31375 16.9369 8.16375 16.1319 7.30875C15.3369 6.465 14.2244 6 13 6Z" fill="white" />
                              </g>
                              <defs>
                                <clipPath id="clip0_558_1232">
                                  <rect width="1440" height="1024" fill="white" transform="translate(-505 -168)" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </div>&nbsp;&nbsp;
                        <label style={{ margin: 0, fontWeight: 700, fontSize: "20px", color: "#000" }}>{completedPat}</label>
                      </div>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <ProgressBar className="comp" style={{ width: "100%", height: "15px" }} now={completedP} label={completedP + "%"} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={(type === "medicine" || type === "food" || type === "liquid") ? "3" : "4"} sm="6">
                <Card className="card-stats " style={{ borderRadius: "5px", overflow: "hidden" }} >
                  <Card.Body style={{ padding: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: "10px 30px", backgroundColor: "#17A1FA" }}>
                      <label style={{ color: "#fff", fontSize: "20px", fontWeight: 500, textTransform: "capitalize" }}>Pending</label>
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                        <path fill="#fff" d="M6.998 13q.415 0 .709-.291q.293-.291.293-.707q0-.415-.291-.709Q7.418 11 7.002 11q-.415 0-.709.291q-.293.291-.293.707q0 .415.291.709q.291.293.707.293Zm5 0q.415 0 .709-.291q.293-.291.293-.707q0-.415-.291-.709q-.291-.293-.707-.293q-.415 0-.709.291q-.293.291-.293.707q0 .415.291.709q.291.293.707.293Zm5 0q.415 0 .709-.291q.293-.291.293-.707q0-.415-.291-.709q-.291-.293-.707-.293q-.415 0-.709.291q-.293.291-.293.707q0 .415.291.709q.291.293.707.293Zm-4.995 8q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21ZM12 20q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z" />
                      </svg>
                    </div>
                    <div style={{ display: 'flex', padding: "10px" }}>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div style={{ height: "35px", width: "35px", backgroundColor: "#17A1FA80", borderRadius: "50%", alignContent: "center", textAlign: "center" }}>
                          {type === "medicine" ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5103 11.9157C18.8771 12.5414 19.1165 13.2336 19.2148 13.9522C19.3131 14.6709 19.2682 15.4019 19.0828 16.1031C18.8974 16.8044 18.5751 17.462 18.1345 18.0382C17.6939 18.6144 17.1437 19.0977 16.5156 19.4604C15.8874 19.8231 15.1937 20.0579 14.4744 20.1514C13.7551 20.2449 13.0244 20.1952 12.3244 20.0052C11.6244 19.8151 10.969 19.4885 10.3957 19.0441C9.82245 18.5997 9.34276 18.0463 8.98426 17.4157M18.5103 11.9157C17.7757 10.6628 16.5754 9.75122 15.1713 9.37999C13.7672 9.00876 12.2732 9.20799 11.0154 9.93419C9.75766 10.6604 8.83817 11.8546 8.45764 13.2562C8.0771 14.6579 8.26641 16.1531 8.98426 17.4157M18.5103 11.9157L8.98426 17.4157M9.20609 5.08012L13.8673 2.32553C15.5833 1.3117 17.7778 1.91303 18.7688 3.67028C19.1474 4.34265 19.3089 5.11568 19.2309 5.8834C19.1529 6.65112 18.8394 7.37593 18.3333 7.95845M9.20609 5.08012L4.54484 7.8347C2.82884 8.84853 2.24034 11.0944 3.23126 12.8507C3.50816 13.3488 3.90014 13.7736 4.37453 14.0895C4.84891 14.4053 5.39194 14.6032 5.95834 14.6666M9.20609 5.08012L10.5417 7.33328" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg> : type === "food" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18 3a1 1 0 0 1 .993.883L19 4v16a1 1 0 0 1-1.993.117L17 20v-5h-1a1 1 0 0 1-.993-.883L15 14V8c0-2.21 1.5-5 3-5Zm-6 0a1 1 0 0 1 .993.883L13 4v5a4.002 4.002 0 0 1-3 3.874V20a1 1 0 0 1-1.993.117L8 20v-7.126a4.002 4.002 0 0 1-2.995-3.668L5 9V4a1 1 0 0 1 1.993-.117L7 4v5a2 2 0 0 0 1 1.732V4a1 1 0 0 1 1.993-.117L10 4l.001 6.732a2 2 0 0 0 .992-1.563L11 9V4a1 1 0 0 1 1-1Z" />
                          </svg> : type === "liquid" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18.32 8H5.67l-.44-4h13.54M12 19a3 3 0 0 1-3-3c0-2 3-5.4 3-5.4s3 3.4 3 5.4a3 3 0 0 1-3 3M3 2l2 18.23c.13 1 .97 1.77 2 1.77h10c1 0 1.87-.77 2-1.77L21 2H3Z" />
                          </svg> : type === "consumables" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                              <path d="M22.003 3v4.497A.503.503 0 0 1 21.5 8v0a.52.52 0 0 1-.466-.3A10 10 0 0 0 12.003 2c-5.185 0-9.449 3.947-9.95 9" />
                              <path d="M17 10v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2m-5 1V8" />
                              <path d="M2.05 21v-4.497c0-.278.226-.503.504-.503v0c.2 0 .38.119.466.3a10.001 10.001 0 0 0 9.03 5.7c5.186 0 9.45-3.947 9.952-9" />
                            </g>
                          </svg> : type === "test" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="none" stroke="#fff" d="M2 23.5h20M8.646 15a4.5 4.5 0 1 0 4.262-7.408M5.124 15a7.519 7.519 0 0 0 4.84 4.22M6.5 9.5L8 11m-6 2.5h9m4.258-8.258l.712-.712a1.81 1.81 0 0 0 0-2.56l-.94-.94a1.81 1.81 0 0 0-2.56 0L7 6.5l3.5 3.5l2.408-2.408m2.35-2.35a7.503 7.503 0 0 1-1.222 13.978m1.222-13.978l-2.35 2.35M9.964 19.22a3.235 3.235 0 0 1 4.072 0m-4.072 0a3.233 3.233 0 0 0-.858 1.069L8.5 21.5s-1 2-3 2h13c-2 0-3-2-3-2l-.606-1.211a3.234 3.234 0 0 0-.858-1.069M15.5 1.5L16.6.4" />
                          </svg> : type === "scan" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="none" stroke="#fff" d="M3.765 7A10.498 10.498 0 0 1 13 1.5c5.799 0 10.5 4.701 10.5 10.5S18.799 22.5 13 22.5A10.503 10.503 0 0 1 3.289 16M18 16H3.289M0 16h3.289M2 13h9.5v-1a3 3 0 0 0-3-3c-.54 0-4.692.437-8.4.84m13.4 1.56s1 1.6 2.25 1.6a1.75 1.75 0 0 0 1.75-1.75c0-.966-.784-1.746-1.75-1.746c-1.25 0-2.25 1.596-2.25 1.596z" />
                          </svg> : type === "procedure" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18.27 10.73q-.74 0-1.622-.46q-.883-.46-1.673-1.276q-.79-.815-1.251-1.698q-.46-.883-.46-1.634q0-.233.048-.4q.048-.168.144-.264q.534-.535 2.233-.907q1.7-.372 3.546-.354q.298.011.49.063q.19.052.294.156q.098.098.153.278t.066.46q.03 1.86-.32 3.56t-.891 2.24q-.115.116-.31.176t-.448.06m.51-2.326q.223-.68.334-1.594q.112-.914.156-2.08q-.98-.024-1.897.1q-.916.124-1.685.366q.525.296.97.617q.445.322.824.681q.442.423.77.904q.328.48.528 1.006m-.51 1.327q-.129-.596-.519-1.267q-.39-.67-.983-1.256q-.586-.587-1.231-.965q-.646-.378-1.242-.512q.05.575.425 1.29q.375.716.944 1.285q.6.6 1.303.987q.703.388 1.303.438m3.031 8.257L16.812 13.5h-10l-4.8-4.8l.688-.688L7.188 12.5h10l4.8 4.8zM8.5 20.981v-3.385q0-.671.472-1.143t1.143-.472h3.77q.67 0 1.143.472q.472.472.472 1.143v3.385zm1-1h5v-2.385q0-.27-.173-.442t-.442-.173h-3.77q-.269 0-.442.173t-.173.442zm0 0v-3z" />
                          </svg> : ""}
                        </div>&nbsp;&nbsp;
                        <label style={{ margin: 0, fontWeight: 700, fontSize: "20px", color: "#000" }}>{pending}</label>
                      </div>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div style={{ height: "35px", width: "35px", backgroundColor: " #17A1FA80", borderRadius: "50%", alignContent: "center", textAlign: "center" }}>
                          {pendingPatId.length > 0 ? (
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={
                                <Tooltip1 className="tap-tooltip custom-tooltip" id="button-tooltip">
                                  <PatientsList data={pendingPatId} />
                                </Tooltip1>
                              }
                            >
                              <svg style={{ cursor: 'pointer', borderRadius: "50%" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="26" height="26" fill="#17A1FA80" />
                                <g clip-path="url(#clip0_558_1232)">
                                  <rect width="1440" height="1024" transform="translate(-505 -168)" fill="white" />
                                  <rect x="-134" y="-71" width="225" height="151" rx="5" fill="white" />
                                  <rect x="-133.75" y="-70.75" width="224.5" height="150.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                                  <rect x="-3" y="-3" width="32" height="32" rx="16" fill="#17A1FA80" />
                                  <path d="M13.1812 0.000231381C5.86999 -0.0985186 -0.0987628 5.87023 -1.27598e-05 13.1815C0.0974872 20.1934 5.80686 25.9027 12.8187 26.0002C20.1312 26.1002 26.0987 20.1315 25.9987 12.8202C25.9025 5.80711 20.1931 0.0977314 13.1812 0.000231381ZM21.0825 20.4534C21.0576 20.4803 21.0271 20.5014 20.9931 20.5152C20.9591 20.529 20.9225 20.5352 20.8859 20.5333C20.8493 20.5314 20.8136 20.5214 20.7812 20.5042C20.7489 20.4869 20.7207 20.4627 20.6987 20.4334C20.1397 19.702 19.4551 19.0758 18.6769 18.584C17.0856 17.5627 15.0694 17.0002 13 17.0002C10.9306 17.0002 8.91436 17.5627 7.32311 18.584C6.54487 19.0755 5.86026 19.7015 5.30124 20.4327C5.27927 20.4621 5.25112 20.4863 5.21877 20.5035C5.18642 20.5208 5.15067 20.5308 5.11405 20.5327C5.07743 20.5346 5.04084 20.5284 5.00687 20.5146C4.9729 20.5008 4.94238 20.4797 4.91749 20.4527C3.08353 18.473 2.04467 15.886 1.99999 13.1877C1.89811 7.10586 6.88874 2.01523 12.9731 2.00023C19.0575 1.98523 24 6.92586 24 13.0002C24.0021 15.7636 22.96 18.4257 21.0825 20.4534Z" fill="white" />
                                  <path d="M13 6C11.7675 6 10.6532 6.46188 9.86128 7.30125C9.0694 8.14063 8.67378 9.30125 8.76316 10.5469C8.94441 13 10.845 15 13 15C15.155 15 17.0519 13 17.2369 10.5475C17.3294 9.31375 16.9369 8.16375 16.1319 7.30875C15.3369 6.465 14.2244 6 13 6Z" fill="white" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_558_1232">
                                    <rect width="1440" height="1024" fill="white" transform="translate(-505 -168)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </OverlayTrigger>
                          ) : (
                            <svg style={{ cursor: 'not-allowed', borderRadius: "50%" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="26" height="26" fill="#17A1FA80" />
                              <g clip-path="url(#clip0_558_1232)">
                                <rect width="1440" height="1024" transform="translate(-505 -168)" fill="white" />
                                <rect x="-134" y="-71" width="225" height="151" rx="5" fill="white" />
                                <rect x="-133.75" y="-70.75" width="224.5" height="150.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                                <rect x="-3" y="-3" width="32" height="32" rx="16" fill="#17A1FA80" />
                                <path d="M13.1812 0.000231381C5.86999 -0.0985186 -0.0987628 5.87023 -1.27598e-05 13.1815C0.0974872 20.1934 5.80686 25.9027 12.8187 26.0002C20.1312 26.1002 26.0987 20.1315 25.9987 12.8202C25.9025 5.80711 20.1931 0.0977314 13.1812 0.000231381ZM21.0825 20.4534C21.0576 20.4803 21.0271 20.5014 20.9931 20.5152C20.9591 20.529 20.9225 20.5352 20.8859 20.5333C20.8493 20.5314 20.8136 20.5214 20.7812 20.5042C20.7489 20.4869 20.7207 20.4627 20.6987 20.4334C20.1397 19.702 19.4551 19.0758 18.6769 18.584C17.0856 17.5627 15.0694 17.0002 13 17.0002C10.9306 17.0002 8.91436 17.5627 7.32311 18.584C6.54487 19.0755 5.86026 19.7015 5.30124 20.4327C5.27927 20.4621 5.25112 20.4863 5.21877 20.5035C5.18642 20.5208 5.15067 20.5308 5.11405 20.5327C5.07743 20.5346 5.04084 20.5284 5.00687 20.5146C4.9729 20.5008 4.94238 20.4797 4.91749 20.4527C3.08353 18.473 2.04467 15.886 1.99999 13.1877C1.89811 7.10586 6.88874 2.01523 12.9731 2.00023C19.0575 1.98523 24 6.92586 24 13.0002C24.0021 15.7636 22.96 18.4257 21.0825 20.4534Z" fill="white" />
                                <path d="M13 6C11.7675 6 10.6532 6.46188 9.86128 7.30125C9.0694 8.14063 8.67378 9.30125 8.76316 10.5469C8.94441 13 10.845 15 13 15C15.155 15 17.0519 13 17.2369 10.5475C17.3294 9.31375 16.9369 8.16375 16.1319 7.30875C15.3369 6.465 14.2244 6 13 6Z" fill="white" />
                              </g>
                              <defs>
                                <clipPath id="clip0_558_1232">
                                  <rect width="1440" height="1024" fill="white" transform="translate(-505 -168)" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </div>&nbsp;&nbsp;
                        <label style={{ margin: 0, fontWeight: 700, fontSize: "20px", color: "#000" }}>{pendingPat}</label>
                      </div>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <ProgressBar className="pend" style={{ width: "100%", height: "15px" }} now={pendingP} label={pendingP + "%"} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {(type === "medicine" || type === "food" || type === "liquid") && <Col lg="3" sm="6">
                <Card className="card-stats " style={{ borderRadius: "5px", overflow: "hidden" }} >
                  <Card.Body style={{ padding: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: "10px 30px", backgroundColor: "#FF941C" }}>
                      <label style={{ color: "#fff", fontSize: "20px", fontWeight: 500, textTransform: "capitalize" }}>Delayed</label>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" fill="#AFAFAF" />
                        <g clip-path="url(#clip0_558_1232)">
                          <rect width="1440" height="1024" transform="translate(-985 -112)" fill="white" />
                          <rect x="-150" y="-16" width="225" height="152" rx="5" fill="white" />
                          <rect x="-149.75" y="-15.75" width="224.5" height="151.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                          <path d="M-150 -11C-150 -13.7614 -147.761 -16 -145 -16H70C72.7614 -16 75 -13.7614 75 -11V42H-150V-11Z" fill="#FF941C" />
                          <g clip-path="url(#clip1_558_1232)">
                            <path d="M16 0C12.8355 0 9.74206 0.938383 7.11088 2.69649C4.47969 4.45459 2.42893 6.95345 1.21793 9.87706C0.00692487 12.8007 -0.309928 16.0177 0.307435 19.1214C0.924799 22.2251 2.44865 25.0761 4.68629 27.3137C6.92393 29.5513 9.77486 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0ZM16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12531 23.9157 3.06569 21.3576C2.00606 18.7994 1.72881 15.9845 2.26901 13.2687C2.8092 10.553 4.14257 8.05844 6.1005 6.1005C8.05844 4.14257 10.553 2.8092 13.2687 2.26901C15.9845 1.72881 18.7994 2.00606 21.3576 3.06569C23.9157 4.12531 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30Z" fill="white" />
                            <path d="M16.92 16.4V8.75C16.92 8.48478 16.8146 8.23043 16.6271 8.04289C16.4396 7.85536 16.1852 7.75 15.92 7.75C15.6548 7.75 15.4004 7.85536 15.2129 8.04289C15.0254 8.23043 14.92 8.48478 14.92 8.75V17.47L20.82 21.47C20.9287 21.5517 21.0529 21.6105 21.185 21.6426C21.3172 21.6747 21.4545 21.6795 21.5886 21.6567C21.7226 21.6339 21.8507 21.584 21.9648 21.5101C22.0789 21.4362 22.1768 21.3397 22.2524 21.2267C22.328 21.1136 22.3797 20.9864 22.4044 20.8526C22.4292 20.7189 22.4263 20.5815 22.3962 20.4489C22.366 20.3163 22.3091 20.1913 22.2289 20.0814C22.1488 19.9716 22.0471 19.8792 21.93 19.81L16.92 16.4Z" fill="white" />
                            <path d="M6 15.9401C5.99085 14.1412 6.4701 12.3734 7.38663 10.8254C8.30317 9.27743 9.62263 8.00718 11.2043 7.15012C12.786 6.29306 14.5707 5.88133 16.368 5.95882C18.1653 6.0363 19.9079 6.60011 21.41 7.59012L22.26 6.23012C20.8332 5.29552 19.2172 4.68851 17.5279 4.45271C15.8387 4.21691 14.1182 4.35816 12.49 4.86631C10.8619 5.37447 9.36646 6.23692 8.11123 7.39171C6.856 8.5465 5.87211 9.96496 5.23023 11.5452C4.58836 13.1254 4.30443 14.8282 4.39886 16.5312C4.49328 18.2342 4.9637 19.8952 5.77634 21.3948C6.58898 22.8943 7.72365 24.1953 9.09885 25.2043C10.474 26.2132 12.0556 26.9051 13.73 27.2301L14 25.7001C11.748 25.2404 9.72306 24.019 8.266 22.2414C6.80895 20.4638 6.00873 18.2386 6 15.9401Z" fill="white" />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_558_1232">
                            <rect width="1440" height="1024" fill="white" transform="translate(-985 -112)" />
                          </clipPath>
                          <clipPath id="clip1_558_1232">
                            <rect width="36" height="36" fill="white" transform="translate(-2 -2)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div style={{ display: 'flex', padding: "10px" }}>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div style={{ height: "35px", width: "35px", backgroundColor: "#FF941C80", borderRadius: "50%", alignContent: "center", textAlign: "center" }}>
                          {type === "medicine" ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5103 11.9157C18.8771 12.5414 19.1165 13.2336 19.2148 13.9522C19.3131 14.6709 19.2682 15.4019 19.0828 16.1031C18.8974 16.8044 18.5751 17.462 18.1345 18.0382C17.6939 18.6144 17.1437 19.0977 16.5156 19.4604C15.8874 19.8231 15.1937 20.0579 14.4744 20.1514C13.7551 20.2449 13.0244 20.1952 12.3244 20.0052C11.6244 19.8151 10.969 19.4885 10.3957 19.0441C9.82245 18.5997 9.34276 18.0463 8.98426 17.4157M18.5103 11.9157C17.7757 10.6628 16.5754 9.75122 15.1713 9.37999C13.7672 9.00876 12.2732 9.20799 11.0154 9.93419C9.75766 10.6604 8.83817 11.8546 8.45764 13.2562C8.0771 14.6579 8.26641 16.1531 8.98426 17.4157M18.5103 11.9157L8.98426 17.4157M9.20609 5.08012L13.8673 2.32553C15.5833 1.3117 17.7778 1.91303 18.7688 3.67028C19.1474 4.34265 19.3089 5.11568 19.2309 5.8834C19.1529 6.65112 18.8394 7.37593 18.3333 7.95845M9.20609 5.08012L4.54484 7.8347C2.82884 8.84853 2.24034 11.0944 3.23126 12.8507C3.50816 13.3488 3.90014 13.7736 4.37453 14.0895C4.84891 14.4053 5.39194 14.6032 5.95834 14.6666M9.20609 5.08012L10.5417 7.33328" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg> : type === "food" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18 3a1 1 0 0 1 .993.883L19 4v16a1 1 0 0 1-1.993.117L17 20v-5h-1a1 1 0 0 1-.993-.883L15 14V8c0-2.21 1.5-5 3-5Zm-6 0a1 1 0 0 1 .993.883L13 4v5a4.002 4.002 0 0 1-3 3.874V20a1 1 0 0 1-1.993.117L8 20v-7.126a4.002 4.002 0 0 1-2.995-3.668L5 9V4a1 1 0 0 1 1.993-.117L7 4v5a2 2 0 0 0 1 1.732V4a1 1 0 0 1 1.993-.117L10 4l.001 6.732a2 2 0 0 0 .992-1.563L11 9V4a1 1 0 0 1 1-1Z" />
                          </svg> : type === "liquid" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18.32 8H5.67l-.44-4h13.54M12 19a3 3 0 0 1-3-3c0-2 3-5.4 3-5.4s3 3.4 3 5.4a3 3 0 0 1-3 3M3 2l2 18.23c.13 1 .97 1.77 2 1.77h10c1 0 1.87-.77 2-1.77L21 2H3Z" />
                          </svg> : ""}
                        </div>&nbsp;&nbsp;
                        <label style={{ margin: 0, fontWeight: 700, fontSize: "20px", color: "#000" }}>{delayedd}</label>
                      </div>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div style={{ height: "35px", width: "35px", backgroundColor: "#FF941C80", borderRadius: "50%", alignContent: "center", textAlign: "center" }}>
                          {delayedPatId.length > 0 ? (
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={
                                <Tooltip1 className="tap-tooltip custom-tooltip" id="button-tooltip">
                                  <PatientsList data={delayedPatId} />
                                </Tooltip1>
                              }
                            >
                              <svg style={{ cursor: 'pointer', borderRadius: "50%" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="26" height="26" fill="#FF941C80" />
                                <g clip-path="url(#clip0_558_1232)">
                                  <rect width="1440" height="1024" transform="translate(-505 -168)" fill="white" />
                                  <rect x="-134" y="-71" width="225" height="151" rx="5" fill="white" />
                                  <rect x="-133.75" y="-70.75" width="224.5" height="150.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                                  <rect x="-3" y="-3" width="32" height="32" rx="16" fill="#FF941C80" />
                                  <path d="M13.1812 0.000231381C5.86999 -0.0985186 -0.0987628 5.87023 -1.27598e-05 13.1815C0.0974872 20.1934 5.80686 25.9027 12.8187 26.0002C20.1312 26.1002 26.0987 20.1315 25.9987 12.8202C25.9025 5.80711 20.1931 0.0977314 13.1812 0.000231381ZM21.0825 20.4534C21.0576 20.4803 21.0271 20.5014 20.9931 20.5152C20.9591 20.529 20.9225 20.5352 20.8859 20.5333C20.8493 20.5314 20.8136 20.5214 20.7812 20.5042C20.7489 20.4869 20.7207 20.4627 20.6987 20.4334C20.1397 19.702 19.4551 19.0758 18.6769 18.584C17.0856 17.5627 15.0694 17.0002 13 17.0002C10.9306 17.0002 8.91436 17.5627 7.32311 18.584C6.54487 19.0755 5.86026 19.7015 5.30124 20.4327C5.27927 20.4621 5.25112 20.4863 5.21877 20.5035C5.18642 20.5208 5.15067 20.5308 5.11405 20.5327C5.07743 20.5346 5.04084 20.5284 5.00687 20.5146C4.9729 20.5008 4.94238 20.4797 4.91749 20.4527C3.08353 18.473 2.04467 15.886 1.99999 13.1877C1.89811 7.10586 6.88874 2.01523 12.9731 2.00023C19.0575 1.98523 24 6.92586 24 13.0002C24.0021 15.7636 22.96 18.4257 21.0825 20.4534Z" fill="white" />
                                  <path d="M13 6C11.7675 6 10.6532 6.46188 9.86128 7.30125C9.0694 8.14063 8.67378 9.30125 8.76316 10.5469C8.94441 13 10.845 15 13 15C15.155 15 17.0519 13 17.2369 10.5475C17.3294 9.31375 16.9369 8.16375 16.1319 7.30875C15.3369 6.465 14.2244 6 13 6Z" fill="white" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_558_1232">
                                    <rect width="1440" height="1024" fill="white" transform="translate(-505 -168)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </OverlayTrigger>
                          ) : (
                            <svg style={{ cursor: 'not-allowed', borderRadius: "50%" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="26" height="26" fill="#FF941C80" />
                              <g clip-path="url(#clip0_558_1232)">
                                <rect width="1440" height="1024" transform="translate(-505 -168)" fill="white" />
                                <rect x="-134" y="-71" width="225" height="151" rx="5" fill="white" />
                                <rect x="-133.75" y="-70.75" width="224.5" height="150.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                                <rect x="-3" y="-3" width="32" height="32" rx="16" fill="#FF941C80" />
                                <path d="M13.1812 0.000231381C5.86999 -0.0985186 -0.0987628 5.87023 -1.27598e-05 13.1815C0.0974872 20.1934 5.80686 25.9027 12.8187 26.0002C20.1312 26.1002 26.0987 20.1315 25.9987 12.8202C25.9025 5.80711 20.1931 0.0977314 13.1812 0.000231381ZM21.0825 20.4534C21.0576 20.4803 21.0271 20.5014 20.9931 20.5152C20.9591 20.529 20.9225 20.5352 20.8859 20.5333C20.8493 20.5314 20.8136 20.5214 20.7812 20.5042C20.7489 20.4869 20.7207 20.4627 20.6987 20.4334C20.1397 19.702 19.4551 19.0758 18.6769 18.584C17.0856 17.5627 15.0694 17.0002 13 17.0002C10.9306 17.0002 8.91436 17.5627 7.32311 18.584C6.54487 19.0755 5.86026 19.7015 5.30124 20.4327C5.27927 20.4621 5.25112 20.4863 5.21877 20.5035C5.18642 20.5208 5.15067 20.5308 5.11405 20.5327C5.07743 20.5346 5.04084 20.5284 5.00687 20.5146C4.9729 20.5008 4.94238 20.4797 4.91749 20.4527C3.08353 18.473 2.04467 15.886 1.99999 13.1877C1.89811 7.10586 6.88874 2.01523 12.9731 2.00023C19.0575 1.98523 24 6.92586 24 13.0002C24.0021 15.7636 22.96 18.4257 21.0825 20.4534Z" fill="white" />
                                <path d="M13 6C11.7675 6 10.6532 6.46188 9.86128 7.30125C9.0694 8.14063 8.67378 9.30125 8.76316 10.5469C8.94441 13 10.845 15 13 15C15.155 15 17.0519 13 17.2369 10.5475C17.3294 9.31375 16.9369 8.16375 16.1319 7.30875C15.3369 6.465 14.2244 6 13 6Z" fill="white" />
                              </g>
                              <defs>
                                <clipPath id="clip0_558_1232">
                                  <rect width="1440" height="1024" fill="white" transform="translate(-505 -168)" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </div>&nbsp;&nbsp;
                        <label style={{ margin: 0, fontWeight: 700, fontSize: "20px", color: "#000" }}>{delayedPat}</label>
                      </div>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <ProgressBar className="del" style={{ width: "100%", height: "15px" }} now={delayedP} label={delayedP + "%"} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>}
              <Col lg={(type === "medicine" || type === "food" || type === "liquid") ? "3" : "4"} sm="6">
                <Card className="card-stats " style={{ borderRadius: "5px", overflow: "hidden" }} >
                  <Card.Body style={{ padding: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: "10px 30px", backgroundColor: "#ED0000" }}>
                      <label style={{ color: "#fff", fontSize: "20px", fontWeight: 500, textTransform: "capitalize" }}>Missed</label>
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="34" fill="#AFAFAF" />
                        <g clip-path="url(#clip0_558_1232)">
                          <rect width="1440" height="1024" transform="translate(-1209 -111)" fill="white" />
                          <rect x="-142" y="-15" width="225" height="152" rx="5" fill="white" />
                          <rect x="-141.75" y="-14.75" width="224.5" height="151.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                          <path d="M-142 -10C-142 -12.7614 -139.761 -15 -137 -15H78C80.7614 -15 83 -12.7614 83 -10V43H-142V-10Z" fill="#ED0000" />
                          <circle cx="17" cy="17" r="16.25" fill="#ED0000" stroke="white" stroke-width="1.5" />
                          <path d="M7.7 15.11L15.29 22.7C15.68 23.09 16.31 23.09 16.7 22.7L23 16.4V20C23 20.55 23.45 21 24 21C24.55 21 25 20.55 25 20V14C25 13.45 24.55 13 24 13H18C17.45 13 17 13.45 17 14C17 14.55 17.45 15 18 15H21.59L16 20.59L9.11 13.7C9.01749 13.6073 8.9076 13.5337 8.78663 13.4836C8.66565 13.4334 8.53597 13.4076 8.405 13.4076C8.27403 13.4076 8.14435 13.4334 8.02338 13.4836C7.9024 13.5337 7.79251 13.6073 7.7 13.7C7.32 14.09 7.32 14.73 7.7 15.11Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_558_1232">
                            <rect width="1440" height="1024" fill="white" transform="translate(-1209 -111)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div style={{ display: 'flex', padding: "10px" }}>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div style={{ height: "35px", width: "35px", backgroundColor: "#ED000080", borderRadius: "50%", alignContent: "center", textAlign: "center" }}>
                          {type === "medicine" ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5103 11.9157C18.8771 12.5414 19.1165 13.2336 19.2148 13.9522C19.3131 14.6709 19.2682 15.4019 19.0828 16.1031C18.8974 16.8044 18.5751 17.462 18.1345 18.0382C17.6939 18.6144 17.1437 19.0977 16.5156 19.4604C15.8874 19.8231 15.1937 20.0579 14.4744 20.1514C13.7551 20.2449 13.0244 20.1952 12.3244 20.0052C11.6244 19.8151 10.969 19.4885 10.3957 19.0441C9.82245 18.5997 9.34276 18.0463 8.98426 17.4157M18.5103 11.9157C17.7757 10.6628 16.5754 9.75122 15.1713 9.37999C13.7672 9.00876 12.2732 9.20799 11.0154 9.93419C9.75766 10.6604 8.83817 11.8546 8.45764 13.2562C8.0771 14.6579 8.26641 16.1531 8.98426 17.4157M18.5103 11.9157L8.98426 17.4157M9.20609 5.08012L13.8673 2.32553C15.5833 1.3117 17.7778 1.91303 18.7688 3.67028C19.1474 4.34265 19.3089 5.11568 19.2309 5.8834C19.1529 6.65112 18.8394 7.37593 18.3333 7.95845M9.20609 5.08012L4.54484 7.8347C2.82884 8.84853 2.24034 11.0944 3.23126 12.8507C3.50816 13.3488 3.90014 13.7736 4.37453 14.0895C4.84891 14.4053 5.39194 14.6032 5.95834 14.6666M9.20609 5.08012L10.5417 7.33328" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg> : type === "food" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18 3a1 1 0 0 1 .993.883L19 4v16a1 1 0 0 1-1.993.117L17 20v-5h-1a1 1 0 0 1-.993-.883L15 14V8c0-2.21 1.5-5 3-5Zm-6 0a1 1 0 0 1 .993.883L13 4v5a4.002 4.002 0 0 1-3 3.874V20a1 1 0 0 1-1.993.117L8 20v-7.126a4.002 4.002 0 0 1-2.995-3.668L5 9V4a1 1 0 0 1 1.993-.117L7 4v5a2 2 0 0 0 1 1.732V4a1 1 0 0 1 1.993-.117L10 4l.001 6.732a2 2 0 0 0 .992-1.563L11 9V4a1 1 0 0 1 1-1Z" />
                          </svg> : type === "liquid" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18.32 8H5.67l-.44-4h13.54M12 19a3 3 0 0 1-3-3c0-2 3-5.4 3-5.4s3 3.4 3 5.4a3 3 0 0 1-3 3M3 2l2 18.23c.13 1 .97 1.77 2 1.77h10c1 0 1.87-.77 2-1.77L21 2H3Z" />
                          </svg> : type === "consumables" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                              <path d="M22.003 3v4.497A.503.503 0 0 1 21.5 8v0a.52.52 0 0 1-.466-.3A10 10 0 0 0 12.003 2c-5.185 0-9.449 3.947-9.95 9" />
                              <path d="M17 10v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2m-5 1V8" />
                              <path d="M2.05 21v-4.497c0-.278.226-.503.504-.503v0c.2 0 .38.119.466.3a10.001 10.001 0 0 0 9.03 5.7c5.186 0 9.45-3.947 9.952-9" />
                            </g>
                          </svg> : type === "test" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="none" stroke="#fff" d="M2 23.5h20M8.646 15a4.5 4.5 0 1 0 4.262-7.408M5.124 15a7.519 7.519 0 0 0 4.84 4.22M6.5 9.5L8 11m-6 2.5h9m4.258-8.258l.712-.712a1.81 1.81 0 0 0 0-2.56l-.94-.94a1.81 1.81 0 0 0-2.56 0L7 6.5l3.5 3.5l2.408-2.408m2.35-2.35a7.503 7.503 0 0 1-1.222 13.978m1.222-13.978l-2.35 2.35M9.964 19.22a3.235 3.235 0 0 1 4.072 0m-4.072 0a3.233 3.233 0 0 0-.858 1.069L8.5 21.5s-1 2-3 2h13c-2 0-3-2-3-2l-.606-1.211a3.234 3.234 0 0 0-.858-1.069M15.5 1.5L16.6.4" />
                          </svg> : type === "scan" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="none" stroke="#fff" d="M3.765 7A10.498 10.498 0 0 1 13 1.5c5.799 0 10.5 4.701 10.5 10.5S18.799 22.5 13 22.5A10.503 10.503 0 0 1 3.289 16M18 16H3.289M0 16h3.289M2 13h9.5v-1a3 3 0 0 0-3-3c-.54 0-4.692.437-8.4.84m13.4 1.56s1 1.6 2.25 1.6a1.75 1.75 0 0 0 1.75-1.75c0-.966-.784-1.746-1.75-1.746c-1.25 0-2.25 1.596-2.25 1.596z" />
                          </svg> : type === "procedure" ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M18.27 10.73q-.74 0-1.622-.46q-.883-.46-1.673-1.276q-.79-.815-1.251-1.698q-.46-.883-.46-1.634q0-.233.048-.4q.048-.168.144-.264q.534-.535 2.233-.907q1.7-.372 3.546-.354q.298.011.49.063q.19.052.294.156q.098.098.153.278t.066.46q.03 1.86-.32 3.56t-.891 2.24q-.115.116-.31.176t-.448.06m.51-2.326q.223-.68.334-1.594q.112-.914.156-2.08q-.98-.024-1.897.1q-.916.124-1.685.366q.525.296.97.617q.445.322.824.681q.442.423.77.904q.328.48.528 1.006m-.51 1.327q-.129-.596-.519-1.267q-.39-.67-.983-1.256q-.586-.587-1.231-.965q-.646-.378-1.242-.512q.05.575.425 1.29q.375.716.944 1.285q.6.6 1.303.987q.703.388 1.303.438m3.031 8.257L16.812 13.5h-10l-4.8-4.8l.688-.688L7.188 12.5h10l4.8 4.8zM8.5 20.981v-3.385q0-.671.472-1.143t1.143-.472h3.77q.67 0 1.143.472q.472.472.472 1.143v3.385zm1-1h5v-2.385q0-.27-.173-.442t-.442-.173h-3.77q-.269 0-.442.173t-.173.442zm0 0v-3z" />
                          </svg> : ""}
                        </div>&nbsp;&nbsp;
                        <label style={{ margin: 0, fontWeight: 700, fontSize: "20px", color: "#000" }}>{missed}</label>
                      </div>
                      <div className="col-6" style={{ display: "flex" }}>
                        <div style={{ height: "35px", width: "35px", backgroundColor: "#ED000080", borderRadius: "50%", alignContent: "center", textAlign: "center" }}>
                          {missedPatId.length > 0 ? (
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={
                                <Tooltip1 className="tap-tooltip custom-tooltip" id="button-tooltip">
                                  <PatientsList data={missedPatId} />
                                </Tooltip1>
                              }
                            >
                              <svg style={{ cursor: 'pointer', borderRadius: "50%" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="26" height="26" fill="#ED000080" />
                                <g clip-path="url(#clip0_558_1232)">
                                  <rect width="1440" height="1024" transform="translate(-505 -168)" fill="white" />
                                  <rect x="-134" y="-71" width="225" height="151" rx="5" fill="white" />
                                  <rect x="-133.75" y="-70.75" width="224.5" height="150.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                                  <rect x="-3" y="-3" width="32" height="32" rx="16" fill="#ED000080" />
                                  <path d="M13.1812 0.000231381C5.86999 -0.0985186 -0.0987628 5.87023 -1.27598e-05 13.1815C0.0974872 20.1934 5.80686 25.9027 12.8187 26.0002C20.1312 26.1002 26.0987 20.1315 25.9987 12.8202C25.9025 5.80711 20.1931 0.0977314 13.1812 0.000231381ZM21.0825 20.4534C21.0576 20.4803 21.0271 20.5014 20.9931 20.5152C20.9591 20.529 20.9225 20.5352 20.8859 20.5333C20.8493 20.5314 20.8136 20.5214 20.7812 20.5042C20.7489 20.4869 20.7207 20.4627 20.6987 20.4334C20.1397 19.702 19.4551 19.0758 18.6769 18.584C17.0856 17.5627 15.0694 17.0002 13 17.0002C10.9306 17.0002 8.91436 17.5627 7.32311 18.584C6.54487 19.0755 5.86026 19.7015 5.30124 20.4327C5.27927 20.4621 5.25112 20.4863 5.21877 20.5035C5.18642 20.5208 5.15067 20.5308 5.11405 20.5327C5.07743 20.5346 5.04084 20.5284 5.00687 20.5146C4.9729 20.5008 4.94238 20.4797 4.91749 20.4527C3.08353 18.473 2.04467 15.886 1.99999 13.1877C1.89811 7.10586 6.88874 2.01523 12.9731 2.00023C19.0575 1.98523 24 6.92586 24 13.0002C24.0021 15.7636 22.96 18.4257 21.0825 20.4534Z" fill="white" />
                                  <path d="M13 6C11.7675 6 10.6532 6.46188 9.86128 7.30125C9.0694 8.14063 8.67378 9.30125 8.76316 10.5469C8.94441 13 10.845 15 13 15C15.155 15 17.0519 13 17.2369 10.5475C17.3294 9.31375 16.9369 8.16375 16.1319 7.30875C15.3369 6.465 14.2244 6 13 6Z" fill="white" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_558_1232">
                                    <rect width="1440" height="1024" fill="white" transform="translate(-505 -168)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </OverlayTrigger>
                          ) : (
                            <svg style={{ cursor: 'not-allowed', borderRadius: "50%" }} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="26" height="26" fill="#ED000080" />
                              <g clip-path="url(#clip0_558_1232)">
                                <rect width="1440" height="1024" transform="translate(-505 -168)" fill="white" />
                                <rect x="-134" y="-71" width="225" height="151" rx="5" fill="white" />
                                <rect x="-133.75" y="-70.75" width="224.5" height="150.5" rx="4.75" stroke="black" stroke-opacity="0.16" stroke-width="0.5" />
                                <rect x="-3" y="-3" width="32" height="32" rx="16" fill="#ED000080" />
                                <path d="M13.1812 0.000231381C5.86999 -0.0985186 -0.0987628 5.87023 -1.27598e-05 13.1815C0.0974872 20.1934 5.80686 25.9027 12.8187 26.0002C20.1312 26.1002 26.0987 20.1315 25.9987 12.8202C25.9025 5.80711 20.1931 0.0977314 13.1812 0.000231381ZM21.0825 20.4534C21.0576 20.4803 21.0271 20.5014 20.9931 20.5152C20.9591 20.529 20.9225 20.5352 20.8859 20.5333C20.8493 20.5314 20.8136 20.5214 20.7812 20.5042C20.7489 20.4869 20.7207 20.4627 20.6987 20.4334C20.1397 19.702 19.4551 19.0758 18.6769 18.584C17.0856 17.5627 15.0694 17.0002 13 17.0002C10.9306 17.0002 8.91436 17.5627 7.32311 18.584C6.54487 19.0755 5.86026 19.7015 5.30124 20.4327C5.27927 20.4621 5.25112 20.4863 5.21877 20.5035C5.18642 20.5208 5.15067 20.5308 5.11405 20.5327C5.07743 20.5346 5.04084 20.5284 5.00687 20.5146C4.9729 20.5008 4.94238 20.4797 4.91749 20.4527C3.08353 18.473 2.04467 15.886 1.99999 13.1877C1.89811 7.10586 6.88874 2.01523 12.9731 2.00023C19.0575 1.98523 24 6.92586 24 13.0002C24.0021 15.7636 22.96 18.4257 21.0825 20.4534Z" fill="white" />
                                <path d="M13 6C11.7675 6 10.6532 6.46188 9.86128 7.30125C9.0694 8.14063 8.67378 9.30125 8.76316 10.5469C8.94441 13 10.845 15 13 15C15.155 15 17.0519 13 17.2369 10.5475C17.3294 9.31375 16.9369 8.16375 16.1319 7.30875C15.3369 6.465 14.2244 6 13 6Z" fill="white" />
                              </g>
                              <defs>
                                <clipPath id="clip0_558_1232">
                                  <rect width="1440" height="1024" fill="white" transform="translate(-505 -168)" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </div>&nbsp;&nbsp;
                        <label style={{ margin: 0, fontWeight: 700, fontSize: "20px", color: "#000" }}>{missedPat}</label>
                      </div>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <ProgressBar className="miss" style={{ width: "100%", height: "15px" }} now={missedP} label={missedP + "%"} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Service Behavior</Card.Title>
                    <p className="card-category">24 Hours performance</p>
                  </Card.Header>
                  <Card.Body>
                    <div style={{ height: "350px",paddingTop:"1%", overflow: "auto", display: "flex", alignItems: "center" }}><Bubble data={data} options={options} /></div>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-history"></i>
                      Updated {timeAgo} minutes ago
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Medication Statistics</Card.Title>
                    <p className="card-category">24 Hours performance</p>
                  </Card.Header>
                  <Card.Body>
                    <div style={{ height: "350px", overflow: "auto" }}>
                      <div
                        className="ct-chart ct-perfect-fourth"
                        id="chartPreferences"
                      >
                        {total > 0 ? <ChartistGraph
                          data={{
                            labels: [completedP > 0 ? (completedP + "%") : "", missedP > 0 ? (missedP + "%") : "", (type === "medicine" || type === "food" || type === "liquid") && delayedP > 0 ? (delayedP + "%") : "", pendingP > 0 ? (pendingP + "%") : ""],
                            series: [completedP, missedP, (type === "medicine" || type === "food" || type === "liquid") && delayedP, pendingP],
                          }}
                          options={{
                            showLabel: false,
                          }}
                          type="Pie"
                        /> : <div style={{ textAlign: "center" }}><i style={{ fontSize: "41px" }} className="nc-icon nc-chart-pie-36" /><br /><p style={{ color: "red" }}>No data!!</p></div>}
                      </div>
                      <div className="legend">
                        <i style={{ color: "#83f783" }} className="fas fa-circle "></i>
                        Completed <i className="fas fa-circle text-danger"></i>
                        Missed
                        {(type === "medicine" || type === "food" || type === "liquid") && <><i className="fas fa-circle text-warning"></i>
                          Delayed</>}<i className="fas fa-circle text-info"></i>Pending
                      </div></div>
                    <hr></hr>
                    <div className="stats">
                      <i className="far fa-clock"></i>
                      Updated {timeAgo} minutes ago
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          {/* <div className="col-1" style={{ fontSize: "xx-small" }}>
            {medFlag && <svg onClick={() => { props.setDate(new Date()); setType("medicine"); }} style={{ position: "absolute", marginTop: "-15px", marginLeft: "50%" }} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 100 100"><path fill="red" d="M88.558 49.96c0-.885-.435-1.663-1.097-2.151l.014-.024l-9.324-5.383l5.367-9.296l-.018-.011a2.666 2.666 0 0 0-.127-2.408a2.667 2.667 0 0 0-2.025-1.314v-.026H70.58V18.61h-.022a2.667 2.667 0 0 0-1.314-2.022a2.662 2.662 0 0 0-2.412-.125l-.013-.023l-9.481 5.474l-5.25-9.094l-.019.011a2.668 2.668 0 0 0-2.149-1.094c-.885 0-1.664.435-2.151 1.097l-.024-.014l-5.337 9.244l-9.19-5.306l-.011.019a2.666 2.666 0 0 0-2.408.127a2.666 2.666 0 0 0-1.315 2.025h-.027v10.674H18.845v.021a2.667 2.667 0 0 0-2.022 1.314a2.667 2.667 0 0 0-.126 2.41l-.023.014l5.246 9.087l-9.394 5.424l.011.019a2.668 2.668 0 0 0-1.094 2.149c0 .885.435 1.664 1.097 2.151l-.014.024l9.324 5.383l-5.367 9.296l.018.01a2.666 2.666 0 0 0 .127 2.408a2.667 2.667 0 0 0 2.025 1.314v.027H29.42V81.39h.022c.092.816.549 1.58 1.314 2.022a2.665 2.665 0 0 0 2.412.125l.013.023l9.481-5.474l5.25 9.094l.019-.011a2.668 2.668 0 0 0 2.149 1.094c.885 0 1.664-.435 2.151-1.096l.023.013l5.337-9.244l9.191 5.306l.011-.019a2.666 2.666 0 0 0 2.408-.127a2.666 2.666 0 0 0 1.315-2.025h.027V70.398h10.613v-.021a2.667 2.667 0 0 0 2.022-1.314a2.67 2.67 0 0 0 .126-2.411l.023-.013l-5.246-9.087l9.394-5.424l-.011-.019a2.666 2.666 0 0 0 1.094-2.149M43.715 61.355l-9.846-4.35l4.345 7.525l-2.456 1.418l-6.662-11.537l2.525-1.459l9.53 4.162l-4.185-7.248l2.457-1.418l6.66 11.537zm4.652-2.686l-6.661-11.538l8.165-4.713l1.248 2.162l-5.709 3.295l1.398 2.422l5.587-3.225l1.248 2.16l-5.587 3.227l1.518 2.629l5.709-3.295l1.248 2.162zm18.906-10.915L60.675 41l2.567 9.08l-2.611 1.508l-9.965-9.629l2.75-1.588l6.838 7.168l-2.617-9.605l1.92-1.108l6.993 7.079l-2.79-9.506l2.75-1.588l3.375 13.436z" /></svg>}
            <div style={{ border: medFlag && "2px solid red" }} onClick={() => { setType("medicine"); }} className={type === "medicine" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M8.6 16.4a4.6 4.6 0 0 1-6.5-6.5l7.8-7.8a4.6 4.6 0 0 1 6.5 6.5M5.999 5.999l4.25 4.25" /><path d="M23.25 15.75a7.669 7.669 0 0 1-6 7.5a7.669 7.669 0 0 1-6-7.5V13.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v2.25Zm-6-.75v4.5M15 17.25h4.5" /></g></svg><br />Medicine</div>

            <br />  {fdFlag && <svg onClick={() => { props.setDate(new Date()); setType("food"); }} style={{ position: "absolute", marginTop: "-15px", marginLeft: "50%" }} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 100 100"><path fill="red" d="M88.558 49.96c0-.885-.435-1.663-1.097-2.151l.014-.024l-9.324-5.383l5.367-9.296l-.018-.011a2.666 2.666 0 0 0-.127-2.408a2.667 2.667 0 0 0-2.025-1.314v-.026H70.58V18.61h-.022a2.667 2.667 0 0 0-1.314-2.022a2.662 2.662 0 0 0-2.412-.125l-.013-.023l-9.481 5.474l-5.25-9.094l-.019.011a2.668 2.668 0 0 0-2.149-1.094c-.885 0-1.664.435-2.151 1.097l-.024-.014l-5.337 9.244l-9.19-5.306l-.011.019a2.666 2.666 0 0 0-2.408.127a2.666 2.666 0 0 0-1.315 2.025h-.027v10.674H18.845v.021a2.667 2.667 0 0 0-2.022 1.314a2.667 2.667 0 0 0-.126 2.41l-.023.014l5.246 9.087l-9.394 5.424l.011.019a2.668 2.668 0 0 0-1.094 2.149c0 .885.435 1.664 1.097 2.151l-.014.024l9.324 5.383l-5.367 9.296l.018.01a2.666 2.666 0 0 0 .127 2.408a2.667 2.667 0 0 0 2.025 1.314v.027H29.42V81.39h.022c.092.816.549 1.58 1.314 2.022a2.665 2.665 0 0 0 2.412.125l.013.023l9.481-5.474l5.25 9.094l.019-.011a2.668 2.668 0 0 0 2.149 1.094c.885 0 1.664-.435 2.151-1.096l.023.013l5.337-9.244l9.191 5.306l.011-.019a2.666 2.666 0 0 0 2.408-.127a2.666 2.666 0 0 0 1.315-2.025h.027V70.398h10.613v-.021a2.667 2.667 0 0 0 2.022-1.314a2.67 2.67 0 0 0 .126-2.411l.023-.013l-5.246-9.087l9.394-5.424l-.011-.019a2.666 2.666 0 0 0 1.094-2.149M43.715 61.355l-9.846-4.35l4.345 7.525l-2.456 1.418l-6.662-11.537l2.525-1.459l9.53 4.162l-4.185-7.248l2.457-1.418l6.66 11.537zm4.652-2.686l-6.661-11.538l8.165-4.713l1.248 2.162l-5.709 3.295l1.398 2.422l5.587-3.225l1.248 2.16l-5.587 3.227l1.518 2.629l5.709-3.295l1.248 2.162zm18.906-10.915L60.675 41l2.567 9.08l-2.611 1.508l-9.965-9.629l2.75-1.588l6.838 7.168l-2.617-9.605l1.92-1.108l6.993 7.079l-2.79-9.506l2.75-1.588l3.375 13.436z" /></svg>}
            <div style={{ border: fdFlag && "2px solid red" }} onClick={() => { setType("food"); }} className={type === "food" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18 3a1 1 0 0 1 .993.883L19 4v16a1 1 0 0 1-1.993.117L17 20v-5h-1a1 1 0 0 1-.993-.883L15 14V8c0-2.21 1.5-5 3-5Zm-6 0a1 1 0 0 1 .993.883L13 4v5a4.002 4.002 0 0 1-3 3.874V20a1 1 0 0 1-1.993.117L8 20v-7.126a4.002 4.002 0 0 1-2.995-3.668L5 9V4a1 1 0 0 1 1.993-.117L7 4v5a2 2 0 0 0 1 1.732V4a1 1 0 0 1 1.993-.117L10 4l.001 6.732a2 2 0 0 0 .992-1.563L11 9V4a1 1 0 0 1 1-1Z" /></svg><br />Food</div><br />
            {lqFlag && <svg onClick={() => { props.setDate(new Date()); setType("liquid"); }} style={{ position: "absolute", marginTop: "-15px", marginLeft: "50%" }} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 100 100"><path fill="red" d="M88.558 49.96c0-.885-.435-1.663-1.097-2.151l.014-.024l-9.324-5.383l5.367-9.296l-.018-.011a2.666 2.666 0 0 0-.127-2.408a2.667 2.667 0 0 0-2.025-1.314v-.026H70.58V18.61h-.022a2.667 2.667 0 0 0-1.314-2.022a2.662 2.662 0 0 0-2.412-.125l-.013-.023l-9.481 5.474l-5.25-9.094l-.019.011a2.668 2.668 0 0 0-2.149-1.094c-.885 0-1.664.435-2.151 1.097l-.024-.014l-5.337 9.244l-9.19-5.306l-.011.019a2.666 2.666 0 0 0-2.408.127a2.666 2.666 0 0 0-1.315 2.025h-.027v10.674H18.845v.021a2.667 2.667 0 0 0-2.022 1.314a2.667 2.667 0 0 0-.126 2.41l-.023.014l5.246 9.087l-9.394 5.424l.011.019a2.668 2.668 0 0 0-1.094 2.149c0 .885.435 1.664 1.097 2.151l-.014.024l9.324 5.383l-5.367 9.296l.018.01a2.666 2.666 0 0 0 .127 2.408a2.667 2.667 0 0 0 2.025 1.314v.027H29.42V81.39h.022c.092.816.549 1.58 1.314 2.022a2.665 2.665 0 0 0 2.412.125l.013.023l9.481-5.474l5.25 9.094l.019-.011a2.668 2.668 0 0 0 2.149 1.094c.885 0 1.664-.435 2.151-1.096l.023.013l5.337-9.244l9.191 5.306l.011-.019a2.666 2.666 0 0 0 2.408-.127a2.666 2.666 0 0 0 1.315-2.025h.027V70.398h10.613v-.021a2.667 2.667 0 0 0 2.022-1.314a2.67 2.67 0 0 0 .126-2.411l.023-.013l-5.246-9.087l9.394-5.424l-.011-.019a2.666 2.666 0 0 0 1.094-2.149M43.715 61.355l-9.846-4.35l4.345 7.525l-2.456 1.418l-6.662-11.537l2.525-1.459l9.53 4.162l-4.185-7.248l2.457-1.418l6.66 11.537zm4.652-2.686l-6.661-11.538l8.165-4.713l1.248 2.162l-5.709 3.295l1.398 2.422l5.587-3.225l1.248 2.16l-5.587 3.227l1.518 2.629l5.709-3.295l1.248 2.162zm18.906-10.915L60.675 41l2.567 9.08l-2.611 1.508l-9.965-9.629l2.75-1.588l6.838 7.168l-2.617-9.605l1.92-1.108l6.993 7.079l-2.79-9.506l2.75-1.588l3.375 13.436z" /></svg>}
            <div style={{ border: lqFlag && "2px solid red" }} onClick={() => { setType("liquid"); }} className={type === "liquid" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18.32 8H5.67l-.44-4h13.54M12 19a3 3 0 0 1-3-3c0-2 3-5.4 3-5.4s3 3.4 3 5.4a3 3 0 0 1-3 3M3 2l2 18.23c.13 1 .97 1.77 2 1.77h10c1 0 1.87-.77 2-1.77L21 2H3Z" /></svg><br />Fluid</div><br />
            {conFlag && <svg onClick={() => { props.setDate(new Date()); setType("consumables"); }} style={{ position: "absolute", marginTop: "-15px", marginLeft: "50%" }} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 100 100"><path fill="red" d="M88.558 49.96c0-.885-.435-1.663-1.097-2.151l.014-.024l-9.324-5.383l5.367-9.296l-.018-.011a2.666 2.666 0 0 0-.127-2.408a2.667 2.667 0 0 0-2.025-1.314v-.026H70.58V18.61h-.022a2.667 2.667 0 0 0-1.314-2.022a2.662 2.662 0 0 0-2.412-.125l-.013-.023l-9.481 5.474l-5.25-9.094l-.019.011a2.668 2.668 0 0 0-2.149-1.094c-.885 0-1.664.435-2.151 1.097l-.024-.014l-5.337 9.244l-9.19-5.306l-.011.019a2.666 2.666 0 0 0-2.408.127a2.666 2.666 0 0 0-1.315 2.025h-.027v10.674H18.845v.021a2.667 2.667 0 0 0-2.022 1.314a2.667 2.667 0 0 0-.126 2.41l-.023.014l5.246 9.087l-9.394 5.424l.011.019a2.668 2.668 0 0 0-1.094 2.149c0 .885.435 1.664 1.097 2.151l-.014.024l9.324 5.383l-5.367 9.296l.018.01a2.666 2.666 0 0 0 .127 2.408a2.667 2.667 0 0 0 2.025 1.314v.027H29.42V81.39h.022c.092.816.549 1.58 1.314 2.022a2.665 2.665 0 0 0 2.412.125l.013.023l9.481-5.474l5.25 9.094l.019-.011a2.668 2.668 0 0 0 2.149 1.094c.885 0 1.664-.435 2.151-1.096l.023.013l5.337-9.244l9.191 5.306l.011-.019a2.666 2.666 0 0 0 2.408-.127a2.666 2.666 0 0 0 1.315-2.025h.027V70.398h10.613v-.021a2.667 2.667 0 0 0 2.022-1.314a2.67 2.67 0 0 0 .126-2.411l.023-.013l-5.246-9.087l9.394-5.424l-.011-.019a2.666 2.666 0 0 0 1.094-2.149M43.715 61.355l-9.846-4.35l4.345 7.525l-2.456 1.418l-6.662-11.537l2.525-1.459l9.53 4.162l-4.185-7.248l2.457-1.418l6.66 11.537zm4.652-2.686l-6.661-11.538l8.165-4.713l1.248 2.162l-5.709 3.295l1.398 2.422l5.587-3.225l1.248 2.16l-5.587 3.227l1.518 2.629l5.709-3.295l1.248 2.162zm18.906-10.915L60.675 41l2.567 9.08l-2.611 1.508l-9.965-9.629l2.75-1.588l6.838 7.168l-2.617-9.605l1.92-1.108l6.993 7.079l-2.79-9.506l2.75-1.588l3.375 13.436z" /></svg>}
            <div style={{ border: conFlag && "2px solid red" }} onClick={() => { setType("consumables"); }} className={type === "consumables" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M22.003 3v4.497A.503.503 0 0 1 21.5 8v0a.52.52 0 0 1-.466-.3A10 10 0 0 0 12.003 2c-5.185 0-9.449 3.947-9.95 9" /><path d="M17 10v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2m-5 1V8" /><path d="M2.05 21v-4.497c0-.278.226-.503.504-.503v0c.2 0 .38.119.466.3a10.001 10.001 0 0 0 9.03 5.7c5.186 0 9.45-3.947 9.952-9" /></g></svg><br />Consumables</div><br />
            {testFlag && <svg onClick={() => { props.setDate(new Date()); setType("test"); }} style={{ position: "absolute", marginTop: "-15px", marginLeft: "50%" }} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 100 100"><path fill="red" d="M88.558 49.96c0-.885-.435-1.663-1.097-2.151l.014-.024l-9.324-5.383l5.367-9.296l-.018-.011a2.666 2.666 0 0 0-.127-2.408a2.667 2.667 0 0 0-2.025-1.314v-.026H70.58V18.61h-.022a2.667 2.667 0 0 0-1.314-2.022a2.662 2.662 0 0 0-2.412-.125l-.013-.023l-9.481 5.474l-5.25-9.094l-.019.011a2.668 2.668 0 0 0-2.149-1.094c-.885 0-1.664.435-2.151 1.097l-.024-.014l-5.337 9.244l-9.19-5.306l-.011.019a2.666 2.666 0 0 0-2.408.127a2.666 2.666 0 0 0-1.315 2.025h-.027v10.674H18.845v.021a2.667 2.667 0 0 0-2.022 1.314a2.667 2.667 0 0 0-.126 2.41l-.023.014l5.246 9.087l-9.394 5.424l.011.019a2.668 2.668 0 0 0-1.094 2.149c0 .885.435 1.664 1.097 2.151l-.014.024l9.324 5.383l-5.367 9.296l.018.01a2.666 2.666 0 0 0 .127 2.408a2.667 2.667 0 0 0 2.025 1.314v.027H29.42V81.39h.022c.092.816.549 1.58 1.314 2.022a2.665 2.665 0 0 0 2.412.125l.013.023l9.481-5.474l5.25 9.094l.019-.011a2.668 2.668 0 0 0 2.149 1.094c.885 0 1.664-.435 2.151-1.096l.023.013l5.337-9.244l9.191 5.306l.011-.019a2.666 2.666 0 0 0 2.408-.127a2.666 2.666 0 0 0 1.315-2.025h.027V70.398h10.613v-.021a2.667 2.667 0 0 0 2.022-1.314a2.67 2.67 0 0 0 .126-2.411l.023-.013l-5.246-9.087l9.394-5.424l-.011-.019a2.666 2.666 0 0 0 1.094-2.149M43.715 61.355l-9.846-4.35l4.345 7.525l-2.456 1.418l-6.662-11.537l2.525-1.459l9.53 4.162l-4.185-7.248l2.457-1.418l6.66 11.537zm4.652-2.686l-6.661-11.538l8.165-4.713l1.248 2.162l-5.709 3.295l1.398 2.422l5.587-3.225l1.248 2.16l-5.587 3.227l1.518 2.629l5.709-3.295l1.248 2.162zm18.906-10.915L60.675 41l2.567 9.08l-2.611 1.508l-9.965-9.629l2.75-1.588l6.838 7.168l-2.617-9.605l1.92-1.108l6.993 7.079l-2.79-9.506l2.75-1.588l3.375 13.436z" /></svg>}
            <div style={{ border: testFlag && "2px solid red" }} onClick={() => { setType("test"); }} className={type === "test" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" d="M2 23.5h20M8.646 15a4.5 4.5 0 1 0 4.262-7.408M5.124 15a7.519 7.519 0 0 0 4.84 4.22M6.5 9.5L8 11m-6 2.5h9m4.258-8.258l.712-.712a1.81 1.81 0 0 0 0-2.56l-.94-.94a1.81 1.81 0 0 0-2.56 0L7 6.5l3.5 3.5l2.408-2.408m2.35-2.35a7.503 7.503 0 0 1-1.222 13.978m1.222-13.978l-2.35 2.35M9.964 19.22a3.235 3.235 0 0 1 4.072 0m-4.072 0a3.233 3.233 0 0 0-.858 1.069L8.5 21.5s-1 2-3 2h13c-2 0-3-2-3-2l-.606-1.211a3.234 3.234 0 0 0-.858-1.069M15.5 1.5L16.6.4" /></svg><br />Test</div><br />
            {scanFlag && <svg onClick={() => { props.setDate(new Date()); setType("scan"); }} style={{ position: "absolute", marginTop: "-15px", marginLeft: "50%" }} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 100 100"><path fill="red" d="M88.558 49.96c0-.885-.435-1.663-1.097-2.151l.014-.024l-9.324-5.383l5.367-9.296l-.018-.011a2.666 2.666 0 0 0-.127-2.408a2.667 2.667 0 0 0-2.025-1.314v-.026H70.58V18.61h-.022a2.667 2.667 0 0 0-1.314-2.022a2.662 2.662 0 0 0-2.412-.125l-.013-.023l-9.481 5.474l-5.25-9.094l-.019.011a2.668 2.668 0 0 0-2.149-1.094c-.885 0-1.664.435-2.151 1.097l-.024-.014l-5.337 9.244l-9.19-5.306l-.011.019a2.666 2.666 0 0 0-2.408.127a2.666 2.666 0 0 0-1.315 2.025h-.027v10.674H18.845v.021a2.667 2.667 0 0 0-2.022 1.314a2.667 2.667 0 0 0-.126 2.41l-.023.014l5.246 9.087l-9.394 5.424l.011.019a2.668 2.668 0 0 0-1.094 2.149c0 .885.435 1.664 1.097 2.151l-.014.024l9.324 5.383l-5.367 9.296l.018.01a2.666 2.666 0 0 0 .127 2.408a2.667 2.667 0 0 0 2.025 1.314v.027H29.42V81.39h.022c.092.816.549 1.58 1.314 2.022a2.665 2.665 0 0 0 2.412.125l.013.023l9.481-5.474l5.25 9.094l.019-.011a2.668 2.668 0 0 0 2.149 1.094c.885 0 1.664-.435 2.151-1.096l.023.013l5.337-9.244l9.191 5.306l.011-.019a2.666 2.666 0 0 0 2.408-.127a2.666 2.666 0 0 0 1.315-2.025h.027V70.398h10.613v-.021a2.667 2.667 0 0 0 2.022-1.314a2.67 2.67 0 0 0 .126-2.411l.023-.013l-5.246-9.087l9.394-5.424l-.011-.019a2.666 2.666 0 0 0 1.094-2.149M43.715 61.355l-9.846-4.35l4.345 7.525l-2.456 1.418l-6.662-11.537l2.525-1.459l9.53 4.162l-4.185-7.248l2.457-1.418l6.66 11.537zm4.652-2.686l-6.661-11.538l8.165-4.713l1.248 2.162l-5.709 3.295l1.398 2.422l5.587-3.225l1.248 2.16l-5.587 3.227l1.518 2.629l5.709-3.295l1.248 2.162zm18.906-10.915L60.675 41l2.567 9.08l-2.611 1.508l-9.965-9.629l2.75-1.588l6.838 7.168l-2.617-9.605l1.92-1.108l6.993 7.079l-2.79-9.506l2.75-1.588l3.375 13.436z" /></svg>}
            <div style={{ border: scanFlag && "2px solid red" }} onClick={() => { setType("scan"); }} className={type === "scan" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" d="M3.765 7A10.498 10.498 0 0 1 13 1.5c5.799 0 10.5 4.701 10.5 10.5S18.799 22.5 13 22.5A10.503 10.503 0 0 1 3.289 16M18 16H3.289M0 16h3.289M2 13h9.5v-1a3 3 0 0 0-3-3c-.54 0-4.692.437-8.4.84m13.4 1.56s1 1.6 2.25 1.6a1.75 1.75 0 0 0 1.75-1.75c0-.966-.784-1.746-1.75-1.746c-1.25 0-2.25 1.596-2.25 1.596z" /></svg><br />Scan</div><br />
            {proFlag && <svg onClick={() => { props.setDate(new Date()); setType("procedure"); }} style={{ position: "absolute", marginTop: "-15px", marginLeft: "50%" }} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 100 100"><path fill="red" d="M88.558 49.96c0-.885-.435-1.663-1.097-2.151l.014-.024l-9.324-5.383l5.367-9.296l-.018-.011a2.666 2.666 0 0 0-.127-2.408a2.667 2.667 0 0 0-2.025-1.314v-.026H70.58V18.61h-.022a2.667 2.667 0 0 0-1.314-2.022a2.662 2.662 0 0 0-2.412-.125l-.013-.023l-9.481 5.474l-5.25-9.094l-.019.011a2.668 2.668 0 0 0-2.149-1.094c-.885 0-1.664.435-2.151 1.097l-.024-.014l-5.337 9.244l-9.19-5.306l-.011.019a2.666 2.666 0 0 0-2.408.127a2.666 2.666 0 0 0-1.315 2.025h-.027v10.674H18.845v.021a2.667 2.667 0 0 0-2.022 1.314a2.667 2.667 0 0 0-.126 2.41l-.023.014l5.246 9.087l-9.394 5.424l.011.019a2.668 2.668 0 0 0-1.094 2.149c0 .885.435 1.664 1.097 2.151l-.014.024l9.324 5.383l-5.367 9.296l.018.01a2.666 2.666 0 0 0 .127 2.408a2.667 2.667 0 0 0 2.025 1.314v.027H29.42V81.39h.022c.092.816.549 1.58 1.314 2.022a2.665 2.665 0 0 0 2.412.125l.013.023l9.481-5.474l5.25 9.094l.019-.011a2.668 2.668 0 0 0 2.149 1.094c.885 0 1.664-.435 2.151-1.096l.023.013l5.337-9.244l9.191 5.306l.011-.019a2.666 2.666 0 0 0 2.408-.127a2.666 2.666 0 0 0 1.315-2.025h.027V70.398h10.613v-.021a2.667 2.667 0 0 0 2.022-1.314a2.67 2.67 0 0 0 .126-2.411l.023-.013l-5.246-9.087l9.394-5.424l-.011-.019a2.666 2.666 0 0 0 1.094-2.149M43.715 61.355l-9.846-4.35l4.345 7.525l-2.456 1.418l-6.662-11.537l2.525-1.459l9.53 4.162l-4.185-7.248l2.457-1.418l6.66 11.537zm4.652-2.686l-6.661-11.538l8.165-4.713l1.248 2.162l-5.709 3.295l1.398 2.422l5.587-3.225l1.248 2.16l-5.587 3.227l1.518 2.629l5.709-3.295l1.248 2.162zm18.906-10.915L60.675 41l2.567 9.08l-2.611 1.508l-9.965-9.629l2.75-1.588l6.838 7.168l-2.617-9.605l1.92-1.108l6.993 7.079l-2.79-9.506l2.75-1.588l3.375 13.436z" /></svg>}
            <div style={{ border: proFlag && "2px solid red" }} onClick={() => { setType("procedure"); }} className={type === "procedure" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18.27 10.73q-.74 0-1.622-.46q-.883-.46-1.673-1.276q-.79-.815-1.251-1.698q-.46-.883-.46-1.634q0-.233.048-.4q.048-.168.144-.264q.534-.535 2.233-.907q1.7-.372 3.546-.354q.298.011.49.063q.19.052.294.156q.098.098.153.278t.066.46q.03 1.86-.32 3.56t-.891 2.24q-.115.116-.31.176t-.448.06m.51-2.326q.223-.68.334-1.594q.112-.914.156-2.08q-.98-.024-1.897.1q-.916.124-1.685.366q.525.296.97.617q.445.322.824.681q.442.423.77.904q.328.48.528 1.006m-.51 1.327q-.129-.596-.519-1.267q-.39-.67-.983-1.256q-.586-.587-1.231-.965q-.646-.378-1.242-.512q.05.575.425 1.29q.375.716.944 1.285q.6.6 1.303.987q.703.388 1.303.438m3.031 8.257L16.812 13.5h-10l-4.8-4.8l.688-.688L7.188 12.5h10l4.8 4.8zM8.5 20.981v-3.385q0-.671.472-1.143t1.143-.472h3.77q.67 0 1.143.472q.472.472.472 1.143v3.385zm1-1h5v-2.385q0-.27-.173-.442t-.442-.173h-3.77q-.269 0-.442.173t-.173.442zm0 0v-3z" /></svg><br />Procedure</div><br />
          </div> */}
          <NurseList date={props.date1}/>
        </div>
        </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Monitor;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CarerCard from "../Carer/CarerCard";

import "./carelist.css";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { APIURL,TZ } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import LoadingOverlay from 'react-loading-overlay';
import BackButton from "../common/BackButton";
import ManagerCard from "../Manager/ManagerCard";
function CarerList() {
  const [loading,setLoading]=useState(false);
  const [nurselist, setNurseList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    nurseList();
  }, []);

  const nurseList=()=>
  {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/carers/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      })
      .then((res) => {
        if (res.status===200) {
          
          const datas = res.data;
          setLoading(false)
          setFreeCount(res.data.free_user_count);
          setActiveCount(res.data.active_free_users);
          
         
          setNurseList(datas.reverse());
        } else {
          setErrorMsg("error");
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }

  const refreshHandle = () => {
    setRefresh(!refresh);
    nurseList();
  
  };

  return (
   
    <div className="drlist-page">
    



      {nurselist.length !== 0 ? (
        <div><br/>
          
          <ManagerCard
                     
                     bdoList={nurselist}
                     user="carer"
                     handle={refreshHandle}
                   />
          </div>

          
      
      ) : (
        <h5 style={{ color: "red", margin: "20px" }}>Carer List is empty </h5>
      )}

      {errorMsg === "error" ? (
        <h5 style={{ color: "red" }}>
          Error in data fetching.Please try again!
        </h5>
      ) : null}
    
    </div>
  );
}

export default CarerList;

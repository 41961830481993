import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button, Dropdown, ToggleButton, Tab, Tabs, ToggleButtonGroup, ButtonGroup } from "react-bootstrap";
import "./webnotifications.css";
import moment from 'moment';
import { useAlert } from 'react-alert'

function Notification() {
    const alert = useAlert()

    const [dataListFilter, setDataListFilter] = useState([])
    const [ageName, setAgeName] = useState([]);
    const [agegroup, setAgeGroup] = useState({ elderly: false, adult: false, young: false, teen: false, kids: false, infants: false })
    const [agegroupArray, setAgeGroupArray] = useState([])
    const [category, setCategory] = useState("all")
    const [doctor, setDoctor] = useState("")
    const [errorMsg, setErrorMsg] = useState("");
    const [dataList, setDataList] = useState([]);
    const [dataList1, setDataList1] = useState([]);
    const [dataList2, setDataList2] = useState([]);
    const [action, setAction] = useState("")
    const [options, setOptions] = useState([]);
    const [gender, setGender] = useState({ male: false, female: false, others: false })
    const [genderArray, setGenderArray] = useState([])
    const [change, setChange] = useState(false)
    const [hasNext, setHasNext] = useState(true)
    const [load, setLoad] = useState(false)
    const [loadFilter, setLoadFilter] = useState(false)
    let navigate = useNavigate();
    const [perPage, setPerPage] = useState(10);
    // const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0)
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );
    const [render, setRender] = useState(false)
    useEffect(() => {
        if (agegroupArray.length > 0 || genderArray.length > 0) {
            setChange(true)
        } else {
            setChange(false)
        }
    }, [agegroupArray, genderArray])
    useEffect(() => {
        if (loadFilter === true) {
            if (hasNext) {
                handleFilter(page + 1, perPage)
            }
        }
    },[load])
    useEffect(() => {


        if (category === "all") {
            const tokenString = sessionStorage.getItem('usertoken');

            let str = tokenString.replace(/["]+/g, '');

            let dataUpdated = null;
            let dataUpdated1 = null;
            let dataUpdated2 = null;

            axios.get(`${APIURL}/api/v1/account/current-notification/?all=true&${TZ}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": 'Token ' + str
                    }
                })
                .then(res => {
                    if (res.data.status === "success") {

                        if (res.data && res.data.high_notifications && res.data.high_notifications.length > 0) {
                            dataUpdated = res.data.high_notifications;
                            setDataList([])
                            setDataList(dataUpdated);
                        } else {
                            setDataList([])
                            setErrorMsg("No Notifications to display at the moment");
                        }
                        if (res.data && res.data.medium_notifications && res.data.medium_notifications.length > 0) {
                            dataUpdated1 = res.data.medium_notifications;
                            setDataList1([])
                            setDataList1(dataUpdated1);


                        } else {
                            setDataList1([])
                            setErrorMsg("No Notifications to display at the moment");
                        }
                        if (res.data && res.data.low_notifications && res.data.low_notifications.length > 0) {
                            dataUpdated2 = res.data.low_notifications;
                            setDataList2([])
                            setDataList2(dataUpdated2);


                        } else {
                            setDataList2([])
                            setErrorMsg("No  Notifications to display at the moment");
                        }

                    }
                })

        }
    }, [category, render])
    useEffect(() => {
        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '');



        axios.get(`${APIURL}/api/v1/account/age-groups/?country=Malta&${TZ}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Token ' + str
                }
            })
            .then(res => {
                if (res.data.status === "success") {
                    setAgeName(res.data.message)

                }

            })


    }, [])
    useEffect(() => {
        if (hasNext) {
            loadFuncser(page + 1, perPage)
        }

    }, [category, render, hasNext, load])

    useEffect(() => {
        if(doctor) {
            loadFuncser(1, 10)
        }            
    }, [doctor])

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(
                `${APIURL}/api/v1/service-provider/procedure-assignee/?q=`,
                {
                    headers: {
                        Authorization: "Token " + str,
                    },
                }
            )
            .then((res) => {
                const doctors = [];
                if (res.data.status === "success") {
                    const doctorList = res.data.details;
                    doctorList.map((person, index) =>
                        doctors.push(person["full_name"])
                    );
                    getDoctors(doctorList)


                }
            })
            .catch((err) => { });


    }, [])
    const loadFuncser = async (page, per_page) => {
        let Data = []
        if (category !== "all") {
            if (category !== "filter") {
                let key = ""
                let value = ""
                if (category === "high") {
                    key = "severity"
                    value = 1
                } else if (category === "medium") {
                    key = "severity"
                    value = 2
                }
                else if (category === "low") {
                    key = "severity"
                    value = 3
                }
                else if (category === "doctor") {
                    key = "doctor_id"
                    value = doctor
                }
                let highdata = null;
                const tokenString = sessionStorage.getItem('usertoken');

                let str = tokenString.replace(/["]+/g, '');
                axios.get(`${APIURL}/api/v1/account/current-notification/?${key}=${value}&page=${page}&per_page=${per_page}&${TZ}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": 'Token ' + str
                        }
                    })
                    .then(res => {
                        if (res.data.status === "success") {
                            setHasNext(res.data.has_next)
                            setLoad(!load)
                            setPage(res.data.page)
                            if (res.data && res.data.notifications && res.data.notifications.length > 0) {
                                highdata = res.data.notifications;
                                Data = highdata.filter(function (o1) {
                                    // filter out (!) items in result2
                                    return !dataList.some(function (o2) {
                                        return o1.id === o2.id;
                                    });
                                })
                                setDataList([])
                                setDataList([...dataList,...Data]);
                                // setDataList(res.data.notifications);

                            } else {
                                setDataList([])
                                if (category === "high") {
                                    setErrorMsg("No high notifications to display at the moment");
                                } else if (category === "medium") {
                                    setErrorMsg("No medium notifications to display at the moment");
                                }
                                else if (category === "low") {
                                    setErrorMsg("No low notifications to display at the moment");
                                } else {
                                    setErrorMsg("No notifications to display at the moment");
                                }
                            }

                        }
                    })
            }
        }
    }


    const handleFilter = async (page, per_page) => {
        let highdata = [];

        const tokenString = sessionStorage.getItem('usertoken');
        const dataToSend = {
            "gender": genderArray,
            "age": agegroupArray
        };
        let str = tokenString.replace(/["]+/g, '');
        axios.get(`${APIURL}/api/v1/account/current-notification/?page=${page}&per_page=${per_page}&${TZ}`,
            {
                params: dataToSend,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Token ' + str
                }
            })

            .then(res => {
                if (res.data.status === "success") {
                    if (res.data && res.data.notifications && res.data.notifications.length > 0) {
                        highdata = res.data.notifications;
                        setHasNext(res.data.has_next)
                        setPage(res.data.page)
                        setDataListFilter([ ...dataListFilter, ...highdata,]);
                        setLoadFilter(true)
                        setLoad(!load)


                    } else {
                        setLoadFilter(false)
                        setDataListFilter([])
                        setErrorMsg("No  Notifications to display at the moment");

                    }

                }
            })

    }
    const handleChange = (e, id) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        setAction(e.target.value);
        handleUpdate(val, id)


    }



    const handleUpdate = (val, id) => {
        console.log(action)
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {
            actions: val,
        };

        axios
            .put(
                `${APIURL}/api/v1/account/current-notification-detail/${id}/`,
                dataToSend,
                {
                    headers: { Authorization: "Token " + v2 },
                }
            )
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success("updated successfully");
                    setRender(!render)

                }
            })
    }
    useEffect(() => {
        let updatedAgeArray = [];
        for (var key in agegroup) {
            if (agegroup[key] === true) {
                updatedAgeArray.push(key);
            }
        }

        setAgeGroupArray(updatedAgeArray);

    }, [agegroup]);

    useEffect(() => {
        let updatedArray = [];
        for (var key in gender) {
            if (gender[key] === true) {
                updatedArray.push(key);
            }
        }

        setGenderArray(updatedArray);

    }, [gender]);

    const handleAgegroup = (e) => {
        //const val=e.target.value;
        const key = e.target.name;

        setAgeGroup((currentstate) => ({
            ...currentstate,
            [key]: !agegroup[key],
        })
        );

    };
    const handleGender = (e) => {
        //const val=e.target.value;
        const key = e.target.name;

        setGender((currentstate) => ({
            ...currentstate,
            [key]: !gender[key],
        })
        );

    };
    let display = dataList.length > 0 ? dataList.map((item, index) => {
        let updatedDate = null;
        if (item.date_time) {
            let dateString = item.date_time;

            let dateObject = new Date(dateString);
            updatedDate = dateObject.toString();

        }
        return (<div key={index}>
            {
                <>
                    <h6 className="text-muted" style={{ marginLeft: "32%", fontSize: "12px" }}>{moment(updatedDate).format("DD-MM-YYYY")}  </h6>
                    <div>

                        <div >

                            <li class="cardm" style={category !== "all" ? { color: "#FFFFFF", paddingTop: "5%", marginLeft: "10px" } : { color: "#FFFFFF", paddingTop: "5%" }}>

                                <h6> {item.type}<span>&nbsp;&nbsp;</span>
                                    <select
                                        onChange={(e) => {
                                            handleChange(e, item.id)


                                        }}
                                        style={{ marginRight: "2px", backgroundColor: "#F07A7A", border: "none", width: "15px", color: "white" }}>
                                        <option selected hidden></option>
                                        {item.possible_actions.map(item => (
                                            <option style={{ backgroundColor: "white", color: "blue" }} >
                                                {item}
                                            </option>
                                        ))}

                                    </select>
                                </h6>

                                <span style={{ fontSize: "12px" }}><b>{item.patient}</b></span>
                                <div class="card-content">
                                    <p style={{ fontSize: "10px" }}>{item.message} <br />
                                        <b style={{ marginLeft: "27%", color: "#ffffffb5" }}> {moment(updatedDate).fromNow()}</b>
                                    </p>

                                </div>

                            </li>

                        </div>
                    </div>
                </>


            }
        </div>)
    }
    ) : errorMsg !== "" ? <h6 style={{ color: "red", padding: "20px" }}> {errorMsg}</h6>
        :
        <h6 style={{ color: "grey", padding: "20px" }}> Loading data.. </h6>;

    let displayFilter = dataListFilter.length > 0 ? dataListFilter.map((item, index) => {
        let updatedDate = null;
        if (item.date_time) {
            let dateString = item.date_time;

            let dateObject = new Date(dateString);
            updatedDate = dateObject.toString();

        }
        return (<div key={index}>
            {
                <>
                    <h6 className="text-muted" style={{ marginLeft: "32%", fontSize: "12px" }}>{moment(updatedDate).format("DD-MM-YYYY")}  </h6>
                    <div>

                        <div >

                            <li class="cardm" style={{ color: "#FFFFFF", paddingTop: "5%", marginLeft: "10px" }}>

                                <h6> {item.type}<span>&nbsp;&nbsp;</span>
                                    <select
                                        onChange={(e) => {
                                            handleChange(e, item.id)


                                        }}
                                        style={{ marginRight: "2px", backgroundColor: "#F07A7A", border: "none", width: "15px", color: "white" }}>
                                        <option selected hidden></option>
                                        {item.possible_actions.map(item => (
                                            <option style={{ backgroundColor: "white", color: "blue" }} >
                                                {item}
                                            </option>
                                        ))}

                                    </select>
                                </h6>

                                <span style={{ fontSize: "12px" }}><b>{item.patient}</b></span>
                                <div class="card-content">
                                    <p style={{ fontSize: "10px" }}>{item.message} <br />
                                        <b style={{ marginLeft: "27%", color: "#ffffffb5" }}> {moment(updatedDate).fromNow()}</b>
                                    </p>

                                </div>

                            </li>

                        </div>
                    </div>
                </>


            }
        </div>)
    }
    ) : errorMsg !== "" ? <h6 style={{ color: "red", padding: "20px" }}> {errorMsg}</h6>
        :
        <h6 style={{ color: "grey", padding: "20px" }}> Loading data.. </h6>;


    let display3 = dataList1.length > 0 ? dataList1.map((item, index) => {


        if (item && item.type && item.patient_id) {
            let str = item.type.replace(/_|-/g, " ");


            let updatedDate = null;
            if (item.date_time) {
                let dateString = item.date_time;

                let dateObject = new Date(dateString);


                updatedDate = dateObject.toString();
            }
            return (<div key={index}>
                {

                    <div>
                        <h6 className="text-muted" style={{ marginLeft: "32%", fontSize: "12px" }}>{moment(updatedDate).format("DD-MM-YYYY")}  </h6>
                        <div >

                            <li class="cardm" style={{ backgroundColor: "#E0D67F", color: "#A258FF", paddingTop: "5%" }}>
                                <h6> {item.type}<span>&nbsp;&nbsp;</span>
                                    <select
                                        onChange={(e) => {
                                            handleChange(e, item.id)

                                        }}
                                        style={{ marginRight: "2px", backgroundColor: "#E0D67F", border: "none", width: "15px", color: "white" }}>
                                        <option selected hidden></option>
                                        {item.possible_actions.map(item => (
                                            <option style={{ backgroundColor: "white", color: "blue" }} >
                                                {item}
                                            </option>
                                        ))}

                                    </select>
                                </h6>

                                <p class="card-title" style={{ fontSize: "12px" }}> <b> {item.patient}</b></p>
                                <div class="card-content">
                                    <p style={{ fontSize: "10px" }}>{item.message}<br />
                                        <b style={{ marginLeft: "20%", color: "#b058ff85" }}> {moment(updatedDate).fromNow()}</b></p>
                                </div>


                            </li>

                        </div>
                    </div>


                }
            </div>)
        }
    }) :
        errorMsg !== "" ? <h6 style={{ color: "red", padding: "20px" }}> {errorMsg}</h6>
            :
            <h6 style={{ color: "grey", padding: "20px" }}> Loading data.. </h6>;



    let display2 = dataList2.length > 0 ? dataList2.map((item, index) => {


        if (item && item.type && item.patient_id) {
            let str = item.type.replace(/_|-/g, " ");


            let updatedDate = null;
            if (item.date_time) {
                let dateString = item.date_time;

                let dateObject = new Date(dateString);


                updatedDate = dateObject.toString();
            }
            return (<div key={index}>
                {


                    <div>
                        <h6 className="text-muted" style={{ marginLeft: "30%" }}>{moment(updatedDate).format("DD-MM-YYYY")}  </h6>
                        <div className={item.read_recipient === true ? "each-notfn read-notfn" : 'each-notfn unread-notfn'}>

                            <li class="card2" style={{ color: "#FFFFFF" }}>
                                <h6> <i class="fa fa-newspaper-o" aria-hidden="true"></i>{item.type}<span>&nbsp;&nbsp;</span>
                                    <select
                                        onChange={(e) => {
                                            handleChange(e, item.id)


                                        }}
                                        style={{ marginRight: "2px", backgroundColor: "#F07A7A", border: "none", width: "15px", color: "white" }}>
                                        <option selected hidden></option>
                                        {item.possible_actions.map(item => (
                                            <option style={{ backgroundColor: "white", color: "blue" }} >
                                                {item}
                                            </option>
                                        ))}

                                    </select>
                                </h6>

                                <p class="card-title" style={{ fontSize: "12px" }}><b> {item.patient}</b></p>
                                <div class="card-content">
                                    <p style={{ fontSize: "12px" }}>{item.message}
                                    </p>
                                    {/* <h6 style={{marginLeft:"20%"}}> {moment(updatedDate).fromNow()}</h6> */}
                                </div>
                                <h6 style={{ marginLeft: "8%" }}> {moment(updatedDate).fromNow()}</h6>

                            </li>

                        </div>
                    </div>


                }
            </div>)
        }
    }) :
        errorMsg !== "" ? <h6 style={{ color: "red", padding: "20px" }}> {errorMsg}</h6>
            :
            <h6 style={{ color: "grey", padding: "20px" }}> Loading data.. </h6>;


    const handleChangeRadio = (val) => {

        setAgeGroup([]);
        setGender([]);
        setDoctor("")
        setDataList([])
        setPage(0)
        setHasNext(true)
        setCategory(val);
    }

    const handleChangeFilter = () => {
        dataListFilter.splice(0, dataListFilter.length + 1)
        setCategory("filter");
        setDoctor("")
        setHasNext(false)
        handleFilter(1, 10);
    }

    const getDoctors = (datas) => {
        let consentarr = datas.map(item => {
            return { label: item.full_name, value: item.id, }
        });
        setOptions(consentarr);
    }

    return (
        <>
            <div className="row" style={{ backgroundColor: "#a7c8d1" }}>
                <div className="col-2">
                    <div style={{ marginLeft: "15px" }}>
                        {login_datas.user_type === "hospital_bdo" || login_datas.user_type === "hospital_nurse" ? <select
                            className="doctorselect"
                            value={doctor}
                            style={{ marginLeft: "8%" }}
                            onChange={(e) => {
                                setDataList([])
                                setCategory("doctor")
                                setDoctor(e.target.value)
                            }}
                            options={options}>
                            <option selected hidden  >Select a doctor</option>
                            {options.map(item => (
                                <option style={{ backgroundColor: "white", color: "blue" }} value={item.value} >
                                    {item.label}
                                </option>
                            ))}
                        </select> : ""}



                        <div className="checkBoxWeb" style={{ backgroundColor: "#E7F2E7", paddingTop: "1%", marginTop: "5%" }}>

                            <div className="list-container" style={{ backgroundColor: "#E7F2E7", width: "91%" }}>
                                <h6> <b style={{ marginLeft: "18%", marginTop: "1%" }}>Gender Filter</b></h6>
                                <div style={{ display: "flex" }}><label className="col-11" style={{ marginTop: "6%" }}>Male</label>
                                    <input
                                        className=""
                                        type="checkbox"
                                        id="male"
                                        name="male"
                                        checked={gender.male}
                                        onChange={handleGender}
                                    /></div>
                                <div style={{ display: "flex" }}><label className="col-11" style={{ marginTop: "6%" }}>Female</label>
                                    <input
                                        className=""
                                        type="checkbox"
                                        id="female"
                                        name="female"
                                        checked={gender.female}
                                        onChange={handleGender}
                                    /></div>
                                <div style={{ display: "flex" }}><label className="col-11" style={{ marginTop: "6%" }}>Others</label>
                                    <input
                                        className=""
                                        type="checkbox"
                                        id="others"
                                        name="others"
                                        checked={gender.others}
                                        onChange={handleGender}
                                    /></div>


                            </div>
                        </div>
                        <div className="checkBoxWeb" style={{ backgroundColor: "#E7F2E7", paddingTop: "5%", marginTop: "5%" }}>

                            <div className="list-container" style={{ backgroundColor: "#E7F2E7", width: "91%" }}>
                                <h6> <b style={{ marginLeft: "22%" }}>Age Filter</b></h6>
                                {ageName.map((item, index) =>

                                    <div style={{ display: "flex" }}><label className="col-11" style={{ marginTop: "6%", textTransform: "capitalize" }}>{item.age_name}<small>({item.min_age}-{item.max_age})</small></label>

                                        <input
                                            className=""
                                            type="checkbox"
                                            id={item.age_name}
                                            name={item.age_name}
                                            checked={agegroup[item.age_name]}
                                            onChange={handleAgegroup}
                                        /></div>)}

                            </div>
                        </div>
                        <div className="">

                            <button disabled={!change}
                                onClick={handleChangeFilter}
                                style={{ backgroundColor: "rgba(23, 116, 168, 0.8)", marginBottom: "3%" }}
                                className="filterClass form-button">Apply <i class="fa fa-arrow-circle-o-right" style={{ backgroundColor: "white", borderRadius: "50%" }} aria-hidden="true"></i></button><br />
                            <Link to="" style={{ color: "white", marginLeft: "18%" }}
                                onClick={() => { setDoctor(""); setCategory("all"); setAgeGroup([]); setGender([]); }}

                                className="filterClass form-button">Clear Filter</Link>
                        </div>
                    </div>
                </div>

                <div className="col-10">

                    <div className="dashboard1" style={{ marginBottom: "10px", width: "100%" }}>
                        <br />
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "2%" }}>
                                <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
                                    <ToggleButton id="tbg-radio-1" value={'all'} className="btn btn-light toggle-btns toggle-btn-1" style={{ backgroundColor: "#3b74b1db", color: "#FFFFFF" }}>
                                        All
                                    </ToggleButton>
                                    <ToggleButton id="tbg-radio-2" value={'high'} className="btn btn-light toggle-btns toggle-btn-2" style={{ backgroundColor: "#F07A7A", color: "#FFFFFF" }}>
                                        High
                                    </ToggleButton>
                                    <ToggleButton id="tbg-radio-2" value={'medium'} className="btn btn-light toggle-btns toggle-btn-3" style={{ backgroundColor: "#E0D67F", color: "#FFFFFF" }}>
                                        Medium
                                    </ToggleButton>
                                    <ToggleButton id="tbg-radio-2" value={'low'} className="btn btn-light toggle-btns toggle-btn-4" style={{ backgroundColor: "#78D29C", color: "#FFFFFF" }}>
                                        Low
                                    </ToggleButton>
                                </ToggleButtonGroup>

                            </div>
                            <div className="todaycalender">
                                <i class="fa fa-calendar" aria-hidden="true" style={{ marginLeft: "30%", marginTop: "7%" }}><span>&nbsp;</span> <b>Today</b></i>

                            </div>
                        </div>
                        <div style={{ marginTop: "1%" }}>
                            {category === "all" ? <>
                                <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                                    <div class="newcontainer">

                                        <ul class="cards">

                                            {dataList.length > 0 ? display : <div style={{ marginTop: "20%", color: "red" }} className="text-center">{errorMsg}</div>}
                                        </ul>
                                    </div>
                                </div>
                                <br />
                                <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                                    <div class="newcontainer3">
                                        <ul class="cards3">
                                            {dataList1.length > 0 ? display3 : <div style={{ marginTop: "20%", color: "red" }} className="text-center">{errorMsg}</div>}
                                        </ul>
                                    </div>
                                </div>
                                <br />
                                <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                                    <div class="newcontainer2">
                                        <ul class="cards2">
                                            {dataList2.length > 0 ? display2 : <div style={{ marginTop: "20%", color: "red" }} className="text-center">{errorMsg}</div>}
                                        </ul>
                                    </div>
                                </div>
                                <br />
                            </> : category === "filter" ?
                                <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                                    <div class="drs-container-not" style={{ borderStyle: "solid", borderColor: "#DD621D" }}>

                                        {dataListFilter.length > 0 ? displayFilter : <div style={{ marginTop: "20%", color: "red" }} className="text-center">{errorMsg}</div>}

                                    </div>
                                </div> :
                                <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                                    <div class="drs-container-not" style={{ borderStyle: "solid", borderColor: "#DD621D" }}>

                                        {dataList.length > 0 ? display : <div style={{ marginTop: "20%", color: "red" }} className="text-center">{errorMsg}</div>}

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}


export default Notification;
import React, { Component, } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useContext, useState, useEffect, useRef } from "react";
import { Nav } from "react-bootstrap";
import ControlledExpandedNode from "./ControlledExpandedNode";
import { Navbar, Container, Dropdown, Button } from "react-bootstrap";
import { CgPlayButtonO } from "react-icons/cg";

function Sidebar(props) {

    const location = useLocation();
    const [selected, setSelected] = useState("treatment")
    const [name, setName] = useState("Organization")

    useEffect(() => {
       
    }, [props.date, props.date1])
    const onSuccess = (data) => {
        setName(data.name)

        props.callid(data.id)
    };

    const getPrevDate = (date1) => {
     
        if (date1 === "") {
            const today = new Date().toISOString().slice(0, 10);
            props.setDate1(today);
            props.setDate(new Date());
        } else {
            var yest = new Date(date1);
            yest.setDate(yest.getDate() - 1);

            const formattedYest = yest.toISOString().slice(0, 10);
          
            props.setDate(yest);
            props.setDate1(formattedYest);
        }
    }

    const getNextDate = (date1) => {
       
        var tomorrow = new Date(date1);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const formattedTomorrow = tomorrow.toISOString().slice(0, 10);
        props.setDate(tomorrow);
        props.setDate1(formattedTomorrow);
      
    }

    return (
        <div >
            <div className="" style={{ margin: "auto" }}>
                <h4
                    style={{
                        color: "#245693",
                        marginTop: "8%",
                        fontFamily: "inter",
                        fontWeight: "700",
                        paddingLeft: "20px"

                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 32 32"><path fill="#245693" d="M28 16v6H4V6h7V4H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8v4H8v2h16v-2h-4v-4h8a2 2 0 0 0 2-2v-6ZM18 28h-4v-4h4Z" /><path fill="#245693" d="M18 18h-.01a1 1 0 0 1-.951-.725L15.246 11H11V9h5a1 1 0 0 1 .962.725l1.074 3.76l3.009-9.78A1.01 1.01 0 0 1 22 3a.98.98 0 0 1 .949.684L24.72 9H30v2h-6a1 1 0 0 1-.949-.684l-1.013-3.04l-3.082 10.018A1 1 0 0 1 18 18" /></svg>&nbsp;&nbsp; Monitor </h4>
            </div>
            <div style={{
                display: "flex", backgroundColor: "rgba(233, 246, 251, 1)", color: "rgba(10, 129, 135, 1)",
                margin: "auto", borderRadius: "20px", alignItems: 'center', height: '45px', border:'.5px solid #80808030'
            }} className="row ">
                <div className='col-2' style={{ padding: 0 }}><button onClick={() => {if(props.load===false) { getPrevDate(props.date)} }} style={{ padding: 0, background: "none", margin: 'auto', display: 'flex' }}>
                    <CgPlayButtonO style={{ height: '25px', width: '25px', color: '#666', transform: 'rotate(180deg)' }} />
                </button></div>
                <div className='col-8' style={{ textAlign: "center", fontSize: "18px" }}>
                    <label style={{ margin: 0, fontSize: '17px', fontWeight: 400 }} className='day-head'>
                        {props.date1 === new Date().toISOString().slice(0, 10) ? "Today" : props.date1}
                    </label>
                </div>
                <div className='col-2' style={{ padding: 0 }}><button onClick={() => {if(props.load===false) {getNextDate(props.date)} }} disabled={props.date1 === new Date().toISOString().slice(0, 10)} style={{ padding: 0, background: "none", margin: 'auto', display: 'flex' }}>
                    <CgPlayButtonO style={{ height: '25px', width: '25px', color: '#666' }} />
                </button></div>
            </div>
            <br />
            <Nav>
                <Dropdown as={Nav.Item} style={{ width: "100%", }}>
                    <Dropdown.Toggle
                        as={Nav.Link}
                        data-toggle="dropdown"
                        id="dropdown-67443507"
                        variant="default"
                        className="m-0"
                        style={{
                            display: "flex", backgroundColor: "rgba(233, 246, 251, 1)", color: "rgba(10, 129, 135, 1)",
                            margin: "auto", borderRadius: "20px",
                        }}
                    >
                        <h6 style={{
                            textTransform: "capitalize", fontFamily: "Poppins",
                            fontSize: "18px",
                            fontWeight: "500",
                            whiteSpace: "break-spaces",
                            wordBreak: "break-all",
                            margin:0
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.75 1.75h3.5v3.5h-3.5zm4 9h3.5v3.5h-3.5zm-8 0h3.5v3.5h-3.5zm5.75-5v2m-3.75 2.5v-2h7.5v2" /></svg>&nbsp;&nbsp;&nbsp;
                            {name}</h6>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "100%", }}>
                        <ControlledExpandedNode onSuccess={onSuccess} />
                    </Dropdown.Menu>
                </Dropdown>



                <li
                    onClick={() => {
                        props.onSelect("vitals"); setSelected("vitals")
                    }}
                    style={{ width: "100%", marginTop: "5%" }}
                    className={selected === "vitals" &&
                        " active active-pro"

                    }

                >
                    <NavLink
                        to=""
                        className="nav-link patientRecbutton"
                        style={{ backgroundColor: selected === "vitals" && "#024851 ", width: "100%", lineHeight: "0px" }}
                        activeClassName="activeRoute"

                    >
                        <svg style={{ color: selected === "vitals" && "white " }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M9 20q-.475 0-.85-.275t-.55-.7L5.3 13H2q-.425 0-.712-.288T1 12t.288-.712T2 11h4q.325 0 .563.175t.362.475L9 17.1l4.6-12.125q.175-.425.55-.7T15 4t.85.275t.55.7L18.7 11H22q.425 0 .713.288T23 12t-.288.713T22 13h-4q-.325 0-.562-.175t-.363-.475L15 6.9l-4.6 12.125q-.175.425-.55.7T9 20" /></svg>&nbsp;&nbsp;
                        <p style={{ color: selected === "vitals" && "white " }}>Vitals</p>
                    </NavLink>
                </li>

                <li
                    style={{ width: "100%" }}
                    onClick={() => {
                        props.onSelect("treatment"); setSelected("treatment")
                    }}
                    className={selected === "treatment" &&
                        " active active-pro"

                    }

                >
                    <NavLink

                        to=""
                        className="nav-link patientRecbutton"
                        activeClassName="icons-right"
                        style={{ backgroundColor: selected === "treatment" && "#024851 ", width: "100%", lineHeight: "0px" }}
                    >
                        <svg style={{ color: selected === "treatment" && "white " }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" color="currentColor"><path d="M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2s7.071 0 8.535 1.464C22 4.93 22 7.286 22 12s0 7.071-1.465 8.535c-.816.817-1.91 1.178-3.535 1.338" /><path d="M12 22a5 5 0 0 1 5-5M2 9h12m4 0h4M9 22V9m0-4V2" /></g></svg>
                        &nbsp;&nbsp;
                        <p style={{ color: selected === "treatment" && "white " }}>Treatment Plan</p>
                    </NavLink>
                </li>


                <li
                    style={{ width: "100%" }}
                    onClick={() => {
                        props.onSelect("care"); setSelected("care")
                    }}
                    className={selected === "care" &&
                        " active active-pro"

                    }

                >
                    <NavLink
                        style={{ backgroundColor: selected === "care" && "#024851 ", width: "100%", lineHeight: "0px" }}
                        to=""
                        className="nav-link patientRecbutton"
                        activeClassName="active"
                    >
                        <p style={{ color: selected === "care" && "white " }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="M9.5 3a5.5 5.5 0 0 0-5.044 7.695a4 4 0 0 0-.819.615a6.5 6.5 0 1 1 12.259-1.645a3.7 3.7 0 0 0-.929-.563A5.5 5.5 0 0 0 9.5 3m4.488 5.834Q14 8.67 14 8.5a4.5 4.5 0 1 0-8.616 1.821a4.2 4.2 0 0 1 1.048-.135a3.5 3.5 0 1 1 6.564-1.526zM9.5 6A1.5 1.5 0 0 0 8 7.5v4.251c-.584-.197-1.243-.316-1.866-.248c-.458.05-.916.201-1.301.515c-.39.317-.67.77-.818 1.36a.5.5 0 0 0 .43.619c2.592.288 3.914 1.7 4.553 2.728c.48.772 1.41 1.407 2.435 1.229l1.517-.264a2.5 2.5 0 0 0 1.918-1.599l.983-2.67a2.5 2.5 0 0 0-1.914-3.327L11 9.58V7.5A1.5 1.5 0 0 0 9.5 6M9 7.5a.5.5 0 0 1 1 0V10a.5.5 0 0 0 .414.492l3.35.587a1.5 1.5 0 0 1 1.149 1.996l-.984 2.67a1.5 1.5 0 0 1-1.15.96l-1.517.264c-.502.087-1.077-.227-1.415-.772c-.7-1.128-2.094-2.62-4.649-3.098c.08-.128.17-.227.266-.305q.306-.247.777-.297c.642-.07 1.419.142 2.035.45A.5.5 0 0 0 9 12.5z" /></svg>
                            &nbsp;&nbsp;Care Touch</p>
                    </NavLink>
                </li>


            </Nav>
        </div>
    );
}

export default Sidebar;
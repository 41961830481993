
import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import printJS from "print-js";

import moment from "moment";
function FormView(props) {
    const [visitor] = useState(props.patient)
    
    
    return (

        <div className='container'>
             
        
                <div className=''>
                    
                <h5 style={{textDecoration:"underline", textAlign:"center",fontSize:"22px"  }}>
                    <b>Visitor Details</b>

                </h5>
                
                </div><br/>


                <div >
                    <div
                        style={{ paddingRight: "4%" , textAlign:'left'}}
                        className="list-group-flush"
                    >
                        <div
                            style={{ paddingRight: "4%" }}
                            className="list-group-flush"
                        >



                            <div className='row'>
                               <div className='col-7'><b>Name </b></div> <div className='col-5' style={{textTransform:"capitalize"}}>:{visitor.first_name}&nbsp;{visitor.last_name}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>ID </b></div><div className='col-5'>:{visitor.id}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Gender</b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{visitor.gender}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Types of Visitor </b></div><div className='col-5' style={{textTransform:"capitalize"}}>:{visitor.visitor_type}</div>

                            </div><br />
                            {visitor.name_of_resident_name&&<><div className='row'>
                               <div className='col-7'><b>Resident </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{visitor.name_of_resident_name}({visitor.bedunit_code})</div>

                            </div><br /></>}
                            <div className='row'>
                               <div className='col-7'><b>Address </b> </div><div className='col-5' style={{textTransform:"capitalize"}}>:{visitor.address}{visitor.location&&", "+visitor.location}{", "}{visitor.state}{", "}{visitor.pincode}{", "}{visitor.country}</div>

                            </div><br />
                           
                           
                            <div className='row'>
                               <div className='col-7'><b>Phone Number </b></div><div className='col-5'>:{visitor.code}{" "}{visitor.mobile_number}</div>

                            </div><br />
                            {visitor.email && <><div className='row'>
                               <div className='col-7'><b>Email </b></div><div className='col-5'>:{visitor.email}</div>

                               </div><br/></>}
                               <div className='row'>
                               <div className='col-7'><b>Number of visitors </b></div><div className='col-5'>:{visitor.number_of_visitors}</div>

                                </div><br/>
                              <div className='row'>
                               <div className='col-7'><b>Purpose of visit </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{visitor.purpose.replace(/_/g, ' ')}</div>

                                </div><br/>
                                <div className='row'>
                               <div className='col-7'><b>Duration of visit </b></div><div className='col-5'>:{visitor.day_count}{visitor.day_count==="1"?" day":"days"}&nbsp;({visitor.from_date}{visitor.day_count!=="1"&&" to "+visitor.to_date})</div>

                            </div><br />

                            {visitor.details&&<><div className='row'>
                               <div className='col-7'><b>The plans and arrangements required </b></div><div className='col-5'>:{visitor.details}</div>

                            </div><br /></>}
                          {(visitor.status==="approved"||visitor.status==="rejected")&& <div style={{textAlign:"right",color:"blue",paddingRight:"10%"}}>({visitor.status==="approved"?"Approved by ":"Rejeted by "} <b>{visitor.approved_user_type==="hospital"?"Trust":visitor.approved_by_name})</b></div>}
                        
                        </div>
                    </div>

                  
                </div>
            </div>
        
        

          
   

    )
}
export default FormView;
import React, {useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function ShipDistance (props) {
    
   const id =props.id;
    const [distance, setDistance]=useState(props.setdistanceship !== " " ? props.setdistanceship : " ")
    const [type, setType]=useState(props.type !== " " ? props.type : 1)
    const [modalShow, setModalShow]=useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [errorSubmit, setErrorSubmit] = useState(false);

    const handleChange = (e)=> {
        const val = e.target.value
     
            setDistance(e.target.value)
         }

  const handleShipEdit = () => {

    if(distance<0){
      setErrorMsg("Negative Values not allowed!!!");
      setErrorSubmit(true);
     }
     if(distance=="0"){
      setErrorMsg("Zero Values not allowed!!!");
      setErrorSubmit(true);
     }
    if(distance!="0"){
                const id =props.id
            const tokenString= sessionStorage.getItem('usertoken');
       
            let str= tokenString.replace(/["]+/g, '');
             
             
           const dataToUpdate = 
               {
                    "threshold_distance": distance
               };

              //  if (type === 1) {
              //    dataToUpdate.threshold_distance = distance;
              //  }
              //  if (type === 2) {
              //   dataToUpdate.threshold_quantity = distance
              //  }
         
         
           
             axios.put(`${APIURL}/api/v1/service-provider/shipping-charge-detail/${id}/`,dataToUpdate, { headers: {
               
               "Authorization" : 'Token '+str,
               'Content-Type': 'application/json'  }
         
         
             })
         
         
         
             .then(res=>{
            
               if(res.data.status==="success"){
                    setModalShow(true)
                    //setCharge(" ")
               }
          
             })
             .catch(err=>{
           
               
         })
        }
            }
      const SubmitPopup =(props)=>{
    

                return (
                  <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                     backdrop="static"
                      keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                       
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className='text-success'>Distance Edited Successfully !!</h5>
                     
                    </Modal.Body>
                    <Modal.Footer>
                     
                       <Button variant="secondary" onClick={props.onHide}>Ok</Button>
              
                     
                     
              
                    </Modal.Footer>
                  </Modal>
                );
              } 
              const SubmitErrorPopup = (props) => {
                return (
                  <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 style={{ color: "red" }}>
                      {errorMsg}
                      </h5>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={props.onHide}>
                        Ok
                      </Button>
                    </Modal.Footer>
                  </Modal>
                );
              };
              const ErrorPopup =(props)=>{
    

                return (
                  <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                     backdrop="static"
                      keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                       
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
                     
                    </Modal.Body>
                    <Modal.Footer>
                     
                       <Button variant="secondary" onClick={props.onHide}>Ok</Button>
              
                     
                     
              
                    </Modal.Footer>
                  </Modal>
                );
              }
        
        return (<> <label>Edit Distance : </label>
            <div className='flex-col'>
             

<input
                    type="number"
                    name="distance"
                    min="0"
                    value={distance}
                    onChange={handleChange}
                    required
                ></input>  
  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
<button  onClick={handleShipEdit} className='btn btn-primary btn-col'>Update</button> 


{
        modalShow ? 
        <SubmitPopup
          show={modalShow}
          onHide= {() => { 
            setModalShow(false);
            props.setDistanceHandle(); 
          }}
        /> : null
      }
      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : (
        ""
      )}
                </div></>
      

        )

}

export default ShipDistance;

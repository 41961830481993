import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import Doctor from "../doctor/Doctor";
import ManagerCard from "../Manager/ManagerCard";
import "./doctorslist.css";
import axios from 'axios';
import { APIURL,TZ } from "../../Global";
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import LoadingOverlay from 'react-loading-overlay';
import BackButton from '../common/BackButton';


function DoctorsList() {
  const [loading,setLoading]=useState(false);
  const [doctorsList, setDoctorsList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState()
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false)

  const navigate = useNavigate();

 

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


 

  
  useEffect(() => {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");


    axios
      .get(`${APIURL}/api/v1/service-provider/doctors/?${TZ}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Token " + v2
        },
      })
      .then((res) => {
   
        if (res.data.status === "success") {
          const datas = res.data.doctors;
          setLoading(false)
          setFreeCount(res.data.free_user_count)
          setActiveCount(res.data.active_free_users)
          setDoctorsList(datas.reverse());
        } else {
          setErrorMsg("error");
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }, [refresh]);

  const refreshHandle = () => {
    setRefresh(!refresh)
  }

  return (<>
  


  <div className="">

<br/>
 

    
  

    {doctorsList.length !== 0 ? (<div>
      <div className="">
      <ManagerCard
                     
                     bdoList={doctorsList}
                     user="doctor"
                     handle={refreshHandle}
                   />
      </div>



    </div>)
      :
      <h5 style={{ color: "red", margin: "20px" }}>Doctors List is empty</h5>
    }

    {errorMsg === "error" ?
      <h5 style={{ color: "red" }}>

        Error in data fetching.Please try again!
      </h5>
      :
      null}

  </div>
</>
  );
}

export default DoctorsList;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button, Dropdown, ToggleButton, Tab, Tabs, ToggleButtonGroup, ButtonGroup } from "react-bootstrap";
import "./webnotifications.css";
import moment from 'moment';
function NotificationsGeneral() {

  return (
    <>
      <div className="row" style={{ backgroundColor: "#a7c8d1" }}>
        <div className="col-2">
          
        </div>

        <div className="col-10">
         
          <div className="dashboard1" style={{ marginBottom: "10px",width:"99%" }}>
            <br />
            <div style={{ display: "flex" }}><br/><br/>
             <h6 style={{marginLeft:"45%", marginTop:"10%",color: "red" }}>The page is under construction</h6>
             
            </div>
            <div style={{ marginTop: "20%", color: "red" }} className="text-center"></div>
          </div>
        </div>
       

      </div>

    </>
  );
}
export default NotificationsGeneral;

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FavDelete, Fav } from "./Context";
import { FaHeart } from "react-icons/fa";
import { handleFavorites } from "./handleFav";
import { MdFavorite } from "react-icons/md";
import { APIURL,TZ } from "../../Global";
import LoadingOverlay from 'react-loading-overlay';
import flag from "./assets/img/flag/us-flag.png";
import OwlCarousel from "react-owl-carousel";
import logo1 from "../../assets/images/felixa_logo.png";
import logo2 from "../../assets/images/logo_name.png";
import prod1 from "./assets/img/products-img1.jpg"
import prod2 from "./assets/img/products-img2.jpg"
import contact from "./assets/img/contact-1.png"
import PatientNav from "./PatientNav";
import PatientFooter from "./PatientFooter";
import prod5 from "./assets/img/products-img5.jpg"
import scan from "./assets/img/scanimage.png"
import cat2 from "./assets/img/categories-img2.png"
import cat3 from "./assets/img/categories-img3.png"
import cat4 from "./assets/img/categories-img4.png"
import { Badge, Button } from "react-bootstrap";
import classes from "./favorite.module.css";
import cat5 from "./assets/img/categories-img5.png"
import procedure from "./assets/img/procedureimage.png"
import ban1 from "./assets/img/banner-img1.png"
import ban2 from "./assets/img/banner-img2.png"
import ban3 from "./assets/img/banner-img3.png"
import { CartContext } from "./AddToCartContext";
import ban4 from "./assets/img/banner-categories-img1.jpg"
import ban5 from "./assets/img/banner-categories-img2.jpg"
import AddToCart from "./AddToCart";
import logo3 from "./assets/img/intpurple.png"
import { useAlert } from "react-alert";
import QuickView from "./QuickView";
function Categories({ setShowNavbarAndFooter, updateCartData }) {
  const [quickViewDetails, setQuickViewDetails] = useState({})
  const location = useLocation();
  const [cartdata, setCartdata] = useState("");
  const [cartShow, setCartShow] = useState(false);
  const [distance, setDistance] = useState(0.0);
  const alert = useAlert();
  const [featureList, setFeatureList] = useState([]);
  const [featureList1, setFeatureList1] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [showWishList, setShowWishList] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [promotions, setPromotions] = React.useState([]);
  const [category, setCategory] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [dataList, setDataList] = useState([]);
  const [dataList1, setDataList1] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [dataList3, setDataList3] = useState([]);
  const [dataList4, setDataList4] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataList5, setDataList5] = useState([]);
  const [dataList6, setDataList6] = useState([]);
  const [hasNext, setHasNext] = useState(true);
  const [load, setLoad] = useState(false);
  const [loadFilter, setLoadFilter] = useState(false);
  const [numItemsToDisplay, setNumItemsToDisplay] = useState(4);
  const [numItemsToDisplay1, setNumItemsToDisplay1] = useState(4);
  const [numItemsToDisplay2, setNumItemsToDisplay2] = useState(4);
  const [numItemsToDisplay3, setNumItemsToDisplay3] = useState(4);
  const [numItemsToDisplay4, setNumItemsToDisplay4] = useState(4);
  const [numItemsToDisplay5, setNumItemsToDisplay5] = useState(4);
  const [numItemsToDisplay6, setNumItemsToDisplay6] = useState(4);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const displayedData = dataList1.slice(0, numItemsToDisplay);
  const displayedData1 = dataList2.slice(0, numItemsToDisplay1);
  const displayedData2 = dataList3.slice(0, numItemsToDisplay2);
  const displayedData3 = dataList4.slice(0, numItemsToDisplay3);
  const displayedData4 = dataList5.slice(0, numItemsToDisplay4);
  const displayedData5 = dataList6.slice(0, numItemsToDisplay5);
  const { favDeletedList, setFavDeletedList } = useContext(FavDelete);
  const { favAdded, setFavAdded } = useContext(Fav);
  const { handleAddToCart } = useContext(CartContext);
  const [favData, setFavData] = useState([]);
  let navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  // const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);

  const [categorynew, setCategorynew] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const categorynew = urlParams.get('categorynew');
    setCategorynew(categorynew);
  }, []);
  // let categorynew = new URLSearchParams(location.search).get("categorynew");
  //favorite adding
  const AddTohandleFavorites = (item) => {
    console.log("add handFav fun", item);
    const DataToFav = {
      p_id:item.id,
      p_name:item.item_service_name,
      p_amount:item.amount,
      p_img:item.image,
      p_category:item.service_category
  }
    handleFavorites(DataToFav);
    console.log("after handleFavorites fun call");
    setFavAdded(true);
  };

  useEffect(() => {
    if (login_datas) {
      const userId = sessionStorage.getItem("userid");
      console.log("userid", userId);
      const localStorageKey = `myFavorite_${userId}`;
      try {
        const favoriteDatas = JSON.parse(localStorage.getItem(localStorageKey));
        //const parsedData = JSON.parse(favoriteDatas);
        setFavData(favoriteDatas);
      } catch (error) {
        let favoriteDatas = [];
      }
      // setFavUpdated(false);
      setFavDeletedList(false);
    }
  }, [favDeletedList, favAdded, login_datas]);


  const handleCategoryClick = (category) => {

    setCategory(category);
    setCategorynew("");

  };
  const addToCart = (item) => {
    
    handleAddToCart(item);
    // Additional logic or actions
  };
  const handleQuickView = (item) => {
    setQuickViewDetails({
      amount: item.amount,
      discount: item.discount,
      hospital: item.hospital,
      main_service: item.service_category === "master_labtest" ? "Laboratory" : item.service_category === "master_scan" ? "Scan" : item.service_category === "master_medicine" ? "Medicine" : item.service_category === "misc" ? "Miscellaneous" : item.service_category === "master_procedure" ? "Procedure" : item.service_category === "master_consumable" ? "Consumables" : "",
      name: item.hospital_name,
      service: item.item_service_name,
      service_category: item.service_category,
      image: item.service_category === "master_labtest" ? "" : item.service_category === "master_scan" ? "" : item.service_category === "master_medicine" ? item.image : item.service_category === "misc" ? "" : item.service_category === "master_procedure" ? "" : item.service_category === "master_consumable" ? item.image : "",
      id: item.id
    })
    setShowDetails(true);
  };
  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ width: "96%" }}>
          <QuickView item={quickViewDetails} handleRefreshCart={HandleClose} from="wo" />
        </Modal.Body>
      </Modal>

    )
  }
  useEffect(() => { setShowNavbarAndFooter(false); return () => setShowNavbarAndFooter(true); }, [setShowNavbarAndFooter]);



  useEffect(() => {
    // const tokenString = sessionStorage.getItem("usertoken");
    // let v2 = tokenString.replace(/["]+/g, "");
    const perPage = 10;
    let allData = [];
    let allData1 = [];
    let allData2 = [];
    let allData3 = [];
    let allData4 = [];
    let allData5 = [];
    let currentPage = 1;
    let totalPages = 1;

    const fetchData = async () => {
      try {
        let res = null;
        switch (true) {
          case categorynew === "Scan" || category === "master_scan":
            res = await axios.get(`${APIURL}/api/v1/patient/service-categories/?category=master_scan&page=${currentPage}&per_page=${perPage}`,
              //  {
              //   headers: { Authorization: "Token " + v2 }
              // }
            );
            allData = allData.concat(res.data.message);
            totalPages = res.data.total_pages;
            break;
          case categorynew === "Lab" || category === "master_lab":
            res = await axios.get(`${APIURL}/api/v1/patient/service-categories/?category=master_labtest&page=${currentPage}&per_page=${perPage}`,
              // {
              //   headers: { Authorization: "Token " + v2 }
              // }
            );
            allData1 = allData1.concat(res.data.message);
            totalPages = res.data.total_pages;
            break;
          case categorynew === "Misc" || category === "master_misc":
            res = await axios.get(`${APIURL}/api/v1/patient/service-categories/?category=misc&page=${currentPage}&per_page=${perPage}`,
              //  {
              //   headers: { Authorization: "Token " + v2 }
              // }
            );
            allData2 = allData2.concat(res.data.message);
            totalPages = res.data.total_pages;
            break;
          case categorynew === "Procedure" || category === "master_procedure":
            res = await axios.get(`${APIURL}/api/v1/patient/service-categories/?category=master_procedure&page=${currentPage}&per_page=${perPage}`,
              //  {
              //   headers: { Authorization: "Token " + v2 }
              // }
            );
            allData3 = allData3.concat(res.data.message);
            totalPages = res.data.total_pages;
            break;
          case categorynew === "Consumable" || category === "master_consumable":
            res = await axios.get(`${APIURL}/api/v1/patient/service-categories/?category=master_consumable&page=${currentPage}&per_page=${perPage}`,
              // {
              //   headers: { Authorization: "Token " + v2 }
              // }
            );
            allData4 = allData4.concat(res.data.message);
            totalPages = res.data.total_pages;
            break;
          case categorynew === "Medicine" || category === "master_medicine":
            res = await axios.get(`${APIURL}/api/v1/patient/service-categories/?category=master_medicine&page=${currentPage}&per_page=${perPage}`,
              //  {
              //   headers: { Authorization: "Token " + v2 }
              // }
            );
            allData5 = allData5.concat(res.data.message);
            totalPages = res.data.total_pages;
            break;
          default:
            // handle error
            break;
        }
        if (currentPage < totalPages) {
          currentPage++;
          fetchData();
        } else {
          // all pages have been fetched, do something with the data
          setLoading(false);
          setDataList1(allData);
          setDataList2(allData1);
          setDataList3(allData2);
          setDataList4(allData3);
          setDataList5(allData4);
          setDataList6(allData5); // or setDataList2, setDataList3, etc. depending on the category
        }
      } catch (error) {
        // handle error
      }
    };

    fetchData();
  }, [categorynew, category]);



  // const handleAddToCart =(item) =>{

  //   
  //   const today = new Date();
  //   const cartItem = {
  //     item: item,
  //     date: today.toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format
  //   };


  //   if ( login_datas) {

  //         const tokenString = sessionStorage.getItem("usertoken");


  //         let v2 = tokenString.replace(
  //           /["]+/g,
  //           ""
  //         );
  //         axios.get(`${APIURL}/api/v1/patient/service-provider-detail/?hospital_id=${item.hospital}`, {
  //           headers: { Authorization: "Token " + v2 },
  //         })
  //           .then((res) => {
  //             console.log(res.data);
  //             let hospital = res.data.hosp_details;
  //             if (hospital.latitude && hospital.longitude) {
  //               console.log('YES THE COORDINATES', login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
  //               calculateDistance(login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
  //             }

  //           })

  //           const dataTosend = {

  //             item_service: item.id,
  //             item_due_date:new Date().toISOString().split("T")[0],
  //             item_service_priority: "critical",
  //             geo_distance:distance,
  //             item_quantity:"1"
  //         };

  //         axios
  //         .post(`${APIURL}/api/v1/patient/cart/`, dataTosend, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: "Token " + v2,
  //           },
  //         })
  //         .then((res) => {
  //           if (res.data.status === "success") {
  //             alert.success(res.data.message);
  //             updateCartData();
  //             //setTimeout(refreshPage, 1000);

  //           } else {
  //             alert.error(res.data.message);

  //           }
  //         })
  //         .catch((err) => {
  //           //setErrorMsg("Error in submission");
  //         });

  //       }

  //       else{

  //         // If the user is not logged in, store the cart item locally
  //         const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
  //         const updatedCartItems = [...existingCartItems, cartItem];
  //         sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  //     alert.success("added to cart")
  // }


  // }

  const calculateDistance = (latt1, latt2, long1, long2) => {

    var R = 6371; // Radius of the earth in km
    const lat1 = parseFloat(latt1)
    const lat2 = parseFloat(latt2)
    const lon1 = parseFloat(long1)
    const lon2 = parseFloat(long2)
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // return d;
    setDistance(parseFloat(d).toFixed(4));
    console.log('THE DISTANCE', d)

  };
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }
  const HandleClose = () => {
    setCartShow(false);
  };

  const AddtoCartPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddToCart
            data={props.data}
            handle={HandleClose}
          // stage={displaystage}
          // onHide={handleDiseaseClose}
          // submitduration={submitDurationHandle}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={props.onHide}>
                    Close
                  </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };



  const display1 = dataList1 && dataList1.length > 0 ? (
    <>
      <h3 className="section-title" style={{ color: "#115BC0", marginTop: "-1" }}>Scan</h3>{numItemsToDisplay < dataList1.length && (
        <button style={{ marginLeft: "90%" }} onClick={() => setNumItemsToDisplay(numItemsToDisplay + 4)}> <b>View more</b></button>
      )}
      <div className="row" style={{ display: "flex", flexWrap: "wrap", marginTop: "1%", marginLeft: "2%" }}>



        {displayedData.map((item, index) => 
        {
          const isFavorite = favData.some(
            (favItem) => item.id === favItem.p_id
          );
          return (
            <div
              className="doctor-card456"
              style={{
                marginTop: "-3%",
                width: "270px",
                borderRadius: "15px",
                marginLeft: "-1%",
                marginTop: "1%",
                marginRight: "3%"
              }}
              key={index}
            >
              <div
                className="text-center123"
                style={{ width: "270px", fontSize: "15px" }}
              >
                <b style={{ color: "black" }}>

                  {item.item_service_name} <span>&nbsp;</span>{" "}
                  <Badge pill variant="success" style={{ fontSize: "10px" }}>
                    {item.discount}% OFF!
                  </Badge>
                  <Link
                    to="/search"
                    state={{ details: item.id }}
                    style={ {float:"right",marginRight:"5%"}}
                  >
                    <i className='bx bx-search-alt'></i>

                  </Link>
                </b>
                <br />
                <span style={{ display: "flex", flexDirection: "column", marginTop: "6px" }}>
                  <b style={{ fontSize: "11px", margin: "0 0 2px 0" }}>
                    <i className="fa fa-check-square" aria-hidden="true"></i>
                    <span>&nbsp;</span>
                    {item.service_category === "master_scan"
                      ? "Scan"
                      : item.service_category === "misc"
                        ? "Misc"
                        : item.service_category === "master_medicine"
                          ? "Medicine"
                          : item.service_category === "master_procedure"
                            ? "Procedure"
                            : item.service_category === "master_consumable"
                              ? "Consumables"
                              : item.service_category === "master_labtest"
                                ? "Test"
                                : item.service_category === "registration"
                                  ? "Registration"
                                  : ""}
                  </b>
                  <b style={{ fontSize: "11px", margin: "0" }}>
                    <i className="fa fa-h-square" aria-hidden="true"></i>
                    <span>&nbsp;</span>
                    {item.hospital_name}
                  </b>
                </span>
                <div className="price" style={{ display: "flex", alignItems: "center", width: "250px", marginTop: "10px" }}>
                  <b style={{ fontSize: "14px", color: "black" }}>
                    <span
                      className="old-price"
                      style={{
                        textDecoration: "line-through",
                        color: "grey",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <i class="fa fa-usd" aria-hidden="true"></i>{item.amount}
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span style={{ fontWeight: "bold" }} className="discounted-price">
                      {((item.amount * (100 - item.discount)) / 100).toFixed(2)}
                    </span>
                  </b>

                  <span style={{ marginLeft: "20px" }}></span>
                  {login_datas ? <FaHeart
                    
                    onClick={() => AddTohandleFavorites(item)}
                    className={
                      isFavorite
                        ? classes.favAdded
                        : classes.favNotAdded
                    }
                    style={{ color: "#e2e2d0", marginLeft: "10px" }}
                  /> : ""}
                  <Button
                    className="my-button"
                    style={{ marginLeft: "3%" }}
                    onClick={() => addToCart(item)}
                  >
                    Add to Cart
                  </Button>
                </div>
              </div>
            </div>);
})}
      </div> </>) : ("No Scans to Display")

  const display2 = dataList2 && dataList2.length > 0 ? (
    <>
      <h3 className="section-title" style={{ color: "#115BC0", marginTop: "-1" }}>Lab Test</h3>{numItemsToDisplay1 < dataList2.length && (
        <button style={{ marginLeft: "90%" }} onClick={() => setNumItemsToDisplay1(numItemsToDisplay1 + 4)}> <b>View more</b></button>
      )}
      <div className="row" style={{ display: "flex", flexWrap: "wrap", marginTop: "1%", marginLeft: "2%" }}>



        {displayedData1.map((item, index) => 
        {
          const isFavorite = favData.some(
            (favItem) => item.id === favItem.p_id
          );
          return (
            <div
              className="doctor-card456"
              style={{
                marginTop: "-3%",
                width: "270px",
                borderRadius: "15px",
                marginLeft: "-1%",
                marginTop: "1%",
                marginRight: "3%"
              }}
              key={index}
            >
              <div
                className="text-center123"
                style={{ width: "270px", fontSize: "15px" }}
              >
                <b onClick={() => ""} style={{ color: "black" }}>
                  {item.item_service_name} <span>&nbsp;</span>{" "}
                  <Badge pill variant="success" style={{ fontSize: "10px" }}>
                    {item.discount}% OFF!
                  </Badge>
                  <Link
                    to="/search"
                    state={{ details: item.id }}
                    style={ {float:"right",marginRight:"5%"}}
                  >
                    <i className='bx bx-search-alt'></i>

                  </Link>
                </b>
                <br />
                <span style={{ display: "flex", flexDirection: "column", marginTop: "6px" }}>
                  <b style={{ fontSize: "11px", margin: "0 0 2px 0" }}>
                    <i className="fa fa-check-square" aria-hidden="true"></i>
                    <span>&nbsp;</span>
                    {item.service_category === "master_scan"
                      ? "Scan"
                      : item.service_category === "misc"
                        ? "Misc"
                        : item.service_category === "master_medicine"
                          ? "Medicine"
                          : item.service_category === "master_procedure"
                            ? "Procedure"
                            : item.service_category === "master_consumable"
                              ? "Consumables"
                              : item.service_category === "master_labtest"
                                ? "Test"
                                : item.service_category === "registration"
                                  ? "Registration"
                                  : ""}
                  </b>
                  <b style={{ fontSize: "11px", margin: "0" }}>
                    <i className="fa fa-h-square" aria-hidden="true"></i>
                    <span>&nbsp;</span>
                    {item.hospital_name}
                  </b>
                </span>
                <div className="price" style={{ display: "flex", alignItems: "center", width: "250px", marginTop: "10px" }}>
                  <b style={{ fontSize: "14px", color: "black" }}>
                    <span
                      className="old-price"
                      style={{
                        textDecoration: "line-through",
                        color: "grey",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <i class="fa fa-usd" aria-hidden="true"></i>{item.amount}
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span style={{ fontWeight: "bold" }} className="discounted-price">
                      {((item.amount * (100 - item.discount)) / 100).toFixed(2)}
                    </span>
                  </b>

                  <span style={{ marginLeft: "30px" }}></span>
                  {login_datas ? <FaHeart
                    onClick={() => AddTohandleFavorites(item)}
                    className={
                      isFavorite
                        ? classes.favAdded
                        : classes.favNotAdded
                    }
                    style={{ color: "#e2e2d0", marginLeft: "10px" }}
                  /> : ""}
                  <Button
                    className="my-button"
                    style={{ marginLeft: "3%" }}
                    onClick={() => addToCart(item)}
                  >
                    Add to Cart
                  </Button>
                </div>
              </div>
            </div>)
        })}
      </div> </>) : ("No Tests to Display")

  const display3 = dataList3 && dataList3.length > 0 ? (
    <>
      <h3 className="section-title" style={{ color: "#115BC0", marginTop: "-1" }}>Miscellaneous</h3>{numItemsToDisplay2 < dataList3.length && (
        <button style={{ marginLeft: "90%" }} onClick={() => setNumItemsToDisplay2(numItemsToDisplay2 + 4)}> <b>View more</b></button>
      )}
      <div className="row" style={{ display: "flex", flexWrap: "wrap", marginTop: "1%", marginLeft: "2%" }}>



        {displayedData2.map((item, index) =>
         {
          const isFavorite = favData.some(
            (favItem) => item.id === favItem.p_id
          );
          return (
            <div
              className="doctor-card456"
              style={{
                marginTop: "-3%",
                width: "270px",
                borderRadius: "15px",
                marginLeft: "-1%",
                marginTop: "1%",
                marginRight: "3%"
              }}
              key={index}
            >
              <div
                className="text-center123"
                style={{ width: "270px", fontSize: "15px" }}
              >
                <b onClick={() => ""} style={{ color: "black" }}>
                  {item.item_service_name} <span>&nbsp;</span>{" "}
                  <Badge pill variant="success" style={{ fontSize: "10px" }}>
                    {item.discount}% OFF!
                  </Badge>
                  <Link
                    to="/search"
                    state={{ details: item.id }}
                    style={ {float:"right",marginRight:"5%"}}
                  >
                    <i className='bx bx-search-alt'></i>

                  </Link>
                </b>
                <br />
                <span style={{ display: "flex", flexDirection: "column", marginTop: "6px" }}>
                  <b style={{ fontSize: "11px", margin: "0 0 2px 0" }}>
                    <i className="fa fa-check-square" aria-hidden="true"></i>
                    <span>&nbsp;</span>
                    {item.service_category === "master_scan"
                      ? "Scan"
                      : item.service_category === "misc"
                        ? "Misc"
                        : item.service_category === "master_medicine"
                          ? "Medicine"
                          : item.service_category === "master_procedure"
                            ? "Procedure"
                            : item.service_category === "master_consumable"
                              ? "Consumables"
                              : item.service_category === "master_labtest"
                                ? "Test"
                                : item.service_category === "registration"
                                  ? "Registration"
                                  : ""}
                  </b>
                  <b style={{ fontSize: "11px", margin: "0" }}>
                    <i className="fa fa-h-square" aria-hidden="true"></i>
                    <span>&nbsp;</span>
                    {item.hospital_name}
                  </b>
                </span>
                <div className="price" style={{ display: "flex", alignItems: "center", width: "250px", marginTop: "10px" }}>
                  <b style={{ fontSize: "14px", color: "black" }}>
                    <span
                      className="old-price"
                      style={{
                        textDecoration: "line-through",
                        color: "grey",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <i class="fa fa-usd" aria-hidden="true"></i>{item.amount}
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span style={{ fontWeight: "bold" }} className="discounted-price">
                      {((item.amount * (100 - item.discount)) / 100).toFixed(2)}
                    </span>
                  </b>

                  <span style={{ marginLeft: "30px" }}></span>
                  {login_datas ? <FaHeart
                    onClick={() => AddTohandleFavorites(item)}
                    className={
                      isFavorite
                        ? classes.favAdded
                        : classes.favNotAdded
                    }
                    style={{ color: "#e2e2d0", marginLeft: "10px" }}
                  /> : ""}
                  <Button
                    className="my-button"
                    style={{ marginLeft: "3%" }}
                    onClick={() => addToCart(item)}
                  >
                    Add to Cart
                  </Button>
                </div>
              </div>
            </div>);
})}
      </div></>) : (" No Procedures to Display")
  const display4 = dataList4 && dataList4.length > 0 ? (
    <>
      <h3 className="section-title" style={{ color: "#115BC0", marginTop: "-1" }}>Procedure</h3>{numItemsToDisplay3 < dataList4.length && (
        <button style={{ marginLeft: "90%" }} onClick={() => setNumItemsToDisplay3(numItemsToDisplay3 + 4)}> <b>View more</b></button>
      )}
      <div className="row" style={{ display: "flex", flexWrap: "wrap", marginTop: "1%", marginLeft: "2%" }}>



        {displayedData3.map((item, index) => 
        {
          const isFavorite = favData.some(
            (favItem) => item.id === favItem.p_id
          );
          return (
            <div
              className="doctor-card456"
              style={{
                marginTop: "-3%",
                width: "270px",
                borderRadius: "15px",
                marginLeft: "-1%",
                marginTop: "1%",
                marginRight: "3%"
              }}
              key={index}
            >
              <div
                className="text-center123"
                style={{ width: "270px", fontSize: "15px" }}
              >
                <b onClick={() =>""} style={{ color: "black" }}>
                  {item.item_service_name} <span>&nbsp;</span>{" "}
                  <Badge pill variant="success" style={{ fontSize: "10px" }}>
                    {item.discount}% OFF!
                  </Badge>
                  <Link
                    to="/search"
                    state={{ details: item.id }}
                    style={ {float:"right",marginRight:"5%"}}
                  >
                    <i className='bx bx-search-alt'></i>

                  </Link>
                </b>
                <br />
                <span style={{ display: "flex", flexDirection: "column", marginTop: "6px" }}>
                  <b style={{ fontSize: "11px", margin: "0 0 2px 0" }}>
                    <i className="fa fa-check-square" aria-hidden="true"></i>
                    <span>&nbsp;</span>
                    {item.service_category === "master_scan"
                      ? "Scan"
                      : item.service_category === "misc"
                        ? "Misc"
                        : item.service_category === "master_medicine"
                          ? "Medicine"
                          : item.service_category === "master_procedure"
                            ? "Procedure"
                            : item.service_category === "master_consumable"
                              ? "Consumables"
                              : item.service_category === "master_labtest"
                                ? "Test"
                                : item.service_category === "registration"
                                  ? "Registration"
                                  : ""}
                  </b>
                  <b style={{ fontSize: "11px", margin: "0" }}>
                    <i className="fa fa-h-square" aria-hidden="true"></i>
                    <span>&nbsp;</span>
                    {item.hospital_name}
                  </b>
                </span>
                <div className="price" style={{ display: "flex", alignItems: "center", width: "250px", marginTop: "10px" }}>
                  <b style={{ fontSize: "14px", color: "black" }}>
                    <span
                      className="old-price"
                      style={{
                        textDecoration: "line-through",
                        color: "grey",
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <i class="fa fa-usd" aria-hidden="true"></i>{item.amount}
                    </span>
                    <span>&nbsp;&nbsp;</span>
                    <span style={{ fontWeight: "bold" }} className="discounted-price">
                      {((item.amount * (100 - item.discount)) / 100).toFixed(2)}
                    </span>
                  </b>

                  <span style={{ marginLeft: "30px" }}></span>
                  {login_datas ? <FaHeart
                    onClick={() => AddTohandleFavorites(item)}
                    className={
                      isFavorite
                        ? classes.favAdded
                        : classes.favNotAdded
                    }
                    style={{ color: "#e2e2d0", marginLeft: "10px" }}
                  /> : ""}
                  <Button
                    className="my-button"
                    style={{ marginLeft: "3%" }}
                    onClick={() => addToCart(item)}
                  >
                    Add to Cart
                  </Button>
                </div>
              </div>
            </div>);
})}
      </div></>) : (" No Miscellaneous to Display")

  const display5 = dataList5 && dataList5.length > 0 ? (
    <>
      <h3 className="section-title" style={{ color: "#115BC0", marginTop: "-1" }}>Consumables</h3>{numItemsToDisplay4 < dataList5.length && (
        <button style={{ marginLeft: "90%" }} onClick={() => setNumItemsToDisplay4(numItemsToDisplay4 + 4)}> <b>View more</b></button>
      )}
      <div className="row" style={{ display: "flex", flexWrap: "wrap", marginTop: "1%", marginLeft: "2%" }}>



        {displayedData4.map((item, index) => 
        {
          const isFavorite = favData.some(
            (favItem) => item.id === favItem.p_id
          );
          return (
            <div className="single-products-box" style={{ width: "25%" }}>
              <div className="image" style={{ backgroundColor: "#f7f8fa" }} >


                <div className="sale">Sale</div>
                <a href="#" className="d-block">  <Link
                    to="/search"
                    state={{ details: item.id }}
                    style={ {float:"right",marginRight:"5%"}}
                  >
                    

                  <img src={item.image} alt="image" style={{ height: "250px", width: "300px", backgroundColor: "#f7f8fa" }} onClick={() => ""} /> </Link></a>

                <div className="buttons-list">
                  <ul>
                    <li>
                      <div onClick={() => addToCart(item)} className="cart-btn">
                        <a data-toggle="modal" data-target="#productsQuickView">
                          <i class="fa fa-cart-plus" aria-hidden="true"></i>
                          <span className="tooltip-label">Add to Cart</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div onClick={() => ""} className="quick-view-btn">
                        
                      <Link
                                                        to="/search"
                                                        state={{ details: item.id}}

                                                    >
                          <i className='bx bx-search-alt'></i>
                          <span className="tooltip-label">Quick View</span>
                        </Link>
                      </div>
                    </li>

                    <li>
                      {login_datas ?
                        <div
                          onClick={() => AddTohandleFavorites(item)}
                          className="quick-view-btn"
                        >
                          <a
                            data-toggle="modal"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: "2px",
                            }}
                          >
                            <MdFavorite
                              className={
                                isFavorite
                                  ? classes.favAdded
                                  : classes.favNotAdded
                              }
                            />
                            <span className="tooltip-label">
                              Add to Favorite
                            </span>
                          </a>
                        </div>
                        : ""}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="content">
                <h3 style={{ fontFamily: "Arial, monospace" }}><b>{item.item_service_name}</b></h3>
                <div className="price">
                  <span className="old-price"> <i class="fa fa-usd" aria-hidden="true"></i>{item.amount}</span>
                  <span>&nbsp;</span><span className="discounted-price">{(item.amount * (100 - item.discount) / 100).toFixed(2)}</span>
                </div>
              </div>
            </div>);
})}
      </div></>) : (" No Consumables to Display")

  const display6 = dataList6 && dataList6.length > 0 ? (
    <>
      <h3 className="section-title" style={{ color: "#115BC0", marginTop: "-1" }}>Medicines</h3>{numItemsToDisplay5 < dataList6.length && (
        <button style={{ marginLeft: "90%" }} onClick={() => setNumItemsToDisplay5(numItemsToDisplay5 + 4)}> <b>View more</b></button>
      )}
      <div className="row" style={{ display: "flex", flexWrap: "wrap", marginTop: "1%", marginLeft: "2%" }}>



        {displayedData5.map((item, index) => 
        {
          const isFavorite = favData.some(
            (favItem) => item.id === favItem.p_id
          );
          return (
            <div className="single-products-box" style={{ width: "25%" }}>
              <div className="image" style={{ backgroundColor: "#f7f8fa" }} >


                <div className="sale">Sale</div>
                <a href="#" className="d-block">  <Link
                                                        to="/search"
                                                        state={{ details: item.id}}

                                                    > <img src={item.image} alt="image" style={{ height: "250px", width: "300px", backgroundColor: "#f7f8fa" }} onClick={() => ""} /></Link></a>

                <div className="buttons-list">
                  <ul>
                    <li>
                      <div onClick={() => addToCart(item)} className="cart-btn">
                        <a data-toggle="modal" data-target="#productsQuickView">
                          <i class="fa fa-cart-plus" aria-hidden="true"></i>
                          <span className="tooltip-label">Add to Cart</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div onClick={() => ""} className="quick-view-btn">
                      <Link
                                                        to="/search"
                                                        state={{ details: item.id}}

                                                    >
                          <i className='bx bx-search-alt'></i>
                          <span className="tooltip-label">Quick View</span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      {login_datas ?
                        <div
                          onClick={() => AddTohandleFavorites(item)}
                          className="quick-view-btn"
                        >
                          <a
                            data-toggle="modal"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: "2px",
                            }}
                          >
                            <MdFavorite
                              className={
                                isFavorite
                                  ? classes.favAdded
                                  : classes.favNotAdded
                              }
                            />
                            <span className="tooltip-label">
                              Add to Favorite
                            </span>
                          </a>
                        </div>
                        : ""}
                    </li>

                  </ul>
                </div>
              </div>

              <div className="content">
                <h3 style={{ fontFamily: "Arial, monospace" }}><b>{item.item_service_name}</b></h3>
                <div className="price">
                  <span className="old-price"> <i class="fa fa-usd" aria-hidden="true"></i>{item.amount}</span>
                  <span>&nbsp;</span><span className="discounted-price">{(item.amount * (100 - item.discount) / 100).toFixed(2)}</span>
                </div>
              </div>
            </div>);
})}
      </div></>) : ("No Medicines")
  return (<>

    <PatientNav />

    <br /><br />
    <br /><br />
    <section className="page-title-area" style={{ paddingTop: "79px", paddingBottom: "24px" }}>


      <h2 style={{ marginTop: "-2%" }}>Categories</h2>


    </section>
    <section className="products-area ptb-70" style={{ marginTop: "-3%" }}>
      <div className="container">



        <div className="row" >
          <div className="col-lg-2 col-sm-4 col-md-4" style={{ marginLeft: "2%", width: "15%" }}>

            <div className="single-categories-box">
              <img
                src={scan}
                alt="Scan"

                onClick={() => handleCategoryClick("master_scan")}
                style={{ cursor: "pointer", height: "55px" }}
              />
              <h3>Scan</h3>

            </div>
          </div>



          <div className="col-lg-2 col-sm-4 col-md-4" style={{ width: "15%" }}>
            <div className="single-categories-box">
              <img
                src={cat3}
                alt="Labratory"
                onClick={() => handleCategoryClick("master_lab")}
                style={{ cursor: "pointer", height: "55px" }}
              />
              <h3>Laboratory</h3>

            </div>
          </div>



          <div className="col-lg-2 col-sm-4 col-md-4" style={{ width: "15%" }}>
            <div className="single-categories-box">
              <img
                src={cat5}
                alt="Miscellaneous"
                onClick={() => handleCategoryClick("master_misc")}
                style={{ cursor: "pointer", height: "55px" }}
              />
              <h3>Miscellaneous</h3>

            </div>
          </div>
          <div className="col-lg-2 col-sm-4 col-md-4" style={{ width: "15%" }}>
            <div className="single-categories-box">
              <img
                src={procedure}
                alt="Procedures"

                onClick={() => handleCategoryClick("master_procedure")}
                style={{ cursor: "pointer", height: "55px" }}
              />
              <h3>Procedures</h3>

            </div>
          </div>

          <div className="col-lg-2 col-sm-4 col-md-4" style={{ width: "15%" }}>
            <div className="single-categories-box">
              <img
                src={cat2}
                alt="Procedures"

                onClick={() => handleCategoryClick("master_consumable")}
                style={{ cursor: "pointer", height: "55px" }}
              />
              <h3>Consumables</h3>

            </div>
          </div>


          <div className="col-lg-2 col-sm-4 col-md-4" style={{ width: "15%" }}>
            <div className="single-categories-box">
              <img
                src={cat4}
                alt="Procedures"

                onClick={() => handleCategoryClick("master_medicine")}
                style={{ cursor: "pointer", height: "55px" }}
              />
              <h3>Pharmacy</h3>

            </div>
          </div>


        </div>







        <div className="services" style={{ marginLeft: "1%" }}>
          {category === "master_scan" && dataList1.length > 0 ? (
            display1
          ) : category === "master_scan" && dataList1.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList1 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'

                    }
                  }),
                  overlay: {},
                }}

              />
            ) : (
              // Display the "No Scans to Display" message when the dataList1 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Scans to Display */}
              </h6>
            )
          ) : categorynew === "Scan" && dataList1.length > 0 ? (
            display1
          ) : categorynew === "Scan" && dataList1.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList1 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'

                    }
                  }),
                  overlay: {},
                }}

              />
            ) : (
              // Display the "No Scans to Display" message when the dataList1 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Scans to Display */}
              </h6>
            )
          ) : null}
        </div>




        <div className="services" style={{ marginLeft: "1%" }}>
          {category === "master_lab" && dataList2.length > 0 ? (
            display2
          ) : category === "master_lab" && dataList2.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList2 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Tests to Display" message when the dataList2 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Tests to Display */}
              </h6>
            )
          ) : categorynew === "Lab" && dataList2.length > 0 ? (
            display2
          ) : categorynew === "Lab" && dataList2.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList2 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Tests to Display" message when the dataList2 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Tests to Display */}
              </h6>
            )
          ) : null}
        </div>





        <div className="services" style={{ marginLeft: "1%" }}>
          {category === "master_misc" && dataList3.length > 0 ? (
            display3
          ) : category === "master_misc" && dataList3.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList3 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Miscellaneous to Display" message when the dataList3 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Miscellaneous to Display */}
              </h6>
            )
          ) : categorynew === "Misc" && dataList3.length > 0 ? (
            display3
          ) : categorynew === "Misc" && dataList3.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList3 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Miscellaneous to Display" message when the dataList3 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Miscellaneous to Display */}
              </h6>
            )
          ) : null}
        </div>




        <div className="services" style={{ marginLeft: "1%" }}>
          {category === "master_procedure" && dataList4.length > 0 ? (
            display4
          ) : category === "master_procedure" && dataList4.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList3 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Miscellaneous to Display" message when the dataList3 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Procedure to Display */}
              </h6>
            )
          ) : categorynew === "Procedure" && dataList4.length > 0 ? (
            display4
          ) : categorynew === "Procedure" && dataList4.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList3 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Miscellaneous to Display" message when the dataList3 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Procedure to Display */}
              </h6>
            )
          ) : null}
        </div>




        <div className="services" style={{ marginLeft: "1%" }}>
          {category === "master_consumable" && dataList5.length > 0 ? (
            display5
          ) : category === "master_consumable" && dataList5.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList3 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Miscellaneous to Display" message when the dataList3 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Consumable to Display */}
              </h6>
            )
          ) : categorynew === "Consumable" && dataList5.length > 0 ? (
            display5
          ) : categorynew === "Consumable" && dataList5.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList3 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Miscellaneous to Display" message when the dataList3 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Consumable to Display */}
              </h6>
            )
          ) : null}
        </div>





        <div className="services" style={{ marginLeft: "1%" }}>
          {category === "master_medicine" && dataList6.length > 0 ? (
            display6
          ) : category === "master_medicine" && dataList6.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList3 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Miscellaneous to Display" message when the dataList3 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Medicines to Display */}
              </h6>
            )
          ) : categorynew === "Medicine" && dataList6.length > 0 ? (
            display6
          ) : categorynew === "Medicine" && dataList6.length === 0 ? (
            loading ? (
              // Display the loader UI when the dataList3 array is empty and loading is true
              <LoadingOverlay
                active={loading}
                spinner
                styles={{
                  spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                      stroke: 'rgba(0, 0, 255, 0.5)'
                    }
                  }),
                  overlay: {},
                }}
              />
            ) : (
              // Display the "No Miscellaneous to Display" message when the dataList3 array is empty and loading is false
              <h6 style={{ justifyContent: "center", color: "red" }}>
                {/* No Medicine to Display */}
              </h6>
            )
          ) : null}
        </div>



      </div>



    </section>
    {showDetails ? (
      <DetailPopup
        show={showDetails}

        onHide={() => {
          setShowDetails(false);

        }}
      />
    ) : (
      ""
    )}
    {cartShow ? (
      <AddtoCartPopUp
        show={cartShow}
        data={cartdata}
        onHide={() => {
          setCartShow(false);

        }}
      />
    ) : (
      ""
    )}
  </>)
}

export default Categories
import React ,{useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import './profile.css'
import DatePicker from 'react-date-picker';
import {Button ,Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';

function DobUpdate(props) {
  const alert = useAlert()
  const [dob, setDob] = useState(() => {
    if (typeof props.dob === 'string') {
        return new Date(props.dob);
    }
    return null;
});
    const [formattedDob, setFormattedDob] = useState("");
    const [error,setError]=useState({
        dob:''
       });

    const handleDOB=(date)=>{

        setDob(date);
      
      let dateUpdated= date;
        if(date!==null){
      const offset = date.getTimezoneOffset();
      
      dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);
        }
      }

      const  validate = () => {
        //let input = email;
        let errors = {};
        let isValid = true;
  
        if (dob==="" ) {
          isValid = false;
          errors["dob"] = "Select a date of birth";
        }
    
        setError(currentstate=>({
          ...currentstate,...errors
        }))
    
        return isValid;
    }

    const handleContinue =async(e) => {
      e.preventDefault();
  
      if( validate() ){
        submitDob()
      }
    }

      const submitDob = (e) =>{
        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '') 
      
         const data = {
           dob : formattedDob
         }
      
         axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {
      
          "Authorization" : 'Token '+str  }})
        .then(res=>{
        
              
          if(res.data.status==="success"){
      
            alert.success("Updated successfully")
            setDob()          
            props.handle()
        
          }else{
           alert.error("Error in Data Submission. Please try again!")          
          }        
        })
        .catch(err=>{
          alert.error("Error in Data Submission. Please try again!")        
        })      
      }

    return (
        <div>
        <div style={{marginBottom:'180px'}} className="form-control dob item">
            <span >Date of Birth : </span>
                    <DatePicker
                          onChange={date=>{handleDOB(date)}}

                          maxDate = {new Date()}
                          value={dob}
                          
                     /> 
                        {error.dob ? <div className="error-validation-msg"> {error.dob}</div> : null}
                      
            </div>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update DOB</button>
        </div>
      </div>
    )
}

export default DobUpdate

import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import PhoneInput from "react-phone-input-2";
import { useAlert } from "react-alert";
import PatientNav from "../PatientNav";
import PatientFooter from "../PatientFooter";
import "./appointment.css"
import DoctorAppointments from "./DoctorAppointments";
import DoctorList from "./DoctorList";
function Appointment({ setShowNavbarAndFooter }) {
  useEffect(() => { setShowNavbarAndFooter(false); return () => setShowNavbarAndFooter(true); }, [setShowNavbarAndFooter]);
 
  const [DoctorsList, setDoctorList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [viewDoctors, setViewDoctors] = useState(false)
  const [page,setPage]=useState(1)
  const [searchTerm1, setSearchTerm1] = useState("")
  const [searchTerm2, setSearchTerm2] = useState("")
  const [searchTerm3, setSearchTerm3] = useState("")
  const [searchTerm4, setSearchTerm4] = useState("")
 
  
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
        ? JSON.parse(sessionStorage.getItem("logindatas"))
        : null
);

  const handleSearch = (page) => {

    axios
      .get(`${APIURL}/api/v1/patient/doctor-search/?doctor_name=${searchTerm1!==""?searchTerm1:""}&specialization=${searchTerm2!==""?searchTerm2:""}&hospital=${searchTerm3!==""?searchTerm3:""}&location=${searchTerm4!==""?searchTerm4:""}&page=${page}&per_page=16`, {

      })
      .then((res) => {

        if (res.data.status === "success") {
          setDoctorList(res.data.results)
          setTotalPages(res.data.total_pages)
          setTotalRecords(res.data.total_records)
          
        }
      })
      .catch((err) => {

      });

  }
  useEffect(() => {
    
      handleSearch(page)
    
   
  },[searchTerm1,searchTerm2,searchTerm3,searchTerm4])

 

 
  const handleSearchChange = (e) => {

    if (e.target.name === "search1") {
      setSearchTerm1(e.target.value);
    }
    else if (e.target.name === "search2") {
      setSearchTerm2(e.target.value);
    }
    else if (e.target.name === "search3") {
      setSearchTerm3(e.target.value);      
    }
    else if (e.target.name === "search4") {
      setSearchTerm4(e.target.value);     
    }
   


    // if (e.target.value === '') {
    //   setDisplay1(false);

    // }

  };
  return (
    <div>
      <PatientNav />
     
<div className="row "style={{ marginTop: "7%", padding:"1%" }}>
  <div className="col-8" >
  <div className='search-in-appointment ' >

      <div style={{ padding: "2% 2% 1% 2%" }} className="row"> 
      <span  className="find-doc">find a doctor</span>

          <div className="col-3" >
            <div className="search-webordering " style={{ height: "38px", marginTop: "-1px", border: "1px solid #948686", backgroundColor: "white" }}>
              <div style={{ display: "flex", marginTop: "0.8%", paddingRight: "1%" }}>

                <span>&nbsp;&nbsp;&nbsp;</span>
                <i style={{ color: "lightseagreen", marginTop: "4%" }} class="fa fa-user-md " aria-hidden="true"></i>
                <span>&nbsp;</span>
                <input
                  name="search1"
                  type="text"
                  className=""
                  placeholder="Search Doctor "
                  value={searchTerm1}
                  onChange={handleSearchChange}
                  style={{ width: "80%", paddingTop: "1%", marginTop: "2%", paddingLeft: "5px", border: "none", fontSize: "15px", }}
                />

              </div>
            </div >
          </div>
          <div className="col-3">
            <div className="search-webordering " style={{ height: "38px", marginTop: "-1px", border: "1px solid #948686", backgroundColor: "white" }}>
              <div style={{ display: "flex", marginTop: "0.8%", paddingRight: "1%" }}>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <i style={{ color: "lightseagreen", marginTop: "4%" }} class="fa fa fa-suitcase " aria-hidden="true"></i>
                <span>&nbsp;</span>
                <input
                  name="search2"
                  type="text"
                  className=""
                  placeholder="Select Specialty "
                  value={searchTerm2}
                  onChange={handleSearchChange}
                  style={{ width: "78%", paddingTop: "1%", marginTop: "2%", paddingLeft: "5px", border: "none", fontSize: "15px" }}
                />

              </div>
            </div >
          </div>
          <div className="col-3">
            <div className="search-webordering " style={{ height: "38px", marginTop: "-1px", border: "1px solid #948686", backgroundColor: "white" }}>
              <div style={{ display: "flex", marginTop: "0.8%", paddingRight: "1%" }}>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <i style={{ color: "lightseagreen", marginTop: "4%" }} class="fa fa-hospital-o " aria-hidden="true"></i>
                <span>&nbsp;</span>
                <input
                  name="search3"
                  type="text"
                  className=""
                  placeholder="Select Hospital "
                  value={searchTerm3}
                   onChange={handleSearchChange}
                  style={{ width: "80%", paddingTop: "1%", marginTop: "2%", paddingLeft: "5px", border: "none", fontSize: "15px" }}
                />

              </div>
            </div >
          </div>
          <div className="col-3">
            <div className="search-webordering " style={{ height: "38px", marginTop: "-1px", border: "1px solid #948686", backgroundColor: "white" }}>
              <div style={{ display: "flex", marginTop: "0.8%", paddingRight: "1%" }}>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <i style={{ color: "lightseagreen", marginTop: "4%" }} class="fa fa-map-marker " aria-hidden="true"></i>
                <span>&nbsp;</span>
                <input
                  name="search4"
                  type="text"
                  className=""
                  placeholder="Select Location "
                  value={searchTerm4}
                   onChange={handleSearchChange}
                  style={{ width: "80%", paddingTop: "1%", marginTop: "2%", paddingLeft: "5px", border: "none", fontSize: "15px" }}
                />

              </div>
            </div >
          </div>
        </div>
      
      
      </div>
      
  </div>
  {(login_datas) ? <div className="col-4">
  <DoctorAppointments/>

 </div>  : ""}
</div>
    
<div>

</div>
<DoctorList doctors={DoctorsList}/>

      <PatientFooter />
    </div>
  )
}
export default Appointment

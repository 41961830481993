import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
function ReassignService({ id, onSuccess1,item_service, subId, refresh1 }) {
    const [spId, setSPId] = useState()
    const [spDatas, setSpDatas] = useState([])
    const [serviceProviders, setServiceProviders] = useState("")
    const [itemService, setItemService] = useState()
    const [error, setError] = useState("")
    const [search, setSearch] = useState('');

    const [successShow, setSuccessShow] = useState(false);
    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );

        axios.get(`${APIURL}/api/v1/staff/service-hospital-list/?sub_service=${id}`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    const datas = res.data.service_providers
                    getSpList(datas)
                 

                    //setConsent(datas.consent_level)
                }
                else {

                }
            })
            .catch((err) => {

            })

    }, [])

    const getSpList = (datas) => {
        let SParr = datas.map(item => {
            return { label: item.hospital_name, value: item.hospital_id, item_service: item.hospital_itemservice_id }
        });
        setServiceProviders(SParr);
    }


    const handleChange = (e) => {
        e.preventDefault();
        const { value, item_service } = JSON.parse(e.target.value);
        setSPId(value);
        setItemService(item_service)
      //  setSearchTerm(e.target.value)

    };
   
    //   {!!serviceProviders&& serviceProviders.filter(({label}) => {
    //     if (searchTerm === '') {
    //       return label
    //     } else if (label.toLowerCase().includes(searchTerm.toLowerCase())) {
    //       return label}
    //     })}
    const addService = (e) => {

        e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {
            hospital: spId,
            item_service: itemService

        }

        axios
            .put(`${APIURL}/api/v1/staff/reassign-sr/${subId}/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    setSuccessShow(true); 
                   
                    //setRefresh(true)
                    //setCompleteShow(true)
                    //setSuccessShow(false)
                } else {
                    setError(res.data.message)
                }
            })
            .catch((err) => {
                setError("Please fill all mandatory fields!")
            });
        // setSuccessShow(false)
    }

    const SubmitPopUp1 = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="" >Successfully Reassigned Service Provider</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary btn-col" onClick={props.onHide}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };



    return (<>
        <div className="appForm" >
            <div className="">

                <div className="title-of-tasks" style={{}}><h5>Reassign Service Provider</h5></div><br />
                <form className="formFields" onSubmit={addService}>
                    <div className="formField">
                        <h6 style={{ textTransform:"capitalize"}}><b>{item_service}</b></h6>

                    </div>
                    <div className="formField">


                        {/* {serviceProviders && <select className="formFieldInput"
                        

                            defaultValue="null"
                            value={searchTerm}
                            onChange={handleChange}>
                            <option >{"Service Providers"}</option>
                            {serviceProviders.map(item => (
                                <><option value={JSON.stringify(item)}>
                                    {item.label}
                                </option></>
                            ))}

                        </select>


                        } */}

<Typeahead
          
          placeholder="Search a Service Provider"
          
          options={!!serviceProviders&& serviceProviders.map(option => {
            return { label: option["label"], value: option["value"], item_service: option["item_service"] }

          })}
         
          onChange={(data) => {
            if(data.length){
                setSPId(data[0].value);
                setItemService(data[0].item_service)
            
            setSearch(data[0].label);}
          }}

        />


                    </div>

                    <div className="form-heading title-of-tasks" style={{}}>
                        <button className="btn formFieldButton">Save</button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}




                    </div>


                </form>

            </div>


        </div>
        {successShow ? (
            <SubmitPopUp1
                show={successShow}
                onHide={() => {
                    setSuccessShow(false);
                    onSuccess1()
                    refresh1();
                }}
            />
        ) : null}</>
    )
}
export default ReassignService
import React, { useState, useEffect } from 'react';
import ReactTable from "react-table-v6";
 import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Modal } from 'react-bootstrap';
import AddNewService from './AddNewService';
import ListGroup from 'react-bootstrap/ListGroup'
import LoadingOverlay from 'react-loading-overlay';
const SubServiceTable = ({ id,servicetype }) => {
    const [loading,setLoading]=useState(false);
    const [addServiceShow, setAddServiceShow] = useState(false)
    const [subServiceList, setSubServiceList] = useState([])
    const [subid, setSubId]=useState(0)
    const [serviceName,SetServiceName]=useState(" ")
    const [category,setCategory]=useState(" ")
    const [unified,setUnified]=useState(" ")
    let serviceType=servicetype

    useEffect(() => {
        setLoading(true)
        const tokenString = sessionStorage.getItem("usertoken");


        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios.get(`${APIURL}/api/v1/service-provider/sub-services/?main_service=${id}`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    const datas = res.data.sub_services

                    setSubServiceList(datas)
                    setLoading(false)
                }
                else {
                   setLoading(false)
                }
            })
            .catch((err) => {
               setLoading(false)
            })


    }, [])

    const AddServicePopUp = (props) => {
        return (
            <Modal 
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            >  <Modal.Header closeButton>
                
                    <Modal.Title id="contained-modal-title-vcenter">
<h5 className="title-of-tasks">{serviceName}</h5>

                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <AddNewService mainid={id} id={subid} servicename={serviceName} servicetype={serviceType}  cateogory ={category} unified_code={unified} />


                </Modal.Body>

            </Modal>
        )
    }

    // const columns = [
       
      
    //     {
            
    //         accessor: 'services',
    //         minWidth: 800
    //     },
    //     {
           
    //         accessor: 'addservice',
    //         minWidth: 120
    //     },


    // ]
      const handleAddService = (subid,servicename,unified_code,service_category) => {
        
       setSubId(subid);
       SetServiceName(servicename);
       setCategory(service_category);
       setUnified(unified_code)
       setAddServiceShow(true);
     
    };
    
    // const getServiceCol = (subServiceList) => {
    //     return subServiceList.map(item => {
    //         return {
              
    //             id: item.id,
    //             services: item.sub_service,
    //             addservice:<div><i className="fa fa-arrow-right" style={{ float: "right",  color: "#6788BC  " }}aria-hidden="true" onClick={() => handleAddService(item.id,item.sub_service) }></i></div>
               

    //         }
    //     })
    // }

    // const data = getServiceCol(subServiceList);

    return (<>
        <LoadingOverlay
    active={loading}
    spinner
    styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: 'rgba(0, 0, 255, 0.5)'
            
          }
        }),
        overlay: {},
      }}
 
>
        <div style={{ width: '100%' }}>

           
               
                    
                    {subServiceList.map((item) => {
					return (
						<ListGroup className='border-primary'  variant="flush">
							<ListGroup.Item  ><h4 style={{color:"#6c757d "}}>{item.sub_service}<span>&nbsp;&nbsp;&nbsp;</span>{item.service_category==="master_consumable"&&<span className='attrstyle'>{item.consumable_detail.color!==""&&item.consumable_detail.color!==null&&item.consumable_detail.color!=="null"?<><i style={{color:item.consumable_detail.color, border:"1px solid black",borderRadius:"50%"}} class="fa fa-circle" aria-hidden="true"></i><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.consumable_detail.size!==""&&item.consumable_detail.size!==null&&item.consumable_detail.size!=="null"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.consumable_detail.size}</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.consumable_detail.weight!==""&&item.weight!==null&&item.consumable_detail.weight!=="null"&&item.consumable_detail.weight!=="0"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.consumable_detail.weight}Kg</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {item.consumable_detail.volume!==""&&item.volume!==null&&item.consumable_detail.volume!=="null"&&item.consumable_detail.volume!=="0"?<><span style={{border:"1px solid black"}}  aria-hidden="true">{item.consumable_detail.volume}L</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}</span>}<span style={{color:"black"}}> {item.unified_code_group!=null ? item.unified_code_group :null}</span><i className="fa fa-plus" style={{ float: "right",  color: "#6788BC  ", cursor:"pointer"}}aria-hidden="true" onClick={() => handleAddService(item.id,item.sub_service,item.unified_code,item.service_category) }></i></h4>
							</ListGroup.Item>

						</ListGroup>
					)
				})}


                        {/* <ReactTable responsive
                         style={{
                        color:"#259BE9",
                        fontSize:"20px"}}
                            minRows={1}
                            data={data}
                            columns={columns}
                            showPaginationBottom={false}
                        /> */}
                       
               
        

            

        </div>
         {
            addServiceShow ?
                <AddServicePopUp
                    show={addServiceShow}
                    onHide={() => setAddServiceShow(false)}

                /> : null
        }
       </LoadingOverlay>
       </>
    );
}


export default SubServiceTable;
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Button, Modal } from 'react-bootstrap';
import Resizer from "react-image-file-resizer";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./config.css"
import LoadingOverlay from "react-loading-overlay";
import { duration } from 'moment';
import Message from '../../common/Message';
import HelpLine from './HelpLine';
function Configuration() {
    const navigate = useNavigate();
    const IMAGE_COUNT = 3;
    const [validShow, setValidShow] = useState(false)
    const [contentShow, setContentShow] = useState(false)
    const [uploadShow, setUploadShow] = useState(false)
    const [sizeShow, setSizeShow] = useState(false)
    const [infoDatas, setInfoDatas] = useState([])
    const [images, setSavedImages] = useState([{ images: null }, { images: null }, { images: null }]);
    const [updatePage, setUpdatePage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState()
    const [updatedimg, setUpdatedimg] = useState(false)
    const [imgURL, setimgUrl] = useState()
    const [existingImage, setExistingImage] = useState()
    const [index, setIndex] = useState()
    const [deleteshow, setDeleteShow] = useState(false)
    const [flag,setFlag]=useState(false)
    const [countImg,setCount]=useState(0)
    
    useEffect(() => {


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios.get(`${APIURL}/api/v1/staff/banner-upload/`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.status === 200) {
                    //setBanerData(res.data)
                    if (res.data.length) {
                        setCount(res.data.length)
                        let savedData = [...res.data];
                        if (savedData.length < IMAGE_COUNT) {
                            for (let i = savedData.length; i < IMAGE_COUNT; i++) {
                                savedData.push({ images: null });
                            }
                        }
                        setSavedImages(savedData);
                      
                    }
                    else {

                        setSavedImages([{ images: null }, { images: null }, { images: null }]);
                    }

                }
            })
    }, [updatePage]);

    useEffect(() => {


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios.get(`${APIURL}/api/v1/staff/helpline-numbers/`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data !== null) {
                        setInfoDatas(res.data)

                    }

                }
            })

    }, [flag])

    const SizePopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h4 id="confirm-reject">You have Exceeded the size limit 10MB</h4>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={props.onHide}>Ok</Button>

                </Modal.Footer>
            </Modal>
        )
    }

    const LoadPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h4 id="confirm-reject">Select an Image</h4>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={props.onHide}>Ok</Button>

                </Modal.Footer>
            </Modal>
        )
    }
    const DeleteConfPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h4> Please Confirm to Remove Image</h4>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { handleRemove(); setDeleteShow(false) }}>Ok</Button>
                    <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

                </Modal.Footer>
            </Modal>
        )
    }
    const ValidPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h4 id="confirm-reject">Select a valid Image</h4>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={props.onHide}>Ok</Button>

                </Modal.Footer>
            </Modal>
        )
    }

    const ContentPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h4 id="confirm-reject">Invalid Image Content</h4>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={navigate("/configuration")}>Ok</Button>

                </Modal.Footer>
            </Modal>
        )
    }

    const resizeFile = (file) =>

        new Promise((resolve) => {
            const fileExt = file.name.split(".")[1];
            Resizer.imageFileResizer(
                file,
                200,
                100,
                fileExt,
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });
    const editImage = (id) => {
        const fileInput = document.getElementById(id)
        fileInput.click();
    }



    function dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });

    }

    const uploadImage = async (e, index) => {

        const file = e.target.files[0];
        if (!file) {
            setUploadShow(!uploadShow)
            return false
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setValidShow(!validShow)
            return false;
        }
        const image = await resizeFile(file);
        images[index].images = image;
        setSavedImages([...images])
    }


    const handleBanerChange = (e, index) => {
        const val = e.target.value;
        const key = e.target.name;
        if (key === "baner_title") {
            images[index].title = val;
        }
        if (key === "baner_description") {
            if (val === null) {
                images[index].description = null;
            }
            images[index].description = val;
        }
        if (key === "baner_duration") {
            images[index].duration = val;
        }
        setSavedImages([...images])
    }


    /**
     * 
     * @param {*} imgURL can be blob or image url, image url for saved ones, blob for new
     * @param {*} existingImage already saved one
     * @param {*} index index of image
     * @param {*} imageId image id of element
     * @returns 
     */
    const submitHandle = async (imgURL, existingImage, index) => {
        if (!imgURL || !images[index].title) {
            setError("Please Provide a Title")
            return;
        }
        setLoading(true);
        const isNewImage = !existingImage?.id;
        const isUpdatedImage = !imgURL.startsWith("http");
        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        let fd = new FormData();
        let fileExt = "";
        if (isUpdatedImage) {
            fileExt = imgURL.split(";")[0].split("/")[1];
            fd.append('images', dataURItoBlob(imgURL), "banner" + index + "." + fileExt);
        }


        fd.append('title', images[index].title);
        fd.append('description', images[index].description || "");
        fd.append('duration', images[index].duration || "");

        if (!isNewImage) {
            axios.put(`${APIURL}/api/v1/staff/banner-detail/${existingImage.id}/`, fd, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": 'Token ' + str
                }
            })
        } else {
            axios.post(`${APIURL}/api/v1/staff/banner-upload/`, fd, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": 'Token ' + str
                }
            })
        }
        setUpdatedimg(true)
        window.scrollTo(0, 0);
        setTimeout(() => {

            setUpdatedimg(false)
        }, 4000)
        setTimeout(() => { setUpdatePage(!updatePage); setLoading(false); }, 1000);
    }
    const handleRemovePopup = (imgURL, existingImage, newIndex) => {
        setimgUrl(imgURL);
        setIndex(newIndex)
        setExistingImage(existingImage)
        setDeleteShow(true)

    }


    const handleRemove = async () => {
        if (!imgURL) {
            return;
        }
        const isSavedImage = !!existingImage?.id;
        const tokenString = sessionStorage.getItem('usertoken');

        if (isSavedImage) {
            setLoading(true);
            let str = tokenString.replace(/["]+/g, '')
            await axios.delete(`${APIURL}/api/v1/staff/banner-detail/${existingImage.id}/`, {
                headers: {
                    "Authorization": 'Token ' + str
                }
            });
            setCount(0)
            setTimeout(() => { setUpdatePage(!updatePage); setLoading(false); setError("") }, 1000);
        }
        else {
            images[index] = { images: null, title: null, description: null, duration: null };
            setSavedImages([...images])
        }
    }
    const onSuccess = () => {
     setFlag(!flag)
      };
    
    return (<>
        <LoadingOverlay
            active={loading}
            spinner
        >
            <div className='appForm' style={{ overflow: "auto" }}>
                <h2 className="title-of-page">Configurations </h2>
                <div className=" appForm">
                    <div className="form-group" style={{ display: "flex", alignContent: "center" }}>
                        <div className='col-lg-3'>
                            <h5 className="form-group "><b>Customer Service Contact  </b></h5>
                        </div>
                        <div className='col-lg-9' style={{ display: "block" }}>
                            {infoDatas && infoDatas.length===1 && <HelpLine contact={infoDatas[0].mobile_number} email={infoDatas[0].email} id={infoDatas[0].id} index="1" onSuccess={onSuccess} />}
                            {infoDatas && infoDatas.length===2 &&<><HelpLine contact={infoDatas[0].mobile_number} email={infoDatas[0].email} id={infoDatas[0].id} index="1" onSuccess={onSuccess}/><HelpLine contact={infoDatas[1].mobile_number} email={infoDatas[1].email} id={infoDatas[1].id} index="2" onSuccess={onSuccess} /></>}
                            {(!infoDatas || infoDatas.length === 0) && <><HelpLine index="1" onSuccess={onSuccess}/> <HelpLine index="2" onSuccess={onSuccess}/></>}
                            {(!infoDatas || infoDatas.length === 1) && <HelpLine index="2" onSuccess={onSuccess}/>}
                        </div>

                    </div>

                </div><br />


                <div className='appForm1' style={{ overflow: "auto" }}>

                    <div className='col-lg-2'>
                        <h5 className="form-group "><b>Advertisement  </b></h5>
                    </div>
                    <div className='col-lg-9' style={{ float: "right", marginRight: "7%" }}>
                        {updatedimg && <div className='successmsg'><Message variant="success" children=" updated successfully" /></div>}
                        {images.length === IMAGE_COUNT && [...Array(IMAGE_COUNT).keys()].map(index => {
                            const image = images[index];
                            const imageId = `imageInput${index}`;
                            const imgURL = image?.images;
                            return (
                                <div className='banner' style={{ display: "flex", paddingLeft: "3%" }}>
                                    <br /><br />
                                    <div className='col-lg-5'>
                                        <div className="form-group " style={{ alignContent: "center" }}>
                                            <div><br />
                                                <label>Image{index + 1} : </label><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                                {imgURL && <img src={imgURL} alt='Advertisement' />}
                                                <input type="file" id={imageId} onChange={(e) => { uploadImage(e, index) }} hidden="hidden" />
                                                <i style={{ cursor: 'pointer' }} onClick={() => { editImage(imageId) }} className="fas fa-camera"></i>
                                            </div>

                                        </div>

                                        <div className=''>
                                            {imgURL && <i onClick={() => submitHandle(imgURL, image, index)} class={index+1<=countImg&&countImg!==0  ? "fas fa-pencil-alt" : "fa fa-floppy-o"} style={{
                                                paddingRight: "25px",
                                                color: "#5a9569",
                                                cursor: "pointer",
                                            }} >{index+1<=countImg&&countImg!==0 ?"Update" : "Save"}</i>}
                                            {imgURL && <i style={{ marginLeft: '10px', color: "red", cursor: "pointer" }} onClick={() => handleRemovePopup(imgURL, image, index)} class="fas fa-trash-alt" >Remove</i>}

                                        </div>
                                    </div>
                                    <div className='col-lg-7 ' style={{ marginLeft: "5%" }} >
                                        <br />
                                        <div className="form-group" style={{ alignContent: "center" }}>
                                            <label className='col-lg-5'>*Title : </label><span>&nbsp;&nbsp;</span>
                                            <input
                                                className='confinput'
                                                type="text"
                                                name="baner_title"
                                                value={images[index]?.title || ""}
                                                onChange={(e) => handleBanerChange(e, index)}
                                            ></input>
                                        </div>
                                        {error && imgURL&&!images[index].title ? (
                                            <div className="error-validation-msg">
                                                {error}
                                            </div>
                                        ) : null}
                                        <div className="form-group" style={{ alignContent: "center" }}>
                                            <label className='col-lg-5'>Duration <small>(in months)</small>: </label><span>&nbsp;&nbsp;</span>
                                            <input
                                                className='confinput'
                                                type="number"
                                                min={0}
                                                name="baner_duration"
                                                value={images[index]?.duration || ""}
                                                onChange={(e) => handleBanerChange(e, index)}
                                            ></input>
                                        </div>
                                        <div className="form-group" style={{ alignContent: "center" }}>
                                            <label className='col-lg-5'>Description : </label><span>&nbsp;&nbsp;</span>
                                            <textarea
                                                className='confinput'
                                                style={{ height: "60px", border: "2px solid #2828284d" }}
                                                type="text"
                                                name="baner_description"
                                                value={images[index]?.description || ""}
                                                onChange={(e) => handleBanerChange(e, index)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <br /><br /><br /><br />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </LoadingOverlay>
        {
            sizeShow ?
                <SizePopUp
                    show={sizeShow}
                    onHide={() => {
                        setSizeShow(false)
                    }} /> : null
        }

        {
            uploadShow ?
                <LoadPopUp
                    show={uploadShow}
                    onHide={() => {
                        setUploadShow(false)
                    }} /> : null
        }

        {
            validShow ?
                <ValidPopUp
                    show={validShow}
                    onHide={() => {
                        setValidShow(false)
                    }} /> : null
        }

        {
            contentShow ?
                <ContentPopUp
                    show={contentShow}
                    onHide={() => {
                        setContentShow(false)
                    }} /> : null
        }
        {
            deleteshow ?
                <DeleteConfPopUp
                    show={deleteshow}
                    onHide={() => {
                        setDeleteShow(false);
                        setUpdatePage(true)
                        navigate("/configuration")
                    }} /> : null
        }
    </>
    )
}

export default Configuration


import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import isAfter from 'date-fns/isAfter';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
//import { useHistory } from 'react-router-dom'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import Reports from './Reports';
import { Badge } from 'react-bootstrap';

import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
// import DateRangePicker from "react-bootstrap-daterangepicker";
//import "./reports.css";
//import HospitalBdoNavbarReports from '../HospBdoNavbar/HospBdoNavbarReports';
import Nav from 'react-bootstrap/Nav';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
function RemoteRequests() {
  const [timePeriod, setTimePeriod] = useState("month")
  const [load, setLoad] = useState('')
  const [doctorData, setDoctorData] = useState([])
  const [serviceData, setServiceData] = useState([])
  const [category, setCategory] = useState("all")
  //const history = useHistory();
  const [fromDate, setFromDate] = useState(moment((new Date(new Date().getFullYear(), new Date().getMonth(), 1))).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment((new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))).format('YYYY-MM-DD'));
  const [custom, setCustom] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [show, setShow] = useState(false);
  useEffect(() => {

    defaultMonth();


  }, []);
  useEffect(() => {
    if (fromDate) {
      apiCallFilter(1, perPage)
    }

  }, [fromDate, toDate]);
  const exportRegisterReport = () => {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Patient Remote Requests";
    const headers = [["Patient Name", "Order Type", "Date", "Total Amount", "Paid Status", "Status"]];

    const data = doctorData.map(elt => [elt.patient, elt.order_type, moment(new Date(elt.created_at)).format('YYYY-MM-DD'), elt.total_pay_amount, elt.paid_status === "paid" ? "Paid" : "Not Paid", elt.request_status]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  };


  const defaultMonth = () => {

    var date = new Date();
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    var FormatedfirstDayOfMonth = moment((firstDayOfMonth)).format('YYYY-MM-DD');
    var FormatedlastDayOfMonth = moment((lastDayOfMonth)).format('YYYY-MM-DD');
    setFromDate(FormatedfirstDayOfMonth)
    setToDate(FormatedlastDayOfMonth)
    // apiCallFilter(1, 10);
  }

  useEffect(() => {
    if (category) {
      apiCallFilter(1, perPage)
    }

  }, [category]);


  const handleChangeRadio = (val) => {

    doctorData.splice(0, doctorData.length + 1)
    setCategory(val);


  }
  const handleChangeValue = (val) => {
    setTimePeriod(val)
    doctorData.splice(0, doctorData.length + 1)
    if (val == 'day') {
      setCustom(false)
      var today = new Date();
      var FormatedCurrentDate = moment((today)).format('YYYY-MM-DD');
      setFromDate(FormatedCurrentDate)
      setToDate(FormatedCurrentDate)

      // apiCallFilter(1, 10)

    }
    if (val == 'week') {
      setCustom(false)
      var startOfWeek = moment().startOf('week').toDate();
      var endOfWeek = moment().endOf('week').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setFromDate(FormatedFirstday)
      setToDate(Formatedlastday)

      // apiCallFilter(1, 10);

    }
    if (val == 'month') {
      setCustom(false)
      var date = new Date();
      var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      var FormatedfirstDayOfMonth = moment((firstDayOfMonth)).format('YYYY-MM-DD');
      var FormatedlastDayOfMonth = moment((lastDayOfMonth)).format('YYYY-MM-DD');
      setFromDate(FormatedfirstDayOfMonth)
      setToDate(FormatedlastDayOfMonth)

      // apiCallFilter(1, 10);


    }
    if (val == 'year') {
      setCustom(false)
      var currentDates = new Date();
      var theFirst = new Date(currentDates.getFullYear(), 0, 1);
      var theLast = new Date(currentDates.getFullYear(), 11, 31);
      var FormatedfirstDayOfYear = moment((theFirst)).format('YYYY-MM-DD');

      var FormatedlastDayOfYear = moment((theLast)).format('YYYY-MM-DD');
      setFromDate(FormatedfirstDayOfYear)
      setToDate(FormatedlastDayOfYear)
      // apiCallFilter(1, 10);
    }
  }

  const apiCallFilter = (page, per_page) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const a = fromDate;
    const b = toDate;
    let type = ""
    if (category === "all") {
      type = ""
    } else {
      type = category
    }
    axios.get(
      `${APIURL}/api/v1/service-provider/hospital-remote-requests/?start_date=${a}&end_date=${b}&type=${type}&page=${page}&per_page=${per_page}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          setLoad('')
          setHasNext(res.data.has_next)
          let datalist = res.data.orders;
          setDoctorData([...doctorData, ...datalist]);


          setTotalRows(res.data.total_records)
        }
        else {
          setLoad('Error in Fetching Data')
        }

      })

      .catch(err => {
        setLoad('Error in Fetching Data2')
      })

  }
  const handleEvent = (event, picker) => {
  setCustom(true)
    doctorData.splice(0, doctorData.length + 1)
    const a = moment((event[0])).format('YYYY-MM-DD');
    let b = moment((event[1])).format('YYYY-MM-DD');

    setFromDate(a);
    setToDate(b);

  }


  const renderTable = (cellInfo) => {
    return (
      <div style={{ color: "black",textTransform:"capitalize" }}>{doctorData[cellInfo.index][cellInfo.column.id]==="master_medicine"?"Medicine"
      :doctorData[cellInfo.index][cellInfo.column.id]==="master_consumable"?"Consumable"
      :doctorData[cellInfo.index][cellInfo.column.id]==="master_procedure"?"Procedure"
      :doctorData[cellInfo.index][cellInfo.column.id]==="master_scan"?"Scan"
      :doctorData[cellInfo.index][cellInfo.column.id]==="master_labtest"?"Lab Test"
      :doctorData[cellInfo.index][cellInfo.column.id]==="misc"?"Miscellaneous"
      :doctorData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }
  const renderPaymentStatus = (cellInfo) => {
    return (
      <div style={{ color: doctorData[cellInfo.index][cellInfo.column.id] === "not_paid" ? "red" : "green" }}>{doctorData[cellInfo.index][cellInfo.column.id] === "not_paid" ? "Not Paid" : "Paid"}</div>
    )
  }

  const renderDate = (cellInfo) => {
    let dateString = doctorData[cellInfo.index][cellInfo.column.id]
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div style={{ color: "black" }}>
        {updatedDate.slice(4, 16)}

      </div>
    )
  }

  const columns = [
    {
      Header: "Patient Name",
      accessor: "patient",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },

    },
    {
      Header: "Order ID",
      accessor: "order_number",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },


    },
    {
      Header: "Order Type",
      accessor: "order_type",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },


    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: renderDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,


    },
    {
      Header: "Total Amount",
      accessor: "total_pay_amount",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,


    },
    {
      Header: "Paid Status",
      accessor: "paid_status",
      Cell: renderPaymentStatus,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,


    },
    {
      Header: "Status",
      accessor: "request_status",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },


    },
  ]
  const handlePageChange = page => {
    if (hasNext) {
      apiCallFilter(page + 1, perPage);
    }
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage)
    doctorData.splice(0, doctorData.length + 1)
    apiCallFilter(1, newPerPage);

  }
  useEffect(() => {
    apiCallFilter(page, perPage);
  }, [perPage]);

  const display = <ReactTable
    columns={columns}
    data={doctorData}
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    defaultPageSize={perPage - 1}
    className='calllog-table'
    resizable
    pagination
    paginationServer
    onPageChange={(pageIndex) => { handlePageChange(pageIndex); setPage(pageIndex + 1) }}
    paginationTotalRows={totalRows}
    onPageSizeChange={handlePerRowsChange}
  ></ReactTable>



  const CsvRegisterheaders = [
    { label: "Doctor Name", key: "doctor" },
    { label: "Procedure Name", key: "procedure" },
    { label: "Total Amount", key: "amount" },
    { label: "Procedure Status", key: "procedure_status" },
    { label: "Date", key: "date" }


  ];


  return (
    <>

      <div className="patient-report">
        <Nav class="row">
          <span class="col-10">
            <h4 className="title-of-reportpage"> Remote Requests</h4>
          </span>
          <span style={{ display: "flex", marginTop: "1%" }} class="col-2">

            <button onClick={() => setShow(!show)} className='btnexport'><i class="fa fa-upload"> &nbsp;&nbsp; Export</i></button>


          </span>

        </Nav>
        {show ? <div className='exportDisplay' >
          <button style={{ border: "none", backgroundColor: " transparent", color: "var(--bs-link-color)" }} onClick={exportRegisterReport}><i class="fa fa-file-pdf-o"> &nbsp;&nbsp; PDF</i></button>
          <CSVLink
            style={{ marginLeft: "3.5%" }}
            data={doctorData}
            headers={CsvRegisterheaders}

          ><i class="fa fa-file-excel-o">
              &nbsp;&nbsp;&nbsp;&nbsp;CSV</i>
          </CSVLink>
        </div> : ""}
        <div className="report-display">
          <span class="col-9 togglegroup" style={{ marginLeft: "2%", }}>
            <ToggleButtonGroup type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
              <ToggleButton id="tbg-radio-1" value={'all'} className="btn btn-light toggle-btns  custombtn custombtnreg">
                All
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-2" value={'master_labtest'} className="btn btn-light toggle-btns  custombtn custombtnreg">
                Lab Test
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-3" value={'master_scan'} className="btn btn-light toggle-btns  custombtn custombtnreg">
                Scan
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-5" value={'master_procedure'} className="btn btn-light toggle-btns  custombtn custombtnreg">
                Procedure
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-4" value={'master_medicine'} className="btn btn-light toggle-btns  custombtn custombtnreg">
                Medicine
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-6" value={'misc'} className="btn btn-light toggle-btns  custombtn custombtnreg">
                Miscellaneous
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-7" value={'master_consumable'} className="btn btn-light toggle-btns  custombtn custombtnreg">
                Consumable
              </ToggleButton>
            </ToggleButtonGroup> </span>


        </div>

        <Nav class="row" style={{ marginTop: "1%" }}>
          <span class="col-5"> </span>
          <span class="col-6 togglegroup" style={{ marginBottom: "1%", marginLeft: "" }}>
            <ToggleButtonGroup style={{ marginTop: "1%", fontSize: "small", }} type="radio" name="options" defaultValue={timePeriod} onChange={handleChangeValue}>
              
            <DateRangePicker
              appearance="default"
              style={{width: "220px", }}
              placeholder={"Select Date Range"}
             
              //  alwaysShowCalendars={true}
              onChange={handleEvent}
                disabledDate={(date) =>
                  isAfter(date, new Date())}

              >

                <ToggleButton  id="tbg-radio-4" value={'custom'} className="btn btn-light toggle-btns custombtn">

                </ToggleButton>

              </DateRangePicker>&nbsp;&nbsp;&nbsp;
              <ToggleButton id="tbg-radio-7" value={'day'} className="btn btn-light toggle-btns custombtn">
                Day
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton id="tbg-radio-1" value={'week'} className="btn btn-light toggle-btns custombtn ">
                Week
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton id="tbg-radio-2" value={'month'} className="btn btn-light toggle-btns custombtn">
                Month
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton id="tbg-radio-3" value={'year'} className="btn btn-light toggle-btns custombtn ">
                Year
              </ToggleButton>&nbsp;&nbsp;&nbsp;
             

            </ToggleButtonGroup> </span>
        </Nav>

        <div className="service-table-holder" style={{ marginRight: "2%" }}> {load === '' ? display
          : load === 'Error in Fetching Data' ? <h6 style={{ color: "red" }}>{load}</h6> : <h6 style={{ color: "grey" }}>{load}</h6>}
        </div>
      </div>
      <br />

    </>

  )
}

export default RemoteRequests

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BdoCard from "../BDO/BdoCard";
import ManagerCard from "../Manager/ManagerCard";
import "./bdolist.css";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { APIURL,TZ } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import LoadingOverlay from 'react-loading-overlay';
import BackButton from "../common/BackButton";
function BdoList() {
  const [loading,setLoading]=useState(false);
  const [bdoList, setBdoList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
bdolist();
  }, []);

  const bdolist=()=>
  {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/bdo/?${TZ}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          
          const datas = res.data.bdos;

          setFreeCount(res.data.free_user_count);
          setActiveCount(res.data.active_free_users);
          setLoading(false)
         
          setBdoList(datas.reverse());
        } else {
          setErrorMsg("error");
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }

  const refreshHandle = () => {
    setRefresh(!refresh);
    bdolist();
  };
 

  return (
   
   
    <div className="drlist-page">
    


 
     
   

      {bdoList.length !== 0 ? (
        <div>
          <div className=""><br/>
          <ManagerCard
                     
                     bdoList={bdoList}
                     user="bdo"
                     handle={refreshHandle}
                   />
          
          </div>

        
        </div>
      ) : (
        <h5 style={{ color: "red", margin: "20px" }}>BDO List is empty </h5>
      )}

      {errorMsg === "error" ? (
        <h5 style={{ color: "red" }}>
          Error in data fetching.Please try again!
        </h5>
      ) : null}
    
   
    </div>
  );
}

export default BdoList;

import React, {useState, useRef, useEffect} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function StageAdd(props) {
  const alert = useAlert();
    const [stage, setStage]=useState(props.setEditStage !== " " ? props.setEditStage : " ")
    const saveButtonRef = useRef(null)
    const inputRef = useRef(null)
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {  
        e.preventDefault()
        saveButtonRef.current.click();
      }
    };
    useEffect(() => {
      
      const handleKey = (e) => {
        if (e.altKey && e.key === 's') {
          e.preventDefault()
          saveButtonRef.current.click() 
        }     
      }
      document.addEventListener("keydown", handleKey);
      
      return () => {
        document.removeEventListener("keydown", handleKey);
      }
      
    }, []);

    useEffect(() => {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.selectionEnd = inputRef.current.value.length;
  }, []);

    const handleChange = (e)=> {
        setStage(e.target.value);
    }

    const handleSubmit = () => {


            const id =props.id
            const duration = props.duration.toString();
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               "stage": stage,
               "duration": duration,
           }
     
     
       
         axios.put(`${APIURL}/api/v1/doctor/primary-disease-details/${id}/`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.status==200){
            alert.success("Stage Updated Successfully")
            props.submitstage()
          
       }
       else{
            alert.error(res.data.message)
        
       }
     
         })
       

       

        
    }

   

  
    

    return (
        <div className='flex-col'>
          <label>Edit Stage:</label>
           <input ref={inputRef} onKeyDown={handleKeyDown} style={{width:'50%'}} type="text" min="0" placeholder="Add Stage" value={stage} onChange={handleChange} className="form-control"/><br/>
           <button ref={saveButtonRef} onClick={handleSubmit} className='btn btn-primary btn-col'>Add</button> 


         


        </div>
    )
}

export default StageAdd

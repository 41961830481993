
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { NavDropdown } from "react-bootstrap";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton, Badge,Button } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import DatePicker from "./Datepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import "../AdmissionReport/reports.css";
import isAfter from 'date-fns/isAfter';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { Modal } from "react-bootstrap";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { MdDownloadForOffline } from "react-icons/md";
import peaceImage from '../../../assets/images/icons/peacevillage.png'; 
import Nav from 'react-bootstrap/Nav';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GrClose } from "react-icons/gr";

function MedicinesReport() {
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [load, setLoad] = useState('')
  const [registerData, setRegisterData] = useState([])
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [refresh,setRefresh]=useState(false);
  const [selectedValue, setSelectedValue] = useState(''); // You can set the initial selected value here
  const [currentDay, setCurrentDay] = useState(moment().format('YYYY-MM-DD'));
  const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
  const [monthsYear, setMonthsYear] = useState(moment().format('YYYY'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  const [selectedDownloads, setSelectedDownloads] = useState([]);
  const [dateRange,setDateRange] = useState(null);
  
    useEffect(() => {
    
   
    setRegisterData([])
    apiCallFilter(1,10);
   setFromDate("")
   setToDate("")
   setSelectedValue("")


   
  }, [refresh]);

  const downloadOptions = [ "PDF", "CSV" ];

  const toggleDownloadDropdown = () => {
    setIsDownloadDropdownOpen(!isDownloadDropdownOpen);
  };

  const handleDownloadChange = (download) => {
    setSelectedDownloads((prevDownloads) => {
      if (prevDownloads.includes(download)) {
        // Deselect the checkbox
        return prevDownloads.filter(item => item !== download);
      } else {
        return [...prevDownloads, download];
      }
    });
  };

  const capitalize = (str) => {
    return str&&str!==""&&str.replace(/\b\w/g, c => c.toUpperCase());
};

  const handleDownloads = () => {

    // Perform actions based on selected download options
    selectedDownloads.forEach((selectedOption) => {
      if (selectedOption === 'PDF') {

        //download pdf
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const marginLeft1 =450;
        const marginBottom =40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
      // Add the image to the PDF
     
    
        doc.setFontSize(15);
    
        const title = "Meidicine  Report";
        const headers = [["Medicine Name", "Total Dispense Count"]];
        // var dateStringWithTime = moment(now).format('YYYY-MM-DD HH:MM:SS');
    
       
        const data = registerData.map((elt) => {
          
            return [
              capitalize(elt.med_name),
              elt.total_dispensing_count,            
            ];
          });
          
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Medicine_Report.pdf")

      } else if (selectedOption === 'CSV') {

        const filteredData = registerData.map(elt => ({
          med_name: capitalize(elt.med_name),
          toatal_dispensing_count: elt.total_dispensing_count
        }));
        
        // Generate CSV content
        const csvContent =
          CsvRegisterheaders.map((h) => h.label).join(',') +
          '\n' +
          filteredData.map((row) => Object.values(row).join(',')).join('\n');
        
        // Create a Blob and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Medicine_Report.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);      
      }
    });

    // Reset selected downloads
    setSelectedDownloads([]);

    setIsDownloadDropdownOpen(false)
  };
  
  useEffect(() => {
    if (fromDate) {
      apiCallFilter(1, perPage)
    }

  }, [fromDate,toDate]);

  const handleChangeValue = (val) => {
    registerData.splice(0, registerData.length + 1)
    
     
      var startOfWeek = moment().startOf('week').toDate();
      var endOfWeek = moment().endOf('week').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setFromDate(FormatedFirstday)
      setToDate(Formatedlastday)
      setSelectedValue('week');

    
  }

  const handleDayChange = (increment) => {
    registerData.splice(0, registerData.length + 1)

    const newDate = moment(currentDay).add(increment, 'days');
  
    // Set maximum date to the current date
    if (newDate.isAfter(moment(), 'day')) {
      setCurrentDay(moment().format('YYYY-MM-DD'));
      return;
    }
  
    setFromDate(newDate.format('YYYY-MM-DD'));
    setToDate(newDate.format('YYYY-MM-DD'));
    setCurrentDay(newDate.toDate());

    setSelectedValue('day');
  };


  const handleMonthChange = (increment) => {
    registerData.splice(0, registerData.length + 1)
    const currentMoment = moment(`${monthsYear}-${currentMonth}`, 'YYYY-MMMM');
    const newMoment = currentMoment.add(increment, 'months');

    // Set minimum month to one year back
    const minMonth = moment().subtract(1, 'year').endOf('month')-1;
    if (newMoment.isSameOrBefore(minMonth, 'month')) {
      setCurrentMonth(minMonth.format('MMMM'));
      setMonthsYear(minMonth.format('YYYY'));
      return;
    }

    // Set maximum month to the current month
    if (newMoment.isAfter(moment(), 'month')) {
      setCurrentMonth(moment().format('MMMM'));
      setMonthsYear(moment().format('YYYY'));
      return;
    }

   
      const startOfMonth = newMoment.startOf('month').format('YYYY-MM-DD');
      const endOfMonth = newMoment.endOf('month').format('YYYY-MM-DD');
  
      setFromDate(startOfMonth);
      setToDate(endOfMonth);
   
    setCurrentMonth(newMoment.format('MMMM'));
    setMonthsYear(newMoment.format('YYYY'));

    setSelectedValue('month');

  };

  const generateYearOptions = () => {
    const years = [];
    for (let year = 2023; year <= currentYear; year++) {
      years.push(String(year));
    }
    return years;
  };

  const filterOptions = [ ...generateYearOptions()];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const handleFilterChange = (filter) => {
    setSelectedYears((prevYears) => {
      if (prevYears.includes(filter)) {
        // Deselect the checkbox
        return [];
      } else {
        // Select the checkbox and disable others
        return [filter];
      }
    });
  };
  

  const handleApplyFilters = () => {
    registerData.splice(0, registerData.length + 1)

    if (selectedYears.length > 0) {
      const sortedYears = selectedYears.sort((a, b) => Number(a) - Number(b));
  
      const startDate = sortedYears[0] + '-01-01';
      const endDate = sortedYears[selectedYears.length - 1] + '-12-31';
  
      setFromDate(startDate);
      setToDate(endDate);
  
     
  
    }
  
    setSelectedYears([]);
    setIsDropdownOpen(false);
    setSelectedValue('');

  };

  const apiCallFilter = (page, per_page) => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const a = fromDate;
    const b = toDate;

    //  2021-10-12
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?start_date=${a}&end_date=${b}&page=${page}&per_page=${per_page}&medicine=medicine&${TZ}`,{
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setHasNext(res.data.has_next)
          setLoad('')
          let datalist = res.data.medicines
          //const data1 = datalist.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(datalist)
          setTotalRows(res.data.total_records)
        }
        else {
          setLoad('Error in Fetching Data')
        }

      })


      .catch(err => {
        setLoad('Error in Fetching Data')
      })


  }

  const handleRefresh =(e)=>{
    
    e.preventDefault();
    setCurrentDay(moment().toDate());
    setCurrentMonth(moment().format('MMMM'));
    setMonthsYear(moment().format('YYYY'));
    setRegisterData([]);
    
    setPage(1);
    setFromDate("")
    setToDate("")
    setRefresh(!refresh)
    setDateRange(null); 
  }

  const handleEvent = (value, picker) => {
    setDateRange(value);    
    registerData.splice(0, registerData.length + 1)
    if (value && value.length === 2) {
      // Check if event is not null and contains two dates
      const [start, end] = value;
      const fromDate = moment(start).format('YYYY-MM-DD');
      const toDate = moment(end).format('YYYY-MM-DD');
      setFromDate(fromDate);
      setToDate(toDate);
      setSelectedValue('');
    } else {
      // Handle the case where event is null or doesn't contain two dates
      setFromDate(''); // Reset fromDate
      setToDate('');   // Reset toDate
      setSelectedValue('');
      setRefresh(!refresh)
    }
  };

  const renderTable = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize",fontSize:"16px" }}>{registerData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }
  const renderTableProcedures = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize",fontSize:"16px" }}>{registerData[cellInfo.index].government_file !==null ? "Yes":"No"}</div>
    )
  }
  const renderTableNew = (cellInfo) => {
    // Extract admission_date from the data
    const admissionDate = new Date(registerData[cellInfo.index].admit_date);
    
    // Get the current date
    const currentDate = new Date(registerData[cellInfo.index].date_of_death);
    
    // Calculate the difference in milliseconds
    const timeDifference = currentDate - admissionDate;
    
    // Calculate the duration in days
    const durationInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "16px" }}>
        {durationInDays} days
      </div>
    );
  }
  
  



  const renderDate = (cellInfo) => {
    let dateString = registerData[cellInfo.index][cellInfo.column.id]
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div style={{ color: "black" }}>
        {updatedDate.slice(4, 16)}

      </div>
    )
  }

  const filterDataName = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?medicine=medicine&med_name=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          const data1 = data.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataCount = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?medicine=medicine&med_count=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          const data1 = data.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };




  const columns = [
    {
      Header: " Medicine Name",
      accessor: "med_name",
      Cell: renderTable,
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataName(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      
      // width: 220

    },

    {
      Header: "Total Dispense Count",
      accessor: "total_dispensing_count",
      Cell: renderTable,
      sortable: true,
      style: { whiteSpace: "unset",wordBreak: "break-all"},
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataCount(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      // width: 200

    },
   


  ]
  const handlePageChange = page => {
    if (hasNext) {
      apiCallFilter(page + 1, perPage);
    }
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage)
    registerData.splice(0, registerData.length + 1)
    apiCallFilter(1, newPerPage);

  }
  useEffect(() => {
    apiCallFilter(page, perPage);
    setSelectedValue('')
  }, [perPage]);
  const CustomPagination = ({
    page,
    pageSize,
    pages,
    canPrevious,
    canNext,
    onPageChange
  }) => {
    return (
      <div style={{display:"flex"}}>
        <button style={{width:"30%",margin: "3px", borderRadius: "5px", padding:"10px"}} onClick={() => onPageChange(page - 1)} disabled={!canPrevious}>
          Previous
        </button>
        <div style={{ width:"40%", textAlign: "center", padding: "10px"}}><span style={{ margin: "10px" }}>Page {page + 1}</span></div> 
        <button style={{width:"30%",margin: "3px", borderRadius: "5px", padding:"10px"}} onClick={() => onPageChange(page + 1)} disabled={!canNext}>
          Next
        </button>
      </div>
    );
  };

  const display = <ReactTable
    columns={columns}
    data={registerData}
    defaultPageSize={perPage - 1}
    className="chol-table"
    resizable
    minRows={perPage - 1}
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    pagination
    paginationServer
    //pages={Math.ceil(totalRows / perPage)}
    onPageChange={(pageIndex) => { handlePageChange(pageIndex); setPage(pageIndex + 1) }}
    paginationTotalRows={totalRows}
    onPageSizeChange={handlePerRowsChange}
    PaginationComponent={CustomPagination}
  ></ReactTable>

 

  const CsvRegisterheaders = [
    { label: "Medicine Name", key: "med_name" },
    { label: "Total Dispense Count", key: "total_dispensing_count" },

  ];
  

  return (
    <>
<div className="patient-report"> 
  <Nav className="row" style={{ marginTop: "1%",alignContent:'center',boxSizing: 'border-box' }}>
    <span className="col-6" style={{ width: '35.7%' }}>  
      <button className="buttonfilter" style={{ marginTop: "1%", borderRadius: "25px", width: "90px", background: "#1269B9",fontSize:"11px" }} onClick={(e) => handleRefresh(e)}>
        <span>Refresh &nbsp;<i className="fa fa-refresh" aria-hidden="true" style={{ color: "white" }}></i> </span>
      </button> 
    </span>
    <span className="col-6 togglegroup" style={{display:'flex', marginBottom: "1%", textAlign: "right", float: 'right' }}>
      <ToggleButtonGroup className="datepicker-reports" style={{ marginTop: "1%", fontSize: "small"}} type="radio" name="options" >
        <DateRangePicker
          appearance="default"              
          placeholder={"Select Date Range"}  
          value={dateRange}               
          onChange={handleEvent}
          disabledDate={(date) => isAfter(date, new Date())}
        >
          <ToggleButton id="tbg-radio-4" value={'custom'} className="btn btn-light toggle-btns custombtn1" style={{ border: 'none',fontSize:'11px'}}></ToggleButton>
        </DateRangePicker>&nbsp;&nbsp;&nbsp;
        
        <div style={{ display: 'flex', alignItems: 'center', height: '30px', fontSize: '11px', paddingTop: '0px', paddingBottom: '0px', border: `1px solid ${selectedValue === 'day' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }}>
          <button className='arrowButton'  onClick={() => handleDayChange(-1)}>
            {<AiFillCaretLeft />}
          </button>
          <span style={{ margin: '0 8px', width: '50px', textAlign: 'center', fontSize:'11px' }}>
            {moment(currentDay).isSame(moment(), 'day') ? 'Today' : moment(currentDay).format('YYYY/MM/DD')}
          </span>
          <button className='arrowButton' onClick={() => handleDayChange(1)}>
            {<AiFillCaretRight />}
          </button>
        </div>&nbsp;&nbsp;&nbsp;
        
        <div style={{ display: 'flex', alignItems: 'center',height: '30px', fontSize: '11px', fontWeight: '400px', border: `1px solid ${selectedValue === 'month' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }}>
          <button className='arrowButton' onClick={() => handleMonthChange(-1)}>
            {<AiFillCaretLeft />}
          </button>
          <span style={{ margin: '0 4px', width: '50px', textAlign: 'center' }}>{currentMonth}</span>
          <button className='arrowButton' onClick={() => handleMonthChange(1)}>
            {<AiFillCaretRight />}
          </button>
        </div>&nbsp;&nbsp;&nbsp;
        <button   
          style={{ display: 'flex',justifyContent:'center',alignItems:'center', height: '30px',width:'50px', fontSize: '11px',backgroundColor:'white', fontWeight: '400px', border: `1px solid ${selectedValue === 'week' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }} 
          onClick={() => handleChangeValue('week')}
        > 
          <span>Week</span>
        </button>&nbsp;&nbsp;&nbsp;
        <div style={{ position: 'relative' }}>
      <button
        className="buttonfilter" 
        style={{ borderRadius: "20px",height: '32px', width: '60px', fontSize: '11px', background: isDropdownOpen ? "rgb(13, 197, 197)" : " #1269B9" }}
        onClick={toggleDropdown}
      ><span>
        More
        </span>
      </button>
      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
            width:'100px',
            alignItems:'center',
          }}
        >
          {filterOptions.map((filter) => (
            <label key={filter} style={{ marginBottom: '5px' }}>
              <input
                type="checkbox"
                checked={selectedYears.includes(filter)}
                onChange={() => handleFilterChange(filter)}
                //disable others if one is selected
                disabled={selectedYears.length > 0 && !selectedYears.includes(filter)}
              />&nbsp;
              {filter}
            </label>
          ))} 
          <button disabled={selectedYears.length===0} style={{ marginTop: '10px' }} onClick={handleApplyFilters}>
            Apply
          </button>
        </div>
      )}
      </div>&nbsp;     
        <button className='buttonfilter' style={{ width: '100px',height: '32px', fontSize: '11px' }} onClick={toggleDownloadDropdown}>
          <span>Download  <MdDownloadForOffline /></span>
        </button>
      </ToggleButtonGroup> 
    </span>    
  </Nav>
  {isDownloadDropdownOpen && (
  <div style={{ position: 'relative', zIndex: 1000 }}>
    {/* Backdrop */}
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black color
        zIndex: 1000, // Ensure it's above other elements
      }}
      onClick={() => {}}
    ></div>
    
    {/* Dropdown */}
    <div className='download-dropdown'
      style={{
        position: 'absolute',
        margin: 'auto',
        width: '30rem',
        borderRadius: '10px',
        backgroundColor: '#fff',            
        padding: '10px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        zIndex: 1001,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '15rem',
        marginTop: '5%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid lightgrey',
          marginBottom: '10px',
          paddingBottom:'10px'
        }}
      >
        <button
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            marginLeft: 'auto',
          }}
          onClick={toggleDownloadDropdown}
        >
          <GrClose />
        </button>
      </div>
      <div style={{display:"flex", flexDirection:"column"}}>
        <h4 style={{ marginBottom:"2rem"}}>Select the formats to download</h4>
        {downloadOptions.map((download) => (
          <label key={download} style={{ marginBottom: '5px' }}>
            <input
              type="checkbox"
              checked={selectedDownloads.includes(download)}
              onChange={() => handleDownloadChange(download)}
              />&nbsp;
            {download}
          </label>
        ))} 
      </div>
      <button
        variant="primary"
        className='buttonfilter'
        style={{width: '100px', height: '32px', fontSize: '11px', marginTop:"10px" }}
        onClick={handleDownloads}
      >
        Download
      </button>
    </div>
  </div>
)}
        
        <div className="service-table-holder" style={{ }}> {load === '' ? display
          : load === 'Error in Fetching Data' ? <h6 style={{ color: "red" }}>{load}</h6> : <h6 style={{ color: "grey" }}>{load}</h6>}
        </div>
      </div>
      <br />
         </>

  )
}

export default MedicinesReport

import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from "react-router-dom";

import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import SimpleBar from 'simplebar-react';
import "simplebar-react/dist/simplebar.min.css";
import { useNavigate } from "react-router-dom";
import "./wound.css"

import "rsuite/dist/rsuite.min.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Management from './Management';
import WoundForm from './WoundForm';
function WoundReport() {
    const alert = useAlert()
    const location = useLocation();
    const id= location.state ? location.state.id : ""
    const name= location.state ? location.state.name : ""
    const gender= location.state ? location.state.gender: ""
    const room = location.state ? location.state.room:""
    const mobile_number = location.state ? location.state.mobile_number:""
    const [woundArray, setWoundArray] = useState([])
    const [woundInfo, setWoundInfo] = useState([])
    const [woundId, setWoundId] = useState(location.state ? location.state.woundId : "")
    const [wound, setWound] = useState({})
    const [status, setStatus] = useState("")
    const [show,setShow]=useState(false)
   const [refresh,setRefresh]=useState(false)
   const [showwoundInfo,setShowWoundInfo]=useState(false)
   let navigate = useNavigate();
    const renderTable = (cellInfo) => {
        return (
            <div className='woundtest' style={{ overflow: "wrap",textTransform:cellInfo.column.id==="treated_by_name"&&"capitalize" }}>{woundArray[cellInfo.index][cellInfo.column.id]}</div>
        )
    }
    useEffect(() => { if(woundId)getDetails()},[woundId,refresh])
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    useEffect(() => {
       
        const tokenString= sessionStorage.getItem('usertoken');
       
    
        let str= tokenString.replace(/["]+/g, '');
         axios.get(`${APIURL}/api/v1/service-provider/wound-info/?timeZone=${currentTimezone}&patient_id=${id}`, 
         { headers: {
           'Content-Type': 'application/json',
           "Authorization" : 'Token '+str  }})
            .then(res=>{
        
           if(res.data.status==="success"){

            
            setWoundInfo(res.data.details)
            if(woundId!==""){
            const filteredData = res.data.details.filter(x => {
                return x.id === woundId
            })
            setStatus(filteredData[0].status)
        }
            if(woundId!==""&&location.state.woundId!==""){
               const filteredData = res.data.details.filter(x => {
                    return x.id === location.state.woundId
                })
                setWound(filteredData[0])
                
                
            }
           
    
            }
         })
       
        
    },[id,refresh])
    const getDetails = (e) => {
        const tokenString= sessionStorage.getItem('usertoken');
       
    
        let str= tokenString.replace(/["]+/g, '');
         axios.get(`${APIURL}/api/v1/service-provider/wound-treatment/?timeZone=${currentTimezone}&wound_id=${woundId}`, 
         { headers: {
           'Content-Type': 'application/json',
           "Authorization" : 'Token '+str  }})
            .then(res=>{
        
           if(res.data.status==="success"){

            
            setWoundArray(res.data.details)
           
    
            }
         })
       
        
    }
    const renderDate = (cellInfo) => {
        let dateString = woundArray[cellInfo.index].treated_time_ist
       
        return (
            cellInfo.column.id === "treated_time_ist" ? <div style={{ color: "black" }}>
                {dateString.slice(0, 10)}

            </div> : <div style={{ color: "black" }}>
                {dateString.slice(11, 20)}

            </div>
        )
    }
    const columns = [
        {
            Header: "No",
            accessor: "id",
            Cell: renderTable,
            sortable: false,
            width: 60,

        },
        {
            Header: "Date",
            accessor: "treated_time_ist",
            Cell: renderDate,
            sortable: false,
            width: 160,

        },
        {
            Header: "Time",
            accessor: "time",
            Cell: renderDate,
            sortable: false,
            width: 160,
        },

        {
            Header: "Wound Progress",
            style: { whiteSpace: "unset" },
            accessor: "progress",
            Cell: renderTable,
            sortable: false,
            // width: 60,
        },
        {
            Header: "Wound Treatment",
            style: { whiteSpace: "unset" },
            accessor: "treatment",
            Cell: renderTable,
            sortable: false,
            // width: 60,
        },
        {
            Header: "Updated By",
            accessor: "treated_by_name",
            Cell: renderTable,
            sortable: false,
            // width: 60,
        },


    ];
    const InfoPopup = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
                    <h6 style={{textTransform:"capitalize"}}><b>{wound.type}</b></h6>
                    <button style={{ backgroundColor: "white", float:"right" }} variant="secondary" onClick={props.onHide}>
                        <i class="fa fa-times-circle fa-2x" style={{ color: "#1269B9" }} aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body style={{ paddingRight: "4%" }}>
                    <Management woundId={woundId} patientId={id} status={status} onSuccess={()=>{setRefresh(!refresh);setShow(false)}}/>
                </Modal.Body>
            </Modal>
        );
    };
    const exportReport = () => {
  setShowWoundInfo(true)
        // const unit = "px";
        // const size = "A4"; // Use A1, A2, A3 or A4
        // const orientation = "portrait"; // portrait or landscape
    
        // const marginLeft = 40;
        // const doc = new jsPDF(orientation, unit, size);
    
        // doc.setFontSize(12);
    
        // const title = "Wound Report";
        // const headers = [["Type", "General", "Assessment", "Action","Status"]];
    
        // const data = woundInfo.map(wound => [
        //     wound.type,
        //     ["Degree:"+wound.degree+"\r\n"+
        //     "Range for risk of decubitus:"+wound.decubitus_risk+"\r\n"+
        //     "Remarks:"+wound.points+"\r\n"+
        //     "Treatment To Date:"+wound.treatment_todate+"\r\n"+
        //     "Influential Factors of wound healing:"+wound.healing_factors+"\r\n"+
        //     "Medicaments(Cortisone, Cytostatic, Antibiotics):"+wound.medicaments+"\r\n"+
        //     "Pain:"+wound.pain],
        //     ["Localisation:"+wound.localisation+"\r\n"+
        //     "Size:"+wound.size+"mm\r\n"+
        //     "Width:"+wound.width+"mm\r\n"+
        //     "Length:"+wound.length+"mm\r\n"+
        //     "Depth:"+wound.depth+"mm\r\n"+
        //     "Condition of Wound:"+wound.condition+"\r\n"+
        //     "Wound Edges:"+wound.edges+"\r\n"+
        //     "Wound Exudate:"+wound.exudate+"\r\n"+
        //     "Phases of wound healing:"+wound.phases],
        //      ["Treatment (Doctor/Specialisation):"+wound.treatment_doc+"\r\n"+
        //      "Wound Cleansing:"+wound.cleansing+"\r\n"+
        //      "Wound Dressng:"+wound.dressing+"\r\n"+
        //      "Other:"+wound.other+"\r\n"+
        //      "Action to be taken:"+wound.action_planned],
        //      wound.status]);
    
    
        // let content = {
        //   startY: 50,
        //   head: headers,
        //   body: data
        // };
    
        // doc.text(title, marginLeft, 40);
        // doc.autoTable(content);
        // doc.save("woundReport.pdf")
    
    
      };
      const FormPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body style={{paddingRight:"2%"}}>
                    <WoundForm woundInfo={woundInfo} />
                  
                </Modal.Body>
            </Modal>
        );
    };
    return (
        <div className='container' style={{marginTop:"1%"}}>
           
            <div className='row ' >
                <div className='col-2 'style={{borderRight: "2px solid rgba(198, 198, 198, 1)"}}>
                <h6 style={{marginTop:"15px"}}className="form-button">
                <span
                  id={gender === "female" ? "patient-female" : "patient-male"}
                >
                  <b> {name}</b>
                </span>
                <h5 className="enquiryheader">Wound reports</h5>
              </h6>
              <button onClick={exportReport} style={{width:"100%", border:"none", color:"rgba(54, 90, 144, 1)",backgroundColor:"white"}}><svg style={{ color: "rgba(54, 90, 144, 1)" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 8H5c-1.66 0-3 1.34-3 3v4c0 1.1.9 2 2 2h2v2c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-2h2c1.1 0 2-.9 2-2v-4c0-1.66-1.34-3-3-3zm-4 11H9c-.55 0-1-.45-1-1v-4h8v4c0 .55-.45 1-1 1zm4-7c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1zm-2-9H7c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1z" /></svg>&nbsp;Export</button>
              
              <SimpleBar style={{ maxHeight: '500px',marginTop:"10%" }}>
              {woundInfo.map((item)=>{
              return(
            <><div onClick={() =>{setWoundId(item.id);setStatus(item.status); setWound(item)}}className='left-unit'>
                        <div style={{backgroundColor:item.id===woundId?"rgba(54, 90, 144, 1)":"#9ADFCC", color:"white",border: "1px solid rgba(198, 198, 198, 1)", borderRadius:"10px",padding:"3%",textTransform:"capitalize"}}>{item.type}</div>
                    <div style={{padding:"1%", textAlign:"center",color: item.status ==="not_healed" ? "#33C300" : "#ECBB0F"}}>{(item.start_date_ist).slice(0, 10)}{" "}<small style={{color:"black"}}>To</small>{" "}{item.end_date_ist!==null?(item.end_date_ist).slice(0, 10):"---"}</div>
                    </div> </> 
              )}
              )}
                  </SimpleBar>  
                   
                </div>
                <div className='col-10'  >
                
             
               
            <div style={{textAlign:"right"}}>
            <button onClick={() =>{navigate("/wound_chart", {
                            state: { name: name, id: id,  gender: gender ,room:room,mobile_number:mobile_number },
                          })}} style={{ backgroundColor: "rgba(54, 90, 144, 1)", color: "white", borderRadius: "10px", height: "40px" }}>Documentation</button>
                &nbsp; &nbsp; &nbsp; &nbsp; 
            <button disabled={status==="healed"} onClick={() =>{woundId===""?alert.error("please select a wound"):status==="healed"?alert.error("Healed wound"):setShow(true)}} style={{ backgroundColor:status==="healed"?"#365a9073": "rgba(54, 90, 144, 1)",color: "white", borderRadius: "10px", height: "40px" }}>Treatment Updates</button>
            </div>
                    <div className=""style={{marginTop:"1%"}}>
                        <ReactTable
                            columns={columns}
                            data={woundArray}
                            defaultPageSize={5}
                         style={{ overflow: "wrap", textAlign: "left" }}
                            resizable
                            className="inventory-table"
                        ></ReactTable>
                    </div>
                    <div  style={{ padding: "2%",paddingLeft:"2%" }}>
                        <div className='row' style={{ border: "1px solid "}}>
                            <div className='col-4'style={{ borderRight: "1px solid "}}>
                                <div className='woundheader'><h6 style={{padding:"3%"}}>General Information</h6></div>
                               {wound.id&&wound.type!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Type of wound:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize" }}>{wound.type}</label><br/></>}
                               {wound.id&&wound.degree!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Degree/Stage:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.degree}</label><br/></>}
                               {wound.id&&wound.decubitus_risk!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}> Range for risk of decubitus:</label><label className="" style={{ marginTop: "2%" , textTransform:"capitalize" }}>{wound.decubitus_risk}</label><br/></>}
                               {wound.id&&wound.points!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Remarks:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.points}</label><br/></>}
                               {wound.id&&wound.treatment_todate!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Treatment To Date:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.treatment_todate}</label><br/></>}
                               {wound.id&&wound.healing_factors.length>0&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Influential Factors of wound healing:</label >{wound.healing_factors.map((item,index)=>{return(<label className="" style={{ marginTop: "2%" , textTransform:"capitalize" }}>{item.replace(/_/g, ' ')}{index!==wound.healing_factors.length-1?", ":" "}</label>)})}<br/></>}
                               {wound.id&&wound.medicaments!=="no"&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Medicaments:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>Cortisone, Cytostatic, Antibiotics</label><br/></>}
                               {wound.id&&wound.pain!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Pain:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.pain.replace(/_/g, ' ')}</label><br/></>}
                            </div>
                            <div className='col-4'style={{ borderRight: "1px solid "}}>
                                <div className='woundheader'><h6 style={{padding:"3%"}}>Assessment of Wound</h6></div>
                                {wound.id&&wound.localisation!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Localisation:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.localisation}</label><br/></>}
                               {wound.id&&wound.size!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Size:</label><label className="" style={{ marginTop: "2%" , textTransform:"capitalize" }}>{wound.size}mm</label><br/></>}
                               {wound.id&&wound.width!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}> Width:</label><label className="" style={{ marginTop: "2%" , textTransform:"capitalize" }}>{wound.width}mm</label><br/></>}
                               {wound.id&&wound.length!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Length:</label><label className="" style={{ marginTop: "2%" , textTransform:"capitalize" }}>{wound.length}mm</label><br/></>}
                               {wound.id&&wound.depth!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Depth:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.depth}mm</label><br/></>}
                               {wound.id&&wound.condition!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Condition of Wound:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.condition.replace(/_/g, ' ')}</label><br/></>}
                               {wound.id&&wound.edges!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Wound Edges:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.edges.replace(/_/g, ' ')}</label><br/></>}
                               {wound.id&&wound.exudate!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Wound Exudate:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.exudate.replace(/_/g, ' ')}</label><br/></>}
                               {wound.id&&wound.phase!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Phases of wound healing:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.phase.replace(/_/g, ' ')}</label><br/></>}
                            </div>
                            <div className='col-4'style={{ borderRight: "1px solid "}}>
                                <div className='woundheader' ><h6 style={{padding:"3%"}}>Action to be Taken</h6></div>
                                {wound.id&&wound.treatment_by!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Treatment (Doctor/Specialisation):</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.treatment_doc}</label><br/></>}
                               {wound.id&&wound.cleansing!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Wound Cleansing:</label><label className="" style={{ marginTop: "2%" , textTransform:"capitalize" }}>{wound.cleansing}</label><br/></>}
                               {wound.id&&wound.dressing!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Wound Dressng:</label><label className="" style={{ marginTop: "2%" , textTransform:"capitalize" }}>{wound.dressing}</label><br/></>}
                               {wound.id&&wound.other!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Other:</label><label className="" style={{ marginTop: "2%" , textTransform:"capitalize" }}>{wound.other}</label><br/></>}
                               {wound.id&&wound.action_planned!==null&&<> <label className="" style={{ marginTop: "2%",fontWeight:"600" }}>Action to be taken:</label><label className="" style={{ marginTop: "2%", textTransform:"capitalize"  }}>{wound.action_planned.replace(/_/g, ' ')}</label><br/></>}
                              </div>
                         
                        </div>
                    </div>
                </div>
            </div>
            {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}
             {showwoundInfo ? (
                <FormPopUp
                    show={showwoundInfo}
                    onHide={() => {
                        setShowWoundInfo(false);
                    }}
                />
            ) : null}
        </div>
    )
}
export default WoundReport

import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import DatePicker from 'react-date-picker';
import "./wound.css"
import PatientSearch from '../../common/PatientSearch';
function General(props) {
    const alert = useAlert()
    const [generalInfo, setGeneralInfo] = useState({ medicaments: "no" })
    const [factors, setFactors] = useState({
        age: false,
        malnutrition: false,
        cachexia_diabetes: false,
        mellitus: false,
        vitamin_deficiency: false,
        allergy: false,
        arteriosclerosis: false,
        immobility: false,
        poor_compliance: false,
        obesity_infectious: false,
        disease: false,
        anemia: false
    })
    const [useMalayalamInput, setUseMalayalamInput] = useState(false); 
    const [factorsArray, setFactorsArray] = useState([])
    useEffect(() => {
        setGeneralInfo((currentstate) => ({
            ...currentstate,
            patient: props.patientId,
        })
        );
    }, [props.patientId])
   
    useEffect(() => {

        setGeneralInfo((currentstate) => ({
            ...currentstate,
            healing_factors: factorsArray,
        })
        );

    }, [factorsArray])
    useEffect(() => {
        let updatedArray = [];
        for (var key in factors) {
            if (factors[key] === true) {
                updatedArray.push(key);
            }
        }

        setFactorsArray(updatedArray);

    }, [factors]);

    const handleFactors = (e) => {
        const key = e.target.name;

        setFactors((currentstate) => ({
            ...currentstate,
            [key]: !factors[key],
        })
        );

    };
    const handleMedicaments = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        setGeneralInfo((currentstate) => ({
            ...currentstate,
            [key]: val === "no" ? "yes" : "no",
        })
        );

    };
    const handlePain = (e) => {
        const key = e.target.name;

        setGeneralInfo((currentstate) => ({
            ...currentstate,
            pain: key,
        })
        );

    };
    const trimspace=(val)=>{
    
        return  val.startsWith(' ')
        ? val.trimLeft()
        : val;
      }
    const handleTitle = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;
        setGeneralInfo(currentstate => ({
            ...currentstate,
            [key]: val
        }));

    }
    const handleMalayalamInputToggle = () => {
        setUseMalayalamInput((prevState) => !prevState);
      };
    return (
        <div>
            <div className='head-div'>
                <h6 style={{ padding: "1%" }}>General Information of Wound</h6>
            </div>
            <div className='row' style={{ marginTop: "2%" }}>
                <div className='col-3'>
                    <span className="text-style-here">&nbsp;&nbsp;Type of Wound</span>
                    <input type="text" name="type" value={generalInfo.type} onChange={handleTitle} className='enquiryinput' />
                </div>
                <div className='col-4'>
                    <span className="text-style-here">&nbsp;&nbsp;Degree/Stage</span>
                    <input type="text" name="degree" value={generalInfo.degree} onChange={handleTitle} className='enquiryinput' />
                </div>
                <div className='col-5'>
                    <span className="text-style-here">&nbsp;&nbsp;Range for risk of decubitus</span>
                    <input type="text" name="decubitus" value={generalInfo.decubitus} onChange={handleTitle} className='enquiryinput' />
                </div>
            </div>
            <div className='row'>
                <div className='col-9'>
                    <span className="text-style-here">&nbsp;&nbsp;</span>
                    <input type="text" name="points" value={generalInfo.points} onChange={handleTitle} placeholder='Remarks' className='enquiryinput' />
            
                </div>
                <div className='col-3'>
                    <span className="text-style-here">&nbsp;&nbsp;Treatment Given</span>

                    <input type="text" name="treatment_todate" value={generalInfo.treatment_todate} onChange={handleTitle}  className='enquiryinput' />
                </div>
            </div>
            <div style={{ marginTop: "2%", backgroundColor: "rgba(244, 244, 244, 1)", padding: "1%" }}>
                <div className='row'>
                    <div className='col-6' style={{ padding: "1%" }}>
                        <div className='sub-head-div'>
                            <h6 style={{ padding: "1%" }}><b>Influential Factors of wound healing</b></h6>
                        </div>
                        <div className='row' style={{ marginTop: "2%", padding: "1%" }}>
                            <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="age"
                                    name="age"
                                    checked={factors.age}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Age</label></>
                            </div>
                            <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="arteriosclerosis"
                                    name="arteriosclerosis"
                                    checked={factors.arteriosclerosis}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Arteriosclerosis / AOD</label></>
                            </div>
                            <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="malnutrition"
                                    name="malnutrition"
                                    checked={factors.malnutrition}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Malnutrition</label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="immobility"
                                    name="immobility"
                                    checked={factors.immobility}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Immobility </label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="cachexia_diabetes"
                                    name="cachexia_diabetes"
                                    checked={factors.cachexia_diabetes}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Cachexia Diabetes</label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="poor_compliance"
                                    name="poor_compliance"
                                    checked={factors.poor_compliance}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Poor Compliance</label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="mellitus"
                                    name="mellitus"
                                    checked={factors.mellitus}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Mellitus</label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="obesity_infectious"
                                    name="obesity_infectious"
                                    checked={factors.obesity_infectious}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Obesity Infectious</label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="vitamin_deficiency"
                                    name="vitamin_deficiency"
                                    checked={factors.vitamin_deficiency}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Vitamin Deficiency</label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="disease"
                                    name="disease"
                                    checked={factors.disease}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Disease</label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="allergy"
                                    name="allergy"
                                    checked={factors.allergy}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Allergy</label></>
                            </div> <div className='col-6' >
                                <><input
                                    className=""
                                    type="checkbox"
                                    id="anemia"
                                    name="anemia"
                                    checked={factors.anemia}
                                    onChange={(e) => { handleFactors(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Anemia</label></>
                            </div>
                        </div>
                    </div>
                    <div className='col-6' style={{ padding: "1%" }}>
                        <div className='sub-head-div'>
                            <h6 style={{ padding: "1%" }}><b>Medicaments</b></h6>
                        </div>
                        <div style={{ padding: "1%" }}>
                            <><input
                                className=""
                                type="radio"
                                id="medicaments"
                                name="medicaments"
                                value={generalInfo.medicaments}
                                checked={generalInfo.medicaments === "yes"}
                                onChange={(e) => { handleMedicaments(e) }}
                            />{" "}<label className="" style={{ marginTop: "2%" }}>Cortisone, Cytostatic, Antibiotics</label></>
                        </div>
                        <div className='sub-head-div'>
                            <h6 style={{ padding: "1%" }}><b>Pain</b></h6>
                        </div>
                        <div style={{ padding: "1%" }}>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="permanent"
                                    name="permanent"

                                    checked={generalInfo.pain === "permanent"}
                                    onChange={(e) => { handlePain(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Permanent</label></>
                            </div>

                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="dressing"
                                    name="upon_changing_dressing"
                                    checked={generalInfo.pain === "upon_changing_dressing"}
                                    onChange={(e) => { handlePain(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Upon Changing Dressing</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="sometimes"
                                    name="sometimes"

                                    checked={generalInfo.pain === "sometimes"}
                                    onChange={(e) => { handlePain(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Sometimes</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="never"
                                    name="never"

                                    checked={generalInfo.pain === "never"}
                                    onChange={(e) => { handlePain(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Never</label></>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
           <div className='text-center'>
                <button onClick={(e) => {!(props.patientId|props.patientId===undefined)?alert.error("Please Select Patient"):!generalInfo.type?alert.error("Please Enter type of wound"):props.onSuccess( generalInfo) }} className='cons-button'>Next</button>

            </div>
        </div>
    )
}
export default General

import React, { useState, useEffect, useRef } from 'react'
import './treat.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
function TreatPlan(props) {
    const navigate = useNavigate();
    const [date, setDate] = useState(new Date())
    const [date1, setDate1] = useState(new Date().toISOString().slice(0, 10))
    const [morning, setMorning] = useState(true)
    const [afternoon, setAfternoon] = useState(false)
    const [evening, setEvening] = useState(false)
    const [night, setNight] = useState(false)
    const [item, setItem] = useState("medicine")
    const [timeperiod,setTimeperiod]=useState("morning")
    const [treatChart, setTreatChart] = useState([])
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
    const [mtitle,setMtitle]=useState(false)
    const [ltitle,setLtitle]=useState(false)
    const [ftitle,setFtitle]=useState(false)
    
   
    useEffect(() => {
       
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(/["]+/g, "");

        axios
            .get(
                `${APIURL}/api/v1/doctor/treatment-plan/?patient_id=${props.id}&date=${date1}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + v2,
                    },
                }
            )
            .then((res) => {

                if (res.data.status === "success") {
                   
                    setTreatChart(res.data.treatment_plan)
                }

            })

            setMtitle(false)
            setFtitle(false)
            setLtitle(false)

    }, [date1]);

    useEffect(() => {
        if (date !== "") {
            setDate1(date.toISOString().slice(0, 10))
        }
        else {
            setDate1("")
        }

    }, [date])
    function getNextDate(date1) {
        var tomorrow = new Date(date1)
        tomorrow.setDate(tomorrow.getDate() + 1);
        setDate(tomorrow)

    }
    function getPrevDate(date1) {
        if (date1 === "") {
            setDate1(new Date().toISOString().slice(0, 10))
            setDate(new Date())
        }
        else {
            var yest = new Date(date1);
            yest.setDate(yest.getDate() - 1);
            setDate(yest)

        }


    }
    useEffect(() => {
        treatChart["medicine"] && treatChart["medicine"].length > 0 && treatChart["medicine"].map((item) => {
           
              if(item.serv_time.includes(timeperiod) ){setMtitle(true)}
              else{
                setMtitle(false)
            }
        
        })
        treatChart["liquid"] && treatChart["liquid"].length > 0 && treatChart["liquid"].map((item) => {
            if(item.serv_time.includes(timeperiod) )
            {setLtitle(true)}
            else{
                setLtitle(false)
            }
        })
        treatChart["food"] && treatChart["food"].length > 0 && treatChart["food"].map((item) => {
            if(item.serv_time.includes(timeperiod)){
                setFtitle(true)}
            else{
                setFtitle(false)
            }
        })
    },[date,timeperiod,treatChart])
    const displayAll = (time) => {
        return (
        <ul className='row' style={{width:"96%"}}>
          
            {mtitle &&<><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Medicine</h6></>}
            {treatChart["medicine"] && treatChart["medicine"].length > 0 && treatChart["medicine"].map((item) => {
                return (
                    item.serv_time.includes(time) && <span style={{ marginBottom: "2%",margin:"auto" }} className='col-4'><h6 className='first_capital'style={{fontWeight:"700"}}>{item.name}</h6>
                        <small>{item.quantity}</small>
                    </span>
                )
            })}
            { ltitle &&<><hr/><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Liquid</h6></>}
            {treatChart["liquid"] &&treatChart["liquid"].length >0&& treatChart["liquid"].map((item) => {
                return (
                    item.serv_time.includes(time) && <span style={{ marginBottom: "2%" ,margin:"auto" }} className='col-4'><h6 className='first_capital'style={{fontWeight:"700"}}>{item.name}</h6>
                        <small>{item.quantity}ml</small>
                    </span>
                )
            })}
           { ftitle &&<><hr/><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Food</h6></>}
            {treatChart["food"] && treatChart["food"].length > 0 && treatChart["food"].map((item) => {
                return (
                    item.serv_time.includes(time) && <span style={{ marginBottom: "2%" ,margin:"auto" }} className='col-4'><h6 className='first_capital'style={{fontWeight:"700"}}>{item.name}</h6>
                          <small>{item.quantity!==0&&item.quantity + "gram"}</small>{' '} {item.calorie!==0&&<small> {item.calorie + "cal"}</small>}
                    </span>
                )
            })}
            {treatChart["consumables"]&&treatChart["consumables"].length > 0 &&<><hr/><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Consumables</h6></>}
            {treatChart["consumables"] && treatChart["consumables"].length > 0 && treatChart["consumables"].map((item) => {
                return (
                    <span style={{ marginBottom: "2%" ,margin:"auto" }} className='col-4'><h6 className='first_capital'style={{fontWeight:"700"}}>{item.name}</h6>
                        <small>{item.quantity}</small>
                    </span>
                )
            })}
           { treatChart["scan"]&&treatChart["scan"].length > 0 &&<><hr/><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Scan</h6></>}
            {treatChart["scan"] && treatChart["scan"].length > 0 && treatChart["scan"].map((item) => {
                return (
                    <span style={{ marginBottom: "2%",margin:"auto"  }} className='col-4'><h6 className='first_capital'style={{fontWeight:"700"}}>{item.name}</h6>

                    </span>
                )
            })}
            { treatChart["test"]&&treatChart["test"].length > 0 &&<><hr/><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Test</h6></>}
            {treatChart["test"] && treatChart["test"].length > 0 && treatChart["test"].map((item) => {
                return (
                    <span style={{ marginBottom: "2%",margin:"auto" }} className='col-4'><h6 className='first_capital'style={{fontWeight:"700"}}>{item.name}</h6>

                    </span>
                )
            })}
            { treatChart["procedure"]&&treatChart["procedure"].length > 0 &&<><hr/><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Procedure</h6></>}
            {treatChart["procedure"] && treatChart["procedure"].length > 0 && treatChart["procedure"].map((item) => {
                return (
                    <span style={{ marginBottom: "2%",margin:"auto"  }} className='col-4'><h6 className='first_capital'style={{fontWeight:"700"}}>{item.name}</h6>

                    </span>
                )
            })}</ul>
        )
    }
    const displayOthers = (type) => {
        return (<ul className='row'style={{width:"96%"}}>
         
            {treatChart[type] && treatChart[type].length > 0 && treatChart[type].map((item) => {
                return (
                    <span  style={{ marginBottom: "2%",margin:"auto"  }} className='col-4'><h6 className='first_capital'style={{fontWeight:"700"}}>{item.name}</h6><br />

                    </span>
                )
            })}</ul>
        )
    }
    const display = (type, time) => {
        return (<ul className='row'style={{width:"96%"}}>
             <h6 className='first_capital' style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>{type}</h6>
            {treatChart[type] && treatChart[type].length > 0 && treatChart[type].map((item) => {
                return (
                    item.serv_time.includes(time) && <span style={{ marginBottom: "2%",margin:"auto"  }} className='col-4'><h6 className='first_capital' style={{fontWeight:"700"}}>{item.name}</h6>
                        {type === "liquid" ? <small>{item.quantity}ml</small> : type === "food" ?<> <small>{item.quantity!==0 && item.quantity + "gram"} </small>{" "}<small>{item.calorie!==0&& item.calorie+ "cal"}</small> </>: ""}
                    </span>
                )
            })}</ul>
        )
    }
    return (
        <div className='container' style={{ padding:"2%", backgroundColor:"rgba(88, 182, 222, 0.15)" }}>
            <div className='row' style={{paddingBottom:"1%"}}>
                {/* <div className='col-6' style={{display:"flex"}}>
                    <div className='round-button'><button onClick={() => { getPrevDate(date) }} style={{ background: "none" }} disabled={date1 === new Date().toISOString().slice(0, 10)}><i class="fa fa-angle-left" aria-hidden="true"></i></button></div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div onClick={() => { setItem("") }} className=' ' style={{alignContent: "center", alignItems: "center", textAlign: "center",paddingTop:"1%" }}>
                    {date1 === new Date().toISOString().slice(0, 10) ? <span className='day-head'>Today</span>
                        : <span className='day-head'>{date1}</span>}
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className=''>
                    <div className='round-button'><button onClick={() => { getNextDate(date) }} style={{ background: "none" }}><i class="fa fa-angle-right" aria-hidden="true"></i></button></div>
                </div>
                </div> */}
                 <div className="col-6 row ">
            {/* <div className="col-1"></div> */}
            <div className='col-1'><button onClick={() => { getPrevDate(date) }} style={{ background: "none" }}disabled={date1 === new Date().toISOString().slice(0, 10)} >{date1 === new Date().toISOString().slice(0, 10)?<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g fill="gray"><path fill-rule="evenodd" d="M13.5 26C20.404 26 26 20.404 26 13.5S20.404 1 13.5 1S1 6.596 1 13.5S6.596 26 13.5 26m0-2C19.299 24 24 19.299 24 13.5S19.299 3 13.5 3S3 7.701 3 13.5S7.701 24 13.5 24" clip-rule="evenodd" opacity="0.2"/><path fill-rule="evenodd" d="m14.453 14l4.2-5.04c1.28-1.536-1.025-3.457-2.305-1.92l-4.957 5.948c-.256.272-.42.63-.418 1.012c-.002.382.162.74.418 1.012l4.957 5.948c1.28 1.537 3.585-.384 2.304-1.92z" clip-rule="evenodd" opacity="0.2"/><path d="m15.384 18.68l-5-6l-.768.64l5 6z"/><path d="m14.616 19.32l-5-6c-.427-.512.341-1.152.768-.64l5 6c.427.512-.341 1.152-.768.64"/><path d="m14.616 6.68l-5 6l.768.64l5-6z"/><path d="m15.384 7.32l-5 6c-.427.512-1.195-.128-.768-.64l5-6c.427-.512 1.195.128.768.64"/><path d="M4.15 4.878a.514.514 0 0 1 .728-.727l16.971 16.971a.514.514 0 0 1-.727.727z"/><path fill-rule="evenodd" d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5S1.5 6.649 1.5 13S6.649 24.5 13 24.5m0 1c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5S.5 6.096.5 13S6.096 25.5 13 25.5" clip-rule="evenodd"/></g></svg>:
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g fill="currentColor"><path fill-rule="evenodd" d="M13.5 26C20.404 26 26 20.404 26 13.5S20.404 1 13.5 1S1 6.596 1 13.5S6.596 26 13.5 26m0-2C19.299 24 24 19.299 24 13.5S19.299 3 13.5 3S3 7.701 3 13.5S7.701 24 13.5 24" clip-rule="evenodd" opacity=".2" /><path fill-rule="evenodd" d="m14.453 14l4.2-5.04c1.28-1.536-1.025-3.457-2.305-1.92l-4.957 5.948c-.256.272-.42.63-.418 1.012c-.002.382.162.74.418 1.012l4.957 5.948c1.28 1.537 3.585-.384 2.304-1.92z" clip-rule="evenodd" opacity=".2" /><path d="m15.384 18.68l-5-6l-.768.64l5 6z" /><path d="m14.616 19.32l-5-6c-.427-.512.341-1.152.768-.64l5 6c.427.512-.341 1.152-.768.64" /><path d="m14.616 6.68l-5 6l.768.64l5-6z" /><path d="m15.384 7.32l-5 6c-.427.512-1.195-.128-.768-.64l5-6c.427-.512 1.195.128.768.64" /><path fill-rule="evenodd" d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5S1.5 6.649 1.5 13S6.649 24.5 13 24.5m0 1c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5S.5 6.096.5 13S6.096 25.5 13 25.5" clip-rule="evenodd" /></g></svg>}</button></div>

            <div  className=' col-4' style={{ textAlign:"center" ,fontSize: "18px" }}>
              {date1 === new Date().toISOString().slice(0, 10) ? <span className='day-head'>Today</span>
                : <span className='day-head'>{date1}</span>}
            </div>


            <div className='col-1'><button onClick={() => { getNextDate(date) }} style={{ background: "none" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g fill="currentColor"><path fill-rule="evenodd" d="M13.5 26C20.404 26 26 20.404 26 13.5S20.404 1 13.5 1S1 6.596 1 13.5S6.596 26 13.5 26m0-2C19.299 24 24 19.299 24 13.5S19.299 3 13.5 3S3 7.701 3 13.5S7.701 24 13.5 24" clip-rule="evenodd" opacity=".2" /><path d="M18.812 14.729a1.49 1.49 0 0 1-.177.252l-4.983 5.98a1.5 1.5 0 0 1-2.304-1.921l4.2-5.04l-4.2-5.04a1.5 1.5 0 1 1 2.304-1.92l5 6a1.5 1.5 0 0 1 .16 1.689" opacity=".2" /><path d="M10.116 7.32a.5.5 0 1 1 .768-.64l5 6a.5.5 0 0 1-.768.64z" /><path d="M10.884 19.32a.5.5 0 0 1-.768-.64l5-6a.5.5 0 1 1 .768.64z" /><path fill-rule="evenodd" d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5S1.5 6.649 1.5 13S6.649 24.5 13 24.5m0 1c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5S.5 6.096.5 13S6.096 25.5 13 25.5" clip-rule="evenodd" /></g></svg></button></div>
          </div>
                <div className='col-3'></div>
               <div className='col-3'> <button disabled={login_datas.user_type !=="hospital_doctor" && login_datas.user_type !== "hospital_allied_prof"} onClick={() => { navigate(`/treat-plan/${props.id}`, { state: { cat:"medicine" } }) }} style={{ margin: "0px", backgroundColor: "rgba(43, 147, 160, 1)",color:"white" ,padding:"4%"}} className='treat-button'><b>Manage prescription</b></button></div>
            </div>

            <div className='row' style={{ paddingTop: "1%", display: "flex",backgroundColor:"white" }}>
                <div className='col-4'>
                    <button style={{  backgroundColor:item!=="consumables"&&item!=="others"&& morning && "rgb(60, 104, 158)",color:item!=="consumables"&&item!=="others"&& morning && "white",margin:"3%" }} onClick={() => { setMorning(true); setAfternoon(false); setEvening(false); setNight(false);setTimeperiod("morning"); }} className='treat-button'>Morning</button>
                    <button style={{ backgroundColor: item!=="consumables"&&item!=="others"&&afternoon && "rgb(60, 104, 158)",color:item!=="consumables"&&item!=="others"&&afternoon &&"white",margin:"3%"  }} onClick={() => { setMorning(false); setAfternoon(true); setEvening(false); setNight(false);setTimeperiod("afternoon")  }} className='treat-button'>Afternoon</button>
                    <button style={{ backgroundColor: item!=="consumables"&&item!=="others"&&evening && "rgb(60, 104, 158)",color:item!=="consumables"&&item!=="others"&&evening && "white",margin:"3%"  }} onClick={() => { setMorning(false); setAfternoon(false); setEvening(true); setNight(false);;setTimeperiod("evening")  }} className='treat-button'>Evening</button>
                    <button style={{ backgroundColor: item!=="consumables"&&item!=="others"&&night && "rgb(60, 104, 158)",color:item!=="consumables"&&item!=="others"&&night&& "white",margin:"3%"  }} onClick={() => { setMorning(false); setAfternoon(false); setEvening(false); setNight(true);setTimeperiod("night")  }} className='treat-button'>Night</button>
                </div>

                {/* <div className='col-3 treat-first-div treat-div'>
                    <button style={{paddingTop:"2%", color: item === "medicine" &&  "white", backgroundColor: item === "medicine" && "rgb(60, 104, 158)",margin:"4%" }} onClick={() => { setItem("medicine") }} className='treat-button'>Medicines</button>
                    <button style={{ color: item === "liquid" &&  "white", backgroundColor: item === "liquid" && "rgb(60, 104, 158)",margin:"4%" }} onClick={() => { setItem("liquid") }} className='treat-button'>Liquid</button>
                    <button style={{ color: item === "food" && "white",  backgroundColor: item === "food" && "rgb(60, 104, 158)",margin:"4%" }} onClick={() => { setItem("food") }} className='treat-button'>Food</button>
                    <button style={{ color: item === "consumables" && "white",  backgroundColor: item === "consumables" && "rgb(60, 104, 158)" ,margin:"4%"}} onClick={() => { setItem("consumables") }} className='treat-button'>Consumables</button>
                    <button style={{ color: item === "others" && "white",  backgroundColor: item === "others" && "rgb(60, 104, 158)",margin:"4%" }} onClick={() => { setItem("others") }} className='treat-button'>Others</button>
                </div> */}

                <div className='col-8' >

                    <SimpleBar style={{ marginTop:"1.8%",paddingTop:"1%",maxHeight: "210px",borderRadius: "5px",minHeight:"210px",border: "1px solid rgba(207, 207, 207, 1)"}}>
                        {item === "others" ?

                            <>
                            <h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Others</h6>
                            {treatChart["scan"]&&treatChart["scan"].length > 0 &&<span ><><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Scan</h6></>{displayOthers("scan")}</span>}<br />
                                {treatChart["test"]&&treatChart["test"].length > 0 &&<span><><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Test</h6></>{displayOthers("test")}</span>}<br/>
                                {treatChart["procedure"]&&treatChart["procedure"].length > 0 &&<span><><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Procedure</h6></>{displayOthers("procedure")}</span>}<br/>
                            </> :
                             item === "consumables" ?
                             <><h6 style={{textDecoration: "underline",color:"rgb(43, 147, 160)" }}>Consumables</h6>
                             {treatChart["consumables"]&&treatChart["consumables"].length > 0 &&<span ><></>{displayOthers("consumables")}</span>}<br />
                                 
                             </> :
                             morning ? (
                                item === "medicine" ? display("medicine", "morning")
                                    : item === "food" ? display("food", "morning")
                                        : item === "liquid" ? display("liquid", "morning")
                                           
                                                : displayAll("morning")) :
                                afternoon ? (
                                    item === "medicine" ? display("medicine", "afternoon")
                                        : item === "food" ? display("food", "afternoon")
                                            : item === "liquid" ? display("liquid", "afternoon")
                                               
                                                    : displayAll("afternoon")) :
                                    evening ? (
                                        item === "medicine" ? display("medicine", "evening")
                                            : item === "food" ? display("food", "evening")
                                                : item === "liquid" ? display("liquid", "evening")
                                                   
                                                        : displayAll("evening")) :
                                        night ? (
                                            item === "medicine" ? display("medicine", "night")
                                                : item === "food" ? display("food", "night")
                                                    : item === "liquid" ? display("liquid", "night")
                                                        
                                                            : displayAll("night")) : ""}
                    </SimpleBar>
                </div>
            </div>
        </div>

    )
}

export default TreatPlan

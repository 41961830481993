import React, { useState, useEffect } from "react";
import "./addtocart.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import LoadingOverlay from 'react-loading-overlay';
import { Badge } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useAlert } from "react-alert";
function AddToCart({ data, handle }) {
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const [date1, setDate1] = useState(new Date());
  const [carddata, setCartData] = useState({

    DueDate: "",
    quantity: "",

  });
  const [distance, setDistance] = useState(0.0);
  const [submitMsg, setSubmitMsg] = useState("");
  const minDate = new Date();
  const [errorShow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [curDate, setCurDate] = useState(new Date());
  const [dateval, setDateVal] = useState("");
  const alert = useAlert();
  const [dueDate, setDueDate] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);

  // const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    if (date !== null) {

      let datenew = date;

      setCartData((currentstate) => ({
        ...currentstate,
        DueDate: datenew,
      }));
    }
  };

  useEffect(() => {
    
    if (login_datas.latitude && login_datas.longitude) {

      const tokenString = sessionStorage.getItem("usertoken");


      let v2 = tokenString.replace(
        /["]+/g,
        ""
      );
      axios.get(`${APIURL}/api/v1/patient/service-provider-detail/?hospital_id=${data.hospital}`, {
        headers: { Authorization: "Token " + v2 },
      })
        .then((res) => {
       
          let hospital = res.data.hosp_details;
          if (hospital.latitude && hospital.longitude) {
          
            calculateDistance(login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
          }

        })
        .catch((err) => {

        })
    }
  })

  const calculateDistance = (latt1, latt2, long1, long2) => {

    var R = 6371; // Radius of the earth in km
    const lat1 = parseFloat(latt1)
    const lat2 = parseFloat(latt2)
    const lon1 = parseFloat(long1)
    const lon2 = parseFloat(long2)
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km

    setDistance(parseFloat(d).toFixed(4));
   

  };
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }

  function SubmitPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps">{submitMsg}</h4>
        </Modal.Body>

      </Modal>
    );
  }

  const refreshPage = () => {
    window.location.reload(false);
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {errorMsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleChangeRadio = (event) => {


    if (event.target.value == 1) {
      setDateVal('');
      let dateUpdated1 = curDate;
      if (curDate !== null) {
        const offset = curDate.getTimezoneOffset();

        // dateUpdated1 = new Date(curDate.getTime() - offset * 60 * 1000);

        // let DateExtracted1 = dateUpdated1.toISOString().split("T")[0];
        setCartData((currentstate) => ({
          ...currentstate,
          DueDate: dateUpdated1,
        }));
      }
    }
    if (event.target.value == 2) {

      setDateVal(event.target.value);

    }


  }


  const handleDate = (date) => {
    setDate1(date)

  }

  const addCart = () => {

    
    const tokenStr = sessionStorage.getItem("usertoken");

    if (tokenStr) {
      const v = tokenStr;
      let v2 = v.replace(/["]+/g, "");

      if (!carddata.DueDate) {
        setErrorMsg("Please Select Delivery Date!!!");
        setErrorSubmit(true);

      } else {


        const dataTosend = {

          item_service: data.id,
          item_due_date: new Date(carddata.DueDate).toISOString().split("T")[0],
          item_service_priority: "critical",
          geo_distance: distance
        };

        axios
          .post(`${APIURL}/api/v1/patient/cart/`, dataTosend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              alert.success(res.data.message);

              setTimeout(refreshPage, 1000);

            } else {
              alert.error(res.data.message);

            }
          })
          .catch((err) => {
            setErrorMsg("Error in submission");
          });
      }
    }
  }
  return (

    <>
      <div className="doctor-card15">
        <div className="card-header-color">
          <h2 className="card-header-text">SERVICE CART-{data.hospital_name}</h2>
        </div>
        <div className="doct-card">
          <br />
          <br />
          <h6 className="card-row">
            <span className="content-text"> PATIENT NAME</span>
            <span className="content-text"> {login_datas.name.toUpperCase()}</span>
          </h6>
          <br />
          <h6 className="card-row">
            <span className="content-text"> PRODUCT NAME</span>
            <span className="content-text"> {data.item_service_name.toUpperCase()}</span>
          </h6>

          <br />
          <h6 className="card-row">
            <span className="content-text"> TOTAL AMOUNT  </span>
            <span className="content-text">  <i class="fa fa-usd" aria-hidden="true"></i> {(data.amount * (100 - data.discount) / 100 * (100 + data.vat) / 100).toFixed(2)}  <span>&nbsp;</span> <Badge pill variant="danger" style={{ fontSize: '10px' }}>
              {data.vat}% VAT!
            </Badge> <Badge pill variant="primary" style={{ fontSize: '10px' }}>
                {data.discount}% OFF!
              </Badge></span>


          </h6>
          <br />






          <h6 className="card-row">
            <span className="content-text"> DELIVERY</span>
            <div className="input-layout">


              <label class="delivery-container">
                <span className="delivery-content">
                  <input type="radio" name="status" onChange={handleChangeRadio} value="1" />
                  <span class="checkmark"></span>
                </span>
                <span style={{ color: 'black' }}><b style={{ fontSize: "11px" }}>IMMEDIATE DELIVARY</b></span>
              </label>
            </div>
          </h6>
          <h6 className="card-row">
            <span className="content-text"> </span>
            <div className="input-layout">


              <label class="delivery-container">
                <span className="delivery-content">
                  <input type="radio" name="status" onChange={handleChangeRadio} value="2" />
                  <span class="checkmark"></span>
                </span>
                {dateval ? <>

                  <div
                    style={{ alignSelf: "flex-start", color: "black" }}

                  >

                    <DatePicker
                      value={carddata.DueDate}
                      onChange={(date) => {
                        handleDateChange(date);

                      }}
                      //onChange={handleDateChange}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      className="custom-datepicker"
                      style={{ color: 'black' }}
                    />
                  </div>
                </> :
                  <span style={{ color: 'black' }}><b style={{ fontSize: "12px" }}>SET DELIVERY DATE</b></span>}

              </label>
            </div>
          </h6>


          <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
            <button className="btn btn-col" onClick={() => { addCart() }} style={{ color: "white" }}>Add Service to Cart</button>
          </div>
        </div>
      </div>
      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : (
        ""
      )}
      {submitMsg !== "" ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            handle();
            setSubmitMsg("");
          }}
        />
      ) : null}
    </>

  );

}
export default AddToCart
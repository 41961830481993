import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { NavDropdown } from "react-bootstrap";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton, Badge,Button } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import DatePicker from "./Datepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import "../AdmissionReport/reports.css";
import isAfter from 'date-fns/isAfter';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { Modal } from "react-bootstrap";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { MdDownloadForOffline } from "react-icons/md";
import Nav from 'react-bootstrap/Nav';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import peaceImage from '../../../assets/images/icons/peacevillage.png'; 
function RemoteRequests() {
  const [timePeriod, setTimePeriod] = useState("month")
  const [load, setLoad] = useState('')
  const [doctorData, setDoctorData] = useState([])
  const [serviceData, setServiceData] = useState([])
  const [category, setCategory] = useState("all")
  //const history = useHistory();
  const [fromDate, setFromDate] = useState(moment((new Date(new Date().getFullYear(), new Date().getMonth(), 1))).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment((new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))).format('YYYY-MM-DD'));
  const [custom, setCustom] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [refresh,setRefresh]=useState(false);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [show, setShow] = useState(false);

  const [selectedValue, setSelectedValue] = useState(''); // You can set the initial selected value here
  const [currentDay, setCurrentDay] = useState(moment().format('YYYY-MM-DD'));
  const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
  const [monthsYear, setMonthsYear] = useState(moment().format('YYYY'));
  const [confirmed, setConfirmed] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedDownload, setSelectedDownload] = useState([]);
  

  useEffect(() => {
    
   
    setDoctorData([])
    apiCallFilter(1,10);
   setFromDate("")
   setToDate("")
   setSelectedValue("")
   

   
  }, [refresh]);

  useEffect(() => {
    if (fromDate) {
      apiCallFilter(1, perPage)
    }

  }, [fromDate, toDate]);
  const handleDownload = (option) => {
    setSelectedDownload((prevSelection) => {
      if (prevSelection.includes(option)) {
        return prevSelection.filter((selectedOption) => selectedOption !== option);
      } else {
        return [...prevSelection, option];
      }
    });
  };
  const handleDownloads = () => {
   

    // Perform actions based on selected download options
    selectedDownload.forEach((selectedOption) => {
      if (selectedOption === 'PDF') {

        const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Patient Remote Requests";
    const headers = [["Patient Name", "Order Type", "Date", "Total Amount", "Paid Status", "Status"]];

    const data = doctorData.map(elt => [elt.patient, elt.order_type, moment(new Date(elt.created_at)).format('YYYY-MM-DD'), elt.total_pay_amount, elt.paid_status === "paid" ? "Paid" : "Not Paid", elt.request_status]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
    } else if (selectedOption === 'CSV') {
        // Extract only the relevant information from registerData based on CsvRegisterheaders
        const filteredData = doctorData.map(elt => ({
          patine_name: elt.patient, 
          order_type: elt.order_type, 
          date: moment(new Date(elt.created_at)).format('YYYY-MM-DD'), 
          total_amount: elt.total_pay_amount, 
          payment_status: elt.paid_status === "paid" ? "Paid" : "Not Paid",
          request_status: elt.request_status
       }));
       
       // Generate CSV content
       const csvContent =
         CsvRegisterheaders.map((h) => h.label).join(',') +
         '\n' +
         filteredData.map((row) => Object.values(row).join(',')).join('\n');
       
       // Create a Blob and download the CSV file
       const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
       const url = URL.createObjectURL(blob);
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'report.csv');
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
    } 
  });

    // Reset selected downloads
    setSelectedDownload([]);

    setConfirmed(false)
  };

  

   const exportRegisterReport = () =>{
    setConfirmed(true)
 }
  const ApprovePopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        
      >
        <Modal.Header style={{height:'50px'}} closeButton>
          <Modal.Title  id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:'200px',display: 'flex', flexDirection: 'column', justifyContent: 'center',alignItems:'center'}}>
          <h4>Select the format you want to download</h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginBottom: '5px' }}>
              <input
                type="checkbox"
                checked={selectedDownload.includes('PDF')}
                onChange={() => handleDownload('PDF')}
              />
              PDF
            </label>
            
              <label  style={{ marginBottom: '5px' }}>
                <input
                  type="checkbox"
                  checked={selectedDownload.includes('CSV')}
                  onChange={() => handleDownload('CSV')}
                />
                CSV
              </label>
          
           </div>
           <button variant="primary" className='buttonfilter' style={{width: '100px',height: '32px', fontSize: '11px' }} onClick={handleDownloads}>
            <span>Download</span>
          </button>
        </Modal.Body>
        
      </Modal>
    );
    }

  useEffect(() => {
    if (category) {
      apiCallFilter(1, perPage)
    }

  }, [category]);


  const handleChangeRadio = (val) => {

    doctorData.splice(0, doctorData.length + 1)
    setCategory(val);


  }

 
  const handleChangeValue = (val) => {
    doctorData.splice(0, doctorData.length + 1)
    
      setCustom(false);
      var startOfWeek = moment().startOf('week').toDate();
      var endOfWeek = moment().endOf('week').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setFromDate(FormatedFirstday)
      setToDate(Formatedlastday)
      setSelectedValue('week');

    
  }

  const handleDayChange = (increment) => {
    doctorData.splice(0, doctorData.length + 1)
    setCustom(false);
    const newDate = moment(currentDay).add(increment, 'days');
  
    // Set maximum date to the current date
    if (newDate.isAfter(moment(), 'day')) {
      setCurrentDay(moment().format('YYYY-MM-DD'));
      return;
    }
  
    setFromDate(newDate.format('YYYY-MM-DD'));
    setToDate(newDate.format('YYYY-MM-DD'));
    setCurrentDay(newDate.toDate());

    setSelectedValue('day');
  };


  const handleMonthChange = (increment) => {
    doctorData.splice(0, doctorData.length + 1)
    const currentMoment = moment(`${monthsYear}-${currentMonth}`, 'YYYY-MMMM');
    const newMoment = currentMoment.add(increment, 'months');

    // Set minimum month to one year back
    const minMonth = moment().subtract(1, 'year').endOf('month')-1;
    if (newMoment.isSameOrBefore(minMonth, 'month')) {
      setCurrentMonth(minMonth.format('MMMM'));
      setMonthsYear(minMonth.format('YYYY'));
      return;
    }

    // Set maximum month to the current month
    if (newMoment.isAfter(moment(), 'month')) {
      setCurrentMonth(moment().format('MMMM'));
      setMonthsYear(moment().format('YYYY'));
      return;
    }

   
      const startOfMonth = newMoment.startOf('month').format('YYYY-MM-DD');
      const endOfMonth = newMoment.endOf('month').format('YYYY-MM-DD');
  
      setFromDate(startOfMonth);
      setToDate(endOfMonth);
   
    setCurrentMonth(newMoment.format('MMMM'));
    setMonthsYear(newMoment.format('YYYY'));

    setSelectedValue('month');

  };

  const generateYearOptions = () => {
    const years = [];
    for (let year = 2022; year <= currentYear; year++) {
      years.push(String(year));
    }
    return years;
  };

  const filterOptions = [ ...generateYearOptions()];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const handleFilterChange = (filter) => {
    setSelectedYears((prevYears) => {
      if (prevYears.includes(filter)) {
        // Deselect the checkbox
        return [];
      } else {
        // Select the checkbox and disable others
        return [filter];
      }
    });
  };
  

  const handleApplyFilters = () => {
    doctorData.splice(0, doctorData.length + 1)
    setCustom(false);    
    if (selectedYears.length > 0) {
      const sortedYears = selectedYears.sort((a, b) => Number(a) - Number(b));
  
      const startDate = sortedYears[0] + '-01-01';
      const endDate = sortedYears[selectedYears.length - 1] + '-12-31';
  
      setFromDate(startDate);
      setToDate(endDate);
  
  
    }
  
    setSelectedYears([]);
    setIsDropdownOpen(false);
    setSelectedValue('');

  };


  const apiCallFilter = (page, per_page) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const a = fromDate;
    const b = toDate;
    let type = ""
    if (category === "all") {
      type = ""
    } else {
      type = category
    }
    axios.get(
      `${APIURL}/api/v1/service-provider/hospital-remote-requests/?start_date=${a}&end_date=${b}&type=${type}&page=${page}&per_page=${per_page}&${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          setLoad('')
          setHasNext(res.data.has_next)
          let datalist = res.data.orders;
          setDoctorData([...doctorData, ...datalist]);


          setTotalRows(res.data.total_records)
        }
        else {
          setLoad('Error in Fetching Data')
        }

      })

      .catch(err => {
        setLoad('Error in Fetching Data2')
      })

  }
  const handleEvent = (event, picker) => {
  setCustom(true)
    doctorData.splice(0, doctorData.length + 1)
    const a = moment((event[0])).format('YYYY-MM-DD');
    let b = moment((event[1])).format('YYYY-MM-DD');

    setFromDate(a);
    setToDate(b);

  }


  const renderTable = (cellInfo) => {
    return (
      <div style={{ color: "black",textTransform:"capitalize" }}>{doctorData[cellInfo.index][cellInfo.column.id]==="master_medicine"?"Medicine"
      :doctorData[cellInfo.index][cellInfo.column.id]==="master_consumable"?"Consumable"
      :doctorData[cellInfo.index][cellInfo.column.id]==="master_procedure"?"Procedure"
      :doctorData[cellInfo.index][cellInfo.column.id]==="master_scan"?"Scan"
      :doctorData[cellInfo.index][cellInfo.column.id]==="master_labtest"?"Lab Test"
      :doctorData[cellInfo.index][cellInfo.column.id]==="misc"?"Miscellaneous"
      :doctorData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }
  const renderPaymentStatus = (cellInfo) => {
    return (
      <div style={{ color: doctorData[cellInfo.index][cellInfo.column.id] === "not_paid" ? "red" : "green" }}>{doctorData[cellInfo.index][cellInfo.column.id] === "not_paid" ? "Not Paid" : "Paid"}</div>
    )
  }

  const renderDate = (cellInfo) => {
    let dateString = doctorData[cellInfo.index][cellInfo.column.id]
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div style={{ color: "black" }}>
        {updatedDate.slice(4, 16)}

      </div>
    )
  }

  const columns = [
    {
      Header: "Patient Name",
      accessor: "patient",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },

    },
    {
      Header: "Order ID",
      accessor: "order_number",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },


    },
    {
      Header: "Order Type",
      accessor: "order_type",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },


    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: renderDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,


    },
    {
      Header: "Total Amount",
      accessor: "total_pay_amount",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,


    },
    {
      Header: "Paid Status",
      accessor: "paid_status",
      Cell: renderPaymentStatus,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,


    },
    {
      Header: "Status",
      accessor: "request_status",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },


    },
  ]
  const handlePageChange = page => {
    if (hasNext) {
      apiCallFilter(page + 1, perPage);
    }
  }

  const handleRefresh =(e)=>{
    
    e.preventDefault();
   
    setDoctorData([]);
    
    setPage(1);
    setFromDate("")
    setToDate("")
    setRefresh(!refresh)
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage)
    doctorData.splice(0, doctorData.length + 1)
    apiCallFilter(1, newPerPage);

  }
  useEffect(() => {
    apiCallFilter(page, perPage);
  }, [perPage]);

  const display = <ReactTable
    columns={columns}
    data={doctorData}
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    defaultPageSize={perPage - 1}
    className='calllog-table'
    resizable
    pagination
    paginationServer
    onPageChange={(pageIndex) => { handlePageChange(pageIndex); setPage(pageIndex + 1) }}
    paginationTotalRows={totalRows}
    onPageSizeChange={handlePerRowsChange}
  ></ReactTable>



  const CsvRegisterheaders = [
    { label: "Doctor Name", key: "doctor" },
    { label: "Procedure Name", key: "procedure" },
    { label: "Total Amount", key: "amount" },
    { label: "Procedure Status", key: "procedure_status" },
    { label: "Date", key: "date" }


  ];


  return (
    <>
<div className="patient-report"> 
  <Nav className="row" style={{ marginTop: "1%",alignContent:'center',boxSizing: 'border-box' }}>
    <span className="col-6" style={{ width: '35.7%' }}>  
      <button className="buttonfilter" style={{ marginTop: "1%", borderRadius: "25px", width: "90px", background: "#1269B9",fontSize:"11px" }} onClick={(e) => handleRefresh(e)}>
        <span>Refresh &nbsp;<i className="fa fa-refresh" aria-hidden="true" style={{ color: "white" }}></i> </span>
      </button> 
    </span>
    <span className="col-6 togglegroup" style={{display:'flex', marginBottom: "1%", textAlign: "right", float: 'right' }}>
      <ToggleButtonGroup style={{ marginTop: "1%", fontSize: "small"}} type="radio" name="options" >
        <DateRangePicker
          appearance="default"              
          placeholder={"Select Date Range"}  
                         
          onChange={handleEvent}
          disabledDate={(date) => isAfter(date, new Date())}
        >
          <ToggleButton id="tbg-radio-4" value={'custom'} className="btn btn-light toggle-btns custombtn1" style={{ border: 'none',fontSize:'11px'}}></ToggleButton>
        </DateRangePicker>&nbsp;&nbsp;&nbsp;
        
        <div style={{ display: 'flex', alignItems: 'center', height: '30px', fontSize: '11px', paddingTop: '0px', paddingBottom: '0px', border: `1px solid ${selectedValue === 'day' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }}>
          <button className='arrowButton'  onClick={() => handleDayChange(-1)}>
            {<AiFillCaretLeft />}
          </button>
          <span style={{ margin: '0 8px', width: '50px', textAlign: 'center', fontSize:'11px' }}>
            {moment(currentDay).isSame(moment(), 'day') ? 'Today' : moment(currentDay).format('YYYY/MM/DD')}
          </span>
          <button className='arrowButton' onClick={() => handleDayChange(1)}>
            {<AiFillCaretRight />}
          </button>
        </div>&nbsp;&nbsp;&nbsp;
        
        <div style={{ display: 'flex', alignItems: 'center',height: '30px', fontSize: '11px', fontWeight: '400px', border: `1px solid ${selectedValue === 'month' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }}>
          <button className='arrowButton' onClick={() => handleMonthChange(-1)}>
            {<AiFillCaretLeft />}
          </button>
          <span style={{ margin: '0 4px', width: '50px', textAlign: 'center' }}>{currentMonth}</span>
          <button className='arrowButton' onClick={() => handleMonthChange(1)}>
            {<AiFillCaretRight />}
          </button>
        </div>&nbsp;&nbsp;&nbsp;
        <button   
          style={{ display: 'flex',justifyContent:'center',alignItems:'center', height: '30px',width:'50px', fontSize: '11px',backgroundColor:'white', fontWeight: '400px', border: `1px solid ${selectedValue === 'week' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }} 
          onClick={() => handleChangeValue('week')}
        > 
          <span>Week</span>
        </button>&nbsp;&nbsp;&nbsp;
        <div style={{ position: 'relative' }}>
      <button
        className="buttonfilter" 
        style={{ borderRadius: "20px",height: '32px', width: '60px', fontSize: '11px', background: isDropdownOpen ? "rgb(13, 197, 197)" : " #1269B9" }}
        onClick={toggleDropdown}
      ><span>
        Filters
        </span>
      </button>
      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            width:'100px',
            alignItems:'center',
          }}
        >
          {filterOptions.map((filter) => (
            <label key={filter} style={{ marginBottom: '5px' }}>
              <input
                type="checkbox"
                checked={selectedYears.includes(filter)}
                onChange={() => handleFilterChange(filter)}
                //disable others if one is selected
                disabled={selectedYears.length > 0 && !selectedYears.includes(filter)}
              />&nbsp;
              {filter}
            </label>
          ))} 
          <button disabled={selectedYears.length===0}style={{ marginTop: '10px' }} onClick={handleApplyFilters}>
            Apply
          </button>
        </div>
      )}
      </div>&nbsp;

        
        
        
        <button className='buttonfilter' style={{ width: '100px',height: '32px', fontSize: '11px' }} onClick={exportRegisterReport}>
          <span>Download  <MdDownloadForOffline /></span>
        </button>
      </ToggleButtonGroup> 
    </span>    
  </Nav>


        <div className="service-table-holder" style={{  }}> {load === '' ? display
          : load === 'Error in Fetching Data' ? <h6 style={{ color: "red" }}>{load}</h6> : <h6 style={{ color: "grey" }}>{load}</h6>}
        </div>
      </div>
      <br />
      {confirmed ? (
      <ApprovePopUp
        show={confirmed}
        onHide={() => {
          setConfirmed(false);
        }}
      />
    ) : null}
    </>

  )
}

export default RemoteRequests

import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import printJS from "print-js";
import ZoomableImage from '../../BDO/Admission/ZoomableImage';
import pv from "../../../assets/images/Peace-Village-Logo.png";
import moment from "moment";
function WoundForm(props) {
    const [patientDetails, setPatientDetails] = useState(props.patient)
    const [info, setInfo] = useState("")
    const [show, setShow] = useState(false);
    
    return (

        <div className='container'>
             
            <svg style={{ marginLeft: "95%", }} onClick={() => { printJS("printablediv", "html") }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#245693" d="M18 17.5v1.25A2.25 2.25 0 0 1 15.75 21h-7.5A2.25 2.25 0 0 1 6 18.75v-1.251l-1.75.001A2.25 2.25 0 0 1 2 15.25V9.254a3.25 3.25 0 0 1 3.25-3.25l.749-.001L6 5.25A2.25 2.25 0 0 1 8.25 3h7.502a2.25 2.25 0 0 1 2.25 2.25v.753h.75a3.254 3.254 0 0 1 3.252 3.25l.003 5.997a2.249 2.249 0 0 1-2.248 2.25H18Zm-2.25-4h-7.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h7.5a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75Zm.002-9H8.25a.75.75 0 0 0-.75.75l-.001.753h9.003V5.25a.75.75 0 0 0-.75-.75Z" /></svg>
            <Print><div id="printablediv">
                <div className='row'>
<div className='col-6'> <img src={pv}  width="60%" height="60%" alt="image" /></div>
<div className='col-6'>
     <div><span>Peace Village Foundation Charitable Trust </span></div>
     <div><span>Pinangode P.O, Wayanad, Kerala, Malta</span></div>
     <div><span>Phone: 04936 293136  Mobile: +91 8281345535 </span></div>
     <div><span>www.peacevillage.in Email:info@peacevillage.com </span></div>
    </div>
                </div>
                <br/>
                <div className=''>
                    
                <h5 style={{textDecoration:"underline", textAlign:"center",fontSize:"22px"  }}>
                    <b>Wound reports</b>

                </h5>
                
                </div><br/>


                <div  className='row'>
                   <div className='col-2 doc-right'>Type</div>
                   <div className='col-3 doc-right'>General</div>
                   <div className='col-3 doc-right'>Assessment</div>
                   <div className='col-3 doc-right'>Action</div>
                   <div className='col-1 doc-right'>Status</div>

                  
                </div>
               {props.woundInfo.map(wound => {
                return(<>
                <div  className='row'>
               <div className='col-1 doc-right'><span>{wound.type}</span></div>
                   <div className='col-4 doc-right'>
                   {wound.id&&wound.degree!==null&&<><span>Degree:{wound.degree}</span><br/></>}
                   {wound.id&&wound.decubitus_risk!==null&&<><span>Range for risk of decubitus:{wound.decubitus_risk}</span><br/></>}
                   {wound.id&&wound.points!==null&&<><span>Remarks:{wound.points}</span><br/></>}
                   {wound.id&&wound.treatment_todate!==null&&<> <span>Treatment To Date:{wound.treatment_todate}</span><br/></>}
                   {wound.id&&wound.healing_factors.length>0&&<> <span>Influential Factors of wound healing:{wound.healing_factors.map((item,index)=>{return(<span className="" >{item.replace(/_/g, ' ')}{index!==wound.healing_factors.length-1?", ":" "}</span>)})}</span><br/></>}
                    <span>Medicaments:{wound.medicaments}</span>
                    {wound.id&&wound.pain!==null&&<><span>Pain:{wound.pain.replace(/_/g, ' ')}</span></>}
                    </div>
                   <div className='col-3 doc-right'>
                   {wound.id&&wound.localisation!==null&&<> <span>Localisation:{wound.localisation}</span><br/></>}
                   {wound.id&&wound.size!==null&&<><span>Size:{wound.size}mm</span><br/></>}
                   {wound.id&&wound.width!==null&&<><span>Width:{wound.width}mm</span><br/></>}
                   {wound.id&&wound.length!==null&&<><span>Length:{wound.length}mm</span><br/></>}
                   {wound.id&&wound.depth!==null&&<><span>Depth:{wound.depth}mm</span><br/></>}
                   {wound.id&&wound.condition!==null&&<><span>Condition of Wound:{wound.condition.replace(/_/g, ' ')}</span><br/></>}
                   {wound.id&&wound.edges!==null&&<><span>Wound Edges:{wound.edges.replace(/_/g, ' ')}</span><br/></>}
                   {wound.id&&wound.exudate!==null&&<><span>Wound Exudate:{wound.exudate.replace(/_/g, ' ')}</span><br/></>}
                   {/* {wound.id&&wound.phase!==null&&<> <span>Phases of wound healing:{wound.phases.replace(/_/g, ' ')}</span><br/></>} */}
                   </div>
                   <div className='col-3 doc-right'>
                   {wound.id&&wound.treatment_by!==null&&<><span>Treatment (Doctor/Specialisation):{wound.treatment_doc}</span><br/></>}
                   {wound.id&&wound.cleansing!==null&&<><span>Wound Cleansing:{wound.cleansing}</span><br/></>}
                   {wound.id&&wound.dressing!==null&&<><span>Wound Dressng:{wound.dressing}</span><br/></>}
                   {wound.id&&wound.other!==null&&<> <span>Other:{wound.other}</span><br/></>}
                   {wound.id&&wound.action_planned!==null&&<><span>Action to be taken:{wound.action_planned.replace(/_/g, ' ')}</span><br/></>}
                    
                   </div>
                   <div className='col-1 doc-right'><span>{wound.status}</span></div>
                   
                </div>
                <div className='row'>
                <div className='col-4'></div>
<div className='col-8'>......................................................................................</div>
              </div><br/><br/><br/><br/></>
                )})}
                
            </div>
            </Print>
           

           
        </div>

    )
}
export default WoundForm;
import React, { useState } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useAlert } from "react-alert";
function HeightAdd(props) {
  const alert = useAlert();
  const [weight, setWeight] = useState(props.seteditweight !== "" ? props.seteditweight : "")
  const [height, setHeight] = useState(props.seteditheight !== " " ? props.seteditheight : " ")
  // const [modalShow, setModalShow]=useState(false)
  // const [errorShow, setErrorShow]= useState(false)
  // const [limitShow , setLimitShow]= useState(false)

  const handleChange = (e) => {
    const val = e.target.value
    if (val.length < 6) {
      setHeight(e.target.value)
    }
    else {
      return false
    }
  }

  const handleSubmit = () => {

    if (height <= 280) {

      const id = props.id
      const tokenString = sessionStorage.getItem('usertoken');

      let str = tokenString.replace(/["]+/g, '');


      const dataToUpdate =
      {
        "height": height
      }



      axios.put(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`, dataToUpdate, {
        headers: {

          "Authorization": 'Token ' + str,
          'Content-Type': 'application/json'
        }


      })
        .then(res => {

          if (res.data.status === "success") {
            alert.success("Height added Successfully")
            props.onSuccess()

          }
          else {
            alert.error("Error in data submission.Please check again!!")

          }

        })


      if (weight > 1 && weight <= 500 && height > 1 && height <= 280) {
        let bmi = (weight / ((height * height) / 10000)).toFixed(2);

        const bmidata = {
          'patient_id': id,
          'weight': parseFloat(weight).toFixed(1),
          'height': parseFloat(height).toFixed(1),
          'bmi': parseFloat(bmi).toFixed(1)
        }
        axios.post(`${APIURL}/api/v1/patient/patient-bmi-calculation/`, bmidata, {
          headers: {
            "Authorization": 'Token ' + str,
            'Content-Type': 'application/json'
          }
        })
          .then(res => {

          })
          .catch(err => {

          })

      }




    }
    else {
      alert.error("Please check height value")
    }


  }




  return (
    <div className='row'>
      <input type="number" min='0' max='250' placeholder="Add height in cm" value={height} onChange={handleChange} className="enquiryinput col-8" />
      <span className='col-1'></span>
      <button disabled={height < 1 || height === undefined} onClick={handleSubmit} style={{
        borderRadius: "10px",
        backgroundColor: "rgba(43, 147, 160, 1)",
        textAlign: "center",
        color: "white",

      }} className=' col-2'>Add</button>

    </div>
  )
}

export default HeightAdd

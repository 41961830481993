import React,{useState,useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { useAlert } from "react-alert";
function EditTest(props) {
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const alert = useAlert();
  const [selectedImage, setSelectedImage] = useState(null);
    const[data, setData]= useState({
      id: props.data.id,
      testname: props.data.test_name,
        testsample: props.data.test_sample,
        testmethod: props.data.test_method,
        unit: props.data.unit,
        minrefrange: props.data.min_ref_range,
        maxrefrange: props.data.max_ref_range,
        cptcode: props.data.cpt_code,
        description: props.data.description,
       disease: props.data.asso_disease,
       image:props.data.image
    })
   const value = props.data.group ? props.data.group.toLowerCase() : '';
    const label = props.data.group ? props.data.group.toUpperCase() : '';
    const [Agegroup,setAgegroup]=useState({value:data.agegroup,label:data.agegroup})
    const [group, setGroup]= useState({value:value, label: label})
    const [errorMsg, setErrorMsg] = useState("");
   const [country1, setCountry1]= useState({value:props.data.country, label: props.data.country}
    )
    const [error, setError] = useState({
      testname: "",
      testsample: "",
      testmethod: "",
      unit: "",
      minrefrange: "",
      maxrefrange: "",
      group: "",
      agegroup:'',
      country: "",
      //is_verified:""
    });

   // const [year, setYear]= useState(data.year_of_manufacture=== null ? '' : new Date(data.year_of_manufacture))
    //const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

  //  const [yearData, setYearData]= useState(data.year_of_manufacture)
    //const [expiryData, setExpiryData]= useState(data.expiry_date)
    const group1 = [
        { value: "male", label: "MALE" },
        { value: "female", label: "FEMALE" },
       
       
    
        { value: "others", label: "OTHERS" },
       
      ];
    
      const agegroupvalue = [{ value:'1', label:'Infants' },
      { value:'2', label:'kids' },{value:'3', label:'teen'},{value:'4', label:'young'},{value:'5', label:'adult'},{value:'6', label:'elderly'}
     
   ]

const handleServiceChange= (data) => {
  setGroup(data)
}

const handleAgeGroup= (data) => {

  setAgegroup(data)
}
const handleImageUpload = (event) => {
  const image = event.target.files[0];
  setData(prevData => ({...prevData, image: URL.createObjectURL(image)}));
  setSelectedImage(image);
};


      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }



const validate = () => {
  ;
  let input = data;
  let errors = {};
  let isValid = true;
  setError({});

  if (!input["testname"] || input["testname"] === "") {
    isValid = false;
    errors["testname"] = "Enter Test Name";
  }

  // if (typeof input["testname"] !== "undefined") {
  //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
  //   if (pattern3.test(input["testname"])) {
  //     isValid = false;
  //     errors["testname"] = "Special Characters are not allowed";
  //   }
  // }
  if (!input["testsample"] || input["testsample"] === "") {
    isValid = false;
    errors["testsample"] = "Enter test sample";
  }
  // if (typeof input["testsample"] !== "undefined") {
  //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
  //   if (pattern3.test(input["testsample"])) {
  //     isValid = false;
  //     errors["testsample"] = "Special Characters are not allowed";
  //   }
  // }
  if (!Agegroup || (input.Agegroup && !Agegroup.value) ) {
    isValid = false;
    errors["agegroup"] = "Select  age group";
  }
  if (!input["testmethod"] || input["testmethod"] === "") {
    isValid = false;
    errors["testmethod"] = "Enter test method";
  }
  // if (typeof input["testmethod"] !== "undefined") {
  //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
  //   if (pattern3.test(input["testmethod"])) {
  //     isValid = false;
  //     errors["testmethod"] = "Special Characters are not allowed";
  //   }
  // }
  if (!input["unit"] || input["unit"] === "") {
    isValid = false;
    errors["unit"] = "Enter unit";
  }
  if (!input["minrefrange"] || input["minrefrange"] === "") {
    isValid = false;
    errors["minrefrange"] = "Enter minimum refer range";
  }

  if (!input["maxrefrange"] || input["maxrefrange"] === "") {
    isValid = false;
    errors["maxrefrange"] = "Enter maximum refer range";
  }

  if (input["minrefrange"] && input["maxrefrange"] && parseFloat(input["minrefrange"]) > parseFloat(input["maxrefrange"])){
    isValid = false;
    errors["minrefrange"] = "Enter minimum refer range lesser than max";
  }

  if (!group1 || (input.group1 && !group1.value) ) {
    isValid = false;
    errors["group"] = "Select group";
  }
  // if (!input["country"] || input["country"] === "") {
  //   isValid = false;
  //   errors["country"] = "Select country";
  // }


  setError((currentstate) => ({
    ...currentstate,
    ...errors,
  }));

  return isValid;
};
const handleConfirm = () => {
  closeConfirmationPopup()
  saveChanges();
 
};

const handleCancel = () => {
  closeConfirmationPopup()
};
const handleContinue = () => {
  if(validate()){ 
    setShowConfirmation(true)
  };
}

const saveChanges = ()=> {
  
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     if (data.maxrefrange<=99999999999999999999){
      const formData = new FormData();
      if (selectedImage) {
        formData.append('image', selectedImage);
      } // Append the image file to the form data
    
       if(props.data.test_name!==data.testname){
        formData.append('test_name', data.testname);
       }
       if(props.data.test_sample!==data.testsample){
        formData.append('test_sample', data.testsample);
       }
       if(props.data.test_method!==data.testmethod){
        formData.append('test_method', data.testmethod);
       }
       if(props.data.unit!==data.unit){
        formData.append('unit', data.unit);
       }
       if(props.data.min_ref_range!==data.minrefrange){
        formData.append('min_ref_range', data.minrefrange);
       }
       if(props.data.max_ref_range!==data.maxrefrange){
        formData.append('max_ref_range', data.maxrefrange);
       }
       if(props.data.cpt_code!==data.cptcode){
        formData.append('cpt_code', data.cptcode);
       }
       if(props.data.description!==data.description){
        formData.append('description', data.description);
       }
       if(props.data.asso_disease!==data.disease){
        formData.append('asso_disease', data.disease);
       }
      
  formData.append('is_verified', false);
  
 
  

  if (country1 && country1.value) {
    formData.append('country', country1.value);
  }
  if (group && group.value&&(props.data.group.toLowerCase()!==group.value)) {
    formData.append('group', group.value);
  }
     axios
       .put(`${APIURL}/api/v1/staff/master-labtest-detail/${data.id}/`, formData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status==="success") {
          alert.success('Updated successfully');
          props.modalClose();
          setData({
            testname: "",
    testsample: "",
    testmethod: "",
    unit: "",
    minrefrange: "",
    maxrefrange: "",
    group: { value: "", label: "" },
    country1: { value: "", label: "" },
   // Agegroup:{value:'',label:''},  
    cptcode: "",
    description: "",
    disease: "",
         });}
          else {
            alert.error(res.data.message)
         }
       })
       .catch((err) => {
        // setErrorShow(true)
       });
    }
    else{
      alert.error("The max range value limited to 20 numbers")
    }
  
  }




    return (
        <>
       

    <h2 style={{ marginTop: "0" }} className="title-of-page">
        Edit Test
      </h2>
      <div style={{display:"flex" , marginLeft: "4rem", marginRight: "4rem", overlap:"hidden", padding: "0 10px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {data.image && (
         <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: "5px"  }}>
          <img src={data.image} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
      <div style={{ maxHeight: "1000px", width: "90%" }} className="config-box fit-content">
        <div className="feature-data pad-features">
          <div className="flex-col justify-feature">
            <span className="align-rental">* Test Name: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testname"
              value={data.testname}
              onChange={handleTitle}
            />
           {error.testname ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testname}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">* Test Method : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testmethod"
             
              value={data.testmethod}
              onChange={handleTitle}
            />
            {error.testmethod ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testmethod}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">* Test Sample : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testsample"
             
              value={data.testsample}
              onChange={handleTitle}
            />
            {error.testsample ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testsample}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Unit: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="unit"
              min="0"
              max="100"
              value={data.unit}
              onChange={handleTitle}
            />
           {error.unit ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.unit}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Min Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="minrefrange"
              
              value={data.minrefrange}
              onChange={handleTitle}
            />
            {error.minrefrange ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.minrefrange}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Max Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="maxrefrange"
              min = "0"
              max = "100"
              value={data.maxrefrange}
              onChange={handleTitle}
            />
            {error.maxrefrange ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.maxrefrange}
              </div>
            ) : null}
          </div>
         <div className="flex-col justify-feature">
            <span className="align-rental">CPT Code: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="cptcode"
              value={data.cptcode}
              onChange={handleTitle}
            />
           
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Associate Disease: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="disease"
              value={data.disease}
              onChange={handleTitle}
            />
             
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Description: </span>
            <textarea
              type="text"
              className="form-control title-flex"
              name="description"
              value={data.description}
              onChange={handleTitle}
            />
            
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">* Group: </span>
            <Select
              defaultValue={group}
              onChange={(data, e) => {
                handleServiceChange(data, e);
              }}
              options={group1}
              name="group1"
              className="select-currency select-flex title-flex"
            />
            {  error.group ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.group}
              </div>
            ) : null}
          </div>
          {/* <div className='flex-col justify-feature'>
                <span className='align-rental'>*Age Group: </span>
                <Select
                  defaultValue={Agegroup}
                  onChange={(data,e)=>{handleAgeGroup(data,e)
                  }}
                  options={agegroupvalue}
                  name='group2'
                  className="select-currency select-flex title-flex"
                />
                {error.agegroup ? <div className="error-validation-msg error-feature-inventory">{error.agegroup}</div> : null}  
                        
            </div> */}
           {/* <div className="flex-row name-content">
            <span className="align-rental">Country: </span>
            <Select
              value={data.country1}
              onChange={(data, e) => {
                handleUnitChange(data, e);
              }}
              options={country}
              name="country1"
              className="select-currency select-flex"
            />
          
          </div> */}
        
       
        </div>

        <div className="text-center">
          <button onClick={handleContinue} className="btn btn-primary btn-col mb-2">
            Save
          </button>
        </div>    
        {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to continue?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}       
        </div>
        </>
    )
}

export default EditTest

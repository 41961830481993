import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import moment from 'moment'
import { positions, useAlert } from "react-alert";
import SimpleBar from 'simplebar-react';
import { SOCKETURL } from "../../Global";
import { Modal, Button } from "react-bootstrap";
import CallforAction from "./CallforAction";
import no_notification from "../../assets/images/noNotification1.png";

function CallforActionPat(props) {
    const webSocket = useRef(null);
    const id=props.id&&props.id
    const name=props.name
    const [actions,setActions]=useState([])
    const [actionshow, setActionShow] = useState(false);
useEffect(() => {
   
    setTimeout(() => {

      handlingWebsocket();
  }, 10000)
  },[])
  const ActionPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
       
        <Modal.Body style={{paddingRight: "4%"}}>
        <h6 style={{ marginTop:"-10px",marginLeft:"-10px",marginRight:"1%",padding:"0.5%",boxShadow: "0 3px 5px -3px rgba(0, 0, 0, 0.25)"}}><b>Call for action</b>
                        <button style={{ backgroundColor: "white",float:"right"}} variant="secondary" onClick={props.onHide}>
                            <i class="fa fa-times" style={{ color: "black" }} aria-hidden="true"></i>
                        </button></h6>
            <CallforAction from="pat" id={id} name={name}/>
         
        </Modal.Body>

      </Modal>
    );
  };
  
  const handlingWebsocket=(props)=>{


    const tokenString = sessionStorage.getItem("usertoken");
    const hospId = sessionStorage.getItem("hospId");
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(
     
      `${SOCKETURL}/ws/call_action/action_sp_${hospId}pat_${id}/?token=` +
      str
      
    );
    webSocket.current.onopen = () => {
        console.log("WebSocket connected");
      
      };
  
    webSocket.current.onmessage = (evt) => {
      const message = JSON.parse(evt.data);
      console.log(message.message)
   setActions(message.message.message)
      
    };
   
    webSocket.current.onclose = () => {
      console.log("WebSocket disconnected");
    
    };
  }

return(<div  >
   <div style={{paddingTop:"5%"}}>
      <svg  onClick={() => setActionShow(true)} style={{float:"right",marginRight:"1%",marginTop:"-30px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill={actions.length>0?"red":"#1e656e" }d="M21 19v1H3v-1l2-2v-6c0-3.1 2.03-5.83 5-6.71V4a2 2 0 0 1 2-2a2 2 0 0 1 2 2v.29c2.97.88 5 3.61 5 6.71v6l2 2m-7 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2"/></svg>
      </div>
    <div style={{}}>
    {actions.length>0?actions.map((item)=>{
        return(
          <div  onClick={() => setActionShow(true)} style={{  width:"100%",backgroundColor:"rgba(255, 251, 237, 1)",borderLeft:"1px solid red",marginLeft:"0%"}} className="callaction_lists">
            <div className="row">
         <div className="col-2" style={{paddingTop:"7%"}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="red" d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8s-3.589 8-8 8z"/><path fill="currentColor" d="M11 11h2v6h-2zm0-4h2v2h-2z"/></svg></div>
          <div className="col-10" style={{ }} >
            <h6 style={{fontSize:"13px"}}>&nbsp;&nbsp;{item.cal_for_name.slice(0, 20)}</h6>
            <h6 style={{color:"red",fontSize:"13px"}}>&nbsp;&nbsp;{item.cal_for_reason}</h6>
            
          </div>
          </div>
          

      </div>
        )
       }): 
       <div  className=" rightdivinpr checkBoxWebmenu">
        <h6 style={{color:"rgb(43, 147, 160)" ,textAlign:"center" ,fontFamily:"Poppins",padding:"1%",paddingTop:"3%"}}>No notifications</h6>
        <svg xmlns="http://www.w3.org/2000/svg" width="7em" height="7em" viewBox="0 0 24 24"><g fill="none" stroke="#b4daff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M10.568 2.975a2.06 2.06 0 0 0-.73 1.27a1 1 0 0 1-.2.42a1 1 0 0 1-.36.3l-.79.38a5.06 5.06 0 0 0-1.65 1.29c-1.4 1.67-1.4 2.42-1.4 5.27c0 1.29-1.37 2.46-1.73 3.62c-.22.69-.34 2.25 1.48 2.25h13.58a1.57 1.57 0 0 0 .77-.16a1.64 1.64 0 0 0 .6-.51a1.62 1.62 0 0 0 .27-.73a1.589 1.589 0 0 0-.13-.78c-.36-1.09-1.79-2.39-1.79-3.68v-2.13"/><path d="M15.228 17.775c.003.427-.075.851-.23 1.25a3.348 3.348 0 0 1-.71 1.06a3.19 3.19 0 0 1-2.33.94a3.2 3.2 0 0 1-1.26-.25a3.29 3.29 0 0 1-1.77-1.77a3.2 3.2 0 0 1-.23-1.23m1.45-8.85h4.24a.19.19 0 0 1 .14.32l-4.06 4.06a.19.19 0 0 0 .035.289a.19.19 0 0 0 .105.03h4.24m-.75-10.459h2.69a.1.1 0 0 1 .096.119a.1.1 0 0 1-.026.05l-2.59 2.59a.1.1 0 0 0 .015.153a.1.1 0 0 0 .055.018h2.66"/></g></svg>
       </div>
       }
       {actionshow === true ? (
        <ActionPopup
          show={actionshow}
          onHide={() => {
            setActionShow(false);
          }}
        />
      ) : null}
      </div>
     
       </div>
)}
export default  CallforActionPat
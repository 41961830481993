import React ,{useState, useContext ,useEffect} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import './hospprofile.css'
import {Button ,Modal } from 'react-bootstrap';

function SpecUpdate(props) {

    const [spec ,setSpec] =useState('');
    const [specArray ,setSpecArray] =useState([]);
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);

    const [error,]=useState({
        username:"",
        email: '',
        phone:""
       });

       useEffect(()=>{

        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')                           
          
         
          axios.get(`${APIURL}/api/v1/service-provider/hospital-profile/?${TZ}`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
              
            if(res.data.status==="success"){
            const hospData= res.data.hospital_profile
              setSpecArray(hospData.specializations)
            }
      
      
          })
          .catch(err=>{
      
      })
      
      
      },[]);

        const handleSpecChange= e =>{
          e.preventDefault()
          const val=e.target.value;
        
          setSpec(val)
        
        }
        
        const handleAdd = e => {
          e.preventDefault();
          let newItem = specArray.concat(spec)
          setSpec('')
          setSpecArray(newItem)
        }

        const deleteSpec = (item) => {
          //setQualArray(qualArray.filter((item)=>item.index !==item))
          //const copy =Object.assign([],qualArray);
          //copy.splice(index,1)
          //setQualArray(copy)
         let check = specArray.filter((thing)=>{
           return thing !== item
         })
         setSpecArray(check)
        }

        const submitSpec = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
               specializations : specArray
             }
          
             axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                
                setSubmitMsg("success");
                setShow(true)
                     
              }else{
                setErrorMsg(res.data.message);
                setErrorSubmit(true);
              
               
              }
            
            })
            .catch(err=>{
              setErrorMsg("error");
  
              setErrorSubmit(true);
            
            })
          
          }


          const ConfirmPopUp = (props) => {
            return(
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                //  backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4 class="updated-message">Updated Successfully</h4>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
          }
          
          
          const SubmitErrorPopup =(props)=>{
              
          
            return (
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                //  backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 class="updated-message" style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>
          
                 
                 
          
                </Modal.Footer>
              </Modal>
            );
          }

    return (
        <div>
            <div className="form-group col-lg-6">

<div className="comorb-holder">

    {
      specArray.map((item, index)=>{
        return(               
         <div key={index }className="comorbidity-row comorb-item">
           <h6>{item} </h6>                  
             <button type="button" onClick={() =>deleteSpec(item)} className="btn btn-danger btn-smallest" > X </button> 
             
         </div>             
        )})
    }      

      <br/>
</div>
<div  className="comorbidity-add-row">
  <input style={{width:'280px'}} className="form-control" type="text"  placeholder="Specialization" name="spec" value={spec}  onChange={handleSpecChange} />
  {error.qual ? <div className="error-validation-msg">{error.qual}</div> : null}
  <button onClick={handleAdd} className="btn btn-smallest btn-square btn-primary btn-col btn-add-comorbidity"> + Add </button>
  </div> 
</div>
            <div className="form-button">
                <button  onClick={submitSpec} className="btn btn-primary btn-col" >Update Specialization</button>
            </div>

            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default SpecUpdate


import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { NavDropdown } from "react-bootstrap";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton, Badge,Button } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import DatePicker from "./Datepicker";
import { Modal } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import "../AdmissionReport/reports.css";
import peaceImage from '../../../assets/images/icons/peacevillage.png'; 
import isBefore from 'date-fns/isBefore';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import AvailabityFilter from "./AvailabilityFilter";
import Nav from 'react-bootstrap/Nav';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { MdDownloadForOffline } from "react-icons/md";
import { GrClose } from "react-icons/gr";


function VolunteerReport(){

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalRows1, setTotalRows1] = useState(0);
  const [page, setPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [hasNext, setHasNext] = useState(false);

  const [show, setShow] = useState(false);
  const [load, setLoad] = useState('')
  const [activate,setActivate]=useState(false);
  const [registerData, setRegisterData] = useState([])

  const [timePeriod, setTimePeriod] = useState("month")
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [refresh,setRefresh]=useState(false);
  const [refresh1,setRefresh1]=useState(false);
  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [selectedJobSkills, setSelectedJobSkills] = useState([]);
  const [selectedValue, setSelectedValue] = useState(''); // You can set the initial selected value here
  const [showMonths, setShowMonths] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
  const [monthsYear, setMonthsYear] = useState(moment().format('YYYY'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedDownloads, setSelectedDownloads] = useState([]);
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  const [dateRange,setDateRange] = useState(null);


  useEffect(() => {
    
   
    setRegisterData([])
    apiCallFilter(1,10);
   setFromDate("")
   setToDate("")
   setFromDate1("")
   setToDate1("")
   setSelectedValue("")
 setSelectedJobSkills([])

   
  }, [refresh]);

  useEffect(() => {
    apiCallFilter(1,10);
  },[refresh1])

  const downloadOptions = [ "PDF", "CSV" ];  
  const toggleDownloadDropdown = () => {
    setIsDownloadDropdownOpen(!isDownloadDropdownOpen);
  };

  const handleDownloadChange = (download) => {
    setSelectedDownloads((prevDownloads) => {
      if (prevDownloads.includes(download)) {
        // Deselect the checkbox
        return prevDownloads.filter(item => item !== download);
      } else {
        return [...prevDownloads, download];
      }
    });
  };

  const capitalize = (str) => {
    if (typeof str === 'string') {
        return str&&str!==""&&str.replace(/\b\w/g, c => c.toUpperCase());
    }
    return str;
};

  const handleDownloads = () => {
    
    // Perform actions based on selected download options
    selectedDownloads.forEach((selectedOption) => {
      if (selectedOption === 'PDF') {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const marginLeft1 =450;
    const marginBottom =40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    
    const title = "Volunteer Report";
    const headers = [["Name", "Gender","Address","Occupation","Job Skills", "Experience","Registration","Available From","Available To","Available Period" ]];
    // var dateStringWithTime = moment(now).format('YYYY-MM-DD HH:MM:SS');

    const data = registerData.map((elt) => {
    
   
    
      return [
        capitalize(elt.full_name),
        capitalize(elt.gender),       
        capitalize(elt.address),
        elt.occupation ? capitalize(elt.occupation): '',
        capitalize(elt.job_skill),
        elt.experience ? capitalize(elt.experience) : '',
        moment(elt.register_at).format("YYYY-MM-DD"),
        moment(elt.avail_from).format("YYYY-MM-DD"),
        moment(elt.avail_to).format("YYYY-MM-DD"),
        capitalize(elt.available_period),   
      ];
    });
    


    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Volunteer_Report.pdf")
  } else if (selectedOption === 'CSV') {
  

    const csvData = registerData.map(elt => {
        const job_skill = elt.job_skill || [];        
    
        const rowData = [
          elt.full_name,
          elt.gender || "N/A",
          elt.address || "N/A",
          elt.occupation || "N/A",
          ...(job_skill.length > 0 ? [`${job_skill.join(", ")}`] : []),
          elt.experience || "N/A",
          moment(elt.register_at).format('YYYY-MM-DD'),
      moment(elt.avail_from).format('YYYY-MM-DD'),
      moment(elt.avail_to).format('YYYY-MM-DD'),
      elt.available_period,
          
        ];
    
        return rowData.map((value) => `"${value}"`).join(",");
      });
    
    // Generate CSV content
    const csvContent =
      CsvRegisterheaders.map((h) => h.label).join(',') +
      '\n' +
      csvData.map((row) => Object.values(row).join(',')).join('\n');

    
      // Create a Blob and download the CSV file
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Volunteer_Report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
      
  }});

    // Reset selected downloads
    setSelectedDownloads([]);

    setIsDownloadDropdownOpen(false)
  };

  const handleApplyFilter = (selectedSkills, dateRange,dateRange1) => {
  

    
    // Update your state or perform other actions based on the filter
    if(selectedSkills.length>0){
       setSelectedJobSkills(selectedSkills);
    
      
    }
 else{
 setSelectedJobSkills([])
 }
 
      if (selectedSkills.length>0 && dateRange[0]==="" && dateRange1[0]==="" ){
      setFromDate("");
      setToDate("");
      setFromDate1("");
      setToDate1("")
      registerData.splice(0, registerData.length + 1)
      apiCallFilter(1, 10)
      setRefresh1(!refresh1)
     } 
     else if (selectedSkills.length>0 && dateRange[0]==="" && dateRange1[0]!=="" ){
      setFromDate(dateRange1[0]);
     setToDate(dateRange1[1])
      setFromDate1("");
      setToDate1("")
      registerData.splice(0, registerData.length + 1)
      apiCallFilter(1, 10)
      setRefresh1(!refresh1)
     } 
     else if(selectedSkills.length>0 && dateRange1[0]==="" && dateRange[0]!=="" ){
     setFromDate1(dateRange[0]);
     setToDate1(dateRange[1]);
     setFromDate("");
     setToDate("")
     registerData.splice(0, registerData.length + 1)
     apiCallFilter(1, 10)
     setRefresh1(!refresh1)

  }
  else if(selectedSkills.length>0 && dateRange1[0]!=="" && dateRange[0]!==""){
    setFromDate1(dateRange[0]);
    setToDate1(dateRange[1]);
    setFromDate(dateRange1[0]);
    setToDate(dateRange1[1])
    registerData.splice(0, registerData.length + 1)
    apiCallFilter(1, 10)
    setRefresh1(!refresh1)

 }

 else if(selectedSkills.length===0 && dateRange1[0]!=="" && dateRange[0]===""){
  setFromDate1("");
  setToDate1("");
  setFromDate(dateRange1[0]);
  setToDate(dateRange1[1])
  registerData.splice(0, registerData.length + 1)
  apiCallFilter(1, 10)
  setRefresh1(!refresh1)

}
else if(selectedSkills.length===0 && dateRange[0]!=="" && dateRange1[0]==="" ){
  setFromDate1(dateRange[0]);
  setToDate1(dateRange[1]);
  setFromDate("");
  setToDate("")
  registerData.splice(0, registerData.length + 1)
  apiCallFilter(1, 10)
  setRefresh1(!refresh1)

}
else if(selectedSkills.length===0 && dateRange[0]!=="" && dateRange1[0]!=="" ){
  setFromDate1(dateRange[0]);
  setToDate1(dateRange[1]);
  setFromDate(dateRange1[0]);
  setToDate(dateRange1[1])
  registerData.splice(0, registerData.length + 1)
  apiCallFilter(1, 10)
  setRefresh1(!refresh1)

}

  }
   
  // useEffect(() => {
  //   debugger;
  //   if (fromDate!=="" || fromDate1!=="" || selectedJobSkills.length>0) {

  //     setRegisterData([])
     
  //     apiCallFilter(1, perPage)
  // //setRefresh(!refresh)
  //   }

  // }, [fromDate,fromDate1,selectedJobSkills]);
  useEffect(() => {
    if (fromDate1) {
      setFromDate("");
      setToDate("");
      apiCallFilter(1, perPage)
    }

  }, [fromDate1,toDate1]);

  const handleChangeValue = (val) => {
    registerData.splice(0, registerData.length + 1)
    
    
      var startOfWeek = moment().startOf('week').toDate();
      var endOfWeek = moment().endOf('week').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setFromDate1(FormatedFirstday)
      setToDate1(Formatedlastday)
      setSelectedValue('week');

    
  }

  const handleMonthChange = (increment) => {
    registerData.splice(0, registerData.length + 1)
    const currentMoment = moment(`${monthsYear}-${currentMonth}`, 'YYYY-MMMM');
    const newMoment = currentMoment.add(increment, 'months');

    // Set maximum month to one year more
    // const minMonth = moment().add(1, 'year').endOf('month')-1;
    // if (newMoment.isSameOrBefore(minMonth, 'month')) {
    //   setCurrentMonth(minMonth.format('MMMM'));
    //   setMonthsYear(minMonth.format('YYYY'));
    //   return;
    // }

    // Set minimum month to the current month
    // if (newMoment.isBefore(moment(), 'month')) {
    //   setCurrentMonth(moment().format('MMMM'));
    //   setMonthsYear(moment().format('YYYY'));
    //   return;
    // }

   
      const startOfMonth = newMoment.startOf('month').format('YYYY-MM-DD');
      const endOfMonth = newMoment.endOf('month').format('YYYY-MM-DD');
  
      setFromDate1(startOfMonth);
      setToDate1(endOfMonth);
   
    setCurrentMonth(newMoment.format('MMMM'));
    setMonthsYear(newMoment.format('YYYY'));

    setSelectedValue('month');

  };
  const generateYearOptions = () => {
    const startYear = 2023;
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = startYear ; year <= currentYear + 2; year++) {
      years.push(String(year));
    }
    return years;
  };
  

  const filterOptions = [ ...generateYearOptions()];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const handleFilterChange = (filter) => {
    setSelectedYears((cmngYears) => {
      if (cmngYears.includes(filter)) {
        // Deselect the checkbox
        return [];
      } else {
        // Select the checkbox and disable others
        return [filter];
      }
    });
  };
  const handleApplyFilters = () => {
    registerData.splice(0, registerData.length + 1);  
      
    if (selectedYears.length > 0) {
      const sortedYears = selectedYears.sort((a, b) => Number(a) - Number(b));
  
      const startDate = sortedYears[0] + '-01-01';
      const endDate = sortedYears[selectedYears.length - 1] + '-12-31';
  
      setFromDate1(startDate);
      setToDate1(endDate);  
    } 
      
    setSelectedYears([]);
    setIsDropdownOpen(false);
    setSelectedValue('');
  };
  
  
 const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const apiCallFilter = (page, per_page) => {
    

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const a = fromDate;
    const b = toDate;
    const c =fromDate1;
    const d= toDate1;
    const skillsParam = selectedJobSkills && selectedJobSkills.map(skill => `skill[]=${encodeURIComponent(skill)}`).join('&');
    //  2021-10-12
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?start_date=${a}&end_date=${b}&start_available=${c}&end_available=${d}&page=${page}&per_page=${per_page}&timezone=${currentTimezone}&volunteer=volunteer&${skillsParam}`,{
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setHasNext(res.data.has_next)
          setLoad('')
          let datalist = res.data.volunteers
          //const data1 = datalist.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData([...registerData,...datalist])
          setTotalRows(res.data.total_records)
        }
        else {
          setLoad('Error in Fetching Data')
        }

      })


      .catch(err => {
        setLoad('Error in Fetching Data')
      })


  }

  

  // const apiCallFilter2 = (page, per_page) => {

    
  //       const tokenString = sessionStorage.getItem('usertoken');
    
  //       let str = tokenString.replace(/["]+/g, '')
  //       const a = fromDate1;
  //       const b = toDate1;
  //       let data = [];
  //       const skillsParam = selectedJobSkills.map(skill => `skill[]=${encodeURIComponent(skill)}`).join('&');
        
  //       //  2021-10-12
  //       axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&start_available=${a}&end_available=${b}&${skillsParam}`,{
  //         headers: {
    
  //           "Authorization": 'Token ' + str
  //         }
  //       })
  //         .then(res => {
    
    
  //           if (res.data.status === "success") {
  //             setHasNext1(res.data.has_next)
  //             setLoad('')
  //             let datalist = res.data.volunteers
  //             if (datalist && datalist.length > 0) {
  //               const newData = datalist.filter(function (o1) {
  //                 return !data.some(function (o2) {
  //                   return o1.id === o2.id;
  //                 });
  //               });
  //               data = [...data, ...newData];
  //             }
  //             setRegisterData1(data)
  //             setTotalRows1(res.data.total_records)
  //           }
  //           else {
  //             setLoad('Error in Fetching Data')
  //           }
    
  //         })
    
    
  //         .catch(err => {
  //           setLoad('Error in Fetching Data')
  //         })
    
    
  //     }
    


  const handleEvent = (value, picker) => {
    setShowMonths(false);
    setShowYears(false);
    setShow(false);
    setDateRange(value);
    registerData.splice(0, registerData.length + 1)
  
    if (value && value.length === 2) {
      // Check if event is not null and contains two dates
      const [start, end] = value;
      const fromDate = moment(start).format('YYYY-MM-DD');
      const toDate = moment(end).format('YYYY-MM-DD');
      setFromDate1(fromDate);
      setToDate1(toDate);
      setSelectedValue('');
    } else {
      // Handle the case where event is null or doesn't contain two dates
      setFromDate(''); // Reset fromDate
      setToDate(''); 
      setFromDate1('');
      setToDate1('');
        // Reset toDate
      setSelectedValue('');
      setRefresh(!refresh);
    }
  };

  const renderTable = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize",fontSize:"13px" }}>{registerData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }

 
  const renderTableAddress = (cellInfo) => {

    if (registerData && registerData[cellInfo.index] && registerData[cellInfo.index][cellInfo.column.id]) {
      const dataLines = registerData[cellInfo.index][cellInfo.column.id]
      const words = dataLines.split(/[, ]/); // Split by either space or comma


      // Create an array to hold lines
      const lines = [];
      let currentLine = '';
    
      // Iterate through the words and group them into lines
      for (let i = 0; i < words.length; i++) {
        currentLine += words[i] + ' ';
        if ((i + 1) % 2 === 0 || i === words.length - 1) {
          // Add the current line to the lines array
          lines.push(currentLine.trim());
          currentLine = '';
        }
      }
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "13px" }}>
          {lines.map((line, index) => (
            <div key={index}>{line}</div>
         ) )}
        </div>
      );
    } else {
      // Handle the case where the data is missing or invalid
      return <div></div>;
    }
  }

 
  const renderTableExp = (cellInfo) => {

    if (registerData && registerData[cellInfo.index] && registerData[cellInfo.index][cellInfo.column.id]) {
      const dataLines = registerData[cellInfo.index][cellInfo.column.id]
      const words = dataLines.split(/[, ]/);

      // Create an array to hold lines
      const lines = [];
      let currentLine = '';
    
      // Iterate through the words and group them into lines
      for (let i = 0; i < words.length; i++) {
        currentLine += words[i] + ' ';
        if ((i + 1) % 3 === 0 || i === words.length - 1) {
          // Add the current line to the lines array
          lines.push(currentLine.trim());
          currentLine = '';
        }
      }
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "13px" }}>
          {lines.map((line, index) => (
            <div key={index}>{line}</div>
         ) )}
        </div>
      );
    } else {
      // Handle the case where the data is missing or invalid
      return <div></div>;
    }
  }
  

 


  const handlePopUp=()=>{
    setModalShow(false)
  }

 
  const renderTableJobSkill = (cellInfo) => {
    // Assuming registerData[cellInfo.index][cellInfo.column.id] is an array of skills
    const skills = registerData[cellInfo.index][cellInfo.column.id];
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "12px" }}>
        {skills.map((skill, index) => (
          <div key={index}>{skill},</div>
        ))}
      </div>
    );
  };
 
  
  const ConfigPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="config-modal-width"
        style={{marginTop:'5rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        <Modal.Header style={{height:'50px' }} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            <h6 className="title-of-tasks" style={{ }}>
            Availabilty Filter
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AvailabityFilter data={registerData}  fromDate={fromDate}
        toDate={toDate}  fromDate1={fromDate1} toDate1={toDate1} updateDateRange={(from, to) => {
          setFromDate(from);
          setToDate(to);
        }} handle={handlePopUp}  onApplyFilter={handleApplyFilter} />
        </Modal.Body>

        
      </Modal>
    );
  };
  
  





  const renderDate = (cellInfo) => {
    const datetimeString = cellInfo.value; // Assuming cellInfo contains the datetime string
    const dateParts = datetimeString.split('T')[0]; // Split by 'T' and then by hyphen
    const formattedDate = datetimeString.split(' ')[0]; // Join the date parts with hyphens
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {formattedDate}
      </div>
    );
  };
  
  const renderDate1 = (cellInfo) => {
    const datetimeString = registerData[cellInfo.index][cellInfo.column.id];
    const dateParts = datetimeString.split(' ')[0]; // Split by space and then by hyphen
    // const formattedDate = dateParts.join('-'); // Join the date parts with hyphens
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {dateParts}
      </div>
    );
  };

 

  
  
  const filterDataName = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&vo_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataOccupation = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&vo_occupation=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataGender = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&vo_gender=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataAddress = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&vo_address=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataJob = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&job_skills=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataExp = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&vo_experience=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataRegDate = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&registration=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataAvailableFrom = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&avail_from=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataDateTo = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&avail_to=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataPeriod = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?volunteer=volunteer&timezone=${currentTimezone}&avail_period=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.volunteers
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };






  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: renderTable,
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataName(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      
       width: 180

    },
    
    {
      Header: "Gender",
      accessor: "gender",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset",wordBreak: "break-all" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataGender(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      // width: 150

    },    
    {
      Header: "Address",
      accessor: "address",
      Cell: renderTableAddress,
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataAddress(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
       width: 180

    },
    {
      Header: "Occupation",
      accessor: "occupation",
      Cell: renderTable,
      style: { whiteSpace: "unset",wordBreak: "break-all"},
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataOccupation(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      // width: 150

    },
    {
      Header: "Job Skill",
      accessor: "job_skill",
      Cell: renderTableJobSkill,
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
            filterDataJob(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
       width: 200

    },

    {
      Header: "Experience",
      accessor: "experience",
      Cell: renderTableExp,
      sortable: true,
      style: { whiteSpace: "unset",wordBreak: "break-all"},
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataExp(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
       width: 200

    },

    {
      Header: "Registration",
      accessor: "register_at",
      Cell: renderDate,
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataRegDate(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      // width: 200

    },
    {
          Header: "Available From",
          accessor: "available_from",
          Cell: renderDate1,        
          style: { whiteSpace: "unset",wordBreak: "break-all"},
          sortable: true,
          filterable: true,
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
        
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
               filterDataAvailableFrom(filterValue,); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
          // width: 80
    
        },
        {
          Header: "Available To",
          accessor: "available_to",
          Cell: renderDate1,
          style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
          sortable: true,
          filterable: true,
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
        
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
               filterDataDateTo(filterValue,); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
          // width: 150
    
        },
    
        {
          Header: "Available Period",
          accessor: "available_period",
          Cell: renderTable,
          style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
          sortable: true,
          filterable: true,
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
        
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
               filterDataPeriod(filterValue,); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
           //width: 150
    
        },
       

   

  ]


  
  const handlePageChange = page => {
    if (hasNext) {
      debugger;
      apiCallFilter(page + 1, perPage);
    }
  }


  const handleAvailabityPopUp =(e)=>{


    setSelectedJobSkills([])
    setFromDate("")
    setToDate("")
    setFromDate1("")
    setToDate1("")

    setModalShow(true)

  }

  const handleRefresh =(e)=>{
    
    e.preventDefault();
    setCurrentMonth(moment().format('MMMM'));
    setMonthsYear(moment().format('YYYY'));
    setRegisterData([]);
    
    setPage(1);
    setFromDate("")
    setToDate("")
    setFromDate1("")
    setToDate1("")
    setSelectedJobSkills([])
    setRefresh(!refresh)
    setDateRange(null); 
  }
  const handleClear = () => {
    setRegisterData([])
    setCurrentMonth(moment().format('MMMM'));
    setMonthsYear(moment().format('YYYY'));
    setRegisterData([]);
    
    setPage(1);
    setFromDate("")
    setToDate("")
    setFromDate1("")
    setToDate1("")
    setRefresh(!refresh)
    setDateRange(null); 
  };

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage)
    registerData.splice(0, registerData.length + 1)
    apiCallFilter(1, newPerPage);

  }
  // const handlePerRowsChange1 = async (newPerPage, pageindex) => {
  //   setPerPage(newPerPage)
  //   registerData1.splice(0, registerData1.length + 1)
  //   apiCallFilter2(1, newPerPage);

  // }
  // useEffect(() => {
  //   apiCallFilter(page, perPage);
  //   setSelectedValue('')
  // }, [perPage]);
  const CustomPagination = ({
    page,
    pageSize,
    pages,
    canPrevious,
    canNext,
    onPageChange
  }) => {
    return (
      <div style={{display:"flex"}}>
        <button style={{width:"30%",margin: "3px", borderRadius: "5px", padding:"10px"}} onClick={() => onPageChange(page - 1)} disabled={!canPrevious}>
          Previous
        </button>
        <div style={{ width:"40%", textAlign: "center", padding: "10px"}}><span style={{ margin: "10px" }}>Page {page + 1}</span></div> 
        <button style={{width:"30%",margin: "3px", borderRadius: "5px", padding:"10px"}} onClick={() => onPageChange(page + 1)} disabled={!canNext}>
          Next
        </button>
      </div>
    );
  };

  const display = <ReactTable
    columns={columns}
    data={registerData}
    defaultPageSize={perPage - 1}
    className="chol-table"
    resizable
    minRows={perPage - 1}
    // pages={Math.ceil(totalRows / perPage)}
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    pagination
    paginationServer
    onPageChange={(pageIndex) => { handlePageChange(pageIndex);  }}
    paginationTotalRows={totalRows}
    onPageSizeChange={handlePerRowsChange}
    PaginationComponent={CustomPagination}
  ></ReactTable>



 

  const CsvRegisterheaders = [
    { label: "Name", key: "full_name" },  
    { label: "Gender", key: "gender" },
    { label: "Address", key: "address" },
    { label: "Occupation", key: "occupation" },
    { label: "Job Skills", key: "job_skill" },
    { label: "Experience", key: "experience" },
    { label: "Registartion", key: "register_at" },
    { label: "Available From", key: "avail_from" },
    { label: "Available To", key: "avail_to" },
    { label: "Available Period", key: "available_period" },

  ];

 

  return (
    <>

      <div className="patient-report">        
      <Nav className="row" 
      style={{  
        display: 'inline-block',
        width: '100%', 
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        margin:'auto'
      }}>
    <div style={{display: 'flex', marginTop:"1%"}}>
    <span  style={{float:"left",width: "35rem" }}>  
      <button className="buttonfilter" style={{ marginTop: "1%", borderRadius: "25px", width: "90px", background: "#1269B9",fontSize:"11px" }} onClick={(e) => handleRefresh(e)}>
        <span>Refresh &nbsp;<i className="fa fa-refresh" aria-hidden="true" style={{ color: "white" }}></i> </span>
      </button> 
    </span>
          <span  style={{display:'flex', marginBottom: "1%", textAlign: "right",float:"right"}}>
      <ToggleButtonGroup className="datepicker-reports" style={{ marginTop: "1%", fontSize: "small"}} type="radio" name="options" >
        <DateRangePicker
          appearance="default"              
          placeholder={"Select Date Range"}  
          value={dateRange}               
          onChange={handleEvent}
        >
          <ToggleButton id="tbg-radio-4" value={'custom'} className="btn btn-light toggle-btns custombtn1" style={{ border: 'none',fontSize:'11px'}}></ToggleButton>
        </DateRangePicker>&nbsp;&nbsp;&nbsp;
                       
        <div style={{ display: 'flex', alignItems: 'center',height: '30px', fontSize: '11px', fontWeight: '400px', border: `1px solid ${selectedValue === 'month' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }}>
          <button className='arrowButton' onClick={() => handleMonthChange(-1)}>
            {<AiFillCaretLeft />}
          </button>
          <span style={{ margin: '0 4px', width: '50px', textAlign: 'center' }}>{currentMonth}</span>
          <button className='arrowButton' onClick={() => handleMonthChange(1)}>
            {<AiFillCaretRight />}
          </button>
        </div>&nbsp;&nbsp;&nbsp;
        <button   
          style={{ display: 'flex',justifyContent:'center',alignItems:'center', height: '30px',width:'50px', fontSize: '11px',backgroundColor:'white', fontWeight: '400px', border: `1px solid ${selectedValue === 'week' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }} 
          onClick={() => handleChangeValue('week')}
        > 
          <span>Week</span>
        </button>&nbsp;&nbsp;
        <div style={{position:"relative"}}>
        <button
        className="buttonfilter" 
        style={{ borderRadius: "20px",height: '32px', width: '60px', fontSize: '11px', background: isDropdownOpen ? "rgb(13, 197, 197)" : " #1269B9" }}
        onClick={toggleDropdown}
      ><span>
        More
        </span>
      </button>&nbsp;
      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
            width:'100px',
            alignItems:'center',
          }}
        >
          {filterOptions.map((filter) => (
            <label key={filter} style={{ marginBottom: '5px' }}>
              <input
                type="checkbox"
                checked={selectedYears.includes(filter)}
                onChange={() => handleFilterChange(filter)}
                //disable others if one is selected
                disabled={selectedYears.length > 0 && !selectedYears.includes(filter)}
              />&nbsp;
              {filter}
            </label>
          ))}
          <button disabled={selectedYears.length===0} style={{ marginTop: '10px',cursor:selectedYears.length===0?"not-allowed":"pointer" }} onClick={handleApplyFilters}>
            Apply
          </button>
        </div>
      )}</div>
      <div>
        <button  className="buttonfilter" style={{ width:'70px',height: '32px', fontSize: '11px', background: modalShow ? "rgb(13, 197, 197)" : "#1269B9" }} onClick={(e)=>handleAvailabityPopUp(e)}> 
              <span>Filters </span>  
            </button>
            {(selectedJobSkills.length>0 || fromDate!=='' ) && 
            <button 
              className='buttonfilter' 
              style={{width: "80px",
                backgroundColor:"#1269B9", 
                color:"white", 
                height:"32px", 
                fontSize:"11px", 
                borderRadius:"0px 20px 20px 0px", 
                marginLeft:"-10px"
              }} 
              onClick={handleClear}
            ><span>Clear Filters</span>
            </button> }&nbsp;            
        </div>
        <button className='buttonfilter' style={{ width: '100px',height: '32px', fontSize: '11px' }} onClick={setIsDownloadDropdownOpen}>
          <span>Download  <MdDownloadForOffline /></span>
        </button>
      </ToggleButtonGroup> 
    </span></div>
        </Nav>
        {isDownloadDropdownOpen && (
  <div style={{ position: 'relative', zIndex: 1000 }}>
    {/* Backdrop */}
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
      onClick={() => {}}
    ></div>
    
    {/* Dropdown */}
    <div className='download-dropdown'
      style={{
        position: 'absolute',
        margin: 'auto',
        width: '30rem',
        borderRadius: '10px',
        backgroundColor: '#fff',            
        padding: '10px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        zIndex: 1001,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '15rem',
        marginTop: '5%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid lightgrey',
          marginBottom: '10px',
          paddingBottom:'10px'
        }}
      >
        <button
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            marginLeft: 'auto',
          }}
          onClick={toggleDownloadDropdown}
        >
          <GrClose />
        </button>
      </div>
      <div style={{display:"flex", flexDirection:"column"}}>
        <h4 style={{ marginBottom:"2rem"}}>Select the formats to download</h4>
        {downloadOptions.map((download) => (
          <label key={download} style={{ marginBottom: '5px' }}>
            <input
              type="checkbox"
              checked={selectedDownloads.includes(download)}
              onChange={() => handleDownloadChange(download)}
            />&nbsp;
            {download}
          </label>
        ))} 
      </div>
      <button
        variant="primary"
        className='buttonfilter'
        style={{width: '100px', height: '32px', fontSize: '11px', marginTop:"10px" }}
        onClick={handleDownloads}
      >
        Download
      </button>
    </div>
  </div>
)}
        <div className="service-table-holder" style={{  }}> {load === '' ? display
          : load === 'Error in Fetching Data' ? <h6 style={{ color: "red" }}>{load}</h6> : <h6 style={{ color: "grey" }}>{load}</h6>}
        </div>
      </div>
      <br />
        {modalShow ? (
          <ConfigPopUp
            show={modalShow}
            onHide={() => {
              setModalShow(false);            
            }}
          />)
        : null}
        
    </>
  )
}

export default VolunteerReport

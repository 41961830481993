import React, {useState, useEffect} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import printJS from 'print-js'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import moment from 'moment'
import { useAlert } from "react-alert";

function PaymentInvoiceLiability(props) {
    
  
  let totalOrderAmount = 0.0;
    const [invoiceData , setInvoiceData]= useState(props.invoiceData);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [method, setMethod]= useState('')
    const [cartID, setCartID]= useState("")
    //const [amount, setAmount]= useState('')
    const [errorShow,setErrorShow]=useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    // const [type, setType]= useState("full")
    // const [discount, setDiscount]= useState(0)
    const alert = useAlert();
    // const [show, setShow]= useState(false)
    const [quantity1, setQuantity] = useState([]);
    const [service, setService] = useState([]);
    const [type, setType] = useState([]);
    const [Ids, setID] = useState([]);
    const [login_datas, setLogin_datas] = useState(
      JSON.parse(sessionStorage.getItem("logindatas"))
          ? JSON.parse(sessionStorage.getItem("logindatas"))
          : null
  );

 useEffect(()=> {

     
        invoiceData.map((item)=>{

    setID(item.hosp_service_id)
       setQuantity (item.quantity);
       setService(item.item_service_name)
       setType(item.order_type)


        })
    
 

       


    },[])


     const handleMethod =(e) => {
        setMethod(e.target.value)
    }


    const itemsArray =  type==="master_medicine" ?  invoiceData.map(item => ({ item: item.hosp_service_id, quantity: parseInt(item.quantity) })) : ""




    const handleSave = async()=> {

            
      setIsLoading(true);
    
      // Disable the button
      document.getElementById("save-btn").disabled = true;

        const tokenString= sessionStorage.getItem('usertoken');
      
        let str= tokenString.replace(/["]+/g, '');

       

   

        //const consult = props.consultData
        const dataToUpdate = {
          payment_mode : method,
           paid_amount: service==="registration" || service==="Registration" ? invoiceData[0].amount :totalOrderAmount,
            patient_id: props.id,
            paid_for: type==="master_labtest" ? "test" : type==="master_scan" ? "scan" : type==="master_medicine" ? "medicine" : type==="misc" ? "misc" :type==="master_procedure" ? "procedure" :type==="registration" ?"registration" : type==="master_consumable" ? "consumables" :"",
            paid_status:"paid",
            items:type==="master_medicine" ? itemsArray : [Ids]
            //procedure_id_list: idArray,
            // doctor_id: consult ? consult.doctorId : null ,
            // consultation_date: consult ? consult.date.split('T').join(" ").slice(0,16) : null
            // discount: discount
        }

        if (isSaving) {
          // If an API call is already in progress, return early to prevent duplicate calls
          return;
        }

        setIsSaving(true);
       
        try {
          const res = await   axios.post(`${APIURL}/api/v1/service-provider/hospital-payments/?master_pay_id=${props.master_id}`, dataToUpdate ,
         { headers: {
           'Content-Type': 'application/json',
           "Authorization" : 'Token '+str  }})
            .then(res=>{
       
           if(res.data.status==="success"){

            

         // setCartID(res.data.checkoutcart_id)
       
        
          alert.show("Bill Generated")
          

         
              
            handlePrint()

           

           // setInvoiceData([])
            props.dataHandle1();
         
           // props.functionData();
       
           }else{
            setErrorMsg(res.data.message)
            setErrorShow(true)
           }
       
       
         })
        }catch(err){
            setErrorMsg('Error in Data Submission.Please try again')
            setErrorShow(true)
       }
       finally {
        setIsSaving(false);
      }
    
      // Enable the button
      document.getElementById("save-btn").disabled = false;

    }


     const handlePrint = () => {
    
        printJS({
           printable: 'print-invoice',
           type: 'html',
           targetStyles: ['*'],
        })
      
      }


      const SubmitErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      } 




   
     totalOrderAmount = invoiceData.reduce(
        (accumulator, item) => accumulator + item.order_amount,
        0
      );

 

      const procedureData = invoiceData.length>0 ?

                                                         invoiceData.map(item => {
                                                      

          return (

                  <tr>
                  
                      <td>{item.item_service_name}</td>
                      {quantity1 ? <td>{item.quantity}</td>:""} 
                      <td>{item.amount}</td>
                      <td>{item.vat}</td>
                      <td>{item.discount}</td>
                      {service==="registration" || service==="Registration" ? <td>{item.amount}</td> : <td>{item.order_amount}</td>}
                  </tr>

          )
      }) : null



   return (<div>
      
      <h2 style={{textAlign:'center'}} className="title-of-tasks"> Invoice  </h2><br/>

      <div className='flex-row invoice-radio'>
           <span className='text-muted font-weight-bold'>Select payment method :</span>
           <input type='radio' className='hideme' checked={method==='card'} id='card' value='card' onChange={handleMethod} />
        <label htmlFor='card' className='invoice-label'>
            Card
        </label>

        <input type='radio' className='hideme' checked={method==='cash'} id='cash' value='cash' onChange={handleMethod} />
        <label htmlFor='cash' className='invoice-label'>
            Cash 
        </label>

        <input type='radio' className='hideme' checked={method==='upi'} id='benefit_pay' value='upi' onChange={handleMethod} />
        <label htmlFor='benefit_pay' className='invoice-label'>
            UPI
        </label>
       </div>
       <br/>
       <div id='print-invoice' className="invoice-table flex-col to-print invoice-print-size ">
        <div className="invoice-row1 invoice-header">

              <div>
                <h5 className='font-weight-bold text-left'>Patient ID {props.id}</h5>
                <h6 className='text-left'>Patient: {props.name}</h6>
                <h6 className='text-left'>Mobile: {props.mobile}</h6>
               
              </div>

             <div>
              <h3 className="invoice-heading caps text-center">{login_datas.hospital_name}</h3>
              <h6 className="invoice-heading caps text-center">{login_datas.hospital_state},{login_datas.hospital_location},{login_datas.hospital_street}</h6>
              <br/>
              <h6 className="invoice-heading invoice-heading-normal">{login_datas.hospital_mobile}</h6>
              <h6 className="invoice-heading invoice-heading-normal">{login_datas.hospital_email}</h6>
             </div>
           {/* <h6 className="invoice-heading caps">Al Janabiyah North</h6> */}

        <div className='invoice-detail mr-2'>

            <h5>{moment().format('DD-MM-YYYY')}</h5>
        
            

        </div>
       </div>
       {/* <div className="invoice-row2">
  
       <div>
          <h5 className="grey-title caps">{invoiceData.patient_name}</h5>
    </div>
       </div> */}
       <div className="invoice-row3">
          <table style={{marginLeft:"10%"}} >
          
         
            <tr>
            
  
              <th> Name</th>
              {quantity1 ? <th>Qty</th>:""} 
              <th> Amount </th>
              <th>VAT%</th>
              <th>Discount%</th>
              <th>Total Amount</th>
            </tr>
    

{ procedureData }
          


  
          </table>
       </div>

       <div className="invoice-row4">

     {/* <h6 className="text-right">Gross Total : <span className="caps-full"><i class="fa fa-eur" aria-hidden="true"></i></span>{props.order_amount}</h6> */}
        {/* <h6 className="text-left">VAT Amount : <span className="caps-full">INR</span>{props.vat}</h6>
        <h6 className="text-left">Discount : {props.discount}</h6>
        <h6 className="text-left">Total Amount : <span className="caps-full">INR</span>{props.total+props.vat}</h6> */}
        <h6 className="text-right">Paid Amount: <span className="caps-full"><i class="fa fa-eur" aria-hidden="true"></i></span>{ service==="registration" || service==="Registration" ? invoiceData[0].amount :totalOrderAmount}</h6>
        </div>
        <div className="invoice-row4">
      
       
     
         <br/>
         <h6 className="text-right">Thanks,</h6>
         <h6 className="text-right">{login_datas.name}</h6>
          

     </div>

       </div>
       <br/>

       {/**<div className="to-print">
        <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>
  
      </div>**/}
       
       <div className="do-not-print text-center" id="pr">
                   <button className="btn btn-primary btn-col btn-square" disabled={method === ''}   id="save-btn" onClick={handleSave}> Save and Print </button>
                   <br/>
                   <br/>
                   <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                    <br/>
                    <br/>
             </div> 
  

             {errorMsg!=='' ? 
    <SubmitErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        setErrorMsg('');}}
       
      />: ''
    }

    </div>
       )
}

export default PaymentInvoiceLiability

import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import DatePicker from 'react-date-picker';
import "./wound.css"
import PatientSearch from '../../common/PatientSearch';
function Assessment(props) {
    const alert = useAlert()
    const [assessmentInfo, setAssessmentInfo] = useState({})
    const [woundId, setWoundId] = useState(props.woundId)
    const trimspace=(val)=>{
    
        return  val.startsWith(' ')
        ? val.trimLeft()
        : val;
      }
    const handleTitle = (e) => {


        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;
        if (key === "size" || key === "length" || key === "width" || key === "depth") {
           if(val===""){
            setAssessmentInfo(currentstate => ({
                ...currentstate,
                [key]: val
            }));
           }
            else if (val < 0.1) {
                alert.error("please enter a valid number")
            }
            else {
                setAssessmentInfo(currentstate => ({
                    ...currentstate,
                    [key]: val
                }));
            }
        }
        else {
            setAssessmentInfo(currentstate => ({
                ...currentstate,
                [key]: val
            }));
        }
    }
 
  
    const handleCondition = (e) => {
        const key = e.target.name;

        setAssessmentInfo((currentstate) => ({
            ...currentstate,
            condition: key,
        })
        );

    };
    const handleEdges = (e) => {
        const key = e.target.name;

        setAssessmentInfo((currentstate) => ({
            ...currentstate,
            edges: key,
        })
        );

    };
    const handleExudate = (e) => {
        const key = e.target.name;

        setAssessmentInfo((currentstate) => ({
            ...currentstate,
            exudate: key,
        })
        );

    };
    const handlePhase = (e) => {
        const key = e.target.name;

        setAssessmentInfo((currentstate) => ({
            ...currentstate,
            phase: key,
        })
        );

    };
    return (
        <div>
            <div className='head-div'>
                <h6 style={{ padding: "1%" }}>Assessment Information of Wound</h6>
            </div>
            <div className='row' style={{ marginTop: "2%" }}>
                <div className='col-3'>
                    <div>
                        <span className="text-style-here">Localisation</span>
                        <input  type="text" value={assessmentInfo.localisation} name="localisation" onChange={(e) => { handleTitle(e) }} className='enquiryinput' />
                    </div>
                </div>
                <div className='col-9 row'>
                    <div className='col-3'>
                        <span className="text-style-here">Size(in mm)</span>
                        <input  type="number" value={assessmentInfo.size} name="size" onChange={(e) => { handleTitle(e) }} className='enquiryinput' />
                    </div>
                    <div className='col-3'>
                        <span className="text-style-here">Length(in mm)</span>
                        <input  type="number" value={assessmentInfo.length} name="length" onChange={(e) => { handleTitle(e) }} className='enquiryinput' />
                    </div>
                    <div className='col-3'>
                        <span className="text-style-here">Width(in mm)</span>
                        <input  type="number" value={assessmentInfo.width} name="width" onChange={(e) => { handleTitle(e) }} className='enquiryinput' />
                    </div>
                    <div className='col-3'>
                        <span className="text-style-here">Depth(in mm)</span>
                        <input type="number" value={assessmentInfo.depth} name="depth" onChange={(e) => { handleTitle(e) }} className='enquiryinput' />
                    </div>
                </div>

            </div>

            <div style={{ marginTop: "2%", backgroundColor: "rgba(244, 244, 244, 1)", padding: "1%" }}>
                <div className='row'>
                    <div className='col-3' style={{ padding: "1%" }}>
                        <div className='sub-head-div'>
                            <h6 style={{ padding: "1%" }}><b>Condition of wound</b></h6>
                        </div>
                        <div style={{ padding: "1%" }}>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="aseptic_wound"
                                    name="aseptic_wound"
                                    checked={assessmentInfo.condition === "aseptic_wound"}
                                    onChange={(e) => {  handleCondition(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Aseptic Wound</label></>
                            </div>

                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="contaminated_wound"
                                    name="contaminated_wound"
                                    checked={assessmentInfo.condition === "contaminated_wound"}
                                    onChange={(e) => {  handleCondition(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Contaminated Wound</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="infected_wound"
                                    name="infected_wound"
                                    checked={assessmentInfo.condition === "infected_wound"}
                                    onChange={(e) => { handleCondition(e) }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Infected Wound</label></>
                            </div>

                        </div>
                    </div>
                    <div className='col-3' style={{ padding: "1%" }}>
                        <div className='sub-head-div'>
                            <h6 style={{ padding: "1%" }}><b>Wound Edges</b></h6>
                        </div>
                        <div style={{ padding: "1%" }}>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="unobtrusive"
                                    name="unobtrusive"
                                    checked={assessmentInfo.edges === "unobtrusive"}
                                    onChange={(e) => { handleEdges(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Unobtrusive</label></>
                            </div>

                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="erythema"
                                    name="erythema"
                                    checked={assessmentInfo.edges === "erythema"}
                                    onChange={(e) => {handleEdges(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Erythema</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="necrosis"
                                    name="necrosis"
                                    checked={assessmentInfo.edges === "necrosis"}
                                    onChange={(e) => { handleEdges(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Necrosis</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="eczema"
                                    name="eczema"
                                    checked={assessmentInfo.edges === "eczema"}
                                    onChange={(e) => { handleEdges(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Eczema</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="oedema"
                                    name="oedema"
                                    checked={assessmentInfo.edges === "oedema"}
                                    onChange={(e) => { handleEdges(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Oedema</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="maceration"
                                    name="maceration"
                                    checked={assessmentInfo.edges === "maceration"}
                                    onChange={(e) => {handleEdges(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Maceration</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="contraction"
                                    name="contraction"
                                    checked={assessmentInfo.edges === "contraction"}
                                    onChange={(e) => {  handleEdges(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Contraction</label></>
                            </div>
                        </div>
                    </div>
                    <div className='col-3' style={{ padding: "1%" }}>
                        <div className='sub-head-div'>
                            <h6 style={{ padding: "1%" }}><b>Wound Exudate</b></h6>
                        </div>
                        <div style={{ padding: "1%" }}>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="serous"
                                    name="serous"
                                    checked={assessmentInfo.exudate === "serous"}
                                    onChange={(e) => {  handleExudate(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Serous</label></>
                            </div>

                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="serousanguineous"
                                    name="serousanguineous"
                                    checked={assessmentInfo.exudate === "serousanguineous"}
                                    onChange={(e) => { handleExudate(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Serousanguineous</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="purulent"
                                    name="purulent"
                                    checked={assessmentInfo.exudate === "purulent"}
                                    onChange={(e) => {  handleExudate(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Purulent</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="odor"
                                    name="odor"
                                    checked={assessmentInfo.exudate === "odor"}
                                    onChange={(e) => {  handleExudate(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Odor</label></>
                            </div>
                        </div>
                    </div>
                    <div className='col-3' style={{ padding: "1%" }}>
                        <div className='sub-head-div'>
                            <h6 style={{ padding: "1%" }}><b>Phases of wound healing</b></h6>
                        </div>
                        <div style={{ padding: "1%" }}>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="cleansing_phase"
                                    name="cleansing_phase"
                                    checked={assessmentInfo.phase === "cleansing_phase"}
                                    onChange={(e) => {  handlePhase(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Cleansing Phase</label></>
                            </div>

                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="granulation_phase"
                                    name="granulation_phase"
                                    checked={assessmentInfo.phase === "granulation_phase"}
                                    onChange={(e) => {  handlePhase(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Granulation Phase</label></>
                            </div>
                            <div >
                                <><input
                                    className=""
                                    type="radio"
                                    id="epithelization_phase"
                                    name="epithelization_phase"
                                    checked={assessmentInfo.phase === "epithelization_phase"}
                                    onChange={(e) => {  handlePhase(e)  }}
                                />{" "}<label className="" style={{ marginTop: "2%" }}>Epithelization Phase</label></>
                            </div>

                        </div>
                    </div>



                </div>

            </div>
            {
                <div className='text-center'>
                    <button  onClick={(e) => {props.onSuccess( assessmentInfo) }} className='cons-button'>Next</button>

                </div>}
        </div>

    )
}
export default Assessment

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import "./monitor.css"
import ProgressBar from 'react-bootstrap/ProgressBar';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import patient from "../../../assets/images/icons/nurse1.png";
import SimpleBar from 'simplebar-react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  Badge,

  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,

} from "react-bootstrap";
function NurseList(props) {
  const [nurselist, setNurseList] = useState([]);
  const [nurselistFree, setNurseListFree] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const [beds, setbeds] = useState([])
  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/assigned-nurses/?date=${props.date}&${TZ}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setNurseList(res.data.nurse);
          setNurseListFree(res.data.free_nurses)
        } else {

        }
      })
      .catch((err) => {

      });
  }, [props.date]);


  return (

    <div className="row ">
      <div className="col-8 ">
        <Card style={{ padding: "1%" }}>
          <Card.Title as="h4" style={{ marginBottom: "2%" }}><b>Nurses on duty</b></Card.Title>
          <div className='nurse-grid'>
            {nurselist.length > 0 && nurselist.map((item, index) => {
              const attendedBeds = item.attended_beds.length;
              const totalBeds = item.bedunits.length;
              const percentage = (attendedBeds / totalBeds) * 100;
              return (
                <div key={item.id} className='nurses-card'>
                  <div style={{ display: "flex" }}>
                    <div className="col-3" style={{ alignContent: 'center', padding: 0 }}>
                      <img src={item.photo && item.photo !== "" ? item.photo : patient} alt="Profile Photo" style={{
                        maxWidth: "none",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                      }} />
                    </div>
                    <div className="col-9" style={{ alignContent: 'center' }}>
                      <label style={{ margin: 0, color: '#000', fontSize: '16px', fontWeight: 500, textTransform: 'capitalize' }}>{item.name}</label>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: '5px' }}>
                    <div key={index} className="col-3" style={{ padding: 0, alignContent: 'center', position: 'relative' }}>
                      <div style={{
                        position: 'relative',
                        width: '35px',
                        height: '35px',
                        margin: 'auto',
                        borderRadius: '50%',
                        backgroundColor: '#fff'
                      }}>
                        <CircularProgressbar
                          value={percentage}
                          styles={buildStyles({
                            pathColor: '#0A875B',
                            trailColor: '#fff',
                          })}
                        />
                        <div style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '32px',
                          height: '32px',
                        }}>
                          <svg
                            onClick={() => { setbeds(item.bedunits); setShowModal(true); }}
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="#fff"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.5205 9.60938H20.4796C21.0104 9.60935 21.5213 9.81183 21.9079 10.1755C22.2946 10.5392 22.5279 11.0367 22.5604 11.5666L22.5642 11.6939V14.2363C22.9773 14.3536 23.3442 14.5956 23.6148 14.9292C23.8853 15.2628 24.0462 15.6717 24.0756 16.1002L24.0802 16.242V21.9271C24.0801 22.0711 24.0254 22.2098 23.9271 22.315C23.8287 22.4203 23.6941 22.4843 23.5504 22.4941C23.4067 22.5039 23.2646 22.4588 23.1529 22.3679C23.0412 22.277 22.9681 22.1471 22.9485 22.0044L22.9432 21.9271V20.2216H10.0569V21.9271C10.0569 22.0645 10.0072 22.1972 9.91689 22.3007C9.82659 22.4043 9.70185 22.4716 9.56575 22.4903L9.48843 22.4956C9.35105 22.4956 9.21832 22.4458 9.11478 22.3555C9.01125 22.2652 8.94391 22.1405 8.92523 22.0044L8.91992 21.9271V16.242C8.91992 15.2876 9.5612 14.4834 10.4359 14.2355V11.6939C10.4359 11.1631 10.6384 10.6522 11.0021 10.2656C11.3658 9.87893 11.8633 9.64559 12.3931 9.61317L12.5205 9.60938ZM21.9956 15.2945H11.0045C10.7699 15.2944 10.5436 15.3813 10.3694 15.5384C10.1952 15.6955 10.0855 15.9116 10.0615 16.145L10.0569 16.242V19.0845H22.9432V16.242C22.9431 16.0075 22.8561 15.7815 22.699 15.6074C22.5419 15.4334 22.3259 15.3238 22.0927 15.2998L21.9956 15.2945ZM20.4796 10.7464H12.5205C12.2859 10.7463 12.0596 10.8332 11.8854 10.9903C11.7113 11.1474 11.6015 11.3635 11.5775 11.5969L11.573 11.6939V14.1575H12.71C12.71 13.9564 12.7898 13.7636 12.932 13.6215C13.0742 13.4793 13.267 13.3994 13.468 13.3994H14.984C15.1697 13.3995 15.3489 13.4676 15.4876 13.591C15.6264 13.7144 15.715 13.8844 15.7367 14.0688L15.742 14.1575H17.2581C17.2581 13.9564 17.3379 13.7636 17.4801 13.6215C17.6222 13.4793 17.815 13.3994 18.0161 13.3994H19.5321C19.7178 13.3995 19.897 13.4676 20.0357 13.591C20.1744 13.7144 20.2631 13.8844 20.2848 14.0688L20.2901 14.1575H21.4271V11.6939C21.4272 11.4593 21.3403 11.2331 21.1832 11.0589C21.0261 10.8847 20.81 10.775 20.5766 10.7509L20.4796 10.7464Z"
                              fill="#0A875B"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="col-9" style={{ alignContent: 'center' }}>
                      <label style={{ margin: 0, color: '#0A875B', fontSize: '20px', lineHeight: '30px', fontWeight: 500 }}><b>{item.attended_beds.length}&nbsp;/&nbsp;{item.bedunits.length}</b></label>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* {nurselist.length > 0 && nurselist.map((item, index) => {
              const attendedBeds = item.attended_beds.length;
              const totalBeds = item.bedunits.length;
              const percentage = (attendedBeds / totalBeds) * 100;
              return (
                <div key={item.id} className='nurses-card'>
                  <div style={{ display: "flex" }}>
                    <div className="col-3" style={{ alignContent: 'center', padding: 0 }}>
                      <img src={item.photo && item.photo !== "" ? item.photo : patient} alt="Profile Photo" style={{
                        maxWidth: "none",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                      }} />
                    </div>
                    <div className="col-9" style={{ alignContent: 'center' }}>
                      <label style={{ margin: 0, color: '#000', fontSize: '16px', fontWeight: 500, textTransform: 'capitalize' }}>{item.name}</label>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: '5px' }}>
                    <div className="col-3" style={{ alignContent: 'center' }}>
                      <svg onClick={() => { setbeds(item.bedunits); setShowModal(true) }} width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="33" height="33" fill="#fff" />
                        <g clip-path="url(#clip0_558_1232)">
                          <rect width="1440" height="1024" transform="translate(-398 -772)" fill="white" />
                          <rect x="-18.75" y="-87.75" width="625.5" height="365.5" rx="2.75" fill="white" stroke="#fff" stroke-width="0.5" />
                          <rect x="-11" y="-50" width="200" height="104" rx="5" fill="#28A5D8" fill-opacity="0.06" />
                          <circle cx="16.9135" cy="16.0869" r="14.4712" fill="white" />
                          <path d="M12.5205 9.60938H20.4796C21.0104 9.60935 21.5213 9.81183 21.9079 10.1755C22.2946 10.5392 22.5279 11.0367 22.5604 11.5666L22.5642 11.6939V14.2363C22.9773 14.3536 23.3442 14.5956 23.6148 14.9292C23.8853 15.2628 24.0462 15.6717 24.0756 16.1002L24.0802 16.242V21.9271C24.0801 22.0711 24.0254 22.2098 23.9271 22.315C23.8287 22.4203 23.6941 22.4843 23.5504 22.4941C23.4067 22.5039 23.2646 22.4588 23.1529 22.3679C23.0412 22.277 22.9681 22.1471 22.9485 22.0044L22.9432 21.9271V20.2216H10.0569V21.9271C10.0569 22.0645 10.0072 22.1972 9.91689 22.3007C9.82659 22.4043 9.70185 22.4716 9.56575 22.4903L9.48843 22.4956C9.35105 22.4956 9.21832 22.4458 9.11478 22.3555C9.01125 22.2652 8.94391 22.1405 8.92523 22.0044L8.91992 21.9271V16.242C8.91992 15.2876 9.5612 14.4834 10.4359 14.2355V11.6939C10.4359 11.1631 10.6384 10.6522 11.0021 10.2656C11.3658 9.87893 11.8633 9.64559 12.3931 9.61317L12.5205 9.60938ZM21.9956 15.2945H11.0045C10.7699 15.2944 10.5436 15.3813 10.3694 15.5384C10.1952 15.6955 10.0855 15.9116 10.0615 16.145L10.0569 16.242V19.0845H22.9432V16.242C22.9431 16.0075 22.8561 15.7815 22.699 15.6074C22.5419 15.4334 22.3259 15.3238 22.0927 15.2998L21.9956 15.2945ZM20.4796 10.7464H12.5205C12.2859 10.7463 12.0596 10.8332 11.8854 10.9903C11.7113 11.1474 11.6015 11.3635 11.5775 11.5969L11.573 11.6939V14.1575H12.71C12.71 13.9564 12.7898 13.7636 12.932 13.6215C13.0742 13.4793 13.267 13.3994 13.468 13.3994H14.984C15.1697 13.3995 15.3489 13.4676 15.4876 13.591C15.6264 13.7144 15.715 13.8844 15.7367 14.0688L15.742 14.1575H17.2581C17.2581 13.9564 17.3379 13.7636 17.4801 13.6215C17.6222 13.4793 17.815 13.3994 18.0161 13.3994H19.5321C19.7178 13.3995 19.897 13.4676 20.0357 13.591C20.1744 13.7144 20.2631 13.8844 20.2848 14.0688L20.2901 14.1575H21.4271V11.6939C21.4272 11.4593 21.3403 11.2331 21.1832 11.0589C21.0261 10.8847 20.81 10.775 20.5766 10.7509L20.4796 10.7464Z" fill="#0A875B" />
                        </g>
                        <defs>
                          <clipPath id="clip0_558_1232">
                            <rect width="1440" height="1024" fill="white" transform="translate(-398 -772)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="col-9" style={{ alignContent: 'center' }}>
                      <label style={{ margin: 0, color: '#0A875B', fontSize: '20px', lineHeight: '30px', fontWeight: 500 }}><b>{item.attended_beds.length}&nbsp;/&nbsp;{item.bedunits.length}</b></label>
                    </div>
                  </div>
                </div>)
            })} */}

          </div>
        </Card>
      </div>
      <div className="col-4" style={{ paddingLeft: "1%" }} >
        <Card style={{ padding: "3%" }}>
          <Card.Title style={{ marginBottom: '15px' }} as="h4"><b>Free Nurses</b></Card.Title>

          <div style={{ display: "flex", flexDirection: "column" }}>
            {nurselistFree.length > 0 && nurselistFree.map((item, index) => {
              return (
                <div key={item.id} className='nurses-card' style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="col-3" style={{ alignContent: 'center', padding: 0 }}>
                    <img src={item.photo && item.photo !== "" ? item.photo : patient} alt="Profile Photo" style={{
                      maxWidth: "none",
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                    }} />
                  </div>
                  <div className="col-9" style={{ alignContent: 'center' }}>
                    <label style={{ margin: 0, color: '#000', fontSize: '16px', fontWeight: 500, textTransform: 'capitalize' }}>{item.name}</label>
                  </div>
                </div>)
            })}
          </div>
        </Card>
      </div>



      <Modal
        className="modal-mini modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center" style={{ alignItems: "center", justifyContent: "center", margin: "0px" }}>
          <div className="row">
            {beds.length > 0 && beds.map((item) => {
              return (
                <p className="col-3" style={{ textAlign: "left" }}>{item}</p>
              )
            })}
          </div>

        </Modal.Body>
        <div className="modal-footer">

          <Button
            className="btn-simple"
            type="button"
            variant="link"
            onClick={() => setShowModal(false)}
            style={{ textDecoration: 'none' }}
          >
            Close
          </Button>
        </div>
      </Modal>



    </div>
  )
}
export default NurseList;
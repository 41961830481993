import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import logo3 from "./assets/img/intpurple.png"
function PatientFooter() {

    return(
        <footer className="footer-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-sm-6 col-md-6">
                    <div className="single-footer-widget">
                        <a href="#" style={{ height: '63px' }} className=" d-inline-block"> <b style={{color:"#F0A639"}}>HorizonCommerce</b></a>
                        <ul className="footer-contact-info">
                            {/* <li><span>Hotline:</span> <a href="#">16768</a></li> */}
                            <li><span>Phone:</span> <a href="tel:+1234567898">(+123) 45678910</a></li>
                            <li><span>Email:</span> <a href="mailto:Contact@Intpurple.com">Contact@HorizonCommerce.net</a></li>
                            <li><span>Address:</span> <a href="#" target="_blank">USA</a></li>
                        </ul>
                        <ul className="social">
                            <li><a href="https://www.facebook.com/IntPurpleTech" target="_blank"><i className='bx bxl-facebook-square'></i></a></li>
                            <li><a href="https://www.intpurple.com/" target="_blank"><i className="bx bxl-wordpress"></i></a></li>
                            {/* <li><a href="#" target="_blank"><i className='bx bxl-instagram-alt'></i></a></li> */}
                            <li><a href="https://www.linkedin.com/company/intpurple/" target="_blank"><i className='bx bxl-linkedin-square'></i></a></li>
                            {/* <li><a href="#" target="_blank"><i className='bx bxl-pinterest'></i></a></li> */}
                        </ul>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-md-6">
                    <div className="single-footer-widget">
                        <h3>Information</h3>

                        <ul className="link-list">
                            <li><a href="about.html">About Us</a></li>
                            <li><a href="contact.html">Contact Us</a></li>
                            <li><a href="privacy-policy.html">Privacy Policy</a></li>
                            <li><a href="terms-of-service.html">Terms & Conditions</a></li>
                            <li><a href="customer-service.html">Delivery Information</a></li>
                            <li><a href="customer-service.html">Orders and Returns</a></li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-md-6">
                    <div className="single-footer-widget">
                        <h3>Customer Care</h3>

                        <ul className="link-list">
                            <li><a href="faq.html">Help & FAQs</a></li>
                            <li><a href="profile-authentication.html">My Account</a></li>
                            <li><a href="cart.html">Order History</a></li>
                            <li><a href="cart.html">Wishlist</a></li>
                            <li><a href="contact.html">Newsletter</a></li>
                            <li><a href="purchase-guide.html">Purchasing Policy</a></li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-md-6">
                    <div className="single-footer-widget">
                        <h3>Newsletter</h3>
                        <p>Sign up for our mailing list to get the latest updates & offers.</p>
                        <form className="newsletter-form" data-toggle="validator">
                            <input type="text" className="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off" />
                            <button type="submit" className="default-btn">Subscribe Now</button>
                            <div id="validator-newsletter" className="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-bottom-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <p>Copyright <i className='bx bx-copyright'></i>2023HorizonCommerce. </p>
                    </div>


                </div>
            </div>
        </div>
    </footer>
    )
}
export default PatientFooter;
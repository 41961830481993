import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function AddBmi(props) {
  const alert = useAlert();
    const [pulse, setPulse]=useState()
    const [bmi, setBmi]=useState()
    const [height, setHeight]=useState(0)
    const [weight, setWeight]=useState(0)

    

    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const [limitShow , setLimitShow]= useState(false)
    const [errorMsg , setErrorMsg]= useState('')
    const saveButtonRef = useRef(null)
    useEffect(() => {
      const handleKey = (e) => {
  
        if (e.altKey && e.key === 's') {
          e.preventDefault()
          saveButtonRef.current.click()
        }else if (e.key === 'Enter') {
          e.preventDefault()
          saveButtonRef.current.click()
        }
      
      }
      document.addEventListener("keydown", handleKey);
      
      return () => {
        document.removeEventListener("keydown", handleKey);
      }
      
    }, []);

    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = toISOString(Date().toLocaleString());
  function toISOString(s) {
    var months = {jan:'01',feb:'02',mar:'03',apr:'04',may:'05',jun:'06',
                  jul:'07',aug:'08',sep:'09',oct:'10',nov:'11',dec:'12'};
    var b = s.split(' ');
  
    return b[3] + '-' +
           months[b[1].toLowerCase()] + '-' +
           ('0' + b[2]).slice(-2) + 'T' +
           b[4] + b[5].substr(3);
  }

    const handleChange = (e)=> {
        const val = e.target.value
        if(val.length < 4){
            setBmi(e.target.value)
        }
        else {
            return false
        }
    }
    const handleChangeWeight =(e)=>
    {
      const weight=e.target.value;
      setWeight(weight);
    }
    const handleChangeHeight =(e)=>
    {
      const height=e.target.value;
      setHeight(height);
    }

    const handleCalculate = () =>{
      if (weight < 1 || weight > 500) {
      
        alert.error('Invalid weight');
      } else if (height < 1 || height > 280) {
        
        alert.error('Invalid height');
      } else {
        let bmiValue = (weight / ((height * height) / 10000)).toFixed(2);
        setBmi(bmiValue);
      }
    }

    const handleSubmit = () => {
      if (bmi) {
        const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
        const dataToUpdate =  {
          'patient_id':id,
          'weight': parseFloat(weight).toFixed(1),
          'height' : parseFloat(height).toFixed(1),
          'bmi': parseFloat(bmi).toFixed(1)
        }  
        axios.post(`${APIURL}/api/v1/patient/patient-bmi-calculation/`,dataToUpdate, { headers: {
          "Authorization" : 'Token '+str,
          'Content-Type': 'application/json'  }
        })
        .then(res=>{
          if(res.data.status==="success"){
            alert.success("Bmi added Successfully ")
            props.submitbmi() ;
            setBmi("")    
          }
          else{
            setErrorShow(true)
          }
        })
        .catch(err=>{
          setErrorShow(true)
        })

        const personalDetails = 
        {
            "height": parseFloat(height).toFixed(1),
            "weight": parseFloat(weight).toFixed(1)
        }
        axios.put(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`,personalDetails, { headers: {
           
          "Authorization" : 'Token '+str,
          'Content-Type': 'application/json'  }
    
    
        })
    
    
    
        .then(res=>{
       
         
    
        })
        .catch(err=>{
      
          
    })






      } else {
        setErrorShow(true)
      }
    }

   

   

     

    return (
        <div className='flex-col'>
<label>Enter Weight (kg)</label>
<input style={{width:'50%'}} type="number" min='0' max='500' placeholder="Add Weight" onChange={handleChangeWeight} className="form-control"/><br/>
<br></br>
<label>Enter Height (cm)</label>

<input style={{width:'50%'}} type="number" min='0' max='280' placeholder="Add Height" onChange={handleChangeHeight} className="form-control"/><br/>      
{ bmi ?

  <>
    <br></br>

    <label>Your BMI (Kg/m 2)</label>

    <input style={{width:'50%'}} type="number" min='0' placeholder="Your Bmi" value={bmi} disabled={true} className="form-control"/><br/>

    <button onClick={handleSubmit} ref={saveButtonRef} className='btn btn-primary btn-col'>Save</button>
  </>
  : 
  <>
    <button onClick={handleCalculate} ref={saveButtonRef} className='btn btn-primary btn-col' disabled={!weight || !height}>Calculate</button>
  </>
}

          




        </div>
    )
}

export default AddBmi

import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AvailableSlots from './AvailableSlots';
function DoctorList(data) {
    const [expand, setExpand] = useState();
    const handleDetail = (index) => {
        if (expand !== index) {
          setExpand(index);
        } else {
          setExpand();
        }
      };
      useEffect(() => {setExpand() },[data])
    return (
        <div className='row' style={{ paddingLeft: "1%", paddingRight: "1%" }}>

            {data.doctors.length > 0 ? data.doctors.map((item,index) => {
                return (<div style={{ paddingLeft: "2%", paddingRight: "2%", paddingBottom: "1%" }} className='col-6'>
                    <div className='each-list-doc row'>
                        <div className='col-3'>
                            <div className="flex align-items-center" style={{padding:"6%"}}>
                                <img style={{ borderRadius: "50%", width: "80px", height: "80px" }} src={item.photo} alt="image" />

                            </div>
                        </div>
                        <div className='col-5'>
                            <span className='doc-name'>{item.full_name.slice(0, 3) === "Dr." ? item.full_name : "Dr." + item.full_name}</span><br />
                            <span className='doc-details'>{item.specialization},{item.experiance}{" "}Years experiance</span><br />
                            <span className='doc-details'>{item.hospital},{" "}{item.location}</span><br />
                            <span className='doc-details'>⭐️ 4.3 (130 reviews)</span><br />

                        </div>
                        <div style={{ paddingTop: "2%" }} className="col-4">
                            {item.fees.length===2?
                                item.fees[0].consult_mode==="offline"?
                                <div style={{marginLeft:"14%"}}><span  className='doc-details'>Pay{" "}<b>${item.fees[0].fees }</b>{" "}for <b>Hospital Visit</b> </span><br/>
                                <span className='doc-details'>Pay{" "}<b>${item.fees[1].fees }</b>{" "}for <b>Video Consult</b></span></div>:
                                <div style={{marginLeft:"14%"}}><span className='doc-details'>Pay{" "}<b>${item.fees[1].fees }</b>{" "}for <b>Hospital Visit</b></span><br/>
                                <span className='doc-details'>Pay{" "}<b>${item.fees[0].fees }</b>{" "}for <b>Video Consult</b></span></div>
                                :item.fees.length===1?
                                     item.fees[0].consult_mode==="offline"?
                                      <div style={{marginLeft:"14%"}}><span className='doc-details'>Pay{" "}<b>${item.fees[0].fees }</b>{" "}for <b>Hospital Visit</b> </span><br/></div>:
                                      <div style={{marginLeft:"14%"}}><span className='doc-details'>Pay{" "}<b>${item.fees[0].fees }</b>{" "}for <b>Video Consult</b></span></div>
                                     :""}
                                     
                                     {item.fees.length>0? <button   onClick={() => handleDetail(index)} style={{marginTop:"2%"}} className='book-appointment-btn'>Schedule Appointment</button>:""}

                        </div>
                       
                    </div>
                    {expand === index ? (
                <>
                 <AvailableSlots doc={item}/>
                </>
              ) : null}
                </div>)
            }) : ""}

        </div>
    )
}
export default DoctorList
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { APIURL, TZ } from "../../../../Global";
import { SOCKETURL } from "../../../../Global";
import "../monitor.css"
import SimpleBar from 'simplebar-react';
import '../monitor.css'
function PatientsList(props) {
  const [patientList, setPatientList] = useState([])
  useEffect(() => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')


    axios.get(`${APIURL}/api/v1/service-provider/monitored-patients/?${TZ}`, {
      params: { 'patient_id': props.data },
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          setPatientList(res.data.details)
        }


      })
      .catch(err => {

      })

  }, [])
  return (
    <div style={{  }}>
      <label style={{ fontSize: 16, fontWeight: 600, color: '#0A8187' }}>Patients</label>
      <div className="pat-list" style={{ maxHeight: "200px", width: '200px', overflowX: 'hidden', overflowY: 'auto' }}>
        {patientList.length > 0 && patientList.map((i, index) => {
          return (
            <>
              <div className="row" style={{marginRight:'1px'}}>
                <span style={{ textTransform: 'capitalize', width: '230px', backgroundColor: "aliceblue", textAlign: "left", border: "2px solid white", borderRadius: "5px", }} ><b>{i.patient_name}</b><br />
                  <small>{i.bedunit_code}</small>
                </span>
              </div>

            </>
          )
        })
        }
      </div>
    </div>
  )
}
export default PatientsList;

import React, { useState, useEffect, useRef,useContext } from "react";
import { Link,  useNavigate } from "react-router-dom";
import "./addpharmuser.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import "./dob.css";
import {DatePicker} from 'rsuite';
import { APIURL,TZ } from "../../Global";
import Form from 'react-bootstrap/Form'
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";

import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../common/SubmitConfirm";

function AddPharmacyUser() {
  const [pharmUserDatas, setPharmUserDatas] = useState({
    name: '',
    phone: '',
    code: '',
    email: '',
    country: 'Malta',
    state: '',
    location: "",
    qualification: "",
    dob: "",
    gender: "",
    region: ""


  });

  const [error, setError] = useState({
    email: '',
    phone: "",
  });
  const [isvalidnum,setIsValidnum]=useState(true)
  const [dobValue, setDobValue] = useState();
  const [, setFormattedDob] = useState("");
  const alert = useAlert();
  const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const navigate = useNavigate();
  const inputRefs = useRef([])
  const submitButtonRef = useRef (null)
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {  
      e.preventDefault()

      if(index === 5 ) {
        submitButtonRef.current.click();
      }else{
      e.preventDefault();    
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        // move focus to the next input if it exists
        inputRefs.current[nextIndex].focus();
      }
    }
    }
  };
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const handleConfirm = () => {
    closeConfirmationPopup()
    handleSubmit();
   
  };

  const handleCancel = () => {
    closeConfirmationPopup()
  };
  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()

      const activeElement = document.activeElement;
      if (
        !inputRefs.current.includes(activeElement) &&
        activeElement !== submitButtonRef.current
      ) {
        submitButtonRef.current.click();
      }
    }else if (e.altKey && e.key === 's') {

      submitButtonRef.current.click(); 
    }else if (e.altKey && e.key === 'c') {
      navigate("../");
    }
  };
  const validatePhoneInput = (value,) => {
  
    if (value.length !== 11&&value.length !== 3) {
      setIsValidnum(false)
        return "Invalid phone number";

    }
    else {
      setIsValidnum(true)
        return true
    }


}
  useEffect(() => {
    document.addEventListener("keydown", handleKey);
  
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);
 


  const handleDOB = (date) => {

    setDobValue(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))

      let DateExtracted = dateUpdated.toISOString().split('T')[0];

      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);

      setPharmUserDatas(currentstate => ({
        ...currentstate,
        dob: DateExtracted
      }));
    }
    setError((currentstate) => ({
      ...currentstate,
      ["dob"]: "",
    }));
  }

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setPharmUserDatas(currentstate => ({
      ...currentstate,
      gender: genderOpted

    }))
    setError((currentstate) => ({
      ...currentstate,
      ["gender"]: "",
    }));
  }


  const handleSubmit = () => {

    if (rawPhone !== null && rawPhone !== "" && rawPhone !== ' ') {
      setValidateRawPhoneMsg("success");
      const tokenStr = sessionStorage.getItem('usertoken');

      if (tokenStr) {

        const v = tokenStr;
        let v2 = v.replace(/["]+/g, '');


        const dataToSend = {
          hospital_connected: true,
          name: pharmUserDatas.firstname + " " +pharmUserDatas.lastname,
          state: pharmUserDatas.state,
          country: pharmUserDatas.country,
          email: pharmUserDatas.email,
          qualification: pharmUserDatas.qualification,
          phone: pharmUserDatas.phone,
          code: pharmUserDatas.code,
          dob: pharmUserDatas.dob,
          gender: pharmUserDatas.gender,
          // location: pharmUserDatas.location,
          region: pharmUserDatas.location
         
        }



        axios.post(`${APIURL}/api/v1/service-provider/pharmacy-user/`, dataToSend, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + v2
          }
        })
          .then(res => {


            if (res.data.status === "success") {
              alert.success('Added successfully')
              navigate('/pharmacymanagement')


            } else {

              alert.error("Error occured. Try again!")

            }




          })
          .catch(err => {
            alert.error("Error in submission");

          })



      }
    }
    else {
      setValidateRawPhoneMsg("error");
    }


  }

  const handleCheckEmail = (e) => {
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError(currentstate => ({
        ...currentstate,
        email: ''
      }));
      e.preventDefault();
      let key = e.target.name;


      const data = {
        [e.target.name]: e.target.value
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`
              }));
              setIsValidEmail(false);
            }
            else {

              setIsValidEmail(true);

            }


          }
          else {

            setIsValidEmail(true);

          }

        })
        .catch(err => {


        })
    }
  }


  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {

      setError(currentstate => ({
        ...currentstate,
        phone: ''
      }));



      const data = {
        code: dialCode,
        mobile_number: rawPhone
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                phone: "This phone number is already registered!"
              }));
              setIsValidPhone(false);
            }
            else {

              setIsValidPhone(true);

            }



          }
          else {

            setIsValidPhone(true);

          }

        })
        .catch(err => {


        })

    }


  }

  const handleContinue = async (e) => {

    e.preventDefault();

    if (validate() && isValidEmail && isValidPhone) {
      setShowConfirmation(true);
    }



  }

  const handleQualification = e => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    let arr = [];
    if (val && val.includes(",")) {
      arr = val.split(",");
    } else {
      arr.push(val);
    }
    setPharmUserDatas((currentstate) => ({
      ...currentstate,
      [key]: arr,
    }));

  }
  const handleChange = e => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setPharmUserDatas(currentstate => ({
      ...currentstate,
      [key]: val
    }));
    setError((currentstate) => ({
      ...currentstate,
      [key]: "",
    }));
  }


  const handleCountry = (val) => {
    setPharmUserDatas(currentstate => ({
      ...currentstate,
      country: val
    }));


  }
  const handleRegion = (val) => {
    setPharmUserDatas(currentstate => ({
      ...currentstate,
      state: val
    }));
    setError((currentstate) => ({
      ...currentstate,
      ["state"]: "",
    }));

  }

  const handlePhoneInput = (value, data, event, formattedValue) => {
    setRawPhone(value.slice(data.dialCode.length));
    setDialCode(value.slice(0, data.dialCode.length))
    setPharmUserDatas(currentstate => ({
      ...currentstate,
      phone: value.slice(data.dialCode.length),
      code: value.slice(0, data.dialCode.length)

    }));

    setError((currentstate) => ({
      ...currentstate,
      "phone": "",
    }));

  }

  const validate = () => {

    let input = pharmUserDatas
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["firstname"] || input["firstname"] === "") {
      isValid = false;
      errors["firstname"] = "Enter first name";
    }

    if (!input["lastname"] || input["lastname"] === "") {
      isValid = false;
      errors["lastname"] = "Enter last name";
    }
    if (!isvalidnum) {
      isValid = false;
      //errors["phone"] = "Enter valid phone number";
    }
    if (
      typeof pharmUserDatas.gender == "undefined" ||
      pharmUserDatas.gender === "" ||
      pharmUserDatas.gender === null
    ) {
      isValid = false;
      errors["gender"] = "Select gender";
    }
    if (typeof input["firstname"] !== "undefined") {
      var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern1.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }

    if (input["firstname"] && typeof input["firstname"] !== "undefined") {
      if (input["firstname"].length > 0 && input["firstname"].length < 3) {
        isValid = false;
        errors["firstname"] = "Must contain atleast 3 characters";
      }
    }

    if (typeof input["lastname"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern2.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }
   

    if (!input["country"] ) {
      isValid = false;
      errors["country"] = "Select a country";
    }
    if (!input["location"] ) {
      isValid = false;
      errors["location"] = "Enter location";
    }
    if (!input["state"]) {
      isValid = false;
      errors["state"] = "Select a region";
    }

    if (!input['qualification'] || input['qualification'] === "") {
      isValid = false;
      errors["qualification"] = "Enter qualification";
    }

    if (!dialCode || dialCode === "" || !rawPhone || rawPhone === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }

    if (typeof dobValue == "undefined" || dobValue === "" || dobValue === null) {
      isValid = false;
      errors["dob"] = "Enter your date of birth";
    }
    // if (typeof pharmUserDatas.gender == "undefined"  ) {
    //   isValid = false;
    //   errors["gender"] = "Select gender";
    // }

    if (typeof input["email"] !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Enter a valid email address.";
      }
    }

    if (typeof input["name"] !== "undefined") {

      var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern1.test(input["name"])) {
        isValid = false;
        errors["name"] = "Special Characters are not allowed";
      }
    }



    if (!input["email"]) {
      isValid = false;

      errors["email"] = "Enter email address.";
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }

    if (rawPhone.length > 0 && rawPhone.length < 8) {
      isValid = false;
      // errors["phone"] = "Enter valid phone number ";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;

  }
  return ( <div style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} className=''>

<div className='row'>
                <div className="col-lg-2 col-md-2">
                    <h4
                        style={{
                            color: "#245693",
                            marginTop: "8%",
                            fontFamily: "inter",
                            fontWeight: "700",
                            marginLeft: "20px",
                        }}
                    >
                        Users Classifications
                    </h4>
                    <div className="leftdivdeathrepo">
                        
                        <button onClick={e => { navigate("/doctormanagement") }}className="deathreportbutton"  style={{ backgroundColor:  "#F8F8F8", color:  "rgb(0, 68, 124)" }}>
                            {" "}
                             <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 48 48"><g fill="none"><path d="M0 0h48v48H0z"/><path fill="currentColor" fill-rule="evenodd" d="M34 16c0 5.523-4.477 10-10 10s-10-4.477-10-10S18.477 6 24 6s10 4.477 10 10m-2 0a8 8 0 1 1-16 0a8 8 0 0 1 16 0M17.914 28.855l.011.022l.075.147h11.749c.229-.434.748-1.126 1.251-1.011c1.13.257 2.268.615 3.361 1.056l.033-.016l.011.022l.008.015C38.528 30.762 42 33.596 42 36.57V42H6v-5.43c0-3.775 5.596-7.327 11-8.557c.441-.1.703.42.914.842m14.79 1.728a20 20 0 0 0-1.301-.407l-.446.848H16.793l-.414-.787l-.36.108q-.011.098-.016.224c-.013.345.013.754.07 1.17a8 8 0 0 0 .28 1.281a3 3 0 1 1-1.957.444l-.008-.028a10 10 0 0 1-.297-1.426a10 10 0 0 1-.084-.928c-1.236.528-2.389 1.166-3.355 1.87C8.73 34.356 8 35.668 8 36.57V40h32v-3.43c0-.903-.73-2.215-2.652-3.617a16.6 16.6 0 0 0-2.666-1.562A10.5 10.5 0 0 1 34.434 33H35a1 1 0 0 1 .894.553l1 2c.07.139.106.292.106.447v2a1 1 0 0 1-1 1h-2v-2h1v-.764L34.382 35h-2.764L31 36.236V37h1v2h-2a1 1 0 0 1-1-1v-2c0-.155.036-.308.106-.447l1-2A1 1 0 0 1 31 33h1.362l.037-.124c.094-.321.178-.72.235-1.136c.056-.412.082-.815.07-1.157M17 36c0 .574-.462 1.015-1 1.015s-1-.44-1-1.015c0-.574.462-1.015 1-1.015s1 .44 1 1.015" clip-rule="evenodd"/></g></svg>{" "}
                            &nbsp;&nbsp;Doctor
                        </button>
                        <button className="deathreportbutton" onClick={e => { navigate("/bdomanagement") }} style={{ backgroundColor:  "#F8F8F8", color:  "rgb(0, 68, 124)" }}>
                            {" "}
                              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4c-3.855 0-7 3.145-7 7c0 2.379 1.21 4.484 3.031 5.75C7.926 18.352 5 22.352 5 27h2c0-4.398 3.191-8.074 7.375-8.844L15 20h2l.625-1.844C21.809 18.926 25 22.602 25 27h2c0-4.648-2.926-8.648-7.031-10.25C21.789 15.484 23 13.379 23 11c0-3.855-3.145-7-7-7m0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5m-1 15l-1 6h4l-1-6z"/></svg>{" "}
                            &nbsp;&nbsp;BDO
                        </button>
                        <button onClick={e => {  navigate("/pharmacymanagement") }}style={{ backgroundColor:"rgb(0, 68, 124)" , color:  "white"  }}className="deathreportbutton"  >
                            {" "}
                              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor"><path d="M.5 6.5V10c0 4.56 2.5 8.5 7 10.36v.14l-.123.123a12 12 0 0 1-3.119 2.248l-.758.379v.25h17v-.25l-.758-.38a12 12 0 0 1-3.119-2.247L16.5 20.5v-.14c4.5-1.86 7-5.8 7-10.36V6.5z"/><path d="M10.5 12.5v-3h3v3h3v3h-3v3h-3v-3h-3v-3zm9-12l-6 6h4l4-4V2A1.5 1.5 0 0 0 20 .5z"/></g></svg>{" "}
                            &nbsp;&nbsp;Pharmacist
                        </button>
                        <button className="deathreportbutton" onClick={e => { navigate("/nursemanagement") }} style={{ backgroundColor:  "#F8F8F8", color:  "rgb(0, 68, 124)" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 48 48"><g fill="none"><path d="M0 0h48v48H0z"/><path fill="currentColor" d="M27 13v-2h-2V9h-2v2h-2v2h2v2h2v-2z"/><path fill="currentColor" fill-rule="evenodd" d="m31.32 16.767l1.978-5.606c.536-1.52-.228-3.24-1.829-3.747C29.61 6.824 26.57 6 24 6s-5.61.824-7.47 1.414c-1.6.508-2.364 2.228-1.828 3.747l1.979 5.606a8 8 0 1 0 14.639 0M24 8c-2.24 0-5.029.738-6.865 1.32c-.46.146-.73.658-.547 1.176l2.024 5.734c3.587-1.14 7.189-1.14 10.776 0l2.024-5.734c.183-.518-.087-1.03-.547-1.175C29.029 8.738 26.239 8 24 8m5.806 10.481h-.001a1 1 0 0 1-.156-.045c-3.774-1.415-7.524-1.415-11.298 0a1 1 0 0 1-.157.045a6 6 0 1 0 11.613 0" clip-rule="evenodd"/><path fill="currentColor" d="M34 34h2v2h-2v2h-2v-2h-2v-2h2v-2h2z"/><path fill="currentColor" fill-rule="evenodd" d="M16.879 28S6 31.393 6 35.467V42h36v-6.533C42 31.393 31.121 28 31.121 28l-5.972 4.193a2 2 0 0 1-2.298 0zm14.562 2.22l-5.142 3.61a4 4 0 0 1-4.597 0l-5.143-3.61a38 38 0 0 0-4.227 1.776c-1.296.646-2.482 1.363-3.316 2.092C8.115 34.875 8 35.343 8 35.467V40h32v-4.533c0-.124-.115-.592-1.016-1.38c-.834-.728-2.02-1.445-3.317-2.092a38 38 0 0 0-3.547-1.528q-.376-.14-.679-.248" clip-rule="evenodd"/></g></svg>{" "}
                            &nbsp;&nbsp;Nurse
                        </button>
                        <button className="deathreportbutton" onClick={() => navigate("/carermanagement")} style={{ backgroundColor:  "#F8F8F8", color:  "rgb(0, 68, 124)" }}>
                            {" "}
                             <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M11.28 9.34a1.91 1.91 0 0 0 0-2.77a2.07 2.07 0 0 0-2.85 0L7 8L5.57 6.57a2.07 2.07 0 0 0-2.85 0a1.91 1.91 0 0 0 0 2.77L7 13.5zM7 4.5a2 2 0 1 0 0-4a2 2 0 0 0 0 4"/></svg>{" "}
                            &nbsp;&nbsp;Carer
                        </button>


                        
                    </div>
                </div>
                <div className="col-lg-10 col-md-10" style={{paddingTop:"2%",paddingBottom:"2%"}}>
    <div className="">

     
     
       
        <div className="add-pharm-div">
        <div className="form-group col-lg-6 ">
              <label className="">* First Name: </label>
              <input
                ref={el => inputRefs.current[0] = el}
                onKeyDown={(e) => handleKeyDown(e,0)}
                className="form-control"
                type="text"
                maxLength="100"
                placeholder=""
                name="firstname"
                onChange={handleChange}
              />
              {error.firstname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.firstname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 ">
              <label className="">* Last Name: </label>

              <input
                ref={el => inputRefs.current[1] = el}
                onKeyDown={(e) => handleKeyDown(e,1)}
                className="form-control"
                type="text"
                maxLength="100"
                placeholder=""
                name="lastname"
                onChange={handleChange}
              />
              {error.lastname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.lastname}
                </div>
              ) : null}
            </div>
            <div className="form-group dob-check col-lg-6 ">
            <div className="dob-container form-control">
              <span >* Date of Birth : </span>
              <DatePicker
              
                onChange={date => { handleDOB(date) }}

                maxDate={new Date()}
                value={dobValue}

              />
            </div>
            {error.dob ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.dob}</div> : null}
          </div>
          <div className="form-group gender-radio-check col-lg-6 ">
            <div className="form-control">
              <span>* Gender: </span>
              <input
                type="radio"
                id="male"
                name="gender"
                value="male"
                checked={pharmUserDatas.gender === "male"}
                onChange={handleGenderRadioButton}
              />
              <label htmlFor="male">Male</label>

              <input
                type="radio"
                id="female"
                name="gender"
                value="female"
                checked={pharmUserDatas.gender === "female"}
                onChange={handleGenderRadioButton} />

              <label htmlFor="female">Female</label>

              <input
                type="radio"
                id="others"
                name="gender"
                value="others"
                checked={pharmUserDatas.gender === "others"}
                onChange={handleGenderRadioButton}
              />
              <label htmlFor="others">Other</label>

            </div>
            {error.gender ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.gender}</div> : null}
          </div>
          <div className="form-group col-lg-6 ">

            <label className="">* Email Address: </label><input ref={el => inputRefs.current[2] = el} onKeyDown={(e) => handleKeyDown(e,2)} className="form-control " type="email" maxLength='50' placeholder="" name="email" onChange={handleChange} onBlur={handleCheckEmail} />
            {error.email ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.email}</div> : null}
          </div>
          <div className="form-group col-lg-6  ">
            <label className="">* Phone: </label>
            <PhoneInput
              inputProps={{
                ref: el => inputRefs.current[3] = el,
                tabIndex: 0,
                onKeyDown: e => handleKeyDown(e, 3),
              }}
              country={"mt"}
                onlyCountries={["mt"]}
                countryCodeEditable={false}
              onChange={handlePhoneInput}
              onBlur={handleCheckExistingPhone}
              isValid={validatePhoneInput}
            />
            {error.phone ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.phone}</div> : null}

          </div>
       
         

          <div className="form-group col-lg-6 ">


            <label className="">* Qualifications: </label>
            <input ref={el => inputRefs.current[4] = el} onKeyDown={(e) => handleKeyDown(e,4)} className="form-control" type="text" maxLength='200' placeholder="" name="qualification" onChange={handleChange} />
            {error.qualification ? <div style={{ zIndex: 'auto' }} className="error-validation-msg">{error.qualification}</div> : null}
          </div>
       

         

          <div className="form-group  col-lg-6 country-selector-pharm" >
            <label className=""> Country: </label>
              <input
                className="form-control"
                type="text"
                value={pharmUserDatas.country}
                maxLength="150"
                disabled={true}
              />
            {/* <CountryDropdown
             disabled={true}
              value={pharmUserDatas.country}
              onChange={(val) => handleCountry(val)}
            /> */}
            {error.country ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.country}</div> : null}
          </div>
         
          <div className="form-group col-lg-6 ">
              <label className="">* Location/ City : </label>
              <input
              ref={el => inputRefs.current[5] = el}
              onKeyDown={(e) => handleKeyDown(e,5)}
                className="form-control"
                type="text"
                maxLength="200"
                placeholder=""
                name="location"
                onChange={handleChange}
              />
              {error.location ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.location}
                </div>
              ) : null}
            </div>
            <div className="form-group  col-lg-6 region-selector-dr">
            <label className="">* Region: </label>
            <RegionDropdown
             className="form-control"
            defaultOptionLabel={'Select Region'}
              country={pharmUserDatas.country}
              value={pharmUserDatas.state}
              onChange={(val) => handleRegion(val)}
            />
            {error.state ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.state}</div> : null}
            
          </div>

        </div>

        




        <div className="form-button">



        <button  onClick={handleContinue} className="btn btn-primary btn-col"> Submit Data</button>
        </div>
      
      
      {validateRawPhoneMsg === "error" ?
        <h5 style={{ color: "red" }}>

          Phone number field is mandatory!
        </h5>
        :
        ""
      }
    </div>
    </div>
    </div>
    {showConfirmation && (
      <SubmitConfirmationPopup
        message="Are you sure you want to submit the form?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    )}
  </div>
  )
}

export default AddPharmacyUser;


import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Button } from 'react-bootstrap';
function EditSubService({ onSuccess,sub_service,id }) {
    
    const [serviceName, setServiceName] = useState(sub_service)
    const [error, setError] = useState("")

  


    
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
      
            setServiceName(val);
        
       
    };
    const editService = (e) => {
        e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {
            sub_service    : serviceName,
           

        }

        axios
            .put(`${APIURL}/api/v1/staff/sub-service-detail/${id}/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {

                    onSuccess();
                    //setRefresh(true)
                    //setCompleteShow(true)
                    //setSuccessShow(false)
                } else {
                    setError(res.data.message)
                }
            })
            .catch((err) => {
                //setErrorShow(true)
            });
        // setSuccessShow(false)






    }

    return (
        <div className="appForm" >
            <div className="">

            <div className="title-of-tasks" style={{}}><h5>Edit SubService</h5></div><br/>
                <form className="formFields" onSubmit={editService}>

                    <div className="formField">

                        <input
                            type="text"
                            className="formFieldInput"
                            name="Servicename"
                            value={serviceName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                   
                    <div className="form-heading title-of-tasks">
                        <button className=" btn formFieldButton">Save Changes</button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}




                    </div>


                </form>

            </div>


        </div>

    )
}
export default EditSubService
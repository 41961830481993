
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { NavDropdown } from "react-bootstrap";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton, Badge } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import Reports from './Reports';
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

import isAfter from 'date-fns/isAfter';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";

import Nav from 'react-bootstrap/Nav';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

function PatientReports() {
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState('')
  const [registerData, setRegisterData] = useState([])
  const [timePeriod, setTimePeriod] = useState("month")
  const [fromDate, setFromDate] = useState(moment((new Date(new Date().getFullYear(), new Date().getMonth(), 1))).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment((new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))).format('YYYY-MM-DD'));
 



  useEffect(() => {
    apiCallFilter(1,10);
 
  }, []);




  const exportRegisterReport = () => {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Patient Registration Report";
    const headers = [["Name", "Gender", "Age", "Date"]];
    // var dateStringWithTime = moment(now).format('YYYY-MM-DD HH:MM:SS');

    const data = registerData.map(elt => [elt.patient_name, elt.gender, elt.age, moment(new Date(elt.connected_on)).format('YYYY-MM-DD')]);


    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")


  };

   
  useEffect(() => {
    if (fromDate) {
      apiCallFilter(1, perPage)
    }

  }, [fromDate,toDate]);


  const handleChangeValue = (val) => {
    registerData.splice(0, registerData.length + 1)
    setTimePeriod(val)
    if (val == 'day') {
      var today = new Date();
      var FormatedCurrentDate = moment((today)).format('YYYY-MM-DD');
      setFromDate(FormatedCurrentDate)
      setToDate(FormatedCurrentDate)

    }
    if (val == 'week') {
      var startOfWeek = moment().startOf('week').toDate();
      var endOfWeek = moment().endOf('week').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setFromDate(FormatedFirstday)
      setToDate(Formatedlastday)


    }
    if (val == 'month') {

      var date = new Date();
      var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      var FormatedfirstDayOfMonth = moment((firstDayOfMonth)).format('YYYY-MM-DD');
      var FormatedlastDayOfMonth = moment((lastDayOfMonth)).format('YYYY-MM-DD');
      setFromDate(FormatedfirstDayOfMonth)
      setToDate(FormatedlastDayOfMonth)



    }
    if (val == 'year') {
      var currentDates = new Date();
      var theFirst = new Date(currentDates.getFullYear(), 0, 1);
      var theLast = new Date(currentDates.getFullYear(), 11, 31);
      var FormatedfirstDayOfYear = moment((theFirst)).format('YYYY-MM-DD');
      var FormatedlastDayOfYear = moment((theLast)).format('YYYY-MM-DD');
      setFromDate(FormatedfirstDayOfYear)
      setToDate(FormatedlastDayOfYear)
    }

  }


  const apiCallFilter = (page, per_page) => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const a = fromDate;
    const b = toDate;

    //  2021-10-12
    axios.get(`${APIURL}/api/v1/service-provider/hospital-registration-reports/?start_date=${a}&end_date=${b}&page=${page}&per_page=${per_page}&${TZ}`,{
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setHasNext(res.data.has_next)
          setLoad('')
          let datalist = res.data.patients
          setRegisterData([...registerData,...datalist])

        }
        else {
          setLoad('Error in Fetching Data')
        }

      })


      .catch(err => {
        setLoad('Error in Fetching Data')
      })


  }


  const handleEvent = (event, picker) => {
    registerData.splice(0, registerData.length + 1)
    const a = moment(event[0]).format('YYYY-MM-DD');
    let b = moment(event[1]).format('YYYY-MM-DD');
                                                        
    setFromDate(a);
    setToDate(b);
    
  }

  const renderTable = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize" }}>{registerData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }
  



  const renderDate = (cellInfo) => {
    let dateString = registerData[cellInfo.index][cellInfo.column.id]
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div style={{ color: "black" }}>
        {updatedDate.slice(4, 16)}

      </div>
    )
  }




  const columns = [
    {
      Header: " Patient Name",
      accessor: "patient_name",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      // width: 220

    },

    {
      Header: "Gender",
      accessor: "gender",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      // width: 200

    },
    {
      Header: "Age",
      accessor: "age",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,
      // width: 200

    },
    {
      Header: "Date",
      accessor: "connected_on",
      Cell: renderDate,
      // style: {
      //   backgroundColor: "rgba(0,0,0,0.1)"
      // },
      sortable: true,
      filterable: true,
      // width: 200

    },


  ]
  const handlePageChange = page => {
    if (hasNext) {
      apiCallFilter(page + 1, perPage);
    }
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage)
    registerData.splice(0, registerData.length + 1)
    apiCallFilter(1, newPerPage);

  }
  useEffect(() => {
    apiCallFilter(page, perPage);
  }, [perPage]);

  const display = <ReactTable
    columns={columns}
    data={registerData}
    defaultPageSize={perPage - 1}
    className="chol-table"
    resizable
    minRows={perPage - 1}
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    pagination
    paginationServer
    onPageChange={(pageIndex) => { handlePageChange(pageIndex); setPage(pageIndex + 1) }}
    paginationTotalRows={totalRows}
    onPageSizeChange={handlePerRowsChange}
  ></ReactTable>

 

  const CsvRegisterheaders = [
    { label: "Patient Name", key: "patient_name" },
    { label: "Gender", key: "gender" },
    { label: "Age", key: "age" },
    { label: "Date", key: "connected_on" }
  ];


  return (
    <>

      <div className="patient-report">


        <Nav class="row" style={{ display: "flex" }}>


          <span class="col-10">
            <h4 className="title-of-reportpage"> Registration Report</h4>
          </span>

          <span style={{ display: "flex", marginTop: "1%" }} class="col-2">
            
            <button  onClick={() =>setShow(!show)} className='btnexport'><i class="fa fa-upload"> &nbsp;&nbsp; Export</i></button>
          
           
          </span>


        </Nav>
        {show?<div className='exportDisplay' >
          <button style={{ border: "none", backgroundColor: " transparent", color: "var(--bs-link-color)" }} onClick={exportRegisterReport}><i class="fa fa-file-pdf-o"> &nbsp;&nbsp; PDF</i></button>
       <CSVLink
                  style={{ marginLeft: "3.5%" }}
                  data={registerData}
                  headers={CsvRegisterheaders}

                ><i class="fa fa-file-excel-o">
                    &nbsp;&nbsp;&nbsp;&nbsp;CSV</i>
                </CSVLink>
      </div>:""}
        <Nav class="row" style={{ marginTop: "1%" }}>
          <span class="col-5"> </span>
          <span class="col-6 togglegroup" style={{ marginBottom: "1%", }}>
            <ToggleButtonGroup style={{ marginTop: "1%", fontSize: "small", }} type="radio" name="options" defaultValue={timePeriod} onChange={handleChangeValue}>
            <DateRangePicker
              appearance="default"
              style={{ width: "220px" }}
              placeholder={"Select Date Range"}
              
              onChange={handleEvent}
                disabledDate={(date) =>
                  isAfter(date, new Date())}
                  
              >

                <ToggleButton  id="tbg-radio-4" value={'custom'} className="btn btn-light toggle-btns custombtn">

                </ToggleButton>

              </DateRangePicker>&nbsp;&nbsp;&nbsp;
              <ToggleButton style={{ height: "35px" }} id="tbg-radio-7" value={'day'} className="btn btn-light toggle-btns custombtn">
                Day
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton style={{ height: "35px" }} id="tbg-radio-1" value={'week'} className="btn btn-light toggle-btns custombtn ">
                Week
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton style={{ height: "35px" }} id="tbg-radio-2" value={'month'} className="btn btn-light toggle-btns custombtn">
                Month
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton style={{ height: "35px" }} id="tbg-radio-3" value={'year'} className="btn btn-light toggle-btns custombtn ">
                Year
              </ToggleButton>&nbsp;&nbsp;&nbsp;
             
            </ToggleButtonGroup> </span>
        </Nav>
        
        <div className="service-table-holder" style={{ marginRight: "2%" }}> {load === '' ? display
          : load === 'Error in Fetching Data' ? <h6 style={{ color: "red" }}>{load}</h6> : <h6 style={{ color: "grey" }}>{load}</h6>}
        </div>
      </div>
      <br />
    </>

  )
}

export default PatientReports

import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./featurelist.css";
import Pagination from "react-js-pagination";
import "./pagination.css";
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
import AddScan from "./AddScan";
import EditScan from "./EditScan";
import { ToggleButton } from "react-bootstrap";
import { ToggleButtonGroup } from "react-bootstrap";
import { expand } from "urijs";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";

function Scan() {
  let navigate = useNavigate();
  const [currentData, setCurrentData] = useState();
  const [loading, setLoading] = useState(false);
  const [testList, setScanList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [addScanShow, setAddScanShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [editShow, setEditShow] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [testName, setScanName] = useState("");
  const [consentLevel, setConsentLevel] = useState("");
  const [expand, setExpand] = useState();

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Delete Scan!! </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to Delete the Scan "{testName}"
          </h5>
          <br />

          <div className="form-group "  >
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleDelete(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    axios
      .delete(`${APIURL}/api/v1/staff/master-scan-detail/${deleteIndex}/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 204) {
          loadData();
        } else {
          console.log("else called ");
        }

        setDeleteIndex("");
        setDeleteShow(false);
      })
      .catch((err) => {
        console.log("catch called");
      });
  };

  const loadData = () => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/staff/master-scans/?${TZ}`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          const datas = res.data;

          setScanList(datas);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSuccess = () => {
    loadData();
    setAddScanShow(false);
    setEditShow(false);
  };

  const notProceedClose = () => {
    setAddScanShow(false);
    setEditShow(false);
  };

  const AddScanPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-medium"
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{margin:"auto"}}>
          {/* <AddScan onSuccess={onSuccess} /> */}
          <AddScan modalClose={onSuccess} notProceedClose={notProceedClose} />
        </Modal.Body>
      </Modal>
    );
  };
  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-medium"
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <EditScan onSuccess={onSuccess} scan_name1={serviceName} id={editIndex} consent_Level1={consentLevel} /> */}
          <EditScan data={currentData} modalClose={onSuccess} />
        </Modal.Body>
      </Modal>
    );
  };
  const handleDeletePopUp = (id, name) => {
    setDeleteIndex(id);
    setScanName(name);
    setDeleteShow(true);
    setCurrentPage(1);
    setExpand();
  };
  const handleEditPopUp = (item) => {
    setEditIndex(item.id);
    setCurrentData(item);
    setEditShow(true);
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setExpand();
  };

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };

  let data = testList
    ? testList
        .sort((a, b) => {
          if (a.scan_name.toLowerCase() < b.scan_name.toLowerCase()) return -1;
          if (a.scan_name.toLowerCase() > b.scan_name.toLowerCase()) return 1;
          return 0;
        })
        .filter((value) => {
          if (searchTerm === "") {
            return value;
          } else if (
            value.scan_name.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return value;
          }
        })
    : [];

  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;

  let display =
    current.length > 0
      ? current.map((item, index) => {
          return (
            <div
              className="care-features"
              key={item.id}
              style={{ cursor: "pointer", marginBottom: 20 }}
              onClick={() => handleDetail(index)}
            >
              {/* <div className="text-left">

          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button>


        </div> */}
              <div className="feature-data">
                <h4 className="feature-title">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDetail(index)}
                  >
                    {index + 1}.{item.scan_name} <span>&nbsp;&nbsp;&nbsp;</span><span>&nbsp;&nbsp;&nbsp;</span><span>&nbsp;&nbsp;&nbsp;</span><span style={{color:"black",fontSize:"20px"}}>{item.group}</span>
                  </span>

                  {/* <i
                    class="fas fa-trash-alt"
                   // onClick={() => handleDeletePopUp(item.id, item.scan_name)}
                    style={{ float: "right", color: "red" , opacity: "0.5",cursor: "not-allowed" }}
                  ></i> */}
                  <i
                    class="fas fa-pencil-alt"
                    onClick={() => handleEditPopUp(item)}
                    aria-hidden="true"
                    style={{
                      float: "right",
                      paddingRight: "25px",
                      color: "#5a9569",
                      cursor: "pointer",
                    }}
                  ></i>
                </h4>
                {/* <button style={{ marginLeft: 'auto' }} onClick={() => handleEdit(index)} className='btn btn-primary btn-col'>Edit</button> */}
                &nbsp;
                {/* <button onClick={() => handleDeletePopUp(item.id)} className='btn btn-danger delete-font'> Delete</button> */}
              </div>
              {expand !== index ? <div className="seperate-content" /> : null}
              {/* [{
        "id": 2,
        "created_name": "admin",
        "scan_name": "haemoglobin",
        "test_sample": "dsfgj",
        "test_method": "colur doppler",
        "test_type": null,
        "is_verified": false,
        "generic_name": null,
        "unit": "rg/gh",
        "group": "male",
        "min_ref_range": 2,
        "max_ref_range": 5,
        "asso_disease": null,
        "cpt_code": null,
        "country": "Malta",
        "description": null,
        "created_at": "2021-12-31T06:49:24.061510Z",
        "modified_at": "2021-12-31T06:49:24.061510Z"
    }] */}

              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleDetail(index)}
                className={
                  expand === index ? "feature-data mt-3" : "feature-data"
                }
              >
                <h6 className="feature-request">
                  Scan Method: {item.scan_method}
                </h6>

                {/* <h6 className='feature-service'>Active : {item.is_active === true? 'Yes': item.is_active === false? 'No' : 'Nil'}</h6> */}
                {/* <h6 className='feature-service'><i className={expand===index ? "fas fa-sort-up detail-open" : "fas fa-sort-down detail-open"}></i>Inventory Details</h6> */}
              </div>
              {expand === index ? (
                <>
                  <div className="seperate-content" />
                  <div className="row-of-features">
                    <div className="col-of-features">
                    { item.image ?  <img src={item.image} alt="Test image" width="100" height="100" />: null}
                      <h6>Scan Name : {item.scan_name}</h6>
                      <h6>Scan Type : {item.scan_type}</h6>
                      <h6>Scan Method: {item.scan_method}</h6>
                      <h6>Scan Area: {item.scan_area}</h6>
                      <h6>Group: {item.group}</h6>
                      {/* <h6>Age Group: {item.age_group}</h6> */}
                      {/* <h6>CPT Code: {item.cpt_code}</h6>
             <h6>Country: {item.country}</h6>

             <h6>Description: {item.description}</h6> */}
                      <h6>
                        Created At:{" "}
                        {moment(item.created_at).format("DD MMM YYYY h:mm A")}
                      </h6>
                    </div>
                  </div>
                  {/* <button onClick={()=>handleEditInventory(index)} style={{alignSelf:'flex-end'}} className='btn btn-primary btn-col'>Edit Inventory</button> */}
                </>
              ) : null}
            </div>
          );
        })
      : null;
  const handleBack = (e) => {
    navigate(-1);
  };

  return (
    <>
      {/* <div>
            <BackofficeNavBar/>
        </div> */}
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div>
          <>
            <div className="container">
              {/* <button style={{padding:"5%",marginTop:"5%"}}
          className="btn btn-info topleft-single-btn"
          onClick={handleBack}
        >
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>Back
        </button> */}
              <div className="container failedlist-parentcontainer">
                <h2 className="title-of-page">Scan Details</h2>
                <div style={{ textAlign: "center" }}>
                  <div className="search-section">
                    <div className="search-div">
                      <div style={{ display: "flex",marginLeft: "25%" }}>
                        <input
                          style={{}}
                          className="form-control search-input"
                          type="text"
                          placeholder="Search a Scan"
                          value={searchTerm}
                          maxLength="150"
                          onChange={handleSearchChange}
                        />
                        <button className="btn btn-primary btn-col search-btn">
                          {" "}
                          <i className="fas fa-search"></i>{" "}
                        </button>
                        <button
                          className="btn btn-primary btn-col"
                          onClick={() => setAddScanShow(true)} style={{ marginLeft: "30%" }}
                        >
                          Add Scan <i className="fa fa-plus"></i>
                        </button>
                      </div>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <br />
                <div className="failed-list-section">
                  <div className="container">
                    <div className="test-reports-display-list">
                      {display ? (
                        display
                      ) : (
                        <div
                          style={{
                            color: "red",
                            fontSize: "25px",
                            height: "200px",
                            padding: "40px",
                            textAlign: "center",
                          }}
                        >
                          {!loading ? <h4>No Scan to show!!</h4> : " "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {totalPages > 1 ? (
                  <div className="pagn pagn-small">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={totalPages}
                      onChange={handlePageChange}
                      disabledClass="disabled-class"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </>
        </div>
      </LoadingOverlay>
      {addScanShow ? (
        <AddScanPopUp show={addScanShow} onHide={() => setAddScanShow(false)} />
      ) : null}

      {deleteShow ? (
        <DeleteConfirmPopup
          show={deleteShow}
          onHide={() => {
            setDeleteShow(false);
            setDeleteIndex("");
          }}
        />
      ) : (
        ""
      )}
      {editShow ? (
        <EditPopup
          show={editShow}
          onHide={() => {
            setEditShow(false);
            setEditIndex("");
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
export default Scan;

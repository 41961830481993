import React, {useState, useEffect} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import printJS from 'print-js'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import moment from 'moment'
import { useAlert } from "react-alert";

function BookingPayment(props) {


    const [invoiceData , setInvoiceData]= useState(props.invoiceData);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [method, setMethod]= useState('')
    const [cartID, setCartID]= useState("")
    //const [amount, setAmount]= useState('')
    const [errorShow,setErrorShow]=useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    // const [type, setType]= useState("full")
    // const [discount, setDiscount]= useState(0)
    const alert = useAlert();
    // const [show, setShow]= useState(false)
    const [login_datas, setLogin_datas] = useState(
      JSON.parse(sessionStorage.getItem("logindatas"))
          ? JSON.parse(sessionStorage.getItem("logindatas"))
          : null
  );

   /* useEffect(()=> {

        setAmount(getPayable())


    },[type,apiData,discount])*/


     const handleMethod =(e) => {
        setMethod(e.target.value)
    }


    const handleSave = async () => {
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
      
        const dataToUpdate = {
          payment_mode: method,
          appoint_id: invoiceData.id,
          patient_id: invoiceData.patient_id,
          final_checkout_amount: invoiceData.fees,
        };
      
        axios.post(`${APIURL}/api/v1/service-provider/consultation-payments/`, dataToUpdate, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + str,
          },
        })
          .then(res => {
            if (res.data.status === 'success') {
              alert.show('Appointment Bill Generated');
              
              handlePrint();
              props.dataHandlePayment();
             
            } else {
              setErrorMsg('Error in Data Submission. Please try again');
              setErrorShow(true);
            }
          });
      };
      

     const handlePrint = () => {
    
        printJS({
           printable: 'print-invoice',
           type: 'html',
           targetStyles: ['*'],
        })
      
      }


      const SubmitErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      } 






      let procedureData;

      procedureData = (
        <tr style={{ textAlign: 'center' }}>
          <td style={{ textAlign: 'center' }}>Appointment Fee</td>
          <td style={{ textAlign: 'center' }}>{invoiceData.fees}</td>
          <td style={{ textAlign: 'center' }}>0%</td>
          <td style={{ textAlign: 'center' }}>0%</td>
          <td style={{ textAlign: 'center' }}>{invoiceData.fees}</td>
        </tr>
      );

      // const handlePayment = (e)=> {
      //   setType(e.target.value)
      // }

      // const handleDiscount = (e)=> {
      //   setDiscount(e.target.value)
      // }

      // const getPayable = () => {

      //   const data = apiData.balance_payment - discount

      //   return data
      // }

   return (<div>
      
      <h2 style={{textAlign:'center'}} className="title-of-tasks"> Invoice  </h2><br/>

      <div className='flex-row invoice-radio'>
           <span className='text-muted font-weight-bold'>Select payment method :</span>
           <input type='radio' className='hideme' checked={method==='card'} id='card' value='card' onChange={handleMethod} />
        <label htmlFor='card' className='invoice-label'>
            Card
        </label>

        <input type='radio' className='hideme' checked={method==='cash'} id='cash' value='cash' onChange={handleMethod} />
        <label htmlFor='cash' className='invoice-label'>
            Cash 
        </label>

        <input type='radio' className='hideme' checked={method==='upi'} id='benefit_pay' value='upi' onChange={handleMethod} />
        <label htmlFor='benefit_pay' className='invoice-label'>
            UPI
        </label>
       </div>
       <br/>
       <div id='print-invoice' className="invoice-table flex-col to-print invoice-print-size ">
        <div className="invoice-row1 invoice-header">

              <div>
                <h5 className='font-weight-bold text-left'>Patient ID {invoiceData.patient_id}</h5>
                <h6 className='text-left'>Patient: {invoiceData.patient_name}</h6>
                <h6 className='text-left'>Doctor: {invoiceData.doctor_name}</h6>
                <h6 className='text-left'>Mobile: {invoiceData.patient_mobile}</h6>
               
              </div>

             <div>
              <h3 className="invoice-heading caps text-center">{login_datas.hospital_name}</h3>
              <h6 className="invoice-heading caps text-center">{login_datas.hospital_state},{login_datas.hospital_location},{login_datas.hospital_street}</h6>
              <br/>
              <h6 className="invoice-heading invoice-heading-normal">{login_datas.hospital_mobile}</h6>
              <h6 className="invoice-heading invoice-heading-normal">{login_datas.hospital_email}</h6>
             </div>
           {/* <h6 className="invoice-heading caps">Al Janabiyah North</h6> */}

        <div className='invoice-detail mr-2'>

            <h5>{moment().format('DD-MM-YYYY')}</h5>
        
            

        </div>
       </div>
       {/* <div className="invoice-row2">
  
       <div>
          <h5 className="grey-title caps">{invoiceData.patient_name}</h5>
    </div>
       </div> */}
       <div className="invoice-row3">
          <table style={{marginLeft:"10%"}} >
          
         
            <tr>
            
  
              <th><b>Appointment</b></th>
             
          
              <th><b> Amount </b></th>
              <th><b>VAT%</b></th>
              <th><b>Discount%</b></th>
              <th><b>Total</b></th>
            </tr>
    

{ procedureData }
          


  
          </table>
       </div>

       <div className="invoice-row4">

     <h6 className="text-right">Gross Total : <span className="caps-full"></span>${invoiceData.fees}</h6>
        {/* <h6 className="text-left">VAT Amount : <span className="caps-full">INR</span>{props.vat}</h6>
        <h6 className="text-left">Discount : {props.discount}</h6> */}
        {/* <h6 className="text-left">Total Amount : <span className="caps-full">INR</span>{props.total+props.vat}</h6> */}
        {/* <h6 className="text-right">Net Total: <span className="caps-full"><i class="fa fa-eur" aria-hidden="true"></i></span>{totalAmount}</h6> */}
        </div>
        <div className="invoice-row4">
      
       
     
         <br/>
         <h6 className="text-right">Thanks,</h6>
         <h6 className="text-right">{login_datas.name}</h6>
          

     </div>

       </div>
       <br/>

       {/**<div className="to-print">
        <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>
  
      </div>**/}
       
       <div className="do-not-print text-center" id="pr">
                   <button className="btn btn-primary btn-col btn-square" disabled={method === ''} onClick={handleSave}> Save and Print </button>
                   <br/>
                   <br/>
                   <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                    <br/>
                    <br/>
             </div> 
  

             {errorMsg!=='' ? 
    <SubmitErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        setErrorMsg('');}}
       
      />: ''
    }

    </div>
       )
}

export default BookingPayment

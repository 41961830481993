import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { useNavigate, Link,useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import "./service.css";
import { Modal } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
let ServiceHistoryDoc1 = () => {
  
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [type, setType] = useState("");
  const [tableData,setTableData]=useState([]);
  const [ServiceList, setServiceList] = useState([]);
  const [activating, setActivating] = useState(false);
  const [render, setRender] = useState(false);
  const [hasNext, setHasNext] = useState(false)
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const[page,setPage]=useState(1)
  let tableData2=[]
  
  let navigate = useNavigate();

  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
  const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
  
// Get current timezone dynamically
const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const loadData = async  (page, per_page)  => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=${page}&per_page=${per_page}&timeZone=${currentTimezone}&current_date=${formattedDate}`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let data=res.data.requests
          // setHasNext(res.data.has_next)
          if (data && data.length > 0) {
            const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...newData]);
          }
          setHasNext(res.data.has_next)
          
         setTotalRows(res.data.total_records)
        } else {
      
          //  setErrorShow(true)
        }
      })
      .catch((err) => {
       
        // setErrorShow(true)
      });
  };


  useEffect(() => {
    loadData(page,perPage);
  }, [perPage]);


  const handlePageChange = page => {
    if(hasNext){
      setPage(page)
    loadData(page+1, perPage);

    }
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage);
    
  }


  const statusOptions = [{ value: 'delivered', label: 'Delivered' },
  { value: 'rejected', label: 'Rejected' }];

 
  const handleInfoPopUp = (item,type) => {
    setInfoData(item);
    setinfoShow(true);
    console.log(item)
    if(type==="master_medicine"){
      setType("Medicines")
    }else{
      setType("Lab Tests")
    }
  };
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           
            
        {infoData.map((item,index) => {
             return (            
             <div className="row-of-features">
               <div className="col-of-features"><br/>
                 <h6> {index+1}&nbsp;{item.item_service_name}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Amount:&nbsp;{item.order_amount}</h6>
             

              
               </div>
             </div>
             );
          })}
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const renderDate = (cellInfo) => {

    let dateString = tableData[cellInfo.index][cellInfo.column.id];

    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (<div >
      <h6> {updatedDate.slice(4, 10)}  </h6>
      {/* <h6> {updatedDate.slice(16, 24)}</h6> */}
    </div>)

  }


  const renderService = (cellInfo) => {

    return (

      <div > <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {tableData[cellInfo.index][cellInfo.column.id]}
      </span>{cellInfo.column.id === "dispatched_count"?<>\{tableData[cellInfo.index].dispensing_count}</>:""}
      {/* <i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               handleInfoPopUp(tableData[cellInfo.index].request_items,tableData[cellInfo.index].request_type);
              }}
            ></i> */}
      </div>

    );
  }
  const renderStatus = (cellInfo) => {
    return (
      <>
        <button  style={{textTransform: "capitalize" ,textAlign:"center",width: "130px",
height: "39px",
flexShrink: "0",
borderRadius: "5px",
background: tableData[cellInfo.index].status==="approved" ? "#61C65F" :"#C6725F", color: "white"}}  >
          {tableData[cellInfo.index].status}
        
         
         
        </button>
      </>
    );
  };


  const filterDataID = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&request_pid=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
      
         data = response.data.requests
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
         }
         else{
          const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData((prevData) => [...prevData, ...data1]);
         }
        // }
        console.log("tableData",tableData)
        
    
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataQuantity = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&quantity=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
     
         data = response.data.requests
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
         }
         else{
          const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData((prevData) => [...prevData, ...data1]);
         }
        // }
        console.log("tableData",tableData)
        
     
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataNurse = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&nurse_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        
         data = response.data.requests
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
         }
         else{
          const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData((prevData) => [...prevData, ...data1]);
         }
        // }
        console.log("tableData",tableData)
        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataBed = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&bedunit_code=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
 
         data = response.data.requests
         if (data.length>0){
         
          setTableData((prevData) => {
            const newData = data.filter((item) => !prevData.some((existingItem) => existingItem.id === item.id));
            return [...prevData, ...newData];
          });
        
          setHasNext(response.data.has_next);
          setTotalRows(response.data.total_records);}
         }
        // }
        console.log("tableData",tableData)
        

      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataName = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data= [];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
      
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };


  const filterDataStatus = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data= [];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&status=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
      
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataDate = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data= [];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&date=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
      
        data = response.data.requests
        if (data.length>0){
     
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
      
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataPatient = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&patient_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
   
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataDoctor = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=consumables&page=1&per_page=20&${TZ }&current_date=${formattedDate}&doctor_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
      
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const renderTableDate = (cellInfo) => {
    const datetimeString = tableData[cellInfo.index][cellInfo.column.id];
    const date = new Date(datetimeString);
    const formattedDate = date.toISOString().split('T')[0]; // Extract the date part
  
    return (
      <div>  <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {formattedDate}
        </span>
      </div>
    );
  };
  

  const columns1 = [
   
    {
      Header: "Id",
      accessor: "request_pid",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 50,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataID(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },

    {
      Header: "Consumables",
      accessor: "request_items",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 130,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataName(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Date",
      accessor: "date",
      Cell: renderTableDate,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 120,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataDate(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
    },
    {
      Header: <div>
      <span data-tooltip-id="name-tooltip1" 
        data-tooltip-content="Dispatched Count" >Dispatched Count</span>
    
      <Tooltip id="name-tooltip1" place="bottom"style={{marginTop:"53%"}}/>
    </div>,
      accessor: "dispatched_count",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 80,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataQuantity(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Patient",
      accessor: "patient",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      // width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataPatient(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Doctor",
      accessor: "doctor",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      // width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataDoctor(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Nurse",
      accessor: "nurse",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      // width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataNurse(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Ward",
      accessor: "admit_details",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      // width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataBed(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: " Status",
      accessor: "status",
      Cell: renderStatus,
      sortable: false,
      filterable: true,
      style: { whiteSpace: "unset",color:"black" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      // width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataStatus(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
     

      // width: 250,
    },


   ]

  return (
    <>
   

       
            <div  style={{marginBottom:"2%"}} className="">
           
            
            
         
         <ReactTable
         columns={columns1}
         data={tableData}
         className="service-req-table"
         resizable
         minRows={perPage - 1}
         style={{ textAlign: "center" }}
         pageSizeOptions={[ perPage,20, 50,Math.ceil(totalRows/ 2),totalRows]}
         //pageSizeOptions={[8,10,Math.ceil(totalRows1 / 10),totalRows1]}
         pages={Math.ceil(totalRows / perPage)}
         showPagination={tableData.length > 0} // Hide pagination if no data
         showPaginationNext={tableData.length > 0 && page < totalRows} // Show "Next" button if there is more data
         // onPageChange={(pageIndex) => {
         //   setPage(pageIndex + 1); // Update the page state
         // }}
        onPageChange={(pageIndex) => { handlePageChange(pageIndex);  }}
     onPageSizeChange={handlePerRowsChange}
         //pages={totalRows} // Specify the total number of pages
         defaultPageSize={perPage}
       />
        
      
      </div>
      {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ServiceHistoryDoc1;

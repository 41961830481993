
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";

import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./reports.css";

import moment from "moment";



import "bootstrap-daterangepicker/daterangepicker.css";


import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PatientReports from './PatientReports';
import RemoteRequests from './RemoteRequests';
import PaymentReports from './PaymentReports';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Reports() {

  return (
    <>
 <div className="reports-menu">

      <Tab.Container id="left-tabs-example" defaultActiveKey="patient" >
        <Row>
          <Col sm={2} >
            <Nav variant="pills" className="checkBoxWebReport" >
              <Nav.Item>
                <Nav.Link  eventKey="patient"> Registration Report </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="payment"> Payment Report</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="doctor"> Remote Requests   </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="patient">
                <PatientReports />
              </Tab.Pane>
              <Tab.Pane eventKey="payment">
                <PaymentReports />
              </Tab.Pane>
              <Tab.Pane eventKey="doctor">
                <RemoteRequests />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      </div>

    </>

  )
}

export default Reports

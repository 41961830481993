import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { ToggleButtonGroup } from "react-bootstrap";
import LabPage from "./LabPage";
import ScanPage from "./ScanPage";
import MiscPage from "./MiscPage";
import MedicinePage from "./MedicinePage";
import ProcedurePage from "./ProcedurePage";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import {  Dropdown, ToggleButton, Tab, Tabs,  ButtonGroup } from "react-bootstrap";
import { Spinner } from 'react-bootstrap';
function PaymentConfigNew(props) {


    const [serviceList, SetServiceList] = useState([]);

    const [nameError, setNameError] = useState("")
    const [dataList, setDataList] = useState([])
    const [main_service_name, setMainService] = useState("laboratory")

    const [activeTab, setActiveTab] = useState("lab");
  


    const [refresh,setRefresh]= useState(false)
  


    let placeholder = "Please chooce a Category"
    

    const handleSelect = (key) => {
        setActiveTab(key);
      };
    const handleRemoveItem = idx => {
      // assigning the list to temp variable
      const temp = [...serviceList];

      // removing the element using splice
      temp.splice(idx, 1);

      // updating the list
      SetServiceList(temp);
  }
 


      let url = ""
      let name = ""
  
      if (main_service_name === "laboratory") {
          name = "service_name"
          placeholder = "Search a Test"
          url = "/api/v1/service-provider/hospital-services/?type=master_labtest"
      }
      else if (main_service_name === "Hospital procedures") {
          name = "service_name"
          placeholder = "Search a Procedure"
          url = "/api/v1/service-provider/hospital-services/?type=master_procedure"
      }
      else if (main_service_name === "Scan") {
          name = "service_name"
          placeholder = "Search a Scan"
          url = "/api/v1/service-provider/hospital-services/?type=master_scan"
      }

      else if (main_service_name === "misc") {
          name = "service_name"
          placeholder = "Search a miscellaneous Service"
          url =  "/api/v1/service-provider/hospital-services/?type=misc"
      }
      else {
          placeholder = "Please Chooce a Category"
  
      }
      const isValid = dataList.filter((option) => !!option[name]).length === dataList.length;

      const options = isValid ?  dataList && dataList.map((option) => {
          return {
              label: option[name],
              //unified_code: option["unified_code"],
              id: option["id"],
              categ: option["main_service_name"],
              amount:option["amount"],
              vat:option["vat"],
              discount:option["discount"]
          }
  
      }) : [];

    // const handleChange = (e) => {
    //     const key = e.target.name

    //     const list = configData

    //     const data =tempData

    //     if(key === 'register'){
    //       list[0].status = !list[0].status
    //       setConfigData(list)
    //       setRefresh(!refresh)
    //     }

    //     if(key === 'consult'){
    //       list[1].status = !list[1].status
    //       setConfigData(list)
    //       setRefresh(!refresh)
    //     }


    //     if(key === 'procedure'){
    //       data.status = !data.status
    //       setTempData(data)
    //       setRefresh(!refresh)
    //     }

       

   
    // }

    const handleAdd =() =>{

        props.dataHandle(serviceList)
        props.modalClose()
    }
 






    return (<>
   
 <div className="">
   <Tabs activeKey={activeTab} className="paymentconfigfont" onSelect={handleSelect}>
      <Tab eventKey="lab" title="Lab" >
      <LabPage  dataHandle={props.dataHandle}
                dataLab={props.dataLab}
            patientID={props.patientID}
            dataPayment={props.dataPayment}
            modalClose={props.modalClose}/>
      </Tab>
      <Tab eventKey="scan" title="Scan">
     <ScanPage dataHandle={props.dataHandle}
            patientID={props.patientID}
            dataScan={props.dataScan}
            dataPayment={props.dataPayment}
            modalClose={props.modalClose}/>
      </Tab>
      <Tab eventKey="medicine" title="Medicine">
     <MedicinePage  dataHandle={props.dataHandle}
            patientID={props.patientID}
            dataMed={props.dataMed}
            dataPayment={props.dataPayment}
            modalClose={props.modalClose}
             />
      </Tab>
      <Tab eventKey="procedures" title="Procedures">
      <ProcedurePage  dataHandle={props.dataHandle}
            patientID={props.patientID}
            dataPro={props.dataPro}
            dataPayment={props.dataPayment}
            modalClose={props.modalClose}
             />
      </Tab>
      <Tab eventKey="misc" title="Misc">
      <MiscPage  dataHandle={props.dataHandle}
            patientID={props.patientID}
            dataMisc={props.dataMisc}
            dataPayment={props.dataPayment}
            modalClose={props.modalClose}
             />
      </Tab>
    </Tabs>

   </div>     
            
     

                 

        </>
    )
}

export default PaymentConfigNew

import React, {useState, useEffect} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import printJS from 'print-js'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import moment from 'moment'
import { useAlert } from "react-alert";

function PaymentInvoiceScanPre(props) {
  

    const [invoiceData , setInvoiceData]= useState(props.invoiceData);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [method, setMethod]= useState('')
    const [cartID, setCartID]= useState("")
    //const [amount, setAmount]= useState('')
    const [errorShow,setErrorShow]=useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    // const [type, setType]= useState("full")
    // const [discount, setDiscount]= useState(0)
    const alert = useAlert();
    // const [show, setShow]= useState(false)
    const [login_datas, setLogin_datas] = useState(
      JSON.parse(sessionStorage.getItem("logindatas"))
          ? JSON.parse(sessionStorage.getItem("logindatas"))
          : null
  );

   /* useEffect(()=> {

        setAmount(getPayable())


    },[type,apiData,discount])*/


     const handleMethod =(e) => {
        setMethod(e.target.value)
    }

    // const handleAmount = (e)=> {
    //     const val = e.target.value
    //     if(val.length < 6 ){
    //         setAmount(e.target.value)
    //     }
    //     else return false
    // }

    // useEffect(()=> {
    //   setProcedure(procedure)
    // },[refresh])

    const handleSave = async () => {
      if (isLoading) {
        return; // Return if the function is already being executed
      }
      
      setIsLoading(true);
    
      const tokenString = sessionStorage.getItem('usertoken');
      let str = tokenString.replace(/["]+/g, '');
    
      const dataToUpdate = {
        payment_mode: method,
        paid_amount: totalAmount,
        patient_id: props.id,
        paid_for: "scan",
        paid_status: "paid",
        items: props.ScanPreID,
      };
    
      try {
        const res = await axios.post(`${APIURL}/api/v1/service-provider/hospital-payments/`, dataToUpdate, {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": 'Token ' + str
          }
        });
    
        if (res.data.status === "success") {
          const dataset = {
            type: "scan",
            ids: props.ScanPutId,
            action: "ap_paid"
          };
    
          await axios.put(`${APIURL}/api/v1/service-provider/careplan-schedule/`, dataset, {
            headers: {
              'Content-Type': 'application/json',
              "Authorization": 'Token ' + str
            }
          });
    
          setCartID(res.data.checkoutcart_id);
          alert.show("Scan Bill Generated");
          handlePrint();
          setInvoiceData([]);
          props.dataHandle1();
        } else {
          setErrorMsg(res.data.message);
          setErrorShow(true);
        }
      } catch (err) {
        setErrorMsg('Error in Data Submission. Please try again');
        setErrorShow(true);
      } finally {
        setIsLoading(false);
      }
    };
    


     const handlePrint = () => {
    
        printJS({
           printable: 'print-invoice',
           type: 'html',
           targetStyles: ['*'],
        })
      
      }


      const SubmitErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      } 

      const getRegistration = () => {
          const data = invoiceData.invoice_details.registration_invoice_details
        if(invoiceData.invoice_details.procedure_invoice_details === null){
          return(
            
            <tr>
                  <td>Registration</td>
                  <td>{data.registration_gross_amount}</td>
                  <td>{data.registration_vat_amount}</td>
            </tr>
            )
        }
        else{
          return(
            
            <tr>
                  <td>Registration</td>
                  <td>-</td>
                  <td>-</td>
                  <td>{data.registration_gross_amount}</td>
                  <td>{data.registration_vat_amount}</td>
            </tr>
            )
        }
          
      }

      const getConsultation = () => {
        const data = invoiceData.invoice_details.consultation_invoice_details
        if(invoiceData.invoice_details.procedure_invoice_details === null){
          return(
            <tr>
                  <td>Consultation</td>
                  <td>{data.consultation_gross_amount}</td>
                  <td>{data.consultation_vat_amount}</td>
            </tr>
            )
        }
        else {
          return(
            <tr>
                  <td>Consultation</td>
                  <td>-</td>
                  <td>-</td>
                  <td>{data.consultation_gross_amount}</td>
                  <td>{data.consultation_vat_amount}</td>
            </tr>
            )
             
        }

    }


    let totalAmount = 0;

for (let i = 0; i < invoiceData.length; i++) {
  let item = invoiceData[i];
  let discountedAmount2 = (item.amount * item.discount)/100;
  let discountedTotalAmount2 = item.amount - discountedAmount2;
  let vatAmount2 =  discountedTotalAmount2 * (item.vat/100)
  let totalAmount2 = discountedTotalAmount2 + vatAmount2;
  totalAmount += totalAmount2;
  totalAmount = Number(totalAmount.toFixed(2));
}


      const procedureData = invoiceData.length>0 ?

                                                         invoiceData.map(item => {
      let discountedAmount2 = (item.amount * item.discount)/100;
      let discountedTotalAmount2 = item.amount - (discountedAmount2);
      let vatAmount2=  discountedTotalAmount2 * (item.vat/100)
      let totalAmount2 =discountedTotalAmount2+ vatAmount2;
          return (

                  <tr>
                  
                      <td>{item.schedule_name}</td>
                     
                      <td>{item.amount}</td>
                      <td>{item.vat}%</td>
                      <td>{item.discount}%</td>
                      <td>{totalAmount2}</td>
                  </tr>

          )
      }) : null

      // const handlePayment = (e)=> {
      //   setType(e.target.value)
      // }

      // const handleDiscount = (e)=> {
      //   setDiscount(e.target.value)
      // }

      // const getPayable = () => {

      //   const data = apiData.balance_payment - discount

      //   return data
      // }

   return (<div>
      
      <h2 style={{textAlign:'center'}} className="title-of-tasks"> Invoice  </h2><br/>

      <div className='flex-row invoice-radio'>
           <span className='text-muted font-weight-bold'>Select payment method :</span>
           <input type='radio' className='hideme' checked={method==='card'} id='card' value='card' onChange={handleMethod} />
        <label htmlFor='card' className='invoice-label'>
            Card
        </label>

        <input type='radio' className='hideme' checked={method==='cash'} id='cash' value='cash' onChange={handleMethod} />
        <label htmlFor='cash' className='invoice-label'>
            Cash 
        </label>

        <input type='radio' className='hideme' checked={method==='upi'} id='benefit_pay' value='upi' onChange={handleMethod} />
        <label htmlFor='benefit_pay' className='invoice-label'>
            UPI
        </label>
       </div>
       <br/>
       <div id='print-invoice' className="invoice-table flex-col to-print invoice-print-size ">
        <div className="invoice-row1 invoice-header">

              <div>
                <h5 className='font-weight-bold text-left'>Patient ID {props.id}</h5>
                <h6 className='text-left'>Patient: {props.name}</h6>
                <h6 className='text-left'>Mobile: {props.mobile}</h6>
               
              </div>

             <div>
              <h3 className="invoice-heading caps text-center">{login_datas.hospital_name}</h3>
              <h6 className="invoice-heading caps text-center">{login_datas.hospital_state},{login_datas.hospital_location},{login_datas.hospital_street}</h6>
              <br/>
              <h6 className="invoice-heading invoice-heading-normal">{login_datas.hospital_mobile}</h6>
              <h6 className="invoice-heading invoice-heading-normal">{login_datas.hospital_email}</h6>
             </div>
           {/* <h6 className="invoice-heading caps">Al Janabiyah North</h6> */}

        <div className='invoice-detail mr-2'>

            <h5>{moment().format('DD-MM-YYYY')}</h5>
        
            

        </div>
       </div>
       {/* <div className="invoice-row2">
  
       <div>
          <h5 className="grey-title caps">{invoiceData.patient_name}</h5>
    </div>
       </div> */}
       <div className="invoice-row3">
          <table style={{marginLeft:"10%"}} >
          
         
            <tr>
            
  
              <th>Test Name</th>
             
          
              <th> Amount </th>
              <th>VAT%</th>
              <th>Discount%</th>
              <th>Total</th>
            </tr>
    

{ procedureData }
          


  
          </table>
       </div>

       <div className="invoice-row4">

     <h6 className="text-right">Gross Total : <span className="caps-full"><i class="fa fa-eur" aria-hidden="true"></i></span>{props.total}</h6>
        {/* <h6 className="text-left">VAT Amount : <span className="caps-full">INR</span>{props.vat}</h6>
        <h6 className="text-left">Discount : {props.discount}</h6> */}
        {/* <h6 className="text-left">Total Amount : <span className="caps-full">INR</span>{props.total+props.vat}</h6> */}
        <h6 className="text-right">Net Total: <span className="caps-full"><i class="fa fa-eur" aria-hidden="true"></i></span>{totalAmount}</h6>
        </div>
        <div className="invoice-row4">
      
       
     
         <br/>
         <h6 className="text-right">Thanks,</h6>
         <h6 className="text-right">{login_datas.name}</h6>
          

     </div>

       </div>
       <br/>

       {/**<div className="to-print">
        <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>
  
      </div>**/}
       
       <div className="do-not-print text-center" id="pr">
                   <button className="btn btn-primary btn-col btn-square" disabled={method === ''} onClick={handleSave}> Save and Print </button>
                   <br/>
                   <br/>
                   <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                    <br/>
                    <br/>
             </div> 
  

             {errorMsg!=='' ? 
    <SubmitErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        setErrorMsg('');}}
       
      />: ''
    }

    </div>
       )
}

export default PaymentInvoiceScanPre

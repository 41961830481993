import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
// import "./service.css";
import logo from "../../assets/images/svp.png";
import { useAlert } from 'react-alert' 
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../Global";
function ConInventory (props){
    const [medData, setMedData] = useState([]);
    const [refresh,setRefresh]=useState(false)
    useEffect(() => {getServices1()},[refresh,props.render])
    const getServices1 =  async  (page, per_page) => {

 
        const tokenString = sessionStorage.getItem('usertoken');
    
        let str = tokenString.replace(/["]+/g, '');
    
    
    
     await   axios.get(`${APIURL}/api/v1/service-provider/nurse-inventory/?type=consumables&${TZ}&is_accepted=True     `, {
          headers: {
    
            "Authorization": 'Token ' + str
          }
        })
          .then(res => {
            if (res.data.status === "success") {
      
             
            //   setLoading(false);
              let data=res.data.data
              if (data && data.length > 0) {
                setMedData((prevData) => {
                  const newData = data.filter((item) => !prevData.some((existingItem) => existingItem.id === item.id));
                  return [...prevData, ...newData];
                });  
              props.onSuccess()
            }
            }
           
            else {
            //   setLoading(false);
    
              alert.error(res.data.message);
            }
    
    
    
          })
          .catch(err => {
            // setLoading(false);
    
            // setGetError("Error in fetching data");
          })
      };
      const renderService1 = (cellInfo) => {

        return (
         
          <div >  <>
         <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
            {medData[cellInfo.index][cellInfo.column.id]}
            
          </span>
        </>
          </div>
    
        );
      }
      const renderServiceMedName = (cellInfo) => {

        return (
    
          <div className="row"> <span style={{textTransform: "capitalize" ,textAlign:"left" }}>
            {medData[cellInfo.index][cellInfo.column.id]} 
          </span>
         
        
          </div>
    
        );
      }
     
      const renderTableDate = (cellInfo) => {
        const datetimeString = medData[cellInfo.index][cellInfo.column.id];
        const dateParts = datetimeString.split(' ')[0].split('-'); // Split by space and then by hyphen
        const formattedDate = datetimeString.slice(0,10); // Join the date parts with hyphens
      
        return (
          <div > <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
            {formattedDate}
            </span>
          </div>
        );
      };
    
    const columns = [

        {
          Header:   <div>
          <span data-tooltip-id="name-tooltip" 
            >ID </span>
        </div>
       ,
          accessor: "id",
           Cell: renderService1,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' },
    
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
          width: 50,
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
         
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
                // filterData(filterValue); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
        },
    
        
        
        {
          Header: "Consumables",
          accessor: "inventory_item",
           Cell: renderServiceMedName,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' },
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
    
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
                // filterData1(filterValue); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
    
        },
      
    
        {
          Header: "Date",
          accessor: "created_at",
          Cell: renderTableDate,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' },
      
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
    
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
                // filterData4(filterValue); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
          width:100
        },
        {
            Header: "Patient",
            accessor: "patient",
             Cell: renderService1,
            sortable: true,
            filterable: true,
            style: { 'whiteSpace': 'unset' },
            filterMethod: (filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
            },
      
            Filter: ({ filter, onChange }) => (
              // Custom filter input component
              <input
                value={filter ? filter.value : ''}
                onChange={(e) => {
                  const filterValue = e.target.value;
                  onChange(filterValue); // Update the filter value
                  // filterData2(filterValue); // Make an API request and update the filteredData state
                }}
                style={{ color: 'black' }}
              />
            ),
      
          },
        {
          Header: "Doctor",
          accessor: "doctor",
          Cell: renderService1,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' ,color:"black"},
    
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
    
          Filter: ({ filter, onChange }) => (
            
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); 
                
              }}
              style={{ color: 'black' }}
            />
          ),
    
        },
       
    
    
        {
          Header: <div>
          <span data-tooltip-id="name-tooltip1" 
            data-tooltip-content="Dispense Count" >Dispatched Count</span>
        
          {/* <Tooltip id="name-tooltip1" place="bottom" style={{ zIndex:"1000000", color: "white" }}/> */}
        </div>,
          accessor: "dispatched_count",
        //   Cell: renderDispenceCount,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' },
    
        
          width: 100,
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
    
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
                // filterData5(filterValue); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
    
        },
       
      ]
    
    const displayTable =  
  <ReactTable
  data={medData}
  columns={columns}
  resizable
  className="service-req-table"
//   defaultPageSize={perPage}
//   showPagination={medData.length>0}
//   pages={Math.ceil(totalRows / perPage)}
//   page={page-1}
//   onPageChange={(pageIndex) => { handlePageChange(pageIndex); }}
//   onPageSizeChange={(newPerPage) => { setPerPage(newPerPage); setPage(0); }}
  pageSizeOptions={[5, 10, 20, 50,]}
/>
    return(
        <div  style={{marginBottom:"2%"}}>
          {displayTable}  
        </div>
    )
}
export default ConInventory
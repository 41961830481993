import React ,{useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import './profile.css'
import { useAlert } from 'react-alert';

function AddressUpdate(props) {

    const[address, setAddress] =useState(props.address !== " " ? props.address : " ");
    const alert = useAlert()
    const [error,setError]=useState({
        address:''
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='address'){
            setAddress(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (address==="" ) {
            isValid = false;
            errors["address"] = "Enter an address";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitAddress()
        }
      }

        const submitAddress = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
               address : address
             }
          
             axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                alert.success("Updated successfully")                 
                setAddress()
                props.handle()               
            
              }else{
                alert.error("Error in Data Submission. Please try again!")                            
              }
            
            })
            .catch(err=>{
              alert.error("Error in Data Submission. Please try again!")           
            })
          
          }

    return (
        <div>
            <div className="item"><input className="form-control" type="text" maxLength="150" name="address" placeholder="Address" value={address} onChange={handleChange}/>

                {error.address ? <div className="error-validation-msg"> {error.address}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Address</button>
            </div>            
        </div>
    )
}

export default AddressUpdate

import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
// import "./service.css";
import { SOCKETURL } from "../../Global";
import { useNavigate, Navigate, Link,useLocation } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";

import MedInventory from "./MedInventory";
import ConInventory from "./ConInventory";
import TotalInventory from "./TotalInventory";
// import ServiceHistoryDoc from "./ServiceHistoryDoc";
// import ServiceHistoryDoc1 from "./ServiceHistoryDoc1";
import orders from "../../assets/images/orders.png"
function NurseInventory() {
    let navigate = useNavigate();
    const [selected, setSelected] = useState("medicine")
    const [count, setCount] = useState(0);
    const [count1, setCount1] = useState(0);
    const [displayAlert,setDisplayAlert]=useState(false)
    const [reRender, setRerender] = useState(false);
    return (
        <>
    
    <div className='row' style={{width:"100%"}}>
                    <div className="col-lg-2 col-md-2">
                        <h5
                            style={{
                                color: "#245693",
                                marginTop: "8%",
                                fontFamily: "inter",
                                fontWeight: "700",
                                marginLeft: "20px",
                            }}
                        >
                           Inventory
                        </h5>
                        <div className="leftdivdeathrepo">
                           
                            <h6
                                style={{
                                    color: "#2D3748",
                                    fontFamily: "Helvetica",
                                    fontWeight: "700",
                                    marginLeft: "20px",
                                    marginTop: "5px",
                                    fontSize: "12px",
    
                                }}
                            >
                              
                            </h6>
                            <button className="deathreportbutton" onClick={e => { setSelected("medicine") }} style={{ backgroundColor: selected === "medicine" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "medicine" ? "white" : "black" }}>
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M8.6 16.4a4.6 4.6 0 0 1-6.5-6.5l7.8-7.8a4.6 4.6 0 0 1 6.5 6.5M5.999 5.999l4.25 4.25" /><path d="M23.25 15.75a7.669 7.669 0 0 1-6 7.5a7.669 7.669 0 0 1-6-7.5V13.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v2.25Zm-6-.75v4.5M15 17.25h4.5" /></g></svg>
                            
                                &nbsp;&nbsp;Medicine
                            </button>
                            {/* <button className="deathreportbutton" onClick={e => { setSelected("consumables")}} style={{ backgroundColor: selected === "consumables" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "consumables" ? "white" : "black" }}>
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M22.003 3v4.497A.503.503 0 0 1 21.5 8v0a.52.52 0 0 1-.466-.3A10 10 0 0 0 12.003 2c-5.185 0-9.449 3.947-9.95 9" /><path d="M17 10v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2m-5 1V8" /><path d="M2.05 21v-4.497c0-.278.226-.503.504-.503v0c.2 0 .38.119.466.3a10.001 10.001 0 0 0 9.03 5.7c5.186 0 9.45-3.947 9.952-9" /></g></svg>
                                &nbsp;&nbsp;Consumables
                            </button> */}
                            <button className="deathreportbutton" onClick={e => {  setSelected("total") }} style={{ backgroundColor: selected === "total" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "total" ? "white" : "black" }}>
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 18v-5h5v5zm1-4v3h3v-3zm6-6.5h5v2h-5zM5 21a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6.17c-.5-.11-1-.17-1.5-.17H19V5H5v14h8.17c.17.72.46 1.39.83 2zm6-15v5H6V6zm-1 4V7H7v3zm9 2v1.5a4 4 0 0 1 4 4c0 .82-.25 1.58-.67 2.21l-1.09-1.09c.17-.34.26-.72.26-1.12A2.5 2.5 0 0 0 19 15v1.5l-2.25-2.25zm0 11v-1.5a4 4 0 0 1-4-4c0-.82.25-1.58.67-2.21l1.09 1.09c-.17.34-.26.72-.26 1.12A2.5 2.5 0 0 0 19 20v-1.5l2.25 2.25z"/></svg>
                                &nbsp;&nbsp;Total Inventory
                            </button>
                           
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-10 text-center" style={{ paddingRight: "2%" }}>
                    <div class="row" style={{ paddingTop: "4px", marginBottom:"0.5%" }}>
                   
                    
                   <div className="col-4"></div>
                   <div className="col-6"></div>
                                  
                                   <div class="col-2">
                                     
                                     <button 
               
                                       type="button"
                                       style={{
                                        border:"1px solid #00657c",
                                        boxShadow:"rgba(0, 0, 0, 0.25) 0px 4px 4px 0px",
                                        borderRadius:"10px",                          
                                        
                                         textDecoration: "none",
                                         curser: "pointer",
                                         
                                         color: "#007bff",
                                         
                                       }}
                                       className="notification1  btn-link"
                                       onClick={() => navigate("/nurseOrders")}
                                       >
                                         <img src={orders} className="" width="22px" />
                                      
                                       New Orders
                   
                                     </button>
                                   </div>
                   
                                   
                                 
                   
                                 </div>
                 
                        {selected === "medicine" ? <MedInventory  render={reRender}  /> :
                            selected === "consumables" ? <ConInventory render={reRender}   /> : 
                             selected === "total" ? <TotalInventory /> : ""
                            }
                    </div>
                </div>
    
       
        </>
      );
}
export default NurseInventory;
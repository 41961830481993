import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./staffdashboard.css";
import sp from "../../assets/images/icons/images.png";
import medicine from "../../assets/images/icons/medicine.png";
import scan from "../../assets/images/icons/scan.png";
import labtest from "../../assets/images/icons/labtest.png";
import steth from "../../assets/images/icons/service.png";
import symptoms from "../../assets/images/icons/symptoms.png";
import allergy from "../../assets/images/icons/allergy.png";
import patient from "../../assets/images/icons/patient.png";
import report from "../../assets/images/icons/report.png";
import medicon from "../../assets/images/icons/medicon.png";
import write from "../../assets/images/icons/write.png";
import consume from "../../assets/images/icons/consumable.png";
import request1 from "../../assets/images/icons/request1.png";
import message from "../../assets/images/icons/message.png";
import img from  "../../assets/images/icons/img.png";
import approval from  "../../assets/images/icons/approvalimage.jpg";
import customer from "../../assets/images/icons/customercare.png";
import BackofficeNavBar from "../BackoffcieNav/BackofficeNavBar";

function StaffDashboard() {
  const navigate = useNavigate();
  const [keys] = useState({
    h: false,
    d: false,
    u: false,
    r: false,
    l: false,
    i: false,
    a: false,
    p: false,
    v: false,
    t: false,
    n: false,
  });
const aggr_type=sessionStorage.getItem("aggr_type")
  console.log(sessionStorage.getItem("id"));

  useEffect(() => {
    window.onkeydown = (e) => {
      keys[e.key] = true;

      if (e.altKey && keys["h"]) {
        navigate("/hospitallist");
      }
      if (e.altKey && keys["d"]) {
        navigate("/doctorslist");
      }
      if (e.altKey && keys["u"]) {
        navigate("/search-hospitals");
      }
      if (e.altKey && keys["r"]) {
        navigate("/rejectedlist");
      }
      if (e.altKey && keys["l"]) {
        navigate("/reportlist");
      }
      if (e.altKey && keys["i"]) {
        navigate("/addsynonyms");
      }
      if (e.altKey && keys["a"]) {
        navigate("/failedlist");
      }
      if (e.altKey && keys["p"]) {
        navigate("/prescriptionlist");
      }
      if (e.altKey && keys["v"]) {
        navigate("/written-prescriptionlist");
      }
      if (e.altKey && keys["t"]) {
        navigate("/testlist");
      }
      if (e.altKey && keys["n"]) {
        navigate("/nutrition");
      }
    };
    window.onkeyup = (ev) => {
      keys[ev.key] = false;
    };

    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);

  const handleBack = () => {
    Navigate.goBack();
  };

  return (
    <>
      <div>{/* <BackofficeNavBar /> */}</div>
      {/* <div className="text-left">

        <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button>


      </div> */}
      <div>
        <div className="home">
          <h3 className="title-of-page"> Service Management</h3>
          <div className="home-container">
            {aggr_type==="aggregator"?<div className="home-tiles">
              <Link to="/hospitallist" style={{ textDecoration: "none" }}>
                <img src={sp} className="home-icon" width="65px" />
                <h4 className="each-title"> Service Providers </h4>
                
              </Link>
            </div>:""}

            <div className="home-tiles">
              <Link to="/servicelist" style={{ textDecoration: "none" }}>
                <img src={steth} className="home-icon" width="65px" />
                <h4 className="each-title"> Service List </h4>
               
              </Link>
            </div>
            {/* <div className="home-tiles">
              <Link to="/serviceorderlist" style={{ textDecoration: "none" }}>
                <img src={request1} className="home-icon" width="65px" />
                <h4 className="each-title"> Service Order Request </h4>
               
              </Link>
            </div> */}

            {/* <div className="home-tiles">
              <Link to="/customercare" style={{ textDecoration: "none" }}>
                <img src={customer} className="home-icon" width="65px" />
                <h4 className="each-title"> Customer Service Centre </h4>
                
              </Link>
            </div> */}

            {/* <div className="home-tiles">
              <Link to="/approvalpage" style={{ textDecoration: "none" }}>
                <img src={approval} className="home-icon" width="65px" />
                <h4 className="each-title"> Promotion Approval</h4>
                
              </Link>
            </div> */}
         
          </div>
        
          <div className="seperator divide-line"> </div>
          <h3 className="title-of-page"> Treatment Planner</h3>
          <div className="home-container">
            <div className="home-tiles">
              <Link to="/Labtest" style={{ textDecoration: "none" }}>
                <img src={labtest} className="home-icon" width="65px" />
                <h4 className="each-title"> Laboratory </h4>
                
              </Link>
            </div>

            <div className="home-tiles">
              <Link to="/Scantest" style={{ textDecoration: "none" }}>
                <img src={scan} className="home-icon" width="65px" />
                <h4 className="each-title"> Scan </h4>
                
              </Link>
            </div>
            <div className="home-tiles">
            <Link to="/procedures" style={{ textDecoration: "none" }}>
              <img src={write} className="home-icon" width="65px" />
              <h4 className="each-title"> Procedures</h4>
            
            </Link>
          </div>

          {/* <div className="home-tiles">
            <Link to="/consumablelist" style={{ textDecoration: "none" }}>
              <img src={consume} className="home-icon" width="65px" />
              <h4 className="each-title"> Consumables</h4>
             
            </Link>
          </div> */}
            {/* <div className="home-tiles">
              <Link to="/misc" style={{ textDecoration: "none" }}>
                <img src={img} className="home-icon" width="65px" />
                <h4 className="each-title"> Miscellaneous </h4>
                
              </Link>
            </div> */}
          </div>
        </div>

        <div className="seperator divide-line"> </div>
        <h3 className="title-of-page"> Diagnosis & Info</h3>
        <div className="home-container">
          {/* <div className="home-tiles">
            <Link to="/observation-symptoms" style={{ textDecoration: "none" }}>
              <img src={symptoms} className="home-icon" width="65px" />
              <h4 className="each-title"> Observations and Symptoms </h4>
              
            </Link>
          </div> */}

          <div className="home-tiles">
            <Link to="/allergy" style={{ textDecoration: "none" }}>
              <img src={allergy} className="home-icon" width="65px" />
              <h4 className="each-title"> Allergy Details </h4>
             
            </Link>
          </div>
          <div className="home-tiles">
            <Link to="/primary-disease" style={{ textDecoration: "none" }}>
              <img src={patient} className="home-icon" width="65px" />
              <h4 className="each-title"> Primary Disease </h4>
             
            </Link>
          </div>
          <div className="home-tiles">
            <Link to="/comorbidities" style={{ textDecoration: "none" }}>
              <img src={report} className="home-icon" width="65px" />
              <h4 className="each-title"> Comorbidities</h4>
             
            </Link>
          </div>
         
        </div>
        <div className="seperator divide-line"> </div>
        <h3 className="title-of-page"> Medicines</h3>
        <div className="home-container">
          <div className="home-tiles">
            <Link to="/medicine" style={{ textDecoration: "none" }}>
              <img src={medicine} className="home-icon" width="65px" />
              <h4 className="each-title"> Medicines </h4>
             
            </Link>
          </div>
          <div className="home-tiles">
              <Link to="/medlist" style={{ textDecoration: "none" }}>
                <img src={medicon} className="home-icon" width="65px" />
                <h4 className="each-title"> Medicine Approval </h4>
                
              </Link>
            </div>
        </div> 

       
      </div>
    </>
  );
}
export default StaffDashboard;

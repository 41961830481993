import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { APIURL,TZ } from "../../../Global";
import Pagination from "react-js-pagination";
import {  Dropdown, ToggleButton, Tab, Tabs,  ButtonGroup } from "react-bootstrap";
//import "./pagination.css";
import { Modal } from "react-bootstrap";
import NewArrivals from "./NewArrivals";
import DealPromotions from "./DealPromotions";
import BestSelling from "./BestSelling";

function Approvalpage(){
    const [activeTab, setActiveTab] = useState("newarrival");
    const handleSelect = (key) => {
        setActiveTab(key);
      };

      const [detailData, setDetailData] = useState([]);
      const [detailData1, setDetailData1] = useState([]);
      const [detailData2, setDetailData2] = useState([]);
  
    useEffect(() => {
        
      const tokenString = sessionStorage.getItem("usertoken");
      let v2 = tokenString.replace(/["]+/g, "");
  
      axios
        .get(`${APIURL}/api/v1/staff/promotion-approval/?promo_type=new_arrival&status=pending`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const datas = res.data;
            setDetailData(datas);
          }
        })
        .catch((err) => {});
    }, []);

    useEffect(() => {
        
      const tokenString = sessionStorage.getItem("usertoken");
      let v2 = tokenString.replace(/["]+/g, "");
  
      axios
        .get(`${APIURL}/api/v1/staff/promotion-approval/?promo_type=deal_promotion&status=pending`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const datas = res.data;
            setDetailData1(datas);
          }
        })
        .catch((err) => {});
    }, []);

    useEffect(() => {
        
      const tokenString = sessionStorage.getItem("usertoken");
      let v2 = tokenString.replace(/["]+/g, "");
  
      axios
        .get(`${APIURL}/api/v1/staff/promotion-approval/?promo_type=best_selling&status=pending`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const datas = res.data;
            setDetailData2(datas);
          }
        })
        .catch((err) => {});
    }, []);



    return(<>

<div className="container">
<div className="container failedlist-parentcontainer">
<h2 className="title-of-page">Promotional Approval List</h2>

<div className="paymentconfigouter">
   <Tabs activeKey={activeTab} className="paymentconfigfont" onSelect={handleSelect}>
      <Tab eventKey="newarrival" title="New Arrivals" >
      <NewArrivals detailData={detailData} setDetailData={setDetailData}/>
      </Tab>
      <Tab eventKey="deals" title="Deal Promotions">
         <DealPromotions detailData1={detailData1} setDetailData1={setDetailData1}/> 
   
      </Tab>
      <Tab eventKey="best" title="Best Selling">
     <BestSelling detailData2={detailData2} setDetailData2={setDetailData2}/> 
      </Tab>
    
    </Tabs>

   </div>  

</div>
</div>


       


    </>)
}
export default Approvalpage

import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
//import { useHistory } from 'react-router-dom'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
//import HospitalBdoNavbarReports from '../HospBdoNavbar/HospBdoNavbarReports';
import Reports from './Reports';
import { Badge } from 'react-bootstrap';

import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import isAfter from 'date-fns/isAfter';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";

//import "./reports.css";
import Nav from 'react-bootstrap/Nav'
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
function PaymentReports() {
  const [timePeriod, setTimePeriod] = useState("month")
  const [load, setLoad] = useState('')
  const [paymentData, setPaymentData] = useState([])
  const [totalAmount, setTotalAmount] = useState(" ")
  const [count, setCount] = useState(" ")
  const [serviceData, setServiceData] = useState([])
  const [rentalData, setRentalData] = useState([])
  const [category, setCategory] = useState('sr-order')
  const [fromDate, setFromDate] = useState(moment((new Date(new Date().getFullYear(), new Date().getMonth(), 1))).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment((new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))).format('YYYY-MM-DD'));
const [tableCountDetails, setTableCountDetails] = useState([{ 'total_amount': '', 'count': '' }])
  const [checked, setChecked] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [show, setShow] = useState(false);


  useEffect(() => {

    apiCallFilter(1,10);


  }, []);


  const exportRegisterReport = () => {


    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Patient Payment Details";
    const headers = [["Patient Name", "Paid For", "Date", "PAyment Mode", "Paid Amount", "Total Amount"]];

    const data = paymentData.map(elt => [elt.patient, elt.paid_for, moment(new Date(elt.payment_date)).format('YYYY-MM-DD'), elt.is_counter_paid ? "Counter" : "Online", elt.amount_paid, elt.total_amount]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("payment_report.pdf")
  }

  useEffect(() => {
    if (category) {
      apiCallFilter(1, perPage)
    }

  }, [category]);

  useEffect(() => {
    if (fromDate) {
      apiCallFilter(1, perPage)
    }

  }, [fromDate,toDate]);


  const handleChangeRadio = (val) => {
    paymentData.splice(0, paymentData.length + 1)
    setCategory(val);

    
  }

  const handleChangeValue = (val) => {
    setTimePeriod(val)
    paymentData.splice(0, paymentData.length + 1)
    if (val == 'day') {
      var today = new Date();
      var FormatedCurrentDate = moment((today)).format('YYYY-MM-DD');
   
      setFromDate(FormatedCurrentDate)
      setToDate(FormatedCurrentDate)
      // apiCallFilter(1,10)

    }
    if (val == 'week') {
      var startOfWeek = moment().startOf('week').toDate();
      var endOfWeek = moment().endOf('week').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setFromDate(FormatedFirstday)
      setToDate(Formatedlastday)

      // apiCallFilter(1, 10);

    }
    if (val == 'month') {
      var date = new Date();
      var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      var FormatedfirstDayOfMonth = moment((firstDayOfMonth)).format('YYYY-MM-DD');
      var FormatedlastDayOfMonth = moment((lastDayOfMonth)).format('YYYY-MM-DD');
      setFromDate(FormatedfirstDayOfMonth)
      setToDate(FormatedlastDayOfMonth)

      // apiCallFilter(1, 10);

    }
    if (val == 'year') {
      var currentDates = new Date();
      var theFirst = new Date(currentDates.getFullYear(), 0, 1);
      var theLast = new Date(currentDates.getFullYear(), 11, 31);
      var FormatedfirstDayOfYear = moment((theFirst)).format('YYYY-MM-DD');
      var FormatedlastDayOfYear = moment((theLast)).format('YYYY-MM-DD');
      setFromDate(FormatedfirstDayOfYear)
      setToDate(FormatedlastDayOfYear)
      // apiCallFilter(1, 10);
    }
  }

  const apiCallFilter = (page, per_page) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const a = fromDate;
    const b = toDate;

    axios.get(`${APIURL}/api/v1/service-provider/hospital-payment-reports/?start_date=${a}&end_date=${b}&type=${category}&page=${page}&per_page=${per_page}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res2 => {

        if (res2.data.status === "success") {

          setLoad('')
          setHasNext(res2.data.has_next)
          let datalist4 = res2.data.payments
          setTotalRows(res2.data.total_records)
          setPaymentData([...paymentData,...datalist4])
        
        }
        else {
          setLoad('Error in Fetching Data')
        }

      })



  }

  const handleEvent = (event, picker) => {
    paymentData.splice(0, paymentData.length + 1)
    const a = moment(event[0]).format('YYYY-MM-DD');
    let b = moment(event[1]).format('YYYY-MM-DD');

    setFromDate(a);
    setToDate(b);
    // apiCallFilter(1, 10);
  }




  const renderTable = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize" }}>{paymentData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }
  const renderPaidfor = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize" }}>{paymentData[cellInfo.index][cellInfo.column.id]==="sr-order"?"Service Order"
      :paymentData[cellInfo.index][cellInfo.column.id]==="test"?"Lab Test"
      :paymentData[cellInfo.index][cellInfo.column.id]==="misc"?"Miscellaneous"
        :paymentData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }

  const renderStatus = (cellInfo) => {
    if (paymentData[cellInfo.index][cellInfo.column.id] === true) {
      return (

        <div style={{ color: "black" }}>Counter</div>
      )
    }
    else {
      return (

        <div style={{ color: "black" }}>Online</div>
      )
    }
  }
  const renderDate = (cellInfo) => {
    let dateString = paymentData[cellInfo.index][cellInfo.column.id]
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div style={{ color: "black" }}>
        {updatedDate.slice(4, 16)}

      </div>
    )
  }



  const columns = [
    {
      Header: "Name",
      accessor: "patient",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,

      // width: 210

    },
    {
      Header: "Paid for",
      accessor: "paid_for",
      Cell: renderPaidfor,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,

      // width: 210

    },
    {
      Header: "Date",
      accessor: "payment_date",
      Cell: renderDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      sortable: true,
      filterable: true,

      // width: 210

    },

    {
      Header: "Payment Mode",
      accessor: "is_counter_paid",
      Cell: renderStatus,

      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      // width: 210

    },

    {
      Header: "Paid Amount",
      accessor: "amount_paid",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      // width: 210

    },
    {
      Header: "Total Amount",
      accessor: "total_amount",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      // width: 210

    }
  ]
  const handlePageChange = page => {
    if (hasNext) {
      apiCallFilter(page + 1, perPage);
    }
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage)
    paymentData.splice(0, paymentData.length + 1)
    apiCallFilter(1, newPerPage);

  }
  useEffect(() => {
    apiCallFilter(page, perPage);
  }, [perPage]);


  const display = <ReactTable
    columns={columns}
    data={paymentData}
    defaultPageSize={perPage - 1}
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    className='calllog-table'
    resizable
    pagination
    paginationServer
    onPageChange={(pageIndex) => { handlePageChange(pageIndex); setPage(pageIndex + 1) }}
    paginationTotalRows={totalRows}
    onPageSizeChange={handlePerRowsChange}
  ></ReactTable>


  const CsvRegisterheaders = [
    { label: "Patient Name", key: "patient" },
    { label: "Paid For", key: "paid_for" },
    { label: "Date", key: "payment_date" },
    { label: "Paid Amount", key: "amount_paid" },
    { label: "Total Amount", key: "total_amount" },

  ];
 

  return (
    <>
    

      <div className="patient-report">
      <Nav class="row">
      <span class="col-10">
            <h4 className="title-of-reportpage"> Payment Report</h4>
          </span>
          <span style={{ display: "flex", marginTop: "1%" }} class="col-2">
            
            <button  onClick={() =>setShow(!show)} className='btnexport'><i class="fa fa-upload"> &nbsp;&nbsp; Export</i></button>
          
           
          </span>



</Nav>
{show?<div className='exportDisplay' >
          <button style={{ border: "none", backgroundColor: " transparent", color: "var(--bs-link-color)" }} onClick={exportRegisterReport}><i class="fa fa-file-pdf-o"> &nbsp;&nbsp; PDF</i></button>
       <CSVLink
                  style={{ marginLeft: "3.5%" }}
                  data={paymentData}
                  headers={CsvRegisterheaders}

                ><i class="fa fa-file-excel-o">
                    &nbsp;&nbsp;&nbsp;&nbsp;CSV</i>
                </CSVLink>
      </div>:""}
        <div className="report-display">

        <span class="col-9 togglegroup" style={{ marginLeft: "", }}>
            <ToggleButtonGroup style={{marginTop: "1%",fontSize:"small"}} type="radio" name="options" defaultValue={category} onChange={handleChangeRadio}>
            <ToggleButton id="tbg-radio-7" value={'registration'} className="btn btn-light toggle-btns custombtn custombtnreg">
                Registration
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-1" value={'sr-order'} className="btn btn-light toggle-btns custombtn custombtnreg">
                Service Request
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-2" value={'test'} className="btn btn-light toggle-btns custombtn custombtnreg">
                Lab Test
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-3" value={'scan'} className="btn btn-light toggle-btns custombtn custombtnreg">
                Scan
              </ToggleButton> <span>&nbsp;&nbsp;&nbsp;</span>             
              <ToggleButton id="tbg-radio-5" value={'procedure'} className="btn btn-light toggle-btns custombtn custombtnreg">
                Procedure
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-4" value={'medicine'} className="btn btn-light toggle-btns custombtn custombtnreg">
                Medicine
              </ToggleButton><span>&nbsp;&nbsp;&nbsp;</span>
              <ToggleButton id="tbg-radio-6" value={'misc'} className="btn btn-light toggle-btns custombtn custombtnreg">
                Miscellaneous
              </ToggleButton>
            </ToggleButtonGroup> </span>


        </div>
      
        <Nav class="row" style={{ marginTop: "1%" }}>
          <span class="col-5"> </span>
          <span class="col-6 togglegroup" style={{ marginBottom: "1%",marginLeft:"" }}>
            <ToggleButtonGroup style={{ marginTop: "1%", fontSize: "small", }} type="radio" name="options" defaultValue={timePeriod} onChange={handleChangeValue}>
            <DateRangePicker
              appearance="default"
              style={{ width: "220px" }}
              placeholder={"Select Date Range"}
             
              //  alwaysShowCalendars={true}
              onChange={handleEvent}
                disabledDate={(date) =>
                  isAfter(date, new Date())}

              >

                <ToggleButton  id="tbg-radio-4" value={'custom'} className="btn btn-light toggle-btns custombtn">

                </ToggleButton>

              </DateRangePicker>&nbsp;&nbsp;&nbsp;
              <ToggleButton  id="tbg-radio-7" value={'day'} className="btn btn-light toggle-btns custombtn">
                Day
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton  id="tbg-radio-1" value={'week'} className="btn btn-light toggle-btns custombtn ">
                Week
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton  id="tbg-radio-2" value={'month'} className="btn btn-light toggle-btns custombtn">
                Month
              </ToggleButton>&nbsp;&nbsp;&nbsp;
              <ToggleButton id="tbg-radio-3" value={'year'} className="btn btn-light toggle-btns custombtn ">
                Year
              </ToggleButton>
             

            </ToggleButtonGroup> </span>
        </Nav>
        
        <div className="service-table-holder " style={{ marginRight: "2%" }}> {load === '' ? display
          : load === 'Error in Fetching Data' ? <h6 style={{ color: "red" }}>{load}</h6> : <h6 style={{ color: "grey" }}>{load}</h6>}
        </div>
      </div>
      <br />

    </>

  )
}

export default PaymentReports

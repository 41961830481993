
import React, { useState, useEffect, useCallback } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { NavDropdown } from "react-bootstrap";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton, Badge,Button } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import DatePicker from "./Datepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import "../AdmissionReport/reports.css";
import isAfter from 'date-fns/isAfter';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { Modal } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import InventoryFilter from "./InventoryFilter";
import peaceImage from '../../../assets/images/icons/peacevillage.png'; 
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { MdDownloadForOffline } from "react-icons/md";
import { GrClose } from "react-icons/gr";


function InventoryReport(props) {
  const { } = props; 
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalRows1, setTotalRows1] = useState(0);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [hasNext1, setHasNext1] = useState(false)
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState('')
  const [activate,setActivate]=useState(false);
  const [returnValue,setReturnValue]=useState("");
  const [recValue,setRecValue]=useState(false);
  const [activeValue, setActiveValue] = useState("")
  const [modalShow, setModalShow] = useState(false);
  const [registerData, setRegisterData] = useState([])
  const [registerData1, setRegisterData1] = useState([])
  const [timePeriod, setTimePeriod] = useState("month")
  const [refresh,setRefresh]=useState(false);
  const [refresh1,setRefresh1]=useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
 
  const [selectedValue, setSelectedValue] = useState(''); // You can set the initial selected value here
  const [showMonths, setShowMonths] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
  const [monthsYear, setMonthsYear] = useState(moment().format('YYYY'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedDownloads, setSelectedDownloads] = useState([]);
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  const [dateRange,setDateRange] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  
  

  const handleClear = () => {
    setRegisterData([])
    // Add code here to reset the state when the clear button is clicked
    setFromDate(''); // Reset the fromDate state
    setToDate('');   // Reset the toDate state
    setFromDate1('');
    setToDate1('');
    setSelectedValue(''); // Reset other relevant state values
    setReturnValue("");
    setActiveValue("")
    
   setRefresh(!refresh)
  };

  

  

  const handleApplyFilter = (selectedSkills, dateRange,radioValue) => {

    
   
    if(selectedSkills && selectedSkills==="1"){
        setActiveValue("active");
    }
    if(selectedSkills && selectedSkills==="2"){
        setActiveValue("deceased"); 
    }
    if(radioValue && radioValue==="3"){
      setRecValue(true)
      setReturnValue('')
    }
    if(radioValue && radioValue==="4"){
      setRecValue(false)
      setReturnValue("returned")
    }
    if(dateRange[0]===undefined){
      setRefresh(!refresh)
    }
    else if(dateRange){
      if(radioValue==="3"){
        setFromDate(dateRange[0]);
        setToDate(dateRange[1]);

      }else{
        setFromDate1(dateRange[0]);
        setToDate1(dateRange[1]);
      }
    }
  };
  
  const handlePopUp=()=>{
    setModalShow(false)
  }

  const ConfigPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="config-modal-width"
        style={{marginTop:'5rem',display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        <Modal.Header style={{height:'50px' }} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            <h6 className="title-of-tasks" style={{ }}>
            Filter
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InventoryFilter isReturned={returnValue} activeVal={activeValue} data={registerData}  fromDate={fromDate}
        toDate={toDate}  updateDateRange={(from, to) => {
          setFromDate(from);
          setToDate(to);
        }} handle={handlePopUp}  onApplyFilter={handleApplyFilter}/>
        </Modal.Body>
      </Modal>
    );
  };
  
  


  useEffect(() => {
    
   
    setRegisterData([])
    apiCallFilter(1,10);
   setFromDate("")
   setToDate("")
   setSelectedValue("")


   
  }, [refresh]);


  const handleRefresh =(e)=>{
    
    e.preventDefault();
    setCurrentMonth(moment().format('MMMM'));
    setMonthsYear(moment().format('YYYY'));
    setRegisterData([])

    setFromDate1("");
    setToDate1("");
    setActiveValue("")
    setReturnValue("")
    setPage(1);
    setFromDate("")
    setToDate("")
    setRefresh(!refresh)
    setDateRange(null); 
  }
  const downloadOptions = [ "PDF", "CSV" ];

  const toggleDownloadDropdown = () => {
    setIsDownloadDropdownOpen(!isDownloadDropdownOpen);
  };

  const handleDownloadChange = (download) => {
    setSelectedDownloads((prevDownloads) => {
      if (prevDownloads.includes(download)) {
        // Deselect the checkbox
        return prevDownloads.filter(item => item !== download);
      } else {
        return [...prevDownloads, download];
      }
    });
  };

  const capitalize = (str) => {
    return str&&str!==""&&str.replace(/\b\w/g, c => c.toUpperCase());
};

  const handleDownloads = () => {

    // Perform actions based on selected download options
    selectedDownloads.forEach((selectedOption) => {
      if (selectedOption === 'PDF') {
            
          const unit = "pt";
          const size = "A4";
          const orientation = "landscape";
          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
        
          doc.setFontSize(15);
          const title = "Inventory Report";
        
          const headers = [
            "Patient Name",
            "Account Status",
            "Item Name",
            "Amount",
            "Received Date",
            "Received By",
            "Deposited By",
            "Returned Date",
            "Returned To",
            "Returned By",
            "Status"
          ];
        
          // Create a map of patients to their inventory details
          const patientMap = new Map();
        
          registerData.forEach((patient) => {
            const patient_name = capitalize(patient.patient_name)
            if (!patientMap.has(patient_name)) {
              patientMap.set(patient_name, {
                accountStatus: patient.account_status,
                inventoryDetails: []
              });
            }
        
            patient.inventory_details.forEach((item) => {
              patientMap.get(patient_name).inventoryDetails.push([
                capitalize(item.item_name),
                item.value,
                item.received_date.split('T')[0], // Format date as yyyy-mm-dd
                capitalize(item.received_by),
                capitalize(item.deposited_by),
                item.returned_date ? moment(item.returned_date).format('YYYY-MM-DD') : '-',
                item.returned_to ? capitalize(item.returned_to) : "-",
                item.returned_date ? capitalize(item.returned_by) : "-",
                capitalize(item.status)
              ]);
            });
          });
        
          let content = {
            startY: 50,
            head: [headers],
            body: []
          };
        
          // Iterate through the patientMap and add data to the report
          patientMap.forEach((data, patientName) => {
            content.body.push([patientName, data.accountStatus, ...data.inventoryDetails[0]]);
            for (let i = 1; i < data.inventoryDetails.length; i++) {
              content.body.push(['', data.accountStatus, ...data.inventoryDetails[i]]);
            }
          });
        
          doc.text(title, marginLeft, 40);
          doc.autoTable(content);
          doc.save("Inventory_Report.pdf");
        
      } else if (selectedOption === 'CSV') {
                
        const csvData = registerData.flatMap(elt => {
          return elt.inventory_details.map(item => {
            return [
              capitalize(elt.patient_name),
              capitalize(elt.account_status),
              capitalize(item.item_name),
              item.value,
              capitalize(item.received_by),
              moment(item.received_date).format('YYYY-MM-DD'),
              item.returned_date ? moment(item.returned_date).format('YYYY-MM-DD') : '', 
              item.returned_to ? capitalize(item.returned_to) : '', 
              item.returned_by ? capitalize(item.returned_by) :'',  
              item.deposited_by || '',
              capitalize(item.status)
            ];
          });
        });
        
        // Generate CSV content
        const headerRow = 'Name,Account Status,Item,Value,Received By,Received Date,Returned Date,Returned To,Returned By,Deposited By,Status\n';
        const csvContent = headerRow + csvData.map(row => row.map(value => `"${value}"`).join(',')).join('\n');
        
        // Create a Blob and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Inventory_Report.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);   
      
  }});

    // Reset selected downloads
    setSelectedDownloads([]);

    setIsDownloadDropdownOpen(false)
  };  

  // useEffect(() => {
  //   if (fromDate1 || activeValue) {
  //     apiCallFilter1(1, perPage)
  //   }

  // }, [fromDate1,toDate1,activeValue]);
  useEffect(() => {
    apiCallFilter(1,10);
  },[refresh1])
   
  useEffect(() => {
    if (fromDate || fromDate1 || activeValue || returnValue) {
      setRegisterData([])
      apiCallFilter(1, perPage)
      setRefresh1(!refresh1)
    }

  }, [fromDate,toDate,fromDate1,activeValue,returnValue]);

  const calculateMonthDates = (selectedMonth) => {
    const currentDate = moment();
    const targetMonth = moment(selectedMonth, 'MMMM YYYY');
  
    const fromDate = targetMonth.startOf('month').format('YYYY-MM-DD');
    const toDate = targetMonth.endOf('month').format('YYYY-MM-DD');
  
    return { fromDate, toDate };
  };


  const handleChangeValue = (val) => {

    setSelectedValue(val);
    registerData.splice(0, registerData.length + 1)
    setTimePeriod(val)    
    // Calculate the start of the week (Sunday)
    var startOfWeek = moment().startOf('week').toDate();  
    // Calculate the end of the week (today)
    var endOfWeek = moment().endOf('day').toDate();
    var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
    var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
    setFromDate(FormatedFirstday)
    setToDate(Formatedlastday)
  }
  const handleMonthChange = (increment) => {
    registerData.splice(0, registerData.length + 1)
    const currentMoment = moment(`${monthsYear}-${currentMonth}`, 'YYYY-MMMM');
    const newMoment = currentMoment.add(increment, 'months');

    //Set maximum month to one year more
    const minMonth = moment().subtract(1, 'year').endOf('month')-1;
    if (newMoment.isSameOrBefore(minMonth, 'month')) {
      setCurrentMonth(minMonth.format('MMMM'));
      setMonthsYear(minMonth.format('YYYY'));
      return;
    }

    // Set minimum month to the current month
    if (newMoment.isAfter(moment(), 'month')) {
      setCurrentMonth(moment().format('MMMM'));
      setMonthsYear(moment().format('YYYY'));
      return;
    }

   
      const startOfMonth = newMoment.startOf('month').format('YYYY-MM-DD');
      const endOfMonth = newMoment.endOf('month').format('YYYY-MM-DD');
  
      setFromDate(startOfMonth);
      setToDate(endOfMonth);
   
    setCurrentMonth(newMoment.format('MMMM'));
    setMonthsYear(newMoment.format('YYYY'));

    setSelectedValue('month');

  };
  const generateYearOptions = () => {
    const years = [];
    for (let year = 2023; year <= currentYear; year++) {
      years.push(String(year));
    }
    return years;
  };
  const filterOptions = [ ...generateYearOptions()];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const handleFilterChange = (filter) => {
    setSelectedYears((cmngYears) => {
      if (cmngYears.includes(filter)) {
        // Deselect the checkbox
        return [];
      } else {
        // Select the checkbox and disable others
        return [filter];
      }
    });
  };
  const handleApplyFilters = () => {
    registerData.splice(0, registerData.length + 1)

    if (selectedYears.length > 0) {
      const sortedYears = selectedYears.sort((a, b) => Number(a) - Number(b));
  
      const startDate = sortedYears[0] + '-01-01';
      const endDate = sortedYears[selectedYears.length - 1] + '-12-31';
  
      setFromDate(startDate);
      setToDate(endDate);
  
    }
  
    setSelectedYears([]);
    setIsDropdownOpen(false);
    setSelectedValue('');

  };

  const apiCallFilter = (page, per_page) => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const a = fromDate1 ? fromDate1 :"";
    const b = toDate1 ?  toDate1 :"";
    const c = fromDate ?  fromDate :"";
    const d = toDate ?  toDate :"";
    let data = [];
    //  2021-10-12
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?returned=${returnValue}&acc_status=${activeValue}&return_start=${a}&return_end=${b}&page=${page}&per_page=${per_page}&receive_start=${c}&receive_end=${d}&${TZ}`,{
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setHasNext(res.data.has_next)
          setLoad('')
          let datalist = res.data.patients
         // const data1 = datalist.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData([...registerData,...datalist])
          setTotalRows(res.data.total_records)
        }
        else {
          setLoad('Error in Fetching Data')
        }

      })


      .catch(err => {
        setLoad('Error in Fetching Data')
      })


  }


  const apiCallFilter1 = (page, per_page) => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const a = fromDate1 ? fromDate1 :"";
    const b = toDate1 ?  toDate1 :"";
    const c = fromDate ?  fromDate :"";
    const d = toDate ?  toDate :"";
    let data = [];
    //  2021-10-12
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?returned=${returnValue}&acc_status=${activeValue}&return_start=${a}&return_end=${b}&page=${page}&per_page=${per_page}&receive_start=${c}&receive_end=${d}&${TZ}`,{
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setHasNext1(res.data.has_next)
          let datalist = res.data.patients
          if (datalist && datalist.length > 0) {
            const newData = datalist.filter(function (o1) {
              return !data.some(function (o2) {
                return o1.id === o2.id;
              });
            });
            data = [...data, ...newData];
          }
          setRegisterData1(data)
          setTotalRows1(res.data.total_records)
        }
        else {
          setLoad('Error in Fetching Data')
        }

      })


      .catch(err => {
        setLoad('Error in Fetching Data')
      })


  }



  const handleEvent = (value, picker) => {
    setShowMonths(false);
    setShowYears(false);
    setShow(false);
    setDateRange(value);    
    if (value && value.length === 2) {
      // Check if event is not null and contains two dates
      const [start, end] = value;
      const fromDate = moment(start).format('YYYY-MM-DD');
      const toDate = moment(end).format('YYYY-MM-DD');
      setFromDate(fromDate);
      setToDate(toDate);
      setSelectedValue('');
    } else {
      // Handle the case where event is null or doesn't contain two dates
      setFromDate(''); // Reset fromDate
      setToDate('');   // Reset toDate
      setSelectedValue('');
      setRefresh(!refresh)
    }
  };
  

  const renderTable = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize",fontSize:"15px" }}>{registerData[cellInfo.index][cellInfo.column.id]}</div>
    )
  }
  const renderTableAccount = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize",fontSize:"15px" }}>{registerData[cellInfo.index].account_status.toLowerCase()}</div>
    )
  }

  const renderTableAccount1 = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize",fontSize:"15px" }}>{registerData[cellInfo.index].account_status.toLowerCase()}</div>
    )
  }

  const renderTablePatient = (cellInfo) => {

    return (
      <div style={{ color: "black",textTransform: "capitalize",fontSize:"15px" }}>{registerData1[cellInfo.index][cellInfo.column.id]}</div>
    )
  }


  const renderTable1 = (cellInfo) => {
    // Access the patient at the current index
    const patient = registerData[cellInfo.index];
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px", overflowX:"hidden" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}>{item.item_name}</li>
              ))}
            </ul>
          )}
      </div>
    );
  }

  const renderTable11 = (cellInfo) => {
    // Access the patient at the current index
    const patient = registerData1[cellInfo.index];
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}>{item.item_name}</li>
              ))}
            </ul>
          )}
      </div>
    );
  }
  
  
  const renderTable2 = (cellInfo) => {
      // Access the patient at the current index
      const patient = registerData[cellInfo.index];
  
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
          {cellInfo.column.id === 'patient_name'
            ? patient.patient_name // Display patient name
            : (
              <ul>
                {patient.inventory_details.map((item, index) => (
                  <li key={index}>{item.value}</li>
                ))}
              </ul>
            )}
        </div>
      );
  }
  const renderTable12 = (cellInfo) => {
    // Access the patient at the current index
    const patient = registerData1[cellInfo.index];

    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}>{item.value}</li>
              ))}
            </ul>
          )}
      </div>
    );
}
  
  const renderTable3 = (cellInfo) => {
      // Access the patient at the current index
      const patient = registerData[cellInfo.index];
  
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
          {cellInfo.column.id === 'patient_name'
            ? patient.patient_name // Display patient name
            : (
              <ul>
                {patient.inventory_details.map((item, index) => (
                  <li key={index}>{item.received_by}</li>
                ))}
              </ul>
            )}
        </div>
      );
  }
  const renderTable13 = (cellInfo) => {
    // Access the patient at the current index
    const patient = registerData1[cellInfo.index];

    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}>{item.received_by}</li>
              ))}
            </ul>
          )}
      </div>
    );
}
  const renderTable4 = (cellInfo) => {
      // Access the patient at the current index
      const patient = registerData[cellInfo.index];
  
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
          {cellInfo.column.id === 'patient_name'
            ? patient.patient_name // Display patient name
            : (
              <ul>
                {patient.inventory_details.map((item, index) => (
                  <li key={index}> {item.received_date.split('T')[0]}</li>
                ))}
              </ul>
            )}
        </div>
      );
  }

  const renderTable14 = (cellInfo) => {
    // Access the patient at the current index
    const patient = registerData1[cellInfo.index];

    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}> {item.received_date.split('T')[0]}</li>
              ))}
            </ul>
          )}
      </div>
    );
}

const renderTable15 = (cellInfo) => {
    // Access the patient at the current index
    const patient = registerData[cellInfo.index];

    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}> {item.returned_date && item.returned_date.split('T')[0]}</li>
              ))}
            </ul>
          )}
      </div>
    );
}

const renderTable16 = (cellInfo) => {
    // Access the patient at the current index
    const patient = registerData[cellInfo.index];

    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}> {item.returned_to}</li>
              ))}
            </ul>
          )}
      </div>
    );
}

const renderTable20 = (cellInfo) => {
  // Access the patient at the current index
  const patient = registerData[cellInfo.index];

  return (
    <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
      {cellInfo.column.id === 'patient_name'
        ? patient.patient_name // Display patient name
        : (
          <ul>
            {patient.inventory_details.map((item, index) => (
              <li key={index}> {item.returned_by}</li>
            ))}
          </ul>
        )}
    </div>
  );
}




  const renderTable5 = (cellInfo) => {
    
    // Assuming you want to display item names for the first patient
    const patient = registerData[cellInfo.index];
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}>{item.deposited_by}</li>
              ))}
            </ul>
          )}
      </div>
    );
  }

  const renderTable17 = (cellInfo) => {
    
    // Assuming you want to display item names for the first patient
    const patient = registerData1[cellInfo.index];
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}>{item.deposited_by}</li>
              ))}
            </ul>
          )}
      </div>
    );
  }

  const renderTable6 = (cellInfo) => {
    
    // Assuming you want to display item names for the first patient
    const patient = registerData[cellInfo.index];
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}>{item.status}</li>
              ))}
            </ul>
          )}
      </div>
    );
  }

  const renderTable18 = (cellInfo) => {
    
    // Assuming you want to display item names for the first patient
    const patient = registerData1[cellInfo.index];
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {cellInfo.column.id === 'patient_name'
          ? patient.patient_name // Display patient name
          : (
            <ul>
              {patient.inventory_details.map((item, index) => (
                <li key={index}>{item.status}</li>
              ))}
            </ul>
          )}
      </div>
    );
  }




  
  





  const filterDataName = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?pa_name=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
        // if (data.length>0){
         if (data && data.length > 0) {
          //const newData = data.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
       setRegisterData(data);
      //  setSelectedValue("")
      //  setFromDate()
      // setRefresh(!refresh)
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataName1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?pa_name=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData1(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData1((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };


  const filterDataAccountStatus = (filterValue) => {
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?acc_status=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          const data1 = data.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataAccountStatus1 = (filterValue) => {
    debugger;
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?acc_status=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData1(data);
         }
         else{
          const data1 = data.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData1((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataItem = (filterValue) => {
    debugger;
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?item_name=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
        //  const data1 = data.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataItem1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?item_name=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData1(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData1((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataNameAmount = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?values=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
         // const data1 = data.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataNameAmount1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?values=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData1(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData1((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataReceived = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;

    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?receive_by=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        let data=[]
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataReceived1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
 

    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?receive_by=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        let data=[]
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData1((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterDataRecDate = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?receive_date=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataRecDate1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?receive_date=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData1(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData1((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataDeposited = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?deposit_by=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataDeposited1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?deposit_by=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData1(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData1((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataStatus = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?status=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataStatus1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?status=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData1(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData1((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };


  const filterDataReturnDate1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?returned=returned&return_date=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataReturnToDate1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?returned=returned&return_to=${filterValue}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataReturnByDate1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
    const a = fromDate;
    const b = toDate;
let data=[]
    axios.get(`${APIURL}/api/v1/service-provider/inventory-report/?returned=returned&return_by=${filterValue}&receive_start=${a}&receive_end=${b}&${TZ}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          const data1 = data.filter((item) => !registerData1.some((existingItem) => existingItem.id === item.id));
          setRegisterData((prevData) => [...prevData, ...data1]);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };





  const columns1 = [
    {
      Header: " Patient Name",
    style: { whiteSpace: "unset",wordBreak: "break-all" ,backgroundColor: "rgba(0,0,0,0.1)"},
      accessor: "patient_name",
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataName(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      
      width: 200

    },

    {
      Header: "Account Status",
    style: { whiteSpace: "unset",wordBreak: "break-all" },
      accessor: "account_status",
      Cell: renderTableAccount,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataAccountStatus(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      
      width: 150

    },

    {
      Header: "Item",
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
      accessor: "item_name",
      Cell: renderTable1,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataItem(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
       width: 150

    },
    
    {
      Header: "Amount",
    style: { whiteSpace: "unset",wordBreak: "break-all" },
      accessor: "value",
      Cell: renderTable2,
      // style: {
      //   backgroundColor: "rgba(0,0,0,0.1)"
      // },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataNameAmount(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      // width: 200

    },
    {
      Header: "Received By",
    style: { whiteSpace: "unset",wordBreak: "break-all" ,backgroundColor: "rgba(0,0,0,0.1)"},
      accessor:"received_by",
      Cell: renderTable3,
        // style: {
        //   backgroundColor: "rgba(0,0,0,0.1)"
        // },
        sortable: true,
        filterable: true,
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
        },
      
        Filter: ({ filter, onChange }) => (
          // Custom filter input component
          <input
            value={filter ? filter.value : ''}
            onChange={(e) => {
              const filterValue = e.target.value;
              onChange(filterValue); // Update the filter value
             filterDataReceived(filterValue,); // Make an API request and update the filteredData state
            }}
            style={{ color: 'black' }}
          />
        ),
         width: 200
  
      },

      {
        Header: "Received Date",
      style: { whiteSpace: "unset",wordBreak: "break-all" },
        accessor:"received_date",
        Cell: renderTable4,
          // style: {
          //   backgroundColor: "rgba(0,0,0,0.1)"
          // },
          sortable: true,
          filterable: true,
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
        
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
               filterDataRecDate(filterValue,); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
          width: 130
    
        },
        
      {
        Header: "Deposited By",
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
        accessor:"deposited_by",
        Cell: renderTable5,
          // style: {
          //   backgroundColor: "rgba(0,0,0,0.1)"
          // },
          sortable: true,
          filterable: true,
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
        
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
               filterDataDeposited(filterValue,); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
          width: 130
    
        },

  
          
        {
            Header: "Status",
          style: { whiteSpace: "unset",wordBreak: "break-all" },
            accessor:"status",
            Cell: renderTable6,
              // style: {
              //   backgroundColor: "rgba(0,0,0,0.1)"
              // },
              sortable: true,
              filterable: true,
              filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
              },
            
              Filter: ({ filter, onChange }) => (
                // Custom filter input component
                <input
                  value={filter ? filter.value : ''}
                  onChange={(e) => {
                    const filterValue = e.target.value;
                    onChange(filterValue); // Update the filter value
                   filterDataStatus(filterValue,); // Make an API request and update the filteredData state
                  }}
                  style={{ color: 'black' }}
                />
              ),
              width: 130
        
            },


  ]

  const columns2 =[

    {
      Header: "Returned Date",
    style: { whiteSpace: "unset",wordBreak: "break-all" ,backgroundColor: "rgba(0,0,0,0.1)"},
      accessor:"returned_date",
      Cell: renderTable15,
        // style: {
        //   backgroundColor: "rgba(0,0,0,0.1)"
        // },
        sortable: true,
       filterable: true,
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
        },
      
        Filter: ({ filter, onChange }) => (
          // Custom filter input component
          <input
            value={filter ? filter.value : ''}
            onChange={(e) => {
              const filterValue = e.target.value;
              onChange(filterValue); // Update the filter value
             filterDataReturnDate1(filterValue,); // Make an API request and update the filteredData state
            }}
            style={{ color: 'black' }}
          />
        ),
         width: 130
  
      },

      {
          Header: "Returned By",
        style: { whiteSpace: "unset",wordBreak: "break-all" },
          accessor:"returned_by",
          Cell: renderTable20,
            // style: {
            //   backgroundColor: "rgba(0,0,0,0.1)"
            // },
            sortable: true,
           filterable: true,
            filterMethod: (filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
            },
          
            Filter: ({ filter, onChange }) => (
              // Custom filter input component
              <input
                value={filter ? filter.value : ''}
                onChange={(e) => {
                  const filterValue = e.target.value;
                  onChange(filterValue); // Update the filter value
                 filterDataReturnByDate1(filterValue,); // Make an API request and update the filteredData state
                }}
                style={{ color: 'black' }}
              />
            ),
             width: 130
      
          },

          {
            Header: "Returned To",
          style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
            accessor:"returned_to",
            Cell: renderTable16,
              // style: {
              //   backgroundColor: "rgba(0,0,0,0.1)"
              // },
              sortable: true,
             filterable: true,
              filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
              },
            
              Filter: ({ filter, onChange }) => (
                // Custom filter input component
                <input
                  value={filter ? filter.value : ''}
                  onChange={(e) => {
                    const filterValue = e.target.value;
                    onChange(filterValue); // Update the filter value
                   filterDataReturnToDate1(filterValue,); // Make an API request and update the filteredData state
                  }}
                  style={{ color: 'black' }}
                />
              ),
               width: 130
        
            },
        
  ]
  const columns = returnValue  ? [...columns1, ...columns2] : [...columns1]  ;


  const handlePageChange = page => {
    if (hasNext) {
      apiCallFilter(page + 1, perPage);
      setPage(page + 1)
    }
  }

  // const handlePageChange1 = page => {
  //   if (hasNext1) {
  //     apiCallFilter1(page + 1, perPage);
  //   }
  // }

  const handleAvailabityPopUp =(e)=>{
    e.preventDefault();
    setCurrentMonth(moment().format('MMMM'));
    setMonthsYear(moment().format('YYYY'));
    setRegisterData([])
    
    setFromDate1("");
    setToDate1("");
    setActiveValue("")
    setReturnValue("")
    setPage(1);
    setFromDate("")
    setToDate("")
    setDateRange(null);
       
  setModalShow(true)
  
  }
  
  // useEffect(() => {
  //   if (modalShow === true) {
  //     setFromDate('');
  //     setToDate('');
  //     setFromDate1('');
  //     setToDate1('');
  //   }
  // }, [modalShow]);

  const handleInventory =(e)=>{
    e.preventDefault();
    setActivate(false);
    setActiveValue("");
    setRegisterData1([])
    setRegisterData([])
    setRefresh(!refresh)
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage)
    registerData.splice(0, registerData.length + 1)
    apiCallFilter(1, newPerPage);

  }

  // const handlePerRowsChange1 = async (newPerPage, pageindex) => {
  //   setPerPage(newPerPage)
  //   registerData1.splice(0, registerData1.length + 1)
  //   apiCallFilter1(1, newPerPage);

  // }
  useEffect(() => {
    apiCallFilter(page, perPage);
    setSelectedValue('')
  }, [page,perPage]);

  const CustomPagination = ({
    page,
    pageSize,
    pages,
    canPrevious,
    canNext,
    onPageChange
  }) => {
    return (
      <div style={{display:"flex"}}>
        <button style={{width:"30%",margin: "3px", borderRadius: "5px", padding:"10px"}} onClick={() => onPageChange(page - 1)} disabled={!canPrevious}>
          Previous
        </button>
        <div style={{ width:"40%", textAlign: "center", padding: "10px"}}><span style={{ margin: "10px" }}>Page {page + 1}</span></div> 
        <button style={{width:"30%",margin: "3px", borderRadius: "5px", padding:"10px"}} onClick={() => onPageChange(page + 1)} disabled={!canNext}>
          Next
        </button>
      </div>
    );
  };

  const display = <ReactTable
    columns={columns}
    data={registerData}
    defaultPageSize={perPage - 1}
    style={{ overflow: "hidden"}}
    className="chol-table"
    resizable
    minRows={perPage - 1}
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    pagination
    paginationServer
    //pages={Math.ceil(totalRows / perPage)}
    onPageChange={(pageIndex) => { handlePageChange(pageIndex); }}
    paginationTotalRows={totalRows}
    onPageSizeChange={handlePerRowsChange}
    PaginationComponent={CustomPagination}
  ></ReactTable>

//   const display2 = <ReactTable
//   columns={columns1}
//   data={registerData1}
//   defaultPageSize={perPage - 1}
//   className="chol-table"
//   resizable
//   minRows={perPage - 1}
//   pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
//   pagination
//   pages={Math.ceil(totalRows1 / perPage)}
//   paginationServer
//   onPageChange={(pageIndex) => { handlePageChange1(pageIndex); }}
//   paginationTotalRows={totalRows1}
//   onPageSizeChange={handlePerRowsChange1}
// ></ReactTable>

 


  
  return (
    <>
      <div className="patient-report">
      <Nav className="row" 
      style={{  
        display: 'inline-block',
        width: '100%', 
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        margin:'auto'
      }}>
    <div style={{display: 'flex', marginTop:"1%"}}>
    <span  style={{float:"left",width: "35rem" }}>  
      <button className="buttonfilter" style={{ marginTop: "1%", borderRadius: "25px", width: "90px", background: "#1269B9",fontSize:"11px" }} onClick={(e) => handleRefresh(e)}>
        <span>Refresh &nbsp;<i className="fa fa-refresh" aria-hidden="true" style={{ color: "white" }}></i> </span>
      </button> 
    </span>
          <span  style={{display:'flex', marginBottom: "1%", textAlign: "right",float:"right"}}>
      <ToggleButtonGroup className="datepicker-reports" style={{ marginTop: "1%", fontSize: "small"}} type="radio" name="options" >
        <DateRangePicker
          appearance="default"              
          placeholder={"Select Date Range"}  
          value={dateRange}               
          onChange={handleEvent}
          disabledDate={(date) => isAfter(date, new Date())}
        >
          <ToggleButton id="tbg-radio-4" value={'custom'} className="btn btn-light toggle-btns custombtn1" style={{ border: 'none',fontSize:'11px'}}></ToggleButton>
        </DateRangePicker>&nbsp;&nbsp;&nbsp;
                       
        <div style={{ display: 'flex', alignItems: 'center',height: '30px', fontSize: '11px', fontWeight: '400px', border: `1px solid ${selectedValue === 'month' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }}>
          <button className='arrowButton' onClick={() => handleMonthChange(-1)}>
            {<AiFillCaretLeft />}
          </button>
          <span style={{ margin: '0 4px', width: '50px', textAlign: 'center' }}>{currentMonth}</span>
          <button className='arrowButton' onClick={() => handleMonthChange(1)}>
            {<AiFillCaretRight />}
          </button>
        </div>&nbsp;&nbsp;&nbsp;
        <button   
          style={{ display: 'flex',justifyContent:'center',alignItems:'center', height: '30px',width:'50px', fontSize: '11px',backgroundColor:'white', fontWeight: '400px', border: `1px solid ${selectedValue === 'week' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }} 
          onClick={() => handleChangeValue('week')}
        > 
          <span>Week</span>
        </button>&nbsp;&nbsp;
        <div style={{position:"relative"}}>
        <button
        className="buttonfilter" 
        style={{ borderRadius: "20px",height: '32px', width: '60px', fontSize: '11px', background: isDropdownOpen ? "rgb(13, 197, 197)" : " #1269B9" }}
        onClick={toggleDropdown}
      ><span>
        More
        </span>
      </button>&nbsp;
      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
            width:'100px',
            alignItems:'center',
          }}
        >
          {filterOptions.map((filter) => (
            <label key={filter} style={{ marginBottom: '5px' }}>
              <input
                type="checkbox"
                checked={selectedYears.includes(filter)}
                onChange={() => handleFilterChange(filter)}
                //disable others if one is selected
                disabled={selectedYears.length > 0 && !selectedYears.includes(filter)}
              />&nbsp;
              {filter}
            </label>
          ))}
          <button disabled={selectedYears.length===0} style={{ marginTop: '10px' }} onClick={handleApplyFilters}>
            Apply
          </button>
        </div>
      )}</div>
        <div> 
            <button className="buttonfilter"  style={{ width:"60px",height: '32px', fontSize: '11px', margin:"0"}} onClick={(e)=>handleAvailabityPopUp(e)}><span>Filters</span></button>  
            {(returnValue !== "" || activeValue !== "") && <button className='buttonfilter' style={{width: "80px",backgroundColor:"#1269B9", color:"white", height:"32px", fontSize:"11px", borderRadius:"0px 20px 20px 0px", marginLeft:"-10px"}} onClick={handleClear}><span>Clear Filters</span></button> }&nbsp;            
        </div>
        <button className='buttonfilter' style={{ width: '100px',height: '32px', fontSize: '11px' }} onClick={setIsDownloadDropdownOpen}>
          <span>Download  <MdDownloadForOffline /></span>
        </button>
      </ToggleButtonGroup> 
    </span></div>
        </Nav>    
        {isDownloadDropdownOpen && (
  <div style={{ position: 'relative', zIndex: 1000 }}>
    {/* Backdrop */}
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
      onClick={() => {}}
    ></div>
    
    {/* Dropdown */}
    <div className='download-dropdown'
      style={{
        position: 'absolute',
        margin: 'auto',
        width: '30rem',
        borderRadius: '10px',
        backgroundColor: '#fff',            
        padding: '10px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        zIndex: 1001,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '15rem',
        marginTop: '5%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid lightgrey',
          marginBottom: '10px',
          paddingBottom:'10px'
        }}
      >
        <button
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            marginLeft: 'auto',
          }}
          onClick={toggleDownloadDropdown}
        >
          <GrClose />
        </button>
      </div>
      <div style={{display:"flex", flexDirection:"column"}}>
        <h4 style={{ marginBottom:"2rem"}}>Select the formats to download</h4>
        {downloadOptions.map((download) => (
          <label key={download} style={{ marginBottom: '5px' }}>
            <input
              type="checkbox"
              checked={selectedDownloads.includes(download)}
              onChange={() => handleDownloadChange(download)}
            />&nbsp;
            {download}
          </label>
        ))} 
      </div>
      <button
        variant="primary"
        className='buttonfilter'
        style={{width: '100px', height: '32px', fontSize: '11px', marginTop:"10px" }}
        onClick={handleDownloads}
      >
        Download
      </button>
    </div>
  </div>
)}
      <div className="service-table-holder" style={{  }}> {load === ''   ? display
          :  "" }
        </div>
      </div> 
      <br />

      {modalShow ? (
        <ConfigPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            
          }}
        />
      ) : null} 
          </>

  )
}

export default InventoryReport

import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./service.css";
import logo from "../../assets/images/svp.png";
import { useAlert } from 'react-alert' 
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { SOCKETURL } from "../../Global";

import EditRemark from "./EditRemark";
import AddRemark from "./AddRemark";
import LoadingOverlay from "react-loading-overlay";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ViewRemark from "./ViewRemark";
import ViewInteractions from "./ViewInteractions";
import moment from 'moment';
import DispenceAdd from "./DispenceAdd";
import FormService from "./FormService";
import { Tooltip } from 'react-tooltip';
import { Tooltip as Tooltip1 } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
function Medicines (props){
    const alert = useAlert()

    const tableRef = useRef(null);
  var Typeahead = require("react-bootstrap-typeahead").Typeahead;
  const [reRender, setRerender] = useState(false);
  const [search, setSearch] = useState("");
    const [show, setShow] = useState(false);
  const [assignId, setAssignId] = useState(0);
  const [options, setOptions] = useState([]);
  const [hasNext, setHasNext] = useState(false)
  const [loading, setLoading] = useState(false);

  const [render, setRender] = useState(true);
  const [currentIndex, setCurrentIndex] = useState("");
  const [intakeValue, setIntakeValue] = useState("");
  const [, setCurrentColumn] = useState("");
  const [wardSearch, setWardSearch] = useState(false);

  const [infoShow1, setinfoShow1] = useState(false);

  const [infoData1, setInfoData1] = useState("");
  const [remarkData, setRemarkData]= useState('')
  const [remarkData1, setRemarkData1]= useState([])
  const [viewShow, setViewShow]= useState(false)

  const [, setGetError] = useState('');
  const [tableData, setTableData] = useState([]);
  const [medData, setMedData] = useState([]);
  const pageSize = 10;
  const [options1, setOptions1] = useState([]);
  const [updatedID, setUpdatedID] = useState();
  const [selectedAssignees, setSelectedAssignees] = useState({})
  const [modalShow, setModalShow] = useState(false);
  const [dispenceCount,setDispenceCount]=useState()

  const [remarkList, setRemarkList] = useState([])

 const [orderedCount,setOrderedCount] = useState("");
 const [dispenceID,setDispenceID] = useState("");
 const[dispenseShow,setDispenseShow] = useState(false);

 const [disHistory,setDisHistory]=useState([])

  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const[page,setPage]=useState(1)
  const [editShow, setEditShow] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false)
const [editingcol,setEditingCol]=useState()
  const [cell, setCell] = useState('')
 const [dispatched,setDispatched]=useState()
 
  const [id, setId] = useState("");
  const [filterid, setFilterId] = useState("");
  const [filtername, setFiltername] = useState("");
  const [filterpatname, setFilterPatname] = useState("");
  const [filterdocname, setFilterDocname] = useState("");
  const [filternursename, setFilterNursename] = useState("");
  const [filterdate, setFilterdate] = useState("");
  const [filterqty, setFilterQty] = useState("");
  const [filterbdunit, setFilterbdunit] = useState("");
  const [patient, setpatient] = useState("");
  const [order, setOrder] = useState("")
  const [trackId, setTrackingId] = useState()
 


  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var sendData = null

  function handleSend(childData) {
    sendData = childData
    
  }
  
  
 
  
  const saveChangesMed = (index) => {

    const rowToUpdate = medData.filter((elt, i) => {
      return i === index
    })

    callSaveMed(rowToUpdate[0],index);

  }
  useEffect(() => {
    
    setMedData(medData)
    // console.log(medData)
  }, [reRender, medData]);

  useEffect(() => {
    console.log(options);
    const xdata = options1;
    
    let filteredData = xdata.length > 0 && xdata.filter(x => {
        return x.name.toLowerCase().includes(search.toLowerCase());
    });
    
    setOptions(filteredData);
    console.log("filteredData", filteredData); // Log the object directly
}, [search]);

  const handleCloseDispence =()=>{
    setDispenseShow(false);
  // setMedData([])
  // setRerender(!reRender)
  // setBackup('')
   // window.location.reload(false)
   
  }


  const callSave1Med = (rowToUpdate,index) => {
   
  
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
   // const id = rowToUpdate.id;
  
  
   const dataToUpdate = {
    status: "rejected"
  };
  
  if (assignId) {
    dataToUpdate.assigned_to = assignId;
  }
  
  
  

    axios.put(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${rowToUpdate.id}/`, dataToUpdate, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
        
          alert.success("Rejected successfully");
          handlePostSuccess();
          setSearch("")
          setAssignId(0)
          setSelectedAssignees({})
          setMedData([]);
          setRerender(!reRender);
       
        }
        else {
     alert.error(res.data.message ? res.data.message : "Error in submission");

        }
      })
      .catch(err => {

     alert.error("Error in submission");
     

      })

  }

  const callSaveMed = (rowToUpdate,index) => {
    
  
   
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
let dataToUpdate={}

if(rowToUpdate.interactions.intake_unit==="nos"){
  dataToUpdate = {
    
    status: rowToUpdate.dispensing_count===rowToUpdate.dispatched_count+rowToUpdate.remaining_count?"approved":"partially_approved",
    dispatched_count:rowToUpdate.remaining_count
  };
}
else{
 dataToUpdate = {
    status: "approved",
    dispensing_count:rowToUpdate.remaining_count,
    dispatched_count:rowToUpdate.remaining_count
  };
}
  
    axios.put(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${rowToUpdate.id}/`, dataToUpdate, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          alert.success("Approved successfully");
          handlePostSuccess();
          setSearch("")
          setAssignId(0)
          setSelectedAssignees({})
          setMedData([]);
          setRerender(!reRender);
        }
        else {
          alert.error(res.data.message ? res.data.message : "Error in submission");
        }
      })
      .catch(err => {

        alert.error("Error in submission");
       

      })
    
  
    
  }
  

  const handleCallback = () => {
    medData.splice(0, medData.length + 1)
    getServices1(1, perPage);
  };

  useEffect(()=>{
    handleCallback()
  },[props.render,reRender,perPage,filterid,filtername,filterpatname,filterdocname,filternursename,filterdate,filterqty,filterbdunit]);
 
  const handlePageChange1 = (page) => {
    if (hasNext) {
      getServices1(page + 1, perPage);
    }
  };

  const handlePerRowsChange1 = async (newPerPage, pageindex) => {
    setPerPage(newPerPage);
  };

  const getServices1 =  async  (page, per_page) => {

 
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');


 await   axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&status=pending&page=${page}&per_page=${per_page}&timeZone=${currentTimezone}&
current_date=${formattedDate}&request_pid=${filterid}&name=${filtername}&patient_name=${filterpatname}&doctor_name=${filterdocname}&date=${filterdate}&quantity=${filterqty}
&nurse_name=${filternursename}&bedunit_code=${filterbdunit}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        if (res.data.status === "success") {
  
         
          setLoading(false);
          let data=res.data.requests
          if (data && data.length > 0) {
          
            setMedData((prevData) => {
              const newData = data.filter((item) => !prevData.some((existingItem) => existingItem.id === item.id));
              const newData1 = newData.filter((item) => item.interactions !== null);
              return [...prevData, ...newData1];
              
            }); 
            props.setCount1() 
            setPage(res.data.page)
            setHasNext(res.data.has_next);
            setTotalRows(res.data.total_records);}
        }
       
        else {
          setLoading(false);

          setGetError(res.data.message);
        }



      })
      .catch(err => {
        setLoading(false);

        setGetError("Error in fetching data");
      })
  };
  const handleRender=(e)=>{
  
    setModalShow(false)
  
  setTableData([])
  setMedData([])
  setRerender(!reRender)

  }

  const AddPopup = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
         <span>Add Remark</span>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} trackId={trackId} id={id} patient={patient} order={order} render1={handleRender}  />

        </Modal.Body>
       
      </Modal>
    );
  }

 


  const ViewPopup=(props)=>{
    

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ViewInteractions data={remarkData1}  />
  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         
  
        </Modal.Footer>
      </Modal>
    );
  }

  

  const InfoPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <span >Medicine Details</span>
       
        </Modal.Header>
        <Modal.Body>
          <div>
          <div className="row-of-features">
               <div className="col-of-features"><br/>               
                 <h6 style={{textAlign:"left"}}>Medicine Name:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.medicine_name}</span></h6>
                 {/* <h6>Strength:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.strength}</span></h6> */}
                 {/* <h6>Medicine Type:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.med_type}</span></h6> */}
                 <h6>Form:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.form&&infoData1.form.toLowerCase().replace(/_/g, ' ')}</span></h6>
                 <h6>Frequency:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.frequency!==null?infoData1.frequency:(infoData1.date+" "+infoData1.time)}</span></h6>
                {!infoData1.is_emergency&& <h6>Duration:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.duration}&nbsp;{infoData1.duration_unit} </span></h6>}
                {!infoData1.is_emergency&& <h6>Intake period:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.intake}</span></h6>}
                 <h6>Quantity:<span>&nbsp;&nbsp;&nbsp;</span><span style={{textTransform:"none"}}>{infoData1.intake_count}{" "}{infoData1.intake_unit==="ml"?"mL":infoData1.intake_unit}</span></h6>
                 {!infoData1.is_emergency&&<h6>Intake time:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.intake_time&&infoData1.intake_time.toLowerCase()}</span></h6>}
                 <h6>Till date:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.till_date}</span></h6>
                 {!infoData1.is_emergency&&<h6>Route:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.route&&infoData1.route.toLowerCase().replace(/_/g, ' ').replace(/-/g, ' ')}</span></h6>}
                 <h6 style={{textAlign:"left"}}>Remarks:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.remarks}</span></h6>
               </div>
             </div>                  
          </div>
        </Modal.Body>
      </Modal>
    );
  };


  const EditPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        <span >Edit Remarks</span>

          
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={remarkData} list={remarkList} update={handleSend} />


        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

        </Modal.Footer>
      </Modal>
    );
  }
  const handleAdd = (index, id, trackId, name, order) => {
    
        setCell(index);
        setId(id);
        setTrackingId(trackId)
        setpatient(name)
        setOrder(order)
        setModalShow(true);
      };
    
  const renderRemarks1 = (cellInfo) => {
    const interactionValue = medData[cellInfo.index].interactions.drug_interactions;
    return (<>
    <OverlayTrigger overlay={<Tooltip1 style={{ textTransform: "capitalize" }} id="tooltip-disabled">Approve</Tooltip1>}>
      <button  
        style={{borderRadius: "5px",padding:"3%",paddingTop:"3.5%",paddingBottom:"3.5%",
      background: "#61C65F",color:"white",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",cursor:"pointer"}}
   
          onClick={() => {saveChangesMed(cellInfo.index)  }} >
             <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
          <path d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z" fill="white"/>
          </svg> 
          </button></OverlayTrigger>&nbsp;
          <OverlayTrigger overlay={<Tooltip1 style={{ textTransform: "capitalize" }} id="tooltip-disabled">Reject</Tooltip1>}>
      
          <button 
      style={{borderRadius: "5px",padding:"3%",paddingTop:"3.5%",paddingBottom:"3.5%",
      background: "#C6725F",color:"white",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",cursor:medData[cellInfo.index].status==="partially_approved"?"not-allowed":"pointer"}}
    disabled={medData[cellInfo.index].status==="partially_approved"}
            onClick={() => {saveChanges1Med(cellInfo.index)  }} > <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="white"/>
            </svg>  </button></OverlayTrigger>&nbsp;
            <OverlayTrigger overlay={<Tooltip1 style={{ textTransform: "capitalize" }} id="tooltip-disabled">Medicine info</Tooltip1>}>
      
            <button   onClick={() => {
        handleInfoPopUp1(medData[cellInfo.index].request_pid);
       }}
       style={{borderRadius: "5px",padding:"3%",paddingTop:"3.5%",paddingBottom:"3.5%",
      background: "#2A6379",color:"white",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
        <i
             
              className="fas fa-info-circle fa-xl"
             
            ></i></button></OverlayTrigger>&nbsp;
             <OverlayTrigger overlay={<Tooltip1 style={{ textTransform: "capitalize" }} id="tooltip-disabled">Remarks</Tooltip1>}>
      
      <button  onClick={() => handleAdd(cellInfo.index, medData[cellInfo.index].ref, medData[cellInfo.index].id, medData[cellInfo.index].patient, medData[cellInfo.index].remarks)}
       style={{borderRadius: "5px",padding:"3%",paddingTop:"3.5%",paddingBottom:"3.5%",
      background: "#2A6379",color:"white",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}>
         <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path d="M20 2H10a2 2 0 0 0-2 2v2h8a2 2 0 0 1 2 2v8h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="currentColor"/><path d="M4 22h10c1.103 0 2-.897 2-2V10c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2zm2-10h6v2H6v-2zm0 4h6v2H6v-2z" fill="white"/></svg>
     </button></OverlayTrigger>&nbsp;
     <OverlayTrigger overlay={<Tooltip1 style={{ textTransform: "capitalize" }} id="tooltip-disabled">Drug Interaction</Tooltip1>}>
      
     <button  onClick={() => handleView(interactionValue)} disabled={interactionValue === "null" || interactionValue === "" || interactionValue === null} style={{borderRadius: "5px",padding:"3%",paddingTop:"3.5%",paddingBottom:"3.5%",
      background:interactionValue !== "null" && interactionValue !== "" && interactionValue !== null? "#2A6379":"gray",color:interactionValue !== "null" && interactionValue !== "" && interactionValue !== null?"white":"#00000047",cursor:interactionValue !== "null" && interactionValue !== "" && interactionValue !== null?"pointer":"not-allowed",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}> <i
              style={{  }}
              
              className="fa fa-exclamation-triangle fa-lg"
              aria-hidden="true"
            ></i></button></OverlayTrigger>&nbsp;
     </>);


  }

 
  const UpdatePopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>Please save to complete changes </h5>




        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>






        </Modal.Footer>
      </Modal>
    );
  }

  




  const saveChanges1Med = (index) => {

    const rowToUpdate = medData.filter((elt, i) => {
      return i === index
    })

    callSave1Med(rowToUpdate[0],index);

  }

  

  const [selectedRows, setSelectedRows] = useState([]);
  const [anyRowSelected, setAnyRowSelected] = useState(false);

  useEffect(() => {
    setAnyRowSelected(Object.values(selectedRows).some((value) => value));
  }, [selectedRows]);
 
 
 

  const DispenseAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          
            <span ><b>Dispensing Count- <span style={{color:"red"}}>{orderedCount-dispatched}</span>/{orderedCount}</b>&nbsp;</span>
         
        </Modal.Header>
        <Modal.Body>
          <DispenceAdd
            id={dispenceID}
            ordered={orderedCount}
            intake={intakeValue}
            history={disHistory}
            dispatched={dispatched}
            onSuccess={(data)=>{let list=medData
              list[editingcol].remaining_count = data;
              setMedData(list); setDispenseShow(false);}}
            seteditDispence={dispenceCount}
            //
            handle={handleCloseDispence}
          />
        </Modal.Body>
       
      </Modal>
    );
  };

 

  const handleInfoPopUp1 = (item) => {

    // setInfoData(item);
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');



    axios.get(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${item}/?${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          let data=res.data
 
        setInfoData1(data);
        setinfoShow1(true);
        
        }

   
    else {
     

      setGetError(res.data.message);
    }



  })
  .catch(err => {
   

    setGetError("Error in fetching data");
  })
  
  };
 

  const renderServiceMedName = (cellInfo) => {

    return (

      <div  style={{color:medData[cellInfo.index].interactions.is_emergency===true&&"#3300FE"}} className="row"> <span style={{textTransform: "capitalize" ,textAlign:"left" }}>
        {medData[cellInfo.index][cellInfo.column.id].replace(/\//g, '\\ ')} 
      </span>
     
    
      </div>

    );
  }
  const handleDispense =(item,x,id,intake,index,data,dispatched) =>{
    setOrderedCount(item)
    setDispenceCount(x);
    setDispenceID(id);
    setIntakeValue(intake)
    setEditingCol(index)
    setDisHistory(data);
    setDispatched(dispatched)
    setDispenseShow(true);
    
    
    }

    const handleView =(data) => {
 
  
        setRemarkData1(data)
        setViewShow(true)
      }

  const renderDispenceCount =(cellInfo)=>{
    const { interactions } = medData[cellInfo.index];
    return(
      <div  style={{color:medData[cellInfo.index].interactions.is_emergency===true&&"#3300FE"}}> <span  style={{textTransform: "capitalize", backgroundColor:"white",borderRadius:"10px",  padding:" 5% 15% 5% 15%",textAlign:"center",fontSize:"16px",width:"90%",display:"flex",border:dispenceID===medData[cellInfo.index].id&&"2px solid var(--dark-teal)" }}>
      {medData[cellInfo.index][cellInfo.column.id]}
      <i
              style={{ color: "#75759c",float:"right",fontSize:"18px",marginTop:"7%"}}
              className="fas fa-info-circle"
              onClick={() => {
                handleDispense(medData[cellInfo.index].dispensing_count,medData[cellInfo.index].remaining_count,medData[cellInfo.index].id,interactions.intake_unit,cellInfo.index,medData[cellInfo.index].dispensing_history,medData[cellInfo.index].dispatched_count);
               }}
            ></i>
    </span>
   
    </div>
    )
  }

 

  const renderService1 = (cellInfo) => {

    return (
     
      <div  style={{color:medData[cellInfo.index].interactions.is_emergency===true&&"#3300FE"}}>  <>
     <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {medData[cellInfo.index][cellInfo.column.id]}
        
      </span>
    </>
      </div>

    );
  }



  const renderTableDate = (cellInfo) => {
    const datetimeString = medData[cellInfo.index][cellInfo.column.id];
    const dateParts = datetimeString.split(' ')[0].split('-'); // Split by space and then by hyphen
    const formattedDate = dateParts.join('-'); // Join the date parts with hyphens
  
    return (
      <div  style={{color:medData[cellInfo.index].interactions.is_emergency===true&&"#3300FE"}}> <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {formattedDate}
        </span>
      </div>
    );
  };
  
  
 
  const columns = [

    {
      Header:   <div>
      <span data-tooltip-id="name-tooltip" 
        >ID </span>
    </div>
   ,
      accessor: "request_pid",
      Cell: renderService1,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 50,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
     
        <input
          value={filterid}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue);
            setFilterId(filterValue) // Update the filter value
            // filterData(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
    },
    // {
    //   Header: <div>
    //   <span data-tooltip-id="name-tooltip1" 
    //     data-tooltip-content="Drug Interaction" >Interaction</span>
    
    //   <Tooltip id="name-tooltip1" place="bottom"style={{ zIndex:"1000000", color: "white" }}/>
    // </div>,
    //   filterable: true,
    //   Filter: ({ filter, onChange }) => (
    //     // Custom filter input component
    //     <i
    //       style={{ color: "black", marginTop: "9%", width: "10%" }}
    //       className="fa fa-exclamation-triangle fa-lg"
    //       aria-hidden="true"
    //     ></i>
    //   ),
    //   width: 80,
    //   accessor: "interactions", // Assuming that "interactions" is the correct key in your data
    //   Cell: (props) => {
    //     const interactionValue = props.value.drug_interactions; // Access "interactions" from the current cell's data
    
    //     if (interactionValue !== "null" && interactionValue !== "" && interactionValue !== null) {
    //       return (
    //         <i
    //           style={{ color: "black" }}
    //           onClick={() => handleView(interactionValue)}
    //           className="fa fa-exclamation-triangle fa-lg"
    //           aria-hidden="true"
    //         ></i>
    //       );
    //     } else {
    //       return null; // Or you can return an alternative JSX if needed
    //     }
    //   },
    // },
    
    {
      Header: "Medicines",
      accessor: "request_items",
      Cell: renderServiceMedName,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filtername}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
            setFiltername(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
  

    {
      Header: "Date",
      accessor: "date",
      Cell: renderTableDate,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
  
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filterdate}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
            setFilterdate(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      width:95
    },
    {
      Header: "Patient",
      accessor: "patient",
      Cell: renderService1,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filterpatname}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
            setFilterPatname(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Doctor",
      accessor: "doctor",
      Cell: renderService1,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filterdocname}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
            setFilterDocname(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Nurse",
      accessor: "nurse",
      Cell: renderService1,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filternursename}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
            setFilterNursename(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Ward",
      accessor: "admit_details",
      Cell: renderService1,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filterbdunit}
          onChange={(e) => {
          
            const filterValue = e.target.value;
          
            onChange(filterValue); 
            setFilterbdunit(filterValue); 
           
          }}
          style={{ color: 'black' }}
        />
      ),
width:95
    },


    {
      Header: <div>
      <span data-tooltip-id="name-tooltip1" 
        data-tooltip-content="Dispense Count" >Dispense Count</span>
    
      <Tooltip id="name-tooltip1" place="bottom" style={{ zIndex:"1000000", color: "white" }}/>
    </div>,
      accessor: "remaining_count",
      Cell: renderDispenceCount,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

    
      width: 100,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filterqty}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
            setFilterQty(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
   
    {
      Header:wardSearch&& <button onClick={() => {
        setShow(true)
  }}  style={{border: "1px solid #00657c",padding:"2%",
      borderRadius:"20px",
      textDecoration: "none",
      curser: "pointer",
      color: "rgb(42, 99, 121)"}}>&nbsp;Export&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M216 112v96a16 16 0 0 1-16 16H56a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h24a8 8 0 0 1 0 16H56v96h144v-96h-24a8 8 0 0 1 0-16h24a16 16 0 0 1 16 16M93.66 69.66L120 43.31V136a8 8 0 0 0 16 0V43.31l26.34 26.35a8 8 0 0 0 11.32-11.32l-40-40a8 8 0 0 0-11.32 0l-40 40a8 8 0 0 0 11.32 11.32"/></svg></button>,
     
      Cell: renderRemarks1,
      
     
      sortable: false,
      filterable: false,
     width:170,
      
    },
  ]

  const handlePageChange = (pageIndex) => {
    const nextPage = pageIndex + 1; // Convert 0-based index to 1-based page number
    if (pageIndex >= 0 && nextPage <= Math.ceil(totalRows / perPage)) {
      setPage(nextPage);
      getServices1(nextPage, perPage); // Fetch data for the new page
    }
  };
  const handlePostSuccess = () => {
    // Clear filters directly on the table instance
    if (tableRef.current) {
        tableRef.current.setState({ filtered: [] });
    }
    // Optionally, refresh the data
   
};
  const displayTable =  
  <ReactTable
  ref={tableRef}
  data={medData}
  columns={columns}
  resizable
  style={{ overflow: "wrap"}}
  className="service-req-table"
  defaultPageSize={perPage}
  showPagination={medData.length>0}
  pages={Math.ceil(totalRows / perPage)}
  page={page-1}
  showPageJump={false}
  onPageChange={(pageIndex) => { handlePageChange(pageIndex); }}
  onPageSizeChange={(newPerPage) => { setPerPage(newPerPage); setPage(0); }}
  pageSizeOptions={[5, 10, 20, 50, totalRows]}
/>

const InfoPopup = (props) => {
  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
      >
          <Modal.Header closeButton>
            <div  style={{width:"100%", display:"flex"}}>
            
              <img  style={{width:"60px"}}  src="https://svpapidev.cianlogic.com/media/utils/logo/svpr_logo_1_ll8Z7pY.png" alt="Logo"/>
                      
              
                <h4 style={{marginLeft:"35%"}}>Preview</h4>
            
             
              <h4 style={{marginLeft:"30%",fontSize:"16px"}}> {(new Date()+"").slice(4,16)}</h4>
             
            </div>
         
          </Modal.Header>
          <Modal.Body>
        <FormService medData={medData} item="med"/>
            
          </Modal.Body>
      </Modal>
  );
};
  
  
  return(
        <div  style={{marginBottom:"2%"}}>

{displayTable}

{
        modalShow ?
          <AddPopup
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          /> : null
      }

{
      viewShow ? 
      <ViewPopup
        show={viewShow}
        onHide= {() => { setViewShow(false)     
        }}
      /> : null
    }


{infoShow1 ? (
        <InfoPopup1
          show={infoShow1}
          onHide={() => {
            setinfoShow1(false);
          }}
        />
      ) : (
        ""
      )}
        {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}
      {
        editShow ?
          <EditPopup
            show={editShow}
            onHide={() => {
              setEditShow(false)
            }}
            onSave={() => {
              if (sendData !== tableData[cell].remarks) {
                const data = tableData
                data[cell].remarks = sendData
                setTableData(data)
                setEditShow(false);
                setRender(!render);
                setUpdateSuccess(true)

              }
            }} /> : null
      }

{dispenseShow ? (
          <DispenseAddPopup
            show={dispenseShow}
            seteditDispence={dispenceCount}
            onHide={() => {
              setDispenseShow(false);
            }}
          />
        ) : null}

      {
        updateSuccess ?
          <UpdatePopup
            show={updateSuccess}
            onHide={() => {
              setUpdateSuccess(false);
            }}
          /> : null
      }
   
        </div>
    )
}
export default Medicines
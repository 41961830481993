import React,{useState,useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
import { useAlert } from "react-alert";
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
function EditScan(props) {
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const alert = useAlert();
  const [selectedImage, setSelectedImage] = useState(null);
    const[data, setData]= useState({
      id: props.data.id,
      scanname : props.data.scan_name,
        scantype : props.data.scan_type,
        scanmethod : props.data.scan_method,
        scansarea :  props.data.scan_area, 
        image:props.data.image,
        country : { value:'Malta', label:'Malta' }
    })
    const value = props.data.group ? props.data.group.toLowerCase() : '';
    const label = props.data.group ? props.data.group.toUpperCase() : '';
   const [Agegroup,setAgegroup]=useState({value:data.agegroup,label:data.agegroup})
    const [group2, setGroup2]= useState({value:value, label: label})
    const [country2, setCountry2]= useState({value:props.data.country3, label: props.data.country3})
    const [errorShow, setErrorShow] = useState(false)

   // const [year, setYear]= useState(data.year_of_manufacture=== null ? '' : new Date(data.year_of_manufacture))
    //const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

  //  const [yearData, setYearData]= useState(data.year_of_manufacture)
    //const [expiryData, setExpiryData]= useState(data.expiry_date)

    const [error,setError]=useState({
      scanname:'',
      scantype:'',
     
     group:'',
   agegroup:'',
    country : ''
        
       });
    const group3= [
        { value: "male", label: "MALE" },
        { value: "female", label: "FEMALE" },
  

        { value: "others", label: "OTHERS" },
    
      ];
    
      const agegroupvalue = [{ value:'1', label:'Infants' },
      { value:'2', label:'kids' },{value:'3', label:'teen'},{value:'4', label:'young'},{value:'5', label:'adult'},{value:'6', label:'elderly'}
     
   ]

const handleServiceChange= (data) => {
  console.log('THE DATA', data);
  setGroup2(data)
}

const handleAgeGroup= (data) => {

  setAgegroup(data)
}
const handleImageUpload = (event) => {
  const image = event.target.files[0];
  setData(prevData => ({...prevData, image: URL.createObjectURL(image)}));
  setSelectedImage(image);
};

      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


      const handleConfirm = () => {
        closeConfirmationPopup()
        saveChanges();
       
      };
      
      const handleCancel = () => {
        closeConfirmationPopup()
      };
      const handleContinue = () => {
        if(validate()){ 
          setShowConfirmation(true)
        };
      }
      
const validate=()=>{
    
  let input = data;
  let errors = {};
  let isValid = true;
setError({});

      
  if (!input["scanname"].trim() || input["scanname"].trim() ==="" ) {
    isValid = false;
    errors["scanname"] = "Enter Scan Name";
  }

  if (!input["scansarea"].trim() || input["scansarea"].trim() ==="" ) {
    isValid = false;
    errors["scansarea"] = "Enter Scan Area";
  }

  if (!input["scantype"] || input["scantype"]==="" ) {
    isValid = false;
    errors["scantype"] = "Enter Scan Type";
  }

  if (!group2 || (input.group2 && !group2.value) ) {
    isValid = false;
    errors["group"] = "Select group";
  }
  if (!Agegroup || (input.Agegroup && !Agegroup.value) ) {
    isValid = false;
    errors["agegroup"] = "Select  age group";
  }
  if (!input["country"] || input["country"]==="" ) {
    isValid = false;
    errors["country"] = "Please Select country";
  }

  

   setError(currentstate=>({
         ...currentstate,
        ...errors
      }));
  

  return isValid;
}


const saveChanges = ()=> {
  

        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     const updatedFields = {};
    
     if (data.scanname!==props.data.scan_name) {
       updatedFields.scan_name = data.scanname;
     }
     if (data.scantype!==props.data.scan_type) {
       updatedFields.scan_type = data.scantype;
     }
     if (data.scanmethod!==props.data.scan_method) {
       updatedFields.scan_method = data.scanmethod;
     }
     if (data.scansarea!==props.data.scan_area) {
       updatedFields.scan_area = data.scansarea;
     }
    //  if (country2 && country2) {
    //    updatedFields.country = country2.value;
    //  }
     if (group2 && group2.value!==props.data.group) {
       updatedFields.group = group2.value;
     }
     if (Agegroup && Agegroup.value) {
       updatedFields.age_group = Agegroup.value;
     }
     if (selectedImage) {
       updatedFields.image = selectedImage;
     }
     
     const dataToSend = new FormData();
     
     Object.entries(updatedFields).forEach(([key, value]) => {
       dataToSend.append(key, value);
     });
     axios
       .put(`${APIURL}/api/v1/staff/master-scan-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
     
         if (res.data.status==="success") {
          alert.success('Updated successfully')
          props.modalClose();
          setData({
            scanname:'',
            scantype:'',
            scanmethod:'',
            scansarea:'',
           group2:{ value:'', label:'' },
           cptcode:'', 
           Agegroup:{value:'',label:''},  
         // description:'',
          country2 : { value:'', label:'' }
          })
      
         }
          else {
            alert.error(res.data.message)
          // setErrorShow(true)
         }
       })
       .catch((err) => {
         //setErrorShow(true)
       });
     
    }




    return (
        <>
      

    <h2 style={{ marginTop: "0" }} className="title-of-page">
        Edit Scan
      </h2>
      <div style={{display:"flex" , marginLeft: "4rem", marginRight: "4rem", overlap:"hidden", padding: "0 10px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {data.image && (
         <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: "5px"  }}>
          <img src={data.image} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
      <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>* Scan Name: </span>
                    <input type='text' className='form-control title-flex' name='scanname' value={data.scanname} onChange={handleTitle} />
                    {error.scanname ? <div className="error-validation-msg error-feature-inventory">{error.scanname}</div> : null}
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>* Scan Type : </span>
                    <input type='text' className='form-control title-flex' name='scantype' value={data.scantype} onChange={handleTitle} />
                    {error.scantype ? <div className="error-validation-msg error-feature-inventory">{error.scantype}</div> : null}
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Scan Method : </span>
                    <input type='text' className='form-control title-flex' name='scanmethod' value={data.scanmethod} onChange={handleTitle} />
               
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>* Scan Area : </span>
                    <input type='text' className='form-control title-flex' name='scansarea' value={data.scansarea} onChange={handleTitle} />
                    {error.scansarea ? <div className="error-validation-msg error-feature-inventory">{error.scansarea}</div> : null}
                </div>
                
                

                <div className='flex-col justify-feature'>
                <span className='align-rental'>* Group: </span>
                <Select
                  defaultValue={group2}
                  onChange={(data,e)=>{handleServiceChange(data,e)
                  }}
                  options={group3}
                  name='group2'
                  className="select-currency select-flex title-flex"
                />
                {error.group ? <div className="error-validation-msg error-feature-inventory">{error.group}</div> : null}  
                        
            </div>
            {/* <div className='flex-col justify-feature'>
                <span className='align-rental'>*Age Group: </span>
                <Select
                  defaultValue={Agegroup}
                  onChange={(data,e)=>{handleAgeGroup(data,e)
                  }}
                  options={agegroupvalue}
                  name='group2'
                  className="select-currency select-flex title-flex"
                />
                {error.agegroup ? <div className="error-validation-msg error-feature-inventory">{error.agegroup}</div> : null}  
                        
            </div> */}
            {/* <div className='flex-row name-content'>
                <span className='align-rental'>Country: </span>
                <Select
                            value={country2}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={country3}
                            name='country2'
                            className="select-currency select-flex"
                        />
                       
            </div> */}
              
                
            </div>

        <div className="text-center">
          <button onClick={handleContinue} className="btn btn-primary btn-col mb-2">
            Save
          </button>
        </div>

             {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to continue?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </div>
        </>
    )
}

export default EditScan

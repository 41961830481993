import React, { useContext, useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  Navigate,
  NavLink,
} from "react-router-dom";
import admission from "../../assets/images/icons/admission.png";  
import { LoginContext } from "../contexts/LoginContext";
import "font-awesome/css/font-awesome.min.css";
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import "../Hospital/hospital.css";
//import Navbar from "../common/Navbar"
//mport Footer from "../common/Footer";
import prof from "../../assets/images/icons/doctor1.png";
import request from "../../assets/images/icons/request1.png";
import bdo from "../../assets/images/icons/bdo.png";
import carer from "../../assets/images/icons/carer.webp";
import sda from "../../assets/images/icons/sda1.png";
import pharmacy from "../../assets/images/icons/pharmacy.png";
import patient from "../../assets/images/icons/patient.png"; 
import lab from "../../assets/images/icons/lab.png";
import nurse from "../../assets/images/icons/nurse1.png";
import allied from "../../assets/images/icons/allied.png";
import usermngmnt from "../../assets/images/usermanagement.jpg";
import monitor from "../../assets/images/icons/monitor.png";
import fac from "../../assets/images/icons/facility.png";
import visitor from "../../assets/images/icons/visitor.png";
import manager from "../../assets/images/icons/manager.png";
import reports from "../../assets/images/icons/reports.png";
import PRO from "../../assets/images/icons/PRO.png";
function ManagerDashboard() {
  const [features,setFeatures]=useState(JSON.parse(sessionStorage.getItem("features")))
  const [web_users,setweb_users]=useState(JSON.parse(sessionStorage.getItem("web_users")))
  const [app_users,setapp_users]=useState(JSON.parse(sessionStorage.getItem("app_users")))
  const [loginDatas] = useContext(LoginContext);
  const projType=sessionStorage.getItem("project_type")
  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <div>
      
      <div className="home">
        <h2 className="title-of-page"> {login_datas.name} </h2>
       
        <div className="home-container" style={{marginTop:"1%"}}>
       

        {features.includes("ip_form_admission")&& <div className="home-tiles" 
           onClick={() => navigate("/admissionadmin")}
          >
            <img src={admission} className="home-icon" width="65px" />
            <h5 className="each-title">Admissions</h5>

          </div>}
          {features.includes("monitor")&&
          <div className="home-tiles" 
           onClick={() => navigate("/monitor")}
          >
            <img src={monitor} className="home-icon" width="65px" />
            <h5 className="each-title">Monitor</h5>

          </div>}
          
          <div className="home-tiles" 
          onClick={() => navigate("/patientlist")}
           >
             <img src={patient} className="home-icon" width="65px" />
            <h5 className="each-title"> Patient Records
            </h5>

          </div>
          {/* {features.includes("visitor_registration")&&<div className="home-tiles" 
          onClick={() => navigate("/visitor_requests")}
           >
             <img src={visitor} className="home-icon" width="65px" />
            <h5 className="each-title"> Visitors
            </h5>

          </div>} */}
          {(features.includes("ip_form_admission")||features.includes("ip_admission"))&&<div className="home-tiles" onClick={() => navigate("/hospitalmanagement")}>
            <img src={fac} className="home-icon" width="65px" />
            <h5 className="each-title">Facility Management</h5>
          </div>}
          {/* {features.includes("death_management")&& <div className="home-tiles" 
           onClick={() => navigate("/deathreport")}
          >
            <img src={reports} className="home-icon" width="65px" />
            <h5 className="each-title">Death Reports</h5>

          </div>} */}
          <div className="home-tiles" onClick={() => navigate("/doctormanagement")}>
            <img src={usermngmnt} className="home-icon" width="60px" />
            <h5 className="each-title"> User Management</h5>
          </div>

        {/* {(web_users["hospital_doctor"]==="True"||app_users["hospital_doctor"]==="True" )&&<> <div className="home-tiles" onClick={() => navigate("/adddoctor")}>
            <img src={prof} className="home-icon" width="60px" />
            <h5 className="each-title"> Add Doctor</h5>
          </div>

          <div className="home-tiles" onClick={() => navigate("/doctorslist")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Doctors List</h5>
          </div></>}


 
          {(web_users["hospital_carer"]==="True"||app_users["hospital_carer"]==="True" )&&<><div className="home-tiles" onClick={() => navigate("/add-carer")}>
            <img src={carer} className="home-icon" width="65px" />
            <h5 className="each-title">Add Carer</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/carer-list")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Carer List</h5>
          </div></>}
          {(web_users["hospital_pro"]==="True"||app_users["hospital_pro"]==="True" )&&<><div className="home-tiles" onClick={() => navigate("/add-pro")}>
            <img src={PRO} className="home-icon" width="65px" />
            <h5 className="each-title">Add PRO</h5>
          </div>

          <div className="home-tiles" onClick={() => navigate("/pro-list")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">PRO List</h5>
          </div></>}
          {(web_users["hospital_bdo"]==="True"||app_users["hospital_bdo"]==="True" )&&<><div className="home-tiles" onClick={() => navigate("/add-bdo")}>
            <img src={bdo} className="home-icon" width="65px" />
            <h5 className="each-title">Add BDO</h5>
          </div>

          <div className="home-tiles" onClick={() => navigate("/bdo-list")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">BDO List</h5>
          </div></>}
          {(web_users["hospital_sda"]==="True"||app_users["hospital_sda"]==="True" )&&<>
            <div className="home-tiles" onClick={() => navigate("/add-sda")}>
            <img src={sda} className="home-icon" width="65px" />
            <h5 className="each-title">Add SDA</h5>
          </div>

          <div className="home-tiles" onClick={() => navigate("/sda-list")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">SDA List</h5>
          </div></>}
          {(web_users["hospital_pharmacist"]==="True"||app_users["hospital_pharmacist"]==="True" )&&<>
          <div
            className="home-tiles"
            onClick={() => navigate("/add-Pharm-user")}
          >
            <img src={pharmacy} className="home-icon" width="65px" />
            <h5 className="each-title">Add Pharmacy User</h5>
          </div>
          <div
            className="home-tiles"
            onClick={() => navigate("/pharmacyusers-list")}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Pharmacy Users List</h5>
          </div></>}
          {(web_users["hospital_labuser"]==="True"||app_users["hospital_labuser"]==="True" )&&<>
   <div className="home-tiles" onClick={() => navigate("/add-labuser")}>
            <img src={lab} className="home-icon" width="65px" />
            <h5 className="each-title">Add Lab User</h5>
          </div>
          <div
            className="home-tiles"
            onClick={() => navigate("/labusers-list")}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Lab Users List</h5>
          </div></>}
          {(web_users["hospital_allied_prof"]==="True"||app_users["hospital_allied_prof"]==="True" )&&<><div
            className="home-tiles"
            onClick={() => navigate("/add-professional")}
          >
            <img src={allied} className="home-icon" width="65px" />
            <h5 className="each-title">Add Allied Professional</h5>
          </div>
          <div
            className="home-tiles"
            onClick={() => navigate("/allied-professional-list")}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Allied Professionals List</h5>
          </div></>}
          {(web_users["hospital_nurse"]==="True"||app_users["hospital_nurse"]==="True" )&&<>
          <div className="home-tiles" onClick={() => navigate("/add-nurse")}>
            <img src={nurse} className="home-icon" width="65px" />
            <h5 className="each-title">Add Nurse</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/nurse-list")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Nurses List</h5>
          </div></>} */}
         {/* </> :null} */}
        </div>

    
      </div>
     
    </div>
  );
}

export default ManagerDashboard;

import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import './userprofile.css';


import { Card, Button, Modal } from 'react-bootstrap'
import { Link, useParams, useNavigate } from 'react-router-dom';
import BackButton from '../common/BackButton';

function HospitalDetails() {
  const { id } = useParams(); 
  const [spstatus,setSpStatus]=useState('')
  const [img, setImg] = useState()
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [mail, setMail] = useState('');
  const [location, setLocation] = useState('');
  const [state, setState] = useState('')
  const [number, setNumber] = useState('')
  const [facilities, setFacilities] = useState([])
  const [country, setCountry] = useState('');
  const [website, setWebsite] = useState('');
  const [acc, setAcc] = useState('');
  const [year, setYear] = useState('');
  const [phone, setPhone] = useState('');
  const [emer, setEmer] = useState('');
  const [prMail, setPrMail] = useState('');
  const [regNum, setRegNum] = useState('');
  const [type, setType] = useState('');
  const [street, setStreet] = useState('');
  const [deliveryRadious, setDeliveryRadious] = useState('');
  const [status, setStatus] = useState("");
  const [deleteShow ,setDeleteShow] =useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [approveFail, setApproveFail] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [rejectFail, setRejectFail] = useState(false);
  const [approveMsg, setApproveMsg] = useState('');
  const [approveFailMsg, setApproveFailMsg] = useState('');
  const [rejectMsg, setRejectMsg] = useState('');
  const [rejectFailMsg, setRejectFailMsg] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [modalShow, setModalShow] = useState(false)
  const [modalShowdel, setModalShowdel] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [dlt, setdlt] = useState(false)
 const [isDergstr,setIsDergstr] = useState(false)

  let navigate = useNavigate();


  useEffect(() => {
   getProfile()

  }, [])
const getProfile=()=>{

  const tokenString = sessionStorage.getItem("usertoken");

  let v2 = tokenString.replace(
    /["]+/g,
    ""
  );
  axios.get(`${APIURL}/api/v1/staff/hospital-profile/?hospital_id=${id}&${TZ}`, {
    headers: { Authorization: "Token " + v2 },
  })
    .then((res) => {
      if (res.data.status === 'success') {
        setSpStatus(res.data.account_status)
        const hospData = res.data.hospital_profile
        setImg(hospData.photo);
        setName(hospData.name);
        setDesc(hospData.description)
        setMail(hospData.email)
        setLocation(hospData.location)
        setState(hospData.state)
        setNumber(hospData.mobile_number)
        setCountry(hospData.country)
        setWebsite(hospData.website)
        setAcc(hospData.accreditation)
        setYear(hospData.established_year)
        setPhone(hospData.contact_phone)
        setStatus(hospData.status)
        setPrMail(hospData.primary_email)
        setRegNum(hospData.reg_number)
        setType(hospData.health_care_type)
        setDeliveryRadious(hospData.delivery_radius)
        setStreet(hospData.street)
        setIsDergstr(hospData.is_deregister)
        if(res.data.message!==null){
          setdlt(true)
        }
        if (hospData.emergency_number === null) {
          setEmer('Not Available')
        }
        else {
          setEmer(hospData.emergency_number);
        }

        const facility = hospData.facilities.join(', ');
        setFacilities(facility)
        // const specializations =hospData.specializations.join(', ');
        //setSpecs(specializations);
      }
      else {
        setErrorShow(true)
      }
    })
    .catch((err) => {
      setErrorShow(true)
    })
}
  const handleDeleteModal= (status1)=> {
    setSpStatus(status1)
    setModalShowdel(true)
  }


  const handleApprove = () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      hospital_id: id
    }
    axios.post(`${APIURL}/api/v1/staff/hospital-approve/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
         
          setApproveMsg('success')
          setApproveShow(true)
        }
        else {
          setApproveFailMsg('Failed to submit data')
          setApproveFail(true)
        }
      })
      .catch((err) => {
        setApproveFailMsg('Failed to submit data')
        setApproveFail(true)
      })

  }

  const handleDelete =() =>{

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
        /["]+/g,
        ""
      );
      let data ={}
     
      data ={            
        account_status :spstatus,
        hospital_email :mail
      }
  
      axios.post(`${APIURL}/api/v1/staff/disable_delete_account/`, data, {
        headers: { Authorization: "Token " + v2 },
   })
   .then((res) =>{
       if(res.data.status==='success'){
         
         setDeleteShow(true)      

       }
       else{

       }
   })
   .catch((err) => {
     
   })
  }


  const handleReject = () => {
   

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      hospital_id: id,
      status: 'rejected'
    }
    axios.post(`${APIURL}/api/v1/staff/hospital-approve/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          setRejectMsg('succesfully rejected')
          setRejectShow(true)
        }
        else {
          setRejectFailMsg('failed to reject')
          setRejectFail(true)
        }
      })
      .catch((err) => {
        setRejectFailMsg('failed to reject')
        setRejectFail(true)
      })

  }

  // const getTime =()=> {
  //   let data = time;
  //   const date = new Date(time)
  //   let dateUpdated = date.toString()
  // }

  const modalHandle = () => {
    setModalShow(false)
  }

 

  const ApprovalPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Service Provider approved Successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={() => navigate("/hospitallist", {
          state: {
            approve: false,
          }})}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }
  const DeletePopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {spstatus==="Delete"?
              <h4 className='text-success'>Service Provider Deleted Successfully</h4>
            :
            spstatus==="Active"?
              <h4 className='text-success'>Service Provider Activated Successfully</h4>
            :
            
              <h4 className='text-success'>Service Provider Deactivated Successfully</h4>
            }
         
        
           
          </Modal.Body>
          <Modal.Footer>

             <Button variant="secondary" onClick={props.onHide}>Ok</Button>

          </Modal.Footer>
        </Modal>
      )
  }

  const ApprovalFail = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ color: "red" }}> Error in Data Submission</h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const RejectPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Service Provider rejected Successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary"  onClick={() => navigate("/hospitallist", {
          state: {
            approve: false,
          }})}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const RejectFail = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ color: "red" }}> Error in Data Submission. Please try again! </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }
  const DelConfirmPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
           {spstatus==="Delete"?
              <h4 className='text-danger'>Are you sure to Delete this Service Provider ?</h4>
            :
            spstatus==="Active"?
              <h4 className='text-success'>Are you sure to Activate this Service Provider ?</h4>
            :
            
              <h4 className='text-danger'>Are you sure to Deactivate this Service Provider ?</h4>
            }
         
           
          </Modal.Body>
          <Modal.Footer>

             <Button variant="secondary" onClick={props.onHide}>No</Button>
             <Button variant="danger" onClick={props.onCall}>Yes</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Are you sure?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onReject}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {!dlt?<h5 style={{ color: "red" }}> Error in Data Retrieval. Please try again! </h5>:" "}

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }
  const handleBack = (e) => {
    navigate(-1);
  };
 return (<>
 

    <div className="padding " >
    
   {dlt?
  <div style={{display:"flex"}}>
        <div className="mb-4" bg='info' border='success' style={{ padding:"2%",width: '60rem', minHeight: '25rem',boxShadow: "0 1px 5px #2f9ec1"}}>
          <img variant="top" src={img} alt='Profile Picture' className="img-radius" />
          <div className='hospdetails'><br />
            <div ><h4 className="title-of-tasks" style={{color:"#31a4bf"}} >{name} </h4></div>
            <div>
              <p className="lead text-justify">{desc ? desc : <p className='lead ' style={{padding:"10px"}}>Description of Service Provider is not available at the moment</p>}</p>
             
            </div>

           
        
        </div>
      </div>
      
        <div className="card-block mb-8">
        <h3 className="title-of-tasks"  style={{color:"#33B5E5"}} >Service Provider Details</h3><br/><br/>

          <div className="row" style={{marginLeft:"15%",padding:""}}>
            <div className="col-sm-5">
              <p className=" text-left">ID :  <span className='text-info span-size'>{id}</span></p>
              <p></p>
            </div>
            <div className="col-sm-5">
              <p className="text-left">Registration Number : <span className='text-info span-size'>{regNum ? regNum : 'Not Available'}</span> </p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className=" text-left">Health Care Type : <span className='text-info span-size'>{type === '' ? 'Not Available' : type}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Established Year : <span className='text-info span-size'>{year === null ? 'Not Available' : year}</span></p>
              <p></p>
            </div>


            <div className="col-sm-5">
              <p className="text-left">Email : <span className='text-info span-size'>{mail === null ? 'Not Available' : mail}</span></p>
              <p></p>
            </div>

            <div className="col-sm-4">
              <p className="text-left">Location : <span className='text-info span-size'>{street}, {location}, {state}, {country}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Phone Number : <span className='text-info span-size'>{number === '' ? 'Not Available' : number}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Emergency Number : <span className='text-info span-size'>{emer === null ? 'Not Available' : emer}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Delivery Radius<small>(km)</small> : <span className='text-info span-size'>{deliveryRadious === null ? 'Not Available' : deliveryRadious}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Website : <span className='text-info span-size'>{website === '' ? 'Not Available' : website}</span></p>
              <p></p>
            </div>

          
        </div>
   

       { status==="pending" ?
        <div className='form-heading title-of-tasks' style={{marginLeft:"-10%"}}>
          <button style={{  }} onClick={() => setConfirm(true)} className="btn btn-danger">Reject</button><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <button   onClick={handleApprove} className="btn btn-primary btn-col">Approve</button>
        </div>:
        
        <div className='form-heading title-of-tasks' style={{marginLeft:"-10%"}}>
          {spstatus==="ACTIVE"?<button   onClick={(e)=> handleDeleteModal('Disable')} className="btn btn-warning">Deactivate</button>:
          <button   onClick={(e)=> handleDeleteModal('Active')} className="btn btn-info">Activate</button>}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <button   onClick={(e)=> handleDeleteModal('Delete')} className="btn btn-danger">Delete</button>
        </div> }

</div>
      </div>: <div class="row">
    <div class="col-md-8 offset-md-2">
      <h3 style={{color:"red"}}>This service provider is deleted</h3>
    </div>
  </div>}

      {
        approveMsg !== '' ?
          <ApprovalPopUp
            show={approveShow}
            onHide={() => { setApproveShow(false); setApproveMsg(''); }} /> : null
      }

      {
        approveFailMsg !== '' ?
          <ApprovalFail
            show={approveFail}
            onHide={() => { setApproveFail(false); setApproveFailMsg(''); }} /> : null
      }

      {
        rejectMsg !== '' ?
          <RejectPopUp
            show={rejectShow}
            onHide={() => { setRejectShow(false); setRejectMsg(''); }} /> : null
      }

      {
        rejectFailMsg !== '' ?
          <RejectFail
            show={rejectFail}
            onHide={() => { setRejectFail(false); setRejectFailMsg(''); }} /> : null
      }

      {
        confirm ?
          <ConfirmPopUp
            show={confirm}
            onHide={() => { setConfirm(false) }}
            onReject={() => { handleReject(); setConfirm(false) }} /> : null
      }

      {/* {
        modalShow ? 
        <ConfirmPopUp 
            show={modalShow}
            onCall={() => { handleDelete();  setModalShow(false) }}
            onHide={() => setModalShow(false) }/> : null
    } */}
      {
        modalShowdel ? 
        <DelConfirmPopUp 
            show={modalShowdel}
            onCall={() => { handleDelete();  setModalShowdel(false) }}
            onHide={() => setModalShowdel(false) }/> : null
    }

      {
        errorShow ?
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
            }} />
          : null
      }
    {deleteShow? 
  <DeletePopUp 
        show={deleteShow}
        onHide={() => { spstatus==="Delete"? navigate("/hospitallist", {
          state: {
            approve: true,
          }}):setDeleteShow(false);getProfile()}}        
      />

: null }


    </div>
  </>

  )}



export default HospitalDetails

import React ,{useState, useContext} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import './hospprofile.css'
import {Button ,Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';

function DeliveryUpdate(props) {
  const alert = useAlert()
  const[delivery ,setDelivery] =useState(props.delivery !== '' ? props.delivery : '')
  const [error,setError]=useState({
        delivery:"",
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='delivery'){
            setDelivery(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (delivery==="" ||delivery < 1  ) {
            isValid = false;
            errors["delivery"] = "Please check delivery radius and must be greater than 1.0";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitDelivery()
        }
      }

        const submitDelivery = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
            let str= tokenString.replace(/["]+/g, '') 
         
            const data = {
                delivery_radius : delivery
            }
         
            axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/` ,data, { headers: {
         
             "Authorization" : 'Token '+str  }})
           .then(res=>{
           
                 
             if(res.data.status==="success"){
               
               alert.success("Updated successfully")     
               props.handle()   
           
             }else{
               alert.error("Error occured. Try again!")            
              
             }
           
           })
           .catch(err=>{
            alert.error("Error occured. Try again!")            
           
           })
         
          }

    return (
        <div>
            <div className="item">
              
            <div className="row">
              

              


                      <input
                        type="number"
                   
                        name="delivery"
                        value={delivery}
                      
                        onChange={handleChange}
                      />
                     
              

              </div>
              
              
              {/* <input className="form-control" type="text" maxLength="20" name="practise" placeholder="Practise Type" onChange={handleChange}/> */}

                {error.delivery ? <div className="error-validation-msg"> {error.delivery}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update delivery radius</button>
            </div>           
        </div>
    )
}

export default DeliveryUpdate

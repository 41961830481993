import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
function AddObs(props) {
  const alert = useAlert();
  const [obsData, setObsData] = useState("");
  const [observations, setObservations] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [display, setDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const inputRef = useRef(null)
  const saveButtonRef = useRef(null)

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {  
      e.preventDefault()
      saveButtonRef.current.click();      
    }else if (e.altKey && e.key === 's') {
      saveButtonRef.current.click(); 
    }
  };
  

  const handleClick = (item) => {
    initialRender.current = true;
    setSearchTerm(item.observation_name);
    setObsData(item.observation_name);
    // setPatientDetails(item)

    setDisplay(false);
    // setAllData([])
    // setRefresh(!refresh)
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setObservations([]);
      setObsData("");
      setDisplay(false);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(`${APIURL}/api/v1/doctor/observations/?search=${searchTerm}&${TZ}`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
        
          if (res && res.data) {
            setObservations(res.data);

            setDisplay(true);
       
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [searchTerm]);
  const handleDisplay = () => {
    setDisplay(false);

  }
  useEffect(() => {
    document.addEventListener('click', handleDisplay)

    return () => {
      document.removeEventListener('click', handleDisplay)
    }
  }, [])
  const handleSave = () => {
    if (searchTerm !=="" && searchTerm !== null) {
    const id = props.id;
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      observations: searchTerm,
      patient_id: id,
    };

    axios
      .post(`${APIURL}/api/v1/patient/observations-list/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
         alert.success("Observation added successfully")
         props.handleAdd();
        } else {
         alert.error(res.data.message);
          
        }
      })
      .catch((err) => {
        alert.error(Error);
      });
    } else {
      alert.error(Error);
    }
  };

 
  

  return (
    <div className="flex-col">
      <div className="comorbidities-body-content" style={{display:"flex"}}>
        <input
          ref={inputRef} 
          onKeyDown={(e) => handleKeyDown(e)}
          type="text"
          maxLength="100"
          placeholder="Add Observation"
          value={searchTerm}
          onChange={handleSearchChange}
          // value={comorbValue}

          // onChange={handleChange}

          className="form-control"
        />
<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <button
          ref={saveButtonRef}
          className="btn  btn-square btn-primary btn-col "
          // disabled={searchTerm===""}
          onClick={() => {searchTerm===""?alert.error("please add observation"):handleSave()}}
         
          
        >
          {" "}
          Save{" "}
        </button>
      </div>
      {/* <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {obsData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setObsData(e.target.value)
          } }
            autoComplete="off"
                />
            <button disabled={obsData===''} onClick={handleSave} className='btn btn-primary btn-col'>Save</button> */}

      {display ? (
        <div className="suggest-display adjust-dimensions comor-auto-suggest ">
          {observations.length > 0 ? (
            observations.map((item) => {
              return (
                <p
                  onClick={() => handleClick(item)}
                  className="suggest-item text-secondary"
                >
                  {item.observation_name}
                </p>
              );
            })
          ) : (
            <h6 className="text-muted">No observations found !!</h6>
          )}
        </div>
      ) : null}

     
     
    </div>
  );
}

export default AddObs;

import React,{useState, useEffect} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import './profile.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {Button ,Modal } from 'react-bootstrap';
import "./popup.css";
import OtpUpdate from './OtpUpdate';

function PhoneUpdate(props) {
  const [phone, setPhone] = useState({
    dialcode: "356", // Setting the dial code to "356"
    rawphone: props.phone !== "" ? props.phone.substr(-10) : "", // Setting the raw phone number from props
    mobile_number: ""
});
    const [isValidPhone,setIsValidPhone]=useState(true);
    const [popup, setPopup] = useState(false);
    const [otpMsg, setOtpMsg]= useState('');
    const [otpFail, setOtpFail]= useState(false)
  useEffect(()=>{
    console.log(phone)
  },[phone])

    const [error,setError]=useState({
        phone:""
       });

       useEffect(() => {
        setPhone(prevState => ({
            ...prevState,
            mobile_number: phone.dialcode + phone.rawphone // Concatenating dial code and raw phone number
        }));
    }, [phone.dialcode, phone.rawphone]);
        const handlePhoneInput= (value, data)=>{
  
            const rawPhone = value.slice(data.dialCode.length) ;
            
            const dialcode = value.slice(0,data.dialCode.length) ;
             setPhone(currentstate=>({
                  ...currentstate,
                      dialcode: dialcode,
                        rawphone: rawPhone,
                        mobile_number: value
          
                                 }));
           }

          


           const handleCheckExistingPhone= (e)=>{

            const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

            
       if(e.target.value !==""){

         const rawPhone =  phone.rawphone ;
         
         const dialcode =  phone.dialcode;
          setError(currentstate=>({
                     ...currentstate,
                   phone: ''}));
       
         
       
           const data = {
                   code: dialcode,
                   mobile_number: rawPhone
               }
              
                 axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
                  "Authorization" : 'Token '+str,
                  'Content-Type': 'application/json'}})
           .then(res =>{
                
                   if(res.data.status === "success"){
                     if(res.data.is_existing_user === true){
                      
                         setError(currentstate=>({
                     ...currentstate,
                   phone: "This phone number is already registered!"}));
                          setIsValidPhone(false);
                        }
                         else{
       
                     setIsValidPhone(true);
                         
                   }
                        
                          
                           
                       }
                   else{
       
                     setIsValidPhone(true);
                         
                   }
                       
                   })
           .catch(err =>{
       
           
         })
       
       }
         
       
        }

  const  validate = () => {
      //let input = email;
      let errors = {};
      let isValid = true;

      if(isValidPhone===false){
        isValid = false;
        errors["phone"] = "Phone number already registered!";
      }

      if (!phone.dialcode || phone.dialcode==="" || !phone.rawphone || phone.rawphone==="" ) {
        isValid = false;
        errors["phone"] = "Enter phone number";
      }
  
      setError(currentstate=>({
        ...currentstate,...errors
      }))
  
      return isValid;
  }


  const handleContinue =async(e) => {
    e.preventDefault();

    if( validate() && isValidPhone){
        
        handleSendOTP();
    }
  }

     /*Handle SENDOTP */
     const handleSendOTP=()=> {

      const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 
    
  //  if(otpStatus===false){
      const data = {
        code : phone.dialcode,
        mobile_number: phone.rawphone,
    }
   
    //let status= false;


    axios.post(`${APIURL}/api/v1/account/change-number/`, {
      

       mobile_number: data.mobile_number,
      code: data.code,  

}, { headers: {
  "Authorization" : 'Token '+str,
 'Content-Type': 'application/json'}})
.then(res =>{
       
        if(res.data.status === "success"){
              
            setPopup(true)
                // setStatus(true);
                
                
            }
        else{
            
              //setStatus(false);
     
        }
            
        })
.catch(err =>{
  alert.error('Failed To send OTP. Please try again!')
})
   // }
    
    }

const popupHandle=()=> {
    setPopup(false) 
    props.handle();
}

      const OtpPopUp = (props) => {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 <h4>Enter OTP</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <OtpUpdate handler={popupHandle} num={phone.rawphone} code={phone.dialcode}/>
               
              </Modal.Body>
            </Modal>
          )
      }

      

    return (<>
        <div className='phone-edit flex-phone'>
            <div className="form-control phone-input item item-left-row">
                                        <PhoneInput
                                            inputProps={{
              
                                                }}
                                                country={"mt"}
                                                onlyCountries={["mt"]}
                                                countryCodeEditable={false}
                                            value={phone.mobile_number}
                                            onChange={handlePhoneInput}
                                            onBlur={handleCheckExistingPhone}

                                          />
                                          {error.phone ? <div className="error-validation-msg"> {error.phone}</div> : null}

                                          </div>
            <div className="form-button">
                <button style={{marginLeft:'10px'}} onClick={handleContinue} className="btn btn-primary btn-col" >Update Phone</button>
            </div>


        {
            popup ?
            <OtpPopUp 
                show={popup}
                onHide= {()=> {
                    setPopup(false)
                }} />
            : null
        }
       </div>
        </>
    )
}

export default PhoneUpdate

import React, {useState,useEffect} from 'react'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import ViewRemark from "./ViewRemark";

function ProcedureHistory(props) {

    const [procedures,setProcedures]= useState([])
    const [modalShow, setModalShow]= useState(false);
    const [detailData, setDetailData]= useState('')
    const [errorMsg,setErrorMsg]=useState("");
    const [submitMsg,setSubmitMsg] = useState("");
     const [successSubmit,setSuccessSubmit]=useState(false);
     const [errorSubmit,setErrorSubmit]=useState(false);


     useEffect(()=> {

        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '')  
               
         
         
       
         axios.get(`${APIURL}/api/v1/service-provider/hospital-patient-procedure/?patient_id=${props.id}&status=completed&${TZ}`, { headers: {
           
           "Authorization" : 'Token '+str  }})
         .then(res=>{
             
           if(res.data.status==="success"){
            setProcedures(res.data.procedures)

           }
     
     
         })
         .catch(err=>{
     
     })

     },[])

    const renderEditable = (cellInfo) => {
        if(procedures[cellInfo.index][cellInfo.column.id]){
            return (
                <div  className="fixed-co"> {procedures[cellInfo.index][cellInfo.column.id]}</div>
            )
        }
        else {
            return <span className='text-danger'>NIL</span>
        }
    }

    const renderProcedureName = (cellInfo) => {
        if(procedures[cellInfo.index].patient_procedure){
            return (
                <div  className="fixed-co"> {procedures[cellInfo.index].patient_procedure}</div>
            )
        }
        else {
            return <span className='text-danger'>NIL</span>
        }
    }

    const renderProcedureType = (cellInfo) => {
        if(procedures[cellInfo.index].procedure_type){
            return (
                <div  className="fixed-co"> {procedures[cellInfo.index].procedure_type}</div>
            )
        }
        else {
            return <span className='text-danger'>NIL</span>
        }
    }

    const renderProcedurePrice = (cellInfo) => {
        if(procedures[cellInfo.index].amount){
            return (
                <div  className="fixed-co"> {procedures[cellInfo.index].amount}</div>
            )
        }
        else {
            return <span className='text-danger'>NIL</span>
        }
    }

    const renderDetails = (cellInfo) => {
        if(procedures[cellInfo.index][cellInfo.column.id]){
            return (
                <button onClick={()=> handleView(procedures[cellInfo.index][cellInfo.column.id])} className='btn btn-primary btn-col btn-square btn-small'>View</button>
            )
        }
        else {
            return <span className='text-danger'>NIL</span>
        }
    }

    const renderDate=(cellInfo)=> {

        if(procedures[cellInfo.index][cellInfo.column.id]){
  
        let dateString = procedures[cellInfo.index].date_of_procedure;
    
    let dateObject = new Date(dateString);
    
    const updatedDate = dateObject.toString();
    
        return (<div >
          <h6> {updatedDate.slice(4,16)}  </h6>
         </div>)

        }
        else {
            return <span className='text-danger'>NIL</span>
        } 
    
    }


    const ViewPopup=(props)=>{
    

        return (
          <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
              dialogClassName="remark-width"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <ViewRemark data={detailData}/>
      
            </Modal.Body>
            <Modal.Footer>
               
              <Button variant="secondary" onClick={props.onHide}>Close</Button>
             
      
            </Modal.Footer>
          </Modal>
        );
      }
      
      const handleView =(data) => {
        setDetailData(data)
        setModalShow(true)
      }


    const SuccessPopup=(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <h5 style={{color:"green"}}>{props.msg} </h5>
              
              
           
             
            </Modal.Body>
            <Modal.Footer>
               
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
               
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }
       
      
       const SubmitErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }  

    const columnsProcedure = [
        
        {
          Header: "Procedure NAME",
          accessor: "patient_procedure",
          Cell: renderEditable,
        //   style:{
        //     backgroundColor:"rgba(0,0,0,0.1)"
        //   },
          width:240,
          minWidth:100,
           sortable:false
        
        },
        {
            Header: "Date",
            accessor: "date_of_procedure",
            Cell: renderDate,

            width:190,
            minWidth:100,
             sortable:false
          
          },
        {
            Header: "Type",
            accessor: "procedure_details",
            Cell: renderProcedureType,

            width:190,
            minWidth:100,
             sortable:false
          
          },
        {
          Header: "Area",
          accessor: "area",
          Cell: renderEditable,

          width:140,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Sessions",
          accessor: "session",
          Cell: renderEditable,

          width:150,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Qty",
          accessor: "quantity",
          Cell: renderEditable,

          width:100,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Price",
          accessor: "procedure_amount",
          Cell: renderEditable,

          width:100,
          minWidth:100,
           sortable:false
        
        },
        {
            Header: "Details",
            accessor: "details",
            Cell: renderDetails,

            width:100,
             sortable:false
          
          },
          {
            Header: "Reason",
            accessor: "reason",
            Cell: renderDetails,

            width:100,
             sortable:false
          
          },
        
    ]


    const displayTable=<ReactTable 
    columns={columnsProcedure}
    data={procedures}
    defaultPageSize = {5}
    className="procedure-table"
    resizable
 
           
    >

    </ReactTable>

    return (
        <div id='procedure-table-list'>
            <h2 className='title-of-tasks'>Procedure History</h2>
            {displayTable}
        <br/>


        {submitMsg!=='' ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');
                        props.modalHandle()}}
       
      />: ''}

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setErrorMsg('');}}
       
      />: ''
    }


            {
      modalShow ? 
      <ViewPopup
        show={modalShow}
        onHide= {() => { setModalShow(false)     
        }}
      /> : null
    }


        </div>
    )
}

export default ProcedureHistory

import React,{useState,useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
function AddMisc(props) {
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
    const[data, setData]= useState({
        misc_name:'',
      description:''
     
        

    })
    const alert = useAlert();
    const [error,setError]=useState({
        misc_name:''
        
       });

    
    const[errorValidate,setErrorValidate]=useState(false)
  

      const handleError = () =>
  {
    setErrorValidate(false);
    
  }
    
      
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


    
  
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["misc_name"] || input["misc_name"]==="" ) {
      isValid = false;
      errors["misc_name"] = "Enter miscellaneous Name";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}
const handleConfirm = () => {
  closeConfirmationPopup()
  saveChanges();
 
};

const handleCancel = () => {
  closeConfirmationPopup()
};
const handleContinue = () => {
  if(validate()){ 
    setShowConfirmation(true)
  };
}

  const saveChanges = ()=> {
 
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
   misc_name:  data.misc_name.slice(0,1).toUpperCase() +data.misc_name.slice(1, data.misc_name.length),                                               
    description: data.description
        

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-miscellaneous/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Successfully saved')
          props.modalClose()
          setData({
            misc_name : '',
            description : ''

         });}
          else {
           alert.error('Miscellaneous already present')
         }
       })
       .catch((err) => {
        alert.error('Miscellaneous already present')
       });
    

  

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add Miscellaneous</h4>
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
              <div  className="formField">
                <div style={{display:"flex"}}>
                <span className='align-rental col-5'>*Name: </span>
                    <input type='text' className='form-control title-flex' name='misc_name' value={data.misc_name} onChange={handleTitle} /></div>
                    {error.misc_name ? <div className="error-validation-msg error-feature-inventory"style={{float:"right"}}>{error.misc_name}</div> : null}
                </div>

              
                <div className="formField" style={{display:"flex"}}>
                    <span className='align-rental col-4'>Description: </span>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                
            </div>
        </div>
        <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={handleContinue} className='btn btn-primary btn-col mb-2'>Save</button>   
          {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to continue?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </div>            
        </>
    )
}

export default AddMisc

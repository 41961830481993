import React, { useState ,useContext} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useAlert } from "react-alert";
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
function EditMisc(props) {
  const alert = useAlert();
  const [error, setError] = useState({
    misc_name: ''

  });
  const [data, setData] = useState(props.data)
  const [completeShow, setCompleteShow] = useState(false)
  const [successShow, setSuccessShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData(current => ({
      ...current, [key]: val
    }))
  }




  const saveChanges = () => {
 
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */
    let dataToSend = {}
    if (props.data.misc_name === data.misc_name) {
      dataToSend = {

        description: data.description

      }
    } else if (props.data.description === data.description) {
      dataToSend = {
        misc_name: data.misc_name,
      }
    } else {
      dataToSend = {
        misc_name: data.misc_name,
        description: data.description

      }
    }

    axios
      .put(`${APIURL}/api/v1/staff/miscellaneous-detail/${data.id}/`, dataToSend, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success('Successfully saved')
          setData({
            misc_name: '',
            description: ''
          });
        }
        else {
          alert.error(res.data.message)
         // setErrorShow(true)
        }
      })
      .catch((err) => {
       // setErrorShow(true)
      });
    //  setSuccessShow(false)
    
  }
  const handleConfirm = () => {
    closeConfirmationPopup()
    saveChanges();
   
  };
  
  const handleCancel = () => {
    closeConfirmationPopup()
  };
  const handleContinue = () => {
    if(validate()){ 
      setShowConfirmation(true)
    };
  }
  
  const validate = () => {

    let input = data;
    let errors = {};
    let isValid = true;
    setError({});


    if (!input["misc_name"] || input["misc_name"] === "") {
      isValid = false;
      errors["misc_name"] = "Enter miscellaneous Name";
    }



    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;
  }



  return (
    <>
      <h4 style={{ marginTop: '0' }} className="title-of-page">Edit Miscellaneous</h4>
      <div style={{ minHeight: 'fit-content', width:'100%' }} className='config-box fit-content'>

        <div className='feature-data pad-features'>
          <div className="formField">
            <div  style={{ display: "flex" }}>
            <label className='align-rental'>*Miscellaneous Name: </label>
            <input type='text' className='form-control title-flex' name='misc_name' value={data.misc_name} onChange={handleTitle} /> </div>
            {error.misc_name ? <div className="error-validation-msg error-feature-inventory">{error.misc_name}</div> : null}

          </div>


          <div className="formField" style={{ display: "flex" }}>
            <label className='align-rental'>Description: </label>
            <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
            {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
          </div>
        </div>        
      </div>
      <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={handleContinue} className='btn btn-primary btn-col mb-2'>Save</button>  
          {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to continue?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )} 
        </div>
    </>
  )
}

export default EditMisc

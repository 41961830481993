import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./presc.css";
import "./presctable.css";
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL, TZ } from "../../Global";
import EditMedicine from './EditMedicine'
//import {MedContext} from "../contexts/MedContext";
import "./currentmedications.css";
import DatePopUp from './DatePopUp';
import Review from "./Review";
//import ImageScale from '../ImageScale/ImageScale';
import ViewRemark from './ViewRemark';
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { useAlert } from "react-alert";
import { DatePicker as DatePicker1 } from 'rsuite';
import DatePicker from 'react-date-picker';
import moment, { invalid } from "moment";
import 'rsuite/dist/rsuite.min.css'; // Importing RSuite CSS

function CurrentMedications(props) {
  const [hr, setHr] = useState()
   
  const alert = useAlert();
  const patientid = props.patient;
  const [infoShow, setinfoShow] = useState(false);
  const [img, setImg] = useState([]);
  const [search, setSearch] = useState("name_suggest__completion");
  const [searchTerm, setSearchTerm] = useState("");
  const [index, setIndex] = useState();
  const [display, setDisplay] = useState(false);
  //const [medRender,setMedRender]=useContext(MedContext);
  const [dateShow, setDateShow] = useState(false)
  const [prescData, setPrescData] = useState([]);
  const [url, setUrl] = useState("")
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)
  const [medicines, setMedicines] = useState([]);
  const [medicine, setMedicine] = useState({});
  const [medList, setMedList] = useState([{
    drug: "",

    from: "",
    to: "",
    is_emergency: false,
    dosage: "",
    is_paused:false,
    intake_count: [],
    intake_time: '',
    remarks: ""
  }]);
  const [action,setAction]=useState("")
  const [editShow, setEditShow] = useState(false)
  const [render,] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [doctor1, setDoctorname] = useState('');
  const [submitMsg, setSubmitMsg] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [imageShow, setImageShow] = useState(false)
  //const [clickShow ,setClickShow]= useState(false)
  const [remarkData, setRemarkData] = useState('')
  const [viewShow, setViewShow] = useState(false)
  const [addnew, setAddnew] = useState(false)
  const [errorSubmitModalShow, setErrorSubmitModalShow] = useState(false);
  const [deleteMedConfirmMsg, setDeleteMedConfirmMsg] = useState("");
  const [deleteMedConfirmModalShow, setDeleteMedConfirmModalShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [medIndexToDelete, setMedIndexToDelete] = useState();
  const [medDelete, setMedDelete] = useState(false);
  const [medRefresh, setMedRefresh] = useState(false);
  const [dataList, setDataList] = useState([])
  const [infoData1, setInfoData1] = useState("");
  const id = props.patient;
  const refrsh = props.refresh;
  const [review, setReview] = useState(false)
  const [keys,] = useState({ 'z': false });

  const [load, setLoad] = useState(false)
  useEffect(() => {
    const handleKeyDown = (e) => {
      keys[e.key] = true

      if (e.altKey && keys['z']) {
        setErrorSubmitModalShow(false)
        setErrorMsg('')
        setModalShow(false)
        // setMedRender(!medRender)
        setSubmitMsg('')
      }

    }
    window.addEventListener('keydown', handleKeyDown)


    const handleKeyUp = (ev) => {

      keys[ev.key] = false
    }

    window.addEventListener('keyup', handleKeyUp)



    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)

    }
  }, [submitMsg, errorMsg]);



  useEffect(() => {

    setMedList(medList)

  }, [render, medList]);

  const handleDateChange = (value) => {

    // setDate(date)
    // const a = moment(date).format('YYYY-MM-DD');

    setMedicine(currentstate => ({
      ...currentstate,
      freq: value
    }));
  }
  useEffect(() => {



    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    // Get current timezone dynamically
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;



    axios.get(`${APIURL}/api/v1/doctor/update-current-medication/?medicines_list=current_medicines&patient_id=${id}&timeZone=${currentTimezone}&current_date=${formattedDate}&is_emergency=True`,
      {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Token ' + str
        }
      })
      .then(res => {




        if (res.data.status === "success") {

          let doctorname = "";

          let data = []
          if (res.data.doctor.length === 0 && res.data.patient.length === 0) {
            data = []
          }

          if (res.data.doctor.length !== 0) {
            res.data.doctor.map((item) => {
              data = data.concat(item.medicines)
              // doctorname=data.doctor_name;
            })
          }
          if (res.data.patient.length !== 0) {
            res.data.patient.map((item) => {
              data = data.concat(item.medicine)
              //patient_name=data.patient_name;
            })
          }


          // if(res.data.prescription.length !==0){
          //   res.data.prescription.map((item)=> {
          //     data = data.concat(item.medicines)
          //   })
          // }
          setMedList(data)
         
          //setDoctorname(doctorname)

          //  if (res.data && res.data.doctor && res.data.doctor.length > 0) {
          //     const data = res.data.doctor.medicines;
          //     setMedList(data);



        } else {

          setErrorMsg(res.data.message ? res.data.message : "Error in fetching data");
          setErrorSubmitModalShow(true);
          // setMedList([]);
        }


      })
      .catch(err => {

        setErrorMsg("Error in fetching data");
        setErrorSubmitModalShow(true);
      })



  }, [id, refresh, refrsh])


  /*const AddRow=()=>{
    const list = medList;
    list.push({drug:"", strength: "",form:"",duration: "",route:"", dosage:"",frequency:"",intake_count:[]});
    
    setMedList(list);
    setRender(!render);
    
  }*/





  const modalClose = () => {
    setEditShow(false)
    setRefresh(!refresh);
  };

  const EditPopup = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Body className="text-center">
          <EditMedicine data={dataList} modalClose={modalClose} />
        </Modal.Body>
      </Modal>
    );
  }
  useEffect(() => {
    setInfoData1(infoData1);
  }, [infoData1]);

  const InfoPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <span >{infoData1.drug.charAt(0).toUpperCase() + (infoData1.drug).slice(1).toLowerCase()}</span>

        </Modal.Header>
        <Modal.Body>
          <div>
          <div style={{ marginBottom: "1%" }} className="row">
           <div style={{ backgroundColor: "rgb(13, 197, 197)", color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-5"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Dosage form</div></div>
              <div style={{ backgroundColor: "rgb(13, 197, 197)",color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>Intake time </div></div>
              <div style={{ backgroundColor: "rgb(13, 197, 197)",color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>Route</div></div>
              </div>
            <div style={{ marginBottom: "1%" }} className="row">
             
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-5"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>{(infoData1.form).charAt(0).toUpperCase() + (infoData1.form).slice(1).toLowerCase().replace(/_/g, ' ')}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{infoData1.intake_time}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{infoData1.route}</div></div>

            </div>

            {/* <ReactTable
              columns={columns1}
              data={infoData1}
              defaultPageSize={1}
              showPagination={false}
              style={{ textAlign: "center" }}
              className="observation-table"
            >

            </ReactTable> */}
            <br />
            <h5 style={{ textAlign: "left" }}>Remarks</h5>
            <div className='remarkdiv'>
              <h6>{infoData1.remarks}</h6>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleShow = (index) => {
    const newarr = medList;
    const newList = newarr.filter((elt, i) => {
      return i === index
    })

    let data = []
    for (let i = 0; i < prescData.length; i++) {
      if (prescData[i].prescription_id === newList[0].prescription_id) {
        prescData[i].image.map(item => {
          data = data.concat(item)
        })
        setImg(data)

        break;
      }
      else {
        setImg([])
      }
    }


    setImageShow(true)


  }

  const ViewPopup = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ViewRemark data={remarkData} url={url} />

        </Modal.Body>
      </Modal>
    );
  }

 

  const renderEditableQty = (cellInfo) => {


    return (
      <div style={{ textTransform: "lowercase", color: medList[cellInfo.index].is_paused && "#cea59d" }}

      > {medList[cellInfo.index][cellInfo.column.id]}{" "}{medList[cellInfo.index].intake_unit}</div>)
  }
  const renderEditableFreq = (cellInfo) => {


    return (
      <div style={{ textTransform: "lowercase", color: medList[cellInfo.index].is_paused && "#cea59d" }}

      > {medList[cellInfo.index][cellInfo.column.id]!==null?medList[cellInfo.index][cellInfo.column.id]:(medList[cellInfo.index].date+" "+medList[cellInfo.index].time)}</div>)
  }
  const renderDrug=(cellInfo)=>{

    return(<div style={{display:"flex"}}>
     <div style={{padding:"1%",color:medList[cellInfo.index].is_paused&&"#cea59d"}}
          className=""
        > {(medList[cellInfo.index][cellInfo.column.id]).charAt(0).toUpperCase() + (medList[cellInfo.index][cellInfo.column.id]).slice(1).toLowerCase()} </div></div> )
  }
  const renderEditable = (cellInfo) => {


    return (
      <div style={{ textTransform: "lowercase", color: medList[cellInfo.index].is_paused && "#cea59d" }}

      > {cellInfo.column.id === "form" || cellInfo.column.id === "route" ? (medList[cellInfo.index][cellInfo.column.id] && medList[cellInfo.index][cellInfo.column.id] !== "" && (medList[cellInfo.index][cellInfo.column.id]).replace(/_/g, ' ').replace(/-/g, ' ')) : medList[cellInfo.index][cellInfo.column.id]}</div>)
  }
  
  const renderATC = (cellInfo) => {


    return (
      <div style={{ color: medList[cellInfo.index].is_paused && "#cea59d" }}

      > {medList[cellInfo.index][cellInfo.column.id]}</div>)
  }
  const renderEditableCr = (cellInfo) => {


    return (
      <div style={{ textTransform: "capitalize", color: medList[cellInfo.index].is_paused && "#cea59d" }}

      > {medList[cellInfo.index][cellInfo.column.id]}
        <br /><small>{medList[cellInfo.index].creator_type}</small></div>)
  }
 

  const IntakeUnitOptions1 = [
    { value: "", label: "" },
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "nos", label: "number" },
    { value: "shots", label: "shots" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
    { value: "cc", label: "cc" },
    { value: "g", label: "g" },
    { value: "puffs", label: "puffs" },
    { value: "mg", label: "mg" },

  ];
  const IntakeUnitOptions2 = [
    { value: "", label: "" },
    { value: "ml", label: "mL" },
    { value: "cc", label: "cc" },
    { value: "shots", label: "shots" },
    { value: "mg", label: "mg" },
  ];
  const IntakeUnitOptions3 = [
    { value: "", label: "" },
    { value: "ml", label: "mL" },
    { value: "puffs", label: "puffs" },
    { value: "mg", label: "mg" },
  ]
  const IntakeUnitOptions4 = [
    { value: "", label: "" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
    { value: "mg", label: "mg" },
  ]

  const IntakeUnitOptions5 = [
    { value: "", label: "" },
    { value: "nos", label: "number" },
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "g", label: "g" },
    { value: "mg", label: "mg" },
  ]
  const IntakeUnitOptions6 = [
    { value: "", label: "" },
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
    { value: "mg", label: "mg" },
  ]
  const IntakeUnitOptions7 = [
    { value: "", label: "" },
    { value: "nos", label: "number" }
  ]

 
  const freqOptions = [
    { value: "", label: "" },
    { value: "0-0-0-1", label: "0-0-0-1" },
    { value: "0-0-1-0", label: "0-0-1-0" },
    { value: "0-0-1-1", label: "0-0-1-1" },
    { value: "0-1-0-0", label: "0-1-0-0" },
    { value: "0-1-0-1", label: "0-1-0-1" },
    { value: "0-1-1-0", label: "0-1-1-0" },
    { value: "0-1-1-1", label: "0-1-1-1" },
    { value: "1-0-0-0", label: "1-0-0-0" },
    { value: "1-0-0-1", label: "1-0-0-1" },
    { value: "1-0-1-0", label: "1-0-1-0" },
    { value: "1-0-1-1", label: "1-0-1-1" },
    { value: "1-1-0-0", label: "1-1-0-0" },
    { value: "1-1-0-1", label: "1-1-0-1" },
    { value: "1-1-1-0", label: "1-1-1-0" },
    { value: "1-1-1-1", label: "1-1-1-1" },
  ];


  const deleteMedicineRow = (index) => {

    setDeleteMedConfirmMsg('success');
    setDeleteMedConfirmModalShow(true);
    setMedIndexToDelete(index);

  }
  const handleEdit = (data) => {
    setDataList(data)
    setEditShow(true)
  }
  useEffect(() => {
    if (date!==null&&medicine.date === moment(new Date()).format('YYYY-MM-DD') ) {
    setHr(new Date().getHours())
    
             
            }
            else {
                setHr(null)
              
            }
        }, [date])

 

  const columns = [

    {
      Header: <button onClick={() => {
        setAddnew(true)
      }} className="review-button"style={{backgroundColor:" rgb(60, 104, 158)", padding: "3%",width:"100%",}}>&nbsp;Add new&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M18 10h-4V6a2 2 0 0 0-4 0l.071 4H6a2 2 0 0 0 0 4l4.071-.071L10 18a2 2 0 0 0 4 0v-4.071L18 14a2 2 0 0 0 0-4" /></svg></button>,
      Cell: props => {
        return (<div >
          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Delete</Tooltip>}>
            <svg style={{ cursor: "pointer" }} onClick={() => { deleteMedicineRow(props.index);setAction("del") }} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="red" d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z" /></svg>
          </OverlayTrigger>
          &nbsp;&nbsp;&nbsp;
          {medList[props.index].is_paused?
      <OverlayTrigger   overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Start</Tooltip>}>
      <svg onClick={() => { deleteMedicineRow(props.index) ;setAction("start")}} style={{cursor:"pointer"}}xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 256 256"><path fill="green" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m40.55 110.58l-52 36A8 8 0 0 1 104 164V92a8 8 0 0 1 12.55-6.58l52 36a8 8 0 0 1 0 13.16"/></svg>
      </OverlayTrigger>:
      <OverlayTrigger   overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Pause</Tooltip>}>
      <svg  onClick={() => { deleteMedicineRow(props.index) ;setAction("pause")}}xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="orangered" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2m-1 14H9V8h2zm4 0h-2V8h2z"/></svg>
      </OverlayTrigger>}
        &nbsp;&nbsp;&nbsp;
          {medList[props.index].pharmacy_status === "approved" ? <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Available</Tooltip>}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 10 10"><path fill="green" d="M4.998 0a4.998 4.998 0 1 0 0 9.995a4.998 4.998 0 0 0 0-9.995ZM1 4.998a3.998 3.998 0 1 1 7.995 0a3.998 3.998 0 0 1-7.995 0Zm6.104-1.602a.5.5 0 0 1 0 .708l-2.25 2.25a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647l1.896-1.897a.5.5 0 0 1 .708 0Z" /></svg></OverlayTrigger>
            : medList[props.index].pharmacy_status === "partially_approved" ? <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Partially Available</Tooltip>}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 10 10"><path fill="blue" d="M4.998 0a4.998 4.998 0 1 0 0 9.995a4.998 4.998 0 0 0 0-9.995ZM1 4.998a3.998 3.998 0 1 1 7.995 0a3.998 3.998 0 0 1-7.995 0Zm6.104-1.602a.5.5 0 0 1 0 .708l-2.25 2.25a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647l1.896-1.897a.5.5 0 0 1 .708 0Z" /></svg></OverlayTrigger>
            : medList[props.index].pharmacy_status === "pending" ? <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Pending</Tooltip>}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 32 32"><circle cx="9" cy="16" r="2" fill="blue" /><circle cx="23" cy="16" r="2" fill="blue" /><circle cx="16" cy="16" r="2" fill="blue" /><path fill="blue" d="M16 30a14 14 0 1 1 14-14a14.016 14.016 0 0 1-14 14m0-26a12 12 0 1 0 12 12A12.014 12.014 0 0 0 16 4" /></svg></OverlayTrigger>
              : <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">not  Available</Tooltip>}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="red" d="M12 20a8 8 0 0 1-8-8H2c0 5.523 4.477 10 10 10v-2Zm0-16a8 8 0 0 1 8 8h2c0-5.523-4.477-10-10-10v2Zm-8 8a7.97 7.97 0 0 1 2.343-5.657L4.93 4.93A9.972 9.972 0 0 0 2 11.999h2Zm2.343-5.657A7.972 7.972 0 0 1 12 4V2a9.972 9.972 0 0 0-7.071 2.929l1.414 1.414Zm-1.414 0l12.728 12.728l1.414-1.414L6.343 4.929L4.93 6.343ZM20 12a7.97 7.97 0 0 1-2.343 5.657l1.414 1.414A9.972 9.972 0 0 0 22 12h-2Zm-2.343 5.657A7.972 7.972 0 0 1 12 20v2a9.972 9.972 0 0 0 7.071-2.929l-1.414-1.414Z" /></svg></OverlayTrigger>}
          &nbsp;&nbsp;&nbsp;
          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">med interaction</Tooltip>}>
            <svg style={{
              cursor: (medList[props.index].med_interactions !== "null" && medList[props.index].med_interactions !== "" && medList[props.index].med_interactions !== null) ||
                (medList[props.index].med_url !== "null" && medList[props.index].med_url !== "" && medList[props.index].med_url !== null) ? "pointer" : "not-allowed"
            }} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill={(medList[props.index].med_interactions !== "null" && medList[props.index].med_interactions !== "" && medList[props.index].med_interactions !== null) ||
              (medList[props.index].med_url !== "null" && medList[props.index].med_url !== "" && medList[props.index].med_url !== null) ? "Highlight" : "gray"} d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3M12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1m1 4h-2v-2h2z" /></svg>
          </OverlayTrigger>
         
          &nbsp;&nbsp;&nbsp;
          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Info</Tooltip>}>
            <svg onClick={() => { setInfoData1(medList[props.index]); setinfoShow(true) }} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 256 256"><path fill="#FF9C01" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m-4 48a12 12 0 1 1-12 12a12 12 0 0 1 12-12m12 112a16 16 0 0 1-16-16v-40a8 8 0 0 1 0-16a16 16 0 0 1 16 16v40a8 8 0 0 1 0 16" /></svg>
          </OverlayTrigger>
          &nbsp;&nbsp;&nbsp;
          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Edit</Tooltip>}>
            <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" onClick={() => handleEdit(medList[props.index])} width="1.5em" height="1.5em" viewBox="0 0 24 24"><g fill="var(--theme-blue)" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352z" /><path d="M19.846 4.318a2.2 2.2 0 0 0-.437-.692a2 2 0 0 0-.654-.463a1.92 1.92 0 0 0-1.544 0a2 2 0 0 0-.654.463l-.546.578l2.852 3.02l.546-.579a2.1 2.1 0 0 0 .437-.692a2.24 2.24 0 0 0 0-1.635M17.45 8.721L14.597 5.7L9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.5.5 0 0 0 .255-.145l4.778-5.06Z" /></g></svg>
          </OverlayTrigger> </div>

        )
      },

      sortable: false,
      filterable: false,
      width: 190,

    },
    {
      Header: "Drug",
      accessor: "drug",
      Cell: renderDrug,
      style:{color:"#3300FE"},
      minWidth: 100,
      sortable: false,
      filterable: false,
      width: 300

    },

    {
      Header: "ATC Code",
      accessor: "med_code",
      // minWidth: 80,
      // width: 110,
      Cell: renderATC,
      sortable: false,
      filterable: false,
      style:{color:"#3300FE"},

    },


    {
      Header: "Quantity",
      style:{color:"#3300FE"},
      accessor: "intake_count",
      // minWidth: 100,
      // width: 100,
      Cell: renderEditableQty,
      sortable: false,
      filterable: false

    },


    {
      Header: "Frequency / Date & time",
      accessor: "frequency",
      // minWidth: 140,
      // width: 130,
      style:{color:"#3300FE"},
      Cell: renderEditableFreq,
      sortable: false,
      filterable: false

    },


    {
      Header: "Prescribed by",
      accessor: "creator",
      style:{color:"#3300FE"},
      Cell: renderEditableCr,
      

      minWidth: 100,
      sortable: false,
      filterable: false,
      // width: 200

    },


  ]





  const handleDeleteMed = () => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    let dataToSend={}
    if(action==="del"){
    dataToSend={
      is_deleted:"True"
    }
    }else if(action==="pause"){
      dataToSend={
        is_paused:"True"
      }
    }else if(action==="start"){
      dataToSend={
        is_paused:"False"
      }
    }

    axios.put(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${medList[medIndexToDelete].id}/`, dataToSend, {
      headers: {
        "Content-Type": "application/json",

        Authorization: 'Token ' + str,
      }


    })



      .then(res => {

        if (res.status == 200) {

          alert.success(action==="del"?"Deleted successfully":res.data.message)
          setMedIndexToDelete(null)
          setRefresh(!refresh)
        }
        else {
          setSubmitMsg("error");


        }

      })
      .catch(err => {

        setSubmitMsg("error");

      })


  }
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setMedicine({});
      setDisplay(false);
    } else {

      const tokenString = sessionStorage.getItem('usertoken');

      let str = tokenString.replace(/["]+/g, '');

      axios
        .get(`${APIURL}/api/v1/doctor/medicine-search/?q=${searchTerm}`,
          {
            headers: {
              'Content-Type': 'application/json',
              "Authorization": 'Token ' + str
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setMedicines(res.data.medicines);

            setDisplay(true);

          } else {
          }
        })
        .catch((error) => { });
    }
  }, [searchTerm]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    ;
    if (e.target.value === '') {
      setDisplay(false);
    }

  };
  const DeleteMedConfirmPopup = (props) => {


    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {action==="del"? <h4 id="confirm-reject">Please confirm to delete current medicine</h4>:
      action==="pause"?<h4 id="confirm-reject">Please confirm to pause current medicine</h4>:
      action==="start"?<h4 id="confirm-reject">Please confirm to start current medicine</h4>:""}
        </Modal.Body>

        <Modal.Footer>


          <Button
            variant="danger"
            onClick={() => {

              setDeleteMedConfirmModalShow(false);
              handleDeleteMed();
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  const handleAddProcedure = (e, item) => {

    e.preventDefault();
    e.preventDefault();
    initialRender.current = true;
    setSearchTerm(item.med_name);
    setMedicine(item)
    setDisplay(false);

    if (item.id === "") {
      alert.error("Please Select Medicine name from Dropdown");

    }






  };
  const handleDate = (date) => {

    setDate(date)

    const a = date !== null ? moment(date).format('YYYY-MM-DD') : null;
    setTime(null)
    setMedicine(currentstate => ({
      ...currentstate,
      date: a !== null ? a : "",
      time: null,
      frequency:""
    }));

  }
  const handleTime = (time) => {
    setTime(time)
    const a = moment(time).format('HH:mm:ss');
if(medicine.date === moment(new Date()).format('YYYY-MM-DD')){
  if(moment(time).format('HH:mm')<moment(new Date()).format('HH:mm')){
    alert.error("past time is not permitted")
    setTime(null)
  }
  else{
    setMedicine(currentstate => ({
      ...currentstate,
      time: a,
      frequency:""
    }));
  }
}
else{
    setMedicine(currentstate => ({
      ...currentstate,
      time: a,
      frequency:""
    }));
  }
  }
  const ReviewPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >

        <div style={{ display: "flex", width: "100%", padding: "1%" }}><h4 style={{ width: "100%" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Preview </h4>
          {/* <svg  onClick={() => { setReview(false); }} style={{float:"right",marginRight:"1%",marginTop:"5%"}}xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512c282.784 0 512-229.216 512-512C1024 229.232 794.784 0 512 0m0 961.008c-247.024 0-448-201.984-448-449.01c0-247.024 200.976-448 448-448s448 200.977 448 448s-200.976 449.01-448 449.01m181.008-630.016c-12.496-12.496-32.752-12.496-45.248 0L512 466.752l-135.76-135.76c-12.496-12.496-32.752-12.496-45.264 0c-12.496 12.496-12.496 32.752 0 45.248L466.736 512l-135.76 135.76c-12.496 12.48-12.496 32.769 0 45.249c12.496 12.496 32.752 12.496 45.264 0L512 557.249l135.76 135.76c12.496 12.496 32.752 12.496 45.248 0c12.496-12.48 12.496-32.769 0-45.249L557.248 512l135.76-135.76c12.512-12.512 12.512-32.768 0-45.248"/></svg> */}
        </div>


        <Modal.Body>
          <Review MedData={[medicine]} from="emergency" onSuccess={(data) => { setReview(false); medicineSubmit([medicine], data); }} needUpdate={() => {setReview(false);setLoad(false)}} />
        </Modal.Body>

      </Modal>
    )
  }
  const medicineSubmit = (MedData, x) => {


    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    if (MedData.length < 1) {
      return false;
    }

    let medList = [];



    for (let i = 0; i < MedData.length; i++) {
      let durationVal = 0;
      let durationunit = "";
      let frequencyValue = 0;
      let intakeValue = 0;
      let intakeTimeValue = 0;
      let intakeUnitValue = 0;

    
      if (
        typeof MedData[i].frequency === "object" &&
        MedData[i].frequency !== null
      ) {
        frequencyValue = MedData[i].frequency.value;
      } else {
        frequencyValue = MedData[i].frequency;
      }

      if (typeof MedData[i].intake === "object" && MedData[i].intake !== null) {
        intakeValue = MedData[i].intake.label;
      } else {
        intakeValue = MedData[i].intake;
      }
      if (
        typeof MedData[i].intake_time === "object" &&
        MedData[i].intake_time !== null
      ) {
        intakeTimeValue = MedData[i].intake_time.value;
      } else {
        intakeTimeValue = MedData[i].intake_time;
      }
      if (
        typeof MedData[i].intake_unit === "object" &&
        MedData[i].intake_unit !== null
      ) {
        intakeUnitValue = MedData[i].intake_unit.value;
      }

      else {
        intakeUnitValue = MedData[i].intake_unit;
      }
      if(frequencyValue!==""){
      medList.push({

        med_code: MedData[i].med_code,
        drug: MedData[i].med_name,
        medicine: MedData[i].id,
        med_type: MedData[i].med_type,
        frequency: frequencyValue,
        intake: "Daily",
        
       
        intake_unit: intakeUnitValue,
        form: MedData[i].dosage_form === " " ? MedData[i].dosage_form1 : MedData[i].dosage_form,
      
        intake_count: MedData[i].dosage_count,
        remarks: MedData[i].remarks,
      });
    }else{
      medList.push({
        form: MedData[i].dosage_form === " " ? MedData[i].dosage_form1 : MedData[i].dosage_form,
        med_code: MedData[i].med_code,
        drug: MedData[i].med_name,
        medicine: MedData[i].id,
        med_type: MedData[i].med_type,
        date:MedData[i].date,
        time:MedData[i].time,
        intake: "Daily",
        intake_unit: intakeUnitValue,
        intake_count: MedData[i].dosage_count,
        remarks: MedData[i].remarks,
      
      });
    }
    }
    const dataToSend = {
      patient_id: patientid,
      is_emergency: true,
      medicines: medList,
    };

    axios
      .post(`${APIURL}/api/v1/doctor/patient-prescriptions/`, dataToSend, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          if (x === true) {
            axios
              .post(`${APIURL}/api/v1/doctor/schedule-runner/?patient_id=${patientid}&schedule=medicine`, {}, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Token " + str,
                },
              })
          }
          alert.success("successfully added");
          
          setSearchTerm("");
           setMedicine({})
           setDisplay(false)
           setLoad(false)
          setDate(null)
          setTime(null)
           setRefresh(!refresh)

        } else {
         
          alert.error(res.data.message)
          setLoad(false)

        }
      })
      .catch((err) => {
        // alert.error(" Please check whether medicine already added or not");
        setLoad(false)
      });

  };
  const handleMedicine = () => {


    setLoad(true)
    let isValid = true;

    const MedData = [medicine]

    MedData.map((item) => {
      console.log(item,"medicine")
      if (item.medicine_name === "") {

        alert.error("*Medicine name is mandatory!");

        isValid = false;
      }


      else if ((!item.frequency||item.frequency === ""||item.frequency===undefined) && (item.date === "" ||item.date === null|| item.time === ""||item.time === null||item.date===undefined||item.time===undefined)) {
        alert.error("*Frequency/Date and time is mandatory!");

        isValid = false;
      }
      else if((item.date !== ""&&(item.time===""||item.time==="Invalid date"))||((item.date === ""||item.date==="Invalid date")&&item.time!=="")){
        alert.error("* date and time is mandatory!");

        isValid = false;
      }
      else if (item.intake_unit === "" || item.intake_unit === undefined) {
        alert.error("*Quantity is mandatory!");

        isValid = false;
      }

      //(item.duration >= 1)
      // else if (item.durationvalue === undefined || item.durationvalue == "") {
      //   alert.error("*Duration is mandatory!");
      //   isValid = false;
      // }

      // else if (item.intake === "") {
      //   alert.error("*Intake Period is mandatory!");

      //   isValid = false;
      // } 
      else if (item.dosage_count === "") {
        alert.error("*quantity is mandatory!");

        isValid = false;
      }


    });

    if (isValid) {
      setReview(true)

    }
    else {
      setLoad(false)
    }
  };
  return (
    <div className="overlay-body">


      <div className="add-presc-page">
        {addnew && <div className="" style={{ marginTop: "1%" }}>

          <div style={{ backgroundColor: "#e4dd9f", padding: "2%", borderRadius: "10px" }}>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-8">

                <div className="search-div">
                  <div style={{ display: "flex", position: "relative" }}>

                    <input

                      type="text"
                      className=""
                      placeholder={search === "composition" ? "Search with medical composition" : search === "med_name" ? "Search with medicine name" : search === "generic_name" ? "Search with generic name" : "Search medicine name, medical composition or ATC code"}
                      value={searchTerm}
                      onChange={handleSearchChange}
                      style={{ paddingLeft: "10px", width: "100%" }}
                    />
                    <div className="btn btn-primary btn-col search-btn">
                      {" "}
                      <i className="fas fa-search"></i>{" "}
                    </div>

                  </div>
                </div>

                {display ? (
                  <div className="suggest-display " >
                    {medicines.length > 0 ? (
                      medicines.map((item) => {
                        return (
                          <p
                            onClick={(e) => { 
                              delete medicine.med_name
                              delete medicine.frequency
                              delete medicine.intake_unit
                              delete medicine.dosage_count
                              delete medicine.date
                              delete medicine.time
                              delete medicine.remarks
                            setDate(null);
                            setTime(null);handleAddProcedure(e, item)}}
                            className=" text-secondary"
                            style={{ padding: "1%" }}
                          >

                            <div className="row">
                              <span className="col-2">{item.med_code}</span>
                              <span className="col-5">{item.med_name}</span>
                              <span className="col-5">{item.composition}</span> </div>
                          </p>
                        );
                      })
                    ) : (
                      <h6 className="text-muted" style={{ padding: 10 }}>
                        No Medicines found !!
                      </h6>
                    )}
                  </div>
                ) : null}

              </div>
<div className='col-2'>
{!medicine.med_name&&  < button  onClick={() => {
                      setAddnew(false); setSearchTerm(""); setMedicine({})
                    }} className='review-button ' style={{ backgroundColor: "red", padding: "0px", width: "80%", height: "42px" }}>
                      Cancel
                    </button>}
</div>

            </div>
           
            {medicine.med_name&&medicine.med_name!=="" && <div>
              <div className='row'>
                <div className='col-4' style={{ paddingTop: "1%" }}>
                  <label >Drug: </label>
                  <textarea maxLength="300"
                    className='css-1s2u09g-control1 '
                    type="text"
                    min="1"
                    value={medicine.med_name}
                    style={{ width: "100%", padding: "2%" }}

                  />
                </div>
                <div className='col-4' style={{ paddingTop: "1%" }}>
                  <label >Composition: </label>
                  <textarea maxLength="300"
                    className='css-1s2u09g-control1 '
                    type="text"
                    min="1"
                    value={medicine.composition}
                    style={{ width: "100%", padding: "2%" }}

                  />
                </div>
                <div className='col-4' style={{ paddingTop: "1%" }}>
                  <label >Remarks: </label>
                  <textarea maxLength="300"
                    className='css-1s2u09g-control1 '
                    type="text"
                    min="1"
                    onChange={(e) => {



                      let data = e.target.value;



                      setMedicine((currentstate) => ({
                        ...currentstate,
                        remarks: e.target.value,
                      }))}}

                    value={medicine.remarks?medicine.remarks:""}
                    style={{ width: "100%", padding: "2%" }}

                  />
                </div>

              </div>
              <div className='row'>
                <div className='col-2' style={{ paddingTop: "1%" }}>
                  <label >ATC code: </label>
                  <input

                    className='css-1s2u09g-control1 '
                    type="text"
                    min="1"
                    value={medicine.med_code}
                    style={{ width: "100%" }}

                  />
                </div>

                <div className='col-6' style={{ paddingTop: "1%" }}>
                  <label >Frequency / Date & time: </label>
                  <div style={{ display: "flex" }}>
                    <select

                      className='css-1s2u09g-control1'
                      style={{ width: "40%" }}
                      value={medicine.frequency?medicine.frequency:""}
                      onChange={(data) => {
                        setMedicine((currentstate) => ({
                          ...currentstate,
                          frequency: data.target.value,
                          date: "",
                          time: ""
                        }))
                        setDate(null);
                        setTime(null)
                      }}
                    // options={freqOptions}
                    >
                      {freqOptions.map((item) => {
                        return (
                          <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                        )
                      })}

                      
                    </select> &nbsp;<h6 style={{marginTop:"2%"}} >
                    <b>OR</b>
                    </h6>&nbsp;
                    <DatePicker

                      style={{ border: "none" }}
                      className=" css-1s2u09g-control1"
                      appearance="default"
                      format="yyyy-MM-dd"
                      minDate={new Date()}
                      onChange={date => { handleDate(date) }}
                      // disabledDate={allowedRange(admissionDate, new Date())}
                      onClear={() => { }}
                      value={date}



                    />
                    &nbsp;

                    <DatePicker1
                      disabled={date === ""}
                      style={{ border: "none" }}
                      className="css-1s2u09g-control1"
                      appearance="default"
                      format="HH:mm"
                      onChange={time => { handleTime(time) }}
                      disabledHours={hour => hour < hr}
                     
                      onClear={() => { }}
                      value={time}

                    />



                  </div>
                </div>
                <div className='col-2' style={{ paddingTop: "1%" }}>
                  <label >Quantity: </label>
                  <div style={{ display: "flex" }}>
                    <input

                      className='css-1s2u09g-control1 '
                      type="number"
                      min="1"
                      value={medicine.dosage_count?medicine.dosage_count:""}
                      style={{ width: "40px", height: "35px" }}

                      onChange={(e) => {



                        let data = e.target.value;



                        setMedicine((currentstate) => ({
                          ...currentstate,
                          dosage_count: e.target.value,
                        }))



                        if (data === "0"||data<1) {
                          setMedicine((currentstate) => ({
                            ...currentstate,
                            dosage_count: "",
                          }))
                          alert.error("Please Enter a Valid quantity")


                        }

                      }} />
                    <select

                      className='css-1s2u09g-control1 '
                      style={{ width: "95%" }}

                      value={medicine.intake_unit?medicine.intake_unit:""}
                      onChange={(data) => {
                        setMedicine((currentstate) => ({
                          ...currentstate,
                          intake_unit: data.target.value,
                        }))
                      }}

                    >
                      {medicine.dosage_form && (
                        medicine.dosage_form.toLowerCase().includes("capsule") || medicine.dosage_form.toLowerCase().includes("chewable") || medicine.dosage_form.toLowerCase().includes("suppositor") || medicine.dosage_form.toLowerCase().includes("tablet")) ? IntakeUnitOptions7.map((item) => {
                          return (
                            <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                          )
                        }) : medicine.dosage_form && (
                          medicine.dosage_form.toLowerCase().includes("solution") || medicine.dosage_form.toLowerCase().includes("emulsion") || medicine.dosage_form.toLowerCase().includes("lotion")) ? IntakeUnitOptions6.map((item) => {
                            return (
                              <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                            )
                          }) : medicine.dosage_form && (
                            medicine.dosage_form.toLowerCase().includes("suspension") || medicine.dosage_form.toLowerCase().includes("cream") || medicine.dosage_form.toLowerCase().includes("ointment")) ? IntakeUnitOptions4.map((item) => {
                              return (
                                <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                              )
                            }) : medicine.dosage_form && (medicine.dosage_form.toLowerCase().includes("granul")) ? IntakeUnitOptions5.map((item) => {
                              return (
                                <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                              )
                            }) : medicine.dosage_form && (medicine.dosage_form.toLowerCase().includes("aerosol") || medicine.dosage_form.toLowerCase().includes("gas")) ? IntakeUnitOptions3.map((item) => {
                              return (
                                <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                              )
                            }) : medicine.dosage_form && (medicine.dosage_form.toLowerCase().includes("injection")) ? IntakeUnitOptions2.map((item) => {
                              return (
                                <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                              )
                            }) : IntakeUnitOptions1.map((item) => {
                              return (
                                <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                              )
                            })}
                    </select>

                  </div>
                </div>
                <div className='col-2'>

                  <div style={{ display: "flex", marginTop: "26%" }}>
                    <button onClick={handleMedicine} disabled={load} className="review-button" style={{ padding: "2%", width: "50%", height: "35px" }}>Preview</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button onClick={() => {
                      setAddnew(false); setSearchTerm(""); setMedicine({})
                    }} className='review-button ' style={{ backgroundColor: "red", padding: "2%", width: "50%", height: "35px" }}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

            </div>}
          </div>

        </div>}



        <div className="current-table-holder" style={{ marginTop: "1%" }}>
          <ReactTable
            columns={columns}
            data={medList}
            defaultPageSize={5}
            filterable
            resizable
            noDataText={"No data"}
            className="observation-table"
          
          >

          </ReactTable>
          <br />

        </div>




      </div>


      {deleteMedConfirmMsg === "success" ?

        <DeleteMedConfirmPopup
          show={deleteMedConfirmModalShow}
          onHide={() => setDeleteMedConfirmModalShow(false)}
          index={medIndexToDelete}
        />

        :
        ''}
      {
        editShow ?
          <EditPopup
            show={editShow}
            onHide={() => {
              setEditShow(false)
            }}
          /> : null
      }
      {
        viewShow ?
          <ViewPopup
            show={viewShow}
            onHide={() => {
              setViewShow(false)
            }}
          /> : null
      }
      {infoShow ? (
        <InfoPopup1
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
      {
        review ?
          <ReviewPopUp
            show={review}
            onHide={() => setReview(false)}
          /> : null
      }
    </div>
  )
}

export default CurrentMedications;
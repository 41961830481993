import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import Map from "./Map";
// import GoogleMapReact from 'google-map-react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Autocomplete from 'react-google-autocomplete';

import "../css/bootstrap.min.css";
import "../css/iofrm-style.css";
import "../css/iofrm-theme4.css";
import "./register.css";
import axios from "axios";

import "./country_region_selector.css";
import "./phoneinput.css";
import "./hospregister.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { APIURL,TZ } from "../../Global";

import AuthUtil from "../login/AuthUtil";


function ServiceProviderRegister(props) {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [img, setImg] = useState();
  const [emailshow, setemailshow] = useState();
  const [latitude, setLatitude] = useState(13.0334);
  const [longitude, setLongitude] = useState(77.5640);
const [isvalidnum,setIsValidnum]=useState(true)
const [isvalidEnum,setIsValidEnum]=useState(true)
  const [hospitalDatas, setHospitalDatas] = useState({
    name: "",
    phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
    email: "",
    country: "Malta",
    state: "",
    location: "",
    street: "",
    password: "",
    confirmpassword: "",
    latitude: "",
    longitude: "",
    pincode: "",
    accreditation: null,
    regn_number: "",
    primary_email: "",
    website_url: "",
    established_year: null,
    healthcare_type: "",
    delivery_radius:"",
    img_url: "",
    img: "",
    gst: "",
    description: "",
    emergency: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
  });
  const [error, setError] = useState({
    name: "",
    phone: "",
    email: "",
    country: "",
    state: "",
    location: "",
    address: "",
    pincode: "",
    street: "",
    password: "",
    confirmpassword: "",
    delivery_radius:"",
    regn_number: "",
    healthcare_type: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidRegis, setIsValidRegis] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [invalidPic, setInvalidPic] = useState(null);
  const [previewOn, setPreviewOn] = useState(false);
  const [formDataStatus, setFormDataStatus] = useState(false);
  const [sizeShow, setSizeShow] = useState(false);
  const [validShow, setValidShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [isMapError, setIsMapError] = useState(false);
  const [isMapSucess, setIsMapSucess] = useState(false);

  const [errorSubmitMsg, setErrorSubmitMsg] = useState(null);
  const [errorSubmit, setErrorSubmit] = useState(false);

  const [successSubmitMsg, setSuccessSubmitMsg] = useState(null);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [confirmDelete, setDeleteShow] = useState(false);
  

  let formData = new FormData();

  const name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const confirmpassword = useRef(null);
  const mobile_number = useRef(null);
  const emergency = useRef(null);
  const country = useRef(null);
  const region = useRef(null);
  const location = useRef(null);
  const address = useRef(null);
  const pincode = useRef(null);
  const street = useRef(null);
  const website_url = useRef(null);
  const primary_email = useRef(null);
  const established_year = useRef(null);
  const delivery_radius = useRef(null);
  const regn_number = useRef(null);
  const togglePasswordVisiblity = (e) =>
  {
  
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const togglePasswordVisiblity1 = (e) =>
  {
  
    if(passwordType1==="password")
    {
     setPasswordType1("text")
     return;
    }
    setPasswordType1("password")
  }
  useEffect(() => {
    let input = hospitalDatas;
    if (
      typeof input["password"] !== "undefined" &&
      input["password"] !== "" &&
      typeof input["confirmpassword"] !== "undefined" &&
      input["confirmpassword"] !== "" &&
      input["confirmpassword"] !== null
    ) {
      if (input["password"] !== input["confirmpassword"]) {
        setError((currentstate) => ({
          ...currentstate,
          confirmpassword: "Passwords don't match",
        }));
      } else {
        setError((currentstate) => ({
          ...currentstate,
          confirmpassword: "",
        }));
      }
    } else if (input["confirmpassword"] === "") {
      setError((currentstate) => ({
        ...currentstate,
        confirmpassword: "",
      }));
    }
  }, [hospitalDatas]);

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
if(key==="name"){
  setError((currentstate) => ({
    ...currentstate,
    "name":"",
  }));
}
if(key==="password"){
  setError((currentstate) => ({
    ...currentstate,
    "password":"",
  }));
}
if(key==="street"){
  setError((currentstate) => ({
    ...currentstate,
    "street":"",
  }));
}
if(key==="location"){
  setError((currentstate) => ({
    ...currentstate,
    "location":"",
  }));
}

if(key==="pincode"){
  setError((currentstate) => ({
    ...currentstate,
    "pincode":"",
  }));
}
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };

  const reader = new FileReader();
  const uploadImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setUploadShow(!uploadShow);
      //setInvalidPic('Please Select an Image')
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow);
      //setInvalidPic('Select valid image')
      return false;
    }
    if (file.size > 10e6) {
      setSizeShow(!sizeShow);
      return false;
    }

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        setImg(file);
        setInvalidPic(null);
      };

      img.onerror = () => {
        setPreviewOn(false);
        setContentShow(!contentShow);
        //setInvalidPic('Invalid Image Content')
        return false;
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);

    const objectURL = URL.createObjectURL(file);

    setHospitalDatas((currentstate) => ({
      ...currentstate,
      img_url: objectURL,
    }));
    setPreviewOn(true);

    setFormDataStatus(true);
  };

  const setMapError = (lat, lng) => {
    setIsMapError(true);
    setLatitude(lat);
    setLongitude(lng);
  }

  const newCoordinates = (lat, lng, address, city, state, pincode) => {
    // console.log('THE LAT LONG', lat, lng, address, city, state);
    // console.log('THE ADDRESS', address);
    // console.log('THE CITY', city);
    // console.log('THE STATE', state);
    let data = {...hospitalDatas}
    data.state = state;
    data.location = city;
    data.street = address;
    data.latitude = parseFloat(lat).toFixed(4);
    data.longitude = parseFloat(lng).toFixed(4);
    data.pincode = pincode;
    setError((currentstate) => ({
      ...currentstate,
      address: "",
      street: "",
      location: "",
      pincode: "",
      state: "",
    }));
    setHospitalDatas(data);
    setLatitude(lat);
    setLongitude(lng);
    setTimeout(() => {
      setIsMapSucess(true);
    }, 300);
  }

  const SizePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 id="confirm-reject">You have exceeded image size limit 10MB</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const MapError = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Select Address within Malta</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const MapSuccess = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ }}>You have selected the following Address</h5>
          <br/>
            <label className="">Latitude: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.latitude}</span><br/>
            <label className="">Longitude: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.longitude}</span><br/>
            <label className="">Address: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.street}</span><br/>
            <label className="">Location/City: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.location}</span><br/>
            <label className="">State: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.state}</span><br/>
            <label className="">Pincode: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.pincode}</span>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const LoadPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Select an Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ValidPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Select a valid Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ConfirmPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 id="confirm-reject">Are you sure you want to delete the uploaded image ?</h4>
           
          </Modal.Body>
          <Modal.Footer>
  
             <Button variant="secondary" onClick={props.onHide}>No</Button>
             <Button variant="danger" onClick={() => {
               removePicPreview();
               setDeleteShow(false);
             }}>Yes</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

 
  const ContentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Invalid Image Content</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {errorSubmitMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h4>Registered successfully !!</h4>
          <h6>An email verification has been send to your email id {hospitalDatas.email}. Please verify the email</h6>
          
        </Modal.Body>
        <Modal.Footer>
          <Link to="/login" state={{from:"reg" }}>
            <Button className="btn btn-primary btn-col">Goto Login</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  };

  const removePicPreview = () => {
    // e.preventDefault();
    setPreviewOn(false);
  };

  const handleContinue = async (e) => {
    e.preventDefault();

    const res = await validate();

    if (res === true && isValidEmail && isValidPhone) {
      handleSignup();
    }
  };
  const validate = async () => {
    let focussed = false;
    let input = hospitalDatas;
    let errors = {};
    let isValid = true;
    setError({});

    if (input["name"].trim() === "" || input["name"].trim() === null) {
      isValid = false;
      errors["name"] = "Enter service provider name";
      if (!focussed) {
        name.current.focus();
        focussed = true;
      }
    }

    if (typeof input["name"] !== "undefined") {
      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern3.test(input["name"])) {
        isValid = false;
        errors["name"] = "Special Characters are not allowed";
        if (!focussed) {
          name.current.focus();
          focussed = true;
        }
      }
    }

    if (typeof input["email"] !== "undefined") {
      const pattern2 = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern2.test(input["email"])) {
        isValid = false;
        errors["email"] = "Enter a valid email address.";
        if (!focussed) {
          email.current.focus();
          focussed = true;
        }
      }
    }

    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
      if (!focussed) {
        email.current.focus();
        focussed = true;
      }
    }
    if (
      typeof input["email"] === "undefined" ||
      input["email"].trim() === null ||
      input["email"].trim() === ""
    ) {
      isValid = false;

      errors["email"] = "Enter email address.";
      if (!focussed) {
        email.current.focus();
        focussed = true;
      }
    }


    if (typeof input["password"] !== "undefined") {
      let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,30}/);
      if (!pattern.test(input["password"])) {
        isValid = false;
        errors["password"] = "The password should be 8-30 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$.!#^%*?&_). Spaces are not allowed.";
        if (!focussed) {
          password.current.focus();
          focussed = true;
        }
      }
    }

    if (input["password"].trim() && typeof input["password"] !== "undefined") {
      if (input["password"].length > 0 && input["password"].length < 8&&input["password"].length>30) {
        isValid = false;

        errors["password"] = "Password should be 8 - 30 characters ";
        if (!focussed) {
          password.current.focus();
          focussed = true;
        }
      }
    }
    if (
      input["password"].trim() === null ||
      typeof input["password"] === "undefined" ||
      input["password"].trim() === "" ||
      input["password"].trim() === null
    ) {
      isValid = false;
      errors["password"] = "Enter your password";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["password"] !== "undefined" &&
      input["password"].trim() !== "" &&
      typeof input["confirmpassword"] !== "undefined" &&
      input["confirmpassword"].trim() !== "" &&
      input["confirmpassword"].trim() !== null
    ) {
      if (input["password"] !== input["confirmpassword"]) {
        isValid = false;
        errors["confirmpassword"] = "Passwords don't match";
        if (!focussed) {
          confirmpassword.current.focus();
          focussed = true;
        }
      }
    }

    if (
      input["confirmpassword"].trim() === null ||
      typeof input["confirmpassword"] === "undefined" ||
      input["confirmpassword"].trim() === ""
    ) {
      isValid = false;
      errors["confirmpassword"] = "Enter confirm password";
      if (!focussed) {
        confirmpassword.current.focus();
        focussed = true;
      }
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }
    if (
      hospitalDatas.phone.dialcode === null ||
      hospitalDatas.phone.dialcode === "" ||
      !hospitalDatas.phone.rawphone.trim() ||
      hospitalDatas.phone.rawphone.trim() === ""
    ) {
      isValid = false;
      errors["phone"] = "Enter phone number";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }
    if (hospitalDatas.phone.rawphone.length > 0 && hospitalDatas.phone.mobile_number.length <11) {
      isValid = false;
      errors["phone"] = "Enter mobile number with 8 digits ";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }
    if (!isvalidnum){
      isValid = false;
      errors["phone"] = "Enter mobile number with 8 digits ";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }
    if (!isvalidEnum){
      isValid = false;
      errors["emergency"] = "Enter mobile number with 8 digits ";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }

    if ((hospitalDatas.phone.dialcode.length > 0 && hospitalDatas.phone.dialcode.length < 3 ) &&
    (hospitalDatas.phone.rawphone.length > 0 && hospitalDatas.phone.rawphone.length < 8))
    {
      isValid = false;
      errors["phone"] = "Enter a valid phone number";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }
  
    if (hospitalDatas.emergency.rawphone.length > 0 && hospitalDatas.emergency.rawphone.length < 8) {
      isValid = false;
      errors["emergency"] = "Enter mobile number with 8 digits ";
      if (!focussed) {
        confirmpassword.current.focus();
        focussed = true;
      }
    }
    if ((hospitalDatas.emergency.dialcode.length > 0 && hospitalDatas.emergency.dialcode.length < 3 ) &&
     (hospitalDatas.emergency.rawphone.length > 0 && hospitalDatas.emergency.rawphone.length < 8))
    {
      isValid = false;
      errors["emergency"] = "Enter a valid emergency number";
      if (!focussed) {
        confirmpassword.current.focus();
        focussed = true;
      }
    }

   
    if (hospitalDatas.country.trim() === "" || hospitalDatas.country.trim() === null) {
      isValid = false;
      errors["country"] = "Enter Country";
      if (!focussed) {
        location.current.focus();
        focussed = true;
      }
    }
    if (
      typeof hospitalDatas.state === "undefined" ||
      hospitalDatas.state.trim() === "" ||
      hospitalDatas.state.trim() === null
    ) {
      isValid = false;
      errors["state"] = "Enter region";
      if (!focussed) {
        street.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["location"] === "undefined" ||
      input["location"].trim() === "" ||
      input["location"].trim() === null
    ) {
      isValid = false;
      errors["location"] = "Enter city";
      if (!focussed) {
        location.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["street"] === "undefined" ||
      input["street"].trim() === "" ||
      input["street"].trim() === null
    ) {
      isValid = false;
      errors["street"] = "Enter street name ";
      if (!focussed) {
        street.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["pincode"] === "undefined" ||
      input["pincode"].trim() === "" ||
      input["pincode"].trim() === null
    ) {
      isValid = false;
      errors["pincode"] = "Enter zipcode";
      if (!focussed) {
        pincode.current.focus();
        focussed = true;
      }
    }

    


        if (
      hospitalDatas.website_url !== "" &&
      hospitalDatas.website_url !== null
    ) {
      var regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      var pattern = new RegExp(regex);
      if (!pattern.test(hospitalDatas.website_url)) {
        isValid = false;
        errors["website_url"] = "Enter a valid url.";
        if (!focussed) {
          website_url.current.focus();
          focussed = true;
        }
      }
    }

   


    if (
      typeof input["primary_email"] !== "undefined" &&
      input["primary_email"].trim() !== "" &&
      input["primary_email"].trim() !== null
    ) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["primary_email"])) {
        isValid = false;
        errors["primary_email"] = "Enter a valid email address.";
        if (!focussed) {
          primary_email.current.focus();
          focussed = true;
        }
      }
    }

  
    let text = /^[0-9]+$/;
    if (
      hospitalDatas &&
      hospitalDatas.established_year &&
      hospitalDatas.established_year !== null &&
      hospitalDatas.established_year !== ""
    ) {
      if (hospitalDatas.established_year.length === 4) {
        if (hospitalDatas.established_year !== 0) {
          if (
            hospitalDatas.established_year !== "" &&
            !text.test(hospitalDatas.established_year)
          ) {
            isValid = false;
            errors["established_year"] = "Enter Numeric Values Only";
            if (!focussed) {
              established_year.current.focus();
              focussed = true;
            }
          }

          if (hospitalDatas.established_year.length !== 4) {
            isValid = false;
            errors["established_year"] = "Enter year in proper format";
            if (!focussed) {
              established_year.current.focus();
              focussed = true;
            }
          }
          var current_year = new Date().getFullYear();
          if (
            hospitalDatas.established_year < 1600 ||
            hospitalDatas.established_year > current_year
          ) {
            isValid = false;
            errors["established_year"] =
              "Year should be in range 1600 to current year";
              if (!focussed) {
                established_year.current.focus();
                focussed = true;
              }
          }
        } else {
          isValid = false;
          errors["established_year"] = "Year should be in proper format";
          if (!focussed) {
            established_year.current.focus();
            focussed = true;
          }
        }
      } else {
        isValid = false;
        errors["established_year"] = "Year should be in proper format";
        if (!focussed) {
          established_year.current.focus();
          focussed = true;
        }
      }
    }

   
    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };
  /*Handle Signup */
  const callSignUpWithoutFormData = () => {
    let dataToSend={}
    if(hospitalDatas.regn_number!==null&&hospitalDatas.regn_number!==""){
    dataToSend = {
      name: hospitalDatas.name,
      country_code:"356",
      mobile_number: hospitalDatas.phone.mobile_number,
      email: hospitalDatas.email,
      country: hospitalDatas.country,
      state: hospitalDatas.state,
      location: hospitalDatas.location,
      street: hospitalDatas.street,
      pincode: hospitalDatas.pincode,
   accreditation: hospitalDatas.accreditation,
      reg_number: hospitalDatas.regn_number,
      established_year: hospitalDatas.established_year,
      website: hospitalDatas.website_url,
      password: hospitalDatas.password,
      gst: hospitalDatas.gst,
      description: hospitalDatas.description,
      emergency_number: hospitalDatas.emergency.mobile_number
       
    };
  }
  else{
    dataToSend = {
      name: hospitalDatas.name,
      mobile_number: hospitalDatas.phone.mobile_number,
      country_code:"356",
      email: hospitalDatas.email,
      country: hospitalDatas.country,
      state: hospitalDatas.state,
      location: hospitalDatas.location,
      street: hospitalDatas.street,
      pincode: hospitalDatas.pincode,
   accreditation: hospitalDatas.accreditation,
      established_year: hospitalDatas.established_year,
      website: hospitalDatas.website_url,
      password: hospitalDatas.password,
      gst: hospitalDatas.gst,
      description: hospitalDatas.description,
      emergency_number: hospitalDatas.emergency.mobile_number
       
  }
}

    axios
      .post(`${APIURL}/api/v1/service-provider/register/`, dataToSend, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setSuccessSubmitMsg("success");
          setSuccessSubmit(true);
          sessionStorage.setItem("reg",1)

          //history.push('/success')
        } else {
          setErrorSubmitMsg(res.data.message);
          setErrorSubmit(true);

        
        }
      })
      .catch((err) => {
        setErrorSubmitMsg(`Error in ${hospitalDatas.healthcare_type} Registration. Please try again!`);
        setErrorSubmit(true);
      });
  };

  const callSignUpWithFormData = () => {
    const user = {
      name: hospitalDatas.name,
      mobile_number: hospitalDatas.phone.mobile_number,
      code:"356",
      email: hospitalDatas.email,
      country: hospitalDatas.country,
      state: hospitalDatas.state,
      location: hospitalDatas.location,
      street: hospitalDatas.street,
      latitude: hospitalDatas.latitude,
      longitude: hospitalDatas.longitude,
      pincode: hospitalDatas.pincode,
      accreditation: hospitalDatas.accreditation,
      reg_number: hospitalDatas.regn_number,
      established_year: hospitalDatas.established_year,
      website: hospitalDatas.website_url,
      health_care_type: hospitalDatas.healthcare_type,
      password: hospitalDatas.password,
      gst: hospitalDatas.gst,
      description: hospitalDatas.description,
      emergency_number: hospitalDatas.emergency.mobile_number
        
    };

    if (user.emergency_number === null) {
      formData.append("emergency_number", "");
    } else {
      formData.append("emergency_number", user.emergency_number);
    }

    if (user.established_year === null) {
      formData.append("established_year", "");
    } else {
      formData.append("established_year", user.established_year);
    }

    

    if (user.gst === "") {
      formData.append("gst", "");
    } else {
      formData.append("gst", user.gst);
    }

    if (user.description === "") {
      formData.append("description", "");
    } else {
      formData.append("description", user.description);
    }

    if (user.accreditation === "") {
      formData.append("accreditation", "");
    } else {
      formData.append("accreditation", user.accreditation);
    }  if (user.website === "") {
      formData.append("website", "");
    } else {
      formData.append("website", user.website);
    }  if (user.photo === "") {
      formData.append("photo", "");
    } else {
      formData.append("photo", img);
    }
    formData.append("name", user.name);
    formData.append("mobile_number", user.mobile_number);
    formData.append("country_code","356")
   if (user.reg_number !== "") {
    formData.append("reg_number", user.reg_number);
   }
    formData.append("email", user.email);
    formData.append("country", user.country);
    formData.append("state", user.state);
    formData.append("location", user.location);
    formData.append("street", user.street);
    formData.append("password", user.password);
    formData.append("pincode", user.pincode);
    axios
      .post(`${APIURL}/api/v1/service-provider/register/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setSuccessSubmitMsg("success");
          setSuccessSubmit(true);
        
          //history.push('/success')
        } else {
          setErrorSubmitMsg(res.data.message);
          setErrorSubmit(true);

          //history.push('/error')
        }
      })
      .catch((err) => {
        setErrorSubmitMsg(`Error in ${hospitalDatas.healthcare_type} Registration. Please try again!`);
        setErrorSubmit(true);
      });
  };

  const handleSignup = () => {
    if (formDataStatus) {
      callSignUpWithFormData();
    } else {
      callSignUpWithoutFormData();
    }
  };

  
  const selectRegion = (val) => {
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
    setError((currentstate) => ({
      ...currentstate,
      "state":"",
    }));
  };

  const validatePhoneInput = (value,) => {
  
        if (value.length !== 11&&value.length !== 3) {
          setIsValidnum(false)
            return "Invalid phone number";

        }
        else {
          setIsValidnum(true)
            return true
        }
    

}
const validatePhoneInputE = (value,) => {
  
      if (value.length !== 11&&value.length !== 3) {
        setIsValidEnum(false)
          return "Invalid phone number";

      }
      else {
        setIsValidEnum(true)
          return true
          
      }
  

}
  const handlePhoneInput = (value, data, event, formattedValue) => {
    const rawPhone = value.replace(/\D/g, '');

    const dialcode = value.slice(0, data.dialCode.length);
    
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      phone: {
        dialcode: dialcode,
        rawphone: rawPhone,
        mobile_number: value,
      },
    }));
    setError((currentstate) => ({
      ...currentstate,
      "phone":"",
    }));
  };
 
  const handleEmergencyInput = (value, data, event, formattedValue) => {
    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      emergency: {
        dialcode: dialcode,
        rawphone: rawPhone,
        mobile_number: value,
      },
    }));
    setError((currentstate) => ({
      ...currentstate,
      "emergency":"",
    }));
  };

 

  const handleCheckExistingRegis = (e) => {
    setIsValidRegis(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        regn_number: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                regn_number: `This Registration number already Exists`,
              }));
              setIsValidRegis(false);
            } else {
              setIsValidRegis(true);
            }
          } else {
            setIsValidRegis(true);
          }
        })
        .catch((err) => {});
    }
  };
  const handleCheckExisting = (e) => {
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };
  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {
      const rawPhone = hospitalDatas.phone.rawphone;

      const dialcode = hospitalDatas.phone.dialcode;
      setError((currentstate) => ({
        ...currentstate,
        phone: "",
      }));

      const data = {
        code: dialcode,
        mobile_number: rawPhone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                phone: "Phone number already registered!",
              }));
              setIsValidPhone(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
  };

  

 
  const authUrl = AuthUtil.validateAuth();
  //When authentication is valid.
  if (authUrl) {
    return <Navigate to={authUrl} state={{ from: location }} />;
  }

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  return (
    <div>
    
      <br/>
    <div className="">
      <div className="">        
          <div className="form-content">
            <div className="form-reg-items">
              
     
              <form className="reg-form hosp-form" onSubmit={handleContinue}>
                <div className="reg-form-div">
                  <br/>
                  <br/>
                <h2 className="form-margin"> Service Provider Registration </h2>
                <br/>
                  <div className="first-row">
                    <div className="custom-file">
                      {previewOn === false ? (
                        <div className="pic-container">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile"
                            onChange={uploadImage}
                          />
                          {invalidPic && <p className="error">{invalidPic}</p>}
                          <label className="" htmlFor="validatedCustomFile">
                            <p>Upload Profile Picture</p>
                            <div className="camera-icon">
                              <i className="fa fa-camera camera-icon"></i>
                            </div>
                          </label>
                          {/*
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label> */}
                        </div>
                      ) : (
                        <div>
                          <div onClick={() => {
                              setDeleteShow(true);
                            }}>
                            <i
                              className="fa fa-window-close circular-button"
                            ></i>
                          </div>
                          <img
                            src={hospitalDatas.img_url}
                            alt="Hospital Picture"
                            className="avatar"
                          />
                        </div>
                      )}
                    </div>

                    {/*
 <div className="custom-file">
                <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={preview_image}/>
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label>
 
   <img id="output_image" className="avatar"/>
 </div>  */}
                  </div>

                  <h6 className="sub-headings"style={{fontSize:"18px"}}> Registration Details  </h6>
                  <br/>
                  <div className="item item-left-row">
                    <label className="">* Service Provider Name: </label>{" "}
                    <input
                      ref={name}
                      className="form-control"
                      type="text"
                      name="name"
                      value={hospitalDatas.name}
                      placeholder=""
                      onChange={handleChange}
                      maxLength="200"
                    />
                    {error.name ? (
                      <div className="error-validation-msg">
                        {error.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="item item-left-row">
                    <label className="">* Email ID: </label>{" "}
                    <input
                      ref={email}
                      className="form-control"
                      type="email"
                      name="email"
                      value={hospitalDatas.email}
                      placeholder=""
                      onChange={handleChange}
                      maxLength="30"
                      onBlur={handleCheckExisting}
                    />
                    {error.email ? (
                      <div className="error-validation-msg">
                        {error.email}
                      </div>
                    ) : null}
                  </div>
                  
                  <div className="item item-left-row">
                    <label className="">* Password: </label>{" "}
                    <div style={{display:"flex", backgroundColor:"#F7F7F7", height:"44px"}}>
                    <input
                      ref={password}
                      className="pswd"
                      type={passwordType}
                      name="password"
                      placeholder=""
                      onChange={handleChange}
                      maxLength="30"
                    />
                    <span style={{float:"right"}}>
         
         { passwordType==="password"? <i style={{paddingTop:"60%"}} onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> :<i style={{paddingTop:"60%"}} onClick={togglePasswordVisiblity} className="fa fa-eye"></i> }
         </span></div>
                    {error.password ? (
                      <div className="error-validation-msg">
                        {error.password}
                      </div>
                    ) : null}
                  </div>
                  <div className="item item-left-row">
                    <label className="">* Confirm Password: </label>{" "}
                    <div style={{display:"flex", backgroundColor:"#F7F7F7", height:"44px"}}>
                    <input
                      ref={confirmpassword}
                      className="pswd"
                      type={passwordType1}
                      name="confirmpassword"
                      placeholder=""
                      onChange={handleChange}
                      maxLength="30"
                    />
                     <span style={{float:"right"}}>
         
         { passwordType1==="password"? <i style={{paddingTop:"60%"}} onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> :<i style={{paddingTop:"60%"}} onClick={togglePasswordVisiblity1} className="fa fa-eye"></i> }
         </span></div>
                    {error.confirmpassword ? (
                      <div className="error-validation-msg">
                        {error.confirmpassword}
                      </div>
                    ) : null}
                  </div>

                  <div className=" phone-input item item-left-row">
                    <label className="">* Phone Number: </label>
                    
                      <PhoneInput
                        inputProps={{}}
                        country={"mt"}
                        onlyCountries={["mt"]}
                        countryCodeEditable={false}
                        value={hospitalDatas.phone.mobile_number}
                        onChange={handlePhoneInput}
                        onBlur={handleCheckExistingPhone}
                        isValid={validatePhoneInput}
                        maxLength={11}
                        
                      />
                    

                    {error.phone ? (
                      <div className="error-validation-msg">{error.phone}</div>
                    ) : null}
                  </div>

                  <div className="phone-input item item-left-row">
                    <label className="">Emergency Number: </label>
                   
                      <PhoneInput
                        ref={emergency}
                        inputProps={{}}
                        country={"mt"}
                        onlyCountries={["mt"]}
                        countryCodeEditable={false}
                        value={hospitalDatas.emergency_number}
                        onChange={handleEmergencyInput}
                        isValid={validatePhoneInputE}
                        maxLength={11}
                         // disableDropdown={true}
                      />
                    {error.emergency ? (
                      <div className="error-validation-msg">
                        {error.emergency}
                      </div>
                    ) : null}
                  </div>

                

                  <div className="divider"></div>
                 

                 
                  <h6 className="sub-headings" style={{width:"95%",fontSize:"18px"}}>Address </h6>
<div className="item item-left-row">
                    <label className="">* Street Name: </label>{" "}
                    <input
                      ref={street}
                      className="form-control "
                      type="text"
                      name="street"
                      placeholder=""
                      value={hospitalDatas.street}
                      onChange={handleChange}
                      maxLength="200"
                     
                    />
                    {error.street ? (
                      <div className="error-validation-msg">{error.street}</div>
                    ) : null}
                  </div>
                  <div className="item item-left-row">


                    <label className="">* City: </label>{" "}
                    <input
                      ref={location}
                      className="form-control"
                      type="text"
                      name="location"
                      placeholder=""
                      value={hospitalDatas.location}
                      onChange={handleChange}
                      maxLength="200"
                     
                    />
                    {error.location ? (
                      <div className="error-validation-msg">
                        {error.location}
                      </div>
                    ) : null}
                  </div>
                  
                  <div className="region-selector item item-left-row">
                    <label className=""> * Region: </label>
                    
                    <RegionDropdown
                      ref={region}
                      country={hospitalDatas.country}
                      value={hospitalDatas.state}
                      name="state"
                      onChange={(val) => selectRegion(val)}
                      // disabled={true}
                    />
                    {error.state ? (
                      <div className="error-validation-msg">{error.state}</div>
                    ) : null}
                   
                  </div>

                
                  <div className="country-selector item item-left-row">
                    <label className="">Country: </label>
                    <input
                      ref={country}
                      className="form-control disabled-input"
                      type="text"
                      name="country"
                      placeholder=""
                      value={hospitalDatas.country}
                      onChange={handleChange}
                      maxLength="150"
                      disabled={true}
                    />
                   
                    {error.country ? (
                      <div className="error-validation-msg">
                        {error.country}
                      </div>
                    ) : null}
                  </div>


                 
                  <div className="item item-left-row">
                    <label className="">* Zipcode: </label>{" "}
                    <input
                      ref={pincode}
                      className="form-control "
                      type="text"
                      name="pincode"
                      placeholder=""
                      value={hospitalDatas.pincode}
                      onChange={handleChange}
                      maxLength="50"
                     
                    />
                    {error.pincode ? (
                      <div className="error-validation-msg">
                        {error.pincode}
                      </div>
                    ) : null}
                  </div>

                  <div className="divider"></div>

                  <h6 className="sub-headings" style={{width:"95%",fontSize:"18px"}}> Service Provider Details </h6>

                  <div className="item item-left-row">
                  <label className="">Accreditation: </label>
                    {" "}
                    <input
                      className="form-control"
                      type="text"
                      value={hospitalDatas.accreditation}
                      name="accreditation"
                      placeholder=""
                      onChange={handleChange}
                    
                    />
                   
                  </div>

                  <div className="item item-left-row">
                  <label className="">Registration Number: </label>
                    {" "}
                    <input  
                    ref={regn_number}
                      className="form-control"
                      type="text"
                      value={hospitalDatas.regn_number}
                      name="regn_number"
                      placeholder=""
                      onChange={handleChange}
                      maxLength="30"
                      onBlur={handleCheckExistingRegis}

                    />
                    {error.regn_number ? (
                      <div className="error-validation-msg">
                        {error.regn_number}
                      </div>
                    ) : null}
                  </div>
                 
                  <div className="item item-left-row">
                  <label className="">Website URL: </label>
                    {" "}
                    <input
                      ref={website_url}
                      className="form-control"
                      type="text"
                      value={hospitalDatas.website_url}
                      name="website_url"
                      placeholder="https://www.example.com"
                      onChange={handleChange}
                      maxLength="150"
                    />
                     {error.website_url ? (
                      <div className="error-validation-msg">
                        {error.website_url}
                      </div>
                   ) : null}
                  </div>

                  <div className="item item-left-row">
                  <label className="">Established Year: </label>
                  {" "}
                    <input
                      ref={established_year}
                      className="form-control"
                      type="number"
                      value={hospitalDatas.established_year}
                      name="established_year"
                      placeholder=""
                      onChange={handleChange}
                      maxLength="30"
                    />
                  {error.established_year ? (
                      <div className="error-validation-msg">
                        {error.established_year}
                      </div>
                   ) : null}
                  </div>

                  <div className="item item-left-row">
                  <label className="">VAT: </label>
                    {" "}
                    <input
                      className="form-control"
                      type="text"
                      value={hospitalDatas.gst}
                      name="gst"
                      placeholder=""
                      onChange={handleChange}
                      maxLength="15"
                    />
                  </div>

                  <div className="item item-left-row">
                  <label className="">Description: </label>
                    {" "}
                    <textarea
                      rows="3"
                      className="text-area-about"
                      placeholder=""
                      name="description"
                      onChange={handleChange}
                    
                    />
                  </div>

                  <div className="divider"></div>
                 
                </div>
                <div className="form-button">
                  <button
                    id="submit"
                    type="submit"
                    className="btn btn-primary formFieldButton"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {errorSubmitMsg !== null ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : null}

      {successSubmitMsg === "success" ? (
        <SubmitSuccessPopup
          show={successSubmit}
          onHide={() => setSuccessSubmit(false)}
          setemailshow={email}
        />
      ) : null}

      {sizeShow ? (
        <SizePopUp
          show={sizeShow}
          onHide={() => {
            setSizeShow(false);
          }}
        />
      ) : null}

      {isMapError ? (
        <MapError
          show={isMapError}
          onHide={() => {
            setIsMapError(false);
          }}
          />
      ) : null}

      {isMapSucess ? (
        <MapSuccess
          show={isMapSucess}
          onHide={() => {
            setIsMapSucess(false);
          }}
          />
      ) : null}

      {uploadShow ? (
        <LoadPopUp
          show={uploadShow}
          onHide={() => {
            setUploadShow(false);
          }}
        />
      ) : null}

      {validShow ? (
        <ValidPopUp
          show={validShow}
          onHide={() => {
            setValidShow(false);
          }}
        />
      ) : null}

      {contentShow ? (
        <ContentPopUp
          show={contentShow}
          onHide={() => {
            setContentShow(false);
          }}
        />
      ) : null}

      {confirmDelete ? (
        <ConfirmPopUp
          show={confirmDelete}
          onHide={() => {
            setDeleteShow(false);
          }}
        />
      ) : null}

     
     <br/>
   
    </div>
  );
}

export default  ServiceProviderRegister;

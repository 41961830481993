import React, { useState, useContext,useEffect } from "react";
import "./managercard.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import AddDoctor from "../doctor/AddDoctor";
import AddBdo from "../BDO/addbdo";
import {
  
  Tooltip,
 
  OverlayTrigger,

} from "react-bootstrap";
function BdoCard(props) {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
  const [action, setAction] = useState("")
  const [successShow, setSuccessShow] = useState(false);
  const alert = useAlert()
  const [list, setList] = useState({})
  const [modalShow, setModalShow] = useState(false);
  const create_link = `/create-${props.user}`;
  const [show, setShow] = useState(false)
  const user = props.user
  const change_password_link = `/changepassword-${props.user}`;
  const update_link = `/update-${props.user}`;
  const [searchTerm, setSearchTerm] = useState("");
const [bdoList,setBdoList]=useState(props.bdoList)
  const handleConfirm = () => {
    closeConfirmationPopup()
    if (action === "del") {
      handleDelete();
    }
    else {
      handleSuspend();
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  
    }
    useEffect(() => {
      let filteredList = [...props.bdoList]; // Clone the original list to avoid mutating it
    
      console.log(filteredList, "initial list");
    
      if (searchTerm === "") {
        setBdoList(props.bdoList);
      } else {
        filteredList = filteredList.filter(value => {
          return (
            value.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            value.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
        setBdoList(filteredList);
        console.log(filteredList, "filtered list");
      }
    }, [searchTerm, props.bdoList]);

  const handleCancel = () => {
    closeConfirmationPopup()
  };


  const handleDeletePopUp = () => {


    setShowConfirmation(true);
  }
  const handleSuspendPopUp = () => {

    setAction("sus")
    setShowConfirmation(true);

  };



  const handleSuspend = (e) => {
    //	e.preventDefault();

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );


    const data1 = {
      is_suspended: !list.is_suspended
    }

    axios.put(`${APIURL}/api/v1/service-provider/${props.user === "pharmacyuser" ? "pharmacy-user-alter" : props.user === "nurse" ? "nurse-detail" : props.user === "carer" ? "carer-detail" : props.user}/${list.id}/`, data1, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Token " + v2
      },
    })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success(`${list.is_suspended ? "Authorised" : "Suspended"} Successfully!!`);
          props.handle()

        } else {
          alert.error("Error occured. Try again!")
        }


      })
      .catch((err) => {
        alert.error("Error occured. Try again!")
        // setSubmitMsg("error");
      });

  };
  const handleDelete = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");


    axios
      .post(
        `${APIURL}/api/v1/service-provider/hospital-user-delete/?user_id=${list.id}&role=${props.user}`,
        {},
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Deleted successfully")
          props.handle();
        } else {
          alert.error("Error occured. Try again!")
        }



      })
      .catch((err) => {
      });
  };
  return (

    <>
    <div className="row" style={{width:"100%",display:"flex" ,paddingLeft:"1%"}}>
      <div className="col-9 ">
        <div  style={{display:"flex",backgroundColor:"rgba(230, 238, 248, 1)",border: "0.5px solid rgba(217, 217, 217, 1)",borderRadius:"12px",height:"44px",paddingRight:"2%"}}>
      <svg xmlns="http://www.w3.org/2000/svg" style={{paddingTop:"8px"}} width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="m19.485 20.154l-6.262-6.262q-.75.639-1.725.989t-1.96.35q-2.402 0-4.066-1.663T3.808 9.503T5.47 5.436t4.064-1.667t4.068 1.664T15.268 9.5q0 1.042-.369 2.017t-.97 1.668l6.262 6.261zM9.539 14.23q1.99 0 3.36-1.37t1.37-3.361t-1.37-3.36t-3.36-1.37t-3.361 1.37t-1.37 3.36t1.37 3.36t3.36 1.37"/></svg>
      <input
                    
                    className=""
                    style={{border:"none",width:"98%",backgroundColor:"rgba(230, 238, 248, 1)"}}
                    type="text"
                    maxLength="100"
                    placeholder="Search name, ID"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                </div>
                 </div>
                <button  onClick={() => {
            props.user === "doctor" ?
            navigate('/adddoctor') :
            props.user === "bdo" ? navigate('/add-bdo') :
              props.user === "carer" ?
                navigate('/add-carer') :
                props.user === "pharmacyuser" ?
                  navigate('/add-Pharm-user') :
                  props.user === "nurse" ?
                    navigate('/add-nurse') : navigate('/add-manager')
          }
          }style={{backgroundColor:"rgba(43, 105, 163, 1)",color:"white",border: "0.5px solid rgba(217, 217, 217, 1)",borderRadius:"12px",height:"44px"}}className="col-3 ">ADD NEW USERS</button>

     
    </div>
      <div className="row" style={{marginTop:"2%"}}>
    

        {bdoList.length > 0

          ? bdoList.map((data, index) => {

            return (
              <div className="col-4">

                <div onClick={() => {
                  setList(data)
                }} key={props.key} className="nurses-card" style={{backgroundColor:"white", }}>
                  <div style={{boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",borderRadius:"10px"}}>
                  <div className="row" style={{width:"100%",marginLeft:"0%",backgroundColor:data.user_name||data.username?"rgba(43, 105, 163, 1)":"rgba(102, 181, 255, 0.58)" , padding:"1%",borderRadius:"10px 10px 0px 0px",height:"40px",paddingTop:"1.5%"}}>
                     <div  className="col-3 rect-head" > 
                      <svg  xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 2048 1536"><path fill="currentColor" d="M1024 1003q0-64-9-117.5t-29.5-103t-60.5-78t-97-28.5q-6 4-30 18t-37.5 21.5T725 733t-43 14.5t-42 4.5t-42-4.5t-43-14.5t-35.5-17.5T482 694t-30-18q-57 0-97 28.5t-60.5 78t-29.5 103t-9 117.5t37 106.5t91 42.5h512q54 0 91-42.5t37-106.5M867 483q0-94-66.5-160.5T640 256t-160.5 66.5T413 483t66.5 160.5T640 710t160.5-66.5T867 483m925 509v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23m0-260v-56q0-15-10.5-25.5T1756 640h-568q-15 0-25.5 10.5T1152 676v56q0 15 10.5 25.5T1188 768h568q15 0 25.5-10.5T1792 732m0-252v-64q0-14-9-23t-23-9h-576q-14 0-23 9t-9 23v64q0 14 9 23t23 9h576q14 0 23-9t9-23m256-320v1216q0 66-47 113t-113 47h-352v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96H640v-96q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v96H160q-66 0-113-47T0 1376V160Q0 94 47 47T160 0h1728q66 0 113 47t47 113"/></svg>&nbsp;&nbsp;&nbsp;&nbsp;<b >{data.id}</b></div>
                     <div className="col-5"></div>
                     <div className="col-4"style={{float:"right",display:"flex"}}>
                      
                     <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">{data.is_suspended?"suspended":"authorised"}</Tooltip>}>
                      <div style={{cursor:"pointer"}} onClick={() => {
                            setList(data)
                            setAction("sus")
                            handleSuspendPopUp();
                          }} className="round-head">

                      <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 20 20"><path fill={data.is_suspended ?"red":"rgb(33, 70, 107)"} d="M5.25 3A2.25 2.25 0 0 0 3 5.25v9.5A2.25 2.25 0 0 0 5.25 17h9.5A2.25 2.25 0 0 0 17 14.75v-9.5A2.25 2.25 0 0 0 14.75 3z"/></svg>
                        </div></OverlayTrigger>&nbsp;
                        <OverlayTrigger overlay={<Tooltip style={{  }} id="tooltip-disabled">Edit details</Tooltip>}>
                        <div className="round-head">
                      <Link onClick={() => {
                        setList(data)
                      }} to={update_link} state={{ id: data.id }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="rgb(33, 70, 107)" d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006z"/><path fill="rgb(33, 70, 107)" d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2"/></svg></Link></div></OverlayTrigger>&nbsp;
                      <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Delete</Tooltip>}>
                      <div style={{cursor:"pointer"}} onClick={() => {
                          setList(data)
                          setAction("del"); handleDeletePopUp();
                        }} className="round-head">
                     <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 32 32"><path fill="rgb(33, 70, 107)" d="M13.5 6.5V7h5v-.5a2.5 2.5 0 0 0-5 0m-2 .5v-.5a4.5 4.5 0 1 1 9 0V7H28a1 1 0 1 1 0 2h-1.508L24.6 25.568A5 5 0 0 1 19.63 30h-7.26a5 5 0 0 1-4.97-4.432L5.508 9H4a1 1 0 0 1 0-2zM9.388 25.34a3 3 0 0 0 2.98 2.66h7.263a3 3 0 0 0 2.98-2.66L24.48 9H7.521zM13 12.5a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0v-10a1 1 0 0 1 1-1m7 1a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0z"/></svg>
                      </div></OverlayTrigger>

                     </div>

                     

                  </div>
                  <div className="row" style={{paddingTop:"1%", paddingLeft:"2%"}}>
                  <h6 className="col-8" style={{ textTransform: "capitalize",fontSize:"16px",color:"rgba(33, 70, 107, 1)" }}> {data.name} </h6>
                  <div className="col-4">
                  <Link  to={(data.user_name||data.username)?change_password_link:create_link} state={{ name: data.name,id: data.id }}>  <button style={{backgroundColor:(data.user_name||data.username)?"rgba(230, 238, 248, 1)":"rgba(43, 105, 163, 1)",color:(data.user_name||data.username)?"rgba(43, 105, 163, 1)":"white",border: "0.5px solid rgba(217, 217, 217, 1)",borderRadius:"5px",padding:"1%", fontSize:"11px", width:"96%",paddingTop:"2%",paddingBottom:"2%"}}>{(data.user_name||data.username)? " Change password ":" Credentials "}</button></Link>
                  </div>
                  </div>
                  <div className="card-user-details">
                  {(props.user !== "doctor" && props.user !== "bdo" && props.user !== "manager") ?  <span style={{ textTransform: "capitalize"}}>{data.qualification}</span> : data.qualification && data.qualification !== null && data.qualification.map((item) => {
                        return (

                          <span style={{ textTransform: "capitalize"}}>{item},</span>

                        );
                      })}<br/>
                  {props.user !== "pharmacyuser" && <> 
                  
                         <span style={{ textTransform: "capitalize"}}>{data.specializations}</span>  <br/>


                     
                    {data.department!=="" &&
                        <span style={{ textTransform: "capitalize" }}>{data.department}{" "}</span>
                      }</>}<br/>
                     {(data.user_name||data.username)?<> <span style={{color:"rgb(33, 70, 107)"}}className="">Username:&nbsp;<span style={{color:"var(--bs-body-color)"}}>{data.user_name?data.user_name:data.username} </span></span><br/></>:<br/>}<br/>
                     <span style={{fontSize:"12px"}}> <i
                          className="fa fa-envelope"
                          aria-hidden="true"
                        ></i>{" "}
                        {data.email}</span>
                   
                       
                       <span style={{fontSize:"12px", float:"right",paddingRight:"2%"}}><i className="fa fa-phone fa-sm" aria-hidden="true"></i>
                       +{data.phone_number}</span>
                  </div>
                 
                    
                
                       
               
                  </div>
                </div>
              </div>


            );
          })
          :<h5 style={{ color: "red", margin: "20px" }}>The requested user does not appear in the list!! </h5>
}

      </div>
      {showConfirmation && (
        <SubmitConfirmationPopup
          message={action === "del" ? " Please confirm to delete the user ID " + list.id : list.is_suspended ? (" Please confirm to authorise the user ID " + list.id) : ("Please confirm to suspend the user ID " + list.id)}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}


    </>
  );
}

export default BdoCard;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import axios from "axios";
import { APIURL,TZ } from "../../Global";
function ResetPassword(props) {

  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [successSubmitMsg, setSuccessSubmitMsg] = useState(null);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [error, setError] = useState({
    password: "",
    confirm_password: ''
  });

  const location = useLocation();
  const otpValue = location.state.otp;
  const user = location.state.username;
  
  const togglePasswordVisiblity = (e) =>
  {
  
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const togglePasswordVisiblity1 = (e) => {
  
    if (passwordType1 === "password") {
      setPasswordType1("text")
      return;
    }
    setPasswordType1("password")
  }
  const SubmitSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}> {successSubmitMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Link to='/login'> <Button className='btn btn-primary btn-col' >Goto Login </Button></Link>
        </Modal.Footer>
      </Modal>
    );
  }

  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {errorMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  const validate = () => {
    let errors = {};
    let isValid = true;
    setError({});
    if (typeof newPassword !== "undefined") {

      // let pattern = new RegExp(/[A-Z]/g);
      let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*&#^])([a-zA-Z0-9!@#$%^&*]{8,})$/);
      if (!pattern.test(newPassword)) {
        isValid = false;
        errors["password"] ="The password should be 8-30 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$.!#^%*?&_). Spaces are not allowed.";
      }


    }
    if (!newPassword || newPassword === "") {
      isValid = false;
      errors["password"] = "Enter your password";
    }

    if (!confirmPassword || confirmPassword === "" || confirmPassword === null) {
      isValid = false;
      errors["confirm_password"] = "Enter confirm password";
    }

    // if (newPassword && typeof newPassword !== "undefined") {
    //   if (newPassword.length > 0 && newPassword.length < 8) {
    //     isValid = false;
    //     errors["password"] = "Must contain atleast 8 characters";
    //   }
    // }

    if (typeof newPassword !== "undefined" && newPassword !== "" && typeof confirmPassword !== "undefined" && confirmPassword !== "" && confirmPassword !== null) {

      if (newPassword !== confirmPassword) {
        isValid = false;
        errors["confirm_password"] = "Passwords don't match";
      }
    }

    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));

    return isValid;
  }


  const callResetPw = () => {
    const dataToSend = {
      username: user,
      otp: otpValue,
      password: newPassword
    }
    axios.put(`${APIURL}/api/v1/account/forgot-password/?route=${location.state.route}`, dataToSend, {
      headers: {


        'Content-Type': 'application/json'
      }


    })

      .then(res => {

        if (res.data.status === "success") {


          setSuccessSubmitMsg(res.data.message ? res.data.message : "Password successfully updated!");
          setSuccessSubmit(true);


        }
        else {

          setErrorMsg(res.data.message ? res.data.message : "Error in Password reset");
          setErrorSubmit(true);

        }

      })
      .catch(err => {
        setErrorMsg("Error in Password Reset");
        setErrorSubmit(true);

      })


  }
  const handleResetPassword = (e) => {
    e.preventDefault();
    if (validate()) {

      callResetPw();



    }
  }
  
  


  return (<><div className="wrapper-content">
    <div className="forgot-password-page">









      <form className="login-form forgot-password-form" onSubmit={handleResetPassword}>
        <h4 className="form-heading title-of-tasks"> Reset Password </h4>


        <div className="form-group ">
           <div className="form-control" style={{backgroundColor:"rgb(232, 240, 254)"}}>
            
           <input className="box1" type={passwordType} maxLength='30' placeholder="New Password" value={newPassword}onChange={(e) => {
          e.preventDefault();
          setNewPassword(e.target.value)
        }} />  
           
           <span style={{float:"right"}}>
           
                     { passwordType==="password"? <i  onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> :<i onClick={togglePasswordVisiblity} className="fa fa-eye"></i> }
                     </span>
            
            </div>
            {error.password ?<div className="error-validation-msg"> {error.password}</div>: null} 
         </div>



        {/* < div className=" " style={{color:"grey"}}> {user}</div>*/}

      

       


        <br />
        <div className="form-group ">
            <div className="form-control " style={{backgroundColor:"rgb(232, 240, 254)"}}>
           <input className="box1" type={passwordType1} maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={(e) => {
          e.preventDefault();
          setConfirmPassword(e.target.value)
        }} />
           <span style={{float:"right"}}>
           
           { passwordType1==="password"? <i  onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> :<i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i> }
           </span>
       
        </div> 
        {error.confirm_password ?<div className="error-validation-msg"> {error.confirm_password}</div>: null}                            
         </div>



      







         <br />  <br />
        <div style={{textAlign: 'center'}}> 
        <button className="btn btn-primary btn-col"  >Reset </button>
        </div>

      </form>


      {successSubmitMsg !== "" ?
        <SubmitSuccessPopup
          show={successSubmit}
          onHide={() => {
            setSuccessSubmit(false);
            setSuccessSubmitMsg('')
          }}

        /> : null}


      {errorMsg !== null ?
        <ErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}

        /> : null}

    </div>
  </div>

  </>

  )


}
export default ResetPassword;
import React ,{useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import './profile.css'
import { useAlert } from 'react-alert';

function GenderUpdate(props) {
    const [gender,setGender] = useState(props.gender !== " " ? props.gender : " ");
    const alert = useAlert()
    const [error,setError]=useState({
        gender:''
       });

       const  handleGenderRadioButton=(e)=>{
        let genderOpted = e.target.value;
        setGender(genderOpted)
       }

       const  validate = () => {
        //let input = email;
        let errors = {};
        let isValid = true;
  
        if (gender==="" ) {
          isValid = false;
          errors["gender"] = "Select a gender";
        }
    
        setError(currentstate=>({
          ...currentstate,...errors
        }))
    
        return isValid;
    }

    const handleContinue =async(e) => {
      e.preventDefault();
  
      if( validate() ){
        submitGender()
      }
    }

       const submitGender = (e) =>{
        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '') 
      
         const data = {
           gender : gender
         }
      
         axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {
      
          "Authorization" : 'Token '+str  }})
        .then(res=>{
        
              
          if(res.data.status==="success"){

           alert.success("Updated Successfully")
           props.handle()
           setGender()
           
        
          }else{
            alert.error("Error in Data Submission. Please try again!")        
          }        
        })
        .catch(err=>{
          alert.error("Error in Data Submission. Please try again!")        
        })      
      }
      
    return (
        <div className='flex-phone'>
            <div className="form-control gender-div">

{/* <span>Gender:</span> */}
  <input 
     type="radio" 
     id="male" 
     name="gender" 
     value="male"
     checked={gender === "male"}
     onChange={handleGenderRadioButton}
    
         />
  <label htmlFor="male">Male</label>

  <input 
    type="radio"
    id="female"
     name="gender" 
     value="female"
     checked={gender === "female"}
    onChange={handleGenderRadioButton} />

  <label htmlFor="female">Female</label>

  <input 
      type="radio"
      id="other" 
      name="gender"
      value="other"
      checked={gender === "other"}
      onChange={handleGenderRadioButton} 
         />
  <label htmlFor="other">Other</label>

  {error.gender ? <div className="error-validation-msg"> {error.gender}</div> : null}
</div>

<div className="form-button">
                <button style={{marginLeft:'10px'}} onClick={handleContinue} className="btn btn-primary btn-col" >Update Gender</button>
            </div>           
        </div>
    )
}

export default GenderUpdate

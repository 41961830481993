import React, { useState, useEffect } from 'react';
import { ToggleButtonGroup } from 'react-bootstrap';
import { ToggleButton, Badge,Button, } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { DateRangePicker } from "rsuite";
import moment from 'moment';
import { useAlert } from "react-alert";
import isAfter from 'date-fns/isAfter';

function InventoryFilter(props) {
  const {fromDate,toDate,data,updateDateRange,isReturned, activeVal ,onApplyFilter, onApplyClear } = props;
  const [localFromDate, setLocalFromDate] = useState();
  const [localToDate, setLocalToDate] = useState();
  const [timePeriod, setTimePeriod] = useState("month")
  //const [fromDate, setFromDate] = useState("");
  const [show, setShow] = useState(false);
  //const [toDate, setToDate] = useState("");
  const [selectedValue, setSelectedValue] = useState(''); // You can set the initial selected value here
 
  const [showMonths, setShowMonths] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
  ];

  const generateYearOptions = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = 2023; year <= currentYear; year++) {
      years.push(String(year));
    }
    return years;
  };
  const Years = [ ...generateYearOptions()];

  useEffect(() => {
    if (activeVal === "active") {
      setRadioValue('1'); // Set Alive as selected
    } else if (activeVal === "deceased") {
      setRadioValue('2'); // Set Death as selected
    }
  }, [activeVal]);
  useEffect(() => {
    if (isReturned === "returned") {
      setRadioValue1('4'); // Set Returned as selected
    } else {
      setRadioValue1('3'); // Set Recieved as selected
    }
  }, [isReturned]);

  const toggleMonths = () => {
    setShowMonths(!showMonths);
    setSelectedValue("month")
    setShowYears(false)
    setShow(false)
  };

  const toggleYears = () => {
    
    setShowYears(!showYears);
    setSelectedValue("year")
    setShowMonths(false)
    setShow(false)
  };


  const handleChangeValue = (val,value) => {
    
        setSelectedValue(val);
       // registerData.splice(0, registerData.length + 1)
        setTimePeriod(val)
        if (val === 'yesterday') {
          setShowMonths(false)
          setShowYears(false)
          setShow(false)
          var today = new Date();
          var yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
        
          var formattedYesterdayDate = moment(yesterday).format('YYYY-MM-DD');
          
          setLocalFromDate(formattedYesterdayDate);
          setLocalToDate(formattedYesterdayDate);
        }
        if (val === 'week') {
          setShowMonths(false)
          setShowYears(false)
          setShow(false)
          // Calculate the start of the week (Sunday)
      var startOfWeek = moment().startOf('week').toDate();
      
      // Calculate the end of the week (today)
      var endOfWeek = moment().endOf('day').toDate();
    
          var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
          var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
          setLocalFromDate(FormatedFirstday)
          setLocalToDate(Formatedlastday)
    
    
        }
         if (value === 'month') {
       
          debugger
          const { fromDate, toDate } = calculateMonthDates(val);
          setLocalFromDate(fromDate);
          setLocalToDate(toDate);
      }
      if (value === 'year') {
     
        const year = parseInt(val, 10);
        const fromDate = moment(`${year}-01-01`).format('YYYY-MM-DD');
        const toDate = moment(`${year}-12-31`).format('YYYY-MM-DD');
        setLocalFromDate(fromDate);
        setLocalToDate(toDate);
      }
      
      }
      const calculateMonthDates = (selectedMonth) => {
        const currentDate = moment();
        const targetMonth = moment(selectedMonth, 'MMMM YYYY');
      
        const fromDate = targetMonth.startOf('month').format('YYYY-MM-DD');
        const toDate = targetMonth.endOf('month').format('YYYY-MM-DD');
      
        return { fromDate, toDate };
      };
    
  const [radioValue, setRadioValue] = useState('');
  const [radioValue1, setRadioValue1] = useState('');
  const alert = useAlert();
  const radios = [
    { name: 'Alive', value: '1' },
    { name: 'Death', value: '2' },
   
  ];

  const radios1 = [
    { name: 'Received', value: '3' },
    { name: 'Returned', value: '4' },
   
  ];
  const handleEvent = (event, picker) => {
    setShowMonths(false);
    setShowYears(false);
    setShow(false);
  
    if (event && event.length === 2) {
      // Check if event is not null and contains two dates
      const [start, end] = event;
      const fromDate = moment(start).format('YYYY-MM-DD');
      const toDate = moment(end).format('YYYY-MM-DD');
      setLocalFromDate(fromDate);
      setLocalToDate(toDate);
    } else {
      // Handle the case where event is null or doesn't contain two dates
      setLocalFromDate(''); // Reset fromDate
      setLocalToDate('');   // Reset toDate
    }
  };
  const handleApplyFilter = () => {
    

    if(radioValue==="" && radioValue1===""){
      alert.error("Please choose filter options")
    }
    else if(radioValue!=="" && localFromDate!=="" && radioValue1===""){
      alert.error("Please choose filter options for the date range")
    }
    else{
    // Call the callback function from the parent component
    onApplyFilter(radioValue, [localFromDate, localToDate],radioValue1);
    props.handle();
    }
  };

  
  const handleRadioChange1 = (value) => {
    // Check if the clicked button is already selected
    if (radioValue1 === value) {
      // If yes, reset the selected value
      setRadioValue1("");
      setShowMonths(false)
      setShowYears(false)
    } else {
      // If not, update the selected value
      setRadioValue1(value);
      setShowMonths(false)
      setShowYears(false)
    }
  };

  const handleRadioChange = (value) => {
    // Check if the clicked button is already selected
    if (radioValue === value) {
      // If yes, reset the selected value
      setRadioValue("");
      setShowMonths(false)
      setShowYears(false)
    } else {
      // If not, update the selected value
      setRadioValue(value);
      setShowMonths(false)
      setShowYears(false)
    }
  };

 

  

  return (
    <div style={{height:"100%",textAlign:"center"}}>
<div className="row" style={{width:"100%",boxSizing:"border-box"}}>
{/* 
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <div className="col-4" style={{border: "solid grey 1px",borderRadius:"10px"}}>
<div className="job-skill-list" style={{textAlign:"left"}} >
    
       
            <div style={{ display: 'block',marginTop:"10%" }}>
            <ButtonGroup>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-danger' : 'outline-success'}
            name="radio1"
            value={radio.value}
            style={{marginLeft:"5%"}}
            checked={radioValue === radio.value}
            onChange={(e) => handleRadioChange(e.currentTarget.value)}
          >
            {radio.name} 
          
          </ToggleButton>
        ))}          
      </ButtonGroup>
          </div>
   
         
      </div></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
      <div className="col-12" style={{border: "solid grey 1px",borderRadius:"10px"}}>
      {/* Date Range Picker */}
      <div  style={{ }}>
      <ToggleButtonGroup style={{ marginTop: "1%", fontSize: "small",display:"block" }} type="radio" name="options" defaultValue={timePeriod} onChange={handleChangeValue}>
      <label> <b>Date: </b></label>&nbsp;
     
      <DateRangePicker
            appearance="default"
        style={{ width: "210px",}}
        placeholder={"Select Date Range"}
        onChange={handleEvent}
        disabledDate={(date) =>
          isAfter(date, new Date())}
      >
   
      </DateRangePicker>
      <br/>

      <button  className="buttonfilter" style={{borderRadius:"25px",marginTop:"5%" ,background:selectedValue==='yesterday' ? "rgb(13, 197, 197)" : " #1269B9" }} onClick={()=>handleChangeValue('yesterday')}>
 
 <span>Yesterday</span>
</button>&nbsp;&nbsp;&nbsp;
<button  className="buttonfilter" style={{borderRadius:"25px",marginTop:"5%" ,background:selectedValue==='week' ? "rgb(13, 197, 197)" : " #1269B9" }} onClick={()=>handleChangeValue('week')}>

 <span>Week</span>
</button>&nbsp;&nbsp;&nbsp;

     <button  className="buttonfilter"
       style={{
         position:"relative",
         background: showMonths ? "rgb(13, 197, 197)" : "#1269B9",marginTop:"5%" ,
       }}
       onClick={toggleMonths}
     >
     
       <span>Month </span>
       {showMonths && (
       <div
         style={{
           position: "absolute",
           top: "100%",
           left: 0,
           color:"black",
           background: "#e4dd9f",
           boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
           borderRadius: "4px",
           zIndex: 1,
           fontWeight:700
         }}
       >
        {months.map((month, index) => {
     const monthDate = moment(month, 'MMMM YYYY');
     if (monthDate.isSameOrBefore(moment(), 'month')) {
       return (
         <div
           key={index} className="year-item"
           onClick={() => {
             handleChangeValue(month,"month");
             toggleMonths();
           }}
           style={{
             padding: "8px 16px",
             cursor: "pointer",
             
           }}
         >
           {month}
         </div>
       );
     }
     return null;
   })}
       </div>
     )}
     </button>
    
  &nbsp;&nbsp;&nbsp;

     <button  className="buttonfilter"
       style={{
position: "relative",
         background: showYears  ? "rgb(13, 197, 197)" : "#1269B9",marginTop:"5%" ,
       }}
       onClick={toggleYears}
     >
     
       <span>Years </span>
     
     {showYears && (
       <div
         style={{
           position: "absolute",
           top: "100%",
           left: 0,
           color:"black",
           background: "#e4dd9f",
           boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
           borderRadius: "4px",
           zIndex: 9999,
           fontWeight:700
         }}
       >
       {Years.map((month, index) => (
 <div
   key={index} className="year-item"
   onClick={() => {
     handleChangeValue(month, "year");
     toggleYears();
   }}
   style={{
     padding: "8px 16px",
     cursor: "pointer",
     zIndex: 9999,
     transition: "background-color 0.3s ease", // Add a transition for smooth hover effect
     "&:hover": {
       backgroundColor: "rgba(255, 255, 255, 0.1)", // Change the background color on hover
     },
   }}
 >
   {month}
 </div>
))}

       </div>
     )}</button> 
       </ToggleButtonGroup>
      </div>
    
      {/* Job Skills List with Checkboxes */}

      <div style={{ display: 'block',marginTop:"10%" }}>
            <ButtonGroup>
        {radios1.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-primary' : 'outline-success'}
            name="radio2"
            value={radio.value}
            style={{marginLeft:"5%", zIndex:0}}
            checked={radioValue1 === radio.value}
            onChange={(e) => handleRadioChange1(e.currentTarget.value)}
          >
            {radio.name} 
          </ToggleButton>
        ))}
      </ButtonGroup>
          </div>
    
    </div>
    
    </div>
    <button className="buttonfilter" style={{width:"15%",textAlign:"center",marginTop:"30px"}} onClick={handleApplyFilter}>Apply</button>

    </div>
  );
}

export default InventoryFilter;

import React, {useState,useEffect} from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import Pagination from "react-js-pagination";
import DatePicker from 'react-date-picker';
import PhoneInput from 'react-phone-input-2';

function EquipmentBooking() {

    const [searchTerm,setSearchTerm]= useState("")
    const [currentPage, setCurrentPage]= useState(1)
    const [priority, setPriority]= useState("")
    const [dueDate, setDueDate]= useState("")
    const [show, setShow]= useState(false)

    
    const priorityOptions = [ {value: 'critical' , label: 'Critical'},
    { value: 'very high' , label: 'Very High'},
       { value: 'high' , label: 'High'},
      { value: 'medium' , label: 'Medium'},
       { value: 'low' , label: 'Low'},

       
    ];

    const serviceData = [
        {
            serviceId:1,
            serviceName:"Wheel Chair",
            price:"50"
        },
        {
            serviceId:2,
            serviceName:"Water Bed",
            price:"25"
        },
        {
            serviceId:3,
            serviceName:"Oxygen Cylinder",
            price:"10"
        },
        {
            serviceId:4,
            serviceName:"data1",
            price:"100"
        },
        {
            serviceId:5,
            serviceName:"data2",
            price:"75"
        },
      ]
      
      const serviceDisplay = serviceData.map((value,index) => {
        return(
              <div id="csc-listing" onClick={()=> setShow(true)} style={{cursor:"pointer",width:"90%"}} tabIndex="-1" key ={value.serviceId} className='list-item flex-row'>               
                  <h5 className="each-list-link">{index+1}.{value.serviceName}</h5>  
                  <div style={{marginLeft:"auto"}} className="details">
                    <span> Price: {value.price} </span>
                    {/* <p> Phn : {value.mobile_number} </p> */}
                  </div>                                                                 
              </div>
          )
      })

    const handleSearchChange = e => {
        setSearchTerm(e.target.value)
      }
      
      const handlePageChange = (pageNumber)=> {
        setCurrentPage(pageNumber)
      }

    const handlePriorityChange = data => {
        setPriority(data)
    }

    const handleDueDate = date => {
        setDueDate(date)
    }

    return (
        <div style={{gap:"10px"}} className='d-flex justify-content-center container'>

        <div className='flex-col justify-content-start appointment-doctor-list'>
          <h4 className="title-of-tasks">Equipment List</h4>
          <div className="search-div">
              <input
                className="form-control search-input"
                style={{width:"77%"}}
                type="text"
                maxLength="150"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>        </div>
            {serviceDisplay}

            <br/>
            <br/>

            {serviceDisplay.length > 2 ?
    <div className="pagn">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={5}
          totalItemsCount={3}
          pageRangeDisplayed={2}
          onChange={handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}

        </div>
{
    show ?

    <div className="service-details">

    <h4 className="title-of-tasks">Water Bed</h4>
    <div className='flex-row name-content'>
        <span>Priority : </span>
        <Select
                    value={priority}
                    onChange={(data,e)=>{handlePriorityChange(data,e)
                    }}
                    options={priorityOptions}
                    name='service_type'
                    className="select-currency service-priority"
                />
                {/* {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null} */}
    </div>

    <div className='flex-row name-content'>
        <span>From Date : </span>
        <div className='service-date'>
            <DatePicker
                  onChange={date=>{handleDueDate(date)}}

                  minDate = {new Date()}
                  value={dueDate}
                  
             />
        </div>
                {/* {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null} */}
    </div>

    <div className='flex-row name-content'>
        <span>To Date : </span>
        <div className='service-date'>
            <DatePicker
                  onChange={date=>{handleDueDate(date)}}

                  minDate = {new Date()}
                  value={dueDate}
                  
             />
        </div>
                {/* {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null} */}
    </div>

    {/* <div className='flex-row name-content'>
        <span>To Date : </span>
        <label style={{marginRight:'20px'}} className="radio-inline"><input type="radio" checked={check1} onChange={()=> {setCheck1(!check1); setDateVal('')}} onClick={handleToday} name="optradio" />Today</label>
      <label className="radio-inline"><input type="radio" checked={check} onChange={()=> {setCheck(!check); setDateVal('')}} onClick={handleTomorrow} name="optradio" />Tomorrow</label>

      <div style={{marginTop:'20px'}} className="form-control dob item">

      <span>Custom Date :  </span>

      <input style={{cursor:'pointer'}} type='date' value id='start'  min={new Date()}
        // onChange={}
        />
        
        </div>
        <div className='service-date'>
            <DatePicker
                  onChange={date=>{handleDueDate(date)}}

                  minDate = {new Date()}
                  value={dueDate}
                  
             />
        </div>

    </div> */}

    <div className='flex-row name-content'>
        <span>Contact : </span>
            <div className='service-phone-size'>
                <PhoneInput
                inputProps={{   }}
                country={"mt"}
                // value={}
                // onChange={}

                                  />
            </div>
                {/* {error.type ? <div className="error-validation-msg error-feature">{error.type}</div> : null} */}
    </div>

    <div className='flex-row name-content'>
        <span>Address : </span>

        <textarea className="text-area service-phone-size" cols="100" rows="4"/>
    </div>

    <div className='flex-row name-content'>
        <span>Details : </span>

        <textarea  className="text-area service-phone-size" cols="100" rows="4"/>
    </div>

    <button className='btn btn-primary btn-col'>Submit</button>

</div> : null

}



      </div>
    )
}

export default EquipmentBooking

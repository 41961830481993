import React, { useState, useEffect, useRef } from 'react'
import { DatePicker } from 'rsuite';
import { Link, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import moment from "moment";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import isBefore from 'date-fns/isBefore';
import { useAlert } from "react-alert";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";

function VisitorRegistration() {
    const [visitorInfo, setVisitorInfo] = useState({country:"Malta",number_of_visitors:1})
    const [errors, setErrors] = useState({})
    const [phone, setPhone] = useState("")
    const [resident, setResident] = useState("")
    const [searchTerm, setSearchTerm] = useState("");
    const [patients, setPatients] = useState([])
    const [display, setDisplay] = useState(false);
    const alert = useAlert()
    const navigate = useNavigate();
    const inputRefs = useRef([])
  const submitButtonRef = useRef (null)
  const handleKeyDown = (e, index) => {
    console.log("function" + e.key)
    if (e.key === "Enter") {  
    console.log("function enter")
      e.preventDefault()

      if(index === 9 ) {
        submitButtonRef.current.click();
        console.log("on click")
      }else{
      e.preventDefault();    
      console.log("Index:", index);
      console.log("Length:", inputRefs.current.length);
      const nextIndex = index + 1;
      console.log("Next index:", nextIndex);
      console.log("Next input field:", inputRefs.current[nextIndex]);
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        // move focus to the next input if it exists
        inputRefs.current[nextIndex].focus();
      }
    }
    }
  };
  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
    console.log("useeffect enter")

      const activeElement = document.activeElement;
      if (
        !inputRefs.current.includes(activeElement) &&
        activeElement !== submitButtonRef.current
      ) {
    console.log("useeffect on save")

        submitButtonRef.current.click();
      }
    }else if (e.altKey && e.key === 's') {
    console.log("useeffect alt and s")

      submitButtonRef.current.click(); 
    }else if (e.altKey && e.key === 'c') {
        navigate("/login");
      }
  };

  useEffect(() => {
    console.log("useeffect")
    document.addEventListener("keydown", handleKey);
  
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);
    const handleTitle = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;

        setErrors(currentstate => ({
            ...currentstate,
            [key]: ""
        }));
        if(key==="day_count"){
           if(visitorInfo.from_date){
            delete visitorInfo["from_date"]
           }
           if(visitorInfo.to_date){
            delete visitorInfo["to_date"]
           }
        }
            setVisitorInfo(current => ({
                ...current, [key]: val
            }))
        

    }
    useEffect(() => {


        if (searchTerm !== "") {

           

            let url = ""


            url = `${APIURL}/api/v1/service-provider/patient-search-list/?per_page=30&page=10&pa=${searchTerm}`


            axios
                .get(url, {
                    headers: {},
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setPatients(res.data.patients)
                        setDisplay(true)
                    }
                    else {

                    }
                }).catch((error) => {

                })

        }
        else {
            setPatients([])
            setDisplay(false)
        }

    }, [searchTerm,])
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
        if (e.target.value === "") {
            setResident("")
            setDisplay(false)
            setPatients([])

        }
    }
    const handleDate = (date) => {
        setErrors(currentstate => ({
            ...currentstate,
            from_date: ""
        }));
        
        const a = moment(date).format('YYYY-MM-DD');
        setVisitorInfo(currentstate => ({
            ...currentstate,
            from_date: a,
            
        }));

    }
    useEffect(() => {
        if(Number(visitorInfo.day_count)>1){
        const b= (new Date(visitorInfo.from_date).getDate() + Number(visitorInfo.day_count-1))
        if(visitorInfo.from_date&&visitorInfo.from_date!==""&&visitorInfo.from_date!=="Invalid date"){
        let x=new Date(visitorInfo.from_date).setDate(b)
        console.log(x)
        setVisitorInfo(currentstate => ({
            ...currentstate,
             to_date:moment(new Date(x)).format('YYYY-MM-DD')
        }));}
        else{
            setVisitorInfo(currentstate => ({
                ...currentstate,
                 to_date:visitorInfo.from_date
            }))
        }
} else{
    setVisitorInfo(currentstate => ({
        ...currentstate,
         to_date:visitorInfo.from_date
    }))
}
    },[visitorInfo.from_date])

    const handleTypeRadioButton = (e) => {
        setErrors(currentstate => ({
            ...currentstate,
            visitor_type: "",
            resident:""
        }));
        let genderOpted = e.target.value;
        if (genderOpted==="general"||genderOpted==="organisation"){
            setVisitorInfo(currentstate => ({
                ...currentstate,
               name_of_resident: null
            }))
            setResident("")
        }
        setVisitorInfo(currentstate => ({
            ...currentstate,
            visitor_type: genderOpted
        }))
    }
    const handleGenderRadioButton = (e) => {
        setErrors(currentstate => ({
            ...currentstate,
            gender: ""
        }));
        let genderOpted = e.target.value;
        setVisitorInfo(currentstate => ({
            ...currentstate,
            gender: genderOpted
        }))
    }
    const selectRegion = (val) => {
        setErrors(currentstate => ({
            ...currentstate,
            state: ""
        }));
        // let value1=e.target.value;
        setVisitorInfo(currentstate => ({
            ...currentstate,
            state: val
        }));
    }
    const handlePhoneInput = (value, data, event, formattedValue) => {
        setErrors(currentstate => ({
            ...currentstate,
            mobile_number: ""
        }));
        const rawPhone = value.slice(data.dialCode.length);
        const dialcode = value.slice(0, data.dialCode.length);
        if (value.length === 12) {
            setPhone(value)
            setVisitorInfo(currentstate => ({
                ...currentstate,
                mobile_number: rawPhone,
                code: dialcode

            }));

        }
        else {
            setPhone("")
            setVisitorInfo(currentstate => ({
                ...currentstate,
                mobile_number: "",
                code: "356"

            }));

        }

    }
    const trimspace=(val)=>{
    
        return  val.startsWith(' ')
        ? val.trimLeft()
        : val;
      }
    const validatePhoneInput = (value,) => {
        if (value.length !== 2) {
            if (value.length !== 12) {
                return "Invalid phone number";

            }
            else {
                return true
            }
        }

    }
    const validate = async () => {
        let input = visitorInfo
        let errors = {};
        let isValid = true;
        setErrors({});

        if (!input["first_name"] || input["first_name"] === "") {
            isValid = false;
            errors["first_name"] = "Enter first name";
        }


        if (!input["last_name"] || input["last_name"] === "") {
            isValid = false;
            errors["last_name"] = "Enter last name";
        }

        if (!input["state"] || input["state"] === "") {
            isValid = false;
            errors["state"] = "Select a region";
        }
        if (!input["purpose"] || input["purpose"] === "") {
            isValid = false;
            errors["purpose"] = "Select purpose of visit";
        }
        if (!input["visitor_type"] || input["visitor_type"] === "") {
            isValid = false;
            errors["visitor_type"] = "Select visitor type";
        }
        if(input["visitor_type"]==="relative"|| input["visitor_type"]==="friend"){
            if (resident === "") {
                isValid = false;
                errors["resident"] = "Select resident";
            }
        }
        if (!input["day_count"] || input["day_count"] === "") {
            isValid = false;
            errors["day_count"] = "Enter duration of visit";
        }
        if (input["day_count"] && input["day_count"] !== ""&&input["day_count"] <1) {
            isValid = false;
            errors["day_count"] = "Enter a valid duration";
        }
        if (!input["from_date"] || input["from_date"] === "") {
            isValid = false;
            errors["from_date"] = "Select available date";
        }
        if (!input["address"] || input["address"] === "") {
            isValid = false;
            errors["address"] = "Enter address";
        }
        if (!input["pincode"] || input["pincode"] === "") {
            isValid = false;
            errors["pincode"] = "Enter pincode";
        }
        if (input["email"] && input["email"] !== "") {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Enter a valid email address.";
            }
        }

        if (typeof input["first_name"] !== "undefined") {
            var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
            if (pattern2.test(input["firstname"])) {
                isValid = false;
                errors["first_name"] = "Special Characters are not allowed";
            }
        }

        if (typeof input["last_name"] !== "undefined") {
            var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
            if (pattern3.test(input["lastname"])) {
                isValid = false;
                errors["last_name"] = "Special Characters are not allowed";
            }
        }

        if (
            !visitorInfo.mobile_number ||
            visitorInfo.mobile_number === ""

        ) {
            isValid = false;
            errors["mobile_number"] = "Enter phone number ";
        }

       
        if (
            typeof visitorInfo.gender == "undefined" ||
            visitorInfo.gender === "" ||
            !visitorInfo.gender
        ) {
            isValid = false;
            errors["gender"] = "Select gender";
        }



        setErrors((currentstate) => ({
            ...currentstate,
            ...errors,
        }));

        return isValid;
    };
    const handleContinue = async () => {
        
        if (
            (await validate())

        ) {
           
            submit();
        }
    };
    const submit = () => {

        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
console.log(visitorInfo)

        axios.post(`${APIURL}/api/v1/service-provider/visitor-info/?timeZone=${currentTimezone} `, visitorInfo, {
          
        })
            .then(res => {


                if (res.data.status === "success") {
                    
                        alert.success(res.data.message)
                        navigate("/login");

                } else {
                    alert.error(res.data.message)

                }

            })

    }
    const disabledDate = (date) => {
        let hr = new Date().getHours()
        let mn = new Date().getMinutes()
        const currentDate = moment().set({ hours: hr, minute: mn, second: 59 });
        return currentDate.isBefore(date);
    };
    const handleEvent1 = (event, picker) => {
        setErrors(currentstate => ({
            ...currentstate,
            from_date: ""
        }));
        const a = moment(event[0]).format('YYYY-MM-DD');
        let b = moment(event[1]).format('YYYY-MM-DD');
        setVisitorInfo(currentstate => ({
            ...currentstate,
            from_date: a,
            to_date: b

        }));
    }
    return (
        <div className='container' style={{ marginTop: "1%", marginBottom: "1%" }}>
            <div className='enquirycontainer' style={{ paddingTop: "", paddingLeft: "2%", paddingRight: "2%", paddingBottom: "2%" }}>
                <h5 className="enquiryheader">Visitor registration</h5>

                <div style={{ marginTop: "2%" }} className='row'>

                    <div className='col-3'><label className='text-style-here ' >*First Name</label><br />
                        <input ref={el => inputRefs.current[0] = el} onKeyDown={(e) => handleKeyDown(e,0)} name="first_name" value={visitorInfo.first_name} onChange={handleTitle} className='enquiryinput' />
                        {errors.first_name ? (
                            <div className="error-validation-msg">{errors.first_name}</div>
                        ) : null}
                    </div>
                    <div className='col-3'><label className='text-style-here ' >*Last Name </label><br />
                        <input ref={el => inputRefs.current[1] = el} onKeyDown={(e) => handleKeyDown(e,1)} name="last_name" value={visitorInfo.last_name}
                            onChange={handleTitle} className='enquiryinput' />
                        {errors.last_name ? (
                            <div className="error-validation-msg">{errors.last_name}</div>
                        ) : null}
                    </div>

                    <div className='col-6'><label className='text-style-here ' >*Gender </label><br />
                        <div style={{ paddingTop: "1.5%", textAlign: "center" }} className='enquiryinput' >&nbsp; &nbsp; <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            checked={visitorInfo.gender === "male"}
                            onClick={handleGenderRadioButton}
                        />

                            <label htmlFor="male">Male</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="female"
                                name="gender"
                                value="female"
                                checked={visitorInfo.gender === "female"}
                                onClick={handleGenderRadioButton}
                            />

                            <label htmlFor="female">Female</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="others"
                                name="gender"
                                value="others"
                                checked={visitorInfo.gender === "others"}
                                onClick={handleGenderRadioButton}

                            />
                            <label htmlFor="others">Others</label>
                        </div>
                        {errors.gender ? (
                            <div className="error-validation-msg">{errors.gender}</div>
                        ) : null}
                    </div>

                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'><label className='text-style-here ' >*Types of Visitor </label><br />
                        <div style={{ paddingTop: "1.5%", textAlign: "center" }} className='enquiryinput' >&nbsp; &nbsp; <input
                            type="radio"
                            id="relative"
                            name="visitor_type"
                            value="relative"
                            checked={visitorInfo.visitor_type === "relative"}
                            onClick={handleTypeRadioButton}
                        />

                            <label htmlFor="relative">Relative</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="friend"
                                name="visitor_type"
                                value="friend"
                                checked={visitorInfo.visitor_type === "friend"}
                                onClick={handleTypeRadioButton}
                            />

                            <label htmlFor="friend">Friends</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="organisation"
                                name="visitor_type"
                                value="organisation"
                                checked={visitorInfo.visitor_type === "organisation"}
                                onClick={handleTypeRadioButton}

                            />
                            <label htmlFor="organization">Organization</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="general"
                                name="visitor_type"
                                value="general"
                                checked={visitorInfo.visitor_type === "general"}
                                onClick={handleTypeRadioButton}

                            />
                            <label htmlFor="general">General</label>
                        </div>
                        {errors.visitor_type ? (
                            <div className="error-validation-msg">{errors.visitor_type}</div>
                        ) : null}
                    </div>
                    <div className='col-6 row'>
                        <div className='col-3'>
                        <label className='text-style-here ' >*No of people </label><br />
                            <input ref={el => inputRefs.current[2] = el} onKeyDown ={(e) =>handleKeyDown(e,2)}  type="number" min={0} name="number_of_visitors" value={visitorInfo.number_of_visitors}
                                onChange={handleTitle} className='enquiryinput' />
                            {errors.day_count ? (
                                <div className="error-validation-msg">{errors.number_of_visitors}</div>
                            ) : null}
                        </div>
                   
                    <div className='col-9'><label className='text-style-here ' >{visitorInfo.visitor_type==="relative"||visitorInfo.visitor_type==="friend"?"*Resident":"Resident"} </label><br />
                        <input name="resident"
                        disabled={visitorInfo.visitor_type!=="relative"&&visitorInfo.visitor_type!=="friend"}
                            placeholder="Search name, ID, Phone number"
                            value={searchTerm!==""?searchTerm:resident}
                            onChange={handleSearchChange} className='enquiryinput' />
                            {errors.resident? (
                            <div className="error-validation-msg">{errors.resident}</div>
                        ) : null}
                        {
                   patients.length>0&&display?

                        <div className='suggest-display ' style={{ width: "100%", }}>
                            <button style={{ backgroundColor: "#F8F8F8", marginLeft: "92%" }} variant="secondary" onClick={() => { setDisplay(false) }}>
                                <i class="fa fa-window-close  " style={{ color: "#0d6efd" ,fontSize:"17px"}} aria-hidden="true"></i>
                            </button>
                            {
                                patients.length > 0 ? patients.map((item) => {
                                    return (
                                        <div style={{ fontWeight:"700"}} onClick={() => {
                                            setVisitorInfo(currentstate => ({
                                                ...currentstate,
                                                name_of_resident: item.id,                                    
                                            }));
                                            setSearchTerm("")
                                            setResident(item.patient_name)
                                            setDisplay(false); 
                                        }} className='suggest-item text-secondary row'>
                                            <div className='col-5'>{item.patient_name}</div>
                                            <div className='col-3'>ID:{item.id}</div>
                                            <div className='col-4'>MOB:{item.mobile_number}</div>
                                        </div>
                                    )
                                }) : <h6 className='text-muted'>No Patients found !!</h6>
                            }
                        </div>

                        : null
                }
                    </div>

                </div>
                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'><label className='text-style-here ' >*Address Line1</label><br />
                        <input ref={el => inputRefs.current[3] = el} onKeyDown={(e) => handleKeyDown(e,3)} name="address" placeholder='Address' value={visitorInfo.address} onChange={handleTitle} className='enquiryinput' />
                        {errors.address ? (
                            <div className="error-validation-msg">{errors.address}</div>
                        ) : null}
                    </div>

                    <div className='col-6'><label className='text-style-here ' >Address Line2 </label><br />
                        <input ref={el => inputRefs.current[4] = el} onKeyDown={(e) => handleKeyDown(e,4)} placeholder='Location' name="location" value={visitorInfo.location}
                            onChange={handleTitle} className='enquiryinput' />

                    </div>

                </div>


                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'><label className='text-style-here ' >Country</label><br />

                        <input className='enquiryinput' value={"Malta"}
                            disabled type="text" /></div>
                    <div className='col-6 row'> <div className='col-6' >
                        <span className="text-style-here">*State</span>
                        <RegionDropdown
                            className='enquiryinput'
                            country={"Malta"}
                            value={visitorInfo.state}
                            onChange={(val) => selectRegion(val)} />
                        {errors.state ? (
                            <div className="error-validation-msg">{errors.state}</div>
                        ) : null}
                    </div>
                        <div className='col-6' >
                            <span className="text-style-here">*Pincode</span>
                            <input ref={el => inputRefs.current[5] = el} onKeyDown={(e) => handleKeyDown(e,5)} className='enquiryinput' type="text" name="pincode" value={visitorInfo.pincode} onChange={handleTitle} />
                            {errors.pincode ? (
                            <div className="error-validation-msg">{errors.pincode}</div>
                        ) : null}
                            </div>

                          

                    </div>

                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6'>
                        <label className='text-style-here ' >*Phone</label><br />
                        <PhoneInput
                            className='enquiryinput'
                            inputProps={{
                                ref: el => inputRefs.current[6] = el, // Assuming inputRefs is a useRef initialized as useRef([])
                                tabIndex: 0,
                                onKeyDown: e => handleKeyDown(e, 6),
                              }}
                            country={"mt"}
                            onlyCountries={["mt"]}
                            countryCodeEditable={false}
                            value={phone !== "" ? phone : "356"}
                            onChange={handlePhoneInput}
                            isValid={validatePhoneInput}

                        />
                        {errors.mobile_number ? (
                            <div className="error-validation-msg">{errors.mobile_number}</div>
                        ) : null}
                    </div>
                    <div className='col-6'>
                        <label className='text-style-here ' >Email </label><br />
                        <input ref={el => inputRefs.current[7] = el} onKeyDown={(e) => handleKeyDown(e,7)} name="email" value={visitorInfo.email}
                            onChange={handleTitle} className='enquiryinput' />
                        {errors.email ? (
                            <div className="error-validation-msg">{errors.email}</div>
                        ) : null}
                    </div>
                </div>
                <div style={{ marginTop: "2%" }} className='row'>
                    <div className='col-6 focused-select'>
                        <label className='text-style-here ' >*Purpose of visit</label><br />


                        <select
                            ref={el => inputRefs.current[8] = el} 
                            onKeyDown ={(e) =>handleKeyDown(e,8)}
                            style={{ backgroundColor: "white", paddingLeft: "1%" }}
                            className="enquiryinput"
                            value={visitorInfo.purpose}
                            name="purpose"
                            onChange={handleTitle}
                            placeholder='Select'
                        >
                            <option hidden value="" ></option>
                            <option value="general" >General</option>
                            <option value="charity" >Charity</option>
                            <option value="awareness" >Awareness</option>
                            <option value="family_visit" >Family Visit</option>


                        </select>
                        {errors.purpose ? (
                            <div className="error-validation-msg">{errors.purpose}</div>
                        ) : null}

                    </div>
                    <div className='col-6 row'>
                        <div className='col-4'>
                            <label className='text-style-here ' >*Duration of visit (in days) </label><br />
                            <input ref={el => inputRefs.current[9] = el} onKeyDown ={(e) =>handleKeyDown(e,9)} type="number" min={0} name="day_count" value={visitorInfo.day_count}
                                onChange={handleTitle} className='enquiryinput' />
                            {errors.day_count ? (
                                <div className="error-validation-msg">{errors.day_count}</div>
                            ) : null}
                        </div>
                        <div className='col-8' style={{position: 'absolute', bottom: '0',left: '33.333%'}}>
                            <label className='text-style-here ' style={{ color: "white" }} ></label><br />
                            <DatePicker
                             disabled={!visitorInfo.day_count}
                                style={{ border: "none", width:Number(visitorInfo.day_count)>1? "50%":"90%",height:'40px' }}
                                className="enquiryinput deathtime"
                                appearance="default"
                                format="yyyy-MM-dd"
                                // value={(visitorInfo.from_date&&visitorInfo.from_date!=="")?new Date(visitorInfo.from_date):""}
                                onChange={date => { handleDate(date) }}
                                disabledDate={(date) =>
                                    isBefore(date, new Date())}
                             

                            /> &nbsp;&nbsp;
                               {Number(visitorInfo.day_count)>1&& <input
                                disabled
                                style={{  width: "40%" }}
                                
                                value={visitorInfo.to_date!==visitorInfo.from_date ?visitorInfo.to_date:""}
                                className="enquiryinput deathtime"
                                appearance="default"
                                format="yyyy-MM-dd"
                               
                            />}
                                
                                {errors.from_date? (
        <div className="error-validation-msg">{errors.from_date}</div>
      ) : null}
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "2%" }}>
                    <label className='text-style-here ' >Explain the plans in the visit and explain if any special arrangement required.</label><br />
                    <textarea maxLength="300" name="details" value={visitorInfo.details}
                        onChange={handleTitle} className='enquiryinputtext' type="text" />
                </div>



                <div className='' style={{ padding: "2%", textAlign: "center" }}>
                    <button ref={submitButtonRef} style={{ backgroundColor: "#9069A5", color: "white", height: "40px", border: "none", width: "18%", borderRadius: "10px" }} onClick={() => { handleContinue() }}>Submit</button>
                </div>

            </div>

        </div>
    )
}
export default VisitorRegistration;
import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
// import "./service.css";
import { APIURL,TZ } from "../../Global";
import logo from "../../assets/images/svp.png";
import { useAlert } from 'react-alert' 
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from "axios";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
function MedOrders (props){
   const alert=useAlert()
    const [medData, setMedData] = useState([]);
    const [refresh,setRefresh]=useState(false)
    useEffect(() => {setMedData([]);getServices1()},[props.render,refresh])
    const getServices1 =  async  (page, per_page) => {

 
        const tokenString = sessionStorage.getItem('usertoken');
    
        let str = tokenString.replace(/["]+/g, '');
    
    
    
     await   axios.get(`${APIURL}/api/v1/service-provider/nurse-inventory/?type=medicine&${TZ}&is_accepted=False     `, {
          headers: {
    
            "Authorization": 'Token ' + str
          }
        })
          .then(res => {
            if (res.data.status === "success") {
      
             
            //   setLoading(false);
              let data=res.data.data
              if (data && data.length > 0) {
                setMedData((prevData) => {
                  const newData = data.filter((item) => !prevData.some((existingItem) => existingItem.id === item.id));
                  return [...prevData, ...newData];
                });  
                props.onSuccess()
            }
            }
           
            else {
            //   setLoading(false);
    
              alert.error(res.data.message);
            }
    
    
    
          })
          .catch(err => {
            // setLoading(false);
    
            // setGetError("Error in fetching data");
          })
      };
      const renderService1 = (cellInfo) => {

        return (
         
          <div >  <>
         <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
            {medData[cellInfo.index][cellInfo.column.id]}
            
          </span>
        </>
          </div>
    
        );
      }
      const renderServiceMedName = (cellInfo) => {

        return (
    
          <div className="row"> <span style={{textTransform: "capitalize" ,textAlign:"left" }}>
            {medData[cellInfo.index][cellInfo.column.id]} 
          </span>
         
        
          </div>
    
        );
      }
      const renderStatus = (cellInfo) => {

        return (
    
          <div className="row" >  <span style={{textTransform: "capitalize" ,textAlign:"left", }}>
            {(medData[cellInfo.index].is_dispatched===true)?<><span style={{color:"rgba(36, 174, 42, 1)", display:"flex"}}>{medData[cellInfo.index].pharm_status==="rejected"&&<OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Rejected</Tooltip>}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="red" d="M12 20a8 8 0 0 1-8-8H2c0 5.523 4.477 10 10 10v-2Zm0-16a8 8 0 0 1 8 8h2c0-5.523-4.477-10-10-10v2Zm-8 8a7.97 7.97 0 0 1 2.343-5.657L4.93 4.93A9.972 9.972 0 0 0 2 11.999h2Zm2.343-5.657A7.972 7.972 0 0 1 12 4V2a9.972 9.972 0 0 0-7.071 2.929l1.414 1.414Zm-1.414 0l12.728 12.728l1.414-1.414L6.343 4.929L4.93 6.343ZM20 12a7.97 7.97 0 0 1-2.343 5.657l1.414 1.414A9.972 9.972 0 0 0 22 12h-2Zm-2.343 5.657A7.972 7.972 0 0 1 12 20v2a9.972 9.972 0 0 0 7.071-2.929l-1.414-1.414Z"/></svg></OverlayTrigger>}<b>Dispatched</b></span></>:<span style={{color:"rgba(36, 86, 174, 1)",display:"flex"}}><b>{medData[cellInfo.index].pharm_status==="rejected"&&<OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Rejected</Tooltip>}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="red" d="M12 20a8 8 0 0 1-8-8H2c0 5.523 4.477 10 10 10v-2Zm0-16a8 8 0 0 1 8 8h2c0-5.523-4.477-10-10-10v2Zm-8 8a7.97 7.97 0 0 1 2.343-5.657L4.93 4.93A9.972 9.972 0 0 0 2 11.999h2Zm2.343-5.657A7.972 7.972 0 0 1 12 4V2a9.972 9.972 0 0 0-7.071 2.929l1.414 1.414Zm-1.414 0l12.728 12.728l1.414-1.414L6.343 4.929L4.93 6.343ZM20 12a7.97 7.97 0 0 1-2.343 5.657l1.414 1.414A9.972 9.972 0 0 0 22 12h-2Zm-2.343 5.657A7.972 7.972 0 0 1 12 20v2a9.972 9.972 0 0 0 7.071-2.929l-1.414-1.414Z"/></svg></OverlayTrigger>}Pending</b></span>} 
          </span>
         
          </div>
    
        );
      }
      const renderTableDate = (cellInfo) => {
        const datetimeString = medData[cellInfo.index][cellInfo.column.id];
        const dateParts = datetimeString.split(' ')[0].split('-'); // Split by space and then by hyphen
        const formattedDate = datetimeString.slice(0,10); // Join the date parts with hyphens
      
        return (
          <div > <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
            {formattedDate}
            </span>
          </div>
        );
      };
      const renderRemarks1 = (cellInfo) => {
   
        return (<>{medData[cellInfo.index].pharm_status==="pending"||(medData[cellInfo.index].pharm_status==="rejected"&&medData[cellInfo.index].is_dispatched===false)?<button disabled style={{borderRadius: "20px",padding:"2% 15% 2% 15%",
        background: "gray",color:"white",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
     
            onClick={() => {saveChangesMed(cellInfo.index)  }} >
                Accept </button>:
          <button  style={{borderRadius: "20px",padding:"2% 15% 2% 15%",          background: "rgba(0, 68, 124, 1)",color:"white",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
       
              onClick={() => {saveChangesMed(cellInfo.index)  }} >
                 Accept </button>}
     
          
         </>);
    
    
      }
      const saveChangesMed = (index) => {

        const rowToUpdate = medData.filter((elt, i) => {
          return i === index
        })
    
        callSaveMed(rowToUpdate[0],index);
    
      }
      const callSaveMed = (rowToUpdate,index) => {
    
  
   
        const tokenString = sessionStorage.getItem('usertoken');
    
        let str = tokenString.replace(/["]+/g, '');
    let dataToUpdate={}
    
  
     dataToUpdate = {
       is_accepted:true
      };
  
      
        axios.put(`${APIURL}/api/v1/service-provider/nurse-inventory/?inv_id=${rowToUpdate.id}`, dataToUpdate, {
          headers: {
    
            "Authorization": 'Token ' + str
          }
        })
          .then(res => {
    
            if (res.data.status === "success") {
    
              alert.success("Accepted successfully");
              setMedData([])
             setRefresh(!refresh)
            }
            else {
              alert.error(res.data.message ? res.data.message : "Error in submission");
            }
          })
          .catch(err => {
    
            alert.error("Error in submission");
           
    
          })
        
      
        
      }
    const columns = [

        {
          Header:   <div>
          <span data-tooltip-id="name-tooltip" 
            >ID </span>
        </div>
       ,
          accessor: "id",
           Cell: renderService1,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' },
    
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
          width: 50,
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
         
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
                // filterData(filterValue); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
        },
     
       
        {
          Header: "Medicines",
          accessor: "inventory_item",
           Cell: renderServiceMedName,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' },
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
    
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
                // filterData1(filterValue); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
    
        },
      
    
        {
          Header: "Date",
          accessor: "created_at",
           Cell: renderTableDate,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' },
      
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
    
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
                // filterData4(filterValue); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
          width:100
        },
        {
            Header: "Patient",
            accessor: "patient",
             Cell: renderService1,
            sortable: true,
            filterable: true,
            style: { 'whiteSpace': 'unset' },
            filterMethod: (filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
            },
      
            Filter: ({ filter, onChange }) => (
              // Custom filter input component
              <input
                value={filter ? filter.value : ''}
                onChange={(e) => {
                  const filterValue = e.target.value;
                  onChange(filterValue); // Update the filter value
                  // filterData2(filterValue); // Make an API request and update the filteredData state
                }}
                style={{ color: 'black' }}
              />
            ),
      
          },
        {
          Header: "Doctor",
          accessor: "doctor",
           Cell: renderService1,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' ,color:"black"},
    
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
    
          Filter: ({ filter, onChange }) => (
            
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); 
                
              }}
              style={{ color: 'black' }}
            />
          ),
    
        },
      
      
    
        {
          Header: <div>
          <span data-tooltip-id="name-tooltip1" 
            data-tooltip-content="Dispense Count" >Dispatched Count</span>
        
          {/* <Tooltip id="name-tooltip1" place="bottom" style={{ zIndex:"1000000", color: "white" }}/> */}
        </div>,
          accessor: "dispatched_count",
        //   Cell: renderDispenceCount,
          sortable: true,
          filterable: true,
          style: { 'whiteSpace': 'unset' },
    
        
          width: 100,
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
          },
    
          Filter: ({ filter, onChange }) => (
            // Custom filter input component
            <input
              value={filter ? filter.value : ''}
              onChange={(e) => {
                const filterValue = e.target.value;
                onChange(filterValue); // Update the filter value
                // filterData5(filterValue); // Make an API request and update the filteredData state
              }}
              style={{ color: 'black' }}
            />
          ),
    
        },
        {
            Header: "Status",
            accessor: "pharm_status",
            Cell: renderStatus,
            sortable: true,
            filterable: true,
            style: { 'whiteSpace': 'unset' ,color:"black"},
      
            filterMethod: (filter, row, column) => {
              const id = filter.pivotId || filter.id;
              if (filter.value.toLowerCase().includes("pending") ) {
                return row[filter.id] === "pending"||row[filter.id] === "rejected";
              }
              if (filter.value.toLowerCase() === "dispatched") {
                return row[filter.id] === "approved"||row[filter.id] === "partially_approved";
              }
              else{
                return row[filter.id] === "approved"||row[filter.id] === "partially_approved"||row[filter.id] === "pending"||row[filter.id] === "rejected";
              }
               
            },
            width:120,
      
            Filter: ({ filter, onChange }) => (
                <select
                  className="serviceinput"
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%" ,color: 'black'}}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">All</option>
                  <option value="pending">Pending</option>
                  <option value="dispatched">Dispatched</option>
                 
                </select>
              ),
      
          },
        
        {
          Header: "",
         
           Cell: renderRemarks1,
          
         
          sortable: false,
          filterable: false,
          minWidth: 30,
          width: 130
        },
      ]
    
    const displayTable =  
  <ReactTable
  data={medData}
  columns={columns}
  resizable
  className="service-req-table"
//   defaultPageSize={perPage}
//   showPagination={medData.length>0}
//   pages={Math.ceil(totalRows / perPage)}
//   page={page-1}
//   onPageChange={(pageIndex) => { handlePageChange(pageIndex); }}
//   onPageSizeChange={(newPerPage) => { setPerPage(newPerPage); setPage(0); }}
  pageSizeOptions={[5, 10, 20, 50,]}
/>
    return(
        <div  style={{marginBottom:"2%" ,justifyContent:"center",textAlign:"center",paddingLeft:"7%"}}>
          <div style={{width:"100%"}}>
          {displayTable} 
          </div> 
        </div>
    )
}
export default MedOrders
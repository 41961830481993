import React, {useState, useRef, useEffect} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function AddSugar(props) {
  const alert = useAlert();
    const [nonFasting ,setNonFasting]=useState("");
    const [fasting ,setFasting]=useState("");
    const [random ,setRandom]=useState("");
    const addSugarButtonRef = useRef(null);
    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = toISOString(Date().toLocaleString());

  useEffect(() => {
    const handleKey = (e) => {

      if (e.altKey && e.key === 's') {
        e.preventDefault()
        addSugarButtonRef.current.click()
      }else if (e.key === 'Enter') {
        e.preventDefault()
        addSugarButtonRef.current.click()
      }
    
    }
    document.addEventListener("keydown", handleKey);
    
    return () => {
      document.removeEventListener("keydown", handleKey);
    }
    
  }, []);
  function toISOString(s) {
    var months = {jan:'01',feb:'02',mar:'03',apr:'04',may:'05',jun:'06',
                  jul:'07',aug:'08',sep:'09',oct:'10',nov:'11',dec:'12'};
    var b = s.split(' ');
  
    return b[3] + '-' +
           months[b[1].toLowerCase()] + '-' +
           ('0' + b[2]).slice(-2) + 'T' +
           b[4] + b[5].substr(3);
  }

    const handleNonFasting = (e)=> {
        const val = e.target.value
        if(val.length < 6){
            setNonFasting(e.target.value)
        }
        else return false
    }

    const handleFasting = (e)=> {
        const val = e.target.value
        if(val.length < 6){
            setFasting(e.target.value)
        }
        else return false
    }
     const handleRandom = (e)=> {
        const val = e.target.value
        if(val.length < 6){
            setRandom(e.target.value)
        }
        else return false
    }
     

   const handleSubmit = () => {

    

    if((nonFasting!== "" && fasting !== "" && random !== "") || (nonFasting !== "" && fasting !== "") || (fasting !== "" && random !== "") || (nonFasting !=="" && random!== ""))
      {
        alert.error("Please Enter any one Sugar level")
      }
      else {
        if((nonFasting > 19.9 && nonFasting < 901) || (fasting > 19.9 && fasting < 901) || (random > 19.9 && random < 901)) {

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
            'result':"",
            'date_time':currentDateTime,
           'vital_name': ""
           }
           if(nonFasting!=""){
            dataToUpdate.result=nonFasting;
            dataToUpdate.vital_name="glucose";
             
           }
           if(fasting!=""){
            dataToUpdate.result=fasting;
            dataToUpdate.vital_name="glucose_fasting";
             
          }
          if(random!=""){
            dataToUpdate.result=random;
            dataToUpdate.vital_name="glucose_random";
          }


                   
    //    const dataToUpdate = 
    //    {
    //     'result':nonFasting,
    //     'glucose_fasting':fasting,
    //     'glucose_random': random,
       
    //  'vital_name': "glucose"
    //    }
     
     
       
         axios.post(`${APIURL}/api/v1/patient/vitals/?patient_id=${id}&timeZone=${timezoneOffset}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
                alert.success("Blood Sugar added Successfully")
                props.submitsugar() ; setNonFasting(""); setFasting(""); setRandom("")
          
       }
       else{
            alert.error(res.data.message)
        
       }
     
         })
         

        }

        else {
            alert.error("Sugar value must be between 20 mg/dL and 900 mg/dL")
        }


        
    }
  }

 
   

  

    

    return (
        <div className='flex-col'><br/>
          <h5>Select Any One:</h5>
           <input class style={{width:'50%'}} type="number" min='0' max='1000' placeholder="Non Fasting Blood Sugar" value={nonFasting} onChange={handleNonFasting} className="form-control"/><br/>
            <h6><b>OR</b></h6>
           <input style={{width:'50%'}} type="number" min='0' max='1000' placeholder="Fasting Blood Sugar" value={fasting} onChange={handleFasting} className="form-control"/><br/>
           <h6><b>OR</b></h6>
           <input style={{width:'50%'}} type="number" min='0' max='1000' placeholder="Random Blood Sugar" value={random} onChange={handleRandom} className="form-control"/><br/>
           
           <button disabled={ !nonFasting && !fasting && !random } onClick={handleSubmit} ref={addSugarButtonRef} className='btn btn-primary btn-lg btn-col'>Add</button> 



        </div>
    )
}

export default AddSugar;


import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css';
import sda from "../../assets/images/icons/sda.png";  
import hospitalroom from "../../assets/images/icons/hospital-room.png";  
import request from "../../assets/images/icons/request1.png"; 
import procedure from "../../assets/images/icons/reports.png";  
import feedback from "../../assets/images/icons/feedback1.png";  
import patient from "../../assets/images/icons/patient.png";  
import patientreg from "../../assets/images/icons/patientreg.png";  
import customer from "../../assets/images/icons/customercare.png";
import payment from "../../assets/images/icons/payment.png";
import admission from "../../assets/images/icons/admission.png";
import admissionreport from "../../assets/images/icons/admissionreport.png";   
import "./doctorDashboard.css";
import enquiry from "../../assets/images/enquiry.jpg";

import inventory from "../../assets/images/inventory.jpg";
import wound from "../../assets/images/wound.png"
import cirtificate from "../../assets/images/icons/report.png"; 
import volunteers from "../../assets/images/icons/volunteer.png"; 
function BDODashboard() {
  const [loginDatas] = useContext(LoginContext);

const [features,setFeatures]=useState(JSON.parse(sessionStorage.getItem("features")))
  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <div >
  {/* <BdoNavbar/> */}
      <div className="home">
        {/* <h1>Doctor Dashboard</h1> */}
        <h2 className="title-of-page"> {login_datas.name} </h2>
        {/* <h4 className="subtitle-of-page">{login_datas.location}</h4> */}

        <div className="home-container">
       

          {features.includes("services")&&<div className="home-tiles" 
           onClick={() => navigate("/selected-services")}
          >
           <img src={sda} className="home-icon" width="65px" />
            <h5 className="each-title">Services</h5>

          </div>}
          {features.includes("services")&&<div className="home-tiles" 
           onClick={() => navigate("/service_request")}
          >
               <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Service Fulfillment</h5>

          </div>}
          {features.includes("ip_form_admission")&&<div className="home-tiles" 
           onClick={() => navigate("/prmary-enquiry")}
          >
               <img src={enquiry} className="home-icon" width="65px" />
            <h5 className="each-title">Patient Enquiry</h5>

          </div>}
          {features.includes("ip_form_admission")&&<div className="home-tiles" 
           onClick={() => navigate("/admission")}
          >
            <img src={admission} className="home-icon" width="65px" />
            <h5 className="each-title">Admissions</h5>

          </div>}

          {features.includes("reports")&& <div className="home-tiles" 
           onClick={() => navigate("/admissionreport")}
          >
            <img src={admissionreport} className="home-icon" width="65px" />
            <h5 className="each-title">Reports</h5>

          </div>}
          
          
          <div className="home-tiles" 
          onClick={() => navigate("/patientlist")}
           >
             <img src={patient} className="home-icon" width="65px" />
            <h5 className="each-title"> Patient Records
            </h5>

          </div>
          
          {(features.includes("ip_form_admission")||features.includes("ip_admission"))&& <div className="home-tiles" 
           onClick={() => navigate("/assignpatient")}
          >
            <img src={hospitalroom} className="home-icon" width="65px" />
            <h5 className="each-title">Facility Assignments</h5>

          </div>}
          {features.includes("csc")&&<div className="home-tiles" 
           onClick={() => navigate("/feedback")}
          >
            <img src={feedback} className="home-icon" width="65px" />
            <h5 className="each-title">Customer Feedback</h5>

          </div>}
          {features.includes("op_counter_registration")&&<div className="home-tiles" 
           onClick={() => navigate("/register-patient")}
          >
            <img src={patientreg} className="home-icon" width="65px" />
            <h5 className="each-title">Patient Registration</h5>

          </div>}
          {features.includes("csc")&& <div className="home-tiles" 
           onClick={() => navigate("/customercare")}
          >
                <img src={customer} className="home-icon" width="65px" />
                <h6 className="each-title"> Customer Service Centre </h6>
      
            </div>}
            {features.includes("payments")&& <div className="home-tiles" 
           onClick={() => navigate("/payment")}
          >
                <img src={payment} className="home-icon" width="65px" />
                <h6 className="each-title"> Payment</h6>
      
            </div>}
           
            {features.includes("personal_inventory")&&<div className="home-tiles" 
           onClick={() => navigate("/personal-inventory")}
          >
                <img src={inventory} className="home-icon" width="65px" />
                <h6 className="each-title"> Personal Inventory</h6>
      
            </div>}
            {/* <div className="home-tiles" 
           onClick={() => navigate("/reports")}
          >
                <img src={procedure} className="home-icon" width="65px" />
                <h6 className="each-title"> Reports</h6>
      
            </div> */}
          
          {/* {features.includes("death_management")&& <div className="home-tiles" 
           onClick={() => navigate("/death_report")}
          >
               <img src={cirtificate} className="home-icon" width="65px" />
            <h5 className="each-title">Death Report</h5>

          </div>} */}
          {/* {features.includes("volunteer_registration")&&<div className="home-tiles" 
           onClick={() => navigate("/volunteers")}
          >
               <img src={volunteers} className="home-icon" width="65px" />
            <h5 className="each-title">Volunteers</h5>

          </div>} */}
        </div>

      </div>

    </div>


  );
}

export default BDODashboard;
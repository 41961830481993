import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./ProcedureNamesBdo.css";
import { Link } from "react-router-dom";
// import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
import Select from "react-select";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { useNavigate,Navigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

function ProcedureNamesBdo() {
  const [render, setRender] = useState(true);
  const [reRender, setRerender] = useState(false);
  const [procedureId, setProcedureId] = useState("");
  const [price, setPrice] = useState("");
  const [vat, setVat] = useState("");
  

  const [tableData, setTableData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [display, setDisplay] = useState(false);

  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [, setOptionsDisplay] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [remarkData, setRemarkData] = useState("");
  const [remarkList, setRemarkList] = useState([]);
  const [cell, setCell] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [backup, setBackup] = useState("");
  const [procedureData, setProcedureData] = useState([]);
  const [procedureShow, setProcedureShow] = useState(false);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [viewShow, setViewShow] = useState(false);
  const defaultCurrency = { value: "inr", label: "INR" };
  // const currencyOptions = [
  //   defaultCurrency,
  //   { value: "usd", label: "USD" },
  //   { value: "aed", label: "AED" },
  // ];
  const [currency, setCurrency] = useState(defaultCurrency);
  const [searchTerm, setSearchTerm] = useState("");
  const [procedure, setProcedure] = useState([]);
  const[fetchTableData,setFetchTableData]=useState(true);
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let navigate = useNavigate();

  // const [keys,] = useState({'u': false,'c': false,'n': false,'a': false,'q': false,'l': false,});

  // useEffect(()=>{

  //   window.onkeydown =(e) => {

  //     keys[e.key]=true

  //     if(e.altKey && keys['u']){
  //       history.push('/directory')
  //     }
  //     if(e.altKey && keys['c']){
  //       history.push('/careplans-db')
  //     }
  //     if(e.altKey && keys['n']){
  //       history.push('/notifications')
  //     }
  //     if(e.altKey && keys['a']){
  //       history.push('/analytics')
  //     }
  //     if(e.altKey && keys['q']){
  //       history.push('/qrcode')
  //     }
  //     if(e.altKey && keys['l']){
  //       history.push('/call-log')
  //     }

  //   }
  //   window.onkeyup=(ev)=> {

  //     keys[ev.key]= false
  //   }

  //   return()=>{
  //     window.onkeydown=null
  //     window.onkeyup=null
  //   }
  // },[]);
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setProcedure([]);
      setDisplay(false);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(
          `${APIURL}/api/v1/service-provider/procedure/?search=${searchTerm}`,
          {
            headers: { Authorization: "Token " + v2 },
          }
        )
        .then((res) => {
          console.log(res);
          if (res && res.data) {
            setProcedure(res.data);
            setDisplay(true);
            // setTotalPages(res.data.total_pages)
            // setCurrentPage(res.data.page)
            // setTotalRecords(res.data.total_records)
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [searchTerm]);


  // useeffect fot fetching table values

  useEffect(() => {
    if (fetchTableData) {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(
          `${APIURL}/api/v1/service-provider/hospital-procedure/`,
          {
            headers: { Authorization: "Token " + v2 },
          }
        )
        .then((res) => {
          let items = [];
          res.data.details.map((item, index) => {
            let statusObj;
            if (!item.is_active) {
              statusObj = { value: "enable", label: "Enable" };
            } else if (item.is_active) {
              statusObj = { value: "disable", label: "Disable" };
            }
            item.statusObj = statusObj;
            items.push(item);
          });
          setTableData(items);
          console.log('FETCH API RES', items);
          setFetchTableData(false)
        })
        .catch((error) => {});
    }
  }, [fetchTableData]);

  

  // added for auto-suggest

  // const handleSearch =() => {

  //   const tokenString = localStorage.getItem("usertoken");

  // let v2 = tokenString.replace(
  //   /["]+/g,
  //   ""
  // ); /* REGEX To remove double quotes from tokenstring */

  //   axios
  //   .get(`${APIURL}/api/v1/hospital/hospital-all-payment/search/?q=${searchTerm}&per_page=10`, {
  //     headers: { Authorization: "Token "+ v2 },
  //   })
  //   .then((res) => {
  //     if (res.data.status === "success") {
  //       setPatient(res.data.results.data)
  //       setDisplay(true)
  //   }
  //   else{

  //   }
  // }).catch((error)=>{

  // })

  // }

  // end of the auto suggest

  const bodyClickHandler = (e) => {
    if (e.target.parentNode.dataset.name !== "procedure-suggestions") {
      document.body.removeEventListener("click", bodyClickHandler);
      setDisplay(false);
    }
  }
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setProcedureId('');
    document.body.addEventListener("click", bodyClickHandler);
  };

  //   window.onscroll = () => {
  //     setDisplay(false);
  // };

  const handleClick = (e, item) => {
    console.log(item)
    e.preventDefault();
    initialRender.current = true;
    setSearchTerm(item.procedure_name);
    setProcedureId(item.id);
    // setPatientDetails(item)

    setDisplay(false);

    // setAllData([])
    // setRefresh(!refresh)
  };

  // useEffect(()=>{
  //   const tokenString= localStorage.getItem('usertoken');

  //    let str= tokenString.replace(/["]+/g, '')  ;

  //     axios.get(`${APIURL}/api/v1/hospital/patient-hospital-procedures/`, { headers: {

  //       "Authorization" : 'Token '+str  }})
  //     .then(res=>{

  //       if(res.data.status==="success"){

  //         let dataList= res.data.data;

  //          setTableData(dataList);

  //       }

  //         else{
  //         setGetError(res.data.message);
  //       }

  //     })
  //     .catch(err=>{

  //  setGetError("Error in fetching data");
  // })

  // },[reRender]);

  // useEffect(()=>{

  //     const tokenString= localStorage.getItem('usertoken');

  //      let str= tokenString.replace(/["]+/g, '')

  //       axios.get(`${APIURL}/api/v1/hospital/procedure-assignee/?q=${search}`, { headers: {

  //         "Authorization" : 'Token '+str  }})
  //       .then(res=>{
  //        const assignee_List=[]
  //         if(res.data.status==="success"){

  //           const assigneeList=res.data.data
  //           assigneeList.map((person, index) => (assignee_List.push(person["id"])))

  //         setOptions(assigneeList);
  //         setOptionsDisplay(assignee_List)

  //         }

  //       })
  //       .catch(err=>{

  //   })

  //   },[search]);

  useEffect(() => {
    setTableData(tableData);
  }, [render, tableData]);

  const statusOptions = [
    { value: "assigned", label: "Assigned" },
    { value: "ongoing", label: "Ongoing" },
    { value: "completed", label: "Completed" },
    { value: "rejected", label: "Rejected" },
  ];

  const SuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>{props.msg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleVatChange = (e) => {
    setVat(e.target.value)
  }

  const handleAddProcedure = (e) => {
    e.preventDefault();

    console.log(price);
    console.log(currency.value);
    console.log(procedureId);

    // post api for Add procedure

    if (procedureId === "" ) {

      setErrorMsg("Please Select Procedure name from the Dropdown list");
      setErrorSubmit(true);
    } else if (price === "") {

      setErrorMsg("Please Enter Price");
      setErrorSubmit(true);
    } else if (vat === "" ) {

      setErrorMsg("Please Enter Vat");
      setErrorSubmit(true);
    } else {
      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

        const dataToSend = {
          procedure: procedureId,
          amount: price,
          currency: currency.value,
          vat: vat,
          is_active: true,
        };

        axios
          .post(`${APIURL}/api/v1/service-provider/hospital-procedure/`, dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            console.log('THE Response', res)
            
            if (res.data.status === "success") {
             
              
              setUpdateSuccess(true);

            } else {
              setErrorMsg(
                res.data.message ? res.data.message : "Error in submission"
              );

              setErrorSubmit(true);
            }
          })
          .catch((err) => {
            console.log('THE Err', err)
            setErrorMsg("Error in submission");
            setErrorSubmit(true);
          });
      }
    }
  };

  const handleCurrencyChange = (data) => {
    setCurrency(data);
  };

  // const handleToggle4 = ()=> {
  //   setToggle4(!toggle4)

  //   if(toggle4===true){
  //       setCurrencyNew('')
  //   }
  // }

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  var sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }

  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <EditRemark data={remarkData} list={remarkList} update={handleSend}/> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const UpdatePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>Procedure Successfully Added </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const InvoicePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <GenerateInvoiceBdo invoiceData={invoiceData} />   */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const InvoiceViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <GenerateInvoiceView invoiceData={invoiceData} />   */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderAssigned2 = (cellInfo) => {
    return (
      <div>
        <input
          style={{ backgroundColor: "#fafafa" }}
          value={tableData[cellInfo.index].assign_name}
          className="form-control"
          name="assignee"
          onChange={(e) => {
            e.preventDefault();

            const list = tableData;
            list[cellInfo.index].assign_name = e.target.value;

            setTableData(list);

            setCurrentIndex(cellInfo.index);
            setCurrentColumn(cellInfo.column.id);

            setDisplay(true);

            setSearch(e.target.value);
          }}
        />

        {currentIndex === cellInfo.index &&
          cellInfo.column.id === "assign_name" &&
          display && (
            <div className="suggestions-box-holder">
              <button
                className="btn-suggestion btn btn-secondary btn-small"
                title="close"
                onClick={() => setDisplay(false)}
              >
                {" "}
                X{" "}
              </button>

              <div className="suggestions-box suggestions-assignee-box">
                {options.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const List = tableData;
                        List[cellInfo.index].assign_id = item.id;
                        List[cellInfo.index].assign_name = item.name;

                        setTableData(List);
                        setDisplay(false);
                      }}
                    >
                      <div className="search-item each-option-suggestion">
                        <h6 className="">ID: {item.id} </h6>
                        <h6 className=""> {item.name}</h6>
                        <h6 className="">{item.email} </h6>
                        <h6 className=""> {item.mobile_number}</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    );
  };

  const enableDisableFeature = (item) => {
    console.log('The item', item);
    const isActive = item.is_active;
    const tokenString= sessionStorage.getItem('usertoken');
     
       let str= tokenString.replace(/["]+/g, '')  ;
    
       const dataToSend={
         is_active: isActive === true? false : true
       }

       console.log('the data', dataToSend);
     
        axios.put(`${APIURL}/api/v1/service-provider/hospital-procedure-detail/${item.id}/`,dataToSend, { headers: {
          
          "Authorization" : 'Token '+str,
          'Content-Type': 'application/json'  }
    
    
        })
    
        .then(res=>{
          console.log('the res', res)
         
          if(res.data.status=== "success"){
         
            // setChangeSuccess(true)
            setFetchTableData(true);
          }
          else{
         
            // setChangeError(true)
              
          }
        })
        .catch(err=>{
          console.log('the err',err)
     
          // setChangeError(true)
    
    })

  }

  const renderTable = (cellInfo) => {
    return (
      <div>
        {" "}
        {tableData[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  };

  const renderEditPrice = (cellInfo) => {
    let price = tableData[cellInfo.index][cellInfo.column.id];
    return (
      <input
            type="number"
            className="form-control procedure-form-width"
            min="0"
            step='any'
            value={price}
            onChange={(e) => {
            e.preventDefault();
            let x = e.target.value;
            console.log('THE PRICE', x)
            tableData[cellInfo.index][cellInfo.column.id] = x;
            const data = tableData;
            data[cellInfo.index].price = x;

            setTableData([...data]);
          }}
        />
    )
  };
  
  const renderEditVat = (cellInfo) => {
    let vat = tableData[cellInfo.index][cellInfo.column.id];
    return (
      <input
            type="number"
            className="form-control procedure-form-width"
            min="0"
            step='any'
            value={vat}
            onChange={(e) => {
            e.preventDefault();
            let x = e.target.value;
            console.log('THE vat', x)
            tableData[cellInfo.index][cellInfo.column.id] = x;
            const data = tableData;
            data[cellInfo.index].vat = x;

            setTableData([...data]);
          }}
        />
    )
  };


  const renderEnableDisable=(cellInfo)=> {
      if(tableData[cellInfo.index].is_active===true){
        return (
      
       <button className="btn btn-danger btn-smallest btn-square" 
          onClick={()=>{enableDisableFeature(tableData[cellInfo.index])}}> Disable </button>
       
       )
     }
     else if(tableData[cellInfo.index].is_active===false){
       return (
      
       <button className="btn btn-primary btn-col btn-smallest btn-square" 
          onClick={()=>{enableDisableFeature(tableData[cellInfo.index])}}> Enable </button>
       
       )
     }
   
  }



  const renderId = (cellInfo) => {
    if (tableData[cellInfo.index][cellInfo.column.id]) {
      return (
        <div className="fixed-col">
          {" "}
          {tableData[cellInfo.index][cellInfo.column.id]}
        </div>
      );
    } else {
      return null;
    }
  };
  const renderService = (cellInfo) => {
    return (
      <div>
        {" "}
        <span style={{ margin: "10px" }}>
          {tableData[cellInfo.index][cellInfo.column.id]}
        </span>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={
            <Tooltip className="my-tooltip" id="button-tooltip">
              Details:{" "}
              {tableData[cellInfo.index].details !== null
                ? tableData[cellInfo.index].details
                : "Not Available"}{" "}
              <br />
            </Tooltip>
          }
        >
          <i
            style={{ color: "var(--theme-blue)" }}
            className="fas fa-info-circle"
          ></i>
        </OverlayTrigger>
      </div>
    );
  };
  const renderContact = (cellInfo) => {
    return (
      <div>
        {" "}
        <span style={{ margin: "10px" }}>
          {tableData[cellInfo.index][cellInfo.column.id]}
        </span>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={
            <Tooltip className="my-tooltip" id="button-tooltip">
              {tableData[cellInfo.index].address !== null
                ? tableData[cellInfo.index].address
                : "Not Available"}{" "}
              <br />
            </Tooltip>
          }
        >
          <h6 className="overlay-address">Address</h6>
        </OverlayTrigger>
      </div>
    );
  };
  /*const renderDetails=(cellInfo)=> {
  
    return (

     <div > <p > {tableData[cellInfo.index][cellInfo.column.id]}</p></div>
      
    );
  }*/

  const renderStatus2 = (cellInfo) => {
    return (
      <>
        <Select
          value={tableData[cellInfo.index].statusObj}
          onChange={(data) => {
            const List = tableData;

            List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status = data.value;
            setTableData(List);
            setRender(!render);
          }}
          options={statusOptions}
          className={`status-select ${
            tableData[cellInfo.index].status === "new"
              ? "new-status"
              : tableData[cellInfo.index].status === "accepted"
              ? "accepted-status"
              : tableData[cellInfo.index].status === "ongoing"
              ? "ongoing-status"
              : tableData[cellInfo.index].status === "pending"
              ? "pending-status"
              : tableData[cellInfo.index].status === "cancelled"
              ? "cancelled-status"
              : tableData[cellInfo.index].status === "completed"
              ? "completed-status"
              : tableData[cellInfo.index].status === "delivered"
              ? "delivered-status"
              : tableData[cellInfo.index].status === "rejected"
              ? "rejected-status"
              : ""
          }`}
        />
      </>
    );
  };

  const renderInvoice = (cellInfo) => {
    if (tableData[cellInfo.index].payment_status === "paid") {
      return (
        <button
          className="btn btn-primary btn-col btn-square btn-small"
          onClick={() => {
            viewChanges(cellInfo.index);
          }}
        >
          View{" "}
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-primary btn-col btn-square btn-small"
          onClick={() => {
            saveChanges(cellInfo.index);
          }}
        >
          Generate{" "}
        </button>
      );
    }
  };

  const saveChangesProcedure=(index)=>{
    const rowToUpdate = tableData.filter((elt,i)=>{
      return i=== index
    })
  
    callSaveProcedure(rowToUpdate[0]);
    console.log('THE CEL TO UPDATE', rowToUpdate[0])
  
  }

  const saveChanges = (index) => {
    const rowToUpdate = tableData.filter((elt, i) => {
      return i === index;
    });

    setInvoiceShow(true);
    setInvoiceData(rowToUpdate[0]);
  };

  const viewChanges = (index) => {
    const rowToUpdate = tableData.filter((elt, i) => {
      return i === index;
    });

    setViewShow(true);
    setInvoiceData(rowToUpdate[0]);
  };
  const validate = (rowToValidate) => {
    let isValid = true;

    if (!rowToValidate.status && !rowToValidate.assign_id) {
      isValid = false;

      setErrorMsg("Enter status or assignee");

      setErrorSubmit(true);
    }

    return isValid;
  };

  const callSaveProcedure=(rowToUpdate)=>{

    // if(validate(rowToUpdate)){
  
    const tokenString= sessionStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')  ;
          const id=rowToUpdate.id;;
          const amount= rowToUpdate.amount;
          const vat= rowToUpdate.vat;
         const dataToSend = {
          // id: id,
          amount: amount,
          vat: vat,
        };

        axios
          .put(`${APIURL}/api/v1/service-provider/hospital-procedure-detail/${id}/`, dataToSend, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
          
        if(res.data.status==="success"){
      
        setSubmitMsg("Updated successfully!");
        setSuccessSubmit(true);
            
     
     } else {
        setErrorMsg(res.data.message ? res.data.message : "Error in submission");
        setErrorSubmit(true);    
      }
      
  
  
      })
      .catch(err=>{
    
     setErrorMsg( "Error in submission");
                setErrorSubmit(true);
  
  })
  // }
  }

  const callSave = (rowToUpdate) => {
    if (validate(rowToUpdate)) {
      const id = rowToUpdate.all_procedures.map((item) => {
        return item.procedure_details.procedure_id;
      });

      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");
      const procedure_id = id;
      const assign_id = rowToUpdate.assign_id;
      const status = rowToUpdate.status;

      const dataToUpdate = {
        procedure_id,
        assign_id,
        status,
      };

      axios
        .put(
          `${APIURL}/api/v1/hospital/patient-procedure-fulfilment/`,
          dataToUpdate,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setSubmitMsg(
              res.data.message ? res.data.message : "Updated successfully!"
            );
            setBackup("");
            setSuccessSubmit(true);
            setTableData([]);
          } else {
            setErrorMsg(
              res.data.message ? res.data.message : "Error in submission"
            );
            setErrorSubmit(true);
          }
        })
        .catch((err) => {
          setErrorMsg("Error in submission");
          setErrorSubmit(true);
        });
    }
  };

  /*const callSaveRemarks=(rowToUpdate)=>{

  if(validate(rowToUpdate)){

  const tokenString= localStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;
      const order_id=rowToUpdate.ref;
      const remarks= sendData
      const status= rowToUpdate.status;
      const assignee = rowToUpdate.assigned_to;
      const priority= rowToUpdate.priority;
   const dataToUpdate={
    order_id,
    remarks,
    status,
    assignee,
    priority
   }
   
    axios.post(`${APIURL}/api/v1/hospital/service-request-order/`,dataToUpdate, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
        
      if(res.data.status==="success"){
    
      setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");

          setSuccessSubmit(true);
          setTableData([]);
   
   }
       else{
       

         setErrorMsg(res.data.message ? res.data.message : "Error in submission");
              setErrorSubmit(true);
              
      }
    


    })
    .catch(err=>{
  
   setErrorMsg( "Error in submission");
              setErrorSubmit(true);

})
}
}*/

  const ProcedurePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <ProcedureList data={procedureData} toggle='bdo'/>  */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const viewProcedures = (data) => {
    setProcedureData(data);
    setProcedureShow(true);
  };

  const renderProcedures = (cellInfo) => {
    if (tableData[cellInfo.index].all_procedures) {
      if (tableData[cellInfo.index].all_procedures.length > 0) {
        return (
          <button
            onClick={() =>
              viewProcedures(tableData[cellInfo.index].all_procedures)
            }
            className="btn btn-primary btn-col btn-square btn-small"
          >
            View
          </button>
        );
      }
    }
  };

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      Cell: renderId,

      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().startsWith(filter.value.toLowerCase())
          : true;
      },
      width: 50,
    },
    {
      Header: "Procedure Name",
      accessor: "procedure_name",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 250,
    },
    {
      Header: "Procedure Type",
      accessor: "procedure_type",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 150,
    },
    {
      Header: <span>Price <i class="fas fa-eur" style={{fontSize: 16}}></i></span>,
      accessor: "amount",
      Cell: renderEditPrice,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 150,
      filterable: true,
      sortable: true,
    },
    {
      Header: <span>GST/VAT <i class="fas fa-percent" style={{fontSize: 16}}></i></span>,
      accessor: "vat",
      Cell: renderEditVat,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 150,
      filterable: true,
      sortable: true,
    },
   

    {
      Header: "Enable/Disable",
      accessor: "statusObj",
      Cell: renderEnableDisable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 120,
      filterable: true,
      sortable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "enable") {
          return row[filter.id].value === 'enable';
        }
        if (filter.value === "disable") {
          return row[filter.id].value === 'disable';
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="enable">Enable</option>
          <option value="disable">Disable</option>
        </select>
      ),
    },

    {
      Header: "",
      Cell: props=>{
        return (
        <button className="btn btn-primary btn-col btn-square btn-smallest" 
           onClick={()=>{saveChangesProcedure(props.index)}}>Save </button>
        )
      },
      sortable:false,
      filterable:false,
      minWidth:50,
      width:80
    },

    // {
    //   Header: "Invoice",
    //   Cell: renderInvoice,
    //   filterable:true,
    //   sortable:true,
    //   minWidth:50,
    //   width:100
    // }
  ];

  const displayTable = (
    <ReactTable
      columns={columns}
      data={tableData}
      defaultPageSize={5}
      className="service-req-table procedure-table"
      resizable
    ></ReactTable>
  );

  const handleBack = (e) => {
    navigate(-1);
  };
  return (
    <>

      <br />
      <div className="flex-head">

        <h2 className="title-of-tasks title-mg"> Procedure</h2>
      </div>
      <br />

      <div className="procedure-navbar ">
        <form className="procedure-form" onSubmit={handleAddProcedure}>
          <h5 className="text-type"> Procedure Name :</h5>
          {/* <label> procedure name:</label> */}
          <input
            type="text"
            className="form-control procedure-form-width"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {display ? (
            <div data-name="procedure-suggestions" className="suggest-display adjust-dimensions procedure-auto-suggest">
              {procedure.length > 0 ? (
                procedure.map((item) => {
                  return (
                    <p
                      onClick={(e) => handleClick(e, item)}
                      className="suggest-item text-secondary"
                    >
                     {/* {item.procedure_name} ,{item.procedure_type} */}
                     {item.procedure_name}
                    </p>
                  );
                })
              ) : (
                <h6 className="text-muted" style={{padding: 10}}>No Procedures found !!</h6>
              )}
            </div>
          ) : null}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h5 className="text-type"> Price :</h5>
          <input
            type="number"
            className="form-control procedure-form-width"
            min="0"
            step='0.1'
            value={price}
            onChange={handlePriceChange}
          ></input>
          <i class="fas fa-eur" style={{fontSize: 16}}></i>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h5 className="text-type"> GST/VAT :</h5>
          <input
            type="number"
            className="form-control procedure-form-width"
            min="0"
            step='0.1'
            value={vat}
            onChange={handleVatChange}
          ></input>
          <i class="fas fa-percent" style={{fontSize: 16}}></i>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* <h5 className="text-type">Currency :</h5>
          <Select
            value={currency}
            onChange={(data) => {
              handleCurrencyChange(data);
            }}
            options={currencyOptions}
            className="select-currency procedure-form-width"
          /> */}
          <button type="submit" className="btn btn-primary btn-col">
            {" "}
            Add Procedure
          </button>{" "}
          <br />
          <br />
        </form>
      </div>
      <br />
      <div className="">
        <div className="text-center">
          <div className="service-table-holder flex-col"> {displayTable}</div>
        </div>
      </div>
      <br />
      <br />

      <br />
      <br />
      

      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg("");
            // setTableData([]);
            // setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {procedureShow ? (
        <ProcedurePopup
          show={procedureShow}
          onHide={() => {
            setProcedureShow(false);
          }}
        />
      ) : null}

      {invoiceShow ? (
        <InvoicePopup
          show={invoiceShow}
          onHide={() => {
            setInvoiceShow(false);
            setRerender(!reRender);
          }}
        />
      ) : null}

      {viewShow ? (
        <InvoiceViewPopup
          show={viewShow}
          onHide={() => {
            setViewShow(false);
          }}
        />
      ) : null}

      {editShow ? (
        <EditPopup
          show={editShow}
          onHide={() => {
            setEditShow(false);
          }}
          onSave={() => {
            if (sendData !== tableData[cell].remarks) {
              const data = tableData;
              data[cell].remarks = sendData;
              setBackup(sendData);
              setTableData(data);
              setEditShow(false);
              setRender(!render);
              setUpdateSuccess(true);
              //saveChanges(cell)
            }
          }}
        />
      ) : null}

      {submitMsg !== "" ? (
        <SuccessPopup
          show={successSubmit}
          msg={submitMsg}
          onHide={() => {
            setSuccessSubmit(false);
            setSubmitMsg("");
            // setTableData([]);
            setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {updateSuccess ? (
        <UpdatePopup
          show={updateSuccess}
          onHide={() => {
            setUpdateSuccess(false);
            setFetchTableData(true);
              setSearchTerm('');
              setProcedureId('');
              setPrice('');
              setVat('');
              setCurrency(defaultCurrency);
            
          }}
        />
      ) : null}
    </>
  );
}

export default ProcedureNamesBdo;

import React, { useState, useCallback, useEffect, useRef,useContext } from 'react'
//import {useHistory} from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {DatePicker} from 'rsuite';
import axios from "axios";
import Select from 'react-select';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL, TZ } from "../../Global";
import { useAlert } from 'react-alert'
import VerifyOTPPatient from "../BDO/VerifyOTPPatient";
import PaymentRegistration from "../BDO/PaymentRegistration";
//import HospBdoNavbar from '../HospBdoNavbar/HospBdoNavbar';
import './hospregisterpatient.css'
import 'react-table-v6/react-table.css'
//import GeneratePatientInvoice from './GeneratePatientInvoice';
import debounce from 'lodash.debounce';
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
function HospRegisterPatient() {
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const [img, setImg] = useState();
  const [isValidSsn, setIsValidSsn] = useState(true);
  const [previewOn, setPreviewOn] = useState(false);
  const [userDatas, setUserDatas] = useState({
    first_name: "",
    last_name: '',
    email: "",

    consent: "",
    gender: "",
    dob: "",
    country: "Malta",
    state: "",
    phone: {
      dialcode: "356",
      rawphone: "",
      mobile_number: "",
    },
    identitycard:  { value: '', label: 'Select identity card' },
    pincode: "",
    identitynumber: "",
    province: "",
    city: "",
    location: "",
    ss_number:""

  });
  let ItemID = [];

  const [isValidIdentity, setIsValidIdentity] = useState(true);
  const [patientId, setPatientId] = useState('')

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(false);

  const alert = useAlert()
  const [errorMsg, setErrorMsg] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [successShow, setSuccessShow] = useState(null);

  const [modalShow, setModalShow] = useState(false)

  const [dob, setDob] = useState();
  const [, setFormattedDob] = useState("");

  const inputRefs = useRef([])
  const [isvalidnum, setIsValidnum] = useState(true)
  const submitButtonRef = useRef(null)
  const [invalidPic, setInvalidPic] = useState(null);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  const [confirmDelete, setDeleteShow] = useState(false);



  const [error, setError] = useState({
    username: "",

    phone: "",

  });
  const handleConfirm = () => {
    closeConfirmationPopup()
    handleRegister();
   
  };

  const handleCancel = () => {
    closeConfirmationPopup()
  };
  const identityoptions = [
    { value: '', label: 'Select identity card' },
   
  { value: 'passport', label: 'Passport' },

  { value: 'driving_license', label: 'Driving license' },

  ]
  const handleChange = e => {

    e.preventDefault();
    const val =  trimspace(e.target.value);
    const key = e.target.name;
    setUserDatas(currentstate => ({
      ...currentstate,
      [key]: val
    }));
    setError((currentstate) => ({
      ...currentstate,
      [key]: "",
    }));



  }
  const handleChangeLoc = e => {

    e.preventDefault();
    const val =  trimspace(e.target.value);
    const key = e.target.name;
    setUserDatas(currentstate => ({
      ...currentstate,
      location: val
    }));



  }
  const handleChangessn = (e) => {
    setError((currentstate) => ({
      ...currentstate,
      ss_number: "",
    }));
    if (e.target.value.includes('#')) {
      setError((currentstate) => ({
        ...currentstate,
        ss_number: "'#' is not allowed",
      }));
  }    
    setIsValidSsn(true)
    const x=e.target.value
  const val = x.trim()
    const key = e.target.name;
    
      setUserDatas((currentstate) => ({
        ...currentstate,
        [key]: val,
      }));
   
    e.preventDefault();
    
    const data = {
      ss_number:val
    };

    axios
      .post(`${APIURL}/api/v1/account/check-user/ `, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.is_existing_user === true) {

            setError(currentstate => ({
              ...currentstate,
              ss_number: "Social security number already registered!"
            }));
            setIsValidSsn(false);
          }
          else {
            setIsValidSsn(true);

          }

        }
        else {

          setIsValidSsn(true);

        }

      })
      .catch(err => {


      })

  }
  
      
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {

      if (index !== 7) {
        e.preventDefault();
        const nextIndex = index + 1;
        if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
          inputRefs.current[nextIndex].focus();
        }
      }
    }
  };

  const handleKey = (event) => {
    if (event.altKey && event.key === 's') {
      submitButtonRef.current.click();
    } else if (event.key === "Enter") {
      const activeElement = document.activeElement;
      if (
        !inputRefs.current.includes(activeElement)
      ) {
        submitButtonRef.current.click();
      }
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener('keydown', handleKey);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, []);




  const refreshPage = () => {
    window.location.reload(false);
  }


  const SubmitPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Verified Successfully!!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleCheckExistingIdentity = (e) => {
    setIsValidIdentity(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        identitynumber: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        idcard_no: e.target.value,
        idcard_type: userDatas.identitycard.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                identitynumber: `This identity number is already registered!`,
              }));
              setIsValidIdentity(false);
            } else {
              setIsValidIdentity(true);
            }
          } else {
            setIsValidIdentity(true);
          }
        })
        .catch((err) => { });
    }
    else if (e.target.value === "") {
      setError((currentstate) => ({
        ...currentstate,
        identitynumber: "",
      }));
    }
  };


  const handleDOB = (date) => {

    setDob(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))

      let DateExtracted = dateUpdated.toISOString().split('T')[0];

      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);

      setUserDatas(currentstate => ({
        ...currentstate,
        dob: DateExtracted
      }));
    }
    setError((currentstate) => ({
      ...currentstate,
      "dob": "",
    }));
  }



  const selectRegion = (val) => {
    // let value1=e.target.value;
    setUserDatas(currentstate => ({
      ...currentstate,
      state: val
    }));
    setError((currentstate) => ({
      ...currentstate,
      "state": "",
    }));
  }

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setUserDatas(currentstate => ({
      ...currentstate,
      gender: genderOpted

    }))
    setError((currentstate) => ({
      ...currentstate,
      "gender": "",
    }));
  }





  const handlePhoneInput = (value, data, event, formattedValue) => {


    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    setUserDatas(currentstate => ({
      ...currentstate,
      phone: {
        dialcode: "356",
        rawphone: rawPhone,
        mobile_number: value
      }

    }));

    setError((currentstate) => ({
      ...currentstate,
      "phone": "",
    }));


  }

  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {

      const rawPhone = userDatas.phone.rawphone;

      const dialcode = userDatas.phone.dialcode;
      setError(currentstate => ({
        ...currentstate,
        phone: ''
      }));



      const data = {
        code: dialcode,
        mobile_number: rawPhone
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                phone: "Phone number already registered!"
              }));
              setIsValidPhone(false);
            }
            else {
              setIsValidPhone(true);

            }


          }
          else {

            setIsValidPhone(true);

          }

        })
        .catch(err => {


        })

    }


  }




  const handleCheckExisting = e => {

    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError(currentstate => ({
        ...currentstate,
        email: ''
      }));
      e.preventDefault();
      let key = e.target.name;


      const data = {
        email: e.target.value
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                email: `This ${key} is already registered!`
              }));
              setIsValidEmail(false);
            }
            else {
              setIsValidEmail(true);
            }


          }
          else {

            setIsValidEmail(true);

          }

        })
        .catch(err => {


        })
    }

  }



  const IdentityChange = (data) => {


    setUserDatas(current => ({
      ...current, identitycard: data
    }))
  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Are you sure you want to delete the uploaded image ?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={() => {
            setPreviewOn(false);
            setDeleteShow(false);
          }}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }





  const validate = () => {
    let input = userDatas;
    let errors = {};
    let isValid = true;
    setError({});
if(!isValidIdentity){
  setError((currentstate) => ({
    ...currentstate,
    identitynumber: `This identity number is already registered!`,
  }));
}
    
    if (!input["first_name"] || input["first_name"] === "") {
      isValid = false;
      errors["first_name"] = "Enter first name";
    }


    if (!input["pincode"] || input["pincode"] === "") {
      isValid = false;
      errors["pincode"] = "Enter zipcode";
    }


    if (!input["last_name"] || input["last_name"] === "") {
      isValid = false;
      errors["last_name"] = "Enter last name";
    }

    if (!isValidSsn) {
      isValid = false;
     
    }

    if (!isvalidnum) {
      isValid = false;

    }



    if (!input["state"] || input["state"] === "") {
      isValid = false;
      errors["state"] = "Select a region";
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }
    if (input["email"] !== "" && isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    if (input["email"] !== "") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Enter a valid email address.";
      }
    }


    if (typeof input["first_name"] !== "undefined") {

      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern2.test(input["first_name"])) {
        isValid = false;
        errors["first_name"] = "Special Characters are not allowed";
      }
    }

    if (typeof input["last_name"] !== "undefined") {

      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern3.test(input["last_name"])) {
        isValid = false;
        errors["last_name"] = "Special Characters are not allowed";
      }
    }


    if (!userDatas.phone.dialcode || userDatas.phone.dialcode === "" || !userDatas.phone.rawphone || userDatas.phone.rawphone === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }

    if (userDatas.phone.rawphone.length > 0 && userDatas.phone.rawphone.length < 8) {
      isValid = false;
      errors["phone"] = "Check phone number";
    }


    if ( dob === "" || dob === null||typeof dob == "undefined") {
      isValid = false;
      errors["dob"] = "Enter your date of birth";
    }
  
    
    if (typeof userDatas.gender == "undefined" || userDatas.gender === "" || userDatas.gender === null) {
      isValid = false;
      errors["gender"] = "Select gender";
    }




    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;
  }


  const handleContinue = async (e) => {


    e.preventDefault();



    if (validate() && isValidPhone && isValidEmail && isValidIdentity) {

      setShowConfirmation(true);


     
    }

  }

  const handleRegister = () => {
    let formData = new FormData();
    if (userDatas.identitynumber !== "" && !isValidIdentity) {

      setErrorMsg("Check the identity number")
      setErrorShow(true)
    }
    else if (userDatas.state === "") {

      alert.error("Select the region")
    }
    else if (userDatas.identitycard.value !== "" && userDatas.identitynumber === "") {
      alert.error("Enter identity card number")
    }
    else {


      const tokenString = sessionStorage.getItem('usertoken');

      let str = tokenString.replace(/["]+/g, '');

      // let user = {

      //   first_name: userDatas.first_name,
      //   last_name: userDatas.last_name,


      //   gender: userDatas.gender,
      //   //country_code:userDatas.phone.dialcode,
      //   dob: userDatas.dob,
      //   province: userDatas.province ? userDatas.province : "-",
      //   city: userDatas.city ? userDatas.city : "-",
      //   country: "Malta",
      //   location: userDatas.location ? userDatas.location : "-",
      //   state: userDatas.state,
      //   pincode: userDatas.pincode,
      //   code: userDatas.phone.dialcode,
      //   mobile_number: userDatas.phone.rawphone,
      //   idcard_type: userDatas.identitycard.value ? userDatas.identitycard.value : "-",
      //   idcard_no: userDatas.identitynumber ? userDatas.identitynumber : "-",
      //   src: "web",

      //   hospital_id: login_datas.hospital_id,



      // }
      formData.append("login_consent",  "False");
      formData.append("login_enabled",  "False");
      formData.append("signup_method",  "mobile");
      formData.append("hospital_id", login_datas.hospital_id);
      formData.append("first_name", userDatas.first_name);
      formData.append("last_name", userDatas.last_name);
      formData.append("code", userDatas.phone.dialcode)
      formData.append("mobile_number", userDatas.phone.rawphone)
      formData.append("dob",userDatas.dob);
      formData.append("gender",userDatas.gender);
      formData.append("apt_no",userDatas.apt_no);
      formData.append("country", userDatas.country);
      formData.append("city", userDatas.city);
      formData.append("state", userDatas.state);
      formData.append("location", userDatas.location);
      formData.append("pincode", userDatas.pincode);
      if (userDatas.identitycard.value !== "" ) {
        formData.append("idcard_type", userDatas.identitycard.value);
        formData.append("idcard_no", userDatas.identitynumber);
      }
      formData.append("src", "web");
      if (img !== "") {
        formData.append("photo", img)
      }
if(userDatas.email!==""){
  formData.append("email", userDatas.email);
}
if(userDatas.ss_number!==""){
  formData.append("ss_number", userDatas.ss_number);
}




      axios.post(`${APIURL}/api/v1/account/signup/`, formData, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Token " + str
        }
      })
        .then(res => {


          if (res.data.status === "success") {



            setPatientId(res.data.patient_id)
            alert.success("Patient Registered Successfully!!")
            // setSuccessShow(true);

            setUserDatas({
              first_name: "",
              last_name: "",
              email: "",
              consent: "",
              gender: "",
              dob: "",
              ss_number:"",
              country: "Malta",
              state: "",
              apt_no:"",
              phone: {
                dialcode: "356",
                rawphone: "",
                mobile_number: "356",
              },
              identitycard: { value: '', label: '' },
              pincode: "",
              identitynumber: "",
              province: "",
              city: "",
              location: ""
            });
            setImg("")
            setPreviewOn(false)
            setDob("")
          }
          else {

            setErrorMsg(res.data.message);
            setErrorShow(true);

          }

        })
        .catch(err => {
          alert.error("Error in registration")

        })
    }

  }

  const trimspace = (val) => {

    return val.startsWith(' ')
      ? val.trimLeft()
      : val;
  }

  const handleChangeidnum = (e) => {
    setError((currentstate) => ({
      ...currentstate,
      identitynumber: "",
    }));
    setIsValidIdentity(true)
   
   
    const x=e.target.value
    const val = x.trim()
    const key = e.target.name;
    
      setUserDatas((currentstate) => ({
        ...currentstate,
        [key]: val,
      }));
     
        const data = {
          idcard_no: val,
          idcard_type: userDatas.identitycard.value,
        };
        axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                identitynumber: `This identity number is already registered!`,
              }));
              setIsValidIdentity(false);
            } else {
              setIsValidIdentity(true);
            }
          } else {
            setIsValidIdentity(true);
          }
        })
        .catch((err) => {});
      
    }
  const reader = new FileReader();
  const uploadImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {

      alert.error('Select an image')
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {

      alert.error('Select valid image')
      return false;
    }
    if (file.size > 10e6) {
      alert.error("You have exceeded image size limit 10MB");
      return false;
    }

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        setImg(file);
        setInvalidPic(null);
      };

      img.onerror = () => {
        setPreviewOn(false);
        alert.error('Invalid Image Content')
        return false;
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);

    const objectURL = URL.createObjectURL(file);

    setUserDatas((currentstate) => ({
      ...currentstate,
      img_url: objectURL,
    }));
    setPreviewOn(true);


  };



  const validatePhoneInput = (value,) => {

    if (value.length !== 11 && value.length !== 3) {
      setIsValidnum(false)
      return "Invalid phone number";

    }
    else {
      setIsValidnum(true)
      return true
    }


  }
  const isValidDate = (date) => {
    // Validate if the date matches the MM/DD/YYYY format
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    return dateFormat.test(date.toLocaleDateString('en-US'));
  };
  return (
    <div>

      <form className="">

        <div className="hosp-patient-form" style={{ marginTop: "1%", width: "80%" }}>

          <h4 className='title-of-tasks'>Patient Details</h4>

          <br />
          <div className=''>



            <div className='row' style={{ paddingLeft: "3%" }}>
              <div className="col-6 co-md-12 row">
                
                <div className="col-6">
                  <label >* First Name: </label>
                  <input ref={el => inputRefs.current[1] = el} onKeyDown={(e) => handleKeyDown(e, 1)} value={userDatas.first_name} className="enquiryinput" type="text" name="first_name" onChange={handleChange} maxLength='100'
                  />

                  {error.first_name ? <div className="error-validation-msg">{error.first_name}</div> : null}
                </div>
                <div className="col-6">
                <label className="">* Last Name: </label>
                  <input ref={el => inputRefs.current[2] = el} onKeyDown={(e) => handleKeyDown(e, 2)} className="enquiryinput" value={userDatas.last_name} type="text" name="last_name" onChange={handleChange} maxLength='100'
                  />
                  {error.last_name ? <div className="error-validation-msg">{error.last_name}</div> : null}
                 
                </div>
                <div>
                <label style={{ marginTop: "3%" }} className="phone-hov">* Phone: </label>
                <PhoneInput
                  inputProps={{
                    ref: el => inputRefs.current[3] = el,
                    tabIndex: 0,
                    onKeyDown: e => handleKeyDown(e, 3),
                  }}
                  country={"mt"}
                  onlyCountries={["mt"]}
                  countryCodeEditable={false}
                  value={userDatas.phone.mobile_number}
                  onChange={handlePhoneInput}
                  onBlur={handleCheckExistingPhone}
                  isValid={validatePhoneInput}

                />
                {error.phone ? <div style={{width:"80%"}}className="error-validation-msg"> {error.phone}</div> : null}
                </div>
              </div>
              <div className='co-md-12 col-6' style={{ display: "flex" }}>
                <div className='col-8' >
                <label >Social Security No: </label>
                  <input ref={el => inputRefs.current[0] = el} onKeyDown={(e) => handleKeyDown(e, 0)} value={userDatas.ss_number} className="enquiryinput" type="text" name="ss_number" onChange={handleChangessn} maxLength='50'
                  />
                 {error.ss_number ? <div className="error-validation-msg"> {error.ss_number}</div> : null}
                  <label style={{ marginTop: "5%" }} className=""> Email Address: </label>
                  <input ref={el => inputRefs.current[4] = el} onKeyDown={(e) => handleKeyDown(e, 4)} className="form-control" type="email" value={userDatas.email} name="email" onChange={handleChange} onBlur={handleCheckExisting} maxLength='50' />

                  {error.email ? <div className="error-validation-msg"> {error.email}</div> : null}
                </div>
                <div className='col-4' style={{ paddingTop: "5%" }}>
                 
                    {previewOn === false ? (
                      <div className="pic-container">
                        <input

                          type="file"
                          className="custom-file-input"
                          id="validatedCustomFile"
                          onChange={uploadImage}
                        />
                        {invalidPic && <p className="error">{invalidPic}</p>}
                        <label className="" htmlFor="validatedCustomFile">
                          <p>Upload Profile Picture</p>
                          <div className="camera-icon">
                            <i className="fa fa-camera camera-icon"></i>
                          </div>
                        </label>

                      </div>
                    ) : (
                      <div>
                        <div onClick={() => {
                          setDeleteShow(true);
                        }}>
                          <i style={{float:"right", color:"red"}}
                            className="fa fa-window-close "
                          ></i>
                        </div>
                        <img
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                          src={userDatas.img_url}
                          alt="Hospital Picture"
                          className="pic-container"
                        />
                      </div>
                    )}
                
                </div>
              </div>
            </div>



            <div className='form-align' style={{ marginTop: "2%" }}>
              <div style={{ alignSelf: "center", marginBottom: "2%",textAlign:"center"}} className="form-control item-left-row">


                <span>* Date of Birth:</span>&nbsp;&nbsp;
                <DatePicker
style={{width:"50%"}}
                  onChange={date => { handleDOB(date) }}
                 
                  maxDate={new Date()}
                  value={dob}

                />
                {error.dob ? <div className="error-validation-msg" style={{ textAlign: "left", marginLeft: "-20px", marginTop: "5px", }}> {error.dob}</div> : null}

              </div>
              <div className="form-control item-left-row" style={{ alignSelf: "center", marginBottom: "2%", textAlign:"center"}} >

                <span>* Gender:</span>&nbsp;&nbsp;
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  checked={userDatas.gender === "male"}
                  onClick={handleGenderRadioButton}

                />&nbsp;
                <label htmlFor="male">Male</label>
&nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  checked={userDatas.gender === "female"}
                  onClick={handleGenderRadioButton} />
&nbsp;
                <label htmlFor="female">Female</label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  id="others"
                  name="gender"
                  value="others"
                  checked={userDatas.gender === "others"}
                  onClick={handleGenderRadioButton}
                />&nbsp;
                <label htmlFor="others">Others</label>

                {error.gender ? <div className="error-validation-msg" style={{ marginTop: "5px", marginLeft: "-20px", textAlign: "left" }}> {error.gender}</div> : null}
              </div>


              <div className="item item-left-row">
                <label className=""> Identity Card: </label>
                <Select
                isDisabled={userDatas.identitynumber!==""&&userDatas.identitynumber!==null}
                style={{height:"44px"}}
                  value={userDatas.identitycard}
                  onChange={(data, e) => {
                    IdentityChange(data, e)
                  }}
                  options={identityoptions}

                  name='identitycard'
                  className="select-currency select-flex1"
                />
                {/* {error.identitycard ? <div className="error-validation-msg"> {error.identitycard}</div> : null} */}
              </div>



              {userDatas.identitycard.value !== "" ? 
              <div className="item item-left-row">

<label className=""> Identity Card Number:</label>
                <input className="form-control" value={userDatas.identitynumber} type="text" name="identitynumber" placeholder="" onChange={handleChangeidnum} maxLength='20'
                />
                {error.identitynumber ? (
                  <div className="error-validation-msg">
                    {" "}
                    {error.identitynumber}
                  </div>
                ) : null}
              </div> : null}

              <div className="item item-left-row">
                <label className=""> Address Line1: </label>
                <input ref={el => inputRefs.current[5] = el} onKeyDown={(e) => handleKeyDown(e, 5)} className="form-control" type="text" value={userDatas.apt_no} name="apt_no" maxLength="200" placeholder="Apartment/ Flat no" onChange={handleChange}
                />
                {/* {error.cpr ? <div className="error-validation-msg"> {error.cpr}</div>  : null} */}
              </div>

              <div className="item item-left-row">
                <label className=""> Address Line2: </label>
                <input ref={el => inputRefs.current[6] = el} onKeyDown={(e) => handleKeyDown(e, 6)} className="form-control" type="text" maxLength="200" value={userDatas.location} name="location" placeholder="Street name" onChange={handleChangeLoc}
                />
                {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
              </div>
              <div className="item item-left-row">
                <label className=""> Address Line3: </label>
                <input ref={el => inputRefs.current[7] = el} onKeyDown={(e) => handleKeyDown(e, 7)} className="form-control" value={userDatas.city} type="text" name="city" placeholder="City" onChange={handleChange}
                />
                {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
              </div>
              <div className="country-selector item-left-row">
                <label className=""> Country: </label>
                <input className="form-control"
                  value={"Malta"}
                  disabled
                //onChange={(val) => selectCountry(val)}
                />

              </div>
              <div className="region-selector item-left-row patientregistration_selector">
                <label className="">* Region:  </label>
                <RegionDropdown
                style={{backgroundColor:"white",border:"1px solid #E3E3E3",height:"44px"}} 
                  country={"Malta"}
                  className=""
                  value={userDatas.state}
                  onChange={(val) => selectRegion(val)} />
                {error.state ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.state}</div> : null}

              </div>


              <div className="item item-left-row">
                <label className="">* Zipcode: </label>
                <input ref={el => inputRefs.current[8] = el} onKeyDown={(e) => handleKeyDown(e, 8)} className="form-control" value={userDatas.pincode} type="text" name="pincode" onChange={handleChange} maxLength='50'
                />
                {error.pincode ? <div className="error-validation-msg"> {error.pincode}</div> : null}
              </div>



            </div>
            <br />
            <div className="form-button">
              <button
                ref={submitButtonRef}
                onClick={handleContinue}
                className="btn btn-primary btn-col"
              >Register</button>
            </div>
          </div>
        </div>
      </form>






      {confirmDelete ? (
        <ConfirmPopUp
          show={confirmDelete}
          onHide={() => {
            setDeleteShow(false);
          }}
        />
      ) : null}
      {modalShow ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            // data.modalClose();

            // setSubmitMsg("");
          }}
        />
      ) : (
        ""
      )}
  {showConfirmation && (
      <SubmitConfirmationPopup
        message="Are you sure you want to submit the form?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    )}
    </div>
  )
}

export default HospRegisterPatient


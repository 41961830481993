import React, {useState,useEffect} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function WeightAdd(props) {
  console.log(props,"props")

    const [dispense, setDispense]=useState(props.seteditDispence !== "" ? props.seteditDispence : "")

    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const [limitShow , setLimitShow]= useState(false)
    const [arr,setArr]=useState(props.history)
    const alert = useAlert()
    const handleChange = (e)=> {
       
        const val = e.target.value
        if (props.intake === "nos") {
          if(val!==""&&val<0){
            alert.error("Enter valid number")
          }
          else if (val <= (props.ordered-props.dispatched) && val !== "0") {
            setDispense(e.target.value===""?e.target.value:Number(e.target.value));
          } 
          else if(val>(props.ordered-props.dispatched)){
            alert.error("Check the count you added");
          }
        } else {
          if(val!==""&&val<0){
            alert.error("Enter valid number")
          }
          else{
        
          setDispense(e.target.value===""?e.target.value:Number(e.target.value));
          }
         
        }
        
    }
    useEffect(()=>{
      let x=arr
      let n=0
      x.map((data,i)=>{
        n=n+data.dispatched_count
          x[i].remaining= props.ordered-n  
       console.log(n)
      })
      setArr(x)
     },[arr]);
    const handleSubmit = () => {

       
     if(dispense===props.seteditDispence){
        alert.error("already exisit")
      }

     else if(dispense){

           props.onSuccess(dispense)
           setModalShow(true)
                
                props.handle()
    }

   

else{
  alert.error("add count")
}   
    }

    const SubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-success'>Dispense count added Successfully !!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const LimitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-muted'>Please check weight value</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

    return (<div >
   
   
    
    
                       {props.history.length>0&&<><div className='row' >
                       <div className="col-2"></div>
                        <div className="col-4 serHead"><b>Dispatched</b></div>
                        <div className="col-4 serHead"><b>Date</b></div>
                       

                    </div>
                    {arr.map((data,i)=>{
                      return(
                        <div  className=' row' >
                           <div className="col-2"></div>
                        <span className='col-4 serBody'>{data.dispatched_count}</span>
                        <span className='col-4 serBody'>{data.dispatched_date.slice(0,10)}</span>
                       
                        </div>
                      )
                    })}</>}
   
   
   <br/>
        <div className='row' style={{display:"flex",margin:"auto"}} >
          <span className='col-4'>Dispensing Count:</span>
           <input style={{}} type="number"  placeholder="Dispense count " className="col-4"    
       
       value={dispense} onChange={handleChange} />
           <button disabled={dispense===props.seteditDispence} className="col-2" style={{color:"white",backgroundColor:"rgb(42, 99, 121)"}} onClick={handleSubmit}>Update</button> 

           </div>
           {
      modalShow ? 
      <SubmitPopup
        show={modalShow}
        onHide= {() => { setModalShow(false); props.onHide(); props.onHide();     
        }}
      /> : null
    }

{
      errorShow ? 
      <ErrorPopup
        show={errorShow}
        onHide= {() => { setErrorShow(false)     
        }}
      /> : null
    }

{
      limitShow ? 
      <LimitPopup
        show={limitShow}
        onHide= {() => { setLimitShow(false)     
        }}
      /> : null
    }

      
        </div>
    )
}

export default WeightAdd;

import React, { useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
const SubmitConfirmationPopup = ({ message, onConfirm, onCancel }) => {
  const [showConfirmation, setShowConfirmation] = useContext(ConfirmationContext);
  const handleConfirm = () => {
    Swal.fire({
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#2b69a3",
      cancelButtonColor: "#dc3545",
      backdrop: "static",
      allowOutsideClick: false,
      reverseButtons: true, 
    }).then((result) => {
      if (result.isConfirmed) {
        setShowConfirmation(false)
        onConfirm();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        onCancel();
      }
    });
  };

  useEffect(() => {
    handleConfirm();

    const handleBackButton = () => {
      setShowConfirmation(false)
      Swal.close();
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return null;
};

export default SubmitConfirmationPopup;

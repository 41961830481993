import React,{useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import PharmacyUser from './PharmacyUser';
import ManagerCard from "../Manager/ManagerCard";
import "./pharmacylist.css";
import axios from 'axios';
import { APIURL,TZ } from "../../Global";
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import LoadingOverlay from 'react-loading-overlay';
import BackButton from '../common/BackButton';
function PharmacyUserList(){
  const [loading,setLoading]=useState(false);
const [pharmUsersList,setPharmUserList] = useState([]); 
const [freeCount, setFreeCount]= useState();
const [activeCount, setActiveCount]= useState()
   const [errorMsg, setErrorMsg]= useState("");
   const [refresh, setRefresh]= useState(false)
	
   
 
  

 
 useEffect(() => {
   setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g,""); 

   
    axios
      .get(`${APIURL}/api/v1/service-provider/pharmacy-user/?${TZ}`, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " +v2},
      })
      .then((res) => {
        
          const datas = res.data;
        
          setLoading(false)
          setPharmUserList(datas.reverse());
       
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }, [refresh]); 

  const refreshHandle= ()=> {
    setRefresh(!refresh)
  }

	return(
  
  <div className="">




	

		{pharmUsersList.length!== 0  ? (<div><br/>
		<div className="">
    <ManagerCard
                     
                     bdoList={pharmUsersList}
                     user="pharmacyuser"
                     handle={refreshHandle}
                   />
         </div>
       
      
       </div>)
       :
       <h5 style={{color: "red" ,margin: "20px"}}>Pharmacy Users List is empty </h5>
   }

    {errorMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data fetching.Please try again!
          </h5>
      :
      null}
		
  
    </div>
		);
}

export default PharmacyUserList;
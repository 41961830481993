import React, { useState, useEffect, useContext } from "react";
import "./cart.css";
import {
  Link,
  useNavigate,
  NavLink,
  useLocation,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import PatientNav from "./PatientNav";
import loginpage from "./assets/img/skip2.png";
import horizonlogo from "./assets/img/horizonlogo.png";
import { APIURL,TZ } from "../../Global";
import PhoneInput from "react-phone-input-2";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import { LoginContext } from "../contexts/LoginContext";
import { Modal, Form, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { Button } from "react-bootstrap";

function SkipPage2 ({ setShowNavbarAndFooter }){
    let navigate = useNavigate();

    useEffect(() => {
        setShowNavbarAndFooter(false);
        return () => setShowNavbarAndFooter(true);
      }, [setShowNavbarAndFooter]);
    return(

        <>
      <section className="products-area ptb-70" style={{ marginTop: "2%" }}>
        <div className="container" style={{ borderRadius: "2%" }}>
          <div class="row" style={{ borderRadius: "2%" }}>
        
            <div class="col-lg-12 col-md-12">
 
          
                <div
                  className="leftpage"
                  style={{
                    backgroundImage: `url(${loginpage})`,
                    backgroundSize: "cover",
                    width:"500px",
                    marginLeft:"30%",
                    height:"500px",
                    marginTop:"-5%"
                  }}
                >
                </div>
                <h5 style={{fontFamily: 'Titillium Web',marginLeft:"48%",color: "#6BCBB8"}}>Make Payment</h5>
              <h6 style={{marginLeft:"25%",fontFamily: 'Titillium Web'}}>  Payment is the transfer of money services in exchange product or Payments typically made terms agreed</h6>
              <a href="#" style={{fontFamily: 'Titillium Web',marginLeft:"52%",color: "#6BCBB8" ,fontSize:"18px"}}   onClick={() => navigate("/loginpatient")} >Skip</a>
  <button className="getstart" style={{marginLeft:"45%"}}   onClick={() => navigate("/skippage3")} >Get Start</button>

 


                </div>
                </div>
                </div>
                </section>
        </>
    )
}

export default SkipPage2;
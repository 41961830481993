
import React, { useState, useEffect, useRef } from 'react'

import PrintProvider, { Print, NoPrint } from "react-easy-print";
import printJS from "print-js";
function FormService(props) {


    return (

        <div className='container'>


            <Print>
                <div id="printablediv">


                    <div className='row' >

                        <h6 className='col-4' >
                            Ward :{" "}{props.medData[0].admit_details}

                        </h6>
                        <h6 className='col-4' style={{ textTransform: "capitalize" }}>
                            Nurse :{" "}{props.medData[0].nurse}

                        </h6>
                        <h6 className='col-4' style={{ textTransform: "capitalize" }}>
                            Pharmacist : {" "}{JSON.parse(sessionStorage.getItem("logindatas")).name}

                        </h6>
                    </div>

                    <br /><br />

                    <div className="row">
                        <div className="col-4 serHead"><b>{props.item === "med" ? "Medicines" : "Consumables"}</b></div>
                        <div className="col-2 serHead"><b>count</b></div>
                        <div className="col-3 serHead"><b>Patient</b></div>
                        <div className="col-3 serHead"><b>Doctor</b></div>

                    </div>
                    {props.medData.map((item) => {
                        return (
                            <div className="row " style={{ marginTop: "1%" }}>
                                <div className="col-4 serBody">{item.request_items}</div>
                                <div className="col-2 serBody">{item.dispensing_count}</div>
                                <div className="col-3 serBody">{item.patient}</div>
                                <div className="col-3 serBody">{item.doctor}</div>
                            </div>
                        )
                    })}
                </div>



            </Print>

            <button onClick={() => { printJS("printablediv", "html") }} style={{
                border: "1px solid #00657c", padding: "1%", marginTop: "2%",
                borderRadius: "20px",
                textDecoration: "none",
                curser: "pointer",
                backgroundColor: "rgb(42, 99, 121)",
                color: "white"
            }}>&nbsp;Print &nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M18 17.5v1.25A2.25 2.25 0 0 1 15.75 21h-7.5A2.25 2.25 0 0 1 6 18.75v-1.251l-1.75.001A2.25 2.25 0 0 1 2 15.25V9.254a3.25 3.25 0 0 1 3.25-3.25l.749-.001L6 5.25A2.25 2.25 0 0 1 8.25 3h7.502a2.25 2.25 0 0 1 2.25 2.25v.753h.75a3.254 3.254 0 0 1 3.252 3.25l.003 5.997a2.249 2.249 0 0 1-2.248 2.25H18Zm-2.25-4h-7.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h7.5a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75Zm.002-9H8.25a.75.75 0 0 0-.75.75l-.001.753h9.003V5.25a.75.75 0 0 0-.75-.75Z" /></svg></button>

        </div>

    )
}
export default FormService;
import React, {useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function BloodAdd(props) {
    const alert = useAlert();
   
    const [blood, setBlood]=useState(props.seteditblood !== " "||props.seteditblood !== null ? props.seteditblood : "")
    
    const handleChange = (e)=> {
        const val = e.target.value
       
            setBlood(e.target.value)
      
    }

    const handleSubmit = () => {

      

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               "blood_group": blood
           }
     
     
       
         axios.put(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
            alert.success(res.data.message)
               props.onSuccess()
          
       }
       else{
        alert.error(res.data.message)
        
       }
     
         })
         .catch(err=>{
       
          
     })

    

    }


    return (
        <div className='row'>
           <input type="text" min='0' max='250' placeholder="Add Blood group" value={blood} onChange={handleChange}  className="enquiryinput col-8"/>
          <span className='col-1'></span>
      <button disabled={blood===""||props.seteditblood===blood} onClick={handleSubmit} style={{
        borderRadius: "10px",
        backgroundColor: "rgba(43, 147, 160, 1)",
        textAlign: "center",
        color: "white",

      }} className=' col-2'>Add</button>


           
     
        </div>
    )
}

export default BloodAdd

import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../../../Global";
import { useAlert } from 'react-alert'
import "./deals.css"
import LoadingOverlay from "react-loading-overlay";
function BestSelling(props) {
    const alert = useAlert();
    const [bestSelling, setBestSelling] = useState([])
    const [itemGroup, setItemGroup] = useState({})
    const [itemGroupArray, setItemGroupArray] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [refresh1, setRefresh1] = useState(false)
    const [update, setUpdate] = useState(false)
    const [promoId, setPromoId] = useState(0)
    const [disabled, setDisabled] = useState(false)
    const aggr_type = sessionStorage.getItem("aggr_type") ? sessionStorage.getItem("aggr_type") : null
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        bestSelling.map((item) => {
            setItemGroup((currentstate) => ({
                ...currentstate,
                [item.item_service]:false
            }))
           
        });
    }, [bestSelling]);
  

    const handleCancelSlot = (e) => {
        setDisabled(false)
        const key = e.target.id;
        setItemGroup((currentstate) => ({
            ...currentstate,
            [key]: !itemGroup[key],
        }))
    };

    useEffect(() => {
        setLoading(true)
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
        axios.get(`${APIURL}/api/v1/service-provider/best-selling-services/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Token ' + str
                }
            })
            .then(res => {
                if (res.data.status === "success") {
                    setBestSelling(res.data.message)
                    
                }
                
            })
           
    }, [])
    useEffect(() => {
       
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
        axios.get(`${APIURL}/api/v1/service-provider/add-promotions/?promo_type=best_selling`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Token ' + str
                }
            })
            .then(res => {
                if (res.data.status === "success") {
                    if (res.data.items.length > 0) {
                        setUpdate(true)
                        setDisabled(true)
                        setPromoId(res.data.items[0].promotion_id)
                    }
                    res.data.items.map((item) => {
                        setItemGroup((currentstate) => ({
                            ...currentstate,
                            [item.item_service]: true,

                        }))
                    });
                    setLoading(false)
                    setRefresh1(!refresh1)
                }               
            })      
    }, [refresh])
    useEffect(() => {
      
        let updatedAgeArray = [];
        for (var key in itemGroup) {
            if (itemGroup[key] === true) {
                updatedAgeArray.push(key);
            }
        }
        setItemGroupArray(updatedAgeArray);
    }, [itemGroup, refresh1]);
    
    const handleSubmit = () => {
        var numberArray = [];
        itemGroupArray.forEach(ele => numberArray.push(+ele))
        const tokenStr = sessionStorage.getItem("usertoken");

        const data = {
            promotions:
                [{
                    "promo_type": "best_selling",
                    "promotion_items": numberArray
                }]
        }

        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.post(`${APIURL}/api/v1/service-provider/add-promotions/?${aggr_type==="aggregator"?"aggregator=True":""}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        alert.success("Successfully Updated")
                        setRefresh(!refresh)
                    } else {
                        alert.error("Error in submission")
                    }
                })
                .catch((err) => {
                    alert.error("Error in submission");
                });
        }

    };
    const handleUpdate = () => {
        var numberArray = [];
        itemGroupArray.forEach(ele => numberArray.push(+ele))
        const tokenStr = sessionStorage.getItem("usertoken");

        const data = {
            "items": numberArray
        }

        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.put(`${APIURL}/api/v1/service-provider/add-promotions/${promoId}/?type=items`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        alert.success("Successfully Updated")
                        setRefresh(!refresh)
                    } else {
                        alert.error("Error in submission")
                    }
                })
                .catch((err) => {
                    alert.error("Error in submission");
                });
        }

    };

    const display = bestSelling.map((item, index) => {

        return (
            <div style={{ display: "flex" }} className="col-4">

                <input
                    className=""
                    disabled={aggr_type==="non-aggregator"?!itemGroup[item.item_service] && itemGroupArray.length > 3:!itemGroup[item.item_service] && itemGroupArray.length > 1}
                    type="checkbox"
                    id={item.item_service}
                    name={item.item_service}
                    checked={itemGroup[item.item_service]}
                    onChange={handleCancelSlot}
                />&nbsp;&nbsp;
                <label style={{ fontWeight: "200", marginTop: "5%", textTransform: "capitalize" }} className="schedule-subtitle">
                    {item.item_service_name}</label>
            </div>
        );
    })
    return (<div className="container">
         <h6 style={{ color: "#1FAEDB", marginTop: "2%" }} className="text-center"><b>
         Best Selling Products
            </b></h6>
         <LoadingOverlay
            active={loading}
            spinner
        >
       {aggr_type==="non-aggregator"?<h6 style={{ font: "Titillium Web", fontWeight: "500" }} className="title-of-page"> Select 4 Best Selling products </h6>
       :<h6 style={{ font: "Titillium Web", fontWeight: "500" }} className="title-of-page"> Select 2 Best Selling products </h6>}
       <div style={{ marginLeft: "5%" }} className=" row">

            {display}

        </div><br />
        <div className="form-button" style={{ alignItems: "center", justifyContent: "center" }}>
            <button disabled={disabled} onClick={update ? handleUpdate : handleSubmit} style={{ backgroundColor: "#115269", marginLeft:"80%" }} className="webpromo">{update ? "Update" : "Save"}</button></div>
            </LoadingOverlay>
    </div>
    );

}
export default BestSelling;
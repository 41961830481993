import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import moment from 'moment'
import { useAlert } from "react-alert";
import SimpleBar from 'simplebar-react';

function CallforAction(props) {
    const navigate = useNavigate();
    const alert = useAlert();
    const [requests,setRequests]=useState([])
    const [type,setType]=useState(props.from==="all"?"all":"pat")
    const[refresh,setRefresh] = useState(false);
    const[refresh1,setRefresh1] = useState(false);
    const [id,setId]=useState(props.id&&props.id)
    const [rid,setrId]=useState()
    const [pinned,setPinned]=useState(false)
    const [action,setAction]=useState("")
    const [mob,setMob]=useState()
    const [searchTerm, setSearchTerm] = useState("");
    const [patients, setPatients] = useState([])
    const [display, setDisplay] = useState(false);
    const [callforname,setCallforName]=useState("")
    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        if (searchTerm !== "") {

           let url = `${APIURL}/api/v1/service-provider/patient-search-list/?per_page=30&page=10&pa=${searchTerm}`
            axios
                .get(url, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setPatients(res.data.patients)
                        setDisplay(true)
                    }
                    else {

                    }
                }).catch((error) => {

                })

        }
        else {
            setPatients([])
            setDisplay(false)
        }

    }, [searchTerm,])
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
        if (e.target.value === "") {
          setSearchTerm(e.target.value)
            setDisplay(false)
            setPatients([])

        }
    }
    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        let url=type==="all"?"/api/v1/doctor/call-for-action/":"/api/v1/doctor/call-for-action/?patient_id="+id
        axios
            .get(`${APIURL}${url}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.status === 200) {

                    setRequests(res.data.message)

                }
            })
            .catch((err) => { });

    }, [refresh]);
    useEffect(() => {handleUpdate()},[refresh1])
    
    const handleUpdate = () => {
       
        const tokenStr = sessionStorage.getItem("usertoken");
        let data={}

       if(action==="pinned"){
        data={is_pinned:!pinned}
       }
       else{
        data={is_read:true, doc_attended:true}
       }

        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.put(`${APIURL}/api/v1/doctor/call-for-action/?id=${rid}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        // alert.success("Successfully Updated")
                        setRefresh(!refresh)
                        if(action==="read"){
                            if(callforname!=="deceased"){
                            navigate(`/patient-navigation/id/${id}/mob/${mob}`, {
                                state: { id: id, mobile: mob },
                              })
                            }
                              props.onClose()
                        }
                    } else {
                        // alert.error("Error in submission")
                    }
                })
                .catch((err) => {
                    // alert.error("Error in submission");
                });
        }

    };

    return(
        <div>
            <div className="row" style={{width:"100%",paddingLeft:"2.5%", paddingRight:"2.5%"}}>
            {props.from==="pat"? <div className="col-6" style={{padding:"1%"}}>
                    <button className='enquiryinput' onClick={() => { setType("pat");setRefresh(!refresh) }} disabled={props.from==="all"} style={{backgroundColor:type==="pat"?"rgba(60, 104, 158, 1)":"white",color:type==="pat"?"white":"black",borderRadius:"5px",width:"100%",border: "1px solid rgba(172, 172, 172, 1)"}}>{props.from==="pat"?props.name:"patient"}</button>
                </div>:<div className="col-6" style={{padding:"1%"}}> <input name="resident"
                            placeholder="Search name or ID"
                            value={searchTerm}
                           style={{color:type==="pat"&&searchTerm!==""&&"rgb(60, 104, 158)",fontWeight:"bolder"}}
                            onChange={handleSearchChange} className='enquiryinput' />
                              {
                   display?
<SimpleBar style={{ maxHeight: "180px", overflowX:'hidden' }}>
                        <div className=' ' style={{ width: "100%",borderRadius:"5px",border:"1px solid rgba(0, 0, 0, 0.1)" }}>
                            <button  style={{ backgroundColor: "#F8F8F8", marginLeft: "80%" ,}} variant="secondary" onClick={() => { setDisplay(false) }}>
                                <i class="fa fa-window-close  " style={{ color: "#0d6efd" ,fontSize:"17px"}} aria-hidden="true"></i>
                            </button>
                            {
                                patients.length > 0 ? patients.map((item) => {
                                    return (
                                        <div style={{ fontWeight:"700",width:"100%"}} onClick={() => {
                                            setType("pat")
                                           setSearchTerm(item.patient_name)
                                           setId(item.id);
                                           setRefresh(!refresh)
                                            setDisplay(false); 
                                        }} className='suggest-item text-secondary row'>
                                            <div className='col-9'>{item.patient_name}</div>
                                            <div className='col-3'>ID:{item.id}</div>
                                           
                                        </div>
                                    )
                                }) : <h6 className='text-muted'>No Patients found !!</h6>
                            }
                        </div>
</SimpleBar>
                        : null
                }
                            </div>}
                <div className="col-6" style={{padding:"1%"}}>
                    <button  className='enquiryinput' onClick={() => { setType("all");setRefresh(!refresh) }} style={{backgroundColor:type==="all"?"rgba(60, 104, 158, 1)":"white",color:type==="all"?"white":"black",border: "1px solid rgba(172, 172, 172, 1)",borderRadius:"5px",width:"100%"}}>All Patients</button>
                </div>
            </div>
            <SimpleBar style={{ maxHeight: '500px',padding:"4.5%" }}>
                {requests.length > 0 ? requests.map((item) => {
                    return (
                       
                        <div style={{ paddingRight: "1%" ,width:"100%",marginRight:"1%",backgroundColor:"rgba(255, 251, 237, 1)"}} className="callaction_lists row">
                           
                            <div className="col-10" style={{ paddingLeft: "2%" }} >
                              <h6 style={{textTransform:"capitalize"}}><b>{item.cal_for_name}</b>-<span style={{color:"red"}}>{item.cal_for_reason}</span></h6>
                              <h6 style={{textTransform:"capitalize"}}><b>{item.patient_name}</b></h6>
                              <span>{moment(item.created_at).format('DD-MM-YYYY')===moment(new Date()).format('DD-MM-YYYY')?"Today":moment(item.created_at).format('DD-MM-YYYY')}&nbsp;&nbsp;@{moment(item.created_at).format('HH:mm:ss')}</span>
                            </div>
                            <div className="col-2" style={{ padding: "1%", }}>
                                <svg style={{float:"right"}}  onClick={() =>{setrId(item.id);setAction("pinned");setPinned(item.is_pinned);setRefresh1(!refresh1)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill={item.is_pinned===true?"rgba(51, 195, 0, 1)":"none"} stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 4.5l-4 4L7 10l-1.5 1.5l7 7L14 17l1.5-4l4-4M9 15l-4.5 4.5M14.5 4L20 9.5"/></svg><br/><br/>
                                <svg style={{float:"right"}} onClick={() =>{setId(item.patient);setMob(item.mobile_number);setrId(item.id);setAction("read");setCallforName(item.cal_for_name);setRefresh1(!refresh1)}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill={item.is_read===true?"gray":"rgba(60, 104, 158, 1)"} d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 16H5V7h14v12zm-5.5-6c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5zM12 9c-2.73 0-5.06 1.66-6 4c.94 2.34 3.27 4 6 4s5.06-1.66 6-4c-.94-2.34-3.27-4-6-4zm0 6.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"/></svg>
                                </div>
                               
                        </div>

                    )

                }) :

                    <div className="" style={{ marginTop: "10%", color: "red" }}>No alerts!!</div>
                }
            </SimpleBar>
        </div>
    )
}

export default  CallforAction

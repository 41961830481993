import React, { useState, useEffect ,useContext} from "react";
import { Link,useLocation, useNavigate } from "react-router-dom";
import "./hospitalupdatecarer.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import axios from 'axios';
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL,TZ } from "../../Global";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";

import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../common/SubmitConfirm";

function HospitalUpdateCarer(props) {
  
  const { state } = useLocation()
  const id =state.id;
  const [modalShow, setModalShow] = useState(false);
  const [nurseDatas, setNurseDatas] = useState({
    name: "",

    phone_number: "",
    email: "",

    specializations: "",
    qualification: [],
    department: "",
    id: ''


  })

  const [error, setError] = useState({
    email: '',
    phone: "",
  });
  const [isvalidnum,setIsValidnum]=useState(true)
  const [submitMsg, setSubmitMsg] = useState("");
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const alert = useAlert();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);

  //const history = useHistory();

  // const [keys,] = useState({ 'd': false, 'a': false, 'b': false, 'o': false, 'n': false, 's': false, 'l': false, 'p': false, 'c': false, 'h': false });


  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  // useEffect(() => {

  //   window.onkeydown = (e) => {

  //     keys[e.key] = true

  //     if (e.altKey && keys['d']) {
  //       history.push('/doctors-list')
  //     }
  //     if (e.altKey && keys['a']) {
  //       history.push('/hospital/add')
  //     }
  //     if (e.altKey && keys['b']) {
  //       history.push('/bdo-list')
  //     }
  //     if (e.altKey && keys['o']) {
  //       history.push('/hospital/add-bdo')
  //     }
  //     if (e.altKey && keys['n']) {
  //       history.push('/emergency-number')
  //     }
  //     if (e.altKey && keys['s']) {
  //       history.push('/service-request-list')
  //     }
  //     if (e.altKey && keys['l']) {
  //       history.push('/license-details')
  //     }
  //     if (e.altKey && keys['p']) {
  //       history.push('/license-payment')
  //     }
  //     if (e.altKey && keys['c']) {
  //       history.push('/hosp-calllog')
  //     }
  //     if (e.altKey && keys['h']) {
  //       history.push('/payment-history')
  //     }

  //   }
  //   window.onkeyup = (ev) => {

  //     keys[ev.key] = false
  //   }

  //   return () => {
  //     window.onkeydown = null
  //     window.onkeyup = null
  //   }
  // }, []);

  useEffect(() => {
    const tokenStr = sessionStorage.getItem('usertoken');

    if (tokenStr) {

      const v = tokenStr;
      let v2 = v.replace(/["]+/g, '');







      axios.get(`${APIURL}/api/v1/service-provider/carer-detail/${id}/?${TZ}`, {

        
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + v2
        }
      })
        .then(res => {
        


          if (res.data.status === "success") {

            if (res.data.carer && res.data.carer.phone_number && !res.data.carer.phone_number.startsWith("356")) {
              res.data.carer.phone_number = "356" + res.data.carer.phone_number;
            }
            setNurseDatas(res.data.carer);
            window.original_value = res.data.carer;

          } else if (res.data.status === "error") {

            setSubmitMsg(res.data.message)

          } else {

            setSubmitMsg("Error in fetching data!")

          }

          setRawPhone(res.data.carer.phone_number.slice(3));


        })
        .catch(err => {
          setSubmitMsg("Error in fetching data!")

        })



    }

  }, [id])
  const validatePhoneInput = (value,) => {
  
    if (value.length !== 11&&value.length !== 3) {
      setIsValidnum(false)
        return "Invalid phone number";
  
    }
    else {
      setIsValidnum(true)
        return true
    }
  
  
  }
  const getChangedData = (nurseDatas) => {
    let objectByKeys = {};
    if (window.original_value) {
      let sda_changed_keys = Object.keys(nurseDatas).filter(key => nurseDatas[key] !== window.original_value[key]);
      sda_changed_keys.forEach(key => {
        objectByKeys[key] = nurseDatas[key]
      });
      delete window.original_value;
    }
    return objectByKeys;
  }

  const handleSubmit = () => {

    const tokenStr = sessionStorage.getItem('usertoken');

    if (tokenStr) {

      const v = tokenStr;
      let v2 = v.replace(/["]+/g, '');



      const x = getChangedData(nurseDatas);
      if(x.phone_number){
        delete x.phone_number
       x["phone"]=rawPhone
       x["code"]=dialCode
      }
      let dataToSend = x

      axios.put(`${APIURL}/api/v1/service-provider/carer-detail/${id}/`, dataToSend, {
        headers: {
          'Content-Type': 'application/json',
          Accept: "application/json",
          Authorization: 'Token ' + v2
        }
      })
        .then(res => {
          if (res.data.status === "success") {
            alert.success('Updated successfully!')
            navigate('/carermanagement')

          } else if (res.data.status === "error") {

            setSubmitMsg(res.data.message);
          }
          else {

            setSubmitMsg("Error in data update!");
          }


        })
        .catch(err => {
          setSubmitMsg("Error in data update!");

        })



    }




  }

  const handleCheckEmail = (e) => {
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError(currentstate => ({
        ...currentstate,
        email: ''
      }));
      e.preventDefault();
      let key = e.target.name;


      const data = {
        [e.target.name]: e.target.value
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`
              }));
              setIsValidEmail(false);
            }
            else {

              setIsValidEmail(true);

            }


          }
          else {

            setIsValidEmail(true);

          }

        })
        .catch(err => {


        })
    }
  }


  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {

      setError(currentstate => ({
        ...currentstate,
        phone: ''
      }));



      const data = {
        code: dialCode,
        mobile_number: rawPhone
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                phone: "This phone number is already registered!"
              }));
              setIsValidPhone(false);
            }
            else {

              setIsValidPhone(true);

            }



          }
          else {

            setIsValidPhone(true);

          }

        })
        .catch(err => {


        })

    }


  }
  const handleConfirm = () => {
    closeConfirmationPopup()
    handleSubmit();
   
  };

  const handleCancel = () => {
    closeConfirmationPopup()
  };

  const handleContinue = async (e) => {

    e.preventDefault();

    if (validate() && isValidEmail && isValidPhone) {
      setShowConfirmation(true);
    }

  }

  const handleChange = e => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setNurseDatas(currentstate => ({
      ...currentstate,
      [key]: val
    }));

  }

 

  const handleQualification = e => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    let arr = [];
    if (val && val.includes(',')) {
      arr = val.split(',');
    }
    else {
      arr.push(val);
    }
    setNurseDatas(currentstate => ({
      ...currentstate,
      [key]: val
    }));

  }

  const handlePhoneInput = (value, data, event, formattedValue) => {
    setError(currentstate => ({
      ...currentstate,
      phone: ''
    }));

    setRawPhone(value.slice(data.dialCode.length));
    setDialCode(value.slice(0, data.dialCode.length))
    setNurseDatas(currentstate => ({
      ...currentstate,
      phone_number: value

    }));
  }

  const validate = () => {

    let input = nurseDatas
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["name"] || input["name"] === "") {
      isValid = false;
      errors["name"] = "Enter name";
    }

    if (!input["specializations"] || input["specializations"] === "") {
      isValid = false;
      errors["specializations"] = "Enter specializations";
    }

    if (!input['qualification'] || input['qualification'].length === 0 || input['qualification'][0] === "") {
      isValid = false;
      errors["qualification"] = "Enter qualification";
    }
    if (!isvalidnum) {
      isValid = false;
      //errors["phone"] = "Enter valid phone number";
    }
    if (!input['phone_number'] || input['phone_number'] === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }
    if ( rawPhone.length ===0) {
      isValid = false;
      errors["phone"] = "Enter phone number ";
    }
    if (rawPhone.length > 0 && rawPhone.length < 8) {
      isValid = false;
      // errors["phone"] = "Enter valid phone number ";
    }

    if (typeof input["email"] !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Enter a valid email address.";
      }
    }

    if (typeof input["name"] !== "undefined") {

      var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern1.test(input["name"])) {
        isValid = false;
        errors["name"] = "Special Characters are not allowed";
      }
    }

    if (!input["email"]) {
      isValid = false;

      errors["email"] = "Enter email address.";
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;

  }

  return ( <div style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} className=''>
  <div className='row'>
      <div className="col-lg-2 col-md-2">
        <h4
          style={{
            color: "#245693",
            marginTop: "8%",
            fontFamily: "inter",
            fontWeight: "700",
            marginLeft: "20px",
          }}
        >
          Users Classifications
        </h4>
        <div className="leftdivdeathrepo">

          <button className="deathreportbutton" onClick={e => { navigate("/doctormanagement") }} style={{ backgroundColor: "#F8F8F8", color: "rgb(0, 68, 124)" }}>
            {" "}
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256a128 128 0 1 0 0-256a128 128 0 1 0 0 256m-96 55.2C54 332.9 0 401.3 0 482.3C0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7c0-81-54-149.4-128-171.1V362c27.6 7.1 48 32.2 48 62v40c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16s7.2-16 16-16v-24c0-17.7-14.3-32-32-32s-32 14.3-32 32v24c8.8 0 16 7.2 16 16s-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-40c0-29.8 20.4-54.9 48-62v-57.1q-9-.9-18.3-.9h-91.4q-9.3 0-18.3.9v65.4c23.1 6.9 40 28.3 40 53.7c0 30.9-25.1 56-56 56s-56-25.1-56-56c0-25.4 16.9-46.8 40-53.7zM144 448a24 24 0 1 0 0-48a24 24 0 1 0 0 48" /></svg>{" "}
            &nbsp;&nbsp;<b>Doctor</b>
          </button>
          <button className="deathreportbutton" onClick={e => { navigate("/bdomanagement") }} style={{ backgroundColor: "#F8F8F8", color: "rgb(0, 68, 124)" }}>
            {" "}
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 100 100"><path fill="currentColor" d="m80.161 60.441l-15.66-7.47l-6.622-3.159c2.892-1.822 5.241-4.634 6.778-8.021a21.743 21.743 0 0 0 1.945-8.99c0-1.827-.29-3.562-.694-5.236c-1.97-8.112-8.305-14.088-15.91-14.088c-7.461 0-13.7 5.763-15.792 13.644c-.483 1.808-.815 3.688-.815 5.68c0 3.459.808 6.684 2.181 9.489c1.587 3.254 3.94 5.937 6.804 7.662l-6.342 2.953l-16.168 7.53c-1.404.658-2.327 2.242-2.327 4.011v17.765c0 2.381 1.659 4.311 3.707 4.311h24.013V72.92a.78.78 0 0 1 .119-.396l-.01-.006l3.933-6.812l.01.006c.14-.24.389-.41.687-.41c.298 0 .547.169.687.41l.004-.003l.036.063c.005.01.012.018.016.028l3.881 6.721l-.005.003a.783.783 0 0 1 .119.397v13.602h24.013c2.048 0 3.708-1.93 3.708-4.311V64.446c.003-1.763-.905-3.332-2.296-4.005M54.62 55.886l.01.006l-3.934 6.812l-.01-.006a.796.796 0 0 1-.687.409a.796.796 0 0 1-.687-.409l-.005.003l-.04-.069c-.003-.007-.009-.013-.012-.02l-3.881-6.723l.004-.003a.783.783 0 0 1-.119-.397c0-.445.361-.806.806-.806h7.866c.445 0 .806.361.806.806a.762.762 0 0 1-.117.397" /></svg>{" "}
            &nbsp;&nbsp;<b>BDO</b>
          </button>
          <button className="deathreportbutton" onClick={e => { navigate("/pharmacymanagement") }} style={{ backgroundColor: "#F8F8F8", color: "rgb(0, 68, 124)" }}>
            {" "}
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 3h12v2H3zM2 21h14V6H2zm3-9h2.5V9.5h3V12H13v3h-2.5v2.5h-3V15H5zm15-6c-1.68 0-3 1.76-3 4c0 1.77.83 3.22 2 3.76V21h2v-7.24c1.17-.54 2-1.99 2-3.76c0-2.24-1.32-4-3-4" /></svg>{" "}
            &nbsp;&nbsp;<b>Pharmacist</b>
          </button>
          <button className="deathreportbutton" onClick={e => { navigate("/nursemanagement") }} style={{ backgroundColor: "#F8F8F8", color: "rgb(0, 68, 124)" }}>
            {" "}
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 448 512"><path fill="currentColor" d="M96 128V70.2c0-13.3 8.3-25.3 20.8-30l96-36c7.2-2.7 15.2-2.7 22.5 0l96 36c12.5 4.7 20.8 16.6 20.8 30V128h-.3c.2 2.6.3 5.3.3 8v40c0 70.7-57.3 128-128 128s-128-57.3-128-128v-40c0-2.7.1-5.4.3-8zm48 48c0 44.2 35.8 80 80 80s80-35.8 80-80v-16H144zm-32.1 151.7c10.5-3.4 21.8.4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5c65 20.9 112 81.7 112 153.6c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6M208 48v16h-16c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h16v16c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h16c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8h-16V48c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8" /></svg>{" "}
            &nbsp;&nbsp;<b>Nurse</b>
          </button>
          <button onClick={() => navigate("/carermanagement")} className="deathreportbutton" style={{ backgroundColor: "rgb(0, 68, 124)", color: "white" }} >
            {" "}
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M4.685 2.422a2.315 2.315 0 1 1 4.63 0a2.315 2.315 0 0 1-4.63 0M2.446 6.214a2.536 2.536 0 0 1 3.492 0l.01.01L7 7.276l1.053-1.052l.01-.01a2.536 2.536 0 0 1 3.49 0a2.378 2.378 0 0 1 .003 3.448l-4.208 4.09a.5.5 0 0 1-.697 0l-4.207-4.09a2.378 2.378 0 0 1 .002-3.447Z" clip-rule="evenodd" /></svg>{" "}
            &nbsp;&nbsp;<b>Carer</b>
          </button>



        </div>
      </div>
      <div className="col-lg-10 col-md-10" style={{ paddingTop: "2%", paddingBottom: "2%" }}>


    <div className="add-dr-form-holder">
 

     
      <form className="add-dr-form" onSubmit={handleContinue}>
        <h4 className="title-of-page"> Update Carer details</h4>
        <div className="add-dr-div">
          <div className="form-group col-lg-6">
            <label > *Full Name: </label>
            <input className="form-control" type="text" maxLength='200' name="name" value={nurseDatas.name} onChange={handleChange} />
            {error.name ? <div style={{ zIndex: 'auto' }} className="error-validation-msg">{error.name}</div> : null}
          </div>

          <div className="form-group col-lg-6">
            <label> *Email: </label>
            <input className="form-control " type="email" maxLength='50' value={nurseDatas.email} name="email" onChange={handleChange} onBlur={handleCheckEmail} />
            {error.email ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.email}</div> : null}
          </div>
          <div className="form-group col-lg-6">
            <label> *Phone Number: </label>
            {/*  <input className="form-control " type="text" value={doctorDatas.phone} name="phone" onChange={handleChange} required/>
           */}
            {/* value={this.state.phone}  */}

            <PhoneInput
              inputProps={{


              }}
              country={"mt"}
                onlyCountries={["mt"]}
                countryCodeEditable={false}
              value={nurseDatas.phone_number}
              onChange={handlePhoneInput}
              onBlur={handleCheckExistingPhone}
              isValid={validatePhoneInput}    
            />

            {error.phone ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.phone}</div> : null}

          </div>



          <div className="form-group col-lg-6">


            <label> *Qualification: </label>
            <input className="form-control" type="text" maxLength='200' value={nurseDatas.qualification} name="qualification" onChange={handleQualification} />
            {error.qualification ? <div style={{ zIndex: 'auto' }} className="error-validation-msg">{error.qualification}</div> : null}
          </div>
          <div className="form-group col-lg-6">

            <label> *Specializations: </label>
            <input className="form-control" type="text" maxLength='255' value={nurseDatas.specializations} name="specializations" onChange={handleChange} />
            {error.specializations ? <div style={{ zIndex: 'auto' }} className="error-validation-msg">{error.specializations}</div> : null}
          </div>

          <div className="form-group col-lg-6">
            <label> Department: </label>
            <input className="form-control" type="text" maxLength='200' value={nurseDatas.department} name="department" onChange={handleChange} />
          </div>







        </div>
        <div className="form-button"
>        <button type="submit" className="btn btn-primary btn-col"> Submit Data</button></div>
      </form>



      {submitMsg !== "" && submitMsg !== null ?
        <h5 style={{ color: "red" }}>

          {submitMsg}
        </h5> :
        null
      }
     
    </div>
    </div>
    </div>
    {showConfirmation && (
      <SubmitConfirmationPopup
        message="Are you sure you want to submit the form?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    )}
  </div>
  )
}

export default HospitalUpdateCarer;


import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
// import "./service.css";
import { SOCKETURL } from "../../Global";
import { useNavigate, Navigate, Link, useLocation, } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import nurseInv from "../../assets/images/nurseInv.jpg"
import MedOrders from "./MedOrders";
import ConOrders from "./ConOrders";
// import ServiceHistoryDoc from "./ServiceHistoryDoc";
// import ServiceHistoryDoc1 from "./ServiceHistoryDoc1";

function NurseOrders() {
  let navigate = useNavigate();
  const [selected, setSelected] = useState("medicine")
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [displayAlert, setDisplayAlert] = useState(false)
  const [reRender, setRerender] = useState(false);
  const [newMed, setNewMed] = useState(false)
  const [newCon, setNewCon] = useState(false)
  useEffect(() => {
    handlingWebsocket();
  }, [])
  const webSocket = useRef(null);
  const handlingWebsocket = () => {
    // if(socketLoaded) {
    //   return;
    // }
    const tokenString = sessionStorage.getItem("usertoken");
    const login = JSON.parse(sessionStorage.getItem("logindatas"));
    const nurseId = login.user_id
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(
      `${SOCKETURL}/ws/call_action/nurseinv_` +
      nurseId +
      "/?token=" +
      str
    );
    webSocket.current.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log("WebSocket connected inside servicereq ");
      // setSocketLoaded(true);
    };

    webSocket.current.onmessage = (evt) => {

      const message = JSON.parse(evt.data);
      console.log(message);
      if (message.message.item_type === "medicine") {


        setNewMed(true)
      }
      else if (message.message.item_type === "consumables") {
        setNewCon(true)
      }

    };

    webSocket.current.onclose = () => {
      console.log("disconnected");

    };
  }
  const reloadTable = (e) => {
    //setMedData([])


    setRerender(!reRender)
    setSelected("medicine")
  };
  const reloadTable1 = (e) => {


    setRerender(!reRender)
    setSelected("consumables")

  };
  return (
    <>

      <div className='row' style={{ width: "100%" }}>

        {/* <div className="col-lg-2 col-md-2">
                       
                        <div className="leftdivdeathrepo">
                           
                            <h6
                                style={{
                                    color: "#2D3748",
                                    fontFamily: "Helvetica",
                                    fontWeight: "700",
                                    marginLeft: "20px",
                                    marginTop: "5px",
                                    fontSize: "12px",
    
                                }}
                            >
                              
                            </h6>
                            <button className="deathreportbutton" onClick={e => {setSelected("medicine")}} style={{ backgroundColor: selected === "medicine" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "medicine" ? "white" : "black" }}>
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M8.6 16.4a4.6 4.6 0 0 1-6.5-6.5l7.8-7.8a4.6 4.6 0 0 1 6.5 6.5M5.999 5.999l4.25 4.25" /><path d="M23.25 15.75a7.669 7.669 0 0 1-6 7.5a7.669 7.669 0 0 1-6-7.5V13.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v2.25Zm-6-.75v4.5M15 17.25h4.5" /></g></svg>
                            
                                &nbsp;&nbsp;Medicine
                            </button>
                            <button className="deathreportbutton" onClick={e => { setSelected("consumables")}} style={{ backgroundColor: selected === "consumables" ? "rgba(0, 68, 124, 1)" : "#F8F8F8", color: selected === "consumables" ? "white" : "black" }}>
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M22.003 3v4.497A.503.503 0 0 1 21.5 8v0a.52.52 0 0 1-.466-.3A10 10 0 0 0 12.003 2c-5.185 0-9.449 3.947-9.95 9" /><path d="M17 10v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2m-5 1V8" /><path d="M2.05 21v-4.497c0-.278.226-.503.504-.503v0c.2 0 .38.119.466.3a10.001 10.001 0 0 0 9.03 5.7c5.186 0 9.45-3.947 9.952-9" /></g></svg>
                                &nbsp;&nbsp;Consumables
                            </button>
                            
                        </div>
                    </div> */}
        <div className="col-lg-12 col-md-12 text-center" style={{ paddingRight: "2%" }}>
          <div class="row" style={{ paddingTop: "4px", marginBottom: "0.5%" }}>
            <div className="col-1"> </div>
            <div className="col-8">
              <h6
                style={{
                  color: "#245693",
                  textAlign: "left",
                  fontFamily: "inter",
                  fontWeight: "700",
                  fontSize:"22px",
                  paddingTop:"1%"

                }}
              >
                Medicine Orders
              </h6>
            </div>


            <div className="col-3">
              <button onClick={(e) => { reloadTable() }}
                type="button"
                style={{
                  border: newMed ? "2px solid red" : "1px solid #00657c",
                  boxShadow: newMed && "rgba(0, 0, 0, 0.25) 0px 4px 4px 0px",
                  borderRadius: "10px",
                  textDecoration: "none",
                  curser: "pointer",
                  padding: "2.5% 2% 2.5% 2%",
                  color: newMed ? "white" : "#007bff",
                  background: newMed && "rgb(0, 68, 124)",
                  marginTop: "1%"
                }}
                className="notification1  btn-link"
              >

                Update Medicine

              </button>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <button

                type="button"
                style={{
                  border: "1px solid #00657c",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0px",
                  borderRadius: "10px",

                  textDecoration: "none",
                  curser: "pointer",

                  color: "#007bff",

                }}
                className="notification1  btn-link"
                onClick={() => navigate("/nurseInventory")}
              >
                <img src={nurseInv} className="" width="38px" />

                Inventories

              </button>
            </div>




          </div>
          {selected === "medicine" ? <MedOrders data={reloadTable} render={reRender} onSuccess={() => { setNewMed(false) }} /> :
            selected === "consumables" ? <ConOrders data={reloadTable1} render={reRender} onSuccess={() => { setNewCon(false) }} /> : ""
            // selected === "medHistory" ? <ServiceHistoryDoc /> : <ServiceHistoryDoc1  />
          }
        </div>
      </div>


    </>
  );
}
export default NurseOrders;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal,Button } from "react-bootstrap";
import { Carousel } from 'react-bootstrap';
import AddToCart from './AddToCart';
import { Badge } from 'react-bootstrap';
import prod8 from "./assets/img/products-img8.jpg"

function DealPromotions2({setShowNavbarAndFooter}) {
    const [promotions, setPromotions] = React.useState([]);

    useEffect(() => {

       
        // const tokenString = sessionStorage.getItem("usertoken");
    
        // let v2 = tokenString.replace(
        //   /["]+/g,
        //   ""
        // ); /* REGEX To remove double quotes from tokenstring */
    
        axios
          .get(`${APIURL}/api/v1/patient/list-promotions/?promo_type=deal_promotion`,
          
          // {
          //   headers: { Authorization: "Token " + v2 },
          // }
          
          )
          .then((res) => {
            //console.log(res);
            if (res.status == 200) {
            
                // const dealPromotions = res.data.details.filter(
                //     (promo) => promo.promo_type === "deal_promotion"
                //   );
                  setPromotions(res.data.details);
              //console.log(featureList)
            } else {
              //setLoading(false);
            }
          })
          .catch((err) => {
           // setLoading(false);
          });
        }, []);


        const dp2Promotions = promotions.filter((promo) => promo.promo_display === 'dp2');


return(
    <>


<section className="banner-categories-area pt-70 pb-40" style={{ marginTop: '-3.5%' ,height:"auto"}}>

      <div className="container">
        <div className="row">
        <div style={{ display: "contents",width:"200px"  }} className="home-slides owl-carousel owl-theme" >
    <OwlCarousel items={2}
                            className="owl-theme"
                            loop
                            nav
                            margin={40}
                            dots={false}
                            autoplay={true}
            navigation={true}
            autoplayTimeout={3000}
                     
                        >
          
          {dp2Promotions .length >0 && dp2Promotions.map((promo) => (
            <div key={promo.promotion_id} className="col-lg-6 col-md-6">
              <div className="single-banner-categories-box" style={{marginLeft:"-8px" ,   width: "600px"
  ,height: "250px" }}>
      <a href={`/dealspage?promo_id=${promo.promotion_id}`}>
                <img src={promo['promo_banner']} alt="image"  style={{height: "250px", width: "100%",objectFit: "cover"}} />
</a>
                <div className="content">
                  {/* <span className="sub-title">Deal of the Week</span> */}
                  {/* <h3><a href="#">{promo.promo_type}</a></h3> */}
                  <div className="btn-box">
                  {promo.overlay_text && (
                <p style={{ fontSize: parseInt(promo.overlay_size), color:promo.overlay_colour }}>
                  {promo.overlay_text} 
                </p>
                )} 
                    <div className="d-flex align-items-center">
                             
                 
                      <a href={`/dealspage?promo_id=${promo.promotion_id}`} className="default-btn" style={{marginBottom:"-10%",marginLeft:"70%",backgroundColor:"#F0A639" ,color:"white"}}><i className="flaticon-trolley" style={{marginTop:"4%"}}></i> Shop Now</a>
                      <span className="price">{promo.amount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
         
    </OwlCarousel>
          </div>
        </div>
      </div>
   
    </section>

    </>






)







}
export default DealPromotions2
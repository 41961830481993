import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal, Button } from "react-bootstrap";
import { Carousel } from 'react-bootstrap';
import AddToCart from './AddToCart';
import { Badge } from 'react-bootstrap';
import prod1 from "./assets/img/products-img1.jpg"
import masterlab from "./assets/img/labtest.png"
import procedure from "./assets/img/procedure.png"
import { FaHeart } from "react-icons/fa";
import { handleFavorites } from "./handleFav";
import classes from "./favorite.module.css";
import { FavDelete, Fav } from "./Context";
import consumable from "./assets/img/consumable.png"
import medicine from "./assets/img/medicine.png"
import scan from "./assets/img/scan.png"
import misc from "./assets/img/misc.png"
import { useAlert } from "react-alert";
import { CartContext } from "./AddToCartContext";
import AddToCartBestSell from "./AddtoCartBestSell"
import QuickView from "./QuickView";
import { connect } from 'react-redux';


function BestSelling({ setShowNavbarAndFooter, updateCartData }) {
  const [quickViewDetails, setQuickViewDetails] = useState({})
  const [cartdata, setCartdata] = useState("");
  const { favDeletedList, setFavDeletedList } = useContext(FavDelete);
  const { favAdded, setFavAdded } = useContext(Fav);
  const [cartShow, setCartShow] = useState(false)
  const [distance, setDistance] = useState(0.0);
  const [featureList, setFeatureList] = useState([]);
  const [favData, setFavData] = useState([]);
  const [showCart, setShowCart] = useState(false)
  const [showWishList, setShowWishList] = useState(false)
  const [showDetails, setShowDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const alert = useAlert();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const { handleAddToCart } = useContext(CartContext);

  useEffect(() => {

    // const tokenString = sessionStorage.getItem("usertoken");

    // let v2 = tokenString.replace(
    //   /["]+/g,
    //   ""
    // ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/patient/list-promotions/?promo_type=best_selling`,
      )
      .then((res) => {
   
        if (res.status == 200) {

          const datas = res.data.items;
    
          setFeatureList(datas);
      
        } else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  }, []);

  //favorite adding
  const AddTohandleFavorites = (item) => {
   
    const DataToFav = {
      p_id:item.item_service,
      p_name:item.item_name,
      p_amount:item.amount,
      p_img:item.image,
      p_category:item.service_category
  }
    handleFavorites(DataToFav);
  
    
    setFavAdded(true);
  };


  //  useEffect for fetching favorite data list from local storage.
  useEffect(() => {
    if (login_datas) {
      const userId = sessionStorage.getItem("userid");
   
      const localStorageKey = `myFavorite_${userId}`;
      try {
        const favoriteDatas = JSON.parse(localStorage.getItem(localStorageKey));
        //const parsedData = JSON.parse(favoriteDatas);
        setFavData(favoriteDatas);
      } catch (error) {
        let favoriteDatas = [];
      }
      // setFavUpdated(false);
      setFavDeletedList(false);
    }
  }, [favDeletedList, favAdded, login_datas]);

  const addToCart = (item) => {
    
    
    handleAddToCart(item);
    // Additional logic or actions
  };


  const calculateDistance = (latt1, latt2, long1, long2) => {

    var R = 6371; // Radius of the earth in km
    const lat1 = parseFloat(latt1)
    const lat2 = parseFloat(latt2)
    const lon1 = parseFloat(long1)
    const lon2 = parseFloat(long2)
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // return d;
    setDistance(parseFloat(d).toFixed(4));


  };
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }
  const HandleClose = () => {
    setCartShow(false)
  }

  const handleQuickView = (item) => {
    setQuickViewDetails({
      amount: item.amount,
      discount: item.discount,
      hospital: item.hospital_id,
      main_service: item.service_category === "master_labtest" ? "Laboratory" : item.service_category === "master_scan" ? "Scan" : item.service_category === "master_medicine" ? "Medicine" : item.service_category === "misc" ? "Miscellaneous" : item.service_category === "master_procedure" ? "Procedure" : item.service_category === "master_consumable" ? "Consumables" : "",
      name: item.hospital_name,
      service: item.item_name,
      service_category: item.service_category,
      image: "",
      id: item.item_service
    })

    setShowDetails(true);
  };
  const AddtoCartPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddToCartBestSell

            datas={props.data}
            handle={HandleClose}
          // stage={displaystage}
          // onHide={handleDiseaseClose}
          // submitduration={submitDurationHandle}

          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={props.onHide}>
                    Close
                  </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ width: "96%" }}>
          <QuickView item={quickViewDetails} handleRefreshCart={HandleClose} from="wo" />
        </Modal.Body>
      </Modal>

    )
  }

  const FeatureListFilter = featureList.filter(p => p['service_category'] !== 'master_consumable' && p['service_category'] !== 'master_medicine');

  return (<>


    <div className="container">
      <div className="section-title">
        <h3 >Best Selling Services</h3>
      </div>




      <div className="row" >
        <div style={{ display: "contents" }} className="home-slides owl-carousel owl-theme" >
       
          <OwlCarousel items={FeatureListFilter.length < 4 ? FeatureListFilter.length : 4}
            className="owl-theme"
            loop
            nav
            margin={0}
            autoplay={true}
            navigation={true}

            autoplayTimeout={5000}
          >
            {FeatureListFilter.length>0 && FeatureListFilter
              .map((item) => {
                const isFavorite = favData.some(
                  (favItem) => item.id === favItem.p_id || item.item_service === favItem.p_id
                  
                );

                return(

                <div className="doctor-card456"   >

                  <div className="text-center123" style={{ width: "270px", fontSize: "15px" }}>
                    <b onClick={() => ""} style={{ color: "black" }}>
                      {item.item_name} <span>&nbsp;</span> <Badge pill variant="success" style={{ fontSize: '10px' }}>
                        {item.discount}% OFF!
                      </Badge>
                      <Link
                        to="/search"
                        state={{ details: item.item_service }}
                        style={{ float: "right", marginRight: "1%" }}
                      >
                        <i className='bx bx-search-alt'></i>

                      </Link>
                    </b>
                    <br />
                    <span style={{ display: "flex", flexDirection: "column", marginTop: "6px" }}>
                      <b style={{ fontSize: "11px", margin: "0 0 2px 0" }}>
                        <i className="fa fa-check-square" aria-hidden="true"></i>
                        <span>&nbsp;</span>
                        {item.service_category === "master_scan"
                          ? "Scan"
                          : item.service_category === "misc"
                            ? "Misc"
                            : item.service_category === "master_medicine"
                              ? "Medicine"
                              : item.service_category === "master_procedure"
                                ? "Procedure"
                                : item.service_category === "master_consumable"
                                  ? "Consumables"
                                  : item.service_category === "master_labtest"
                                    ? "Test"
                                    : item.service_category === "registration"
                                      ? "Registration"
                                      : ""}
                      </b>
                      <b style={{ fontSize: "11px", margin: "0" }}>
                        <i className="fa fa-h-square" aria-hidden="true"></i>
                        <span>&nbsp;</span>
                        {item.hospital_name}
                      </b>
                    </span>
                    <div className="price" style={{ display: "flex", alignItems: "center", width: "250px", marginTop: "10px" }}>
                      <b style={{ fontSize: "14px", color: "black" }}>

                        {item.amount !== (item.amount * (100 - item.discount) / 100) ?
                          <span className="price">
                            <span className="old-price" style={{ textDecoration: "line-through", color: "grey", fontWeight: "normal",fontSize:"15px" }}>
                              <i class="fa fa-usd" aria-hidden="true"></i>
                              {item.amount}
                            </span>
                            <span>&nbsp;</span>
                            <span className="discounted-price" style={{fontSize:"16px"}}>
                              <b> {(item.amount * (100 - item.discount) / 100).toFixed(2)}</b>
                            </span>
                          </span>
                          :
                          <>
                            <span className="price"> <i class="fa fa-usd" aria-hidden="true"></i><b> {item.amount} </b></span>
                          </>
                        }


                      </b>

                      <span style={{ marginLeft: "30px" }}></span>
                      {login_datas ?  <FaHeart
                            onClick={() => AddTohandleFavorites(item)}
                            className={
                              isFavorite
                                ? classes.favAdded
                                : classes.favNotAdded
                            }
                            style={{ color: "#e2e2d0", marginLeft: "10px" }}
                          /> :""}
                      <Button
                        className="my-button"
                        style={{ marginLeft: "3%" }}
                        onClick={() => addToCart(item)}
                      >
                        Add to Cart
                      </Button>
                    </div>




                  </div>
                </div>
                 )



                        })}
          </OwlCarousel>
        </div>
      </div>

    </div>

    {showDetails ? (
      <DetailPopup
        show={showDetails}
        data={selectedItem}
        onHide={() => {
          setShowDetails(false);

        }}
      />
    ) : (
      ""
    )}

    {cartShow ? (
      <AddtoCartPopUp
        show={cartShow}
        data={cartdata}
        onHide={() => {
          setCartShow(false);

        }}
      />
    ) : (
      ""
    )}




  </>)


}

export default BestSelling
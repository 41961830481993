import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Modal } from 'react-bootstrap';

import del from "../../assets/images/del.png";

import LoadingOverlay from 'react-loading-overlay';
import { Button } from "react-bootstrap";

function PreviewPage({ patient_id1, name1, data }) {
  const [serviceList, setServiceList] = useState([]);
  const [display, setDisplay] = useState(false);
  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, ""); 
    axios
    .get(`${APIURL}/api/v1/staff/add-to-cart/?patient_id=${patient_id1}`, {
      headers: { Authorization: "Token " + v2 },
    })
    .then((res) => {
      // " " ;
      if (res.data.status === "success") {
        // setLoading(false);

        const datas = res.data.cart_items;

        setServiceList(datas);
        console.log("datas", serviceList);
        setDisplay(true);
      
      } else {
        //  setLoading(false);
     
        setDisplay(false);
      }
    })
    .catch((err) => {
      // setLoading(false);
    
    });
  }, [])

  let display1 = serviceList.length > 0 ? serviceList.map((item, index) => {
    return (
      <>

      {" "}
      <div class="row" style={{  padding: "0px", marginBottom: "0px", marginTop: "0px", textAlign: "left" }} >
      <div class="col-3 ser_body" style={{ wordWrap: "break-word"}}>
          {item.item_service_name}

        </div>
        <div class="col ser_body">
          {item.item_amount}

        </div>
        <div class="col ser_body">
          {item.item_quantity}

        </div>
        <div class="col ser_body">
          {item.discount_amount}

        </div>
        <div class="col ser_body">
          {item.shipping_charge}

        </div>
        <div class="col ser_body">
          {item.vat_amount}
       
        </div>
        <div class="col ser_body" style={{ wordWrap: "break-word"}}>
          {Math.round(item.net_total_item_amount+item.shipping_charge)}
       
        </div>
     
      


      </div>
    </>
  )
}) : null
return(
    <>
  <div className="doctor-card16">
  <div className="card-header-color">
        <div className="card-header-text"><h3 style={{color:"var(--theme-blue)"}}>Preview</h3></div> </div>

    {/* <table>
        <tr>
            <th>Total Quantity</th><th>Total amount</th><th>Service Name</th>
            </tr>
        </table> */}
  
  
                <div className="container34">
                  <br/>

                  <div class="row" style={{ textAlign: "left" }}>
                  <div class="col-3 ser_header">
                      <h6 >Item</h6>
                      {/* <input /> */}
                    </div>
                    <div class="col ser_header">
                      <h6>Price</h6>
                      {/* <input /> */}
                    </div>
                    <div class="col ser_header">
                      <h6>Quantity</h6>
                      {/* <input /> */}
                    </div>
                    <div class="col ser_header">
                      <h6> {" "}Discount <br/> <span style={{marginLeft:"20%"}}>(<i class="fa fa-eur" aria-hidden="true" ></i>)</span></h6>
                      {/* <input /> */}
                    </div>
                    <div class="col ser_header">
                      <h6>Shipping Charge</h6>
                      {/* <input /> */}
                    </div>
                    <div class="col ser_header">
                     <h6> {" "} GST ( {""}<i class="fa fa-eur" aria-hidden="true"></i>{""})</h6>
                      {/* <input /> */}
                    </div>
                    <div class="col ser_header">
                      <h6>Total</h6>
                      {/* <input /> */}
                    </div>
                  </div>
                  <div className="test-reports-display-list">
                    {display ? (
                      display1
                    ) : (
                     null
                    )}
                  </div>
 </div>
</div>


 

    </>
)

}
export default PreviewPage
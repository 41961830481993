import React ,{useState} from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import './profile.css'
import {Button ,Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';

function EducationalUpdate(props) {
    const alert = useAlert()
    const[exp ,setExp] =useState(props.educational !== '' ?props.educational : '')
    const id = props.id1
    const [error,setError]=useState({
        exp:''
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='exp'){
            setExp(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (exp==="" ) {
            isValid = false;
            errors["exp"] = "Enter Educational Credentials";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitAddress()
        }
      }

        const submitAddress = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
                educational_credentials : exp
             }
          
             axios.put(`${APIURL}/api/v1/doctor/medical-profile-update-view/${id}/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                
               alert.success("Updates successfully")
               props.handle()
               setExp()
               
            
              }else{
                alert.error("Error in submission. Try again!")
              }
            
            })
            .catch(err=>{
              alert.error("Error in submission. Try again!")

            })
          
          }

    return (
        <div>
            <div className="item"><input className="form-control" type="text" value={exp} min="0" max="50" maxLength="150" name="exp" placeholder="Educational Credential" onChange={handleChange}/>

                {error.exp ? <div className="error-validation-msg"> {error.exp}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Educational Credentials</button>
            </div>
        </div>
    )
}

export default  EducationalUpdate
